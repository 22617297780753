<div>
	<table class="steuern-table">
		<thead>
			<tr>
				<th scope="col">Steuer</th>
				<th scope="col">Steuerart</th>
				<th scope="col">Steuerbasis</th>
				<th scope="col">Steuersatz</th>
				<th scope="col">Steuerwert</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let steuer of steuern">
				<td>{{ steuer.steuername }}</td>
				<td>{{ steuer.steuerart }}</td>
				<td>{{ steuer.steuergrundbetrag | efaNumber }} EUR</td>
				<td>{{ steuer.steuersatz | percent: '1.2-2':'de' }}</td>
				<td>{{ steuer.steuerbetrag | efaNumber }} EUR</td>
			</tr>
		</tbody>
	</table>
</div>

<hr style="margin-top: 1em" />

<div class="flex-row" style="margin-left: 5em">
	<div class="data-column2" style="max-width: 20em">
		<h3 style="font-weight: bold">Zusammenfassung</h3>
		<div app-data-item [field]="metadata.fields.bruttowertEuro">{{ gattung.bruttowertEuro | efaNumber }} EUR</div>
		<div app-data-item label="Summe Steuern:">{{ summaryValues.summeSteuern | efaNumber }} EUR</div>
		<div app-data-item label="Nettowert:">{{ summaryValues.nettowert | efaNumber }} EUR</div>
		<!--  -->
		<div app-data-item label="Summe Quest:" style="margin-top: 2em">
			{{ summaryValues.summeQuest | efaNumber }} EUR
		</div>
	</div>
</div>
