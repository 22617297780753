<div class="flex-column">
	<div class="page-header">
		<h1>Dateien</h1>
		<div>
			<button
				mat-raised-button
				color="primary"
				[disabled]="isWorking$ | async"
				(click)="refresh()">
				<mat-icon>refresh</mat-icon>
				Aktualisieren
			</button>
		</div>
	</div>

	<mat-action-list>
		<button
			style="height: 100%"
			mat-list-item
			*ngFor="let asset of assets$ | async"
			(click)="download(asset)">
			<mat-icon mat-list-icon>description</mat-icon>
			<div mat-line>{{ asset.schluessel }}</div>
			<div mat-line>
				{{ asset.mimeType }} {{ asset.geaendertAm }} {{ asset.geaendertVon }}
			</div>
		</button>
	</mat-action-list>

	<hr />

	<h2 *requireRole="'erweitert'">Neue Version hochladen</h2>
	<form [formGroup]="form" (ngSubmit)="upload()" *requireRole="'erweitert'">
		<div class="flex-row">
			<mat-form-field>
				<mat-label>Schlüssel</mat-label>
				<mat-select
					formControlName="schluessel"
					style="width: 100%"
					#matSelectElement
					panelClass="filter-panel"
					[required]="true">
					<mat-option
						*ngFor="let schluesselOption of schluesselOptions$ | async"
						[value]="schluesselOption">
						{{ schluesselOption }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<app-file-upload
				(fileSelected)="onFileSelected($event)"
				[placeholder]="'Datei auswählen..'"></app-file-upload>
			<div>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					[disabled]="cannotUpload$ | async">
					Hochladen
				</button>
			</div>
		</div>
	</form>
</div>
