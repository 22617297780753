import { Pipe, PipeTransform } from '@angular/core';
import { VorabpauschaleStatus } from '../einreichung.service';

@Pipe({
	name: 'vorabpauschaleStatus',
})
export class VorabpauschaleStatusPipe implements PipeTransform {
	transform(value: VorabpauschaleStatus): string {
		switch (value) {
			case VorabpauschaleStatus.Unbekannt:
			default:
				return 'Unbekannt';
			case VorabpauschaleStatus.DurchGeschaeftsfallAbgedeckt:
				return 'Durch einen Geschäftsfall abgedeckt';
			case VorabpauschaleStatus.Faelig:
				return 'Fällig';
			case VorabpauschaleStatus.FaelligUndEingegangen:
				return 'Fällig und eingegangen';
			case VorabpauschaleStatus.KeineFaellig:
				return 'Keine Vorabpauschale fällig';
		}
	}
}
