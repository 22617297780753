import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import {
	EinreichungListStatusFilter,
	isMatch,
} from 'src/app/bewegungsdaten/einreichung-list/einreichung-list.component';
import { EinreichungService } from 'src/app/bewegungsdaten/einreichung.service';
import { GeschaeftsfallService } from 'src/app/bewegungsdaten/geschaeftsfall.service';
import {
	einreichungMetadata,
	geschaeftsfallMetadata,
	GeschaeftsfallListStatusFilter,
	doesGFMatchStatusFilter,
} from 'src/app/bewegungsdaten/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { finanzinstitutMetadata } from 'src/app/stammdaten/finanzinstitut/model';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { gattungMetadata } from 'src/app/stammdaten/gattung/model';
import { ConfigService } from '../config.service';
import { CurrentUser, CurrentUserService } from '../current-user.service';
import { InitService } from '../init/init.service';
import { BenutzerRolle } from '../roles';

let registered = false;

export function registerLocale(): void {
	if (registered) {
		return;
	}

	registerLocaleData(localeDe);
	registered = true;
}

registerLocale();

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	readonly themes = ['default', '1', '2', '3', '4'];

	readonly einreichunglistMenuItems = (
		[
			'Eingegangen',
			'In Bearbeitung',
			'Zur Freigabe',
			'Freigegeben',
			'Teilgebucht',
			'Blockiert',
			'Automatisierung Fehler',
		] as EinreichungListStatusFilter[]
	).map(
		status =>
			({
				label: status,
				link: einreichungMetadata.routing.list!.url,
				indented: 1,
				queryParams: { status },
				badgeValue: '0',
				onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
			} as MenuItem)
	);

	readonly geschaeftsfallMenuItems = (
		[
			'Zum Übertrag ins Depot',
			'Zum Inkasso',
			'Inkasso angefordert',
			'Blockiert',
			'Zur Buchung',
		] as GeschaeftsfallListStatusFilter[]
	).map(
		status =>
			({
				label: status,
				link: geschaeftsfallMetadata.routing.list!.url,
				indented: 1,
				queryParams: { status },
				badgeValue: '0',
				onlyForRoles:
					status === 'Zum Übertrag ins Depot'
						? [
								BenutzerRolle.Anteilsscheingeschäft,
								BenutzerRolle.Leser,
								BenutzerRolle.Standard,
						  ]
						: [BenutzerRolle.Leser, BenutzerRolle.Standard],
			} as MenuItem)
	);

	private readonly menuItems: MenuItem[] = [
		{
			label: 'Einreichungen',
			link: einreichungMetadata.routing.list!.url,
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
		},
		...this.einreichunglistMenuItems,
		{
			label: 'Archiv',
			indented: 1,
			link: '/einreichung/archiv',
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
		},
		{
			label: 'Geschäftsfälle',
			link: geschaeftsfallMetadata.routing.list!.url,
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
		},
		...this.geschaeftsfallMenuItems,
		{
			label: 'Buchungen',
			link: '/buchungen',
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard, BenutzerRolle.DSGVO],
		},
		{
			label: 'Tagesverarbeitung',
			link: '/tagesverarbeitung',
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
		},
		{
			label: 'Ultimo',
			link: '/ultimo',
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
		},
		{
			label: 'Stammdaten',
			link: '/stammdaten',
		},
		{
			label: finanzinstitutMetadata.plural,
			indented: 1,
			link: finanzinstitutMetadata.routing!.list!.url,
		},
		{
			label: gattungMetadata.plural,
			indented: 1,
			link: gattungMetadata.routing!.list!.url,
		},
		{
			label: 'Fehlende Ausschüttungen',
			indented: 2,
			link: '/fehlende-ausschuettungen',
			onlyForRoles: [BenutzerRolle.Leser, BenutzerRolle.Standard],
		},
		{
			label: 'Konfiguration',
			indented: 0,
			link: '/konfiguration',
		},
	];

	readonly themeSelection = new TypedForm<{ darkMode: boolean; theme: string }>({
		darkMode: new UntypedFormControl(false),
		theme: new UntypedFormControl('default'),
	});

	readonly filteredMenuItems$ = new BehaviorSubject<MenuItem[]>([]);

	constructor(
		public readonly init: InitService,
		public readonly configService: ConfigService,
		public readonly currentUser: CurrentUserService,
		einreichungService: EinreichungService,
		geschaeftsfallService: GeschaeftsfallService,
		gattungService: GattungService,
		changeDetectorRef: ChangeDetectorRef,
		router: Router,
		matDialog: MatDialog
	) {
		router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.forEach(() => matDialog.closeAll());

		currentUser.currentUser$.subscribe(currentUser => {
			this.buildMenu(currentUser);
		});

		this.themeSelection.values$.subscribe(v => {
			const bodyClassList = document.body.classList;
			if (v.darkMode) {
				bodyClassList.add('dark-mode');
			} else {
				bodyClassList.remove('dark-mode');
			}

			const themeClasses: string[] = [];
			bodyClassList.forEach(v => {
				if (v.startsWith('efa-theme')) {
					themeClasses.push(v);
				}
			});

			bodyClassList.remove(...themeClasses);
			bodyClassList.add('efa-theme-' + v.theme);
			try {
				localStorage.setItem('darkMode', v.darkMode ? 'true' : 'false');
				localStorage.setItem('theme', v.theme);
			} catch {}
		});

		try {
			const darkMode = localStorage.getItem('darkMode') === 'true' ?? false;
			const theme = localStorage.getItem('theme') ?? 'default';
			this.themeSelection.setValueTyped({ darkMode, theme });
		} catch (err) {
			console.error('Failed to set theme', err);
		}

		einreichungService.list$.subscribe(list => {
			this.einreichunglistMenuItems.forEach(menuItem => {
				menuItem.badgeValue = list
					.filter(e => isMatch(e, menuItem.label as EinreichungListStatusFilter))
					.length.toString();
			});
			changeDetectorRef.markForCheck();
		});

		geschaeftsfallService.list$.subscribe(list => {
			this.geschaeftsfallMenuItems.forEach(menuItem => {
				menuItem.badgeValue = list
					.filter(e =>
						doesGFMatchStatusFilter(e, menuItem.label as GeschaeftsfallListStatusFilter)
					)
					.length.toString();
			});
			changeDetectorRef.markForCheck();
		});

		const neuausschuettungenItem = this.menuItems.find(
			m => m.link === '/fehlende-ausschuettungen'
		);
		gattungService.fehlendeNeuausschuettungen$.subscribe(list => {
			neuausschuettungenItem!.badgeValue = list.length.toString();
		});
	}

	buildMenu(currentUser: CurrentUser): void {
		this.filteredMenuItems$.next(
			this.menuItems.filter(mi => {
				if (
					mi.denyForRoles &&
					mi.denyForRoles.some(role => currentUser.roles.includes(role))
				)
					return false;

				if (
					mi.onlyForRoles &&
					mi.onlyForRoles.every(role => !currentUser.roles.includes(role))
				)
					return false;

				return true;
			})
		);
	}
}

interface MenuItem {
	label: string;
	link?: string;
	indented?: number;
	queryParams?: { [key: string]: string };
	badgeValue?: string;
	onlyForRoles?: BenutzerRolle[];
	denyForRoles?: BenutzerRolle[];
}
