<div class="flex-column">
	<mat-card class="flex-column-mat-card">
		<form (ngSubmit)="search()" [formGroup]="searchForm">
			<div class="page-header">
				<h1>Compliance Einreichungsliste</h1>
				<button
					mat-raised-button
					color="accent"
					[disabled]="!(existsAllSearchCriteria$ | async)"
					(click)="downloadAsExcel()">
					<mat-icon *ngIf="isLoading$ | async | not">file_download</mat-icon>
					<mat-spinner
						*ngIf="isLoading$ | async"
						[diameter]="20"
						style="display: inline-block; margin-right: 0.5em"></mat-spinner>
					Als Excel herunterladen
				</button>
			</div>
			<div style="display: flex; gap: 1em; margin-bottom: 1em">
				<label style="margin-top: 2em">Suchkriterium:</label>
				<mat-form-field style="flex-basis: 22em">
					<mat-label>Wähle Zeitraum für Auswertung</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input
							readonly
							matStartDate
							placeholder="Start date"
							formControlName="datumAb" />
						<input
							readonly
							matEndDate
							placeholder="End date"
							formControlName="datumBis" />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					style="margin: 1em 0 1em 0"
					[disabled]="!(existsAllSearchCriteria$ | async)">
					Suchen...
				</button>
			</div>
		</form>
	</mat-card>

	<table
		mat-table
		[dataSource]="dataSource"
		style="width: 100%"
		id="ultimo-einreichungen-table"
		matSort>
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				[matTooltip]="'Name, Vorname'"
				mat-sort-header>
				Name
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
				{{ element.name }}, {{ element.vorname }}
			</td>
		</ng-container>
		<ng-container matColumnDef="rolle">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				[matTooltip]="'Rolle (Einreicher, Wirtschaftlich Berechtigter)'"
				mat-sort-header>
				Rolle
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.rolle }}
			</td>
		</ng-container>
		<ng-container matColumnDef="anschrift">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="padding-left: 1em"
				[matTooltip]="'Anschrift (PLZ, Stadt, Strasse, Adresszusatz, Bundesland, Land)'"
				mat-sort-header>
				Anschrift
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				style="
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow-x: hidden;
					padding-left: 1em;
				">
				{{ element.postleitzahl }} {{ element.stadt }} {{ element.adresse }}
				{{ element.adresszusatz }} {{ element.bundesland }} {{ element.land }}
			</td>
		</ng-container>
		<ng-container matColumnDef="geburtsdatum">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 12em; padding-left: 1em"
				mat-sort-header>
				{{ personMetadata.fields.geburtsdatum.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.geburtsdatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="kycPruefnummer">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 12em"
				[matTooltip]="'KYC - Prüfungsnummer und Kein Handlungsbedarf (Flag)'"
				mat-sort-header>
				{{ personMetadata.fields.kycPruefungsnummer.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.kycPruefnummer }} ({{ element.kycPositiv | booleanText : true }})
			</td>
		</ng-container>
		<ng-container matColumnDef="staatsangehoerigkeit">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 12em" mat-sort-header>
				{{ personMetadata.fields.staatsangehoerigkeit.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.staatsangehoerigkeit }}
			</td>
		</ng-container>
		<ng-container matColumnDef="einreichungenHoehe">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				class="text-right"
				style="width: 12em"
				mat-sort-header>
				Nominal
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right">
				{{ element.einreichungenHoehe }}
			</td>
		</ng-container>
		<ng-container matColumnDef="isinWkn">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="padding-left: 2em"
				[matTooltip]="'ISIN / WKN (Land)'"
				mat-sort-header>
				ISIN / WKN (Land)
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 2em">
				{{ element.isin }} / {{ element.wkn }} ({{ element.land }})
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<mat-paginator showFirstLastButtons></mat-paginator>
</div>
