import { forwardRef } from '@angular/core';
import { EntityMetadata, RequiredLevel } from 'src/app/model';
import { FinanzinstitutListPageComponent } from './finanzinstitut-list-page/finanzinstitut-list-page.component';
import { FinanzinstitutEditPageComponent } from './finanzinstitut-edit-page/finanzinstitut-edit-page.component';
import { StammdatenStatus } from '../model';

import { FinanzinstitutViewPageComponent } from './finanzinstitut-view-page/finanzinstitut-view-page.component';

export interface Finanzinstitut {
	id: string;
	finanzinstitutsnummer: string;
	finanzinstitutsname: string;
	status: StammdatenStatus;
	finanzinstitutskonto: string | null;
	adresszusatz: string | null;
	adresse: string | null;
	postleitzahl: string | null;
	ort: string | null;
	land: string | null;
	bundesland: string | null;
	geaendertVon: string;
	geaendertAm: Date;
	freigegebenVon: string | null;
	freigegebenAm: Date | null;
	istEinreicher: boolean;
	istEmittent: boolean;
	displayName: string; // kommt nicht vom Backend
	displayNameLower: string; // kommt nicht vom Backend
}

export const finanzinstitutMetadata: EntityMetadata<Finanzinstitut> = {
	label: 'Finanzinstitut',
	labelGender: 'n',
	apiCollectionName: 'finanzinstitut',
	plural: 'Finanzinstitute',
	routing: {
		list: { url: '/finanzinstitute', component: forwardRef(() => FinanzinstitutListPageComponent) },
		edit: {
			url: id => `/finanzinstitute/${id}/edit`,
			component: forwardRef(() => FinanzinstitutEditPageComponent),
		},
		view: { url: id => `/finanzinstitute/${id}`, component: forwardRef(() => FinanzinstitutViewPageComponent) },
	},
	fields: {
		finanzinstitutsname: {
			typ: 'text',
			label: 'Finanzinstitutsname',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Name des Finanzinstituts',
		},
		status: {
			typ: 'backend-text',
			label: 'Status',
		},
		finanzinstitutsnummer: {
			typ: 'text',
			label: 'Finanzinstitutsnummer',
			maxLength: 10,
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Eindeutige Nummer des Instituts',
			mussNumerischSein: true,
		},
		finanzinstitutskonto: {
			typ: 'iban',
			label: 'Finanzinstitutskonto (IBAN)',
			requiredLevel: RequiredLevel.NotRequired,
			description: 'Institutskontonummer (IBAN)',
		},
		istEinreicher: {
			typ: 'boolean',
			label: 'Einreicher',
			requiredLevel: RequiredLevel.NotRequired,
		},
		istEmittent: {
			typ: 'boolean',
			label: 'Emittent',
			requiredLevel: RequiredLevel.NotRequired,
		},
		adresszusatz: {
			typ: 'text',
			label: 'Adresszusatz',
			maxLength: 200,
			requiredLevel: RequiredLevel.NotRequired,
			description: 'Zusätzliche Angaben zur Anschrift',
		},
		adresse: {
			typ: 'text',
			label: 'Straße und Hausnummer',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredForFreigabe,
		},
		postleitzahl: {
			typ: 'text',
			label: 'Postleitzahl',
			maxLength: 10,
			requiredLevel: RequiredLevel.RequiredForFreigabe,
			referenzlist: 'postleitzahl',
		},
		ort: {
			label: 'Ort',
			typ: 'text',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredForFreigabe,
		},
		bundesland: {
			label: 'Bundesland',
			typ: 'referenzlist',
			referenzlist: 'bundesland',
		},
		land: {
			label: 'Land',
			typ: 'referenzlist',
			requiredLevel: RequiredLevel.RequiredForFreigabe,
			referenzlist: 'land',
		},
		geaendertAm: { label: 'Geändert am', typ: 'backend-datum' },
		geaendertVon: { label: 'Geändert von', typ: 'backend-text' },
		freigegebenAm: { label: 'Freigegeben am', typ: 'backend-datum' },
		freigegebenVon: { label: 'Freigegeben von', typ: 'backend-text' },
		displayName: { label: 'Finanzinstitut', typ: 'backend-text' },
		displayNameLower: { label: '', typ: 'backend-text' },
	},
};

export interface FinanzinstitutAktiveNutzungInfo {
	inActiveUse: boolean;
	geschaeftsfallnummerList: string | null;
	einreichungList: string | null;
};

export enum FinanzinstitutRolle{
	Emittent = 'Emittent',
	Einreicher = 'Einreicher',
	EmittentUndEinreicher = 'EmittentUndEinreicher',
}
