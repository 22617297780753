import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityServiceBase, StammdatenEntityServiceBase } from '../entity.service.base';
import {
	Finanzinstitut,
	FinanzinstitutAktiveNutzungInfo,
	finanzinstitutMetadata,
} from './model';
import { parseIncomingAsDate } from 'src/app/utils';
import { map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FinanzinstitutService extends StammdatenEntityServiceBase<Finanzinstitut> {
	constructor(http: HttpClient) {
		super(http, finanzinstitutMetadata);

		this.onLoaded = f => {
			f.displayName = `${f.finanzinstitutsnummer} ${f.finanzinstitutsname}`.trim();
			f.displayNameLower = f.displayName.toLocaleLowerCase();
			f.geaendertAm = parseIncomingAsDate(f.geaendertAm);
			f.freigegebenAm = parseIncomingAsDate(f.freigegebenAm);
		};

		this.onSaving = f => {
			delete f.freigegebenAm;
			delete f.freigegebenVon;
			delete f.displayName;
			delete f.displayNameLower;
			delete f.geaendertAm;
			delete f.geaendertVon;
			delete f.status;
			delete f.id;

			// Anpassung von Werten, damit Backend die richtig interpretiert
			if (!f.istEinreicher) f.istEinreicher = false; // null -> false
			if (!f.istEmittent) f.istEmittent = false; // null -> false
			if (!f.land) f.land = null; // '' -> null
			if (!f.bundesland) f.bundesland = null; // '' -> null
		};

		this.defaultSortCompare = (f1, f2) => f1.displayName.localeCompare(f2.displayName);
	}

	public getName(id: string | null | undefined): string {
		const institut = this.list$.value.find(i => i.id === id);
		return institut ? institut.displayName : '<FALSCHES ID?!>';
	}

	public getName$(id: string | null | undefined): Observable<string> {
		return this.list$.pipe(
			map(list => {
				if (!id) return '';
				const institut = list.find(i => i.id === id);
				return institut ? institut.displayName : '<FALSCHES ID?!>';
			})
		);
	}

	finanzinstitutAktiveNutzungInfo$(
		finanzinstitutId: string
	): Observable<FinanzinstitutAktiveNutzungInfo | null> {
		return this.http.get<FinanzinstitutAktiveNutzungInfo | null>(
			`/finanzinstitut/${finanzinstitutId}/inaktivierungpruefen`
		);
	}

	assembleOpenItemMessage(info: FinanzinstitutAktiveNutzungInfo): string {
		const numberOfOpenGf = info.geschaeftsfallnummerList
			? info.geschaeftsfallnummerList.split(',').map(x => +x).length
			: 0;
		const numberOfOpenEr = info.einreichungList
			? info.einreichungList.split(',').map(x => +x).length
			: 0;
		const part1 = 'Zur Beachtung: Das Finanzinstitut wird ';
		const part2a =
			numberOfOpenGf === 1
				? `bei dem offener Geschäftsfall ${info.geschaeftsfallnummerList} `
				: '';
		const part2b =
			numberOfOpenGf > 1
				? `bei den offenen Geschäftsfällen ${info.geschaeftsfallnummerList} `
				: '';
		const part3 = info.geschaeftsfallnummerList && info.einreichungList ? 'und ' : '';
		const part4a =
			numberOfOpenEr === 1 ? `bei der offenen Einreichung ${info.einreichungList} ` : '';
		const part4b =
			numberOfOpenEr > 1 ? `bei den offenen Einreichungen ${info.einreichungList} ` : '';
		const part5 = 'verwendet!';
		return part1 + part2a + part2b + part3 + part4a + part4b + part5;
	}
}
