<div class="page-header">
	<button mat-raised-button color="accent" (click)="abbrechen()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<button mat-raised-button color="primary" type="submit" [disabled]="cannotSave$ | async" (click)="save()">
		<!-- <button mat-raised-button color="primary"> -->
		<mat-icon>save</mat-icon>
		Korrektur speichern
	</button>
</div>

<div class="page-header">
	<h1>
		<span>Korrektur Einreichung {{ (pageHeaderValues$ | async)?.nummer }}</span>
	</h1>
</div>

<form [formGroup]="form" class="flex-column">
	<div class="flex-row">
		<app-text-input
			[metadata]="metadata.fields.depotNummer"
			formControlName="depotNummer"
			style="flex: 1"></app-text-input>
		<app-text-input
			style="flex: 1"
			[metadata]="metadata.fields.depotInhaber"
			formControlName="depotInhaber"></app-text-input>
	</div>
	<div class="flex-row">
		<app-text-input [metadata]="metadata.fields.konto" formControlName="konto"></app-text-input>
		<app-text-input [metadata]="metadata.fields.bic" formControlName="bic"></app-text-input>
		<app-text-input
			style="flex: 1"
			[metadata]="metadata.fields.kontoInhaber"
			formControlName="kontoInhaber"></app-text-input>
	</div>
	<div class="flex-row">
		<app-text-input
			[metadata]="metadata.fields.dsgvoAenderungsgrund"
			formControlName="dsgvoAenderungsgrund"></app-text-input>
	</div>
	<div style="margin: 2em 0 1em 0">
		<span>Personen:</span>
	</div>
	<div>
		<app-einreichung-edit-personen formControlName="personen" [korrektur]="true"></app-einreichung-edit-personen>
		<mat-error>{{ form.controls.personen | fieldErrorMessage$ | async }}</mat-error>
	</div>
</form>
