<form [formGroup]="form" class="login-form mat-elevation-z2" (ngSubmit)="login()">
	<div class="logo">
		<img src="assets/logo.jpg" alt="EfA logo" />
		<span>EfA</span>
		<div>
			<span class="dev-test-environment-info" *ngIf="configService.isDevelopment">Dev/Test-Umgebung</span>
		</div>
	</div>
	<h1>Login</h1>
	<mat-form-field appearance="fill">
		<label>Username</label>
		<input matInput placeholder="Username..." formControlName="username" autocomplete="off" />
	</mat-form-field>

	<mat-form-field appearance="fill">
		<label>Passwort</label>
		<input matInput placeholder="Passwort..." formControlName="passwort" autocomplete="off" type="password" />
	</mat-form-field>

	<button mat-raised-button type="submit" color="primary" [disabled]="cannotStartLogin$ | async">Login</button>

	<div *ngIf="init.stateAndMessage$ | async as stateAndMessage" style="text-align: center">
		<div [ngSwitch]="stateAndMessage.state" class="state-info">
			<div *ngSwitchCase="'LoadingConfiguration'">
				<mat-spinner></mat-spinner>
				<div>Lade Konfiguration...</div>
			</div>
			<div *ngSwitchCase="'FailedToLoadConfiguration'" class="error-text">
				Konfiguration konnte nicht geladen werden: {{ stateAndMessage.message }}
			</div>
			<div *ngSwitchCase="'VerifyingCachedLogin'">
				<mat-spinner></mat-spinner>
				<div>Prüfe gespeicherte Logindaten...</div>
			</div>
			<div *ngSwitchCase="'RequiresLogin'">Bitte einloggen!</div>
			<div *ngSwitchCase="'WrongCredentials'" class="error-text">Login fehlgeschlagen!</div>
			<div *ngSwitchCase="'LoggingIn'">
				<mat-spinner></mat-spinner>
				<div>Einloggen...</div>
			</div>
			<div *ngSwitchCase="'NoRoles'">
				<div>User hat keine Berechtigung für diese Anwendung!</div>
			</div>
			<div *ngSwitchCase="'LoadInitialData'">
				<mat-spinner></mat-spinner>
				<div>Lade initiale Daten...</div>
			</div>
			<div *ngSwitchCase="'InitialDataLoadFailed'">
				<div class="error-text">Daten konnten nicht geladen werden: {{ stateAndMessage.message }}</div>
			</div>
			<div *ngSwitchCase="'LoggedIn'">Eingeloggt</div>
		</div>
	</div>
</form>

<footer style="display: flex; flex-direction: row; gap: 1em">
	<ng-container *ngIf="configService.backendVersion$ | async as backendVersion">
		<span>
			<span>Version:&nbsp;</span>
			<strong>{{ backendVersion.releaseVersion }}</strong>
		</span>
		<strong>|</strong>
		<strong>BACKEND</strong>
		<span>
			<span>Commit:&nbsp;</span>
			<strong>{{ backendVersion.beGitCommit }}</strong>
		</span>
		<span>
			<span>Build-Zeitpunkt:&nbsp;</span>
			<strong>{{ backendVersion.beBuildDateTime }}</strong>
		</span>
	</ng-container>
	<strong>|</strong>
	<strong>FRONTEND</strong>
	<span>
		Commit:
		<strong>{{ configService.frontendVersion$ | async }}</strong>
	</span>
	<span>
		Build-Zeitpunkt:
		<strong>{{ configService.frontendBuildDate$ | async }}</strong>
	</span>
</footer>
