import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, map, startWith } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { SaveService } from 'src/app/shared/services/save.service';
import { AssetMoniker, AssetService } from './asset.service';

const standardSchluessel = ['logo'];

@Component({
	selector: 'app-assets',
	templateUrl: './assets.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsComponent implements OnInit {
	public readonly isWorking$ = new BehaviorSubject(false);
	public readonly assets$ = new BehaviorSubject<AssetMoniker[]>([]);
	public readonly schluesselOptions$ = this.assets$.pipe(
		map(assets => [...new Set([...assets.map(a => a.schluessel), ...standardSchluessel])])
	);

	public readonly form = new TypedForm<{ schluessel: string; file: File }>({
		schluessel: new UntypedFormControl(null, [Validators.required]),
		file: new UntypedFormControl(null, [Validators.required]),
	});

	public readonly cannotUpload$ = combineLatest([
		this.isWorking$,
		this.form.statusChanges,
	]).pipe(
		map(([isLoading, status]) => isLoading || status !== 'VALID'),
		startWith(true)
	);

	constructor(
		private readonly service: AssetService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {}

	ngOnInit(): void {
		this.refresh();
	}

	refresh(): void {
		this.isWorking$.next(true);
		this.service.getAll$().subscribe({
			next: assets => {
				this.assets$.next(assets);
				this.isWorking$.next(false);
			},
			error: err => {
				this.alert.error('Aktualisierung fehlgeschlagen', err);
				this.isWorking$.next(false);
			},
		});
	}

	upload(): void {
		if (!this.form.valid) return;
		this.isWorking$.next(true);

		this.service.upload$(this.form.typedValue).subscribe({
			next: () => {
				this.alert.success('Upload erfolgreich');
				this.form.reset();
				this.refresh();
			},
			error: err => {
				this.alert.error('Upload fehlgeschlagen', err);
				this.refresh();
			},
		});
	}

	onFileSelected(event: Event) {
		const selectedFile = (event.target as HTMLInputElement).files?.item(0);

		this.form.patchValue({
			file: selectedFile,
		});
	}

	download(asset: AssetMoniker): void {
		this.service.get$(asset.schluessel).subscribe({
			next: result => {
				this.save.saveAs(result.data, result.filename);
				this.alert.success('Download erfolgreich');
			},
			error: err => {
				this.alert.error('Download fehlgeschlagen', err);
				this.refresh();
			},
		});
	}
}
