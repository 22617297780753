import { AfterViewInit, Injectable, Input, OnChanges, Optional, Self, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription } from 'src/app/model';

@Injectable()
export abstract class InputBaseComponent<T> extends BaseComponent implements OnChanges, ControlValueAccessor {
	@Input() metadata?: FieldDescription;
	@Input() label = '';
	@Input() placeholder = '';

	public resolvedLabel = '';

	public resolvedPlaceholder = '';

	public readonly isDisabled$ = new BehaviorSubject(false);
	public readonly isRequired$ = new BehaviorSubject(false);
	protected onChange?: (val: T | null) => void;
	protected onTouchedCallback?: Function;

	constructor(protected readonly ngControl: NgControl) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl) throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}
	writeValue(obj: any): void {
		throw new Error('Method not implemented.');
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.resolvedLabel = this.label || this.metadata?.label || '';
		this.resolvedPlaceholder = this.placeholder || this.metadata?.placeholder || '';
	}

	registerOnChange(fn: (val: T | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled$.next(isDisabled);
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}
}
