<div class="page-header">
	<h1>{{ metadata.plural }}</h1>
	<div>
		<button
			mat-raised-button
			color="accent"
			(click)="refresh()"
			[disabled]="isRefreshing$ | async">
			<mat-icon *ngIf="isRefreshing$ | async | not">refresh</mat-icon>
			<mat-spinner
				*ngIf="isRefreshing$ | async"
				[diameter]="15"
				style="display: inline-block; margin-right: 0.5em"></mat-spinner>
			Aktualisieren
		</button>
		<button
			mat-raised-button
			color="accent"
			(click)="downloadAsExcel(list.dataSource.filteredData)">
			<mat-icon>file_download</mat-icon>
			Als Excel herunterladen
		</button>
		<ng-container *requireRole="['standard', 'anteilsscheingeschaeft']">
			<button
				mat-raised-button
				color="accent"
				[disabled]="numberOfChosen === 0"
				(click)="csvExportAnteilsgeschaeft(list.dataSource.filteredData)"
				*ngIf="
					(activatedRoute.queryParamMap | async)?.get('status') ===
					geschaeftsfallStatus.ZumUebertrag
				">
				<mat-icon>file_download</mat-icon>
				CSV Export Anteilsscheingeschäft
			</button>
		</ng-container>
		<ng-container *requireRole="'standard'">
			<button
				mat-raised-button
				color="accent"
				[disabled]="
					hasNoLuxSammelabwicklungItems(list.dataSource.data) || (isRefreshing$ | async)
				"
				(click)="luxSammelabwicklungStarten()"
				*ngIf="
					(activatedRoute.queryParamMap | async)?.get('status') ===
					geschaeftsfallStatus.ZumInkasso
				">
				<mat-icon>article</mat-icon>
				<mat-spinner
					*ngIf="isRefreshing$ | async"
					[diameter]="20"
					style="display: inline-block; margin-right: 0.5em"></mat-spinner>
				Inkassoverarbeitung LUX starten
			</button>
			<button
				mat-raised-button
				color="primary"
				(click)="sammelverarbeitung()"
				[disabled]="isProcessing$ | async"
				*ngIf="
					(activatedRoute.queryParamMap | async)?.get('status') ===
					geschaeftsfallStatus.ZurBuchung
				">
				<mat-icon *ngIf="isProcessing$ | async | not">payments</mat-icon>
				<mat-spinner
					*ngIf="isProcessing$ | async"
					[diameter]="20"
					style="display: inline-block; margin-right: 0.5em"></mat-spinner>
				Sammelverarbeitung
			</button>
		</ng-container>
	</div>
</div>

<app-geschaeftsfall-list
	#list
	(item$)="onItem($event)"
	(numberOfChosen)="onChosenChange($event)"
	[statusFilter]="(activatedRoute.queryParamMap | async)?.get('status')"
	[enableSelection]="
		(activatedRoute.queryParamMap | async)?.get('status') ===
		geschaeftsfallStatus.ZumUebertrag
	"></app-geschaeftsfall-list>
