<div class="flex-column">
	<div class="page-header">
		<h1>Schnellnavigation</h1>
	</div>

	<form (ngSubmit)="goToGattung()" class="flex-row">
		<app-gattung-choose
			[formControl]="chooseGattungControl"
			label="Gattung"
			placeholder="Suche Gattungen..."></app-gattung-choose>
		<div style="flex: auto 0 0">
			<button mat-raised-button color="primary" type="submit" [disabled]="!chooseGattungControl.value">
				<mat-icon>visibility</mat-icon>
				Ansicht
			</button>
		</div>
	</form>

	<form (ngSubmit)="goToFinanzinstitut()" class="flex-row">
		<app-finanzinstitut-choose [finanzinstitutRolle]="finanzinstitutRolle" [formControl]="chooseFinanzinstitutControl"></app-finanzinstitut-choose>
		<div style="flex: auto 0 0">
			<button mat-raised-button color="primary" type="submit" [disabled]="!chooseFinanzinstitutControl.value">
				<mat-icon>visibility</mat-icon>
				Ansicht
			</button>
		</div>
	</form>

	<form (ngSubmit)="goToEinreichung()" class="flex-row" *ngIf="showEinreichung$ | async">
		<app-text-input
			[formControl]="einreichungNummerControl"
			label="Einreichung"
			placeholder="Einreichungsnummer"></app-text-input>
		<div style="flex: auto 0 0">
			<button mat-raised-button color="primary" type="submit" [disabled]="!einreichungNummerControl.value">
				<mat-icon>visibility</mat-icon>
				Ansicht
			</button>
		</div>
	</form>
</div>
