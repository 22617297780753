import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

let i = 0;

@Injectable()
export class LogHttpInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		i++;
		const myIndex = i.toFixed(0);

		performance.mark(myIndex + 'START');
		return next.handle(req).pipe(
			tap(event => {
				switch (event.type) {
					case HttpEventType.Sent:
						console.time(myIndex.toString());
						const body = req.url.endsWith('/login') ? '[REDACTED]' : req.body;
						console.log(
							`[${myIndex}: SENT] ${req.method} ${req.url}`,
							JSON.parse(JSON.stringify(body)),
							req.headers
						);
						break;
					case HttpEventType.Response:
						performance.mark(myIndex + 'END');
						const measure = performance.measure(myIndex, myIndex + 'START', myIndex + 'END');
						const text =
							`[${myIndex}: ${event.status} ${event.statusText} in ` +
							`${measure.duration.toFixed(0)}ms] ${req.method} ${req.url}`;

						if (event.ok) {
							console.log(text, event.body);
						} else {
							console.log(text, event);
						}

						break;
					default:
						console.log(`[${myIndex}: ${event.type}] ${req.method} ${req.url}`, event);
						break;
				}
			})
		);
	}
}
