import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { evaluateFreigabe } from 'src/app/model';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { SimpleDialogContent } from 'src/app/shared/dialogs/simple-dialog/simple-dialog.component';
import { BackendQueryResult, queryBackend } from 'src/app/utils';
import { EntityActionsService } from '../../entity-actions.service';
import { StammdatenStatus } from '../../model';
import { QuickInfoPopupService } from '../../quick-info-popup.service';
import { FinanzinstitutService } from '../finanzinstitut.service';
import { Finanzinstitut, finanzinstitutMetadata } from '../model';

@Component({
	selector: 'app-finanzinstitut-view-page',
	templateUrl: './finanzinstitut-view-page.component.html',
	styleUrls: ['./finanzinstitut-view-page.component.scss'],
})
export class FinanzinstitutViewPageComponent extends BaseComponent {
	public readonly metadata = finanzinstitutMetadata;
	public readonly stammdatenStatus = StammdatenStatus;
	/*
  1. Seite wird initiert und this.route.paramMap gibt den Wert { id: 123 } aus
  2. navigatedTo$ agiert auf den Wert von this.route.paramMap und gibt 123 aus
  3. finanzinstitutWrapper$ agiert auf den Wert 123 aus navigatedTo$ und gibt Finanzinstitut (im Wrapper) aus

  Jetzt soll finanzinstitutWrapper$ bei auf navigatedTo$ und auf reloadRequested$ hören
*/

	public readonly reloadRequested$ = new Subject<void>();

	/** wenn emittet, soll die Seite durchladen */
	public readonly navigatedTo$: Observable<string> = this.route.paramMap.pipe(
		map(params => params.get('id') as string)
	);

	public readonly pageContent$ = new BehaviorSubject<BackendQueryResult<Finanzinstitut>>({ isLoading: true });

	public readonly freigabeInfo$ = this.pageContent$.pipe(
		map(w => {
			let freigabeFehler: string[];
			const finanzinstitut = w?.content;
			if (!finanzinstitut) {
				return {
					freigabeFehler: [],
					showFreigabeAnfordern: false,
					disableFreigabeAnfordern: false,
					showFreigeben: false,
					disableFreigeben: false,
					showInaktivieren: false,
				};
			}

			switch (finanzinstitut.status) {
				case StammdatenStatus.InArbeit:
					freigabeFehler = evaluateFinanzinstitut(finanzinstitut);
					return {
						showFreigabeAnfordern: true,
						showFreigeben: false,
						showInaktivieren: true,
						freigabeFehler,
						disableFreigabeAnfordern: freigabeFehler.length > 0,
						disableFreigeben: false,
					};
				case StammdatenStatus.ZurFreigabe:
					freigabeFehler = evaluateVieraugenprinzip(finanzinstitut, this.currentUserService.username);
					return {
						showFreigeben: true,
						showFreigabeAnfordern: false,
						showInaktivieren: true,
						freigabeFehler,
						disableFreigabeAnfordern: false,
						disableFreigeben: freigabeFehler.length > 0,
					};
				case StammdatenStatus.Aktiv:
					return {
						showFreigeben: false,
						showFreigabeAnfordern: false,
						showInaktivieren: true,
						freigabeFehler: [],
						disableFreigabeAnfordern: false,
						disableFreigeben: false,
					};
				case StammdatenStatus.Inaktiv:
					return {
						showFreigeben: false,
						showFreigabeAnfordern: false,
						showInaktivieren: false,
						freigabeFehler: [],
						disableFreigabeAnfordern: false,
						disableFreigeben: false,
					};
				default:
					return {
						showFreigabeAnfordern: false,
						showFreigeben: false,
						showInaktivieren: false,
						freigabeFehler: [],
						disableFreigabeAnfordern: false,
						disableFreigeben: false,
					};
			}
		})
	);

	constructor(
		public readonly service: FinanzinstitutService,
		private readonly route: ActivatedRoute,
		public readonly quickInfo: QuickInfoPopupService,
		private readonly currentUserService: CurrentUserService,
		private readonly entityActionsService: EntityActionsService,
		private readonly simpleDialog: SimpleDialogService
	) {
		super();

		const loadFinanzinstitutWithId$ = merge(
			this.navigatedTo$,
			this.reloadRequested$.pipe(
				withLatestFrom(this.navigatedTo$),
				map(([_, id]) => id)
			)
		);

		this.registerSubscription(
			loadFinanzinstitutWithId$
				.pipe(queryBackend(id => this.service.loadSingle$(id)))
				.subscribe(content => this.pageContent$.next(content))
		);
	}

	freigabeAnfordern(): void {
		const finanzinstitut = this.pageContent$.value.content;
		if (!finanzinstitut) return;

		this.entityActionsService.freigabeAnfordern({
			service: this.service,
			entity: finanzinstitut,
			name: finanzinstitut.finanzinstitutsname,
			onCompleted: () => this.reloadRequested$.next(),
		});
	}

	freigeben(): void {
		const finanzinstitut = this.pageContent$.value.content;
		if (!finanzinstitut) return;

		this.entityActionsService.freigeben({
			service: this.service,
			entity: finanzinstitut,
			name: finanzinstitut.finanzinstitutsname,
			onCompleted: () => this.reloadRequested$.next(),
		});
	}

	inaktivieren(): void {
		const finanzinstitut = this.pageContent$.value.content;
		if (!finanzinstitut) return;

		this.registerSubscription(
			this.service.finanzinstitutAktiveNutzungInfo$(finanzinstitut.id).subscribe(info => {
				if (!info) return;
				if (info.inActiveUse) {
					const message = this.service.assembleOpenItemMessage(info);
					const config: SimpleDialogContent = {
						title: 'Info',
						message: message,
						buttonsLeft: [],
						buttonsRight: [{ label: 'Ok', color: 'primary' }],
					};
					this.simpleDialog.open(config);
				} else {
					this.entityActionsService.inaktivieren({
						service: this.service,
						entity: finanzinstitut,
						name: finanzinstitut.finanzinstitutsname,
						onCompleted: () => this.reloadRequested$.next(),
					});
				}
			})
		);
	}
}

function evaluateFinanzinstitut(finanzinstitut: Finanzinstitut): string[] {
	const probleme = evaluateFreigabe(finanzinstitut, finanzinstitutMetadata);

	if (finanzinstitut.land === 'DE' && !finanzinstitut.bundesland)
		probleme.push('Bundesland muss für Deutschland ausgefüllt werden!');

	if (finanzinstitut.land !== 'DE' && finanzinstitut.bundesland)
		probleme.push('Bundesland darf nur für Deutschland ausgefüllt werden!');

	return probleme;
}

function evaluateVieraugenprinzip(finanzinstitut: Finanzinstitut, currentUser: string): string[] {
	let probleme: string[] = [];

	if (currentUser?.toLocaleLowerCase() === finanzinstitut.geaendertVon?.toLocaleLowerCase()) {
		probleme = ['Vier-Augen-Prinzip verletzt: Freigeber darf nicht gleich dem letzter Bearbeiter sein!'];
	}

	return probleme;
}

export interface FreigabeInfo {
	freigabeFehler: string[];
	showFreigabeAnfordern: boolean;
	disableFreigabeAnfordern: boolean;
	showFreigeben: boolean;
	disableFreigeben: boolean;
	showInaktivieren: boolean;
}
