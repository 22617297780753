import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, debounceTime, map } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription } from 'src/app/model';
import { TypedControl } from 'src/app/shared/forms/typed-form';
import { coalesce } from 'src/app/utils';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from '../kuerzel-bezeichnung-referenzlist.service';
import { KuerzelBezeichnungReferenz } from '../model';

@Component({
	selector: 'app-postleitzahl-input',
	templateUrl: './postleitzahl-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./postleitzahl-input.component.scss'],
})
export class PostleitzahlInputComponent extends BaseComponent implements OnInit {
	@Input() metadata?: FieldDescription;
	@Input() label = '';
	@Input() placeholder = '';
	@Input() referenzlist?: string;
	@Input() set land(value: string) {
		this.isLandDeutschland$.next(value === 'DE');
	}
	@Output() userEnteredPostleitzahl = new EventEmitter<KuerzelBezeichnungReferenz>();

	get resolvedLabel(): string {
		return coalesce(this.label, this.metadata?.label, '');
	}

	get resolvedPlaceholder(): string {
		return coalesce(this.placeholder, this.metadata?.label, '');
	}

	private readonly isLandDeutschland$ = new BehaviorSubject(false);

	public readonly textControl = new TypedControl<string>('');
	public readonly showClearButton$ = this.textControl.value$.pipe(map(v => !!v));
	public service: KuerzelBezeichnungReferenzlistService;

	private onChange?: (val: string | null) => void;
	private onTouchedCallback?: Function;

	public readonly options$ = new BehaviorSubject<KuerzelBezeichnungReferenz[]>([]);
	public readonly filteredOptions$ = combineLatest([
		this.textControl.value$,
		this.options$,
		this.isLandDeutschland$,
	]).pipe(
		map(([text, options, isLandDeutschland]) => {
			if (!isLandDeutschland) return [];

			if (!text) return options;
			text = text.toLocaleUpperCase();
			return options.filter(ref => ref.kuerzel.toLocaleUpperCase().startsWith(text)).slice(0, 10);
		})
	);
	public readonly isDisabled$ = new BehaviorSubject(false);
	public readonly isRequired$ = new BehaviorSubject(false);

	constructor(
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		@Optional() @Self() public readonly ngControl: NgControl // no idea why @Optional...
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl) throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
		this.service = serviceFactory.getService('postleitzahl');
	}

	ngOnInit(): void {
		this.registerSubscription(
			this.service.list$.subscribe(list => {
				this.options$.next(list.filter(l => l.status === 'Aktiv'));
			})
		);
	}

	ngAfterViewInit(): void {
		if (!this.ngControl.control) {
			console.error('no control on ngControl! Did you add formControlName or formControl?', this);
			throw new Error('no control on ngControl! Did you add formControlName or formControl?');
		}
		const hostControl = this.ngControl.control;

		const onChange = this.onChange;
		if (!onChange) {
			throw new Error('Missing onChange!');
		}

		const reevaluateIsRequired = () => {
			const shouldBe = hostControl.hasValidator(Validators.required);
			if (shouldBe !== this.isRequired$.value) {
				this.isRequired$.next(shouldBe);
			}
		};

		reevaluateIsRequired();

		this.registerSubscription(
			this.textControl.value$.pipe(debounceTime(400)).subscribe(textValue => {
				reevaluateIsRequired();
				onChange(textValue ?? '');
				const errorsFromParentValidators = hostControl.validator ? hostControl.validator(hostControl) : null;
				this.textControl.setErrors(errorsFromParentValidators);
			})
		);
	}

	/** called when bound FormControl gets input */
	writeValue(val: string | null | undefined): void {
		this.textControl.reset(val, { emitEvent: false });
	}

	registerOnChange(fn: (val: string | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled$.next(isDisabled);
		if (isDisabled) {
			this.textControl.disable();
		} else {
			this.textControl.enable();
		}
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	clear(): void {
		this.textControl.reset();
	}

	maybeEmit(): void {
		if (!this.isLandDeutschland$.value) return;

		const textValue = this.textControl.typedValue?.toLocaleUpperCase();
		if (!textValue) return;
		const option = this.options$.value.find(v => v.kuerzel.toLocaleUpperCase() === textValue);
		if (option) this.userEnteredPostleitzahl.emit(option);
	}
}
