import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	Optional,
	Self,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { BehaviorSubject, map, shareReplay, take } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription, ReferenzlistFieldDescription } from 'src/app/model';
import { TypedControl } from 'src/app/shared/forms/typed-form';
import { coalesce } from 'src/app/utils';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from '../kuerzel-bezeichnung-referenzlist.service';
import { KuerzelBezeichnungReferenz } from '../model';

@Component({
	selector: 'app-kuerzel-bezeichnung-choose',
	templateUrl: './kuerzel-bezeichnung-choose.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuerzelBezeichnungChooseComponent extends BaseComponent implements OnInit {
	@Input() metadata?: FieldDescription;
	@Input() label = '';
	@Input() placeholder = '';
	@Input() referenzlist?: string;
	@Input() panelClass: string = 'filter-panel';

	get resolvedLabel(): string {
		return coalesce(this.label, this.metadata?.label, '');
	}

	get resolvedPlaceholder(): string {
		return coalesce(this.placeholder, this.metadata?.label, '');
	}

	public readonly selectControl = new TypedControl<string>(null);
	public readonly selectControlNotEmpty$ = this.selectControl.value$.pipe(
		map(v => !!v),
		shareReplay(1)
	);
	public service?: KuerzelBezeichnungReferenzlistService;

	private onChange?: (val: string | null) => void;
	private onTouchedCallback?: Function;

	public readonly options$ = new BehaviorSubject<KuerzelBezeichnungReferenz[]>([]);
	public readonly isRequired$ = new BehaviorSubject(false);

	constructor(
		public readonly serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		@Optional() @Self() public readonly ngControl: NgControl // no idea why @Optional...
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl)
			throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;

		this.selectControlNotEmpty$.pipe(take(1)).subscribe(); // wenn ich die Zeile entferne, funktioniert die Reset-Taste nicht mehr :(
	}

	ngOnInit(): void {
		if (this.referenzlist) {
			this.service = this.serviceFactory.getService(this.referenzlist);
		} else {
			const referenzlistFromMetadata = (this.metadata as ReferenzlistFieldDescription)
				?.referenzlist;
			if (referenzlistFromMetadata) {
				this.service = this.serviceFactory.getService(referenzlistFromMetadata);
			} else {
				throw new Error('Missing referenzlist and it is not set on metadata!');
			}
		}

		this.registerSubscription(
			this.service.list$.subscribe(list => {
				this.options$.next(list.filter(l => l.status === 'Aktiv'));
			})
		);
	}

	ngAfterViewInit(): void {
		if (!this.ngControl.control) {
			console.error(
				'no control on ngControl! Did you add formControlName or formControl?',
				this
			);
			throw new Error(
				'no control on ngControl! Did you add formControlName or formControl?'
			);
		}
		const hostControl = this.ngControl.control;

		const onChange = this.onChange;
		if (!onChange) {
			throw new Error('Missing onChange!');
		}

		this.registerSubscription(
			this.selectControl.value$.subscribe(textValue => {
				onChange(textValue ?? '');
				this.isRequired$.next(hostControl.hasValidator(Validators.required));
				const errorsFromParentValidators = hostControl.validator
					? hostControl.validator(hostControl)
					: null;
				this.selectControl.setErrors(errorsFromParentValidators);
			})
		);
	}

	/** called when bound FormControl gets input */
	writeValue(val: string | null | undefined): void {
		this.selectControl.setValue(val);
	}

	registerOnChange(fn: (val: string | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.selectControl.disable();
		} else {
			this.selectControl.enable();
		}
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	clear(): void {
		this.selectControl.reset();
	}
}
