<span
	class="data-item-title"
	*ngIf="field?.description; else justTitle"
	[matTooltip]="field!.description!">
	<!-- <mat-icon>help_outline</mat-icon> -->
	{{ resolvedTitle }}
</span>
<span class="data-item-content">
	<ng-content></ng-content>
</span>

<ng-template #justTitle>
	<span class="data-item-title data-item-title-no-icon">{{ resolvedTitle }}</span>
</ng-template>
