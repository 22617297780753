import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { evaluateFreigabe } from 'src/app/model';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { SimpleDialogContent } from 'src/app/shared/dialogs/simple-dialog/simple-dialog.component';
import { BackendQueryResult, queryBackend } from 'src/app/utils';
import { EntityActionsService } from '../../entity-actions.service';
import { StammdatenStatus } from '../../model';
import { QuickInfoPopupService } from '../../quick-info-popup.service';
import { GattungService } from '../gattung.service';
import { DotationInfo, dotationInfoMetadata, Gattung, gattungMetadata } from '../model';

@Component({
	selector: 'app-gattung-view-page',
	templateUrl: './gattung-view-page.component.html',
	styleUrls: ['./gattung-view-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungViewPageComponent extends BaseComponent {
	public readonly metadata = gattungMetadata;
	public readonly dotationInfoMetadata = dotationInfoMetadata;
	public readonly stammdatenStatus = StammdatenStatus;
	public readonly reloadRequested$ = new Subject<void>();

	/** wenn emittet, soll die Seite durchladen */
	public readonly navigatedTo$: Observable<string> = this.route.paramMap.pipe(
		map(params => params.get('id') as string)
	);

	public readonly pageContent$ = new BehaviorSubject<BackendQueryResult<Gattung>>({ isLoading: true });

	public readonly freigabeInfo$ = this.pageContent$.pipe(
		map(w => {
			let freigabeFehler: string[];
			const gattung = w?.content;
			if (!gattung) {
				return {
					freigabeFehler: [],
					showFreigabeAnfordern: false,
					disableFreigabeAnfordern: false,
					showFreigeben: false,
					disableFreigeben: false,
					showInaktivieren: false,
				};
			}

			switch (gattung.status) {
				case StammdatenStatus.InArbeit:
					freigabeFehler = evaluateGattung(gattung);
					return {
						showFreigabeAnfordern: true,
						showFreigeben: false,
						showInaktivieren: true,
						freigabeFehler,
						disableFreigabeAnfordern: freigabeFehler.length > 0,
						disableFreigeben: false,
					};
				case StammdatenStatus.ZurFreigabe:
					freigabeFehler = evaluateVieraugenprinzip(gattung, this.currentUserService.username);
					return {
						showFreigeben: true,
						showFreigabeAnfordern: false,
						showInaktivieren: true,
						freigabeFehler,
						disableFreigabeAnfordern: false,
						disableFreigeben: freigabeFehler.length > 0,
					};
				case StammdatenStatus.Aktiv:
					return {
						showFreigeben: false,
						showFreigabeAnfordern: false,
						showInaktivieren: true,
						freigabeFehler: [],
						disableFreigabeAnfordern: false,
						disableFreigeben: false,
					};
				case StammdatenStatus.Inaktiv:
					return {
						showFreigeben: false,
						showFreigabeAnfordern: false,
						showInaktivieren: false,
						freigabeFehler: [],
						disableFreigabeAnfordern: false,
						disableFreigeben: false,
					};
				default:
					return {
						showFreigabeAnfordern: false,
						showFreigeben: false,
						showInaktivieren: false,
						freigabeFehler: [],
						disableFreigabeAnfordern: false,
						disableFreigeben: false,
					};
			}
		})
	);
	readonly dotationInfo$ = new BehaviorSubject<DotationInfoErweitert | null>(null);

	constructor(
		public readonly service: GattungService,
		private readonly route: ActivatedRoute,
		public readonly quickInfo: QuickInfoPopupService,
		private readonly currentUserService: CurrentUserService,
		private readonly entityActionsService: EntityActionsService,
		private readonly simpleDialog: SimpleDialogService
	) {
		super();

		const loadGattungWithId$ = merge(
			this.navigatedTo$,
			this.reloadRequested$.pipe(
				withLatestFrom(this.navigatedTo$),
				map(([_, id]) => id)
			)
		);

		this.registerSubscription(
			loadGattungWithId$
				.pipe(queryBackend(id => this.service.loadSingle$(id)))
				.subscribe(content => this.pageContent$.next(content))
		);

		this.registerSubscription(
			combineLatest([
				loadGattungWithId$.pipe(switchMap(id => this.service.dotationInfo$(id))),
				this.pageContent$,
			]).subscribe(([dotationInfo, pageContent]) => {
				if (!dotationInfo) {
					this.dotationInfo$.next(null);
					return;
				}

				const gattung = pageContent.content;
				if (!gattung) return;

				const d: DotationInfoErweitert = {
					...dotationInfo,
					anzahlRest: (gattung.anzahlAnfang ?? 0) - dotationInfo.anzahlEingeloest,
					wertRest:
						((gattung.anzahlAnfang ?? 0) - dotationInfo.anzahlEingeloest) *
						(gattung.stueckelungEinzelwert ?? 0),
				};
				this.dotationInfo$.next(d);
			})
		);
	}

	freigabeAnfordern(): void {
		const gattung = this.pageContent$.value.content;
		if (!gattung) return;

		this.entityActionsService.freigabeAnfordern({
			service: this.service,
			entity: gattung,
			name: gattung.gattungsbezeichnung,
			onCompleted: () => this.reloadRequested$.next(),
		});
	}

	freigeben(): void {
		const gattung = this.pageContent$.value.content;
		if (!gattung) return;

		this.entityActionsService.freigeben({
			service: this.service,
			entity: gattung,
			name: gattung.gattungsbezeichnung,
			onCompleted: () => this.reloadRequested$.next(),
		});
	}

	inaktivieren(): void {
		const gattung = this.pageContent$.value.content;
		if (!gattung) return;

		this.registerSubscription(
			this.service.gattungAktiveNutzungInfo$(gattung.id).subscribe(info => {
				if (!info) return;
				if (info.inActiveUse) {
					const message = this.service.assembleOpenItemMessage(info);
					const config: SimpleDialogContent = {
						title: 'Info',
						message: message,
						buttonsLeft: [],
						buttonsRight: [{ label: 'Ok', color: 'primary' }],
					};
					this.simpleDialog.open(config);
				} else {
					this.entityActionsService.inaktivieren({
						service: this.service,
						entity: gattung,
						name: gattung.gattungsbezeichnung,
						onCompleted: () => this.reloadRequested$.next(),
					});
				}
			})
		);
	}
}

function evaluateGattung(gattung: Gattung): string[] {
	const probleme = evaluateFreigabe(gattung, gattungMetadata);

	if (!gattung.steuerpflichtigerAnteil && gattung.steuerpflichtigerAnteil !== 0 && !gattung.istSteuerfreieGattung) {
		probleme.push('Das Feld "Steuerpflichtiger Anteil" muss gepflegt sein!');
	}

	return probleme;
}

function evaluateVieraugenprinzip(gattung: Gattung, currentUser: string): string[] {
	let probleme: string[] = [];

	if (currentUser?.toLocaleLowerCase() === gattung.geaendertVon?.toLocaleLowerCase()) {
		probleme = ['Vier-Augen-Prinzip verletzt: Freigeber darf nicht gleich dem letzter Bearbeiter sein!'];
	}

	return probleme;
}

export interface FreigabeInfo {
	freigabeFehler: string[];
	showFreigabeAnfordern: boolean;
	disableFreigabeAnfordern: boolean;
	showFreigeben: boolean;
	disableFreigeben: boolean;
	showInaktivieren: boolean;
}

export interface DotationInfoErweitert extends DotationInfo {
	anzahlRest: number;
	wertRest: number;
}
