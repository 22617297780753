<div class="flex-column">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		[trackBy]="trackBy"
		matSort="positionnummer"
		matSortActive="positionnummer"
		matSortDirection="asc">
		<ng-container matColumnDef="positionnummer">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header style="width: 3em">
				Nr.
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 1em">
				{{ element.positionnummer }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="isin" style="margin-left: 3em">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header
				style="width: 150px">
				ISIN
			</th>
			<td mat-cell *matCellDef="let element">{{ element.isin }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="gattungId">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header="gattungsbezeichnung">
				{{ metadata.fields.gattungId.label }}
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				[matTooltip]="element.gattungsbezeichnung"
				class="text-hide-overflow">
				<button
					*ngIf="element.gattungId"
					mat-icon-button
					color="accent"
					(click.stop)="quickInfo.showGattung(element.gattungId)">
					<mat-icon>preview</mat-icon>
				</button>
				{{ element.gattungsbezeichnung }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="kuponnummerFaelligkeit">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header
				style="width: 7em"
				[matTooltip]="gattungMetadata.fields.kuponnummerFaelligkeit.label!">
				<div class="text-hide-overflow">Kup.</div>
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				[matTooltip]="element.kuponnummerFaelligkeit"
				class="text-hide-overflow">
				{{ element.kuponnummerFaelligkeit }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="emittent">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ gattungMetadata.fields.emittentId.label }}
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				[matTooltip]="element.emittent"
				class="text-hide-overflow">
				<button
					*ngIf="element.gattungId"
					mat-icon-button
					color="accent"
					(click.stop)="quickInfo.showFinanzinstitut(element.emittentId)">
					<mat-icon>preview</mat-icon>
				</button>
				{{ element.emittent }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="gesamtwert">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header
				style="width: 15em"
				class="text-right">
				{{ metadata.fields.gesamtwert.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 1em">
				{{ element.gesamtwert | efaNumber }} EUR
			</td>
			<td mat-footer-cell *matFooterCellDef style="text-align: right; padding-right: 1em">
				{{ gesamtwertEUR$ | async | efaNumber }} EUR
			</td>
		</ng-container>

		<ng-container matColumnDef="anteil">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header
				style="width: 8em"
				class="text-right">
				{{ metadata.fields.anteil.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 1em">
				{{ element.anteil | efaNumber : 0 }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="anzahl">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header
				style="width: 8em"
				class="text-right">
				{{ metadata.fields.anzahl.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 1em">
				{{ element.anzahl | number : '1.0-0' : 'de' }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="nominalwert">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				Wert
			</th>
			<td mat-cell *matCellDef="let element">{{ element.nominalwert }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 8em; text-align: right"
				class="action-column">
				<button
					mat-icon-button
					*ngIf="positionen$.value.length > 0"
					color="primary"
					(click.stop)="removeAll()"
					style=""
					matTooltip="Alle Positionen löschen">
					<mat-icon>playlist_remove</mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let element" class="action-column">
				<button mat-icon-button><mat-icon>edit</mat-icon></button>
				<button mat-icon-button color="primary" (click.stop)="remove(element)">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="open(row)"></tr>

		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>
	<div class="flex-row" style="margin-bottom: 0.5em">
		<span style="flex: 1"></span>
		<button mat-raised-button color="primary" (click)="open()" style="flex: 15em 0 0">
			<mat-icon>add</mat-icon>
			Neue Position
		</button>
	</div>
</div>
