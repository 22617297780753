<form [formGroup]="form" class="flex-column" (ngSubmit)="save()">
	<div class="page-header">
		<div>
			<a
				mat-raised-button
				color="accent"
				[routerLink]="metadata.routing.view!.url(id)"
				*ngIf="id$ | async as id">
				<mat-icon>visibility</mat-icon>
				Zurück zur Ansicht
			</a>
			<a mat-raised-button color="accent" [routerLink]="metadata.routing.list!.url">
				<mat-icon>list</mat-icon>
				Zurück zur Liste
			</a>
		</div>
		<div>
			<button
				mat-raised-button
				color="primary"
				type="submit"
				[disabled]="
					(cannotSave$ | async) ||
					(openItemsMessage$ | async) ||
					(duplicateYearVorabpauschaleMessage$ | async)
				"
				[matTooltip]="(saveButtonTooltip$ | async)!">
				<mat-icon>save</mat-icon>
				Speichern
			</button>
		</div>
	</div>

	<div class="page-header">
		<h1>
			<span *ngIf="!(id$ | async)">Neue&nbsp;Gattung</span>
			<span *ngIf="id$ | async">
				Gattung {{ form.typedValue.isin }} {{ form.typedValue.gattungsbezeichnung }} (
				{{ form.typedValue.status }} )
			</span>
		</h1>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<mat-error>{{ form | fieldErrorMessage$ | async }}</mat-error>
		<span class="mat-error">{{ openItemsMessage$ | async }}</span>
		<span class="mat-error">{{ duplicateYearVorabpauschaleMessage$ | async }}</span>

		<div class="flex-row">
			<app-finanzinstitut-choose
				[finanzinstitutRolle]="finanzinstitutRolle"
				style="flex: 1"
				formControlName="emittentId"
				label="Emittent"></app-finanzinstitut-choose>
			<span style="flex: 1"></span>
		</div>

		<div class="flex-row">
			<app-text-input
				style="flex: 1"
				formControlName="gattungsbezeichnung"
				[metadata]="metadata.fields.gattungsbezeichnung"></app-text-input>
			<app-text-input
				style="flex: 1"
				formControlName="isin"
				[metadata]="metadata.fields.isin"
				[maxLength]="12"></app-text-input>
			<app-text-input
				style="flex: 1"
				formControlName="wkn"
				[metadata]="metadata.fields.wkn"
				[maxLength]="6"></app-text-input>
			<div style="flex: 1; margin-top: 2em; white-space: nowrap">
				<mat-radio-group
					aria-label="Select an option"
					formControlName="wkz"
					style="flex: 1; margin-left: 2em">
					<mat-radio-button [value]="true" style="margin-right: 1em">
						Mantel
					</mat-radio-button>
					<mat-radio-button [value]="false">Kupon</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<div class="flex-row">
			<app-kuerzel-bezeichnung-choose
				formControlName="nominalwaehrung"
				[metadata]="metadata.fields.nominalwaehrung"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>
			<app-kuerzel-bezeichnung-choose
				formControlName="land"
				[metadata]="metadata.fields.land"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>
			<app-kuerzel-bezeichnung-choose
				formControlName="bundesland"
				[metadata]="metadata.fields.bundesland"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>
			<app-text-input
				style="flex: 1"
				formControlName="kuponnummerFaelligkeit"
				[metadata]="metadata.fields.kuponnummerFaelligkeit"></app-text-input>
		</div>

		<div class="flex-row">
			<app-date-input
				style="flex: 1"
				formControlName="zahlbarkeitstag"
				[metadata]="metadata.fields.zahlbarkeitstag"></app-date-input>

			<app-numeric-input
				style="flex: 1"
				formControlName="bruttowert"
				[symbol]="form.controls.nominalwaehrung.valueChanges | async"
				[metadata]="metadata.fields.bruttowert"></app-numeric-input>
			<app-numeric-input
				style="flex: 1"
				formControlName="bruttowertEuro"
				symbol="EUR"
				[metadata]="metadata.fields.bruttowertEuro"></app-numeric-input>
			<app-numeric-input
				style="flex: 1"
				formControlName="steuerpflichtigerAnteil"
				symbol="EUR"
				[metadata]="metadata.fields.steuerpflichtigerAnteil"></app-numeric-input>
			<!-- <app-numeric-input
				style="flex: 1"
				formControlName="nettoKapst"
				[symbol]="form.controls.nominalwaehrung.valueChanges | async"
				[metadata]="metadata.fields.nettoKapst"></app-numeric-input> -->
		</div>

		<!-- <div class="flex-row"> -->
		<!-- <app-numeric-input
				style="flex: 1"
				formControlName="nettoZast"
				[symbol]="form.controls.nominalwaehrung.valueChanges | async"
				[metadata]="metadata.fields.nettoZast"></app-numeric-input> -->
		<!-- </div> -->

		<div class="flex-row">
			<app-kuerzel-bezeichnung-choose
				formControlName="investmentsteuerart"
				[metadata]="metadata.fields.investmentsteuerart"
				panelClass="status-filter-panel-400"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>
			<app-numeric-input
				style="flex: 1"
				formControlName="zinswert"
				[symbol]="form.controls.nominalwaehrung.valueChanges | async"
				[metadata]="metadata.fields.zinswert"></app-numeric-input>
			<app-numeric-input
				style="flex: 1"
				formControlName="dividendenwert"
				[symbol]="form.controls.nominalwaehrung.valueChanges | async"
				[metadata]="metadata.fields.dividendenwert"></app-numeric-input>
			<div class="flex-row" style="margin: 1em 0 0 1em">
				<mat-checkbox formControlName="istSteuerfreieGattung" style="flex: 1">
					{{ metadata.fields.istSteuerfreieGattung.label }}
				</mat-checkbox>
				<mat-checkbox formControlName="kestVonEmittentenAbgefuehrt" style="flex: 1">
					{{ metadata.fields.kestVonEmittentenAbgefuehrt.label }}
				</mat-checkbox>
			</div>
		</div>

		<!-- <div class="flex-row">
			<app-numeric-input
				style="flex: 1"
				formControlName="basisertrag"
				[symbol]="form.controls.nominalwaehrung.valueChanges | async"
				[metadata]="metadata.fields.basisertrag"></app-numeric-input>
		</div> -->

		<div class="flex-row">
			<app-text-input
				style="flex: 1"
				formControlName="dotationskonto"
				[metadata]="metadata.fields.dotationskonto"></app-text-input>
			<app-date-input
				style="flex: 1"
				formControlName="umstellungAusgeschuettetAb"
				[metadata]="metadata.fields.umstellungAusgeschuettetAb"></app-date-input>
		</div>

		<div class="flex-row">
			<mat-checkbox formControlName="istInkasso" style="flex: 1">
				{{ metadata.fields.istInkasso.label }}
			</mat-checkbox>
			<span style="flex: 3"></span>
		</div>

		<div class="flex-row">
			<mat-checkbox formControlName="istLiquidiert" style="flex: 1">
				{{ metadata.fields.istLiquidiert.label }}
			</mat-checkbox>
			<span style="flex: 1"></span>
		</div>
	</div>

	<div class="mat-elevation-z5 flex-row" style="padding: 1em">
		<mat-checkbox formControlName="istThesaurierenderFonds">
			{{ metadata.fields.istThesaurierenderFonds.label }}
		</mat-checkbox>
		<ng-container *ngIf="form.controls.istThesaurierenderFonds.value">
			<app-numeric-input
				formControlName="akkumulierteErtraege"
				symbol="EUR"
				[metadata]="metadata.fields.akkumulierteErtraege"></app-numeric-input>
			<app-numeric-input
				formControlName="zwischengewinn"
				symbol="EUR"
				[metadata]="metadata.fields.zwischengewinn"></app-numeric-input>
		</ng-container>
	</div>

	<div class="mat-elevation-z5 flex-row" style="padding: 1em">
		<mat-checkbox formControlName="istFusioniert">Ist fusioniert</mat-checkbox>
		<ng-container *ngIf="form.controls.istFusioniert?.value">
			<app-text-input
				style="flex: 1"
				formControlName="fusioniertMit"
				[metadata]="metadata.fields.fusioniertMit"
				[maxLength]="6"></app-text-input>
			<app-date-input
				style="flex: 1"
				formControlName="fusioniertAb"
				[metadata]="metadata.fields.fusioniertAb"></app-date-input>
			<app-numeric-input
				style="flex: 1"
				formControlName="fusionUmrechnungsfaktor"
				[metadata]="metadata.fields.fusionUmrechnungsfaktor"></app-numeric-input>
		</ng-container>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<div class="flex-row">
			<mat-checkbox formControlName="hatDotationen">Hat Dotationen</mat-checkbox>
			<ng-container *ngIf="form.controls.hatDotationen?.value">
				<app-numeric-input
					style="flex: 1"
					formControlName="stueckelungEinzelwert"
					[symbol]="form.controls.nominalwaehrung.valueChanges | async"
					[metadata]="metadata.fields.stueckelungEinzelwert"></app-numeric-input>
				<app-numeric-input
					style="flex: 1"
					formControlName="anzahlAnfang"
					[metadata]="metadata.fields.anzahlAnfang"></app-numeric-input>
				<app-numeric-input
					style="flex: 1"
					formControlName="wertAnfang"
					[symbol]="form.controls.nominalwaehrung.valueChanges | async"
					[metadata]="metadata.fields.wertAnfang"></app-numeric-input>
			</ng-container>
		</div>
	</div>

	<div class="mat-elevation-z5 flex-row" style="padding: 1em">
		<app-gattung-edit-ausschuettungsdatum
			style="flex: 2"
			formControlName="ausschuettungsdatum"></app-gattung-edit-ausschuettungsdatum>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<span class="mat-error">{{ duplicateYearVorabpauschaleMessage$ | async }}</span>
		<app-gattung-edit-vorabpauschale
			style="flex: 2"
			formControlName="zuVersteuerndeVorabpauschale"></app-gattung-edit-vorabpauschale>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<div class="flex-row">
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.geaendertVon.label }}:</span>
					<span>{{ form.controls.geaendertVon.value }}</span>
				</div>
			</div>
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.freigegebenVon.label }}:</span>
					<span>{{ form.controls.freigegebenVon.value }}</span>
				</div>
			</div>
		</div>
		<div class="flex-row">
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.geaendertAm.label }}:</span>
					<span>{{ form.controls.geaendertAm.value | date : 'dd.MM.yyyy' }}</span>
				</div>
			</div>
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.freigegebenAm.label }}:</span>
					<span>{{ form.controls.freigegebenAm.value | date : 'dd.MM.yyyy' }}</span>
				</div>
			</div>
		</div>
	</div>
</form>
