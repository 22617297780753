<h1 mat-dialog-title>Einreichung {{ einreichung.nummer }} ({{ einreichung.status }})</h1>

<div mat-dialog-content style="height: 100%">
	<app-einreichung-details
		[einreichung]="einreichung"
		[showHeader]="false"></app-einreichung-details>
</div>

<div mat-dialog-actions style="display: flex">
	<span style="flex: 1"></span>
	<button
		mat-raised-button
		color="accent"
		mat-dialog-close
		[routerLink]="metadata.routing.view!.url(einreichung.id)">
		<mat-icon>visibility</mat-icon>
		Ansicht
	</button>
	<button
		mat-raised-button
		color="primary"
		[disabled]="!(einreichung | isEditable)"
		mat-dialog-close
		[routerLink]="metadata.routing.edit!.url(einreichung.id)"
		*requireRole="'standard'">
		<mat-icon>edit</mat-icon>
		Bearbeiten
	</button>
</div>
