import { Opposition } from './../stammdaten/opposition/model';
import { logger } from './../../logger';
import { trimEnd } from 'lodash';
import {
	Einreichung,
	Einreichungsperson,
	EinreichungStatus,
	GeschaeftsfallMitPositionen,
} from '../bewegungsdaten/model';
import { getPersonName } from '../bewegungsdaten/pipes/person-name.pipe';
import { Finanzinstitut } from '../stammdaten/finanzinstitut/model';
import { Gattung } from '../stammdaten/gattung/model';
import { StammdatenStatus } from '../stammdaten/model';
import * as _ from 'lodash';

type AddMessage = (level: 'info' | 'warning' | 'error', text: string) => void;

export interface PlausibilitaetContext {
	einreichung: Einreichung;
	gattungen: Gattung[];
	addMessage: AddMessage;
	institute: Finanzinstitut[];
	geschaeftsfaelle: GeschaeftsfallMitPositionen[];
}

export function plausibilitaet(context: PlausibilitaetContext): void {
	keinePositionen(context);
	mussEinreicherHaben(context);
	beimEinreicherKeineEinreichungspersonen(context);
	privateEinreichungMussWirtschaftlichBerechtigtenHaben(context);
	kycPruefung(context);
	kycPruefung2(context);
	oppositionspruefung(context);
	amtlicheAusweisung(context);
	kistam(context);
	einreicherAktiv(context);
	gattungenAktiv(context);
	// vorabpauschaleEingegangen(context);
	geschaeftsfallMischtInkassoUndNichtInkassoNicht(context);
	fehlendeKontonummer(context);
	fehlendeBIC(context);
	fehlendeAdresse(context);
	fehlendeKundeninformation(context);
	einreicherMandatoryFields(context);
}

function mussEinreicherHaben({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (!einreichung.einreicher && !einreichung.personen.find(p => p.istEinreicher)) {
		addMessage(
			'error',
			'Eine Einreichung muss entweder ein Finanzinstitut oder eine Einreichungsperson als Einreicher haben!'
		);
	}
}

function beimEinreicherKeineEinreichungspersonen({
	einreichung,
	addMessage,
}: PlausibilitaetContext): void {
	if (einreichung.einreicher && einreichung.personen.length > 0) {
		addMessage(
			'error',
			'Eine Einreichung darf nicht gleichzeitig Finanzinstitut und Personen als Einreicher haben!'
		);
	}
}

function privateEinreichungMussWirtschaftlichBerechtigtenHaben({
	einreichung,
	addMessage,
}: PlausibilitaetContext): void {
	if (
		einreichung.personen.length > 0 &&
		!einreichung.personen.find(p => p.istWirtschaftlichBerechtigter)
	) {
		addMessage(
			'error',
			'Eine private Einreichung muss mindestens eine Person als wirtschaftlich Berechtigten ausweisen!'
		);
	}
}

//EFA-19
function kycPruefung({ einreichung, addMessage }: PlausibilitaetContext): void {
	const einreicher = einreichung.personen.filter(p => p.istEinreicher);
	const wirtschaftlichBerechtigt = einreichung.personen.filter(
		p => p.istWirtschaftlichBerechtigter
	);
	const einreicherUndWirtschaftlichBerechtigtUnterscheidenSich = einreicher.some(e =>
		wirtschaftlichBerechtigt.some(w => e !== w)
	);

	if (!einreicherUndWirtschaftlichBerechtigtUnterscheidenSich) return;
	//EFA-2951
	if (einreichung.status == EinreichungStatus.InBearbeitung) return;
	if (einreichung.status == EinreichungStatus.Eingegangen) return;

	const zuPruefen = [
		...new Set<Einreichungsperson>([
			...einreicher.filter(p => !p.dekaDepotVorhanden),
			...wirtschaftlichBerechtigt.filter(p => !p.dekaDepotVorhanden),
		]).values(),
	]; // distinct

	for (const person of zuPruefen) {
		if (!person.kycPruefungsnummer) {
			addMessage('error', `Keine KYC Prüfungsnummer für ${getPersonName(person)}!`);
		}
	}
}

//EFA-1643
function kycPruefung2({ einreichung, addMessage }: PlausibilitaetContext): void {
	//EFA-2951
	if (einreichung.status == EinreichungStatus.InBearbeitung) return;
	if (einreichung.status == EinreichungStatus.Eingegangen) return;

	for (const person of einreichung.personen) {
		if (!person.dekaDepotVorhanden && !person.kycPositiv) {
			addMessage(
				'error',
				`KYC Prüfung für ${getPersonName(
					person
				)} nicht ausreichend (Entweder muss ein Deka-Depot vorhanden oder Flag "Kein Handlungsbedarf" gesetzt sein)!`
			);
		}
	}
}

function oppositionspruefung({ einreichung, addMessage }: PlausibilitaetContext): void {
	const msgOppositionSystem = `Oppositionstreffer (System)!`;
	if (einreichung.oppositionSystem) {
		addMessage('error', msgOppositionSystem);
	}

	const msgOppositionErfasser = `Oppositionstreffer (Erfasser)!`;
	const hasOppositionstrefferErfasser = einreichung.oppositionstreffer;
	if ((hasOppositionstrefferErfasser && !_.isBoolean(einreichung.oppositionSystem)) ||
		(hasOppositionstrefferErfasser && !einreichung.oppositionSystem)) {
		addMessage('error', msgOppositionErfasser);
	}

	const msgNoOppositionCheck = 'Oppositionsprüfung steht noch aus!';
	if (einreichung.oppositionSystem === null && einreichung.oppositionstreffer === null) {
		addMessage('error', msgNoOppositionCheck);
	}
}

function kistam({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (einreichung.einreicher) return; // nicht relevant für Institute
	
	if (!einreichung.kistam) addMessage('error', 'KISTAM fehlt!');
}

function einreicherAktiv({ einreichung, institute, addMessage }: PlausibilitaetContext): void {
	if (!einreichung.einreicher) return;
	const institut = institute.find(i => i.id === einreichung.einreicher);
	if (!institut) return addMessage('error', 'Institutinformation nicht gefunden?');
	if (institut.status !== StammdatenStatus.Aktiv) {
		return addMessage(
			'error',
			`Status vom Einreicher-Finanzinstitut ist nicht ${StammdatenStatus.Aktiv} sondern ${institut.status}!`
		);
	}
}

export function einreicherMandatoryFields({
	einreichung,
	addMessage,
}: PlausibilitaetContext): void {
	if (!einreichung.personen || !einreichung.personen.length) return;

	for (const einreicher of einreichung.personen) {
		let errMsg = '';
		if (!einreicher.vorname?.trim()) errMsg += ' Vorname,';
		if (!einreicher.nachname?.trim()) errMsg += ' Nachname,';
		if (!einreicher.adresse?.trim()) errMsg += ' Adresse,';
		if (!einreicher.postleitzahl?.trim()) errMsg += ' Postleitzahl,';
		if (!einreicher.ort?.trim()) errMsg += ' Ort,';
		if (!einreicher.land?.trim()) errMsg += ' Land,';
		if (!einreicher.istVerein && !einreicher.geburtsdatum) errMsg += 'Geburtsdatum,';
		if (!einreicher.istVerein && !einreicher.geburtsort?.trim()) errMsg += ' Geburtsort,';
		if (!einreicher.istVerein && !einreicher.staatsangehoerigkeit?.trim())
			errMsg += ' Staatsangehörigkeit,';
		if (!einreicher.istVerein && !einreicher.ausweisart?.trim()) errMsg += ' Ausweisart,';
		if (!einreicher.istVerein && !einreicher.ausweisnummer?.trim())
			errMsg += ' Ausweisnummer,';
		if (!einreicher.istVerein && !einreicher.ausweisAusgestelltAm)
			errMsg += ' Ausweis - Ausgestellt am,';
		if (!einreicher.istVerein && !einreicher.ausweisAusgestelltVon?.trim())
			errMsg += ' Ausweis - Ausgestellt von,';
		if (!einreicher.istVerein && !einreicher.ausweisGueltigBis)
			errMsg += ' Ausweis - Gültig bis,';

		if (errMsg) {
			logger.log(einreichung, 'Missing data in Einreichung: ' + errMsg);
			errMsg =
				`Freigabe erfordert Personen-Angaben zu Person '${einreicher.vorname} ${einreicher.nachname}': ` +
				trimEnd(errMsg, ',');
			addMessage('error', errMsg);
		}
	}
}

function gattungenAktiv({
	einreichung,
	gattungen,
	institute,
	addMessage,
}: PlausibilitaetContext): void {
	for (const position of einreichung.positionen) {
		if (!position.gattungId)
			return addMessage('error', `Position ${position.positionnummer} hat keine Gattung!`);
		const gattung = gattungen.find(g => g.id === position.gattungId);
		if (!gattung)
			return addMessage(
				'error',
				`Gattungsinformation für die Position ${position.positionnummer} nicht gefunden!`
			);

		if (gattung.status !== StammdatenStatus.Aktiv)
			return addMessage(
				'error',
				`Status der Gattung ${gattung.isin} ${gattung.gattungsbezeichnung} in der Position ist nicht ${StammdatenStatus.Aktiv} sondern ${gattung.status}!`
			);

		const emittent = institute.find(i => i.id === gattung.emittentId);
		if (!emittent) {
			return addMessage(
				'error',
				`Bei der Gattung ${gattung.isin} ${gattung.gattungsbezeichnung} fehlt Emittent!`
			);
		}

		if (emittent.status !== StammdatenStatus.Aktiv) {
			return addMessage(
				'error',
				`Status vom Emittenten der Gattung ${gattung.isin} ${gattung.gattungsbezeichnung} in der Position ist nicht ${StammdatenStatus.Aktiv} sondern ${emittent.status}!`
			);
		}
	}
}

function vorabpauschaleEingegangen({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (einreichung.vorabpauschale > 0 && !einreichung.vorabpauschaleEingegangen) {
		return addMessage('error', `Vorabpauschale nicht eingegangen!`);
	}
}

function amtlicheAusweisung({ einreichung, addMessage }: PlausibilitaetContext): void {
	for (const person of einreichung.personen) {
		console.log(person, '===person');
		if (!person.istVerein && !person.ausweisnummer) {
			addMessage('error', `Ausweisnummer fehlt für ${getPersonName(person)}!`);
		}
	}
}

function geschaeftsfallMischtInkassoUndNichtInkassoNicht({
	gattungen,
	geschaeftsfaelle,
	addMessage,
}: PlausibilitaetContext): void {
	for (const geschaeftsfall of geschaeftsfaelle) {
		if (geschaeftsfall.positionen.length === 1) continue;
		for (const position of geschaeftsfall.positionen) {
			const gattung = gattungen.find(g => g.id === position.gattungId);
			if (!gattung) continue;

			if (geschaeftsfall.geschaeftsfall.istInkasso !== gattung.istInkasso) {
				const gBezeichnung =
					(geschaeftsfall.geschaeftsfall.istInkasso ? 'Inkasso' : 'Nicht-Inkasso') +
					'-Geschäftsfall';
				return addMessage(
					'error',
					`Im ${gBezeichnung} befindet sich die Gattung ${gattung.gattungsbezeichnung}, die ein anderes Inkasso-Kennzeichnung hat.`
				);
			}
		}
	}
}

function keinePositionen({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (einreichung.positionen.length === 0)
		addMessage('error', 'Die Einreichung hat keine Positionen!');
}

function fehlendeKontonummer({
	einreichung,
	gattungen,
	addMessage,
}: PlausibilitaetContext): void {
	if (
		einreichung.positionen.length > 0 &&
		einreichung.personen.length > 0 &&
		!einreichung.konto
	) {
		for (const position of einreichung.positionen) {
			const gattung = gattungen.find(g => g.id === position.gattungId);
			if (gattung?.land !== 'DE' && (gattung?.istInkasso || gattung?.istLiquidiert)) {
				addMessage(
					'error',
					'Kontonummer fehlt (wird für Auszahlung benötigt, da mindestens eine Position auf einer Gattung mit Inkasso- oder Liquidiert-Flag basiert)!'
				);
				break;
			}
		}
	}
}

function fehlendeBIC({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (einreichung.personen.length > 0 && einreichung.konto && !einreichung.bic)
		addMessage('error', 'BIC fehlt (wird für Auszahlung benötigt)!');
}

function fehlendeAdresse({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (einreichung.personen.length > 0) {
		const wirtschaftlichBerechtigte = einreichung.personen.filter(
			p => p.istWirtschaftlichBerechtigter
		);
		if (
			wirtschaftlichBerechtigte.length === 1 &&
			(!wirtschaftlichBerechtigte[0].adresse ||
				(!wirtschaftlichBerechtigte[0].bundesland &&
					wirtschaftlichBerechtigte[0].land == 'DE') ||
				!wirtschaftlichBerechtigte[0].postleitzahl ||
				!wirtschaftlichBerechtigte[0].ort)
		) {
			addMessage(
				'error',
				`Adresse des wirtschaftlich Berechtigten / Einreicher ${getPersonName(
					wirtschaftlichBerechtigte[0]
				)} nicht vollständig gepflegt (Erforderlich: PLZ, Stadt, Straße, Bundesland)!`
			);
		} else {
			const einreicher = einreichung.personen.filter(p => p.istEinreicher);
			if (
				einreicher.length > 0 &&
				(!einreicher[0].adresse ||
					(!einreicher[0].bundesland && 
						einreicher[0].land == 'DE') ||
					!einreicher[0].postleitzahl ||
					!einreicher[0].ort)
			) {
				addMessage(
					'error',
					`Adresse des wirtschaftlich Berechtigten / Einreicher ${getPersonName(
						einreicher[0]
					)} nicht vollständig gepflegt (Erforderlich: PLZ, Stadt, Straße, Bundesland)!`
				);
			}
		}
	}
}

function fehlendeKundeninformation({ einreichung, addMessage }: PlausibilitaetContext): void {
	if (einreichung.personen.length > 0 && !einreichung.konto && !einreichung.depotNummer)
		addMessage('error', 'Kundeninformationen (Konto oder Depotnummer) fehlen!');
}
