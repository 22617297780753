import { Injector } from '@angular/core';
import { Buchung } from '../bewegungsdaten/buchungen/model';
import {
	Geschaeftsfall,
	GeschaeftsfallMitPositionen,
	GeschaeftsfallStatus,
	Steuerart,
} from '../bewegungsdaten/model';
import { Konfiguration } from '../general/konfiguration/model';
import { FinanzinstitutService } from '../stammdaten/finanzinstitut/finanzinstitut.service';
import { Gattung, WKZ } from '../stammdaten/gattung/model';
import { round2 } from '../utils';

export class BuchungenBuilder {
	public nextBuchungsnummer = 1;
	constructor(
		public readonly buchungen: Buchung[],
		public readonly konfiguration: Konfiguration
	) {}

	public addBuchung(buchung: Omit<Buchung, 'buchungsnummer' | 'primanotenNummer'>): void {
		this.buchungen.push({
			...buchung,
			betrag: round2(buchung.betrag),
			kontonummer: kontonummerKuerzen(buchung.kontonummer),
			buchungsnummer: this.nextBuchungsnummer,
			status: 'Vorschau',
			primanotenNummer: '3447',
		});
		this.nextBuchungsnummer++;
	}

	public addStandardBuchungen(
		geschaeftsfall: Geschaeftsfall,
		isFinanzinstitutEinreichung: boolean
	): void {
		if (geschaeftsfall.dekaGebuehren) {
			this.addBuchung({
				betrag: geschaeftsfall.dekaGebuehren,
				buchungsinhalt: 'Deka-Gebühren',
				buchungssatz: 1,
				kontonummer: this.konfiguration.kontoProvision,
			});
		}

		// isFinanzinstitutEinreichung: KESt Quellensteuer wird berechnet und aggregiert, aber nicht verbucht
		// ,da schon abgezogen (für einreichung von finanzinstitut) EFA-2639
		if (geschaeftsfall.kapitalertragssteuer && !isFinanzinstitutEinreichung) {
			this.addBuchung({
				betrag: geschaeftsfall.kapitalertragssteuer,
				buchungsinhalt: 'Kapitalertragssteuer',
				buchungssatz: 1,
				kontonummer: this.konfiguration.kontoKest,
			});
		}

		if (geschaeftsfall.kirchensteuer) {
			this.addBuchung({
				betrag: geschaeftsfall.kirchensteuer,
				buchungsinhalt: 'Kirchensteuer',
				buchungssatz: 1,
				kontonummer: this.konfiguration.kontoKist,
			});
		}

		// isFinanzinstitutEinreichung: Soli Quellensteuer wird berechnet und aggregiert, aber nicht verbucht
		// ,da schon abgezogen (für einreichung von finanzinstitut) EFA-2639
		if (geschaeftsfall.solidaritaetszuschlag && !isFinanzinstitutEinreichung) {
			this.addBuchung({
				betrag: geschaeftsfall.solidaritaetszuschlag,
				buchungsinhalt: 'Solidaritätszuschlag',
				buchungssatz: 1,
				kontonummer: this.konfiguration.kontoSoli,
			});
		}

		if (geschaeftsfall.zinsabschlagsteuer) {
			this.addBuchung({
				betrag: geschaeftsfall.zinsabschlagsteuer,
				buchungsinhalt: 'Zinsabschlagsteuer',
				buchungssatz: 1,
				kontonummer: this.konfiguration.kontoKest,
			});
		}

		if (geschaeftsfall.zuVerrechnendeVorabpauschale) {
			this.addBuchung({
				betrag: geschaeftsfall.zuVerrechnendeVorabpauschale,
				buchungsinhalt: 'Vorabpauschale',
				buchungssatz: 1,
				kontonummer: this.konfiguration.kontoVorabpauschale,
			});
		}
	}

	public addQuestBuchungen(gf: GeschaeftsfallMitPositionen, gattungen: Gattung[]): void {
		let quellensteuernKest = 0;
		let quellensteuernSoli = 0;
		const rueckerstattungskonten: { [iban: string]: number } = {};
		let konto = '';

		if (gf.positionen.length === 0) return;
		if (gf.geschaeftsfall.land === 'LX') return; // LX nur Mäntel, Quest nur für Kupons
		if (gf.einreichung.einreicher) return; //keine Quest Ausgleichsbuchungen für Einreichung eines Finanzinstitut

		function addRueckerstattungskonto(k: Konfiguration, g: Gattung, betrag: number): void {
			if (g.istInkasso) {
				konto = k.kontoInkasso;
			} else {
				konto = g.dotationskonto || k.kontoDotation;
			}
			if (rueckerstattungskonten[konto]) {
				rueckerstattungskonten[konto] += betrag;
			} else {
				rueckerstattungskonten[konto] = betrag;
			}
		}

		for (const position of gf.positionen) {
			const gattung = gattungen.find(g => g.id === position.gattungId);
			if (!gattung)
				throw new Error(`Gattung nicht gefunden für Position ${position.positionnummer}`);
			if (gattung.wkz === WKZ.Mantel) continue; // nur Kupons relevant
			for (const steuer of position.steuern) {
				switch (steuer.steuerart) {
					case Steuerart.QuellensteuerKESt:
						addRueckerstattungskonto(this.konfiguration, gattung, steuer.steuerbetrag);
						quellensteuernKest += steuer.steuerbetrag;
						break;
					case Steuerart.QuellensteuernSoli:
						addRueckerstattungskonto(this.konfiguration, gattung, steuer.steuerbetrag);
						quellensteuernSoli += steuer.steuerbetrag;
						break;
				}
			}
		}

		for (const [konto, betrag] of Object.entries(rueckerstattungskonten)) {
			this.addBuchung({
				betrag: betrag,
				buchungsinhalt: `Ausgleich QueSt`,
				buchungssatz: 2,
				kontonummer: konto,
			});
		}

		if (quellensteuernKest) {
			this.addBuchung({
				betrag: -quellensteuernKest,
				buchungsinhalt: 'Ausgleich Quellensteuer KESt',
				buchungssatz: 2,
				kontonummer: this.konfiguration.kontoKest,
			});
		}

		if (quellensteuernSoli) {
			this.addBuchung({
				betrag: -quellensteuernSoli,
				buchungsinhalt: 'Ausgleich Quellensteuer Soli',
				buchungssatz: 2,
				kontonummer: this.konfiguration.kontoSoli,
			});
		}
	}

	public addAuszahlungAnLeipzig(auszuzahlenderBetrag: number): void {
		this.addBuchung({
			betrag: auszuzahlenderBetrag,
			buchungsinhalt: 'Auszahlung und Wiederanlage',
			buchungssatz: 1,
			kontonummer: this.konfiguration.kontoAnteilsgeschaeftLeipzig,
		});
	}

	/**
	 * add buchung to zahlungsverkehr
	 * @param auszuzahlenderBetrag betrag
	 * @param einreichungsKonto Defines the text for buchungsinhalt
	 * @param shouldPay auszahlung=true, false=wiederanlage
	 */
	public addAuszahlungAnZahlungsverkehr(
		auszuzahlenderBetrag: number,
		einreichungsKonto?: string,
		shouldPay?: boolean,
		gf?: GeschaeftsfallMitPositionen
	): void {
		const isAuszahlung =
			shouldPay ||
			(gf && gf.geschaeftsfall && gf.geschaeftsfall.land === 'LX' && !!einreichungsKonto) ||
			(!!einreichungsKonto && !gf);
		const buchungsinhalt = isAuszahlung
			? 'Auszahlung' + (einreichungsKonto ? ` (Zielkonto ${einreichungsKonto})` : '')
			: 'Wiederanlage';
		const kontonummer = isAuszahlung
			? this.konfiguration.kontoZahlungsverkehr
			: this.konfiguration.kontoAnteilsgeschaeftLeipzig;
		this.addBuchung({
			betrag: auszuzahlenderBetrag,
			buchungsinhalt,
			buchungssatz: 1,
			kontonummer,
		});
	}
}

export function direktBuchungen(
	gf: GeschaeftsfallMitPositionen,
	gattungen: Gattung[],
	konfiguration: Konfiguration
) {
	if (gf.positionen.length === 0) return;
	if (gf.geschaeftsfall.istInkasso) return;
	if (gf.geschaeftsfall.land === 'LX') return;
	const firstGattung = gattungen.find(g => g.id === gf.positionen[0].gattungId);
	if (!firstGattung)
		throw new Error(
			`Gattung nicht gefunden für Position ${gf.positionen[0].positionnummer}`
		);

	// nur Kupons && liquidierte Mäntel
	if (firstGattung.wkz === WKZ.Mantel && !firstGattung.istLiquidiert) return;

	gf.geschaeftsfall.buchungen = [];
	const builder = new BuchungenBuilder(gf.geschaeftsfall.buchungen, konfiguration);

	//
	// Geldbezug
	//
	let isFirst = true;
	for (const position of gf.positionen) {
		const gattung = gattungen.find(gattung => gattung.id === position.gattungId);
		const isFinanzinstitutEinreichung = !!gf.einreichung.einreicher;
		if (!gattung)
			throw new Error(`Gattung nicht gefunden für Position ${position.positionnummer}`);
		const dotationskonto = gattung.dotationskonto || konfiguration.kontoDotation;

		let positionWert: number;
		if (isFinanzinstitutEinreichung) {
			// the only tax calculated on finanzinstitut einreichungen is quest (EFA-2639)
			const positionQuest = position.steuern.reduce((pv, st) => pv + st.steuerbetrag, 0);
			positionWert =
				position.gesamtwert +
				(isFirst ? gf.geschaeftsfall.cdcZinsgutschrift ?? 0 : 0) -
				positionQuest;
		} else {
			positionWert =
				position.gesamtwert + (isFirst ? gf.geschaeftsfall.cdcZinsgutschrift ?? 0 : 0);
		}
		isFirst = false;

		const buchung = builder.buchungen.find(b => b.kontonummer === dotationskonto);
		if (buchung) {
			buchung.betrag -= positionWert;
		} else {
			builder.addBuchung({
				betrag: -positionWert,
				buchungsinhalt: 'Direktbezug',
				buchungssatz: 1,
				kontonummer: dotationskonto,
			});
		}
	}

	builder.addStandardBuchungen(gf.geschaeftsfall, !!gf.einreichung.einreicher);

	if (!gf.einreichung.einreicher && gf.einreichung.depotNummer && !gf.einreichung.konto) {
		// KEIN einreicher & KEIN konto & EIN depot = Leipzig (auszahlung & wiederanlage)
		builder.addAuszahlungAnLeipzig(gf.geschaeftsfall.nettobetrag);
	} else {
		let shouldPay = gf.einreichung.couponShouldPay;

		if (shouldPay != true) {
			shouldPay =
				gattungen.find(g => g.id === gf?.positionen[0].gattungId)?.istLiquidiert == true;
		}

		builder.addAuszahlungAnZahlungsverkehr(
			gf.geschaeftsfall.nettobetrag,
			undefined,
			shouldPay
		);
	}

	builder.addQuestBuchungen(gf, gattungen);
}

export function inkassoBuchungen(
	gf: GeschaeftsfallMitPositionen,
	gattungen: Gattung[],
	konfiguration: Konfiguration,
	injector: Injector
) {
	const finanzinstitutService = injector.get(FinanzinstitutService);
	console.log('inkassoBuchungen', { gf, gattungen, konfiguration });
	if (gf.positionen.length === 0) {
		console.warn('Not generating inkassoBuchungen because gf.positionen.length === 0');
		return;
	}

	if (
		!gf.geschaeftsfall.istInkasso &&
		gf.geschaeftsfall.status !== GeschaeftsfallStatus.InkassoAngefordert
	) {
		console.warn('Not generating inkassoBuchungen because !gf.geschaeftsfall.istInkasso');
		return;
	}

	if (!gf.geschaeftsfall.buchungen) {
		gf.geschaeftsfall.buchungen = [];
	} else {
		gf.geschaeftsfall.buchungen.length = 0;
	}

	const builder = new BuchungenBuilder(gf.geschaeftsfall.buchungen, konfiguration);

	// Luxemburg
	switch (gf.geschaeftsfall.land) {
		case 'LX': {
			builder.addBuchung({
				betrag: -gf.geschaeftsfall.inkassobetrag,
				buchungsinhalt: 'Inkassoeingang',
				buchungssatz: 1,
				kontonummer: konfiguration.kontoCdc,
			});

			builder.addStandardBuchungen(gf.geschaeftsfall, !!gf.einreichung.einreicher);

			// auszahlung auf Einreichungskonto, oder (wenn vorhanden!) auf das Konto des einreichenden Finanzinstituts.
			const finanzinstitutKonto =
				finanzinstitutService.list$.value.find(i => i.id === gf.einreichung.einreicher)
					?.finanzinstitutskonto ?? undefined;
			let auszahlungsKonto = gf.einreichung.konto
				? gf.einreichung.konto
				: finanzinstitutKonto;

			builder.addAuszahlungAnZahlungsverkehr(
				gf.geschaeftsfall.nettobetrag,
				auszahlungsKonto,
				gf.einreichung.couponShouldPay,
				gf
			);
			break;
		}
		default:
			builder.addBuchung({
				betrag: -gf.geschaeftsfall.inkassobetrag,
				buchungsinhalt: 'Inkassoeingang',
				buchungssatz: 1,
				kontonummer: konfiguration.kontoInkasso,
			});

			builder.addStandardBuchungen(gf.geschaeftsfall, !!gf.einreichung.einreicher);

			if (gf.einreichung.depotNummer && !gf.einreichung.konto) {
				builder.addAuszahlungAnLeipzig(gf.geschaeftsfall.nettobetrag);
			} else {
				builder.addAuszahlungAnZahlungsverkehr(
					gf.geschaeftsfall.nettobetrag,
					gf.einreichung.konto
				);
			}

			builder.addQuestBuchungen(gf, gattungen);

			break;
	}
}

export function kontonummerKuerzen(kontonummer: string): string {
	if (kontonummer && kontonummer.length > 10) {
		return kontonummer.substring(kontonummer.length - 10, kontonummer.length);
	}

	return kontonummer;
}
