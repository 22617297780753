import { EntityMetadata } from 'src/app/model';

export interface UltimoKestEintrag {
	bundesland: string;
	kuerzel: string;
	nummer: number;
	summe: number;
}

export interface UltimoKistEintrag {
	kistam: string;
	text: string;
	zeileKapst: string;
	summe: number;
}

export interface UltimoKontenEintrag {
	kontonummer: string;
	kontoart: string | null;
	summe: number;
}

export interface UltimoKontenSearchCriteria {
	kontoZerlegungAb: string | null;
	kontoZerlegungBis: string | null;
}

export interface UltimoEinreichungenEintrag {
	name: string;
	vorname: string;
	rolle: string;
	adresse: string;
	adresszusatz: string;
	postleitzahl: string;
	stadt: string;
	bundesland: string;
	land: string;
	geburtsdatum: string;
	kycPruefnummer: string;
	kycPositiv: boolean;
	staatsangehoerigkeit: string;
	einreichungenHoehe: number;
	isin: string;
	wkn: string;
	gattungLand: string;
}

export interface UltimoEinreichungenSearchCriteria {
	ab: string | null;
	bis: string | null;
}

export const ultimoMetadata: EntityMetadata<UltimoKestEintrag & UltimoKistEintrag & UltimoKontenEintrag> = {
	apiCollectionName: '',
	label: 'Ultimo',
	plural: 'Ultimo',
	labelGender: 'n',
	routing: {},
	fields: {
		bundesland: {
			label: 'Bundesland',
			typ: 'backend-text',
		},
		kuerzel: {
			label: 'Kürzel',
			typ: 'backend-text',
		},
		nummer: {
			label: 'Nummer',
			typ: 'backend-number',
		},
		summe: {
			label: 'Summe',
			typ: 'backend-number',
		},
		kistam: {
			label: 'KiStam',
			typ: 'backend-text',
		},
		text: {
			label: 'Text',
			typ: 'backend-text',
		},
		zeileKapst: {
			label: 'Zeile Kapst',
			typ: 'backend-text',
		},
		kontonummer: {
			label: 'Kontonummer',
			typ: 'backend-text',
		},
		kontoart: {
			label: 'Kontoart',
			typ: 'backend-text',
		},
	},
};

export interface UltimoGeschaeftsfaelleEintrag {
	name: string;
	vorname: string;
	rolle: string;
	adresse: string;
	adresszusatz: string;
	postleitzahl: string;
	stadt: string;
	bundesland: string;
	land: string;
	geburtsdatum: string;
	kycPruefnummer: string;
	kycPositiv: boolean;
	staatsangehoerigkeit: string;
	einreichungenHoehe: number;
	isin: string;
	wkn: string;
	gattungLand: string;
	geschaeftsfallNummer: string;
	pruefungsergebnis: string;
	pruefungsdatum: string;
	geschaeftsfallAbschlussdatum: string;
	embargoPruefnummer: string;
}

export interface UltimoGeschaeftsfaelleSearchCriteria {
	ab: string | null;
	bis: string | null;
}

export interface UltimoRisikoklassenbewertungEintrag {
	kunde: string; 
    anlagedatum: string; 
    gb: string; 
    we: string; 
    filiale: string; 
    gruppe: string; 
    staatsangehoerigkeit: string; 
    adressland: string; 
    branche: string; 
	rechtsform: number; 
    pep: string; 
    kundentyp: string; 
    qualitativeAnalyse: string; 
	risikovererbung: number;
    konto: string; 
    gb2 : string; 
    produkt: string; 
    konto2: string; 
    kunde2: string; 
    relationstyp: string;
}
