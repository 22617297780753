import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	AfterViewInit,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, catchError, EMPTY } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { SaveService } from 'src/app/shared/services/save.service';
import { formatDateForSaveModel } from 'src/app/utils';
import { einreichungspersonMetadata, geschaeftsfallMetadata } from '../../model';
import { UltimoEinreichungenEintrag, UltimoEinreichungenSearchCriteria } from '../model';
import { UltimoService } from '../ultimo.service';

@Component({
	selector: 'app-ultimo-einreichungen',
	templateUrl: './ultimo-einreichungen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltimoEinreichungenComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	@ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

	public readonly isSearching$ = new BehaviorSubject(false);
	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly personMetadata = einreichungspersonMetadata;
	public readonly geschaeftsfallMetadata = geschaeftsfallMetadata;

	public readonly dataSource = new MatTableDataSource<UltimoEinreichungenEintrag>();
	public readonly displayedColumns = [
		'name',
		'rolle',
		'anschrift',
		'geburtsdatum',
		'kycPruefnummer',
		'staatsangehoerigkeit',
		'einreichungenHoehe',
		'isinWkn',
	];

	// public sumOfSums: number = 0;
	readonly searchForm = new TypedForm<FormValues>({
		datumAb: new UntypedFormControl(),
		datumBis: new UntypedFormControl(),
	});
	public existsAllSearchCriteria$ = new BehaviorSubject(false);

	constructor(
		private readonly service: UltimoService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {
		super();

		this.dataSource.sortingDataAccessor = (element, property) => {
			switch (property) {
				case 'name':
				case 'rolle':
				case 'geburtsdatum':
				case 'staatsangehoerigkeit':
				case 'einreichungenHoehe':
					return element[property];
				case 'anschrift':
					return element.postleitzahl + element.stadt + element.adresse;
				case 'kycPruefnummer':
					return element.kycPruefnummer;
				case 'isinWkn':
					return element.isin + element.land;
				default:
					return '';
			}
		};

		this.registerSubscription(
			this.searchForm.valueChanges.subscribe(val => {
				if (Object.values(val).filter(s => !!s).length === 2) {
					this.existsAllSearchCriteria$.next(true);
				} else {
					this.existsAllSearchCriteria$.next(false);
					this.dataSource.data = [];
				}
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	search(): void {
		if (!this.searchForm.typedValue) return;

		this.isSearching$.next(true);

		const payload: UltimoEinreichungenSearchCriteria = {
			ab: formatDateForSaveModel(this.searchForm.typedValue.datumAb),
			bis: formatDateForSaveModel(this.searchForm.typedValue.datumBis),
		};

		this.service
			.getUltimoComplianceData$(payload)
			.pipe(
				catchError(err => {
					this.dataSource.data = [];
					this.alert.error('Konnte nicht laden!', err, {
						date: this.searchForm.typedValue,
					});
					this.isSearching$.next(false);
					return EMPTY;
				})
			)
			.subscribe(items => {
				this.dataSource.data = items;
				this.isSearching$.next(false);
			});
	}

	downloadAsExcel(): void {
		const payload: UltimoEinreichungenSearchCriteria = {
			ab: formatDateForSaveModel(this.searchForm.typedValue.datumAb),
			bis: formatDateForSaveModel(this.searchForm.typedValue.datumBis),
		};
		if (!!payload) {
			this.isLoading$.next(true);
			this.service.getUltimoComplianceDocument$(payload).subscribe({
				next: result => {
					this.save.saveAs(result.data, result.filename);
					this.alert.success('Download erfolgreich');
					this.isLoading$.next(false);
				},
				error: err => {
					this.alert.error('Download fehlgeschlagen', err);
					this.isLoading$.next(false);
				},
			});
		}
	}
}

interface FormValues {
	datumAb: Date;
	datumBis: Date;
}
