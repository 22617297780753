import { Pipe, PipeTransform } from '@angular/core';
import { FieldDescription, NumericFieldDescription } from 'src/app/model';
import { toExternalModel } from 'src/app/utils';

@Pipe({
	name: 'money',
})
export class MoneyPipe implements PipeTransform {
	transform(
		value: number | null | undefined,
		currency: string | null | undefined,
		metadata: FieldDescription
	): string {
		if (!value) return '--';

		const numFieldDesc = metadata as NumericFieldDescription;
		if (numFieldDesc.isEfaNumber) value = toExternalModel(value);
		const decimalDigits = numFieldDesc.decimals ?? 6;

		const formattedNumber = value.toLocaleString('de', {
			minimumFractionDigits: decimalDigits,
			maximumFractionDigits: decimalDigits,
		});
		if (currency) {
			return formattedNumber + ' ' + currency;
		} else {
			return formattedNumber;
		}
	}
}
