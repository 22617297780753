import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-konfiguration-overview',
	templateUrl: './konfiguration-overview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./konfiguration-overview.component.scss'],
})
export class KonfigurationOverviewComponent {
	readonly links: { label: string; route: string[] }[] = [
		{ label: 'Allgemeine Konfiguration', route: ['/konfiguration/allgemein'] },
		{ label: 'Dateien', route: ['/konfiguration/dateien'] },
	];
}
