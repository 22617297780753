<mat-form-field style="width: 100%">
	<div matPrefix>
		<app-field-help-icon [helpText]="metadata?.description"></app-field-help-icon>
	</div>
	<mat-label>{{ resolvedLabel }}</mat-label>
	<mat-select
		[formControl]="selectControl"
		[ngStyle]="{ width: (showClearButton$ | async) ? '90%' : '100%' }"
		style="width: 90%"
		[placeholder]="resolvedPlaceholder"
		#matSelectElement
		panelClass="filter-panel"
		[required]="!!(isRequired$ | async)">
		<mat-option *ngFor="let item of options$ | async" [value]="item.value">
			{{ item.label }}
		</mat-option>
	</mat-select>
	<div matSuffix style="margin-left: 0.5em; width: 1em" class="fixedlist-input-suffix">
		<mat-icon
			(click.stop)="clear()"
			class="suffix-icon"
			*ngIf="showClearButton$ | async"
			matTooltip="Zurücksetzen">
			close
		</mat-icon>
	</div>
	<mat-error>{{ selectControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
