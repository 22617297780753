<div *ngIf="person">
    <div *ngFor="let kycCheck of person.kycChecks, let last = last">
        <div class="flex-row">
            <div class="data-column2">
                <div app-data-item [field]="kycMetadata.fields.datum">
                    {{ kycCheck.datum | date : 'dd.MM.yyyy' }}
                </div>

                <div app-data-item [field]="kycMetadata.fields.pepPruefungsnummer">
                    {{ kycCheck.pepPruefungsnummer }}
                </div>

                <div app-data-item [field]="kycMetadata.fields.embargoPruefungsnummer">
                    {{ kycCheck.embargoPruefungsnummer }}
                </div>

                <div app-data-item [field]="kycMetadata.fields.resultat">
                    {{ kycCheck.resultat }}
                </div>
            </div>

            <div class="data-column2">
                <div app-data-item [field]="kycMetadata.fields.kommentar">
                </div>

                <div>
                    {{ kycCheck.kommentar!==null && kycCheck.kommentar!== "" ? kycCheck.kommentar : "---" }}
                </div>
            </div>
        </div>

        <hr [class.subline]="!last" />

    </div>
</div>