import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDateForSaveModel, parseIncomingAsDate } from 'src/app/utils';
import { EntityServiceBase } from '../entity.service.base';
import { FeiertagFormValues } from './feiertagskalender-popup/feiertagskalender-popup.component';
import { Feiertag, feiertagskalenderMetadata } from './model';

@Injectable({
	providedIn: 'root',
})
export class FeiertagskalenderService extends EntityServiceBase<Feiertag> {
	readonly metadata = feiertagskalenderMetadata;

	constructor(http: HttpClient) {
		super(http, feiertagskalenderMetadata);

		this.onLoaded = f => {
			f.erstesAuftreten = parseIncomingAsDate(f.erstesAuftreten);
		};

		this.onSaving = f => {
			f.erstesAuftreten = formatDateForSaveModel(f.erstesAuftreten) as any;
			delete f.id;
			if (!f.istWiederkehrend) f.istWiederkehrend = false; // null -> false
		};
	}

	save$(item: FeiertagSaveType): Observable<string> {
		const itemId = item.id;
		if (this.onSaving) {
			item = { ...item };
			this.onSaving(item);
		}
		if (itemId) {
			return this.http.put(`/${this.metadata.apiCollectionName}/${itemId}`, item, { responseType: 'text' });
		} else {
			return this.http.post(`/${this.metadata.apiCollectionName}`, item, { responseType: 'text' });
		}
	}

	delete$(id: string): Observable<string> {
		return this.http.delete(`/${this.apiCollectionName}/${id}`, { responseType: 'text' });
	}
}

export interface FeiertagSaveType extends FeiertagFormValues {
	id: string | undefined;
}
