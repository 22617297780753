import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Gattung } from '../../model';

@Component({
	selector: 'app-gattung-choose-dialog',
	templateUrl: './gattung-choose-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungChooseDialogComponent {
	constructor(@Inject(MatDialogRef) private readonly ref: MatDialogRef<GattungChooseDialogComponent>) {}

	choose(gattung: Gattung): void {
		this.ref.close(gattung);
	}
}
