<div class="page-header">
	<h1>{{ metadata.plural }}</h1>
	<div>
		<button
			mat-raised-button
			color="primary"
			[routerLink]="metadata.routing.edit!.url('new')"
			*requireRole="'standard'">
			<mat-icon>add</mat-icon>
			Neues Institut
		</button>
	</div>
</div>

<app-finanzinstitut-list (selected$)="openPopup($event)"></app-finanzinstitut-list>
