<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">Personendaten</h1>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="accept()">
	<mat-accordion multi>
		<mat-expansion-panel [expanded]="true" class="expansionPanel">
			<mat-expansion-panel-header>Person</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<app-text-input
						[metadata]="metadata.fields.anrede"
						formControlName="anrede"></app-text-input>
					<app-text-input
						[metadata]="metadata.fields.vorname"
						formControlName="vorname"></app-text-input>
					<app-text-input
						[metadata]="metadata.fields.nachname"
						formControlName="nachname"></app-text-input>
				</div>

				<div class="flex-row" style="margin-top: 1em">
					<app-text-input
						[metadata]="metadata.fields.email"
						formControlName="email"
						class="flex-column"></app-text-input>
					<div class="flex-column"></div>
					<mat-slide-toggle formControlName="istEinreicher" class="flex-column slider">
						Einreicher
					</mat-slide-toggle>
					<mat-slide-toggle
						formControlName="istWirtschaftlichBerechtigter"
						class="flex-column slider">
						Wirtschaftlich berechtigt
					</mat-slide-toggle>
					<mat-slide-toggle formControlName="istVerein" class="flex-column slider">
						Ist Verein
					</mat-slide-toggle>
				</div>
				<div class="flex-row">
					<mat-error>{{ form | fieldErrorMessage$ | async }}</mat-error>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Adresse</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<app-kuerzel-bezeichnung-choose
						formControlName="land"
						[metadata]="metadata.fields.land"></app-kuerzel-bezeichnung-choose>

					<app-postleitzahl-input
						formControlName="postleitzahl"
						[metadata]="metadata.fields.postleitzahl"
						[land]="form.controls.land.value"
						(userEnteredPostleitzahl)="
							onUserEnteredPostleitzahl($event)
						"></app-postleitzahl-input>

					<app-text-input
						formControlName="ort"
						[metadata]="metadata.fields.ort"></app-text-input>

					<app-kuerzel-bezeichnung-choose
						formControlName="bundesland"
						[metadata]="metadata.fields.bundesland"></app-kuerzel-bezeichnung-choose>
				</div>

				<div class="flex-row">
					<app-text-input
						formControlName="adresse"
						[metadata]="metadata.fields.adresse"></app-text-input>

					<app-text-input
						formControlName="adresszusatz"
						[metadata]="metadata.fields.adresszusatz"></app-text-input>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Amtliche Identifizierung</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<app-date-input
						[metadata]="metadata.fields.geburtsdatum"
						formControlName="geburtsdatum"></app-date-input>

					<app-text-input
						formControlName="geburtsort"
						[metadata]="metadata.fields.geburtsort"></app-text-input>

					<app-kuerzel-bezeichnung-choose
						formControlName="geburtsland"
						[metadata]="metadata.fields.geburtsland"></app-kuerzel-bezeichnung-choose>

					<app-kuerzel-bezeichnung-choose
						formControlName="staatsangehoerigkeit"
						[metadata]="
							metadata.fields.staatsangehoerigkeit
						"></app-kuerzel-bezeichnung-choose>
				</div>

				<div class="flex-row">
					<app-kuerzel-bezeichnung-choose
						formControlName="ausweisart"
						panelClass="status-filter-panel-300"
						[metadata]="metadata.fields.ausweisart"></app-kuerzel-bezeichnung-choose>

					<app-text-input
						formControlName="ausweisnummer"
						[metadata]="metadata.fields.ausweisnummer"></app-text-input>

					<app-text-input
						formControlName="ausweisAusgestelltVon"
						[metadata]="metadata.fields.ausweisAusgestelltVon"></app-text-input>

					<app-date-input
						[metadata]="metadata.fields.ausweisAusgestelltAm"
						formControlName="ausweisAusgestelltAm"></app-date-input>

					<app-date-input
						[metadata]="metadata.fields.ausweisGueltigBis"
						formControlName="ausweisGueltigBis"></app-date-input>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Dekainformationen</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<mat-slide-toggle formControlName="dekaDepotVorhanden" class="slider">
						{{ depotVorhandenLabel() }}
					</mat-slide-toggle>

					<app-text-input
						formControlName="kycPruefungsnummer"
						[metadata]="metadata.fields.kycPruefungsnummer"></app-text-input>

					<app-text-input
						formControlName="embargoPruefungsnummer"
						[metadata]="metadata.fields.embargoPruefungsnummer"></app-text-input>

					<mat-slide-toggle formControlName="kycPositiv" class="slider">
						{{ metadata.fields.kycPositiv.label }}
					</mat-slide-toggle>
					
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</form>

<div mat-dialog-actions style="display: flex">
	<button mat-raised-button color="accent" (click)="tryCancel()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button
		mat-raised-button
		color="primary"
		(click)="accept()"
		[disabled]="cannotAccept$ | async">
		<mat-icon>check</mat-icon>
		Akzeptieren
	</button>
</div>
