import { Component, ChangeDetectionStrategy, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { FieldDescription } from 'src/app/model';

@Component({
	selector: '[app-data-item]',
	templateUrl: './data-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./data-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DataItemComponent implements OnChanges {
	@Input() field?: FieldDescription;
	@Input() label?: string;

	resolvedTitle: string = '';

	ngOnChanges(): void {
		if (this.label) {
			this.resolvedTitle = this.label;
		} else if (this.field && this.field.label) {
			this.resolvedTitle = this.field.label + ':';
		} else {
			this.resolvedTitle = '';
		}
	}
}
