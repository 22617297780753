import { SummaryValues } from './../gattung-steuer/gattung-steuer.component';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Gattung, gattungMetadata } from '../../model';
import { Einreichungspositionssteuer } from 'src/app/bewegungsdaten/model';

@Component({
	selector: 'app-gattung-steuer-display',
	templateUrl: './gattung-steuer-display.component.html',
	styleUrls: ['./gattung-steuer-display.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungSteuerDisplayComponent {
	@Input() gattung: Gattung;
	@Input() steuern: Einreichungspositionssteuer[];
	@Input() summaryValues: SummaryValues;

	public readonly metadata = gattungMetadata;

	constructor() {
		this.gattung = {} as Gattung;
		this.steuern = [] as Einreichungspositionssteuer[];
		this.summaryValues = {} as SummaryValues;
	}
}
