import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityServiceBase } from '../entity.service.base';
import { Benutzer, benutzerMetadata } from './model';

@Injectable({
	providedIn: 'root',
})
export class BenutzerService extends EntityServiceBase<Benutzer> {
	constructor(http: HttpClient) {
		super(http, benutzerMetadata);
	}
}
