<ng-container *ngIf="buchungen[0]; else keineBuchungen">
	<h2 *ngIf="header !== 'hide'">
		Buchungsdatum: {{ buchungen[0].buchungsdatum | date : 'dd.MM.yyyy' }}, Valuta:
		{{ buchungen[0].valutatag | date : 'dd.MM.yyyy' }}, Verarbeitung:
		{{ buchungen[0].verarbeitungszeitpunkt | date : 'dd.MM.yyyy HH:mm:ss' }}
	</h2>

	<table
		class="buchungen-table mat-table cdk-table"
		style="border-spacing: 1em 0; width: 100%"
		role="table">
		<thead role="rowgroup">
			<tr class="mat-header-row cdk-header-row">
				<th
					class="mat-header-cell cdk-header-cell"
					scope="col"
					style="width: 5em; text-align: left">
					Satz
				</th>
				<th
					class="mat-header-cell cdk-header-cell"
					scope="col"
					style="width: 5em; text-align: left">
					Nr.
				</th>
				<th class="mat-header-cell cdk-header-cell" scope="col" style="text-align: left">
					Buchungsinhalt
				</th>
				<th class="mat-header-cell cdk-header-cell" scope="col" style="text-align: left">
					Kontonummer
				</th>
				<th class="mat-header-cell cdk-header-cell" scope="col" style="text-align: left">
					Primanoten
				</th>
				<th
					class="mat-header-cell cdk-header-cell"
					scope="col"
					style="width: 15em; text-align: right">
					Soll-Betrag
				</th>
				<th
					class="mat-header-cell cdk-header-cell"
					scope="col"
					style="width: 15em; text-align: right">
					Haben-Betrag
				</th>
			</tr>
		</thead>
		<tbody role="rowgroup">
			<tr *ngFor="let buchung of buchungen" class="mat-row cdk-row">
				<td class="mat-cell cdk-cell">{{ buchung.buchungssatz }}</td>
				<td class="mat-cell cdk-cell">{{ buchung.buchungsnummer }}</td>
				<td class="mat-cell cdk-cell">{{ buchung.buchungsinhalt }}</td>
				<td class="mat-cell cdk-cell">{{ buchung.kontonummer }}</td>
				<td class="mat-cell cdk-cell">{{ buchung.primanotenNummer }}</td>
				<td class="mat-cell cdk-cell" style="text-align: right">
					{{ (buchung.sollHaben === 'S' ? buchung.betrag : null) | efaNumber }}
				</td>
				<td class="mat-cell cdk-cell" style="text-align: right">
					{{ (buchung.sollHaben === 'H' ? buchung.betrag : null) | efaNumber }}
				</td>
			</tr>
		</tbody>
	</table>
</ng-container>

<ng-template #keineBuchungen>
	<p>Keine Buchungen vorhanden</p>
</ng-template>
