<form [formGroup]="form">
	<div class="page-header">
		<h1>Konfiguration</h1>
	</div>

	<div class="flex-column">
		<div class="mat-elevation-z2 flex-column" style="padding: 1em">
			<div class="flex-row">
				<h2>Konten</h2>
				<app-text-input
					style="flex: 1"
					formControlName="bankleitzahl"
					[metadata]="metadata.fields.bankleitzahl"></app-text-input>
				<div style="flex: 2"></div>
			</div>
			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="kontoDotation"
					[metadata]="metadata.fields.kontoDotation"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoCdc"
					[metadata]="metadata.fields.kontoCdc"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoInkasso"
					[metadata]="metadata.fields.kontoInkasso"></app-text-input>
				<div style="flex: 1"></div>
			</div>

			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="kontoKist"
					[metadata]="metadata.fields.kontoKist"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoKest"
					[metadata]="metadata.fields.kontoKest"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoSoli"
					[metadata]="metadata.fields.kontoSoli"></app-text-input>
				<div style="flex: 1"></div>
			</div>

			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="kontoVorabpauschale"
					[metadata]="metadata.fields.kontoVorabpauschale"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoAnteilsgeschaeftLeipzig"
					[metadata]="metadata.fields.kontoAnteilsgeschaeftLeipzig"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoZahlungsverkehr"
					[metadata]="metadata.fields.kontoZahlungsverkehr"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="kontoProvision"
					[metadata]="metadata.fields.kontoProvision"></app-text-input>
			</div>
		</div>

		<div class="mat-elevation-z2 flex-column" style="padding: 1em">
			<h2>Verzeichnisse</h2>
			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="basisablageOrt"
					[metadata]="metadata.fields.basisablageOrt"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="addVerzeichnis"
					[metadata]="metadata.fields.addVerzeichnis"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="sepaVerzeichnis"
					[metadata]="metadata.fields.sepaVerzeichnis"></app-text-input>
			</div>
			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="dokumentenVerzeichnis"
					[metadata]="metadata.fields.dokumentenVerzeichnis"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="roboticsVerzeichnis"
					[metadata]="metadata.fields.roboticsVerzeichnis"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="linkZumAnwenderhandbuch"
					[metadata]="metadata.fields.linkZumAnwenderhandbuch"></app-text-input>
			</div>
			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="fileNetBaseLink"
					[metadata]="metadata.fields.fileNetBaseLink"></app-text-input>
			</div>
		</div>

		<div class="mat-elevation-z2 flex-column" style="padding: 1em">
			<h2>Deka-Informationen</h2>
			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="dekaOrganisationseinheit"
					[metadata]="metadata.fields.dekaOrganisationseinheit"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="dekaFinanzinsitutsnummer"
					[metadata]="metadata.fields.dekaFinanzinsitutsnummer"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="dekaFinanzamtName"
					[metadata]="metadata.fields.dekaFinanzamtName"></app-text-input>
			</div>
			<div class="flex-row">
				<app-text-input
					style="flex: 1"
					formControlName="dekaAnsprechpartner"
					[metadata]="metadata.fields.dekaAnsprechpartner"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="dekaTelefonnummer"
					[metadata]="metadata.fields.dekaTelefonnummer"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="dekaFaxnummer"
					[metadata]="metadata.fields.dekaFaxnummer"></app-text-input>
				<app-text-input
					style="flex: 1"
					formControlName="dekaEmail"
					[metadata]="metadata.fields.dekaEmail"></app-text-input>
			</div>
		</div>
	</div>
</form>
