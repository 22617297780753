import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, EMPTY, Observable, switchMap, tap } from 'rxjs';
import { AlertService } from '../shared/alert.service';
import { SimpleDialogService } from '../shared/dialogs/simple-dialog.service';
import { getArticle, titleCase } from '../utils';
import { StammdatenEntityServiceBase } from './entity.service.base';

@Injectable({
	providedIn: 'root',
})
export class EntityActionsService {
	constructor(
		private readonly simpleDialog: SimpleDialogService,
		private readonly sanitizer: DomSanitizer,
		private readonly alert: AlertService
	) {}

	private action<T extends { id: string }>(config: EntityActionConfigExtended<T>): void {
		this.simpleDialog
			.open({
				title: config.title,
				messageHtml: config.messageHtml,
				buttonsLeft: [{ label: config.neinMessage, icon: 'close', color: 'accent', value: 'Nein' }],
				buttonsRight: [{ label: config.jaMessage, icon: config.jaIcon, color: 'primary', value: 'Ja' }],
			})
			.pipe(
				switchMap(response => {
					if (response !== 'Ja') return EMPTY;
					const closeSpinner = this.simpleDialog.showSpinner(config.inProgressMessage);
					return config.action().pipe(
						tap(() => this.alert.success(config.successMessage)),
						catchError(err => {
							this.alert.error(config.failedMessage, err);
							return EMPTY;
						}),
						tap(() => closeSpinner())
					);
				})
			)
			.subscribe({ next: config.onCompleted });
	}

	public inaktivieren<T extends { id: string }>(config: EntityActionConfig<T>): void {
		const entityLabel = config.service.metadata.label;
		const entityLabelGender = config.service.metadata.labelGender;
		const nameSanitized = this.sanitizer.sanitize(SecurityContext.HTML, config.name);

		this.action({
			...config,
			action: () => config.service.inaktivieren$(config.entity.id),
			title: `${titleCase(getArticle(entityLabelGender, 'akkusativ'))} ${entityLabel} inaktivieren?`,
			messageHtml: `Soll ${getArticle(
				entityLabelGender,
				'nominativ'
			)} ${entityLabel} <strong>${nameSanitized}</strong> inaktiviert werden?`,
			neinMessage: 'Nein, nicht inaktivieren!',
			jaMessage: 'Ja, inaktivieren!',
			jaIcon: 'visibility_off',
			inProgressMessage: 'Inaktivierung...',
			successMessage: `${titleCase(getArticle(entityLabelGender, 'nominativ'))} ${entityLabel} ${restoreUmlaute(
				nameSanitized || ''
			)} wurde inaktiviert.`,
			failedMessage: 'Eine Inaktivierung ist nicht erfolgt',
		});
	}

	public freigeben<T extends { id: string }>(config: EntityActionConfig<T>): void {
		const entityLabel = config.service.metadata.label;
		const entityLabelGender = config.service.metadata.labelGender;
		const nameSanitized = this.sanitizer.sanitize(SecurityContext.HTML, config.name);

		this.action({
			...config,
			action: () => config.service.freigeben$(config.entity.id),
			title: `${titleCase(getArticle(entityLabelGender, 'akkusativ'))} ${entityLabel} freigeben?`,
			messageHtml: `Soll ${getArticle(
				entityLabelGender,
				'nominativ'
			)} ${entityLabel} <strong>${nameSanitized}</strong> freigegeben werden?`,
			neinMessage: 'Nein, nicht freigeben!',
			jaMessage: 'Ja, freigeben!',
			jaIcon: 'approval',
			inProgressMessage: 'Freigabe...',
			successMessage: `${titleCase(getArticle(entityLabelGender, 'nominativ'))} ${entityLabel} ${restoreUmlaute(
				nameSanitized || ''
			)} wurde freigeben.`,
			failedMessage: 'Die Freigabe ist nicht erfolgt',
		});
	}

	public freigabeAnfordern<T extends { id: string }>(config: EntityActionConfig<T>): void {
		const entityLabel = config.service.metadata.label;
		const entityLabelGender = config.service.metadata.labelGender;
		const nameSanitized = this.sanitizer.sanitize(SecurityContext.HTML, config.name);

		this.action({
			...config,
			action: () => config.service.freigabeAnfordern$(config.entity.id),
			title: `Freigabe für ${titleCase(getArticle(entityLabelGender, 'akkusativ'))} ${entityLabel} fordern?`,
			messageHtml: `Soll eine Freigabe für ${getArticle(
				entityLabelGender,
				'nominativ'
			)} ${entityLabel} <strong>${nameSanitized}</strong> gefordert werden?`,
			neinMessage: 'Nein, die Freigabe nicht fordern!',
			jaMessage: 'Ja, eine Freigabe fordern!',
			jaIcon: 'flaky',
			inProgressMessage: 'Freigabe anfordern...',
			successMessage: `Eine Freigabe für ${titleCase(
				getArticle(entityLabelGender, 'nominativ')
			)} ${entityLabel} ${restoreUmlaute(nameSanitized || '')} wurde gefordert.`,
			failedMessage: 'Das Freigabeanfordern ist nicht erfolgt',
		});
	}
}

export interface EntityActionConfig<T extends { id: string }> {
	entity: T;
	service: StammdatenEntityServiceBase<T>;
	name: string;
	onCompleted: () => void;
}

interface EntityActionConfigExtended<T extends { id: string }> extends EntityActionConfig<T> {
	jaIcon: string | undefined;
	failedMessage: string;
	successMessage: string;
	inProgressMessage: string;
	jaMessage: string;
	neinMessage: string;
	messageHtml: string;
	title: string;
	action: () => Observable<T>;
}

function restoreUmlaute(input: string): string {
	let result = '';
	if (input) {
		result = input.replace(/&#(\d+);/g, function (match, p1) {
			return String.fromCharCode(p1);
		});
	}
	return result;
}
