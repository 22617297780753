import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, startWith } from 'rxjs';
import { createFormControl, EntityMetadata } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FeiertagskalenderService } from '../feiertagskalender.service';
import { Feiertag } from '../model';

@Component({
	selector: 'app-feiertagskalender-popup',
	templateUrl: './feiertagskalender-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeiertagskalenderPopupComponent {
	public readonly form: TypedForm<FeiertagFormValues>;
	public readonly isSaving$ = new BehaviorSubject(false);
	public readonly cannotSave$: Observable<boolean>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public readonly data: FeiertagskalenderPopupData,
		private readonly ref: MatDialogRef<FeiertagskalenderPopupComponent>,
		private readonly service: FeiertagskalenderService,
		private readonly alert: AlertService
	) {
		this.form = new TypedForm<FeiertagFormValues>({
			name: createFormControl(data.metadata.fields.name),
			erstesAuftreten: createFormControl(data.metadata.fields.erstesAuftreten),
			istWiederkehrend: createFormControl(data.metadata.fields.istWiederkehrend),
		});

		this.cannotSave$ = combineLatest([
			this.form.statusChanges.pipe(
				map(s => s === 'VALID'),
				startWith(false)
			),
			this.isSaving$,
		]).pipe(map(([isValid, isSaving]) => !isValid || isSaving));

		if (data.item) {
			this.form.reset(data.item);
		}
	}

	save(): void {
		if (this.form.invalid) return;

		const values = this.form.rawTypedValue;
		this.isSaving$.next(true);
		this.form.disable({ emitEvent: false });
		this.service.save$({ ...values, id: this.data?.item?.id ? this.data?.item?.id : undefined }).subscribe({
			next: () => {
				this.alert.success('Feiertag gespeichert');
				this.service
					.loadAll$()
					.pipe(catchError((err: Error) => of(err)))
					.subscribe(maybeError => {
						if (maybeError instanceof Error) {
							this.alert.error(
								'Der Eintrag ist gespeichert, aber die Liste konnte nicht durchgeladen werden',
								maybeError
							);
						}

						this.ref.close();
					});
			},
			error: err => {
				this.alert.error('Fehler beim Speichern!', err);
				this.isSaving$.next(false);
				this.form.enable({ emitEvent: false });
			},
		});
	}
}

// type FormValues = Omit<Feiertag, 'id'>;
export interface FeiertagFormValues {
	name: string;
	erstesAuftreten: Date;
	istWiederkehrend: boolean;
}

export interface FeiertagskalenderPopupData {
	item?: Feiertag;
	metadata: EntityMetadata<Feiertag>;
}
