import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Gattung } from 'src/app/stammdaten/gattung/model';
import { KuerzelBezeichnungReferenz } from 'src/app/stammdaten/kuerzel-bezeichnung/model';

@Pipe({
	name: 'fieldErrorMessage$',
})
export class FieldErrorMessagePipe implements PipeTransform {
	transform(control: AbstractControl): Observable<string | undefined> {
		return merge(control.valueChanges, control.statusChanges).pipe(
			debounceTime(50),
			map(() => {
				const errors = control.errors;
				if (!errors) return undefined;
				return Object.entries(errors)
					.map(([key, value]) => errorText(key, value))
					.filter(v => !!v)
					.join(' ');
			})
		);
	}
}

const format = new Intl.NumberFormat('de', { useGrouping: true });

function errorText(errorKey: string, value: any) {
	switch (errorKey) {
		case 'required':
			return 'Pflichtfeld!';
		case 'entryInvalid':
			return 'Eingabe nicht korrekt';
		// case 'minlength':
		// 	return `Minimale Länge ${value.requiredLength}!`;
		case 'maxlength':
			return `Maximale Länge ${value.requiredLength} überschritten!`;
		case 'minlength':
			return `Minimale Länge ${value.requiredLength} nicht erreicht!`;
		case 'min':
			return `Minimumwert: ${format.format(value.min)}`;
		case 'max':
			return `Maximumwert: ${format.format(value.max)}`;
		case 'exactLength':
			return `Erwartete Länge: ${format.format(value)}`;
		case 'mussEinreicherOderEmittent':
			return `Das Institut muss entweder Einreicher, Emittent oder beides sein.`;
		case 'finanzinstitutNummerSchonVergeben':
			return value;
		case 'mussNumerischSein':
			return 'Nur Ziffern erlaubt!';
		case 'badDate':
			return 'Falsches Datumformat!';
		case 'isName':
			return 'Name darf kein "&" enthalten!';
		case 'personRoleError':
			return 'Der Person muss mindestens eine Rolle (Einreicher und/oder wirtschaftlich Berechtigeter) zugewiesen sein.';
		case 'errorMessage':
			return value as string;
		case 'duplicateGattung':
			const duplicateGattung = value as Gattung;

			return (
				`Duplikat der Gattung ISIN: ${duplicateGattung.isin || '<LEER>'} WKN: ${duplicateGattung.wkn}` +
				(duplicateGattung.kuponnummerFaelligkeit
					? ` Kuponnummer/Fälligkeit ${duplicateGattung.kuponnummerFaelligkeit}`
					: '') +
				': ' +
				duplicateGattung.gattungsbezeichnung
			);
		case 'duplicateReferenz':
			const ref = value as KuerzelBezeichnungReferenz;
			return `Dieser Kürzel ist bereits definiert: ${ref.bezeichnung}`;
		default:
			return `${errorKey}: ${JSON.stringify(value)}`;
	}
}
