<h1>Stammdatenübersicht</h1>

<table mat-table style="width: 100%; table-layout: fixed" [dataSource]="dataSource">
	<ng-container matColumnDef="label">
		<th mat-header-cell *matHeaderCellDef scope="col">
			<div>Stammdaten</div>
		</th>
		<td mat-cell *matCellDef="let element">{{ element.metadata.plural }}</td>
	</ng-container>

	<ng-container matColumnDef="isLoading">
		<th mat-header-cell *matHeaderCellDef scope="col"></th>
		<td mat-cell *matCellDef="let element">
			<mat-spinner *ngIf="element.isLoading$ | async" diameter="16"></mat-spinner>
		</td>
	</ng-container>

	<ng-container matColumnDef="count">
		<th mat-header-cell *matHeaderCellDef style="width: 10em" scope="col">Anzahl</th>
		<td mat-cell *matCellDef="let element">
			{{ element.service.count$ | async }}
		</td>
	</ng-container>

	<ng-container matColumnDef="lastLoadedOn">
		<th mat-header-cell *matHeaderCellDef style="width: 15em" scope="col">Geladen am</th>
		<td mat-cell *matCellDef="let element">
			{{ element.service.lastLoadedOn$ | async | date : 'yyyy-MM-dd HH:mm' }}
		</td>
	</ng-container>

	<ng-container matColumnDef="actions">
		<th mat-header-cell *matHeaderCellDef scope="col" class="action-column"></th>
		<td mat-cell *matCellDef="let element" class="action-column">
			<button
				mat-icon-button
				color="primary"
				(click.stop)="reload(element, self)"
				#self
				matTooltip="Liste neu laden">
				<mat-icon>refresh</mat-icon>
			</button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row-bold"></tr>
	<tr
		mat-row
		*matRowDef="let row; columns: displayedColumns"
		[class.mat-row-clickable]="row.metadata.routing.list"
		(click)="navigate(row)"></tr>
</table>
