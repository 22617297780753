import { EinreichungFilenetImportComponent } from './../einreichung-filenet-import/einreichung-filenet-import.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	EMPTY,
	filter,
	map,
	merge,
	Observable,
	of,
	shareReplay,
	Subject,
	switchMap,
	take,
	withLatestFrom,
} from 'rxjs';
import {
	buildGeschaeftsfaelleMitPositionen,
	calculateGeschaeftsfaelle,
	CalculateGeschaeftsfaelleResult,
} from 'src/app/berechnung/geschaeftsfaelle';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { BenutzerRolle } from 'src/app/general/roles';
import { AlertService } from 'src/app/shared/alert.service';
import { ButtonState } from 'src/app/shared/button-state';
import { SaveService } from 'src/app/shared/services/save.service';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { KuerzelBezeichnungReferenzlistServiceFactory } from 'src/app/stammdaten/kuerzel-bezeichnung/kuerzel-bezeichnung-referenzlist.service';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { BackendQueryResult, queryBackend, toExternalModel } from 'src/app/utils';
import {
	EinreichungService,
	FreigabeAnfordernPayload,
	FreigabeAnfordernPayloadGeschaeftsfall,
	VorabpauschaleStatus,
} from '../einreichung.service';
import { GeschaeftsfallService } from '../geschaeftsfall.service';
import {
	calculateVorabpauschaleSteuersummen,
	EditableEinreichungStati,
	Einreichung,
	einreichungMetadata,
	EinreichungStatus,
	GeschaeftsfallStatus,
	VorabpauschaleSteuersummen,
} from '../model';
import { EinreichungAbbrechenPopupComponent } from './einreichung-abbrechen-popup/einreichung-abbrechen-popup.component';
import {
	EinreichungFreigabeAnfordernPopupComponent,
	EinreichungFreigabeAnfordernResult,
} from './einreichung-freigabe-anfordern-popup/einreichung-freigabe-anfordern-popup.component';
import {
	EinreichungFreigebenPopupComponent,
	EinreichungFreigebenPopupData,
	EinreichungFreigebenResult,
} from './einreichung-freigeben-popup/einreichung-freigeben-popup.component';
import { EinreichungRueckfragePopupComponent } from './einreichung-rueckfrage-popup/einreichung-rueckfrage-popup.component';
import { generatePersonenPDF, PDFContext } from './generate-personen-pdf';
import { logger } from 'src/logger';
import * as _ from 'lodash';
import { Buchung } from '../buchungen/model';

@Component({
	selector: 'app-einreichung-view-page',
	templateUrl: './einreichung-view-page.component.html',
	styleUrls: ['./einreichung-view-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungViewPageComponent extends BaseComponent {
	public readonly metadata = einreichungMetadata;

	// #region RxJS - Objects
	public readonly reloadRequested$ = new Subject<void>();

	/** wenn emittet, soll die Seite durchladen */
	public readonly navigatedTo$: Observable<string> = this.route.paramMap.pipe(
		map(params => params.get('id') as string)
	);

	public readonly pageContent$ = new BehaviorSubject<BackendQueryResult<Einreichung>>({
		isLoading: true,
	});
	public readonly isLoading$ = new BehaviorSubject(false);

	public readonly calculateGeschaeftsfaelle$: Observable<CalculateGeschaeftsfaelleResult> =
		combineLatest([
			this.pageContent$,
			this.gattungService.list$,
			this.finanzinstitutService.list$,
		]).pipe(
			switchMap(([pageContent, gattungen, finanzinstitute]) => {
				const einreichung = pageContent.content;
				if (!einreichung)
					return of({
						geschaeftsfaelle: [],
						messages: [],
						vorabpauschaleComment: '',
						vorabpauschaleStatus: VorabpauschaleStatus.Unbekannt,
					} as CalculateGeschaeftsfaelleResult);

				switch (einreichung.status) {
					case EinreichungStatus.Eingegangen:
					case EinreichungStatus.Rueckfrage:
					case EinreichungStatus.InBearbeitung:
					case EinreichungStatus.InternInKlaerung:
						return of(
							calculateGeschaeftsfaelle(
								einreichung,
								gattungen,
								finanzinstitute,
								this.konfigurationService.konfiguration$.value
							)
						);
					default:
						return this.geschaeftsfallService.getByEinreichung$(einreichung.id).pipe(
							catchError((err: Error) => of(err)),
							map(gf => buildGeschaeftsfaelleMitPositionen(gf, einreichung))
						);
				}
			}),
			shareReplay(1)
		);

	public readonly canDoRueckfrage$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (!currentUser.roles.includes(BenutzerRolle.Standard)) return;
			if (!queryResult.content) return false;

			switch (queryResult.content.status) {
				case EinreichungStatus.Abgebrochen:
				case EinreichungStatus.Abgeschlossen:
				case EinreichungStatus.Freigegeben:
				case EinreichungStatus.Rueckfrage:
				case EinreichungStatus.Storniert:
				case EinreichungStatus.Teilgebucht:
				case EinreichungStatus.ZurFreigabeKorrigiert:
					return false;
			}

			return true;
		})
	);

	public readonly canDoInterneKlaerung$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (!currentUser.roles.includes(BenutzerRolle.Standard)) return;
			if (!queryResult.content) return false;

			switch (queryResult.content.status) {
				case EinreichungStatus.Abgebrochen:
				case EinreichungStatus.Abgeschlossen:
				case EinreichungStatus.Freigegeben:
				case EinreichungStatus.InternInKlaerung:
				case EinreichungStatus.Storniert:
				case EinreichungStatus.Teilgebucht:
				case EinreichungStatus.ZurFreigabeKorrigiert:
					return false;
			}

			return true;
		})
	);

	public readonly canDoVorabpauschaleSeparieren$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			const einreichung = queryResult.content;
			if (!currentUser.roles.includes(BenutzerRolle.Standard)) return;
			if (!queryResult.content) return false;
			if (
				(einreichung?.vorabpauschale ?? 0) === 0 ||
				einreichung?.vorabpauschaleEingegangen
			)
				return false;

			switch (queryResult.content.status) {
				case EinreichungStatus.Eingegangen:
				case EinreichungStatus.InBearbeitung:
					return true;
			}

			return false;
		})
	);

	public readonly canDoVorabpauschleEinfordern$ = combineLatest([
		this.pageContent$,
		this.calculateGeschaeftsfaelle$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, calc, currentUser]) => {
			if (!currentUser.roles.includes(BenutzerRolle.Standard)) return;
			if (!queryResult.content) return false;
			if (
				(queryResult.content.vorabpauschale ?? 0) === 0 ||
				queryResult.content.vorabpauschaleEingegangen
			)
				return false;

			// switch (queryResult.content.status) {
			// 	case EinreichungStatus.Eingegangen:
			// 	case EinreichungStatus.InBearbeitung:
			// 		return true;
			// }
			if (calc.vorabpauschaleStatus === VorabpauschaleStatus.Faelig) {
				return true;
			}

			return false;
		})
	);

	public readonly canDoAbbrechen$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (!currentUser.roles.includes(BenutzerRolle.Standard)) return;
			if (!queryResult.content) return false;

			switch (queryResult.content.status) {
				case EinreichungStatus.Eingegangen:
				case EinreichungStatus.InBearbeitung:
				case EinreichungStatus.Rueckfrage:
				case EinreichungStatus.InternInKlaerung:
				case EinreichungStatus.ZurFreigabe:
				case EinreichungStatus.Freigegeben:
				case EinreichungStatus.AutomatisierungFehler:
					return true;
			}

			return false;
		})
	);

	public readonly canDoKorrektur$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (
				!currentUser.roles.some(
					role => role === BenutzerRolle.Erweitert || role === BenutzerRolle.DSGVO
				)
			)
				return;
			if (!queryResult.content || queryResult.content.personen.length === 0) return false; //korrektur macht nur für priv.einreichungen sinn

			switch (queryResult.content.status) {
				case EinreichungStatus.Abgeschlossen:
				case EinreichungStatus.ZurFreigabeKorrigiert:
					return true;
			}

			return false;
		})
	);

	public readonly freigabeAnfordernButtonState$: Observable<{
		state: ButtonState;
		tooltip: string;
	}> = combineLatest([
		this.pageContent$,
		this.calculateGeschaeftsfaelle$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([pc, calc, currentUser]) => {
			const einreichung = pc.content;

			// Role Check
			if (!einreichung || !currentUser.roles.includes(BenutzerRolle.Standard))
				// Einreichung fehlt OR User hat keine Standard-Rolle
				return {
					state: ButtonState.Hidden,
					tooltip: '',
				};

			// Status check
			const isBtnHidden = this.einreichungStatusCheck4FreigebenBtn(einreichung);
			if (isBtnHidden) return isBtnHidden;

			// Has Messages
			if (calc.messages.some(m => m.level === 'error')) {
				return {
					state: ButtonState.Disabled,
					tooltip: 'Nicht möglich, weil die Plausibilitätsprüfung fehlgeschlagen.',
				};
			} else if (einreichung.oppositionstreffer === null && einreichung.oppositionSystem === null) {
				return {
					state: ButtonState.Disabled,
					tooltip:
						'Freigeben nicht möglich. Oppositionsprüfung sind vom Erfasser und dem System nicht durchgeführt.',
				};
			} else if (einreichung.oppositionstreffer === true && einreichung.oppositionSystem === false) {
				return {
					state: ButtonState.Disabled,
					tooltip:
						'Freigeben nicht möglich. Einreicher hat eine Opposition eingetragen.',
				};
			} else {
				return { state: ButtonState.Active, tooltip: 'Freigabe anfordern' };
			}
		})
	);

	public readonly freigebenButtonState$: Observable<{ state: ButtonState; tooltip: string; }> =
		combineLatest([this.pageContent$, this.currentUserService.currentUser$]).pipe(
			map(([pc, currentUser]) => {
				const einreichung = pc.content;
				// Role check
				if (
					!einreichung ||
					!einreichung.geaendertVon ||
					!currentUser.roles.includes(BenutzerRolle.Standard)
				)
					return {
						state: ButtonState.Hidden,
						tooltip: '',
					};

				// Status check
				switch (einreichung.status) {
					case EinreichungStatus.ZurFreigabe:
					case EinreichungStatus.ZurFreigabeNurDokumentation:
					case EinreichungStatus.ZurFreigabeKorrigiert:
						break;
					default:
						return {
							state: ButtonState.Hidden,
							tooltip: '',
						};
				}

				// Disable if no other current user
				const geaendertVon = einreichung.geaendertVon.toLocaleUpperCase();
				if (geaendertVon === currentUser.username.toLocaleUpperCase()) {
					return {
						state: ButtonState.Disabled,
						tooltip:
							'Nicht möglich. Der Benutzer, der die Freigabe gefordert hat, kann nicht selbst freigeben.',
					};
				}
				if (einreichung.oppositionSystem) {
					return {
						state: ButtonState.Disabled,
						tooltip:
							'Freigeben nicht möglich. Die Oppositionsprüfung (System) ergab einen Treffer.',
					};
				}
				if (einreichung.oppositionstreffer) {
					return {
						state: ButtonState.Disabled,
						tooltip:
							'Freigeben nicht möglich. Erfasser hat eine Opposition eingetragen.',
					};
				}
				if (einreichung.oppositionstreffer === null && einreichung.oppositionSystem === null) {
					return {
						state: ButtonState.Disabled,
						tooltip:
							'Freigeben nicht möglich. Oppositionsprüfung sind vom Erfasser und dem System nicht durchgeführt.',
					};
				}

				return { state: ButtonState.Active, tooltip: 'Freigeben' };
			})
		);

	public aendernButtonState$: Observable<{ state: ButtonState; tooltip: string; }> =
		combineLatest([this.pageContent$, this.currentUserService.currentUser$]).pipe(
			map(([pc, currentUser]) => {
				const einreichung = pc.content;
				if (!einreichung || !currentUser.roles.includes(BenutzerRolle.Standard))
					return {
						state: ButtonState.Hidden,
						tooltip: '',
					};

				if (!EditableEinreichungStati.includes(einreichung.status)) {
					return {
						state: ButtonState.Hidden,
						tooltip: '',
					};
				}

				switch (einreichung.status) {
					case EinreichungStatus.Abgeschlossen:
					case EinreichungStatus.Abgebrochen:
					case EinreichungStatus.Storniert:
					case EinreichungStatus.Teilgebucht:
					case EinreichungStatus.Freigegeben:
						return {
							state: ButtonState.Hidden,
							tooltip: '',
						};
				}

				return {
					state: ButtonState.Active,
					tooltip: 'Ändern',
				};
			})
		);

	public readonly freigabeAnfordern$ = new Subject<void>();
	public readonly freigeben$ = new Subject<void>();

	public readonly canDoNeuabrechnung$ = combineLatest([
		this.calculateGeschaeftsfaelle$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([gf, u]) => {
			if (!u || !u.roles.includes(BenutzerRolle.Standard)) return false;
			if (!gf) return false;
			return gf.geschaeftsfaelle.some(
				gfl =>
					gfl.geschaeftsfall.status === GeschaeftsfallStatus.Storniert ||
					gfl.geschaeftsfall.status === GeschaeftsfallStatus.StorniertZurBuchung
			);
		})
	);
	//#endregion RxJS - Objects

	public vorabpauschaleSteuersummen: VorabpauschaleSteuersummen = {
		kestSumme: 0,
		soliSumme: 0,
		kistSumme: 0,
	};

	constructor(
		public readonly service: EinreichungService,
		private readonly gattungService: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService,
		private readonly route: ActivatedRoute,
		public readonly quickInfo: QuickInfoPopupService,
		private readonly currentUserService: CurrentUserService,
		private readonly matDialog: MatDialog,
		private readonly alert: AlertService,
		private readonly kuerzelBezeichnungServiceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private readonly konfigurationService: KonfigurationService,
		private readonly geschaeftsfallService: GeschaeftsfallService,
		private readonly save: SaveService
	) {
		super();

		const loadEinreichungWithId$ = merge(
			this.navigatedTo$,
			this.reloadRequested$.pipe(
				withLatestFrom(this.navigatedTo$),
				map(([_, id]) => id)
			)
		);

		this.registerSubscription(
			loadEinreichungWithId$
				.pipe(queryBackend(id => this.service.loadSingle$(id)))
				.subscribe(content => this.pageContent$.next(content))
		);

		this.registerSubscription(
			this.freigabeAnfordern$
				.pipe(
					withLatestFrom(this.calculateGeschaeftsfaelle$, this.pageContent$),
					map(([_, calculateResult, pageContent]) => ({ calculateResult, pageContent })),
					filter(
						({ calculateResult, pageContent }) =>
							!calculateResult.messages.some(m => m.level === 'error')
					),
					map(
						({ calculateResult, pageContent }) =>
						({
							calculateResult,
							einreichung: pageContent.content!,
						} as CalculateResultMitEinreichung)
					),
					switchMap(
						result =>
							this.matDialog
								.open(EinreichungFreigabeAnfordernPopupComponent, { data: result })
								.afterClosed() as Observable<EinreichungFreigabeAnfordernResult | null>
					),
					switchMap(result => {
						if (!result) return EMPTY;
						const payload: FreigabeAnfordernPayload = this.calcFreigabeAnfordernPayload(result);
						const ret = this.service.freigabeAnfordern$(result.einreichung.id, payload).pipe(
							catchError(err => {
								this.alert.error('Freigabe anfordern fehlgeschlagen!', err);
								return EMPTY;
							})
						);
						return ret;
					})
				)
				.subscribe(() => {
					this.alert.success('Freigabe angefordert');
					this.reloadRequested$.next();
				})
		);

		this.registerSubscription(
			this.freigeben$
				.pipe(
					withLatestFrom(this.pageContent$.pipe(map(pc => pc.content!))),
					switchMap(([_, einreichung]) => {
						// Open einreichung confirm popup
						const data: EinreichungFreigebenPopupData = {
							einreichung,
						};
						const ret = this.matDialog
							.open(EinreichungFreigebenPopupComponent, { data })
							.afterClosed() as Observable<EinreichungFreigebenResult>;
						return ret;
					}),
					switchMap(confirmationResult => {
						if (
							!confirmationResult ||
							confirmationResult.oppositionFreigeber === null ||
							confirmationResult.oppositionFreigeber === undefined
						) return EMPTY;

						const oppositionFreigeber = confirmationResult.oppositionFreigeber;
						if (oppositionFreigeber) {
							return this.service.interneKlaerung$({
								id: confirmationResult.id,
								bemerkung: confirmationResult.bemerkung,
								oppositionFreigeber,
							});
						} else {
							// http request for freigeben
							return this.service.freigeben$(confirmationResult.id, { oppositionFreigeber });
						}
					})
				)
				.subscribe({
					next: () => {
						logger.log('Reload einreichung after freigabe or interneKlaerung');
						this.reloadRequested$.next();
					},
					error: err => this.alert.error('Fehlgeschlagen!', err),
				})
		);
	}

	calcFreigabeAnfordernPayload(result: EinreichungFreigabeAnfordernResult): FreigabeAnfordernPayload {
		//Sonderfall Wertpapier ist fremder Mantel mit Vorabpauschale
		//==>Buchungen dürfen nicht gellert werden. Sie müssen an das BE übermittelt werden
		//istInkassoMantelMitVorabpauschale ist ein flag für diesen Sonderfall.
		const istInkassoMantelMitVorabpauschale = result.einreichung.positionen.some(position => {
			const gattung = this.gattungService.list$.value.find(gattung => gattung.id === position.gattungId);
			return gattung && gattung.wkz && gattung.istInkasso;
		}) && result.einreichung.vorabpauschaleGesamtsumme>0;
		
		const ret = {
			nurDokumentation: result.nurDokumentation,
			vorabpauschaleEingegangen: result.vorabpauschaleEingegangen,
			vorabpauschaleStatus: result.vorabpauschaleStatus === VorabpauschaleStatus.Faelig
				? VorabpauschaleStatus.FaelligUndEingegangen
				: result.vorabpauschaleStatus,
			geschaeftsfaelle: result.calculateResult.geschaeftsfaelle.map(gf => {
				const payloadGf: FreigabeAnfordernPayloadGeschaeftsfall = {
					bruttobetrag: gf.geschaeftsfall.bruttobetrag,
					cdcGebuehren: gf.geschaeftsfall.cdcGebuehren,
					cdcZinsgutschrift: gf.geschaeftsfall.cdcGebuehren,
					dekaGebuehren: gf.geschaeftsfall.cdcGebuehren,
					einreichungId: result.einreichung.id,
					isin: gf.geschaeftsfall.isin,
					istInkasso: gf.geschaeftsfall.istInkasso,
					land: gf.geschaeftsfall.land,
					nettobetrag: gf.geschaeftsfall.nettobetrag,
					nummer: gf.geschaeftsfall.nummer,
					vorgangsnummer: gf.geschaeftsfall.vorgangsnummer,
					steuern: gf.geschaeftsfall.steuern,
					zuVerrechnendeVorabpauschale: gf.geschaeftsfall.zuVerrechnendeVorabpauschale,
					bemerkungen: gf.geschaeftsfall.bemerkungen,
					positionen: gf.positionen.map(p => p.id),
					inkassobetrag: 0,
					kapitalertragssteuer: gf.geschaeftsfall.kapitalertragssteuer,
					kirchensteuer: gf.geschaeftsfall.kirchensteuer,
					solidaritaetszuschlag: gf.geschaeftsfall.solidaritaetszuschlag,
					zinsabschlagsteuer: gf.geschaeftsfall.zinsabschlagsteuer,
					kapitalertragssteuerCalculated:
						gf.geschaeftsfall.kapitalertragssteuerCalculated,
					kirchensteuerCalculated: gf.geschaeftsfall.kirchensteuerCalculated,
					solidaritaetszuschlagCalculated:
						gf.geschaeftsfall.solidaritaetszuschlagCalculated,
					zinsabschlagsteuerCalculated: gf.geschaeftsfall.zinsabschlagsteuerCalculated,
					buchungen: !istInkassoMantelMitVorabpauschale //Prüfung auf oben beschriebenen Sonderfall
						? gf.geschaeftsfall.istInkasso
							? []
							: gf.geschaeftsfall.buchungen
						: gf.geschaeftsfall.buchungen,
				};
				return payloadGf;
			}),
			steuern: this.calcSteuernArray(result),
		};
		return ret;
	}

	calcSteuernArray(result: EinreichungFreigabeAnfordernResult): any[] {
		return result.einreichung.positionen.flatMap(position => position.steuern.map(steuer => ({
			...steuer,
			einreichungspositionId: position.id,
		})));
	}

	private einreichungStatusCheck4FreigebenBtn(einreichung: Einreichung) {
		switch (einreichung.status) {
			case EinreichungStatus.Abgebrochen:
			case EinreichungStatus.Storniert:
			case EinreichungStatus.Teilgebucht:
			case EinreichungStatus.ZurFreigabe:
			case EinreichungStatus.ZurFreigabeKorrigiert:
			case EinreichungStatus.ZurFreigabeNurDokumentation:
			case EinreichungStatus.Freigegeben:
			case EinreichungStatus.Rueckfrage:
			case EinreichungStatus.InternInKlaerung:
			case EinreichungStatus.Abgeschlossen:
			case EinreichungStatus.AutomatisierungFehler:
				return {
					state: ButtonState.Hidden,
					tooltip: '',
				};
		}
		return null;
	}

	public fileNetImport(): void {
		const einr = this.pageContent$.value.content;
		if (einr) {
			this.service.isFileNetImportDone$.next(false);
			this.service.fileNetImportResult$.next('');
			this.matDialog.open(EinreichungFilenetImportComponent, {
				minWidth: '600px',
				data: einr as Einreichung,
			});
		} else {
			logger.error('No object "einreichung" available to open FilenetImport-Dialog');
		}
	}

	public rueckfrage(): void {
		this.matDialog
			.open(EinreichungRueckfragePopupComponent, {
				data: {
					bemerkung: this.pageContent$.value.content?.bemerkung,
					mode: 'Rückfrage an Einreicher',
				},
			})
			.afterClosed()
			.pipe(
				filter(val => val && val.dialogResult === 'Ja'),
				withLatestFrom(this.navigatedTo$),
				switchMap(([val, id]) =>
					this.service.rueckfrage$({ id, bemerkung: val.bemerkung })
				)
			)
			.subscribe({
				next: () => {
					this.reloadRequested$.next();
				},
				error: err => {
					this.alert.error('Fehlgeschlagen', err);
					this.reloadRequested$.next();
				},
			});
	}

	public interneKlaerung(): void {
		this.matDialog
			.open(EinreichungRueckfragePopupComponent, {
				data: {
					bemerkung: this.pageContent$.value.content?.bemerkung,
					mode: 'Interne Klärung',
				},
			})
			.afterClosed()
			.pipe(
				filter(val => val && val.dialogResult === 'Ja'),
				withLatestFrom(this.navigatedTo$),
				switchMap(([val, id]) =>
					this.service.interneKlaerung$({ id, bemerkung: val.bemerkung })
				)
			)
			.subscribe({
				next: () => {
					this.reloadRequested$.next();
				},
				error: err => {
					this.alert.error('Fehlgeschlagen', err);
					this.reloadRequested$.next();
				},
			});
	}

	/**
	 * Btn: Vorabpauschale
	 * = PDF Download
	 */
	public vorabpauschaleEinfordern(): void {
		this.isLoading$.next(true);
		this.navigatedTo$
			.pipe(
				take(1),
				withLatestFrom(this.pageContent$),
				switchMap(([id, page]) => {
					this.vorabpauschaleSteuersummen = calculateVorabpauschaleSteuersummen(
						page.content // Einreichung
					);

					// Calculate steuern array
					const vorabpauschaleSteuersummenExternal = this.calcPayload4VorabpauschaleEinfordern(page);
					// httpRequest
					const ret = this.service.vorabpauschaleEinfordern$(
						id,
						vorabpauschaleSteuersummenExternal // payload
					);
					return ret;
				})
			)
			.subscribe({
				next: result => {
					this.save.saveAs(result.data, result.filename);
					this.alert.success('Download erfolgreich');
					this.isLoading$.next(false);
				},
				error: err => {
					this.alert.error('Download fehlgeschlagen', err);
					this.isLoading$.next(false);
				},
			});
	}

	/**
	 * Calculate the payload for the POST Request
	 * @param page Model data
	 */
	calcPayload4VorabpauschaleEinfordern(page: BackendQueryResult<Einreichung>) {
		const _einreichungData = { einreichung: page.content } as EinreichungFreigabeAnfordernResult;
		const _payloadSteuern = this.calcSteuernArray(_einreichungData);
		const _steuernData = { steuern: [..._payloadSteuern] } as FreigabeAnfordernPayload;
		this.service.calcPayloadSteuern(_steuernData);

		const vorabpauschaleSteuersummenExternal = {
			kestSumme: toExternalModel(this.vorabpauschaleSteuersummen.kestSumme),
			kistSumme: toExternalModel(this.vorabpauschaleSteuersummen.kistSumme),
			soliSumme: toExternalModel(this.vorabpauschaleSteuersummen.soliSumme),
			steuern: [..._steuernData.steuern],
		};
		return vorabpauschaleSteuersummenExternal;
	}

	public abbrechen(): void {
		this.matDialog
			.open(EinreichungAbbrechenPopupComponent)
			.afterClosed()
			.pipe(
				filter(val => val && val.dialogResult === 'Ja'),
				withLatestFrom(this.navigatedTo$),
				switchMap(([val, id]) =>
					this.service.abbrechen$({ id, abbruchStornoGrund: val.abbruchStornoGrund })
				)
			)
			.subscribe({
				next: () => {
					this.reloadRequested$.next();
				},
				error: err => {
					this.alert.error('Fehlgeschlagen', err);
					this.reloadRequested$.next();
				},
			});
	}

	public generatePDF(): void {
		const einreichung = this.pageContent$.value.content;

		if (!einreichung) return;

		const context: PDFContext = {
			bundeslaender: this.kuerzelBezeichnungServiceFactory
				.getService('bundesland')
				.getKuerzelBezeichnungMap(),
			laender: this.kuerzelBezeichnungServiceFactory
				.getService('land')
				.getKuerzelBezeichnungMap(),
			ausweisarten: this.kuerzelBezeichnungServiceFactory
				.getService('ausweisart')
				.getKuerzelBezeichnungMap(),
			konfiguration: this.konfigurationService.konfiguration$.value,
		};

		generatePersonenPDF(einreichung, context);
	}
}

export interface CalculateResultMitEinreichung {
	calculateResult: CalculateGeschaeftsfaelleResult;
	einreichung: Einreichung;
}
