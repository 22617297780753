import { Pipe, PipeTransform } from '@angular/core';
import { KuerzelBezeichnungReferenzlistServiceFactory } from './kuerzel-bezeichnung-referenzlist.service';

@Pipe({
	name: 'kuerzelBezeichnung',
})
export class KuerzelBezeichnungPipe implements PipeTransform {
	constructor(private readonly kuerzelBezeichnungServiceFactory: KuerzelBezeichnungReferenzlistServiceFactory) {}

	transform(value: string | null | undefined, referenzlist: string): string {
		if (!value) return '';
		return this.kuerzelBezeichnungServiceFactory
			.getService(referenzlist)
			.getKuerzelBezeichnungMap()
			.mapOrDefault(value, value);
	}
}
