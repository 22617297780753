<ng-container *ngIf="pageContent$ | async as pageContent">
	<div *ngIf="pageContent.content as gattung; else noContent">
		<div class="page-header">
			<div>
				<a mat-raised-button color="accent" [routerLink]="metadata.routing!.list!.url">
					<mat-icon>list</mat-icon>
					Zurück zur Liste
				</a>
			</div>
			<div>
				<ng-container *requireRole="'standard'">
					<ng-container *ngIf="freigabeInfo$ | async as freigabeInfo">
						<button
							mat-raised-button
							color="primary"
							*ngIf="freigabeInfo.showFreigabeAnfordern"
							[disabled]="freigabeInfo.disableFreigabeAnfordern"
							(click)="freigabeAnfordern()">
							<mat-icon>flaky</mat-icon>
							Freigabe anfordern
						</button>
						<button
							mat-raised-button
							color="primary"
							*ngIf="freigabeInfo.showFreigeben"
							(click)="freigeben()"
							[disabled]="freigabeInfo.disableFreigeben">
							<mat-icon>approval</mat-icon>
							Freigeben
						</button>
						<button
							mat-raised-button
							color="primary"
							*ngIf="freigabeInfo.showInaktivieren"
							(click)="inaktivieren()">
							<mat-icon>visibility_off</mat-icon>
							Inaktivieren
						</button>
					</ng-container>
					<a
						mat-raised-button
						color="primary"
						[routerLink]="metadata.routing!.edit!.url(gattung.id)"
						[queryParams]="{ duplicate: true }">
						<mat-icon>file_copy</mat-icon>
						Kopieren
					</a>
					<a
						mat-raised-button
						color="primary"
						[routerLink]="metadata.routing!.edit!.url(gattung.id)">
						<mat-icon>edit</mat-icon>
						<span
							*ngIf="gattung.status === stammdatenStatus.Inaktiv; else gattungAendern">
							Aktivieren
						</span>
						<ng-template #gattungAendern>Ändern</ng-template>
					</a>
				</ng-container>
			</div>
		</div>

		<div class="page-header">
			<h1>{{ gattung.displayName }} ({{ gattung.status }})</h1>
		</div>

		<mat-tab-group>
			<mat-tab title="Details" label="Details">
				<app-gattung-details
					[gattung]="gattung"
					[showHeader]="false"></app-gattung-details>
			</mat-tab>
			<mat-tab title="Dotationen" label="Dotationen">
				<div class="flex-row">
					<div class="data-column2">
						<div app-data-item [field]="metadata.fields.stueckelungEinzelwert">
							{{
								gattung.stueckelungEinzelwert
									| money
										: gattung.nominalwaehrung
										: metadata.fields.stueckelungEinzelwert
							}}
						</div>
						<div app-data-item [field]="metadata.fields.anzahlAnfang">
							{{ gattung.anzahlAnfang }}
						</div>
						<div app-data-item [field]="metadata.fields.wertAnfang">
							{{
								gattung.wertAnfang
									| money : gattung.nominalwaehrung : metadata.fields.wertAnfang
							}}
						</div>
					</div>
					<div class="data-column2">
						<ng-container *ngIf="dotationInfo$ | async as dotationInfo">
							<div app-data-item [field]="dotationInfoMetadata.fields.anzahlEingeloest">
								{{ dotationInfo.anzahlEingeloest }}
							</div>
							<div
								app-data-item
								[field]="dotationInfoMetadata.fields.anzahlVorlaeufigEingeloest">
								{{ dotationInfo.anzahlVorlaeufigEingeloest }}
							</div>
							<div app-data-item label="Anzahl Rest">
								{{ dotationInfo.anzahlRest }}
							</div>
							<div app-data-item label="Wert Rest">
								{{
									dotationInfo.wertRest
										| money : gattung.nominalwaehrung : metadata.fields.wertAnfang
								}}
							</div>
						</ng-container>
						<!-- <div app-data-item [field]="metadata.fields.anzahlEingeloest">
							{{ gattung.anzahlEingeloest }}
						</div>
						<div app-data-item [field]="metadata.fields.wertEingeloest">
							{{
								gattung.wertEingeloest
									| money: gattung.nominalwaehrung:metadata.fields.stueckelungEinzelwert
							}}
						</div>
						<div app-data-item [field]="metadata.fields.anzahlRest">
							{{ gattung.anzahlRest }}
						</div>
						<div app-data-item [field]="metadata.fields.wertRest">
							{{ gattung.wertRest | money: gattung.nominalwaehrung:metadata.fields.wertRest }}
						</div> -->
					</div>
					<div class="data-column2"></div>
					<div class="data-column2"></div>
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					(freigabeInfo$ | async)?.showFreigabeAnfordern ||
					(freigabeInfo$ | async)?.showFreigeben
				"
				label="Freigabeinfo">
				<ng-template mat-tab-label>
					<div
						*ngIf="
							(freigabeInfo$ | async)?.freigabeFehler?.length;
							else freigabeInfoPlain
						"
						class="error-text">
						<mat-icon class="example-tab-icon">warning</mat-icon>
						Freigabeinfo
					</div>
				</ng-template>
				<mat-list role="list" *ngIf="freigabeInfo$ | async as freigabeInfo">
					<div *ngIf="freigabeInfo.freigabeFehler.length === 0">
						<div *ngIf="gattung.status === stammdatenStatus.InArbeit">
							<span style="margin-right: 1em">Freigabe anfordern möglich</span>
							<button
								mat-raised-button
								color="primary"
								(click)="freigabeAnfordern()"
								*requireRole="'standard'">
								<mat-icon>flaky</mat-icon>
								Freigabe anfordern
							</button>
						</div>
						<div
							*ngIf="gattung.status === stammdatenStatus.ZurFreigabe"
							style="margin-right: 1em">
							<span style="margin-right: 1em">Freigabe möglich</span>
							<button
								mat-raised-button
								color="primary"
								(click)="freigeben()"
								*requireRole="'standard'">
								<mat-icon>approval</mat-icon>
								Freigeben
							</button>
						</div>
					</div>
					<mat-list-item
						role="listitem"
						*ngFor="let freigabeFehler of freigabeInfo.freigabeFehler">
						{{ freigabeFehler }}
					</mat-list-item>
				</mat-list>
			</mat-tab>
			<mat-tab title="Steuern" label="Steuern" *requireRole="'standard'">
				<app-gattung-steuer [gattung]="gattung"></app-gattung-steuer>
			</mat-tab>
		</mat-tab-group>
	</div>

	<ng-template #noContent>
		<mat-spinner *ngIf="pageContent.isLoading; else error"></mat-spinner>
	</ng-template>

	<ng-template #error>
		<div>Gattung konnte nicht geladen werden!</div>
		<div *ngIf="pageContent.error">{{ pageContent.error }}</div>
	</ng-template>
</ng-container>

<ng-template #freigabeInfoPlain>Freigabeinfo</ng-template>
