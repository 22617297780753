import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { geschaeftsfallMetadata } from 'src/app/bewegungsdaten/model';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';

@Component({
	selector: 'app-geschaeftsfall-abbrechen-popup',
	templateUrl: './geschaeftsfall-abbrechen-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallAbbrechenPopupComponent {
	public readonly metadata = geschaeftsfallMetadata;
	public readonly form = new TypedForm<{ abbruchStornoGrund: string }>({
		abbruchStornoGrund: createFormControl(geschaeftsfallMetadata.fields.abbruchStornoGrund),
	});
	readonly cannotAccept$ = this.form.statusChanges.pipe(
		map(s => s !== 'VALID'),
		startWith(true)
	);
	constructor(
		private ref: MatDialogRef<GeschaeftsfallAbbrechenPopupComponent>,
		@Inject(MAT_DIALOG_DATA) data: { abbruchStornoGrund: string | undefined | null }
	) {
		this.ref.backdropClick().subscribe(b => this.tryCancel());
		this.form.setValue({ abbruchStornoGrund: data.abbruchStornoGrund || '' });
		this.form.controls.abbruchStornoGrund.addValidators([Validators.required]);
	}

	tryCancel(): void {
		this.ref.close();
	}
	accept(): void {
		this.ref.close({ dialogResult: 'Ja', ...this.form.typedValue });
	}
}
