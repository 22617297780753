import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'kontonummer',
})
export class KontonummerPipe implements PipeTransform {
	transform(value: string | undefined | null): string {
		if (!value) return '';
		let output = '';
		for (let index = 0; index < value.length; index += 4) {
			output += ' ' + value.substring(index, Math.min(index + 4, value.length + 1));
		}
		return output.substring(1);
	}
}
