<div class="flex-column">
	<mat-card class="flex-column-mat-card">
		<form (ngSubmit)="search()" [formGroup]="searchForm">
			<div class="page-header">
				<h1>Compliance Geschäftsfallliste</h1>
				<button
					mat-raised-button
					color="accent"
					[disabled]="!(existsAllSearchCriteria$ | async)"
					(click)="downloadAsCsv()">
					<mat-icon *ngIf="isLoading$ | async | not">file_download</mat-icon>
					<mat-spinner
						*ngIf="isLoading$ | async"
						[diameter]="20"
						style="display: inline-block; margin-right: 0.5em"></mat-spinner>
					Als CSV herunterladen
				</button>
			</div>
			<div style="display: flex; gap: 1em; margin-bottom: 1em">
				<label style="margin-top: 2em">Suchkriterium:</label>
				<mat-form-field style="flex-basis: 22em">
					<mat-label>Wähle Zeitraum für Auswertung</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input
							readonly
							matStartDate
							placeholder="Beginn"
							formControlName="datumAb" />
						<input
							readonly
							matEndDate
							placeholder="Ende"
							formControlName="datumBis" />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					style="margin: 1em 0 1em 0"
					[disabled]="!(existsAllSearchCriteria$ | async)">
					Suchen...
				</button>
			</div>
		</form>
	</mat-card>

	<div style="overflow: auto; max-height: 65vh;">
	<table
		mat-table
		[dataSource]="dataSource"
		style="width: 100%"
		id="ultimo-einreichungen-table"
		matSort>
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				[matTooltip]="'Name, Vorname'"
				mat-sort-header>
				Name
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
				{{ element.name }}, {{ element.vorname }}
			</td>
		</ng-container>
		<ng-container matColumnDef="rolle">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				[matTooltip]="'Rolle (Einreicher, Wirtschaftlich Berechtigter)'"
				mat-sort-header>
				Rolle
			</th>
			<td mat-cell *matCellDef="let element"
			style="text-overflow: ellipsis; overflow-x: hidden; padding-left: 1em">
				{{ element.rolle }}
			</td>
		</ng-container>
		<ng-container matColumnDef="anschrift">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				[matTooltip]="'Anschrift (PLZ, Stadt, Strasse, Adresszusatz, Bundesland, Land)'"
				mat-sort-header>
				Anschrift
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				style="
					text-overflow: ellipsis;
					overflow-x: hidden;
					padding-left: 1em;
				">
				{{ element.postleitzahl }} {{ element.stadt }} {{ element.adresse }}
				{{ element.adresszusatz }} {{ element.bundesland }} {{ element.land }}
			</td>
		</ng-container>
		<ng-container matColumnDef="geburtsdatum">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				mat-sort-header>
				{{ personMetadata.fields.geburtsdatum.label }}
			</th>
			<td mat-cell *matCellDef="let element" 
			style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
				{{ element.geburtsdatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="staatsangehoerigkeit">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<!-- {{ personMetadata.fields.staatsangehoerigkeit.label }} -->
				Staats-<br>angehörigkeit
			</th>
			<td mat-cell *matCellDef="let element"
			style="text-align: center">
				{{ element.staatsangehoerigkeit }}
			</td>
		</ng-container>
		<ng-container matColumnDef="einreichungenHoehe">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				class="text-right"
				style="width: 8em"
				mat-sort-header>
				Nominal
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 3em">
				{{ element.einreichungenHoehe }}
			</td>
		</ng-container>
		<ng-container matColumnDef="isinWkn">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				[matTooltip]="'ISIN / WKN (Land)'"
				mat-sort-header>
				ISIN / WKN<br>(Land)
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.isin }} / {{ element.wkn }} ({{ element.land }})
			</td>
		</ng-container>
		<ng-container matColumnDef="geschaeftsfallNummer">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				class="text-right"
				style="white-space: nowrap; width: 10em; padding-left: 1em"
				mat-sort-header>
				Geschäftsfall-<br>nummer
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 3em;">
				{{ element.geschaeftsfallNummer }}
			</td>
		</ng-container>
		<ng-container matColumnDef="pruefungsergebnis">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				mat-sort-header>
				Prüfungs-<br>ergebnis
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.pruefungsergebnis }}
			</td>
		</ng-container>
		<ng-container matColumnDef="pruefungsdatum">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				mat-sort-header>
				Prüfungs-<br>datum
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.pruefungsdatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="geschaeftsfallAbschlussdatum">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				mat-sort-header>
				Abschluss-<br>datum
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.geschaeftsfallAbschlussdatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="kycPruefnummer">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				mat-sort-header>
				PEP-<br>Prüfung
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em" >
				{{ element.kycPruefnummer }}
			</td>
		</ng-container>
		<ng-container matColumnDef="embargoPruefnummer">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="white-space: nowrap; width: 12em; padding-left: 1em"
				mat-sort-header>
				Embargo-<br>Prüfung
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em" >
				{{ element.embargoPruefnummer }}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
	</div>
	
	<mat-paginator showFirstLastButtons></mat-paginator>
</div>
