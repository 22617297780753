<div class="mat-elevation-z2 flex-column">
	<table mat-table [dataSource]="dataSource" matSort matSortActive="jahr" matSortDirection="asc">
		<ng-container matColumnDef="jahr">
			<th mat-header-cell *matHeaderCellDef scope="col">
				{{ metadata!.fields.jahr.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				<app-numeric-input
					[metadata]="metadata.fields.jahr"
					[useGrouping]="false"
					[formControl]="element.controls.jahr"></app-numeric-input>
			</td>
		</ng-container>

		<ng-container matColumnDef="betragVorabp">
			<th mat-header-cell *matHeaderCellDef scope="col">
				{{ metadata!.fields.betragVorabp.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				<app-numeric-input
					[metadata]="metadata.fields.betragVorabp"
					[formControl]="element.controls.betragVorabp"></app-numeric-input>
			</td>
		</ng-container>

		<ng-container matColumnDef="betragStpflAnteil">
			<th mat-header-cell *matHeaderCellDef scope="col">
				{{ metadata!.fields.betragStpflAnteil.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				<app-numeric-input
					[metadata]="metadata.fields.betragStpflAnteil"
					[formControl]="element.controls.betragStpflAnteil"></app-numeric-input>
			</td>
		</ng-container>

		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 4em"></th>
			<td mat-cell *matCellDef="let element" style="text-align: right">
				<button
					mat-icon-button
					color="primary"
					type="button"
					(click.stop)="deleteYear(element)"
					style="margin-left: 2em">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
	</table>
	<div style="padding: 0.5em; display: flex; flex-flow: row-reverse">
		<button mat-raised-button color="primary" (click.stop)="addYear()" type="button">
			<mat-icon>add</mat-icon>
			Neue Vorabpauschale
		</button>
	</div>
</div>
