import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { QuickInfoPopupService } from '../../quick-info-popup.service';
import { Gattung, gattungMetadata } from '../model';

@Component({
	selector: 'app-gattung-list-page',
	templateUrl: './gattung-list-page.component.html',
	styleUrls: ['./gattung-list-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungListPageComponent {
	public readonly metadata = gattungMetadata;

	constructor(private readonly quickInfo: QuickInfoPopupService, private readonly router: Router) {}

	openPopup(gattung: Gattung): void {
		this.quickInfo.showGattung(gattung);
	}

	navigate(gattung: Gattung): void {
		this.router.navigate([this.metadata.routing.view!.url(gattung.id)]);
	}
}
