<ng-container *ngIf="pageContent$ | async as pageContent">
	<div *ngIf="pageContent.content as geschaeftsfallMitPositionen; else noContent">
		<div class="page-header">
			<div>
				<a
					*ngIf="userHasRoleAnteilsscheingeschaeft$ | async; else userHasOtherRoles"
					mat-raised-button
					color="accent"
					[routerLink]="metadata.routing!.list!.url"
					[queryParams]="{ status: geschaeftsfallStatus.ZumUebertrag }">
					<mat-icon>list</mat-icon>
					Zurück zur Liste
				</a>
				<ng-template #userHasOtherRoles>
					<a mat-raised-button color="accent" [routerLink]="metadata.routing!.list!.url">
						<mat-icon>list</mat-icon>
						Zurück zur Liste
					</a>
				</ng-template>
			</div>
			<div>
				<a
					*ngIf="canGenerateKundendokumente$ | async"
					mat-raised-button
					color="primary"
					(click)="kundendokumenteGenerieren()"
					[disabled]="isGeneratingKundendokumente$ | async">
					<mat-icon>print</mat-icon>
					<mat-spinner
						*ngIf="isGeneratingKundendokumente$ | async"
						[diameter]="20"
						[color]="'accent'"
						style="display: inline-block; margin-right: 0.5em; margin-left: 0.5em"></mat-spinner>
					Kundendokumente
				</a>
				<a
					*ngIf="showInkassoDruckAnfordern$ | async"
					mat-raised-button
					color="primary"
					[disabled]="isProcessing$ | async"
					(click)="inkassodruck()">
					<mat-icon *ngIf="!(isProcessing$ | async)">print</mat-icon>
					<mat-spinner
						*ngIf="isProcessing$ | async"
						[diameter]="20"
						[color]="'accent'"
						style="display: inline-block; margin-right: 0.5em; margin-left: 0.5em"></mat-spinner>
					Inkassodruck
				</a>
				<a mat-raised-button color="accent" (click)="reloadRequested$.next()">
					<mat-icon>refresh</mat-icon>
					Aktualisieren
				</a>
				<a *ngIf="canDoStorno$ | async" mat-raised-button color="primary" (click)="stornieren()">
					<mat-icon>delete_forever</mat-icon>
					Storno
				</a>
				<a *ngIf="canDoAbbrechen$ | async" mat-raised-button color="primary" (click)="abbrechen()">
					<mat-icon>delete_forever</mat-icon>
					Abbrechen
				</a>
				<a *ngIf="canDoAbschliessen$ | async" mat-raised-button color="primary" (click)="abschliessen()">
					<mat-icon>check_circle_outline</mat-icon>
					Abschliessen
				</a>
				<a
					*ngIf="canDoNeuabrechnen$ | async"
					mat-raised-button
					color="primary"
					[routerLink]="einreichungMetadata.routing.edit!.url(geschaeftsfallMitPositionen.einreichung.id)"
					[queryParams]="{ neuabrechnung: geschaeftsfallMitPositionen.geschaeftsfall.id }">
					<mat-icon>functions</mat-icon>
					Neu abrechnen
				</a>
			</div>
		</div>

		<div class="page-header">
			<div>
				<h1>Geschäftsfall {{ geschaeftsfallMitPositionen.geschaeftsfall.nummer }}</h1>
				<div class="text-primary">{{ geschaeftsfallMitPositionen.geschaeftsfall.status }}</div>
			</div>
		</div>

		<mat-tab-group>
			<mat-tab title="Details" label="Details">
				<div style="padding-top: 1em">
					<app-geschaeftsfall
						showHeader="false"
						[geschaeftsfallMitPositionen]="geschaeftsfallMitPositionen"
						embeddedMode="false"
						[userHasRoleAnteilsscheingeschaeft]="
							!!(userHasRoleAnteilsscheingeschaeft$ | async)
						"></app-geschaeftsfall>
				</div>
			</mat-tab>

			<mat-tab title="Einreichung" label="Einreichung">
				<app-einreichung-details
					[einreichung]="geschaeftsfallMitPositionen.einreichung"></app-einreichung-details>
			</mat-tab>

			<ng-container *requireRole="'standard'">
				<mat-tab *ngIf="showInkassoBearbeitung$ | async" color="primary">
					<ng-template mat-tab-label>
						<span class="text-primary" style="font-weight: bold">
							<mat-icon>payments</mat-icon>
							<span style="margin-left: 1em">Inkasso</span>
						</span>
					</ng-template>

					<app-inkasso-bearbeitung
						[geschaeftsfallMitPositionen]="geschaeftsfallMitPositionen"
						(reload$)="reloadRequested$.next()"></app-inkasso-bearbeitung>
				</mat-tab>
			</ng-container>

			<mat-tab title="kycPrüfungen" label="KYC-Prüfungen" *ngIf="showKycChecksGfWhiteList.includes(geschaeftsfallMitPositionen.geschaeftsfall.status)">
				<ng-template matTabContent>
					<div *ngFor="let person of personen">
						<mat-accordion multi>
							<mat-expansion-panel [expanded]="true">
								<mat-expansion-panel-header style="font-weight: bold">
									{{ person | personName }}
									{{ (person | personRole) ? '(' + (person | personRole) + ')' : '' }}
								</mat-expansion-panel-header>

								<app-einreichung-view-personen-kycchecks 
								[person]="person"></app-einreichung-view-personen-kycchecks>
								
								<form mat-dialog-content [formGroup]="form">
									<div *ngIf="person.istWirtschaftlichBerechtigter">
										<div class="flex-column">
											<div class="flex-row">
												<app-text-input
												formControlName="pepPruefungsnummer"
												[metadata]="kycMetadata.fields.pepPruefungsnummer" 
												(input)="updateValidity()" 
												(focusout)="updateValidity()"></app-text-input>

												<app-text-input
												formControlName="embargoPruefungsnummer"
												[metadata]="kycMetadata.fields.embargoPruefungsnummer" 
												(input)="updateValidity()" 
												(focusout)="updateValidity()"></app-text-input>

												<app-fixedlist-input
												formControlName="resultat"
												[metadata]="kycMetadata.fields.resultat" 
												(input)="updateValidity()" 
												(focusout)="updateValidity()"></app-fixedlist-input>

												<app-date-input
												[metadata]="kycMetadata.fields.datum"
												formControlName="datum" 
												(input)="updateValidity()" 
												(focusout)="updateValidity()"></app-date-input>
											</div>
											<div class="flex-row">
												<app-text-input
												formControlName="kommentar"
												[metadata]="kycMetadata.fields.kommentar" 
												(input)="updateValidity()" 
												(focusout)="updateValidity()"></app-text-input>
											</div>
											<div mat-dialog-actions style="display: flex">
												<span style="flex: 1"></span>
												<div matTooltip="Zum Speichern einer Prüfung bitte alle Felder füllen!">
													<button
														mat-raised-button
														color="primary"
														(click)="accept(person)"
														[disabled]="!canAccept">
														<mat-icon>check</mat-icon>
														KYC-Prüfung anlegen
													</button>
												</div>
											</div>
										</div>
										<hr />
									</div>
								</form>
							</mat-expansion-panel>
						</mat-accordion>
					</div>
				</ng-template>
			</mat-tab>

			<mat-tab title="Steuern" label="Steuern">
				<app-geschaeftsfall-steuern
					[geschaeftsfallMitPositionen]="geschaeftsfallMitPositionen"></app-geschaeftsfall-steuern>
			</mat-tab>

			<mat-tab title="Buchungen" label="Buchungen">
				<app-geschaeftsfall-buchungen
					[geschaeftsfallMitPositionen]="geschaeftsfallMitPositionen"></app-geschaeftsfall-buchungen>
			</mat-tab>
		</mat-tab-group>
	</div>

	<ng-template #noContent>
		<mat-spinner *ngIf="pageContent.isLoading; else error"></mat-spinner>
	</ng-template>

	<ng-template #error>
		<div>Geschäftsfall mit Positionen konnte nicht geladen werden!</div>
		<div *ngIf="pageContent.error">{{ pageContent.error }}</div>
	</ng-template>
</ng-container>
