<ng-container>
	<div class="page-header">
		<h1>Feiertagskalender</h1>
		<button mat-raised-button color="primary" (click)="reload()">
			<mat-icon>refresh</mat-icon>
			Liste neu laden
		</button>
		<ng-container *requireRole="'erweitert'">
			<button mat-raised-button color="primary" (click)="openPopup()">
				<mat-icon>add</mat-icon>
				Neu
			</button>
		</ng-container>
	</div>

	<div class="mat-elevation-z2" style="margin-bottom: 1em">
		<div class="flex-row">
			<div style="flex: 1"></div>
			<span>
				Jahr
				<button mat-icon-button (click)="goPreviousYear()">
					<mat-icon>keyboard_arrow_left</mat-icon>
				</button>
				{{ currentYear$ | async }}
				<button mat-icon-button (click)="goNextYear()">
					<mat-icon>keyboard_arrow_right</mat-icon>
				</button>
			</span>
			<div style="flex: 1"></div>
		</div>
	</div>

	<div class="mat-elevation-z2" *ngIf="metadata">
		<table mat-table [dataSource]="dataSource" [trackBy]="trackBy">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef scope="col">
					{{ metadata!.fields.name.label }}
				</th>
				<td mat-cell *matCellDef="let element">{{ element.name }}</td>
			</ng-container>

			<ng-container matColumnDef="erstesAuftreten">
				<th mat-header-cell *matHeaderCellDef scope="col">
					{{ metadata!.fields.erstesAuftreten.label }}
				</th>
				<td mat-cell *matCellDef="let element">{{ element.erstesAuftreten | date: 'dd.MM.' }}</td>
			</ng-container>

			<ng-container matColumnDef="istWiederkehrend">
				<th mat-header-cell *matHeaderCellDef scope="col">
					{{ metadata!.fields.istWiederkehrend.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.istWiederkehrend | booleanText }}
					{{
						element.istWiederkehrend ? '(seit ' + (element.erstesAuftreten | date: 'dd.MM.yyyy') + ')' : ''
					}}
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef scope="col" style="width: 5em"></th>
				<td mat-cell *matCellDef="let element" style="white-space: nowrap">
					<button
						mat-icon-button
						(click.stop)="openPopup(element)"
						matTooltip="Ändern"
						*requireRole="'erweitert'">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="primary" (click.stop)="remove(element)" *requireRole="'erweitert'">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				class="mat-row-clickable"
				(click)="openPopup(row)"></tr>
		</table>
	</div>
</ng-container>
