import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackendVersion } from './init/init.service';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	public config: AppConfig = { noconfig: false } as unknown as AppConfig;

	public get isDevelopment(): boolean {
		return !this.config.production;
	}

	public readonly backendVersion$ = new BehaviorSubject<BackendVersion>({
		beBuildDateTime: '??',
		beGitCommit: '??',
		releaseVersion: '??',
	});
	public readonly frontendVersion$ = new BehaviorSubject<string>('UNBEKANNT');
	public readonly frontendBuildDate$ = new BehaviorSubject<string>('UNBEKANNT');
}

export interface AppConfig {
	backendUrl: string;
	sendLogsToServer?: boolean;
	fakeLogin?: boolean;
	production?: boolean;
	enableAutoDataRefresh?: boolean;
}
