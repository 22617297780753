import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, startWith } from 'rxjs';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from '../kuerzel-bezeichnung-referenzlist.service';
import { KuerzelBezeichnungReferenz, KuerzelBezeichnungReferenzlistMetadata } from '../model';

@Component({
	selector: 'app-kuerzel-bezeichnung-popup',
	templateUrl: './kuerzel-bezeichnung-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuerzelBezeichnungPopupComponent {
	public readonly form: TypedForm<FormValues>;
	public readonly isSaving$ = new BehaviorSubject(false);
	public readonly cannotSave$: Observable<boolean>;
	private readonly service: KuerzelBezeichnungReferenzlistService;

	constructor(
		@Inject(MAT_DIALOG_DATA) public readonly data: KuerzelBezeichnungPopupData,
		private readonly ref: MatDialogRef<KuerzelBezeichnungPopupComponent>,
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private readonly alert: AlertService
	) {
		this.service = serviceFactory.getService(data.metadata.apiCollectionName);
		this.form = new TypedForm<FormValues>({
			kuerzel: createFormControl(data.metadata.kuerzel),
			bezeichnung: createFormControl(data.metadata.bezeichnung),
			extra: data.metadata.extra ? createFormControl(data.metadata.extra) : new UntypedFormControl(),
		});

		this.cannotSave$ = combineLatest([
			this.form.statusChanges.pipe(
				map(s => s === 'VALID'),
				startWith(false)
			),
			this.isSaving$,
		]).pipe(map(([isValid, isSaving]) => !isValid || isSaving));

		if (data.item) {
			let item = data.item as any;
			if (data.metadata.extra?.typ === 'number' && item.extra) {
				item = { ...item, extra: Number(item.extra) };
			}
			this.form.reset(item);
			this.form.controls.kuerzel.disable({ emitEvent: false });
		} else {
			this.form.controls.kuerzel.addValidators(c => checkReferenzExists(c.value, this.service.list$));
		}
	}

	save(): void {
		if (this.form.invalid) return;

		const value = this.form.rawTypedValue;
		this.isSaving$.next(true);
		this.form.disable({ emitEvent: false });
		this.service.save$(value).subscribe({
			next: () => {
				this.alert.success('Referenzlisteintrag gespeichert');
				this.service
					.loadAll$()
					.pipe(catchError((err: Error) => of(err)))
					.subscribe(maybeError => {
						if (maybeError instanceof Error) {
							this.alert.error(
								'Der Eintrag ist gespeichert, aber die Liste konnte nicht durchgeladen werden',
								maybeError
							);
						}

						this.ref.close();
					});
			},
			error: err => {
				this.alert.error('Fehler beim Speichern!', err);
				this.isSaving$.next(false);
				this.form.enable({ emitEvent: false });
			},
		});

		//this.ref.close();
	}
}

export interface KuerzelBezeichnungPopupData {
	item?: KuerzelBezeichnungReferenz;
	metadata: KuerzelBezeichnungReferenzlistMetadata;
}

interface FormValues {
	kuerzel: string;
	bezeichnung: string;
	extra: string | number;
}

function checkReferenzExists(
	value: string,
	list$: BehaviorSubject<KuerzelBezeichnungReferenz[]>
): ValidationErrors | null {
	if (!value) return null;
	value = value.toLocaleUpperCase();
	const duplicate = list$.value.find(item => item.kuerzel.toLocaleUpperCase() === value);
	if (!duplicate) return null;
	return { duplicateReferenz: duplicate };
}
