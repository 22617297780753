import { Pipe, PipeTransform } from '@angular/core';
import { Religionszugehoerigkeit, religionszugehoerigkeitBezeichnung } from '../model';

@Pipe({
	name: 'religionszugehoerigkeit',
})
export class ReligionszugehoerigkeitPipe implements PipeTransform {
	transform(value?: Religionszugehoerigkeit): string {
		return (value ? religionszugehoerigkeitBezeichnung[value] : null) ?? '';
	}
}
