<ng-container *ngIf="metadata$ | async as metadata">
	<div class="page-header">
		<h1>{{ title$ | async }}</h1>
		<button mat-raised-button color="accent" (click)="downloadAsExcel()">
			<mat-icon>file_download</mat-icon>
			Als Excel herunterladen
		</button>
		<ng-container *requireRole="'erweitert'">
			<button
				mat-raised-button
				color="accent"
				(click)="loadFromExcel()"
				*ngIf="!metadata.readonly">
				<mat-icon>file_upload</mat-icon>
				Aus Excel hochladen
			</button>
		</ng-container>
		<button mat-raised-button color="primary" (click)="reload()">
			<mat-icon>refresh</mat-icon>
			Liste neu laden
		</button>
		<ng-container *requireRole="'erweitert'">
			<button
				mat-raised-button
				color="primary"
				(click)="openPopup()"
				*ngIf="!metadata.readonly">
				<mat-icon>add</mat-icon>
				Neu
			</button>
		</ng-container>
	</div>

	<div class="flex-column mat-elevation-z2">
		<app-kuerzel-bezeichnung-list
			[metadata]="metadata"
			(selected$)="openPopup($event)"
			[enableActions]="!metadata.readonly"></app-kuerzel-bezeichnung-list>
	</div>
</ng-container>
