import { forwardRef } from '@angular/core';
import { EntityMetadata, RequiredLevel } from 'src/app/model';
import { FeiertagskalenderListComponent } from './feiertagskalender-list/feiertagskalender-list.component';

export interface Feiertag {
	id: string; // bei anlage undefined
	name: string;
	erstesAuftreten: Date;
	istWiederkehrend: boolean;
}

export const feiertagskalenderMetadata: EntityMetadata<Feiertag> = {
	label: 'Feiertagskalender',
	labelGender: 'm',
	apiCollectionName: 'feiertag',
	plural: 'Feiertage',
	routing: {
		list: { url: '/feiertagskalender', component: forwardRef(() => FeiertagskalenderListComponent) },
	},
	fields: {
		name: {
			typ: 'text',
			maxLength: 200,
			label: 'Name',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Bezeichnung des Feiertags',
		},
		erstesAuftreten: {
			typ: 'datum',
			label: 'Datum',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Datum des Feiertags',
		},
		istWiederkehrend: {
			//undefined beim speichern auf false gesetzt
			typ: 'boolean',
			label: 'Ist wiederkehrend',
			description: 'Feiertag mit festem Datum (wiederkehrend) oder beweglicher Feiertag',
		},
	},
};
