<h1 mat-dialog-title>Schnellerfassung</h1>
<form mat-dialog-content style="height: 60vh; overflow-y: hidden; display: flex; gap: 1em; flex-direction: column"
	[formGroup]="form">

	<div class="flex-row" style="flex: auto 0 0">
		<app-text-input label="ISIN oder WKN" formControlName="isinOrWkn" style="flex: 20em 0 0"></app-text-input>
		<div style="flex: auto 0 0; display: flex; flex-direction: column; gap: 5px">
			<mat-slide-toggle formControlName="mantel">Mantel</mat-slide-toggle>
			<mat-slide-toggle formControlName="kupons">Kupons</mat-slide-toggle>
		</div>
		<app-text-input label="Kuponnummer von" formControlName="kuponnummern"></app-text-input>
	</div>

	<div class="flex-row" style="flex: auto 0 0">
		<app-numeric-input [metadata]="positionMetadata.fields.anteil" formControlName="anteil"></app-numeric-input>
		<app-numeric-input [metadata]="positionMetadata.fields.anzahl" formControlName="anzahl"></app-numeric-input>
		<app-string-array-input [metadata]="positionMetadata.fields.stueckenummer" style="flex: 3"
			formControlName="stueckenummer"></app-string-array-input>


	</div>

	<div *ngIf="errorMessage$ | async as errorMessage" class="error-text">
		{{ errorMessage }}
	</div>
	<div style="display:flex; flex: auto 0 0;">
		<button style="margin-left: auto;" mat-raised-button color="primary" (click)="search()"
			[disabled]="(isSearching$ | async) || (cannotSearch$ | async )">
			Suchen
		</button>
	</div>

	<div style="flex: 0em 1 1; overflow-y: scroll">
		<table mat-table [dataSource]="dataSource" class="sticky-thead" matSort matSortActive="zahlbarkeitstag"
			matSortDirection="asc">
			<ng-container matColumnDef="wkzString">
				<th mat-header-cell *matHeaderCellDef scope="col" style="width: 80px">WKZ</th>
				<td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 1em">
					{{ element.wkzString }}
				</td>
			</ng-container>

			<ng-container matColumnDef="gattungsbezeichnung">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bezeichnung</th>
				<td class="text-hide-overflow" mat-cell *matCellDef="let element"
					[matTooltip]="element.gattungsbezeichnung" class="text-hide-overflow">
					<button *ngIf="element.gattungId" mat-icon-button color="accent"
						(click.stop)="quickInfo.showGattung(element.gattungId)">
						<mat-icon>preview</mat-icon>
					</button>
					{{ element.bezeichnung }}
				</td>
			</ng-container>

			<ng-container matColumnDef="kuponnummer">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Kuponnummer</th>
				<td mat-cell *matCellDef="let element">
					{{ element.kuponnummer }}
				</td>
			</ng-container>

			<ng-container matColumnDef="zahlbarkeitstag">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					Zahlbarkeitstag
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.zahlbarkeitstag | date : 'dd.MM.yyyy' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Status</th>
				<td mat-cell *matCellDef="let element">
					<ng-container [ngSwitch]="element.status">
						<mat-icon *ngSwitchCase="'Gattung nicht vorhanden'" style="color: red">
							close
						</mat-icon>
					</ng-container>
					{{ element.status }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

			<tr mat-row *matRowDef="let row; columns: displayedColumns" class="mat-row-clickable"></tr>
		</table>
	</div>



</form>

<div mat-dialog-actions style="display: flex">
	<button mat-raised-button mat-dialog-close color="accent" (click)="tryCancel()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" (click)="accept()" [disabled]="cannotAccept$ | async">
		<mat-icon>check</mat-icon>
		Akzeptieren
	</button>
</div>