<div class="flex-column">
	<mat-card class="flex-column-mat-card">
		<form [formGroup]="filterForm">
			<div class="page-header">
				<h1>KESt Meldung</h1>
				<button mat-raised-button color="accent" (click)="downloadAsExcel()">
					<mat-icon *ngIf="isLoading$ | async | not">file_download</mat-icon>
					<mat-spinner
						*ngIf="isLoading$ | async"
						[diameter]="20"
						style="display: inline-block; margin-right: 0.5em"></mat-spinner>
					Als Excel herunterladen
				</button>
			</div>
			<div style="display: flex; gap: 1em; margin-bottom: 1em">
				<label style="margin-top: 2em">Bitte auswählen:</label>
				<mat-form-field style="margin-right: 2em">
					<mat-label>Auswertungsmonat</mat-label>
					<input
						autocomplete="off"
						readonly
						[max]="dateNow"
						matInput
						[matDatepicker]="picker"
						formControlName="datum"
						(click)="openDatePicker()" />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker
						#picker
						startView="year"
						(monthSelected)="closeDatePicker($event)"></mat-datepicker>
				</mat-form-field>
			</div>
		</form>
	</mat-card>

	<table
		mat-table
		[dataSource]="dataSource"
		style="width: 100%"
		id="ultimo-kest-table"
		matSort
		[trackBy]="trackBy">
		<ng-container matColumnDef="bundesland">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.fields.bundesland.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.bundesland }}</td>
			<td mat-footer-cell *matFooterCellDef>
				<strong>Summe</strong>
			</td>
		</ng-container>
		<ng-container matColumnDef="kuerzel">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.fields.kuerzel.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.kuerzel }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<ng-container matColumnDef="nummer">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.fields.nummer.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.nummer }}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<ng-container matColumnDef="summe">
			<th mat-header-cell *matHeaderCellDef scope="col" class="text-right" mat-sort-header>
				{{ metadata.fields.summe.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: right">
				{{ element.summe | number : '1.2-2' : 'de' }} EUR
			</td>
			<td mat-footer-cell *matFooterCellDef style="text-align: right">
				<strong>{{ sumOfSums | number : '1.2-2' : 'de' }} EUR</strong>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>
</div>
