import { Einreichung } from './../model';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { logger } from 'src/logger';
import { EinreichungService } from '../einreichung.service';
import { isThisISOWeek, isThisWeek } from 'date-fns';

@Component({
	selector: 'app-einreichung-filenet-import',
	templateUrl: './einreichung-filenet-import.component.html',
	styleUrls: ['./einreichung-filenet-import.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungFilenetImportComponent extends BaseComponent implements OnInit {
	readonly file$ = new BehaviorSubject<File | null>(null);
	readonly parseError$ = new BehaviorSubject<string>('');

	constructor(
		private ref: MatDialogRef<EinreichungFilenetImportComponent>,
		@Inject(MAT_DIALOG_DATA) public readonly data: Einreichung,
		public einreichungService: EinreichungService
	) {
		super();
	}

	ngOnInit(): void {
		this.registerSubscription(
			this.ref.afterClosed().subscribe(() => {
				console.log('Import-Dialog wird geschlossen.');
				this.clearDialogState();
			})
		);
	}

	onCancel() {
		this.ref.close();
	}

	onSubmit(form: NgForm): void {
		logger.log('Submit file!');
		this.einreichungService.filenetImport(
			this.file$.value as File,
			this.data.id,
			this.data.nummer
		);
	}

	onFileSelected(event: any) {
		logger.log('Enter onFileSelected: ', event);

		this.clearDialogState();
		const selectedFile = (event.target as HTMLInputElement).files?.item(0);

		if (selectedFile) {
			if (selectedFile.name.toUpperCase().endsWith('EXE')) {
				this.parseError$.next('Datei darf kein ausführbares Programm (*.exe) sein!');
				return;
			}
			this.file$.next(selectedFile as File);
		}
	}

	protected get importResult$() {
		return this.einreichungService.fileNetImportResult$;
	}

	public clearDialogState(): void {
		this.einreichungService.isFileNetImportDone$.next(false);
		this.einreichungService.fileNetImportResult$.next('');
		this.parseError$.next('');
		this.file$.next(null);
	}

	protected get text4CancelBtn(): string {
		let ret = 'Abbrechen';
		if (this.einreichungService.isFileNetImportDone$.value === true) {
			ret = 'Ok';
		}
		return ret;
	}

	protected get showImportBtn(): boolean {
		return this.text4CancelBtn === 'Abbrechen';
	}
}
