<div class="flex-column">
	<mat-card class="flex-column-mat-card">
		<form (ngSubmit)="search()" [formGroup]="searchForm">
			<div class="page-header">
				<h1>Buchungen</h1>
				<button
					mat-raised-button
					color="accent"
					(click)="downloadAsExcel(dataSource.filteredData)">
					<mat-icon>file_download</mat-icon>
					Als Excel herunterladen
				</button>
			</div>
			<div style="display: flex; gap: 1em; margin-bottom: 1em">
				<label for="buc-search" style="margin-top: 2em">
					Suchkriterium {{ metadata.fields.verarbeitungszeitpunkt.label }}:
				</label>
				<app-date-input
					id="buc-search"
					formControlName="date"
					placeholder="Wähle Tag"></app-date-input>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					style="margin: 1em 0 1em 0">
					Suchen...
				</button>
			</div>
		</form>
	</mat-card>

	<mat-card [hidden]="isLoading$ | async">
		<mat-card-header>
			<h2 style="margin-left: -0.5em">
				Übersicht der Buchungen {{ searchDate$ | async | date : 'dd.MM.yyyy' }}
			</h2>
		</mat-card-header>
		<table
			mat-table
			[dataSource]="dataSource"
			style="width: 100%"
			matSort
			[trackBy]="trackBy"
			[hidden]="isLoading$ | async">
			<ng-container matColumnDef="kontonummer">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					{{ metadata.fields.kontonummer.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					<span>{{ element.kontonummer | kontonummer }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="buchungsdatum">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					{{ metadata.fields.buchungsdatum.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					<span>{{ element.buchungsdatum | date : 'dd.MM.yyyy' }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="valutatag">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					{{ metadata.fields.valutatag.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					<span>{{ element.valutatag | date : 'dd.MM.yyyy' }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="betrag">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="padding-right: 3em"
					mat-sort-header>
					{{ metadata.fields.betrag.label }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
					style="text-align: right; padding-right: 3em">
					<span>{{ element.betrag | efaNumber }} EUR</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="isin">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					{{ metadata.fields.geschaeftsfallIsin.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					<span>{{ element.geschaeftsfallIsin }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="geschaeftsfallNummer">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					{{ metadata.fields.geschaeftsfallNummer.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					<span>{{ element.geschaeftsfallNummer }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="verarbeitungszeitpunkt">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
					{{ metadata.fields.verarbeitungszeitpunkt.label }}
				</th>
				<td mat-cell *matCellDef="let element">
					<span>{{ element.verarbeitungszeitpunkt | date : 'dd.MM.yyyy HH:mm:ss' }}</span>
				</td>
			</ng-container>

			<ng-container *matHeaderRowDef="displayedColumns">
				<tr mat-header-row></tr>
				<tr class="mat-header-row" [formGroup]="filterForm" class="table-filter-row">
					<th class="mat-header-cell" scope="col">
						<mat-form-field appearance="fill">
							<input
								matInput
								placeholder="Suche..."
								formControlName="kontonummer"
								autocomplete="off" />
						</mat-form-field>
					</th>
					<th class="mat-header-cell" scope="col">
						<mat-form-field appearance="fill">
							<mat-date-range-input [rangePicker]="buchungsdatumPicker">
								<input
									matStartDate
									placeholder="Beginn"
									formControlName="buchungsdatumAnfang" />
								<input
									matEndDate
									placeholder="Ende"
									formControlName="buchungsdatumEnde" />
							</mat-date-range-input>
							<mat-datepicker-toggle
								matSuffix
								[for]="buchungsdatumPicker"></mat-datepicker-toggle>
							<mat-date-range-picker #buchungsdatumPicker></mat-date-range-picker>
						</mat-form-field>
					</th>
					<th class="mat-header-cell" scope="col">
						<mat-form-field appearance="fill">
							<mat-date-range-input [rangePicker]="valutatagPicker">
								<input
									matStartDate
									placeholder="Beginn"
									formControlName="valutatagAnfang" />
								<input matEndDate placeholder="Ende" formControlName="valutatagEnde" />
							</mat-date-range-input>
							<mat-datepicker-toggle
								matSuffix
								[for]="valutatagPicker"></mat-datepicker-toggle>
							<mat-date-range-picker #valutatagPicker></mat-date-range-picker>
						</mat-form-field>
					</th>
					<th class="mat-header-cell" scope="col"></th>
					<th class="mat-header-cell" scope="col"></th>
					<th class="mat-header-cell" scope="col">
						<mat-form-field appearance="fill">
							<input
								matInput
								placeholder="Suche..."
								formControlName="geschaeftsfallNummer"
								autocomplete="off" />
						</mat-form-field>
					</th>
					<th class="mat-header-cell" scope="col"></th>
				</tr>
			</ng-container>

			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<mat-paginator showFirstLastButtons></mat-paginator>
	</mat-card>
</div>

<mat-spinner *ngIf="isLoading$ | async" style="display: flex; margin: auto"></mat-spinner>
