<div class="flex-column">
	<mat-card class="flex-column-mat-card">
		<form (ngSubmit)="search()" [formGroup]="searchForm">
			<div class="page-header">
				<h1>Übersicht Tagesverarbeitung</h1>
				<button
					mat-raised-button
					color="accent"
					(click)="downloadAsExcel(dataSource.filteredData)">
					<mat-icon>file_download</mat-icon>
					Als Excel herunterladen
				</button>
			</div>
			<div style="display: flex; gap: 1em; margin-bottom: 1em">
				<label style="margin-top: 2em">Suchkriterien:</label>
				<mat-form-field style="flex-basis: 22em">
					<mat-label>Wähle Zeitraum für Eingangsdatum</mat-label>
					<mat-date-range-input [rangePicker]="eingangsDatumPicker">
						<input
							matStartDate
							placeholder="Start date"
							formControlName="eingangsdatumAb" />
						<input
							matEndDate
							placeholder="End date"
							formControlName="eingangsdatumBis" />
					</mat-date-range-input>
					<mat-datepicker-toggle
						matSuffix
						[for]="eingangsDatumPicker"></mat-datepicker-toggle>
					<mat-date-range-picker #eingangsDatumPicker></mat-date-range-picker>
				</mat-form-field>
				<mat-form-field style="flex-basis: 24em">
					<mat-label>Wähle Zeitraum für Verarbeitungsdatum</mat-label>
					<mat-date-range-input [rangePicker]="verarbeitungsDatumPicker">
						<input
							matStartDate
							placeholder="Start date"
							formControlName="verarbeitungszeitpunktAb" />
						<input
							matEndDate
							placeholder="End date"
							formControlName="verarbeitungszeitpunktBis" />
					</mat-date-range-input>
					<mat-datepicker-toggle
						matSuffix
						[for]="verarbeitungsDatumPicker"></mat-datepicker-toggle>
					<mat-date-range-picker #verarbeitungsDatumPicker></mat-date-range-picker>
				</mat-form-field>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					style="margin: 1em 0 1em 0"
					[disabled]="!(existsAtLeastOneSearchCriteria$ | async)">
					Suchen...
				</button>
			</div>
		</form>
	</mat-card>
	<section class="table-container mat-elevation-z2" tabindex="0">
		<table
			mat-table
			[dataSource]="dataSource"
			style="width: 2000px"
			id="tagesverarbeitung-uebersicht-table"
			matSort>
			<ng-container matColumnDef="nummer">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					mat-sort-header
					style="width: 120px">
					Geschäftsfall
				</th>
				<td mat-cell *matCellDef="let element">{{ element.nummer }}</td>
			</ng-container>
			<ng-container matColumnDef="wkn">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					style="width: 100px"
					mat-sort-header>
					WKN
				</th>
				<td mat-cell *matCellDef="let element">{{ element.wkn }}</td>
			</ng-container>
			<ng-container matColumnDef="land">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					style="width: 70px"
					mat-sort-header>
					Land
				</th>
				<td mat-cell *matCellDef="let element">{{ element.land }}</td>
			</ng-container>
			<ng-container matColumnDef="einreicher">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					mat-sort-header
					style="width: 250px">
					Einreicher
				</th>
				<td
					mat-cell
					*matCellDef="let element"
					style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden"
					[title]="element.einreicher">
					{{ element.einreicher }}
				</td>
			</ng-container>
			<ng-container matColumnDef="valutatag">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					mat-sort-header
					style="width: 150px">
					Valutatag
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.valutatag | date : 'dd.MM.yyyy' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="verarbeitungszeitpunkt">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					mat-sort-header
					style="width: 150px">
					Verarbeitung
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.verarbeitungszeitpunkt | date : 'dd.MM.yyyy HH:mm:ss' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="einreichungsdatum">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					mat-sort-header
					style="width: 130px">
					Einreichungsd.
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.einreichungsdatum | date : 'dd.MM.yyyy' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="nominal">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					style="width: 90px"
					mat-sort-header>
					Nominal
				</th>
				<td mat-cell *matCellDef="let element">{{ element.nominal }}</td>
			</ng-container>
			<ng-container matColumnDef="bruttobetrag">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					{{ metadata.fields.bruttobetrag.label }}
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.bruttobetrag | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="steuern">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					{{ metadata.fields.steuern.label }}
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.steuern | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="kapitalertragssteuer">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					KESt
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.kapitalertragssteuer | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="zinsabschlagsteuer">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					ZASt
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.zinsabschlagsteuer | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="solidaritaetszuschlag">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					Soli
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.solidaritaetszuschlag | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="kirchensteuer">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					KiSt
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.kirchensteuer | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="cdcGebuehren">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					{{ metadata.fields.cdcGebuehren.label }}
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.cdcGebuehren | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="dekaGebuehren">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					{{ metadata.fields.dekaGebuehren.label }}
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.dekaGebuehren | number : '1.2-2' : 'de' }} EUR
				</td>
			</ng-container>
			<ng-container matColumnDef="nettobetrag">
				<th
					mat-header-cell
					*matHeaderCellDef
					scope="col"
					class="text-right"
					style="width: 150px"
					mat-sort-header>
					{{ metadata.fields.nettobetrag.label }}
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: right">
					{{ element.nettobetrag | number : '1.2-2' : 'de-DE' }} EUR
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</section>
	<mat-paginator showFirstLastButtons></mat-paginator>
</div>
