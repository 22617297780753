import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, combineLatest, concat, of } from 'rxjs';
import {
	debounceTime,
	distinct,
	filter,
	map,
	pairwise,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { determineVorabpauschaleStatus } from 'src/app/berechnung/steuern';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungChooseComponent } from 'src/app/stammdaten/gattung/gattung-choose/gattung-choose.component';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { gattungMetadata } from 'src/app/stammdaten/gattung/model';
import {
	convertCurrency,
	extract,
	INTERNAL_NUMBER_FACTOR,
	isValid$,
	round2,
} from 'src/app/utils';
import { Einreichungsposition, einreichungspositionMetadata } from '../../model';

type ZuErfassendeEinreichungspositionFormValues = Omit<
	Einreichungsposition,
	'id' | 'positionnummer' | 'steuern' | 'einzelwert'
>;
interface InformationEinreichungspositionFormValues {
	emittent: string;
	zahlbarkeitstag: string;
	kuponnummerFaelligkeit: string;
	// nominalwertNachFaktor: number;
	nominal: number;
}
type EinreichungspositionFormValues = InformationEinreichungspositionFormValues &
	ZuErfassendeEinreichungspositionFormValues;

@Component({
	selector: 'app-einreichung-edit-position-popup',
	templateUrl: './einreichung-edit-position-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungEditPositionPopupComponent
	extends BaseComponent
	implements OnDestroy, AfterViewInit
{
	@ViewChild(GattungChooseComponent) public gattungChoose?: GattungChooseComponent;
	public readonly metadata = einreichungspositionMetadata;
	public readonly gattungMetadata = gattungMetadata;
	public stueckenummerIsRequired: boolean = false;

	public readonly form = new TypedForm<EinreichungspositionFormValues>({
		anzahl: createFormControl(this.metadata.fields.anzahl),
		// einzelwert: createFormControl(this.metadata.fields.einzelwert),
		nominal: new UntypedFormControl(0),
		gattungId: createFormControl(this.metadata.fields.gattungId),
		nominalwert: createFormControl(this.metadata.fields.nominalwert),
		gesamtwert: createFormControl(this.metadata.fields.gesamtwert),
		anteil: createFormControl(this.metadata.fields.anteil),
		faktor: createFormControl(this.metadata.fields.faktor),
		stueckenummer: createFormControl(this.metadata.fields.stueckenummer),
		vorabpauschale: createFormControl(this.metadata.fields.vorabpauschale),
		emittent: createFormControl(gattungMetadata.fields.emittentId),
		zahlbarkeitstag: createFormControl(gattungMetadata.fields.zahlbarkeitstag),
		kuponnummerFaelligkeit: createFormControl(gattungMetadata.fields.kuponnummerFaelligkeit),
		nominalwaehrung: createFormControl(gattungMetadata.fields.nominalwaehrung),
		// nominalwertNachFaktor: new FormControl(0),
	});

	public readonly cannotAccept$ = combineLatest([
		isValid$(this.form),
		this.isStueckenummerValid$(this.form),
	]).pipe(map(([isValid, isStueckenummerValid]) => !(isValid && isStueckenummerValid)));

	public readonly gattung$ = combineLatest([
		this.gattungService.list$,
		this.form.controls.gattungId.valueChanges,
	]).pipe(map(([list, id]) => (id ? list.find(g => g.id === id) : undefined)));

	public readonly dotation$ = this.gattung$.pipe(
		distinct(),
		switchMap(g => {
			if (!g) return of(null);
			return concat(of(null), this.gattungService.dotationInfo$(g.id));
		})
	);

	public readonly einreichungsposition: Einreichungsposition;
	private readonly kistamProzentsatz: number;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		{
			einreichungsposition,
			kistamProzentsatz,
		}: { einreichungsposition: Einreichungsposition; kistamProzentsatz?: number },
		private readonly ref: MatDialogRef<EinreichungEditPositionPopupComponent>,
		private readonly gattungService: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService
	) {
		super();

		this.einreichungsposition = einreichungsposition;
		this.kistamProzentsatz = kistamProzentsatz ?? 0;

		this.registerSubscription(
			this.form.values$
				.pipe(
					pairwise(),
					filter(
						([oldVersion, newVersion]) =>
							JSON.stringify(oldVersion) !== JSON.stringify(newVersion)
					),
					debounceTime(50)
				)
				.subscribe(([oldVersion, newVersion]) => this.onFormValueChanged(newVersion))
		);

		this.registerSubscription(
			this.ref.keydownEvents().subscribe((event: KeyboardEvent) => {
				if (event.key === 'Escape') this.tryCancel();
			})
		);

		this.form.resetTyped({
			...einreichungsposition,
		});

		this.form.controls.nominalwert.disable({ emitEvent: false });
		// this.form.controls.einzelwert.disable({ emitEvent: false });
		this.form.controls.nominal.disable({ emitEvent: false });
		this.form.controls.gesamtwert.disable({ emitEvent: false });
		this.form.controls.emittent.disable({ emitEvent: false });
		this.form.controls.zahlbarkeitstag.disable({ emitEvent: false });
		this.form.controls.kuponnummerFaelligkeit.disable({ emitEvent: false });
		this.form.controls.nominalwaehrung.disable({ emitEvent: false });
		// this.form.controls.nominalwertNachFaktor.setValue(0);
		// this.form.controls.nominalwertNachFaktor.disable({ emitEvent: false });
		this.form.controls.vorabpauschale.disable({ emitEvent: true });
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			// necessary so that gattung$ get reevaluated
			this.form.controls.gattungId.reset(this.form.controls.gattungId.value);

			// no other idea how to keep it closed :(
			setTimeout(() => this.gattungChoose?.autocompleteTrigger?.closePanel(), 200);
		});
	}

	onFormValueChanged(values: EinreichungspositionFormValues): void {
		let expectedValues: Partial<EinreichungspositionFormValues> = {
			nominalwert: 0,
			// einzelwert: 0,
			gesamtwert: 0,
			emittent: '',
			nominalwaehrung: 'EUR',
			// nominalwertNachFaktor: 0,
			vorabpauschale: 0,
			nominal: 0,
		};

		if (values.gattungId) {
			const gattung = this.gattungService.list$.value.find(g => g.id === values.gattungId);
			if (gattung) {
				expectedValues.nominalwaehrung = gattung.nominalwaehrung ?? 'EUR';
				expectedValues.nominalwert = gattung.bruttowert || 0;

				expectedValues.nominal = Math.floor(
					((values.anteil ?? INTERNAL_NUMBER_FACTOR) *
						(values.faktor ?? INTERNAL_NUMBER_FACTOR) *
						(values.anzahl ?? 1)) /
						INTERNAL_NUMBER_FACTOR /
						INTERNAL_NUMBER_FACTOR
				);
				const convertedBruttoWert = convertCurrency(
					gattung.bruttowert ?? 0,
					gattung.nominalwaehrung
				);

				expectedValues.gesamtwert = round2(
					(convertedBruttoWert ?? 0) * expectedValues.nominal
				);

				const emittent = this.finanzinstitutService.list$.value.find(
					f => f.id === gattung.emittentId
				);
				expectedValues.emittent = emittent?.displayName ?? '';

				expectedValues.zahlbarkeitstag = gattung.zahlbarkeitstag?.toDateString() ?? '';
				expectedValues.kuponnummerFaelligkeit = gattung.kuponnummerFaelligkeit ?? '';
				expectedValues.vorabpauschale = 0;

				if (gattung.fusionUmrechnungsfaktor) {
					expectedValues.faktor = gattung.fusionUmrechnungsfaktor;
				}

				const vorabpauschaleItems = determineVorabpauschaleStatus(
					gattung,
					this.kistamProzentsatz,
					((values.anzahl ?? 1) * (values.anteil ?? INTERNAL_NUMBER_FACTOR)) /
						INTERNAL_NUMBER_FACTOR
				);
				expectedValues.vorabpauschale = vorabpauschaleItems.reduce(
					(pv, cv) => pv + cv.steuerbetrag,
					0
				);
			}
		}

		const actualValues = extract(values, expectedValues);

		if (JSON.stringify(actualValues) !== JSON.stringify(expectedValues)) {
			this.form.patchValue(expectedValues);
			return;
		}
	}

	accept(): void {
		const rawTypedValues = this.form.rawTypedValue;
		const position: Einreichungsposition = {
			id: this.einreichungsposition.id,
			positionnummer: this.einreichungsposition.positionnummer,
			anteil: rawTypedValues.anteil,
			anzahl: rawTypedValues.anzahl,
			// einzelwert: rawTypedValues.einzelwert,
			einzelwert: 0,
			faktor: rawTypedValues.faktor,
			gattungId: rawTypedValues.gattungId,
			gesamtwert: rawTypedValues.gesamtwert,
			nominalwert: rawTypedValues.nominalwert,
			stueckenummer: rawTypedValues.stueckenummer,
			vorabpauschale: rawTypedValues.vorabpauschale,
			nominalwaehrung: rawTypedValues.nominalwaehrung,
			kuponnummerFaelligkeit: rawTypedValues.kuponnummerFaelligkeit,
			steuern: [],
		};
		this.ref.close(position);
	}

	tryCancel(): void {
		this.ref.close();
	}

	isStueckenummerValid$(form: AbstractControl): Observable<boolean> {
		const gattungIdControl = form.get('gattungId');
		const stueckenummerControl = form.get('stueckenummer');

		if (!gattungIdControl || !stueckenummerControl) {
			return new Observable<boolean>(observer => {
				observer.next(false);
				observer.complete();
			});
		}

		return combineLatest([
			gattungIdControl.valueChanges.pipe(startWith(gattungIdControl.value)),
			stueckenummerControl.valueChanges.pipe(startWith(stueckenummerControl.value)),
		]).pipe(
			filter(([gattungIdValue]) => !!gattungIdValue),
			map(([gattungIdValue, stueckenummerValue]) => {
				const gattung = this.gattungService.list$.value.find(g => g.id === gattungIdValue);

				const isMantel = gattung?.wkz === true;

				const hasStueckenummerValue =
					(Array.isArray(stueckenummerValue) && stueckenummerValue.length > 0) ||
					stueckenummerControl.value.length > 0;

				this.stueckenummerIsRequired = isMantel && !(isMantel && hasStueckenummerValue);

				if (!isMantel || (isMantel && hasStueckenummerValue)) {
					return true;
				} else {
					return false;
				}
			})
		);
	}
}
