<div *ngFor="let positionWrapper of positionWrappers">
	<h2>Position {{ positionWrapper.position.positionnummer }}</h2>
	<table class="steuern-table">
		<thead>
			<tr>
				<th scope="col" style="text-align: left">Steuer</th>
				<th scope="col">Steuerart</th>
				<th scope="col" style="text-align: right">Steuerbasis</th>
				<th scope="col" style="text-align: right">Steuersatz</th>
				<th scope="col" style="text-align: right">Steuerwert</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let steuer of positionWrapper.position.steuern">
				<td>{{ steuer.steuername }}</td>
				<td>{{ steuer.steuerart }}</td>
				<td>{{ steuer.steuergrundbetrag | efaNumber }} EUR</td>
				<td>{{ steuer.steuersatz | percent: '1.2-2':'de' }}</td>
				<td>{{ steuer.steuerbetrag | efaNumber }} EUR</td>
			</tr>
		</tbody>
	</table>
</div>
