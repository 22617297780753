import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMetadata } from '../model';

export abstract class EntityServiceBaseMock<T extends { id: string }> {
	public readonly list$ = new BehaviorSubject<T[]>([]);
	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly lastLoadedOn$ = new BehaviorSubject<Date | null>(null);
	public readonly count$ = this.list$.pipe(map(l => l.length));

	constructor(public readonly metadata: EntityMetadata<T>) {}

	loadAll$(): Observable<void> {
		if (this.loadAllMock) {
			return this.loadAllMock();
		} else {
			throw new Error('loadAllMock not provided in test!');
		}
	}

	loadSingle$(id: string): Observable<T> {
		if (this.loadSingleMock) {
			return this.loadSingleMock(id);
		} else {
			throw new Error('loadSingleMock not provided in test!');
		}
	}

	save$(item: Partial<T>): Observable<string> {
		if (this.saveMock) {
			return this.saveMock(item);
		} else {
			throw new Error('saveMock not provided in test!');
		}
	}

	public loadSingleMock?: (id: string) => Observable<T>;
	public loadAllMock?: () => Observable<void>;
	public saveMock?: (item: Partial<T>) => Observable<string>;
}
