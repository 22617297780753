<div class="page-header">
	<h1>Archiv</h1>
	<div>
		<button
			mat-raised-button
			color="accent"
			[disabled]="isSearching$ | async"
			(click)="downloadAllAsExcel()">
			<mat-icon *ngIf="isSearching$ | async | not">file_download</mat-icon>
			<mat-spinner
				*ngIf="isSearching$ | async"
				[diameter]="20"
				style="display: inline-block; margin-right: 0.5em"></mat-spinner>
			Als Excel herunterladen
		</button>
		<button
			mat-raised-button
			color="primary"
			[routerLink]="metadata.routing.edit!.url('new')"
			*requireRole="'standard'">
			<mat-icon>add</mat-icon>
			Neue Einreichung
		</button>
	</div>
</div>

<div class="flex-column">
	<table
		mat-table
		[dataSource]="dataSource"
		class="gattung-table"
		matSort
		[trackBy]="trackBy">
		<ng-container matColumnDef="nummer">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				{{ metadata.fields.nummer.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.nummer }}</td>
		</ng-container>

		<ng-container matColumnDef="einreicher">
			<th mat-header-cell *matHeaderCellDef scope="col">Einreicher</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.einreicher }}
			</td>
		</ng-container>

		<ng-container matColumnDef="einreicherart">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em">
				Einreicherart
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.einreicherart | einreicherart }}
			</td>
		</ng-container>

		<ng-container matColumnDef="eingangsdatum">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				{{ metadata.fields.eingangsdatum.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.eingangsdatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="freigabedatum">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				{{ metadata.fields.freigegebenAm.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.freigabedatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="absender">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.fields.absender.label }}
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.absender }}
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				{{ metadata.fields.status.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.status }}</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 5em"></th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap">
				<button
					mat-icon-button
					[routerLink]="metadata.routing.view!.url(element.id)"
					matTooltip="Ansicht">
					<mat-icon>visibility</mat-icon>
				</button>
				<button
					mat-icon-button
					[routerLink]="metadata.routing.edit!.url(element.id)"
					matTooltip="Ändern"
					[disabled]="!(element | isEditable)"
					*requireRole="'standard'">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container *matHeaderRowDef="displayedColumns">
			<tr mat-header-row></tr>
			<tr class="mat-header-row" [formGroup]="filterForm" class="table-filter-row">
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="nummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="einreicher"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-select
							formControlName="einreicherart"
							panelClass="filter-panel"
							placeholder="Suche...">
							<mat-option [value]="">Kein Filter</mat-option>
							<mat-option [value]="'privat'">Privat</mat-option>
							<mat-option [value]="'geschaeftlich'">Geschäftlich</mat-option>
						</mat-select>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-label>Wähle Zeitraum</mat-label>
						<mat-date-range-input [rangePicker]="eingangsdatumPicker">
							<input
								matStartDate
								placeholder="Start date"
								formControlName="eingangsdatumAb" />
							<input
								matEndDate
								placeholder="End date"
								formControlName="eingangsdatumBis" />
						</mat-date-range-input>
						<mat-datepicker-toggle
							matSuffix
							[for]="eingangsdatumPicker"></mat-datepicker-toggle>
						<mat-date-range-picker #eingangsdatumPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-label>Wähle Zeitraum</mat-label>
						<mat-date-range-input [rangePicker]="freigegebenDatumPicker">
							<input
								matStartDate
								placeholder="Start date"
								formControlName="freigegebenDatumAb" />
							<input
								matEndDate
								placeholder="End date"
								formControlName="freigegebenDatumBis" />
						</mat-date-range-input>
						<mat-datepicker-toggle
							matSuffix
							[for]="freigegebenDatumPicker"></mat-datepicker-toggle>
						<mat-date-range-picker #freigegebenDatumPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="absender"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-select
							formControlName="status"
							panelClass="status-filter-panel-250"
							placeholder="Suche...">
							<mat-option [value]="">Kein Statusfilter</mat-option>
							<mat-option
								*ngFor="let status of einreichungStatus | keyvalue"
								[value]="status.value">
								{{ status.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col" style="max-width: 10em">
					<button
						mat-raised-button
						color="primary"
						[disabled]="isSearching$ | async"
						(click)="search(false)"
						style="margin-bottom: 1em">
						Suche
					</button>
				</th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="open(row)"></tr>
	</table>

	<mat-card class="flex-row">
		<mat-card-content>
			<span style="margin-right: 50px">Seite {{ currentPage$ | async }}</span>
			<button
				[disabled]="(currentPage$ | async) === 1"
				mat-button
				matTooltip="Seite zurück"
				(click)="prevPage()">
				<mat-icon>chevron_left</mat-icon>
				Seite zurück
			</button>
			<button
				[disabled]="canGoNextPage$ | async | not"
				mat-button
				matTooltip="Nächste Seite"
				(click)="nextPage()">
				<mat-icon>chevron_right</mat-icon>
				Nächste Seite
			</button>
		</mat-card-content>
	</mat-card>
</div>
