import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { Konfiguration } from './model';

/** Behälter für die fachliche Konfiguration */
@Injectable({
	providedIn: 'root',
})
export class KonfigurationService {
	public konfiguration$ = new BehaviorSubject<Konfiguration>({} as Konfiguration);
	constructor(private http: HttpClient) {}

	public load$(): Observable<Konfiguration> {
		return this.http
			.get<Konfiguration>(`/konfiguration`)
			.pipe(tap(newVersion => this.konfiguration$.next(newVersion)));
	}

	save$(value: Konfiguration): Observable<Konfiguration> {
		return this.http.put(`/konfiguration`, value, { responseType: 'text' }).pipe(switchMap(() => this.load$()));
	}
}
