<h1>Systeminformation</h1>
<h2>Version</h2>
<p>
	Frontend-Version:
	<strong>{{ config.frontendVersion$ | async }}</strong>
</p>
<p>
	Frontend-Build-Date:
	<strong>{{ config.frontendBuildDate$ | async }}</strong>
</p>
<ng-container *ngIf="config.backendVersion$ | async as backendVersion">
	<p>
		Backend-Version:
		<strong>{{ backendVersion.releaseVersion }}</strong>
	</p>
	<p>
		Backend-Commit:
		<strong>{{ backendVersion.beGitCommit }}</strong>
	</p>
	<p>
		Backend-Timestamp:
		<strong>{{ backendVersion.beBuildDateTime }}</strong>
	</p>
</ng-container>

<h2>Konfiguration</h2>
<div style="white-space: pre">{{ configInfo }}</div>
<h2>Benutzer</h2>
<div style="white-space: pre">{{ currentUserInfo$ | async }}</div>
