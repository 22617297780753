import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EinreichungService } from 'src/app/bewegungsdaten/einreichung.service';
import { einreichungMetadata } from 'src/app/bewegungsdaten/model';
import { FinanzinstitutRolle, finanzinstitutMetadata } from 'src/app/stammdaten/finanzinstitut/model';
import { gattungMetadata } from 'src/app/stammdaten/gattung/model';
import { CurrentUserService } from '../current-user.service';
import { BenutzerRolle } from '../roles';

@Component({
	selector: 'app-start-page',
	templateUrl: './start-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartPageComponent {
	public readonly finanzinstitutRolle = FinanzinstitutRolle.EmittentUndEinreicher;

	public readonly chooseGattungControl = new UntypedFormControl(null);
	public readonly chooseFinanzinstitutControl = new UntypedFormControl(null);
	public readonly einreichungNummerControl = new UntypedFormControl(null);

	public readonly showEinreichung$ = this.currentUserService.hasAnyOfRoles$([
		BenutzerRolle.Leser,
		BenutzerRolle.Standard,
	]);

	constructor(
		private readonly router: Router,
		private readonly einreichungService: EinreichungService,
		private readonly currentUserService: CurrentUserService
	) {}

	goToGattung(): void {
		const id = this.chooseGattungControl.value;
		if (id) this.router.navigate([gattungMetadata.routing.view!.url(id)]);
	}

	goToEinreichung(): void {
		const nummerString = (this.einreichungNummerControl.value as string)?.trim();
		if (!nummerString) return;
		const nummer = Number(nummerString);
		if (isNaN(nummer)) return;

		const einreichung = this.einreichungService.list$.value.find(e => e.nummer === nummer);
		if (einreichung) {
			this.router.navigate([einreichungMetadata.routing.view!.url(einreichung.id)]);
		} else {
			this.router.navigate(['einreichung/archiv'], { queryParams: { nummer } });
		}
	}

	goToFinanzinstitut(): void {
		const id = this.chooseFinanzinstitutControl.value;
		if (id) this.router.navigate([finanzinstitutMetadata.routing.view!.url(id)]);
	}
}
