<mat-form-field style="width: 100%">
	<mat-label>{{ resolvedLabel }}</mat-label>
	<input
		autocomplete="off"
		type="text"
		[placeholder]="resolvedPlaceholder"
		matInput
		[formControl]="textControl"
		[matAutocomplete]="auto"
		(blur)="onTouched()"
		style="width: 100%"
		[required]="!!(isRequired$ | async)" />
	<span matSuffix>
		<mat-icon (click)="openDialog()" style="cursor: pointer; display: inline-block">search</mat-icon>
		<mat-icon (click)="clear()" style="cursor: pointer; display: inline-block">close</mat-icon>
	</span>

	<mat-autocomplete #auto="matAutocomplete">
		<mat-option *ngFor="let entry of filteredEntries$ | async" [value]="entry.displayName" class="flex-row">
			<span>
				{{ entry.displayName }}
			</span>
			<span style="flex: auto 0 0; display: flex; gap: 1em">
				{{ entry.zahlbarkeitstag | date: 'dd.MM.yyyy' }}
			</span>
		</mat-option>
	</mat-autocomplete>
	<mat-error>{{ textControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
