<div *ngIf="einreichung">
	<div *ngFor="let person of personen">
		<mat-accordion multi>
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header style="font-weight: bold">
					{{ person | personName }}
					{{ (person | personRole) ? '(' + (person | personRole) + ')' : '' }}
				</mat-expansion-panel-header>
				<div class="flex-row">
					<div class="data-column2">
						<div app-data-item [field]="metadata.fields.nachname"
							style="text-overflow: ellipsis; overflow-x: hidden">
							{{ person.nachname }}
						</div>
						<div app-data-item [field]="metadata.fields.geburtsort" label="Geburtsort / -land">
							{{ person.geburtsort }} /
							{{ person.geburtsland | kuerzelBezeichnung : 'land' }}
						</div>
						<div app-data-item [field]="metadata.fields.adresse">
							{{ person.adresse }}
						</div>
						<div app-data-item [field]="metadata.fields.adresszusatz">
							{{ person.adresszusatz }}
						</div>
						<div app-data-item [field]="metadata.fields.land" label="Land / Bundesland">
							{{ person.land | kuerzelBezeichnung : 'land' }} /
							{{ person.bundesland | kuerzelBezeichnung : 'bundesland' }}
						</div>

						<div>&nbsp;</div>

						<div app-data-item [field]="metadata.fields.ausweisart">
							{{ person.ausweisart | kuerzelBezeichnung : 'ausweisart' }}
						</div>
						<div app-data-item [label]="'Ausweis ausgestellt am / von'">
							{{ person.ausweisAusgestelltAm | date : 'dd.MM.yyyy' }}
						</div>
						<div app-data-item [field]="metadata.fields.ausweisAusgestelltVon">
							{{ person.ausweisAusgestelltVon }}
						</div>
					</div>

					<div class="data-column2">
						<div app-data-item [field]="metadata.fields.vorname">
							{{ person.vorname }}
						</div>
						<div app-data-item [field]="metadata.fields.geburtsdatum">
							{{ person.geburtsdatum | date : 'dd.MM.yyyy' }}
						</div>

						<div app-data-item [field]="metadata.fields.postleitzahl" label="PLZ / Ort">
							{{ person.postleitzahl }} {{ person.ort }}
						</div>
						<div app-data-item [field]="metadata.fields.email">{{ person.email }}</div>
						<div app-data-item [field]="metadata.fields.staatsangehoerigkeit">
							{{ person.staatsangehoerigkeit | kuerzelBezeichnung : 'land' }}
						</div>

						<div>&nbsp;</div>
						<div app-data-item [field]="metadata.fields.ausweisnummer">
							{{ person.ausweisnummer }}
						</div>
						<div app-data-item [field]="metadata.fields.ausweisGueltigBis">
							{{ person.ausweisGueltigBis | date : 'dd.MM.yyyy' }}
						</div>
					</div>

					<div class="data-column2">
						<div app-data-item [field]="metadata.fields.istVerein">
							{{ person.istVerein | booleanText }}
						</div>
						<div app-data-item [field]="metadata.fields.istEinreicher">
							{{ person.istEinreicher | booleanText }}
						</div>
						<div app-data-item [field]="metadata.fields.istWirtschaftlichBerechtigter">
							{{ person.istWirtschaftlichBerechtigter | booleanText }}
						</div>

						<div app-data-item [field]="metadata.fields.kycPruefungsnummer">
							{{ person.kycPruefungsnummer }}
						</div>

						<div app-data-item [field]="metadata.fields.embargoPruefungsnummer">
							{{ person.embargoPruefungsnummer }}
						</div>

						<div app-data-item [field]="metadata.fields.kycPositiv">
							{{ person.kycPositiv | booleanText : true }}
						</div>
						<div app-data-item [field]="metadata.fields.dekaDepotVorhanden"
							[label]="depotVorhandenLabel(person)">
							{{ person.dekaDepotVorhanden | booleanText }}
						</div>
						<div app-data-item [field]="metadata.fields.sapNummer"
							*ngIf="person.sapNummer !== null && person.sapNummer !== ''">
							{{ person.sapNummer }}
						</div>
						<div app-data-item [field]="metadata.fields.dsgvoAenderungsgrund">
							{{ person.dsgvoAenderungsgrund }}
						</div>
					</div>
				</div>

				<hr />

				<app-einreichung-view-personen-kycchecks [person]="person"></app-einreichung-view-personen-kycchecks>

			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>