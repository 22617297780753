<h1 mat-dialog-title>Freigabe anfordern</h1>

<div mat-dialog-content class="flex-column">
	<div>
		<mat-slide-toggle *ngIf="istNeuabrechnung" (change)="setNurDokumentation($event)">
			Neuerfassung nur zur Dokumentationszwecke.
		</mat-slide-toggle>
	</div>
	<div>
		<p>
			<strong>Vorabpauschale</strong>
			: {{ result.calculateResult.vorabpauschaleStatus | vorabpauschaleStatus }}
			<mat-slide-toggle [formControl]="vorabpauschaleEingegangenControl" (change)="result.vorabpauschaleEingegangen = !!$event">
				<span *ngIf="result.calculateResult.vorabpauschaleStatus === 'F'">
					Hiermit bestätige ich, dass die Vorabpauschale eingegangen ist.
				</span>
				<span *ngIf="result.calculateResult.vorabpauschaleStatus !== 'F'">
					Vorabpauschale nicht fällig bzw. gedeckt.
				</span>
			</mat-slide-toggle>
		</p>
	</div>
	<app-einreichung-dotation-info [positionen]="result.einreichung.positionen"></app-einreichung-dotation-info>
	<app-geschaeftsfall-detail-list
		style="margin: 0.5em"
		[calculateGeschaeftsfaelleResult]="result.calculateResult"></app-geschaeftsfall-detail-list>
</div>

<div mat-dialog-actions style="display: flex; gap: 1em">
	<button mat-raised-button color="accent" mat-dialog-close>
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" (click)="steuernUeberschreiben()" *requireRole="'erweitert'">
		<mat-icon>money_off</mat-icon>
		Steuern überschreiben
	</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="result" [disabled]="canAnfordern$ | async | not">
		<mat-icon>flaky</mat-icon>
		Freigabe anfordern
	</button>
</div>
