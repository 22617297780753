import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'app-ultimo-overview',
	templateUrl: './ultimo-overview.component.html',
	styleUrls: ['./ultimo-overview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltimoOverviewComponent {
	readonly links: { label: string; route: string[] }[] = [
		{ label: 'Ultimo KESt Meldung', route: ['/ultimo/kest'] },
		{ label: 'Ultimo KiSt Meldung', route: ['/ultimo/kist'] },
		{ label: 'Ultimo Kontenübersicht', route: ['/ultimo/konten'] },
		{ label: 'Compliance Einreichungsliste', route: ['/ultimo/einreichungen'] },
		{ label: 'Compliance Geschäftsfälle', route: ['/ultimo/geschaeftsfaelle'] },
		{ label: 'Compliance Risikoklassenbewertung', route: ['/ultimo/risikoklassen'] },
	];
}
