<div class="mat-elevation-z2">
	<table
		mat-table
		[dataSource]="dataSource"
		class="gattung-table"
		matSort
		[trackBy]="trackBy">
		<ng-container matColumnDef="wkn">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 10em"
				mat-sort-header="wknUpperCase">
				<span>{{ metadata.fields.wkn.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.wkn }}</td>
		</ng-container>

		<ng-container matColumnDef="isin">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 15em"
				mat-sort-header="isinUndZahlbarkeitstag">
				<span>{{ metadata.fields.isin.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.isin }}</td>
		</ng-container>

		<ng-container matColumnDef="wkz">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<span>{{ metadata.fields.wkz.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element" class="column-name">
				{{ element.wkz ? 'Mantel' : 'Kupon' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="kuponnummerFaelligkeit">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				<span>{{ metadata.fields.kuponnummerFaelligkeit.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element" class="column-kuponnumemrFaelligkeit">
				<span>{{ element.kuponnummerFaelligkeit }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				mat-sort-header="gattungsbezeichnung">
				<span>{{ metadata.fields.gattungsbezeichnung.label }}</span>
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.gattungsbezeichnung">
				{{ element.gattungsbezeichnung }}
			</td>
		</ng-container>

		<ng-container matColumnDef="zahlbarkeitstag">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				<span>{{ metadata.fields.zahlbarkeitstag.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element" class="column-zahlbarkeitstag">
				<span>{{ element.zahlbarkeitstag | date : 'dd.MM.yyyy' }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="emittent">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header="institutName">
				<span>{{ metadata.fields.emittentId.label }}</span>
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.institutBezeichnung">
				<button
					*ngIf="!finanzinstitut"
					mat-icon-button
					color="primary"
					[routerLink]="finanzinstitutMetadata.routing.view!.url(element.emittentId)">
					<mat-icon>open_in_new</mat-icon>
				</button>
				<span>{{ element.institutBezeichnung }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<span>{{ metadata.fields.status.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">
				<span>{{ element.status }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 5em"></th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap">
				<button
					mat-icon-button
					[routerLink]="metadata.routing.view!.url(element.id)"
					matTooltip="Ansicht">
					<mat-icon>visibility</mat-icon>
				</button>
				<button
					mat-icon-button
					[routerLink]="metadata.routing.edit!.url(element.id)"
					matTooltip="Ändern"
					*requireRole="'standard'">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container *matHeaderRowDef="displayedColumns">
			<tr mat-header-row></tr>
			<tr class="mat-header-row" [formGroup]="filterForm" class="table-filter-row">
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="wkn"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="isin"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="wkz"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="name"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="kuponnummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col" s>
					<mat-form-field>
						<mat-label style="padding: 0">Wähle Zeitraum</mat-label>
						<mat-date-range-input [rangePicker]="zahlbarkeittagPicker">
							<input
								matStartDate
								placeholder="Start date"
								formControlName="zahlbarkeittagAnfang" />
							<input
								matEndDate
								placeholder="End date"
								formControlName="zahlbarkeittagEnde" />
						</mat-date-range-input>
						<mat-datepicker-toggle
							matSuffix
							[for]="zahlbarkeittagPicker"></mat-datepicker-toggle>
						<mat-date-range-picker #zahlbarkeittagPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="emittent"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<mat-select
							formControlName="status"
							placeholder="Suche..."
							panelClass="status-filter-panel-200">
							<mat-option [value]="">Kein Statusfilter</mat-option>
							<mat-option
								*ngFor="let status of stammdatenStatus | keyvalue"
								[value]="status.value">
								{{ status.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col" style="max-width: 5em"></th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="itemClicked$.next(row)"></tr>
	</table>

	<mat-paginator showFirstLastButtons></mat-paginator>
</div>
