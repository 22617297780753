<form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
	<div class="page-header">
		<div>
			<a mat-raised-button color="accent" [routerLink]="metadata.routing.view!.url(id)" *ngIf="id$ | async as id">
				<mat-icon>visibility</mat-icon>
				Zurück zur Ansicht
			</a>
			<a mat-raised-button color="accent" [routerLink]="metadata.routing.list!.url">
				<mat-icon>list</mat-icon>
				Zurück zur Liste
			</a>
		</div>
		<div>
			<button mat-raised-button color="primary" type="submit" [disabled]="cannotSave$ | async">
				<mat-icon>save</mat-icon>
				Speichern
			</button>
		</div>
	</div>

	<div class="page-header">
		<h1>
			<span *ngIf="!(id$ | async)">Neues Finanzinstitut</span>
			<span *ngIf="id$ | async">
				{{ form.controls.finanzinstitutsnummer.value }} -- {{ form.controls.finanzinstitutsname.value }} ({{
					form.controls.status.value
				}})
			</span>
		</h1>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<span class="mat-error">{{ openItemsMessage$ | async }}</span>
		<div class="flex-row">
			<div>Finanzinstitut ist:</div>
			<div>
				<!-- <mat-slide-toggle formControlName="istEmittent">Emittent</mat-slide-toggle> -->
				<mat-checkbox formControlName="istEmittent">Emittent</mat-checkbox>
				<br />
				<mat-checkbox formControlName="istEinreicher">Einreicher</mat-checkbox>
			</div>
			<mat-error>{{ form | fieldErrorMessage$ | async }}</mat-error>
		</div>

		<div class="flex-row">
			<app-text-input
				style="flex: 1"
				formControlName="finanzinstitutsnummer"
				[metadata]="metadata.fields.finanzinstitutsnummer"></app-text-input>

			<app-text-input
				style="flex: 1"
				formControlName="finanzinstitutsname"
				[metadata]="metadata.fields.finanzinstitutsname"></app-text-input>

			<app-text-input
				style="flex: 1"
				formControlName="finanzinstitutskonto"
				[metadata]="metadata.fields.finanzinstitutskonto"></app-text-input>
		</div>

		<div class="flex-row">
			<app-kuerzel-bezeichnung-choose
				formControlName="land"
				[metadata]="metadata.fields.land"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>

			<app-postleitzahl-input
				formControlName="postleitzahl"
				[metadata]="metadata.fields.postleitzahl"
				[land]="form.controls.land.value"
				(userEnteredPostleitzahl)="onUserEnteredPostleitzahl($event)"
				style="flex: 1"></app-postleitzahl-input>

			<app-text-input style="flex: 1" formControlName="ort" [metadata]="metadata.fields.ort"></app-text-input>

			<app-kuerzel-bezeichnung-choose
				formControlName="bundesland"
				[metadata]="metadata.fields.bundesland"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>
		</div>

		<div class="flex-row">
			<app-text-input
				style="flex: 1"
				formControlName="adresszusatz"
				[metadata]="metadata.fields.adresszusatz"></app-text-input>

			<app-text-input
				style="flex: 1"
				formControlName="adresse"
				[metadata]="metadata.fields.adresse"></app-text-input>
		</div>
	</div>

	<div class="mat-elevation-z5 flex-column" style="padding: 1em">
		<div class="flex-row">
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.geaendertVon.label }}:</span>
					<span>{{ form.controls.geaendertVon.value }}</span>
				</div>
			</div>
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.freigegebenVon.label }}:</span>
					<span>{{ form.controls.freigegebenVon.value }}</span>
				</div>
			</div>
		</div>
		<div class="flex-row">
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.geaendertAm.label }}:</span>
					<span>{{ form.controls.geaendertAm.value | date: 'dd.MM.yyyy' }}</span>
				</div>
			</div>
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.freigegebenAm.label }}:</span>
					<span>{{ form.controls.freigegebenAm.value | date: 'dd.MM.yyyy' }}</span>
				</div>
			</div>
		</div>
	</div>
</form>
