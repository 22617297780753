import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BenutzerNamePipe } from './benutzer/benutzer-name.pipe';
import { FinanzinstitutChooseDialogComponent } from './finanzinstitut/finanzinstitut-choose/finanzinstitut-choose-dialog/finanzinstitut-choose-dialog.component';
import { FinanzinstitutChooseComponent } from './finanzinstitut/finanzinstitut-choose/finanzinstitut-choose.component';
import { FinanzinstitutDetailsModalComponent } from './finanzinstitut/finanzinstitut-details-modal/finanzinstitut-details-modal.component';
import { FinanzinstitutDetailsComponent } from './finanzinstitut/finanzinstitut-details/finanzinstitut-details.component';
import { FinanzinstitutDisplayNamePipe } from './finanzinstitut/finanzinstitut-display-name.pipe';
import { FinanzinstitutEditPageComponent } from './finanzinstitut/finanzinstitut-edit-page/finanzinstitut-edit-page.component';
import { FinanzinstitutListPageComponent } from './finanzinstitut/finanzinstitut-list-page/finanzinstitut-list-page.component';
import { FinanzinstitutListComponent } from './finanzinstitut/finanzinstitut-list/finanzinstitut-list.component';
import { FinanzinstitutViewPageComponent } from './finanzinstitut/finanzinstitut-view-page/finanzinstitut-view-page.component';
import { GattungDetailsModalComponent } from './gattung/gattung-details-modal/gattung-details-modal.component';
import { GattungDetailsComponent } from './gattung/gattung-details/gattung-details.component';
import { GattungEditPageComponent } from './gattung/gattung-edit-page/gattung-edit-page.component';
import { GattungListPageComponent } from './gattung/gattung-list-page/gattung-list-page.component';
import { GattungListComponent } from './gattung/gattung-list/gattung-list.component';
import { GattungViewPageComponent } from './gattung/gattung-view-page/gattung-view-page.component';
import { KuerzelBezeichnungChooseComponent } from './kuerzel-bezeichnung/kuerzel-bezeichnung-choose/kuerzel-bezeichnung-choose.component';
import { KuerzelBezeichnungImportComponent } from './kuerzel-bezeichnung/kuerzel-bezeichnung-import/kuerzel-bezeichnung-import.component';
import { KuerzelBezeichnungListPageComponent } from './kuerzel-bezeichnung/kuerzel-bezeichnung-list-page/kuerzel-bezeichnung-list-page.component';
import { KuerzelBezeichnungListComponent } from './kuerzel-bezeichnung/kuerzel-bezeichnung-list/kuerzel-bezeichnung-list.component';
import { KuerzelBezeichnungPopupComponent } from './kuerzel-bezeichnung/kuerzel-bezeichnung-popup/kuerzel-bezeichnung-popup.component';
import { StammdatenUebersichtComponent } from './stammdaten-uebersicht/stammdaten-uebersicht.component';
import { GattungEditAusschuettungsdatumComponent } from './gattung/gattung-edit-page/gattung-edit-ausschuettungsdatum/gattung-edit-ausschuettungsdatum.component';
import { MatChipsModule } from '@angular/material/chips';
import { GattungAusschuettungsdatumPipe } from './gattung/pipes/gattung-ausschuettungsdatum.pipe';
import { PostleitzahlInputComponent } from './kuerzel-bezeichnung/postleitzahl-input/postleitzahl-input.component';
import { GattungChooseComponent } from './gattung/gattung-choose/gattung-choose.component';
import { GattungChooseDialogComponent } from './gattung/gattung-choose/gattung-choose-dialog/gattung-choose-dialog.component';
import { GattungBezeichnungPipe } from './gattung/pipes/gattung-bezeichnung.pipe';
import { FeiertagskalenderListComponent } from './feiertagskalender/feiertagskalender-list/feiertagskalender-list.component';
import { FeiertagskalenderPopupComponent } from './feiertagskalender/feiertagskalender-popup/feiertagskalender-popup.component';
import { GattungEditVorabpauschaleComponent } from './gattung/gattung-edit-page/gattung-edit-vorabpauschaule/gattung-edit-vorabpauschale.component';
import { ZuVersteuerndeVorabpauschalePipe } from './gattung/pipes/zu-versteuernde-vorabpauschale.pipe';
import { GattungNeuAusschuettungenComponent } from './gattung/gattung-neu-ausschuettungen/gattung-neu-ausschuettungen.component';
import { GattungSteuerComponent } from './gattung/gattung-view-page/gattung-steuer/gattung-steuer.component';
import { GattungSteuerDisplayComponent } from './gattung/gattung-view-page/gattung-steuer-display/gattung-steuer-display.component';
import { KuerzelBezeichnungPipe } from './kuerzel-bezeichnung/kuerzel-bezeichnung.pipe';
import { OppositionListPageComponent } from './opposition/opposition-list-page/opposition-list-page.component';
import { OppositionFileimportModalComponent } from './opposition/opposition-fileimport-modal/opposition-fileimport-modal.component';

@NgModule({
	declarations: [
		GattungListComponent,
		GattungDetailsComponent,
		GattungDetailsModalComponent,
		BenutzerNamePipe,
		StammdatenUebersichtComponent,
		FinanzinstitutListComponent,
		FinanzinstitutEditPageComponent,
		FinanzinstitutDetailsComponent,
		FinanzinstitutDetailsModalComponent,
		FinanzinstitutViewPageComponent,
		GattungEditPageComponent,
		GattungViewPageComponent,
		FinanzinstitutChooseComponent,
		FinanzinstitutListPageComponent,
		FinanzinstitutListComponent,
		GattungListPageComponent,
		FinanzinstitutChooseDialogComponent,
		FinanzinstitutDisplayNamePipe,
		KuerzelBezeichnungListComponent,
		KuerzelBezeichnungListPageComponent,
		KuerzelBezeichnungChooseComponent,
		KuerzelBezeichnungPopupComponent,
		KuerzelBezeichnungImportComponent,
		GattungEditAusschuettungsdatumComponent,
		GattungAusschuettungsdatumPipe,
		PostleitzahlInputComponent,
		GattungChooseComponent,
		GattungChooseDialogComponent,
		GattungBezeichnungPipe,
		FeiertagskalenderListComponent,
		FeiertagskalenderPopupComponent,
		GattungEditVorabpauschaleComponent,
		ZuVersteuerndeVorabpauschalePipe,
		GattungNeuAusschuettungenComponent,
		GattungSteuerComponent,
		GattungSteuerDisplayComponent,
		KuerzelBezeichnungPipe,
		OppositionListPageComponent,
  OppositionFileimportModalComponent,
	],
	exports: [
		FinanzinstitutChooseComponent,
		GattungChooseComponent,
		GattungBezeichnungPipe,
		FinanzinstitutDisplayNamePipe,
		KuerzelBezeichnungChooseComponent,
		PostleitzahlInputComponent,
		KuerzelBezeichnungPipe,
	],
	imports: [
		CommonModule,
		SharedModule,
		AppRoutingModule,
		HttpClientModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatTableModule,
		MatPaginatorModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatTooltipModule,
		SharedModule,
		MatSelectModule,
		MatCheckboxModule,
		MatRadioModule,
		MatSortModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatDatepickerModule,
	],
	providers: [DatePipe],
})
export class StammdatenModule {}
