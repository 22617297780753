import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'einreicherart',
})
export class EinreicherartPipe implements PipeTransform {
	transform(value: string | null): string | null {
		if (value === 'geschaeftlich') return 'Geschäftlich';
		if (value === 'privat') return 'Privat';
		return value;
	}
}
