import {
	Attribute,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	Optional,
	Self,
	SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription, NumericFieldDescription } from 'src/app/model';
import { coalesce, toExternalModel, toInternalModel } from 'src/app/utils';
import { TypedControl } from '../typed-form';

// stolen from https://stackoverflow.com/questions/55364947/is-there-any-javascript-standard-api-to-parse-to-number-according-to-locale
const formattedNumber = (
	new Intl.NumberFormat('de', { useGrouping: true, minimumFractionDigits: 1 }) as any
).formatToParts(1234.5);
const groupSymbol = formattedNumber.find((e: any) => e.type === 'group')!.value;
const decimalSymbol = formattedNumber.find((e: any) => e.type === 'decimal')!.value;

/**

*/
@Component({
	selector: 'app-numeric-input',
	templateUrl: './numeric-input.component.html',
	styleUrls: ['./numeric-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericInputComponent extends BaseComponent implements OnChanges, ControlValueAccessor {
	@Input() metadata?: FieldDescription;
	@Input() label?: string;
	@Input() placeholder?: string;
	@Input() symbol = '';
	@Input() decimals?: number;
	@Input() useGrouping = true;
	@Input() efaNumber?: boolean;

	resolvedLabel: string = '';
	resolvedPlaceholder: string = '';
	resolvedDecimals: number = 2;

	public readonly isDisabled$ = new BehaviorSubject(false);
	public readonly textControl = new TypedControl<string>('');
	public readonly showClearButton$ = combineLatest([this.textControl.value$, this.isDisabled$]).pipe(
		map(([value, isDisabled]) => !isDisabled && value)
	);
	public readonly isRequired$ = new BehaviorSubject(false);

	private onChange?: (val: number | null) => void;
	private onTouchedCallback?: Function;

	private format: Intl.NumberFormat = new Intl.NumberFormat('de', {
		useGrouping: this.useGrouping,
		maximumFractionDigits: this.decimals,
		minimumFractionDigits: this.decimals,
	});

	private readonly value$ = new BehaviorSubject<number | null>(null);

	constructor(
		@Optional() @Self() public readonly ngControl: NgControl // no idea why @Optional...
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl) throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.resolvedLabel = this.label ?? this.metadata?.label ?? '';
		this.resolvedDecimals = this.decimals ?? (this.metadata as NumericFieldDescription)?.decimals ?? 2;

		this.format = new Intl.NumberFormat('de', {
			useGrouping: this.useGrouping,
			maximumFractionDigits: this.resolvedDecimals,
			minimumFractionDigits: this.resolvedDecimals,
		});

		if (this.value$.value) this.textControl.setValue(this.format.format(this.value$.value), { emitEvent: false });

		this.resolvedPlaceholder = coalesce(this.placeholder, this.format.format(0), '');
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (!this.ngControl.control) {
				console.error('no control on ngControl! Did you add formControlName or formControl?', this);
				throw new Error('no control on ngControl! Did you add formControlName or formControl?');
			}
			const hostControl = this.ngControl.control;
			this.reevaluateIsRequired();

			const onChange = this.onChange;
			if (!onChange) {
				throw new Error('Missing onChange!');
			}

			if (this.value$.value) {
				this.textControl.markAsTouched();
			}

			this.registerSubscription(
				this.value$.subscribe(value => {
					const isEfaNumber =
						this.efaNumber !== undefined
							? this.efaNumber
							: (this.metadata as NumericFieldDescription)?.isEfaNumber ?? false;
					if (isEfaNumber) value = toInternalModel(value);
					onChange(value);
					if (this.textControl.errors) return; // could not parse entry text as a number
					if (hostControl.validator) {
						// if parent control has associated validators
						// we validate hostControl, because its value was set with onChange a few lines above
						const errorsFromParentValidators = hostControl.validator(hostControl);
						this.textControl.setErrors(errorsFromParentValidators);
					}
				})
			);

			this.registerSubscription(
				this.textControl.value$.subscribe(textValue => {
					// when input changes in the text control
					this.reevaluateIsRequired(); // the only place we can regularly check for changes

					if (!textValue || typeof textValue !== 'string') {
						this.textControl.setErrors(null);
						this.value$.next(null);
						return;
					}

					const valueToParse = textValue
						.trim()
						.replace(new RegExp(`[${groupSymbol}]`, 'g'), '')
						.replace(new RegExp(`[${decimalSymbol}]`, 'g'), '.');

					const value = Number.parseFloat(valueToParse);

					if (isNaN(value)) {
						this.textControl.setErrors({ entryInvalid: true });
						this.value$.next(null);
					} else {
						const factor = Math.pow(10, this.resolvedDecimals);
						const roundedValue = Math.round(value * factor) / factor;

						this.textControl.setErrors(null);
						this.value$.next(roundedValue);
					}
				})
			);
		});
	}

	/** called when bound FormControl gets input */
	writeValue(val: number | null | undefined): void {
		const isEfaNumber =
			this.efaNumber !== undefined
				? this.efaNumber
				: (this.metadata as NumericFieldDescription)?.isEfaNumber ?? false;
		if (isEfaNumber) val = toExternalModel(val ?? null);
		if (val === this.value$.value) return;

		if (typeof val === 'number') {
			this.value$.next(val);
			this.textControl.reset(this.format.format(val), { emitEvent: false });
		} else {
			this.value$.next(null);
			this.textControl.reset('', { emitEvent: false });
		}
	}

	registerOnChange(fn: (val: number | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.textControl.disable();
		} else {
			this.textControl.enable();
		}
		this.isDisabled$.next(isDisabled);
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	clear(): void {
		this.textControl.reset();
	}

	reformatValue(): void {
		if (this.value$.value && this.textControl.valid) {
			const displayValue = this.format.format(this.value$.value);
			if (displayValue !== this.textControl.value) {
				this.textControl.setValue(displayValue, { emitEvent: false });
			}
		}
	}

	reevaluateIsRequired(): void {
		const shouldBeRequired = !!this.ngControl.control?.hasValidator(Validators.required);
		if (this.isRequired$.value !== shouldBeRequired) {
			this.isRequired$.next(shouldBeRequired);
		}
	}
}
