import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { EntityMetadata } from '../model';
import { DatabaseService } from '../shared/database.service';

export abstract class EntityServiceBase<
	T extends { id: string },
	TSaveModel extends { id: string | undefined } = Partial<T> & { id: string | undefined }
> extends DatabaseService<T> {
	constructor(http: HttpClient, public readonly metadata: EntityMetadata<T>) {
		super(http, metadata.apiCollectionName);
	}

	/** allows to extend values after loading from database */
	protected onLoaded?: (val: T) => void;
	protected defaultSortCompare?: (t1: T, t2: T) => number;

	protected onSaving?: (t: TSaveModel) => void;

	loadSingle$(id: string): Observable<T> {
		return this.http.get<T>(`/${this.metadata.apiCollectionName}/${id}`).pipe(
			tap(newVersion => {
				if (this.onLoaded) this.onLoaded(newVersion);

				const newList = [...this.list$.value.filter(item => item.id !== newVersion.id), newVersion];

				if (this.defaultSortCompare) {
					newList.sort(this.defaultSortCompare);
				}

				this.list$.next(newList);
			})
		);
	}

	save$(item: TSaveModel): Observable<string> {
		const itemId = item.id;
		if (this.onSaving) {
			item = { ...item };
			this.onSaving(item);
		}
		if (itemId) {
			return this.http.put(`/${this.metadata.apiCollectionName}/${itemId}`, item).pipe(
				switchMap(() => this.loadSingle$(itemId)),
				map(item => item.id)
			);
		} else {
			return this.http.post(`/${this.metadata.apiCollectionName}`, item, { responseType: 'text' }).pipe(
				switchMap(response => this.loadSingle$(response)),
				map(item => item.id)
			);
		}
	}
}

export abstract class StammdatenEntityServiceBase<
	T extends { id: string },
	TSaveModel extends { id: string | undefined } = Partial<T> & { id: string | undefined }
> extends EntityServiceBase<T, TSaveModel> {
	constructor(http: HttpClient, public readonly metadata: EntityMetadata<T>) {
		super(http, metadata);
	}

	freigabeAnfordern$(id: string): Observable<T> {
		return this.http
			.post(`/${this.metadata.apiCollectionName}/${id}/freigabeAnfordern`, null, { responseType: 'text' })
			.pipe(switchMap(response => this.loadSingle$(response)));
	}

	freigeben$(id: string): Observable<T> {
		return this.http
			.post(`/${this.metadata.apiCollectionName}/${id}/freigeben`, null, { responseType: 'text' })
			.pipe(switchMap(response => this.loadSingle$(response)));
	}

	inaktivieren$(id: string): Observable<T> {
		return this.http
			.post(`/${this.metadata.apiCollectionName}/${id}/deaktivieren`, null, { responseType: 'text' })
			.pipe(switchMap(response => this.loadSingle$(response)));
	}
}
