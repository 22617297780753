import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { toExternalModel } from 'src/app/utils';
import { ZuVersteuerndeVorabpauschale } from '../model';

@Pipe({
	name: 'zuVersteuerndeVorabpauschale',
})
export class ZuVersteuerndeVorabpauschalePipe implements PipeTransform {
	transform(value: ZuVersteuerndeVorabpauschale[], type: string = 'anteil'): string {
		if (!value || value.length === 0) return '--';
		if (type === 'anteil') {
			return value
				.map(r => `${r.jahr}: ${formatNumber(toExternalModel(r.betragStpflAnteil), 'de', '1.2-2')} `)
				.join(', ');
		} else if (type === 'betrag') {
			return value
				.map(r => `${r.jahr}: ${formatNumber(toExternalModel(r.betragVorabp), 'de', '1.2-2')} `)
				.join(', ');
		} else return '';
	}
}
