<h1 mat-dialog-title>Freigabe bestätigen</h1>

<div mat-dialog-content *ngIf="showOppositionControls" class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<app-fixedlist-input
			[metadata]="metadata.fields.oppositionFreigeber"
			formControlName="oppositionFreigeber"></app-fixedlist-input>
	</div>
	<div *ngIf="form.controls.oppositionFreigeber.value" class="flex-row">
		<app-text-input [metadata]="metadata.fields.bemerkung" formControlName="bemerkung"></app-text-input>
	</div>
</div>

<div mat-dialog-actions style="display: flex; gap: 1em">
	<button mat-raised-button color="accent" mat-dialog-close>
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button
		mat-raised-button
		color="primary"
		[mat-dialog-close]="result$ | async"
		[disabled]="disableInterneKlaerung$ | async">
		<mat-icon>quiz</mat-icon>
		Interne Klärung anfordern
	</button>
	<button
		mat-raised-button
		color="primary"
		[mat-dialog-close]="result$ | async"
		[disabled]="disableFreigabe$ | async">
		<mat-icon>approval</mat-icon>
		Freigabe erteilen
	</button>
</div>
