import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	NgControl,
	ValidatorFn,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription } from 'src/app/model';

@Component({
	selector: 'app-string-array-input',
	templateUrl: './string-array-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringArrayInputComponent extends BaseComponent implements ControlValueAccessor {
	@Input() metadata?: FieldDescription;
	@Input() set validator(v: ValidatorFn) {
		this.inputControl.setValidators(v);
	}
	private onChange?: (list: string[]) => void;
	private onTouchedCallback: any;
	public readonly inputControl = new UntypedFormControl(null);

	public readonly values$ = new BehaviorSubject<string[]>([]);

	constructor(@Optional() @Self() public readonly ngControl: NgControl) {
		super();
		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl)
			throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}

	writeValue(obj?: string[] | null): void {
		this.values$.next(obj ?? []);
	}

	registerOnChange(fn: (list: string[]) => void): void {
		this.onChange = fn;
	}

	add(event: MatChipInputEvent): void {
		if (!event.value) return;
		if (!this.inputControl.valid) return;
		this.inputControl.setValue(null, { emitEvent: false });

		var values = new Set<string>(this.values$.value);
		values.add(event.value);
		this.values$.next([...values]);
		if (this.onChange) this.onChange(this.values$.value);
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	remove(value: string): void {
		this.values$.next(this.values$.value.filter(v => v !== value));
		if (this.onChange) this.onChange(this.values$.value);
	}

	onBlur(): void {
		if (this.inputControl.value) {
			this.inputControl.setErrors({ errorMessage: 'Akzeptiere oder lösche Text' });
		}
	}

	onFocus(): void {
		this.inputControl.updateValueAndValidity();
	}
}
