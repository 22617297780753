<form mat-dialog-content class="flex-column" [formGroup]="form" (ngSubmit)="save()">
	<app-text-input style="flex: 1" formControlName="kuerzel" [metadata]="data.metadata.kuerzel"></app-text-input>

	<app-text-input
		style="flex: 1"
		formControlName="bezeichnung"
		[metadata]="data.metadata.bezeichnung"></app-text-input>

	<ng-container *ngIf="data.metadata.extra">
		<ng-container [ngSwitch]="data.metadata.extra.typ">
			<app-kuerzel-bezeichnung-choose
				*ngSwitchCase="'referenzlist'"
				formControlName="extra"
				[metadata]="data.metadata.extra"
				style="flex: 1"></app-kuerzel-bezeichnung-choose>

			<app-numeric-input
				*ngSwitchCase="'number'"
				style="flex: 1"
				formControlName="extra"
				[metadata]="data.metadata.extra"></app-numeric-input>

			<app-text-input
				*ngSwitchDefault
				style="flex: 1"
				formControlName="extra"
				[metadata]="data.metadata.extra"></app-text-input>
		</ng-container>
	</ng-container>

	<div style="display: flex; justify-content: space-between; gap: 1em">
		<div>
			<button mat-raised-button mat-dialog-close color="accent" [disabled]="isSaving$ | async">Abbrechen</button>
		</div>

		<div>
			<button
				mat-raised-button
				color="primary"
				type="submit"
				[disabled]="cannotSave$ | async"
				*requireRole="'erweitert'">
				<mat-icon *ngIf="isSaving$ | async | not">save</mat-icon>
				<mat-spinner
					*ngIf="isSaving$ | async"
					[diameter]="20"
					style="display: inline-block; margin-right: 0.5em"></mat-spinner>
				Speichern
			</button>
		</div>
	</div>
</form>
