<h1 *ngIf="showHeader">Einreichung {{ einreichung.nummer }}</h1>

<div [ngStyle]="!showHeader ? { 'padding-left': '10px', 'padding-top': '15px' } : {}">
	<div *ngIf="einreichung.einreicher" class="flex-row">
		<div class="data-column2" style="max-width: 40em">
			<div app-data-item [field]="metadata.fields.einreicher">
				<button
					mat-flat-button
					color="accent"
					(click)="quickInfo.showFinanzinstitut(einreichung.einreicher)">
					{{ einreichung.einreicher | finanzinstitutDisplayName$ | async }}
				</button>
			</div>
		</div>
		<div class="data-column2" style="max-width: 40em"></div>
	</div>

	<div class="flex-row">
		<div class="data-column2" style="max-width: 40em">
			<div app-data-item [field]="metadata.fields.eingangsdatum">
				{{ einreichung.eingangsdatum | date : 'dd.MM.yyyy' }}
			</div>
			<div app-data-item [field]="metadata.fields.versandart">
				{{ einreichung.versandart }}
			</div>
			<div app-data-item [field]="metadata.fields.posteingangsdatum">
				{{ einreichung.posteingangsdatum | date : 'dd.MM.yyyy' }}
			</div>
			<div app-data-item [field]="metadata.fields.absender">
				{{ einreichung.absender }}
			</div>
			<div app-data-item [field]="metadata.fields.sendungsnummer">
				{{ einreichung.sendungsnummer }}
			</div>
			<div app-data-item [field]="metadata.fields.empfaenger">
				{{ einreichung.empfaenger }}
			</div>
			<div app-data-item [field]="metadata.fields.betrag">
				{{ einreichung.betrag | money : '' : metadata.fields.betrag }}
			</div>
			<div app-data-item [field]="metadata.fields.waehrungStueck">
				{{ einreichung.waehrungStueck }}
			</div>
			<div app-data-item [field]="metadata.fields.anzahlUmschlaege">
				{{ einreichung.anzahlUmschlaege }}
			</div>
			<div app-data-item label="Dokumentenlink:">
				<a
					rel="noopener"
					[href]="fileNetBaseLink"
					matTooltip="{{ fileNetBaseLink }}"
					target="_blank"
					mat-button
					*ngIf="fileNetBaseLink"
					color="primary">
					<mat-icon>open_in_new</mat-icon>
					FileNet Dokumente
				</a>
			</div>
		</div>

		<div *ngIf="!einreichung.einreicher" class="data-column2" style="max-width: 40em">
			<div app-data-item [field]="metadata.fields.erbfall">
				{{ einreichung.erbfall | booleanText }}
			</div>
			<div app-data-item [field]="metadata.fields.depotNummer">
				{{ einreichung.depotNummer }}
			</div>
			<div app-data-item [field]="metadata.fields.depotInhaber">
				{{ einreichung.depotInhaber }}
			</div>
			<div app-data-item [field]="metadata.fields.konto">
				{{ einreichung.konto | kontonummer }}
			</div>
			<div app-data-item [field]="metadata.fields.kontoInhaber">
				{{ einreichung.kontoInhaber }}
			</div>
			<div app-data-item [field]="metadata.fields.bic">
				{{ einreichung.bic }}
			</div>
			<div app-data-item [field]="metadata.fields.couponShouldPay">
				{{ einreichung.couponShouldPay ? 'ja' : 'nein' }}
			</div>
			<div app-data-item [field]="metadata.fields.vorabpauschaleEingegangen">
				{{ einreichung.vorabpauschaleEingegangen | booleanText }}
			</div>
			<div app-data-item [field]="metadata.fields.vorabpauschaleGesamtsumme">
				{{
					einreichung.vorabpauschaleGesamtsumme
						| money : 'EUR' : metadata.fields.vorabpauschaleGesamtsumme
				}}
			</div>
			<div app-data-item [field]="metadata.fields.vorabpauschale">
				{{ einreichung.vorabpauschale | money : 'EUR' : metadata.fields.vorabpauschale }}
			</div>
			<div app-data-item label="Vorabpauschale (KESt)">
				{{ vorabpauschaleKest | money : 'EUR' : metadata.fields.vorabpauschale }}
			</div>
			<div app-data-item label="Vorabpauschale (Soli)">
				{{ vorabpauschaleSoli | money : 'EUR' : metadata.fields.vorabpauschale }}
			</div>
			<div app-data-item label="Vorabpauschale (KiSt)">
				{{ vorabpauschaleKiSt | money : 'EUR' : metadata.fields.vorabpauschale }}
			</div>
		</div>

		<div class="data-column2" style="max-width: 40em">
			<div app-data-item [field]="metadata.fields.oppositionstreffer">
				{{ einreichung.oppositionstreffer | oppositionspruefungText }}
			</div>
			<div app-data-item [field]="metadata.fields.oppositionFreigeber">
				{{ einreichung.oppositionFreigeber | oppositionspruefungText }}
			</div>
			<div app-data-item [field]="metadata.fields.oppositionSystem">
				{{ einreichung.oppositionSystem | oppositionspruefungText }}
			</div>
			<div *ngIf="!einreichung.einreicher" app-data-item [field]="metadata.fields.kistam">
				{{ einreichung.kistam }}
			</div>
			<div
				*ngIf="!einreichung.einreicher"
				app-data-item
				[field]="metadata.fields.kistamProzentsatz">
				{{ einreichung.kistamProzentsatz | percent }}
			</div>
			<div app-data-item [field]="metadata.fields.religionszugehoerigkeit">
				{{ einreichung.religionszugehoerigkeit | religionszugehoerigkeit }}
			</div>
			<div app-data-item [field]="metadata.fields.abbruchStornoGrund">
				{{ einreichung.abbruchStornoGrund }}
			</div>
			<div app-data-item [field]="metadata.fields.externeReferenz">
				{{ einreichung.externeReferenz }}
			</div>
			<div app-data-item [field]="metadata.fields.neuerfassungZu">
				<div *ngIf="neuerfassungZu$ | async as neuefassungZu; else spinner">
					<button
						mat-flat-button
						color="accent"
						(click)="quickInfo.showEinreichung(neuefassungZu.id)"
						*ngIf="neuefassungZu.id">
						{{ neuefassungZu.nummer }}
					</button>
				</div>
			</div>
			<div app-data-item [field]="metadata.fields.valutatag">
				{{ einreichung.valutatag | date : 'dd.MM.yyyy' }}
			</div>
			<div app-data-item [field]="metadata.fields.dsgvoAenderungsgrund">
				{{ einreichung.dsgvoAenderungsgrund }}
			</div>
			<div app-data-item [field]="metadata.fields.kassenvereinsnummer">
				{{ einreichung.kassenvereinsnummer }}
			</div>
		</div>
	</div>

	<hr />

	<div class="flex-row">
		<div class="data-column2">
			<div app-data-item [field]="metadata.fields.bemerkung"></div>
			<div>
				{{ einreichung.bemerkung }}
			</div>
		</div>
	</div>

	<hr />

	<div class="flex-row">
		<div class="data-column2" style="max-width: 40em">
			<div>
				<span>{{ metadata.fields.geaendertVon.label }}:</span>
				<span>{{ einreichung.geaendertVon }}</span>
			</div>
			<div>
				<span>{{ metadata.fields.geaendertAm.label }}:</span>
				<span>{{ einreichung.geaendertAm | date : 'dd.MM.yyyy' }}</span>
			</div>
		</div>
		<div class="data-column2" style="max-width: 40em">
			<div>
				<span>{{ metadata.fields.freigegebenVon.label }}:</span>
				<span>{{ einreichung.freigegebenVon }}</span>
			</div>
			<div>
				<span>{{ metadata.fields.freigegebenAm.label }}:</span>
				<span>{{ einreichung.freigegebenAm | date : 'dd.MM.yyyy' }}</span>
			</div>
		</div>
		<div class="data-column2" style="max-width: 40em">
			<div></div>
		</div>
	</div>
</div>

<ng-template #spinner>
	<mat-spinner></mat-spinner>
</ng-template>
