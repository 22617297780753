<section class="table-container mat-elevation-z2">
	<table
		mat-table
		[dataSource]="dataSource"
		class="gattung-table"
		matSort
		[trackBy]="trackBy">
		<ng-container matColumnDef="selection">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 4em"></th>
			<td mat-cell *matCellDef="let element" (click.stop)="({})" style="text-align: center">
				<mat-checkbox
					[(ngModel)]="element.isChosen"
					(change)="onChangeChosen()"></mat-checkbox>
			</td>
		</ng-container>
		<ng-container matColumnDef="nummer">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th1" mat-sort-header>
				{{ metadata.fields.nummer.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.nummer }}</td>
		</ng-container>

		<ng-container matColumnDef="einreicherName">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header="einreicherName">
				Einreicher
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.einreicherName }}
			</td>
		</ng-container>

		<ng-container matColumnDef="eingangsdatum">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th4" mat-sort-header>
				{{ metadata.fields.eingangsdatum.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.eingangsdatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="freigegebenAm">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th3" mat-sort-header>
				{{ metadata.fields.freigegebenAm.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.freigegebenAm | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="absender">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.fields.absender.label }}
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.absender }}
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				{{ metadata.fields.status.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.status }}</td>
		</ng-container>

		<ng-container matColumnDef="kistam">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th2" mat-sort-header>
				{{ metadata.fields.kistam.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.kistam }}</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef scope="col" class="action-column-local"></th>
			<td mat-cell *matCellDef="let element" class="action-column action-column-local">
				<button
					mat-icon-button
					[routerLink]="metadata.routing.view!.url(element.id)"
					matTooltip="Ansicht">
					<mat-icon>visibility</mat-icon>
				</button>
				<button
					mat-icon-button
					[routerLink]="metadata.routing.edit!.url(element.id)"
					matTooltip="Ändern"
					[disabled]="!(element | isEditable)"
					*requireRole="'standard'">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>

		<!-- Table Header Zeile -->
		<ng-container *matHeaderRowDef="displayedColumns">
			<tr mat-header-row></tr>
			<tr class="table-filter-row" [formGroup]="filterForm">
				<th
					scope="col"
					style="padding-left: 16px"
					*ngIf="displayedColumns[0] === 'selection'">
					<mat-checkbox
						style="margin-top: -30px"
						(change)="toggleAll()"
						formControlName="selectForExport"></mat-checkbox>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill" class="table-filter-row-th">
						<input
							matInput
							placeholder="Suche..."
							formControlName="nummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="name"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<mat-date-range-input [rangePicker]="eingangsdatumPicker">
							<input
								matStartDate
								placeholder="Beginn"
								formControlName="eingangsdatumAnfang" />
							<input
								matEndDate
								placeholder="Ende"
								formControlName="eingangsdatumEnde" />
						</mat-date-range-input>
						<mat-datepicker-toggle
							matSuffix
							style="padding-left: 0px"
							[for]="eingangsdatumPicker">
							<mat-icon matDatepickerToggleIcon>today</mat-icon>
						</mat-datepicker-toggle>
						<mat-date-range-picker #eingangsdatumPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col"></th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="absender"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<mat-select
							formControlName="status"
							placeholder="Suche..."
							panelClass="status-filter-panel-250">
							<mat-option [value]="">Kein Statusfilter</mat-option>
							<mat-option
								*ngFor="let status of einreichungStatus | keyvalue"
								[value]="status.value">
								{{ status.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="kistam"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col" class="action-column"></th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="itemClicked$.next(row)"></tr>
	</table>
</section>
<mat-paginator showFirstLastButtons></mat-paginator>
