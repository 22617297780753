import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeschaeftsfallMitPositionen } from '../../model';

@Component({
	selector: 'app-steuern-popup',
	templateUrl: './steuern-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SteuernPopupComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: GeschaeftsfallMitPositionen) {}
}
