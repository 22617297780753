import { Component } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { DisplayErrorService } from 'src/app/shared/display-error.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { isValid$ } from 'src/app/utils';
import { logger } from 'src/logger';
import { KuerzelBezeichnungReferenz } from '../../kuerzel-bezeichnung/model';
import { StammdatenStatus } from '../../model';
import { FinanzinstitutService } from '../finanzinstitut.service';
import { finanzinstitutMetadata } from '../model';

@Component({
	selector: 'app-finanzinstitut-edit-page',
	templateUrl: './finanzinstitut-edit-page.component.html',
})
export class FinanzinstitutEditPageComponent extends BaseComponent {
	public readonly metadata = finanzinstitutMetadata;

	public readonly form = new TypedForm<EditFinanzinstitutFormValues>({
		finanzinstitutsname: createFormControl(finanzinstitutMetadata.fields.finanzinstitutsname),
		finanzinstitutsnummer: createFormControl(finanzinstitutMetadata.fields.finanzinstitutsnummer),
		status: createFormControl(finanzinstitutMetadata.fields.status),
		finanzinstitutskonto: createFormControl(finanzinstitutMetadata.fields.finanzinstitutskonto),
		istEinreicher: createFormControl(finanzinstitutMetadata.fields.istEinreicher),
		istEmittent: createFormControl(finanzinstitutMetadata.fields.istEmittent),
		adresszusatz: createFormControl(finanzinstitutMetadata.fields.adresszusatz),
		adresse: createFormControl(finanzinstitutMetadata.fields.adresse),
		postleitzahl: createFormControl(finanzinstitutMetadata.fields.postleitzahl),
		ort: createFormControl(finanzinstitutMetadata.fields.ort),
		bundesland: createFormControl(finanzinstitutMetadata.fields.bundesland),
		land: createFormControl(finanzinstitutMetadata.fields.land),
		geaendertVon: createFormControl(finanzinstitutMetadata.fields.geaendertVon),
		geaendertAm: createFormControl(finanzinstitutMetadata.fields.geaendertAm),
		freigegebenVon: createFormControl(finanzinstitutMetadata.fields.freigegebenVon),
		freigegebenAm: createFormControl(finanzinstitutMetadata.fields.freigegebenAm),
	});

	public readonly id$ = this.activatedRoute.paramMap.pipe(
		map(params => {
			const id = params.get('id');
			if (!id || id === 'new') return null;
			return id;
		}),
		shareReplay(1)
	);

	public readonly isSaving$ = new BehaviorSubject(false);
	public readonly cannotSave$ = combineLatest([this.isSaving$, isValid$(this.form)]).pipe(
		map(([isSaving, isValid]) => isSaving || !isValid || this.form.pristine)
	);

	public lastId?: string | null;

	public readonly land$ = this.form.controls.land.valueChanges.pipe(startWith(null));
	public readonly openItemsMessage$ = new BehaviorSubject<string | null>(null);

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		public readonly service: FinanzinstitutService,
		private readonly router: Router,
		private readonly displayError: DisplayErrorService,
		private readonly alert: AlertService
	) {
		super();

		this.form.addValidators(mussEinreicherOderEmittent);

		this.form.controls.finanzinstitutsnummer.addValidators(control => {
			if (!control.value) return null;

			const existingInstitut = service.list$.value.find(
				institut => institut.finanzinstitutsnummer === control.value
			);
			if (!existingInstitut || existingInstitut.id === this.lastId) return null;

			return {
				finanzinstitutNummerSchonVergeben: `Nummer bereits vergeben an Finanzinstitut: ${existingInstitut.displayName} `,
			};
		});

		this.registerSubscription(
			this.id$.subscribe(id => {
				this.lastId = id;
				if (!id) {
					this.form.reset({ land: 'DE' });
				} else {
					this.form.disable();
					combineLatest([
						this.service.loadSingle$(id),
						this.service.finanzinstitutAktiveNutzungInfo$(id),
					]).subscribe(([f, info]) => {
						this.form.reset({
							finanzinstitutsname: f.finanzinstitutsname,
							finanzinstitutsnummer: f.finanzinstitutsnummer,
							finanzinstitutskonto: f.finanzinstitutskonto,
							istEmittent: f.istEmittent,
							istEinreicher: f.istEinreicher,
							adresszusatz: f.adresszusatz,
							adresse: f.adresse,
							postleitzahl: f.postleitzahl,
							ort: f.ort,
							bundesland: f.bundesland,
							land: f.land,
							status: f.status,
							geaendertVon: f.geaendertVon,
							geaendertAm: f.geaendertAm,
							freigegebenVon: f.freigegebenVon,
							freigegebenAm: f.freigegebenAm,
						});

						this.form.enable();
						this.form.controls.finanzinstitutsnummer.disable();
						if (f.status === StammdatenStatus.Aktiv && info?.inActiveUse) {
							const message = this.service.assembleOpenItemMessage(info);
							this.openItemsMessage$.next(message);
						}
					});
				}
			})
		);
	}

	onUserEnteredPostleitzahl(ref: KuerzelBezeichnungReferenz): void {
		if (!ref) return;

		const currentValues = this.form.typedValue;
		if (!currentValues.bundesland && !currentValues.ort) {
			this.form.patchValue({ ort: ref.bezeichnung, bundesland: ref.extra });
		}
	}

	save(): void {
		const values = this.form.typedValue;
		this.isSaving$.next(true);
		this.form.disable();

		this.id$
			.pipe(
				take(1),
				switchMap(id =>
					this.service.save$({
						...values,
						finanzinstitutsnummer: this.form.controls.finanzinstitutsnummer.value,
						istEinreicher: values.istEinreicher ?? false,
						istEmittent: values.istEmittent ?? false,
						id: id ? id : undefined,
					})
				)
			)
			.subscribe({
				error: err => {
					this.displayError.showError('Speichern fehlgeschlagen', err);
					logger.error(err);
					this.isSaving$.next(false);
					this.form.enable();
				},
				next: data => {
					this.isSaving$.next(false);
					this.alert.success('Gespeichert!');
					this.router.navigate([this.service.metadata.routing.view!.url(data)]);
				},
			});
	}
}

interface EditFinanzinstitutFormValues {
	finanzinstitutsname: string;
	finanzinstitutsnummer: string;
	status: StammdatenStatus;
	istEinreicher: boolean | false;
	istEmittent: boolean | false;
	finanzinstitutskonto: string | null;
	adresszusatz: string | null;
	adresse: string | null;
	postleitzahl: string | null;
	ort: string | null;
	land: string | undefined;
	bundesland: string | undefined;
	geaendertVon: string;
	geaendertAm: Date;
	freigegebenVon: string | null;
	freigegebenAm: Date | null;
}

function mussEinreicherOderEmittent(form: AbstractControl): ValidationErrors | null {
	const value = (form as TypedForm<EditFinanzinstitutFormValues>).typedValue;

	if (value.istEinreicher || value.istEmittent) {
		return null;
	}

	return { mussEinreicherOderEmittent: true };
}
