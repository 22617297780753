import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { AlertService } from '../shared/alert.service';
import { DatabaseService } from '../shared/database.service';
import { parseIncomingAsDate, toExternalModel, toInternalModel } from '../utils';
import { Buchung } from './buchungen/model';
import { Einreichungspositionssteuer, Geschaeftsfall, geschaeftsfallMetadata } from './model';

@Injectable({
	providedIn: 'root',
})
export class GeschaeftsfallService extends DatabaseService<Geschaeftsfall> {
	public readonly metadata = geschaeftsfallMetadata;

	constructor(http: HttpClient, private readonly alertService: AlertService) {
		super(http, 'geschaeftsfall');

		this.onLoaded = gf => {
			gf.positionen ||= [];
			gf.inkassoVersanddatum = parseIncomingAsDate(gf.inkassoVersanddatum);
			gf.erstelltAm = parseIncomingAsDate(gf.erstelltAm);
			gf.geaendertAm = parseIncomingAsDate(gf.geaendertAm);

			gf.bruttobetrag = toInternalModel(gf.bruttobetrag);
			gf.cdcGebuehren = toInternalModel(gf.cdcGebuehren);
			gf.cdcZinsgutschrift = toInternalModel(gf.cdcZinsgutschrift);
			gf.dekaGebuehren = toInternalModel(gf.dekaGebuehren);
			gf.inkassobetrag = toInternalModel(gf.inkassobetrag);
			gf.kapitalertragssteuer = toInternalModel(gf.kapitalertragssteuer);
			gf.kirchensteuer = toInternalModel(gf.kirchensteuer);
			gf.solidaritaetszuschlag = toInternalModel(gf.solidaritaetszuschlag);
			gf.zinsabschlagsteuer = toInternalModel(gf.zinsabschlagsteuer);
			gf.kapitalertragssteuerCalculated = toInternalModel(gf.kapitalertragssteuerCalculated);
			gf.kirchensteuerCalculated = toInternalModel(gf.kirchensteuerCalculated);
			gf.solidaritaetszuschlagCalculated = toInternalModel(gf.solidaritaetszuschlagCalculated);
			gf.zinsabschlagsteuerCalculated = toInternalModel(gf.zinsabschlagsteuerCalculated);
			gf.nettobetrag = toInternalModel(gf.nettobetrag);
			gf.steuern = toInternalModel(gf.steuern);
			gf.zuVerrechnendeVorabpauschale = toInternalModel(gf.zuVerrechnendeVorabpauschale);
			gf.buchungen ||= [];
			gf.buchungen.forEach(b => (b.betrag = toInternalModel(b.betrag)));
		};
	}

	public getByEinreichung$(id: string): Observable<Geschaeftsfall[]> {
		return this.http.get<Geschaeftsfall[]>(`/einreichung/${id}/geschaeftsfall`).pipe(
			tap(data => {
				data.forEach(this.onLoaded!);

				const loadedIds = data.map(g => g.id);
				this.list$.next(this.list$.value.filter(gf => !loadedIds.includes(gf.id)).concat(data));
			})
		);
	}

	public getSingle$(id: string): Observable<Geschaeftsfall> {
		return this.http.get<Geschaeftsfall>(`/geschaeftsfall/${id}`).pipe(
			tap(data => {
				this.onLoaded!(data);

				this.list$.next(this.list$.value.filter(gf => gf.id !== data.id).concat([data]));
			})
		);
	}

	public anteilsgeschaeftsabschluss$(id: string, payload: AnteilsgeschaeftsabschlussPayload): Observable<any> {
		return this.http.post(`/geschaeftsfall/${id}/anteilsgeschaeftsabschluss`, payload, {
			observe: 'body',
			responseType: 'text' as 'json',
		});
	}

	public abbrechen$(id: string, payload: AbbrechenPayload): Observable<any> {
		return this.http.post(`/geschaeftsfall/${id}/abbrechen`, payload, {
			observe: 'body',
			responseType: 'text' as 'json',
		});
	}

	public storno$(id: string, payload: StornoPayload): Observable<any> {
		return this.http.post(`/geschaeftsfall/${id}/abbrechen`, payload, {
			observe: 'body',
			responseType: 'text' as 'json',
		});
	}

	public addKycCheck$(payload: KycPayload): Observable<any> {
		return this.http.post(`/kyccheck/add`, payload, {
			observe: 'body',
			responseType: 'text' as 'json',
		});
	}

	public inkasso$(id: string, payload: InkassoPayload): Observable<any> {
		payload.cdcGebuehren = toExternalModel(payload.cdcGebuehren);
		payload.cdcZinsgutschrift = toExternalModel(payload.cdcZinsgutschrift);
		payload.dekaGebuehren = toExternalModel(payload.dekaGebuehren);
		payload.inkassobetrag = toExternalModel(payload.inkassobetrag);

		if (payload.inkassoAbschliessen) {
			payload.bruttobetrag = toExternalModel(payload.bruttobetrag);
			payload.nettobetrag = toExternalModel(payload.nettobetrag);
			payload.steuern = toExternalModel(payload.steuern);
			payload.solidaritaetszuschlag = toExternalModel(payload.solidaritaetszuschlag);
			payload.kirchensteuer = toExternalModel(payload.kirchensteuer);
			payload.kapitalertragssteuer = toExternalModel(payload.kapitalertragssteuer);
			payload.zinsabschlagsteuer = toExternalModel(payload.zinsabschlagsteuer);
			payload.solidaritaetszuschlagCalculated = toExternalModel(payload.solidaritaetszuschlagCalculated);
			payload.kirchensteuerCalculated = toExternalModel(payload.kirchensteuerCalculated);
			payload.kapitalertragssteuerCalculated = toExternalModel(payload.kapitalertragssteuerCalculated);
			payload.zinsabschlagsteuerCalculated = toExternalModel(payload.zinsabschlagsteuerCalculated);
			payload.positionsteuern = payload.positionsteuern.map(st => ({
				...st,
				steuerbetrag: toExternalModel(st.steuerbetrag),
				steuergrundbetrag: toExternalModel(st.steuergrundbetrag),
			}));
			payload.buchungen = payload.buchungen.map(st => ({
				...st,
				betrag: st.betrag && toExternalModel(st.betrag),
				geschaeftsfallId: id,
			}));
		}

		return this.http.post(`/geschaeftsfall/${id}/inkasso`, payload, {
			observe: 'body',
			responseType: 'text' as 'json',
		});
	}

	public druckAnfordern$(id: string): Observable<any> {
		return this.http
			.post(`/geschaeftsfall/${id}/druckAnfordern`, null, {
				observe: 'body',
				responseType: 'text' as 'json',
			})
			.pipe(
				catchError(err => {
					const he = err as HttpErrorResponse;
					this.alertService.error('Druckanforderung fehlgeschlagen', err, {
						message: he?.message,
					});
					return EMPTY;
				})
			);
	}

	public luxSammelabwicklung$(): Observable<any> {
		return this.http.post(`/geschaeftsfall/luxSammelabwicklung`, null, {
			observe: 'body',
			responseType: 'text' as 'json',
		});
	}

	public sammelverarbeitung$(): Observable<HttpResponse<any>> {
		return this.http.post<any>('/geschaeftsfall/sammelverarbeitung', null, { observe: 'response' });
	}

	kundendokumenteGenerieren$(id: string): Observable<Blob> {
		return this.http.get(`/geschaeftsfall/${id}/kundendokumente`, {
			observe: 'body',
			responseType: 'blob',
		});
	}

	public postChosenGeschaeftsfall$(payload: Geschaeftsfall[]): Observable<Geschaeftsfall[]> {
		return this.http.post<Geschaeftsfall[]>('/kyccheck/check/geschaeftsfaelle', payload, { observe: 'body'})
	}
}

export type InkassoPayload = InkassoDraftPayload | InkassoAbschlussPayload;

export interface InkassoDraftPayload {
	inkassobetrag: number;
	cdcGebuehren: number;
	cdcZinsgutschrift: number;
	dekaGebuehren: number;
	inkassoAbschliessen: false;
}

export interface InkassoAbschlussPayload {
	inkassoAbschliessen: true;
	inkassobetrag: number;
	cdcGebuehren: number;
	cdcZinsgutschrift: number;
	dekaGebuehren: number;
	kapitalertragssteuer: number;
	kapitalertragssteuerCalculated: number;
	zinsabschlagsteuer: number;
	zinsabschlagsteuerCalculated: number;
	solidaritaetszuschlag: number;
	solidaritaetszuschlagCalculated: number;
	kirchensteuer: number;
	kirchensteuerCalculated: number;
	steuern: number;
	nettobetrag: number;
	bruttobetrag: number;
	positionsteuern: (Einreichungspositionssteuer & { einreichungspositionId: string })[];
	buchungen: Buchung[];
}

export interface AbbrechenPayload {
	abbruchStornoGrund: string;
}

export interface StornoPayload {
	abbruchStornoGrund: string;
	buchungenStornieren: boolean;
}

export interface AnteilsgeschaeftsabschlussPayload {
	vorgangsnummer: string;
}

export interface KycPayload {
	pepPruefungsnummer:string;
	embargoPruefungsnummer:string;
	resultat:string;
	datum: string;
	kommentar:string;
	tblEinreichungPerson:number;
}
