import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityMetadata, FieldDescription } from 'src/app/model';
import { EinreichungStatus } from '../model';

@Injectable({
	providedIn: 'root',
})
export class EinreichungSucheService {
	constructor(private readonly http: HttpClient) {}

	public search$(criteria: Einreichungssuchekriterien): Observable<Einreichungssucheeintrag[]> {
		return this.http.post<Einreichungssucheeintrag[]>('/einreichung/suche', criteria);
	}

	public searchAll$(criteria: Einreichungssuchekriterien): Observable<Einreichungssucheeintrag[]> {
		return this.http.post<Einreichungssucheeintrag[]>('/einreichung/suche/alle', criteria);
	}
}

export interface Einreichungssuchekriterien {
	page: number;
	pageSize: number;
	orderBy?: 'nummer' | 'eingangsdatum' | 'absender' | 'status' | null;
	orderDirection?: 'asc' | 'desc' | null;
	nummer?: number | null;
	einreicher?: string | null;
	// wirtschaftlichBerechtigter?: string | null;
	eingangsdatumAb?: string | null;
	eingangsdatumBis?: string | null;
	freigegebendatumAb?: string | null;
	freigegebendatumBis?: string | null;
	absender?: string | null;
	status?: string | null;
	einreicherart?: 'privat' | 'geschaeftlich' | null;
}

export interface Einreichungssucheeintrag {
	id: string;
	nummer: number;
	einreicher: string;
	eingangsdatum: Date | null;
	absender: string;
	status: EinreichungStatus;
	freigabedatum: Date | null;
	einreicherart: string | null;
}
