import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

try {
	const theme = localStorage.getItem('theme');
	if (theme) document.body.classList.add(theme);

	const darkMode = localStorage.getItem('darkMode');
	if (darkMode) document.body.classList.add('dark-mode');
} catch {
	console.error('Failed setting theme');
}

// delay initialization so that fonts load (yes, yes, race etc., but a good enough solution for most cases)
setTimeout(() => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule, {})
		.catch(err => console.error(err));
}, 50);
