<div *ngIf="einreichung">
	<mat-expansion-panel [expanded]="true" style="margin-bottom: 1em">
		<mat-expansion-panel-header style="font-weight: bold">Gesamtübersicht Positionen</mat-expansion-panel-header>
		<div class="flex-row">
			<div class="data-column2">
				<div app-data-item [label]="'Anzahl Positionen: '">
					{{ positionen.length }}
				</div>
			</div>
			<div class="data-column2">
				<div app-data-item [label]="'Summe Gesamtwerte über alle Positionen: '">
					{{ gesamtwertSummeEUR | efaNumber }} EUR
				</div>
			</div>
			<div class="data-column2"></div>
		</div>
		<app-einreichung-dotation-info [positionen]="positionen"></app-einreichung-dotation-info>
	</mat-expansion-panel>

	<div *ngFor="let position of positionen">
		<mat-accordion multi>
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header style="font-weight: bold">
					Position {{ position.positionnummer }}
				</mat-expansion-panel-header>

				<div class="flex-row">
					<div class="data-column2">
						<div app-data-item [field]="metadata.fields.gattungId">
							<button mat-flat-button color="accent" (click)="quickInfo.showGattung(position.gattungId)">
								{{ position.gattungsbezeichnung }}
							</button>
						</div>

						<div app-data-item label="WKN / ISIN">{{ position.wkn }} / {{ position.isin }}</div>
						<div app-data-item [field]="gattungMetadata.fields.zahlbarkeitstag">
							{{ position.zahlbarkeitstag | date: 'dd.MM.yyyy' }}
						</div>
						<div app-data-item [field]="gattungMetadata.fields.kuponnummerFaelligkeit">
							{{ position.kuponnummerFaelligkeit }}
						</div>
						<div app-data-item [field]="metadata.fields.vorabpauschale">
							{{ position.vorabpauschale | efaNumber }} EUR
						</div>
						<div app-data-item [field]="metadata.fields.stueckenummer">
							{{ position.stueckenummer }}
						</div>
					</div>
					<div class="data-column2">
						<div app-data-item [field]="gattungMetadata.fields.emittentId">
							<button
								mat-flat-button
								color="accent"
								(click)="quickInfo.showFinanzinstitut(position.emittentId)">
								{{ position.emittentId | finanzinstitutDisplayName$ | async }}
							</button>
						</div>
						<div app-data-item [field]="metadata.fields.anzahl">
							{{ position.anzahl }}
						</div>

						<div app-data-item [field]="metadata.fields.anteil">
							{{ position.anteil | efaNumber: 0 }}
						</div>
						<div app-data-item [field]="metadata.fields.faktor">
							{{ position.faktor | efaNumber: 6 }}
						</div>
					</div>
					<div class="data-column2">
						<div app-data-item style="padding-bottom: 1em">&nbsp;</div>
						<div app-data-item [field]="metadata.fields.nominalwert">
							{{ position.nominalwert | efaNumber }} {{ position.nominalwaehrung }}
						</div>
						<div app-data-item [label]="'Nominal:'">
							{{ position.nominal }}
						</div>
						<div app-data-item [field]="metadata.fields.gesamtwert">
							{{ position.gesamtwert | efaNumber }} EUR
						</div>
					</div>
				</div>
				<!-- {{ position | json }} -->
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
