<h1 mat-dialog-title>Wähle eine Excel Datei</h1>
<div mat-dialog-content>
	<mat-select
		[formControl]="selectSheetControl"
		style="width: 100%"
		placeholder="Reiter auswählen"
		panelClass="filter-panel">
		<mat-option *ngFor="let item of data.workbook.worksheets" [value]="item">
			{{ item.name }}
		</mat-option>
	</mat-select>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-between; gap: 2em">
	<div>
		<button mat-raised-button mat-dialog-close color="accent">
			<mat-icon>close</mat-icon>
			Abbrechen
		</button>
	</div>
	<div>
		<button
			mat-raised-button
			color="primary"
			[disabled]="selectSheetControl.invalid"
			[mat-dialog-close]="selectSheetControl.value">
			<mat-icon>done</mat-icon>
			Auswählen
		</button>
	</div>
</div>
