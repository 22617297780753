import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DisplayErrorService } from './display-error.service';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(
		private readonly snackbar: MatSnackBar,
		private readonly displayError: DisplayErrorService
	) {}

	public success(message: string): void {
		this.snackbar.open(message, 'Schließen', {
			duration: 2000,
			panelClass: ['alert-success'],
		});
	}

	/** Routet Fehler weiter zu DisplayError */
	public error(content: string, err: Error, context?: any): void {
		this.displayError.showError(content, err, context);
	}
}
