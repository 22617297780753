import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, startWith } from 'rxjs';
import { AlertService } from 'src/app/shared/alert.service';
import { EinreichungNotizenService, Einreichungsnotiz } from '../einreichung-notizen.service';

@Component({
	selector: 'app-einreichung-notizen',
	templateUrl: './einreichung-notizen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungNotizenComponent implements OnInit {
	@Input() einreichungId?: string;

	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly notizen$ = new BehaviorSubject<Einreichungsnotiz[]>([]);
	public readonly inhaltControl = new UntypedFormControl(null, Validators.required);

	public readonly cannotSave$ = combineLatest([this.isLoading$, this.inhaltControl.statusChanges]).pipe(
		map(([isLoading, status]) => isLoading || status !== 'VALID'),
		startWith(true)
	);

	constructor(private readonly service: EinreichungNotizenService, private readonly alert: AlertService) {}

	ngOnInit(): void {
		this.reload();
	}

	reload(): void {
		if (!this.einreichungId) return;
		this.isLoading$.next(true);
		this.service.get$(this.einreichungId).subscribe({
			next: data => {
				data.sort((i1, i2) => (i1.speicherzeitpunkt ?? '').localeCompare(i2.speicherzeitpunkt ?? '') * -1);
				this.notizen$.next(data);
				this.isLoading$.next(false);
			},
			error: err => {
				this.alert.error('Notizen fehlgeschlagen!', err);
				this.isLoading$.next(false);
			},
		});
	}

	save(): void {
		if (this.einreichungId && this.inhaltControl.valid) {
			this.service.create$({ einreichungId: this.einreichungId, inhalt: this.inhaltControl.value }).subscribe({
				next: () => {
					this.inhaltControl.reset();
					this.reload();
				},
				error: err => {
					this.alert.error('Speichern fehlgeschlagen!', err);
					this.isLoading$.next(false);
				},
			});
		}
	}
}
