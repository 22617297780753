import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { Einreichung, Einreichungsposition, einreichungspositionMetadata } from '../../model';
import { gattungMetadata } from 'src/app/stammdaten/gattung/model';
import { finanzinstitutMetadata } from 'src/app/stammdaten/finanzinstitut/model';
import { INTERNAL_NUMBER_FACTOR } from 'src/app/utils';

@Component({
	selector: 'app-einreichung-view-positionen',
	templateUrl: './einreichung-view-positionen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungViewPositionenComponent implements OnInit {
	@Input() einreichung?: Einreichung;

	public readonly metadata = einreichungspositionMetadata;
	public readonly gattungMetadata = gattungMetadata;
	public readonly finanzinstitutMetadata = finanzinstitutMetadata;
	positionen: EinreichungspositionExtended[] = [];
	gesamtwertSummeEUR = 0;

	constructor(
		private readonly gattungService: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService,
		public readonly quickInfo: QuickInfoPopupService
	) {}

	ngOnInit(): void {
		if (!this.einreichung) return;
		const gattungen = this.gattungService.list$.value;
		const institute = this.finanzinstitutService.list$.value;
		this.positionen = [...this.einreichung.positionen].map(p => {
			const gattung = p.gattungId ? gattungen.find(g => g.id === p.gattungId) : undefined;
			const institut = gattung?.emittentId ? institute.find(i => i.id === gattung.emittentId) : undefined;
			return {
				...p,
				nominalwert: gattung?.bruttowert ?? 0,
				gattungsbezeichnung: gattung?.gattungsbezeichnung ?? '',
				zahlbarkeitstag: gattung?.zahlbarkeitstag ?? null,
				emittent: institut?.displayName ?? '',
				emittentId: institut?.id,
				nominal: Math.floor(
					((p.anteil ?? INTERNAL_NUMBER_FACTOR) * (p.anzahl ?? 1) * (p.faktor ?? INTERNAL_NUMBER_FACTOR)) /
						INTERNAL_NUMBER_FACTOR /
						INTERNAL_NUMBER_FACTOR
				),
				wkn: gattung?.wkn,
				isin: gattung?.isin,
			};
		});
		this.positionen.sort(positionSorter);
		this.gesamtwertSummeEUR = this.positionen.reduce((agg, cv) => agg + cv.gesamtwert, 0);
	}
}

function positionSorter(p1: Einreichungsposition, p2: Einreichungsposition): number {
	return Math.sign(p1.positionnummer - p2.positionnummer);
}

export interface EinreichungspositionExtended extends Einreichungsposition {
	gattungsbezeichnung: string;
	emittent: string;
	emittentId: string | undefined;
	zahlbarkeitstag: Date | null;
	nominal: number;
	wkn?: string;
	isin?: string;
}
