import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EinreichungNotizenService {
	constructor(private readonly http: HttpClient) {}

	public create$(model: EinreichungsnotizCreateModel): Observable<unknown> {
		return this.http.post<void>(`/einreichung/notiz`, model, { observe: 'body', responseType: 'text' as any });
	}

	public get$(einreichungId: string): Observable<Einreichungsnotiz[]> {
		return this.http.get<Einreichungsnotiz[]>(`/einreichung/${einreichungId}/notiz`);
	}
}

export interface EinreichungsnotizCreateModel {
	einreichungId: string;
	inhalt: string;
}

export interface Einreichungsnotiz {
	id: string;
	einreichungId: string;
	inhalt: string;
	erstelltVon: string;
	speicherzeitpunkt: string;
}
