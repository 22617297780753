import { Oppositionspruefung } from './../model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'oppositionspruefungText',
})
export class OppositionspruefungTextPipe implements PipeTransform {
	transform(value: boolean | undefined | null): string {
		switch (value) {
			case true:
				return Oppositionspruefung.Treffer;
			case false:
				return Oppositionspruefung.KeinTreffer;
			default:
				return Oppositionspruefung.Offen;
		}
	}
}
