import { Injectable } from '@angular/core';
import { determineVorabpauschaleStatus } from 'src/app/berechnung/steuern';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { Gattung, WKZ } from 'src/app/stammdaten/gattung/model';
import { StammdatenStatus } from 'src/app/stammdaten/model';
import { convertCurrency, INTERNAL_NUMBER_FACTOR, toExternalModel, toInternalModel } from 'src/app/utils';
import { Einreichungsposition } from '../../model';

let nextPositionIndex = 1;

@Injectable({
	providedIn: 'root',
})
export class SchnellerfassungService {
	constructor(private readonly gattungService: GattungService) {}

	search(
		criteria: SchnellerfassungSearchCriteria,
		parseKuponnummern: KuponnummernItem[],
		positionen: Einreichungsposition[],
		kistamProzentsatz: number
	): SearchResultItem[] {
		const result: SearchResultItem[] = [];

		const mantelGattung = this.gattungService.list$.value.find(
			g =>
				g.wkz === WKZ.Mantel &&
				(g.isin === criteria.isinOrWkn || g.wkn === criteria.isinOrWkn) &&
				g.status !== StammdatenStatus.Inaktiv
		);

		if (criteria.mantel) {
			result.push({
				bezeichnung: mantelGattung?.gattungsbezeichnung ?? `${criteria.isinOrWkn} MANTEL`,
				kuponnummer: '',
				wkzString: 'Mantel',
				gattung: mantelGattung,
				status: SearchResultItemStatus.NoGattung, // will be re-evaluated later on
				zahlbarkeitstag: mantelGattung?.zahlbarkeitstag ?? null,
				faktor: 1,
			});
		}

		if (criteria.kupons && parseKuponnummern.length > 0) {
			const potentialKupons = this.gattungService.list$.value.filter(
				g =>
					g.wkz === WKZ.Kupon &&
					(g.isin === criteria.isinOrWkn || g.wkn === criteria.isinOrWkn) &&
					g.status !== StammdatenStatus.Inaktiv
			);
			result.push(...findKupons(potentialKupons, parseKuponnummern));
		}

		if (
			mantelGattung &&
			mantelGattung.fusioniertMit &&
			mantelGattung.fusioniertAb &&
			parseKuponnummern.some(p => p.type === 'until-today')
		) {
			const potentialKupons = this.gattungService.list$.value.filter(
				g =>
					g.wkz === WKZ.Kupon &&
					(g.isin === mantelGattung.fusioniertMit || g.wkn === mantelGattung.fusioniertMit)
			);
			const fusionierteKupons = findFusionierteKupons(potentialKupons, mantelGattung);
			result.push(...fusionierteKupons);
		}

		const stueckenummerJson = JSON.stringify(criteria.stueckenummer);
		for (const item of result) {
			item.position = item.gattung && positionen.find(p => p.gattungId === item.gattung?.id);
			if (!item.gattung) {
				item.status = SearchResultItemStatus.NoGattung;
			} else if (item.position) {
				const areSame =
					item.position.anteil === criteria.anteil &&
					item.position.anzahl === criteria.anzahl &&
					JSON.stringify(item.position.stueckenummer) === stueckenummerJson;
				item.status = areSame
					? SearchResultItemStatus.PositionAlreadyOK
					: SearchResultItemStatus.PositionDifferent;
			} else {
				item.status = SearchResultItemStatus.NoPosition;
				const gattung = item.gattung;
				const faktor = (gattung.fusionUmrechnungsfaktor || toInternalModel(1)) * item.faktor;

				const vorabpauschaleItems = determineVorabpauschaleStatus(
					gattung,
					kistamProzentsatz,
					((criteria.anzahl ?? 1) * (criteria.anteil ?? INTERNAL_NUMBER_FACTOR)) / INTERNAL_NUMBER_FACTOR
				);

				item.position = {
					anteil: criteria.anteil,
					anzahl: criteria.anzahl,
					nominalwaehrung: gattung.nominalwaehrung || 'EUR',
					nominalwert: gattung.bruttowert || 0,
					einzelwert: 0,
					gesamtwert:
						convertCurrency(
							(gattung.bruttowert ?? 0) *
								Math.floor(
									((criteria.anteil ?? INTERNAL_NUMBER_FACTOR) *
										(faktor ?? INTERNAL_NUMBER_FACTOR) *
										(criteria.anzahl ?? 1)) /
										INTERNAL_NUMBER_FACTOR /
										INTERNAL_NUMBER_FACTOR
								),
							gattung.nominalwaehrung
						) ?? 0,
					gattungId: gattung.id,
					id: 'new-chErf-' + (nextPositionIndex++).toString(),
					faktor,
					positionnummer: 1000 + nextPositionIndex,
					stueckenummer: criteria.stueckenummer,
					steuern: [],
					vorabpauschale: vorabpauschaleItems.reduce((pv, cv) => pv + cv.steuerbetrag, 0),
					kuponnummerFaelligkeit: gattung.kuponnummerFaelligkeit || '',
				};
			}
		}

		return result;
	}
}

export function findKupons(potentialKupons: Gattung[], kuponnummernItems: KuponnummernItem[]): SearchResultItem[] {
	const gattungenByKuponnummer = potentialKupons.map(gattung => {
		const kuponnummer = gattung.kuponnummerFaelligkeit && Number(gattung.kuponnummerFaelligkeit);
		return {
			kuponnummer: kuponnummer && !isNaN(kuponnummer) ? kuponnummer : null,
			gattung,
		};
	});

	const gattungenToInclude: (Gattung | number)[] = [];

	function maybeAdd(kuponnummer: number): Gattung | undefined {
		const gattung = gattungenByKuponnummer.find(g => g.kuponnummer === kuponnummer)?.gattung;
		if (gattung) {
			if (!gattungenToInclude.includes(gattung)) gattungenToInclude.push(gattung);
			return gattung;
		} else {
			if (!gattungenToInclude.includes(kuponnummer)) gattungenToInclude.push(kuponnummer);
			return undefined;
		}
	}

	for (const kuponnummernItem of kuponnummernItems) {
		switch (kuponnummernItem.type) {
			case 'exact': {
				maybeAdd(kuponnummernItem.number);
				break;
			}
			case 'range': {
				let minZahlbarkeit: Date | undefined;
				let maxZahlbarkeit: Date | undefined;
				for (let kuponnummer = kuponnummernItem.from; kuponnummer <= kuponnummernItem.until; kuponnummer++) {
					const zahlbarkeit = maybeAdd(kuponnummer)?.zahlbarkeitstag;
					if (zahlbarkeit) {
						minZahlbarkeit = minZahlbarkeit && minZahlbarkeit < zahlbarkeit ? minZahlbarkeit : zahlbarkeit;
						maxZahlbarkeit = maxZahlbarkeit && maxZahlbarkeit > zahlbarkeit ? maxZahlbarkeit : zahlbarkeit;
					}
				}

				if (minZahlbarkeit && maxZahlbarkeit) {
					potentialKupons
						.filter(
							g =>
								g.zahlbarkeitstag &&
								g.zahlbarkeitstag >= minZahlbarkeit! &&
								g.zahlbarkeitstag <= maxZahlbarkeit!
						)
						.forEach(g => {
							if (!gattungenToInclude.includes(g)) gattungenToInclude.push(g);
						});
				}
				break;
			}
			case 'until-today': {
				const maxActuallyExistingNumber = Math.max(
					...gattungenByKuponnummer
						.filter(pair => pair.kuponnummer && pair.kuponnummer >= kuponnummernItem.from)
						.map(pair => pair.kuponnummer!)
				);
				if (!maxActuallyExistingNumber) break;

				let minZahlbarkeit: Date | undefined;
				const maxZahlbarkeit = new Date(new Date().toDateString());
				for (let kuponnummer = kuponnummernItem.from; kuponnummer <= maxActuallyExistingNumber; kuponnummer++) {
					const zahlbarkeit = maybeAdd(kuponnummer)?.zahlbarkeitstag;
					if (zahlbarkeit) {
						minZahlbarkeit = minZahlbarkeit && minZahlbarkeit < zahlbarkeit ? minZahlbarkeit : zahlbarkeit;
					}
				}

				if (minZahlbarkeit && maxZahlbarkeit) {
					potentialKupons
						.filter(
							g =>
								g.zahlbarkeitstag &&
								g.zahlbarkeitstag >= minZahlbarkeit! &&
								g.zahlbarkeitstag < maxZahlbarkeit
						)
						.forEach(g => {
							if (!gattungenToInclude.includes(g)) gattungenToInclude.push(g);
						});
				}

				break;
			}
		}
	}

	const result: SearchResultItem[] = [];
	for (const maybeGattung of gattungenToInclude) {
		if (typeof maybeGattung === 'number') {
			result.push({
				bezeichnung: `KUPON ${maybeGattung}`,
				kuponnummer: maybeGattung.toString(),
				wkzString: 'Kupon',
				gattung: undefined,
				status: SearchResultItemStatus.NoGattung, // re-evaluated later on
				zahlbarkeitstag: null,
				faktor: 1,
			});
		} else {
			result.push({
				bezeichnung: maybeGattung.gattungsbezeichnung,
				kuponnummer: maybeGattung.kuponnummerFaelligkeit || '',
				wkzString: 'Kupon',
				gattung: maybeGattung,
				status: SearchResultItemStatus.NoPosition, // re-evaluated later on
				zahlbarkeitstag: maybeGattung.zahlbarkeitstag,
				faktor: 1,
			});
		}
	}

	return result;
}

export interface SchnellerfassungSearchCriteria {
	isinOrWkn: string;
	mantel: boolean;
	kupons: boolean;
	kuponnummern: string;

	anzahl: number;
	anteil: number;
	stueckenummer: string[]; // createFormControl(this.metadata.fields.stueckenummer),
}

export type KuponnummernItem =
	| { type: 'exact'; number: number }
	| { type: 'until-today'; from: number }
	| { type: 'range'; from: number; until: number };

export interface SearchResultItem {
	wkzString: 'Mantel' | 'Kupon';
	bezeichnung: string;
	kuponnummer: string;
	zahlbarkeitstag: Date | null;
	gattung?: Gattung;
	position?: Einreichungsposition;
	status: SearchResultItemStatus;
	faktor: number;
}

export enum SearchResultItemStatus {
	NoGattung = 'Gattung nicht vorhanden oder deaktiviert',
	NoPosition = 'Position wird angelegt',
	PositionAlreadyOK = 'Position bereits angelegt',
	PositionDifferent = 'Position bereits angelegt, aber geändert',
}

export function findFusionierteKupons(potentialKupons: Gattung[], mantelGattung: Gattung): SearchResultItem[] {
	if (!mantelGattung.fusioniertAb) return [];
	let kuponsNachFusioniertAb = potentialKupons.filter(
		k => k.zahlbarkeitstag && k.zahlbarkeitstag >= mantelGattung.fusioniertAb!
	);

	if (kuponsNachFusioniertAb.length === 0) return [];

	const minNummer = Math.min(
		...kuponsNachFusioniertAb.map(k => Number(k.kuponnummerFaelligkeit)).filter(n => n && !isNaN(n))
	);

	const maxZahlbarkeit = new Date(new Date().toDateString());
	const validKupons = potentialKupons.filter(k => {
		if (
			k.zahlbarkeitstag &&
			k.zahlbarkeitstag >= mantelGattung.fusioniertAb! &&
			k.zahlbarkeitstag < maxZahlbarkeit
		) {
			return true;
		}

		if (minNummer && k.kuponnummerFaelligkeit) {
			const nummer = Number(k.kuponnummerFaelligkeit);
			if (nummer && !isNaN(nummer) && nummer >= minNummer) {
				return true;
			}
		}

		return false;
	});

	validKupons.sort((a, b) => {
		if (!a.zahlbarkeitstag && !b.zahlbarkeitstag) {
			return 0;
		} else if (!b.zahlbarkeitstag) {
			return -1;
		} else if (!a.zahlbarkeitstag) {
			return 1;
		} else {
			return a.zahlbarkeitstag < b.zahlbarkeitstag ? -1 : 1;
		}
	});

	return validKupons.map(k => ({
		bezeichnung: `Fusionierter KUPON ${k.gattungsbezeichnung}`,
		kuponnummer: k.kuponnummerFaelligkeit || '',
		wkzString: 'Kupon',
		gattung: k,
		status: SearchResultItemStatus.NoGattung, // re-evaluated later on
		zahlbarkeitstag: k.zahlbarkeitstag,
		faktor: toExternalModel(mantelGattung.fusionUmrechnungsfaktor) || 1,
	}));
}
