<div class="flex-row">
	<mat-card-title
		style="flex: 26em 0 0"
		*ngIf="embeddedMode === 'true' || showButtons === 'true'">
		Geschäftsfall {{ geschaeftsfallMitPositionen.geschaeftsfall.nummer }}
		<small class="text-primary">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.status }}
		</small>
	</mat-card-title>
	<mat-card-subtitle>
		<div
			style="display: flex; justify-content: space-between"
			*ngIf="showButtons === 'true'; else notDisplayedInPopup">
			<div style="flex: auto 0 0; display: flex; gap: 0.5em">
				<span>Gattung {{ geschaeftsfallMitPositionen.geschaeftsfall.isin }}</span>
				<span>
					{{
						geschaeftsfallMitPositionen.geschaeftsfall.istInkasso ? 'INKASSO' : 'DIREKT'
					}}
				</span>
			</div>
			<button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
		</div>
	</mat-card-subtitle>
</div>

<ng-template #notDisplayedInPopup>
	<div style="flex: auto 0 0; display: flex; gap: 0.5em; justify-content: flex-end">
		<span>
			Gattung {{ geschaeftsfallMitPositionen.geschaeftsfall.wkn }} /
			{{ geschaeftsfallMitPositionen.geschaeftsfall.isin }}
		</span>
		<span>
			{{ geschaeftsfallMitPositionen.geschaeftsfall.istInkasso ? 'INKASSO' : 'DIREKT' }}
		</span>
	</div>
</ng-template>

<div class="flex-row" *ngIf="!userHasRoleAnteilsscheingeschaeft">
	<div class="data-column2" style="flex: 40em 0 0">
		<div app-data-item [field]="metadata.fields.einreichungId">
			<button
				mat-flat-button
				color="accent"
				(click)="quickInfo.showEinreichung(geschaeftsfallMitPositionen.einreichung)">
				Nr. {{ geschaeftsfallMitPositionen.einreichung.nummer }} vom
				{{ geschaeftsfallMitPositionen.einreichung.eingangsdatum | date : 'dd.MM.yyyy' }}
			</button>
		</div>
	</div>
</div>

<div [ngClass]="'gf-steuern-' + positionenLocation">
	<div class="data-column2" style="max-width: 50em">
		<div app-data-item [field]="metadata.fields.nettobetrag">
			<span *ngIf="showNettoBetrag">
				{{ geschaeftsfallMitPositionen.geschaeftsfall.nettobetrag | efaNumber }} EUR
			</span>
			<span
				*ngIf="!showNettoBetrag"
				matTooltip="Nettobetrag wird erst nach der Inkassobearbeitung verfügbar">
				--
			</span>
		</div>
		<div app-data-item [field]="metadata.fields.bruttobetrag">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.bruttobetrag | efaNumber }} EUR
		</div>

		<div
			app-data-item
			[field]="metadata.fields.inkassobetrag"
			*ngIf="geschaeftsfallMitPositionen.geschaeftsfall.istInkasso">
			<span *ngIf="geschaeftsfallMitPositionen.geschaeftsfall.inkassobetrag; else nothing">
				{{ geschaeftsfallMitPositionen.geschaeftsfall.inkassobetrag | efaNumber }} EUR
			</span>
		</div>
		<div app-data-item [field]="metadata.fields.cdcZinsgutschrift">
			<span
				*ngIf="geschaeftsfallMitPositionen.geschaeftsfall.cdcZinsgutschrift; else nothing">
				{{ geschaeftsfallMitPositionen.geschaeftsfall.cdcZinsgutschrift | efaNumber }} EUR
			</span>
		</div>
		<div app-data-item [field]="metadata.fields.cdcGebuehren">
			<span *ngIf="geschaeftsfallMitPositionen.geschaeftsfall.cdcGebuehren; else nothing">
				{{ geschaeftsfallMitPositionen.geschaeftsfall.cdcGebuehren | efaNumber }} EUR
			</span>
		</div>
		<div app-data-item [field]="metadata.fields.dekaGebuehren">
			<span *ngIf="geschaeftsfallMitPositionen.geschaeftsfall.dekaGebuehren; else nothing">
				{{ geschaeftsfallMitPositionen.geschaeftsfall.dekaGebuehren | efaNumber }} EUR
			</span>
		</div>
		<div app-data-item [field]="metadata.fields.steuern">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.steuern | efaNumber }} EUR
		</div>
		<div app-data-item [field]="metadata.fields.kapitalertragssteuer" class="indented">
			<app-steuer-ueberschreibung-anzeige
				[calculatedValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.kapitalertragssteuerCalculated
				"
				[finalValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.kapitalertragssteuer
				"></app-steuer-ueberschreibung-anzeige>
		</div>
		<div app-data-item [field]="metadata.fields.solidaritaetszuschlag" class="indented">
			<app-steuer-ueberschreibung-anzeige
				[calculatedValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.solidaritaetszuschlagCalculated
				"
				[finalValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.solidaritaetszuschlag
				"></app-steuer-ueberschreibung-anzeige>
		</div>
		<div app-data-item [field]="metadata.fields.kirchensteuer" class="indented">
			<app-steuer-ueberschreibung-anzeige
				[calculatedValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.kirchensteuerCalculated
				"
				[finalValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.kirchensteuer
				"></app-steuer-ueberschreibung-anzeige>
		</div>
		<div app-data-item [field]="metadata.fields.zinsabschlagsteuer" class="indented">
			<app-steuer-ueberschreibung-anzeige
				[calculatedValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.zinsabschlagsteuerCalculated
				"
				[finalValue]="
					geschaeftsfallMitPositionen.geschaeftsfall.zinsabschlagsteuer
				"></app-steuer-ueberschreibung-anzeige>
		</div>
		<div app-data-item [field]="metadata.fields.dokumenteVersendet">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.dokumenteVersendet | booleanText }}
		</div>
		<div app-data-item [field]="metadata.fields.bemerkungen">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.bemerkungen }}
		</div>
		<div app-data-item [field]="metadata.fields.abbruchStornoGrund">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.abbruchStornoGrund }}
		</div>
		<div app-data-item [field]="metadata.fields.vorgangsnummer">
			{{ geschaeftsfallMitPositionen.geschaeftsfall.vorgangsnummer }}
		</div>
	</div>
	<div class="flex-column">
		<h2 style="margin-top: 0">Positionen</h2>
		<div style="flex: 1">
			<table class="mat-table positionen">
				<thead>
					<tr class="mat-header-row">
						<th class="mat-header-cell" scope="col">Kuponnr./Fälligkeit</th>
						<th class="mat-header-cell" scope="col" style="width: 5em">Nr</th>
						<th class="mat-header-cell" scope="col">Anteilswert</th>
						<th class="mat-header-cell" scope="col" style="width: 8em">Faktor</th>
						<th class="mat-header-cell" scope="col" style="width: 5em">Anteil</th>
						<!-- <th class="mat-header-cell" scope="col">Einzelwert</th> -->
						<th class="mat-header-cell" scope="col" style="width: 5em">Anzahl</th>
						<th class="mat-header-cell" scope="col">Gesamtwert</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let position of geschaeftsfallMitPositionen.positionen">
						<tr class="mat-row">
							<td class="mat-cell">{{ position.kuponnummerFaelligkeit }}</td>
							<td class="mat-cell">{{ position.positionnummer }}</td>
							<td class="mat-cell">
								{{ position.nominalwert | efaNumber }} {{ position.nominalwaehrung }}
							</td>
							<td class="mat-cell">{{ position.faktor | efaNumber : 6 }}</td>
							<td class="mat-cell">{{ position.anteil | efaNumber : 0 }}</td>
							<!-- <td class="mat-cell">{{ position.einzelwert | efaNumber }} EUR</td> -->
							<td class="mat-cell">{{ position.anzahl | number : '1.0-0' : 'de' }}</td>
							<td class="mat-cell">{{ position.gesamtwert | efaNumber }} EUR</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
		<!-- verwendung für einreichung display, tab geschaeftsfaelle -->
		<div class="flex-row" *ngIf="embeddedMode === 'true'">
			<span style="flex: 1"></span>
			<button
				style="flex: 14em 0 0"
				mat-raised-button
				color="accent"
				(click)="bewegungsdatenQuickInfoService.showTaxes(geschaeftsfallMitPositionen)">
				<mat-icon>money_off</mat-icon>
				Steuern
			</button>
			<button
				style="flex: 14em 0 0"
				mat-raised-button
				color="accent"
				(click)="
					bewegungsdatenQuickInfoService.showBuchungen(geschaeftsfallMitPositionen)
				">
				<mat-icon>payments</mat-icon>
				Buchungen
			</button>
			<button
				style="flex: 10em 0 0"
				mat-raised-button
				color="primary"
				*ngIf="showAnsichtButtonResolved"
				[routerLink]="
					metadata.routing.view!.url(geschaeftsfallMitPositionen.geschaeftsfall.id)
				">
				<mat-icon>visibility</mat-icon>
				Ansicht
			</button>
		</div>
		<!-- verwendung für geschaeftsfaelle popup aus gf list -->
		<div style="display: flex; justify-content: flex-end" *ngIf="showButtons === 'true'">
			<button
				style="flex: 10em 0 0"
				mat-raised-button
				color="accent"
				[routerLink]="
					metadata.routing.view!.url(geschaeftsfallMitPositionen.geschaeftsfall.id)
				">
				<mat-icon>visibility</mat-icon>
				Ansicht
			</button>
		</div>
	</div>
</div>

<ng-template #nothing>--</ng-template>
