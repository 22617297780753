import { Injectable } from '@angular/core';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
	providedIn: 'root',
})
export class PdfService {
	public createPdf(def: TDocumentDefinitions): pdfMake.TCreatedPdf {
		return pdfMake.createPdf(def);
	}
}
