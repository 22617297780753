<div class="flex-column">
	<mat-card class="flex-column-mat-card">
		<mat-card-content>
			<form (ngSubmit)="save()" class="flex-row">
				<app-text-input
					label="Neue Notiz"
					[formControl]="inhaltControl"
					style="flex: 5"></app-text-input>
				<div>
					<button
						mat-raised-button
						type="submit"
						[disabled]="cannotSave$ | async"
						color="primary">
						<mat-icon>save</mat-icon>
						Speichern
					</button>
				</div>
			</form>
		</mat-card-content>
	</mat-card>

	<mat-list>
		<mat-list-item *ngFor="let notiz of notizen$ | async">
			<mat-icon mat-list-icon>folder</mat-icon>
			<div mat-line>{{ notiz.inhalt }}</div>
			<div mat-line>{{ notiz.erstelltVon }} {{ notiz.speicherzeitpunkt }}</div>
		</mat-list-item>
	</mat-list>
	<hr />
</div>
