import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
	BehaviorSubject,
	map,
	merge,
	Observable,
	startWith,
	Subject,
	withLatestFrom,
	combineLatest,
	filter,
	switchMap,
	catchError,
	of,
} from 'rxjs';
import { buildGeschaeftsfaelleMitPositionen } from 'src/app/berechnung/geschaeftsfaelle';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { BenutzerRolle } from 'src/app/general/roles';
import { AlertService } from 'src/app/shared/alert.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { BackendQueryResult, queryBackend } from 'src/app/utils';
import { logger } from 'src/logger';
import { EinreichungService } from '../../einreichung.service';
import { GeschaeftsfallService, KycPayload } from '../../geschaeftsfall.service';
import {
	einreichungMetadata,
	Einreichungsperson,
	einreichungspersonKycChecksMetadata,
	geschaeftsfallMetadata,
	GeschaeftsfallMitPositionen,
	GeschaeftsfallStatus,
	KycChecks,
	showKycChecksGfWhiteList,
} from '../../model';
import { GeschaeftsfallAbbrechenPopupComponent } from './geschaeftsfall-abbrechen-popup/geschaeftsfall-abbrechen-popup.component';
import { GeschaeftsfallAbschliessenPopupComponent } from './geschaeftsfall-abschliessen-popup/geschaeftsfall-abschliessen-popup.component';
import { GeschaeftsfallStornoPopupComponent } from './geschaeftsfall-storno-popup/geschaeftsfall-storno-popup.component';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { UntypedFormControl } from '@angular/forms';
import { createFormControl } from 'src/app/model';
import { format } from 'date-fns';
import { Location } from '@angular/common';

@Component({
	selector: 'app-geschaeftsfall-page',
	templateUrl: './geschaeftsfall-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallPageComponent extends BaseComponent {
	public readonly metadata = geschaeftsfallMetadata;
	public readonly geschaeftsfallStatus = GeschaeftsfallStatus;
	public readonly einreichungMetadata = einreichungMetadata;
	public readonly kycMetadata = einreichungspersonKycChecksMetadata;
	public readonly showKycChecksGfWhiteList = showKycChecksGfWhiteList;
	public readonly reloadRequested$ = new Subject<void>();
	public readonly pageContent$ = new BehaviorSubject<
		BackendQueryResult<GeschaeftsfallMitPositionen>
	>({
		isLoading: true,
	});
	public readonly isProcessing$ = new BehaviorSubject(false);

	public readonly navigatedTo$: Observable<string> = this.route.paramMap.pipe(
		map(params => params.get('id') as string)
	);

	public readonly userHasRoleAnteilsscheingeschaeft$ =
		this.currentUserService.currentUser$.pipe(
			map(user => user.roles.includes(BenutzerRolle.Anteilsscheingeschäft))
		);

	public readonly canDoAbschliessen$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (
				!currentUser.roles.some(u =>
					[BenutzerRolle.Standard, BenutzerRolle.Anteilsscheingeschäft].includes(u)
				)
			)
				return false;
			if (!queryResult.content) return false;

			switch (queryResult.content.geschaeftsfall.status) {
				case GeschaeftsfallStatus.ZumUebertrag:
					return true;
			}

			return false;
		})
	);

	public readonly canDoAbbrechen$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (
				!currentUser.roles.some(u =>
					[BenutzerRolle.Standard, BenutzerRolle.Anteilsscheingeschäft].includes(u)
				)
			)
				return false;

			if (!queryResult.content) return false;

			switch (queryResult.content.geschaeftsfall.status) {
				case GeschaeftsfallStatus.Neu:
				case GeschaeftsfallStatus.ZumUebertrag:
				case GeschaeftsfallStatus.ZumInkasso:
				case GeschaeftsfallStatus.InkassoAngefordert:
				case GeschaeftsfallStatus.ZurBuchung:
				case GeschaeftsfallStatus.BuchungBlockiert:
				case GeschaeftsfallStatus.ZumUebertragInsDepotBlockiert:
					return true;
			}

			return false;
		})
	);

	public readonly canDoStorno$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (!currentUser.roles.some(u => [BenutzerRolle.Standard].includes(u))) return false;

			if (!queryResult.content) return false;

			switch (queryResult.content.geschaeftsfall.status) {
				case GeschaeftsfallStatus.Abgeschlossen:
				case GeschaeftsfallStatus.NeuanlageImDepot:
				case GeschaeftsfallStatus.ZurAuszahlung:
					return true;
			}

			return false;
		})
	);

	public readonly canGenerateKundendokumente$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([queryResult, currentUser]) => {
			if (!currentUser.roles.some(u => [BenutzerRolle.Standard].includes(u))) return false;
			if (!queryResult.content) return false;

			if (
				![
					GeschaeftsfallStatus.Neu,
					GeschaeftsfallStatus.ZumInkasso,
					GeschaeftsfallStatus.InkassoAngefordert,
				].includes(queryResult.content.geschaeftsfall.status)
			) {
				return true;
			}

			return false;
		})
	);

	public readonly showInkassoBearbeitung$ = this.pageContent$.pipe(
		map(pc => {
			const status = pc.content?.geschaeftsfall?.status;
			return status === GeschaeftsfallStatus.InkassoAngefordert;
		}),
		startWith(false)
	);

	public readonly showInkassoDruckAnfordern$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([pc, user]) => {
			if (!pc.content) return false;
			if (!user.roles.includes(BenutzerRolle.Standard)) return false;
			return pc.content.geschaeftsfall.status === GeschaeftsfallStatus.ZumInkasso;
		}),
		startWith(false)
	);

	public readonly isGeneratingKundendokumente$ = new BehaviorSubject(false);

	public readonly canDoNeuabrechnen$ = combineLatest([
		this.pageContent$,
		this.currentUserService.currentUser$,
	]).pipe(
		map(([pc, user]) => {
			if (!pc.content) return false;
			if (!user.roles.includes(BenutzerRolle.Standard)) return false;
			switch (pc.content.geschaeftsfall.status) {
				case GeschaeftsfallStatus.StorniertZurBuchung:
				case GeschaeftsfallStatus.Storniert:
					return true;
				default:
					return false;
			}
		}),
		startWith(false)
	);
	personen: Einreichungsperson[] = [];
	areAllFieldsFilled: boolean = false;
	canAccept: boolean=false;

	readonly form = new TypedForm<KycChecksForm>({
		id: new UntypedFormControl(null),
		pepPruefungsnummer: createFormControl(this.kycMetadata.fields.pepPruefungsnummer),
		embargoPruefungsnummer: createFormControl(
			this.kycMetadata.fields.embargoPruefungsnummer
		),
		resultat: createFormControl(this.kycMetadata.fields.resultat),
		datum: createFormControl(this.kycMetadata.fields.datum),
		kommentar: createFormControl(this.kycMetadata.fields.kommentar),
		
	});

	constructor(
		private readonly geschaeftsfallService: GeschaeftsfallService,
		private readonly einreichungService: EinreichungService,
		private readonly route: ActivatedRoute,
		private readonly currentUserService: CurrentUserService,
		private readonly matDialog: MatDialog,
		private readonly alert: AlertService,
		private readonly saveService: SaveService,
		private readonly location: Location
	) {
		super();

		const navigatedTo$: Observable<string> = this.route.paramMap.pipe(
			map(params => params.get('id') as string)
		);

		const loadGeschaeftsfall$ = merge(
			navigatedTo$,
			this.reloadRequested$.pipe(
				withLatestFrom(navigatedTo$),
				map(([_, id]) => id)
			)
		);

		this.registerSubscription(
			loadGeschaeftsfall$
				.pipe(
					queryBackend(id =>
						this.geschaeftsfallService.getSingle$(id).pipe(
							switchMap(gf =>
								this.einreichungService
									.loadSingle$(gf.einreichungId)
									.pipe(map(e => ({ gf, e })))
							),
							map(({ gf, e }) => {
								var calculateResult = buildGeschaeftsfaelleMitPositionen([gf], e)
									.geschaeftsfaelle[0];
								if (!calculateResult)
									throw new Error('Konnte Geschäftsfall nicht ausrechnen!');
								this.personen = [...calculateResult.einreichung.personen];
								return calculateResult;
							})
						)
					)
				)
				.subscribe(content => {
					this.pageContent$.next(content);
					this.alert.success('Geschäftsfall geladen');
				})
		);
	}

	//Checks whether all fields are filled to enable the button to commit
	updateValidity() {
		const filledFields = Object.values(this.form.value).filter((field) => !!field);
		this.areAllFieldsFilled = filledFields.length == 5;
		if (this.areAllFieldsFilled) {
			this.canAccept=true;
		}else{
			this.canAccept=false;
		}
	}

	//Commits data to backend
	public accept(person:Einreichungsperson): void {
		if (this.form.valid) {
			this.form.disable();
			let kycCheck = this.form.value as KycChecks;
			const payload:KycPayload={
				pepPruefungsnummer: kycCheck.pepPruefungsnummer,
				embargoPruefungsnummer: kycCheck.embargoPruefungsnummer,
				resultat: kycCheck.resultat,
				datum: format(kycCheck.datum, 'yyyy-MM-dd'),
				kommentar: kycCheck.kommentar,
				tblEinreichungPerson: person.nummer
			}
			
			this.geschaeftsfallService
			.addKycCheck$(payload)
			.pipe(
				catchError(err => {
					this.alert.error('Hinzufügen der KYC-Prüfung fehlgeschlagen', err, payload);
					this.form.reset();
					return of({});
				})
			)
			.subscribe(() => {
				this.alert.success('Gespeichert');
				person.kycChecks.push(kycCheck);
				this.form.reset();
				this.form.enable();
				this.updateValidity();
				this.location.go(this.location.path());
			});	
		}
	}
	
	public abschliessen(): void {
		this.matDialog
			.open(GeschaeftsfallAbschliessenPopupComponent)
			.afterClosed()
			.pipe(
				filter(val => val && val.dialogResult === 'Ja'),
				withLatestFrom(this.navigatedTo$),
				switchMap(([val, id]) =>
					this.geschaeftsfallService.anteilsgeschaeftsabschluss$(id, {
						vorgangsnummer: val.vorgangsnummer,
					})
				)
			)
			.subscribe({
				next: () => {
					this.reloadRequested$.next();
				},
				error: err => {
					this.alert.error('Fehlgeschlagen', err);
					this.reloadRequested$.next();
				},
			});
	}

	public abbrechen(): void {
		this.matDialog
			.open(GeschaeftsfallAbbrechenPopupComponent, {
				data: {
					abbruchStornoGrund:
						this.pageContent$.value.content?.geschaeftsfall.abbruchStornoGrund,
				},
			})
			.afterClosed()
			.pipe(
				filter(val => val && val.dialogResult === 'Ja'),
				withLatestFrom(this.navigatedTo$),
				switchMap(([val, id]) =>
					this.geschaeftsfallService.abbrechen$(id, {
						abbruchStornoGrund: val.abbruchStornoGrund,
					})
				)
			)
			.subscribe({
				next: () => {
					this.reloadRequested$.next();
				},
				error: err => {
					this.alert.error('Fehlgeschlagen', err);
					this.reloadRequested$.next();
				},
			});
	}

	public inkassodruck(): void {
		this.isProcessing$.next(true);
		this.geschaeftsfallService
			.druckAnfordern$(this.pageContent$.value.content!.geschaeftsfall.id)
			.subscribe({
				next: () => {
					this.reloadRequested$.next();
					this.alert.success('Druck angefordert!');
					this.isProcessing$.next(false);
				},
				error: err => {
					logger.error(err);
					this.alert.error('Druckanforderung fehlgeschlagen!', err);
					this.isProcessing$.next(false);
				},
			});
	}

	public stornieren(): void {
		this.matDialog
			.open(GeschaeftsfallStornoPopupComponent, {
				data: {
					abbruchStornoGrund:
						this.pageContent$.value.content?.geschaeftsfall.abbruchStornoGrund,
				},
			})
			.afterClosed()
			.pipe(
				filter(val => val && val.dialogResult === 'Ja'),
				withLatestFrom(this.navigatedTo$),
				switchMap(([val, id]) => {
					const payload = {
						abbruchStornoGrund: val.abbruchStornoGrund,
						buchungenStornieren: !!val.buchungenStornieren,
					};
					return this.geschaeftsfallService.storno$(id, payload).pipe(
						catchError(err => {
							this.alert.error('Storno fehlgeschlagen', err, payload);
							return of({});
						})
					);
				})
			)
			.subscribe(() => this.reloadRequested$.next());
	}

	public kundendokumenteGenerieren(): void {
		if (this.isGeneratingKundendokumente$.value) return;
		this.isGeneratingKundendokumente$.next(true);

		const gf = this.pageContent$.value.content!.geschaeftsfall;
		this.geschaeftsfallService.kundendokumenteGenerieren$(gf.id).subscribe({
			next: kundendokumente => {
				this.saveService.saveAs(kundendokumente, `kundendokumente-${gf.nummer}.zip`);
				this.alert.success('Kundendokumente generiert!');
				this.isGeneratingKundendokumente$.next(false);
			},
			error: err => {
				logger.error(err);
				this.alert.error('Druckanforderung fehlgeschlagen!', err);
				this.isGeneratingKundendokumente$.next(false);
			},
		});
	}
}

type KycChecksForm = KycChecks;
