import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuickInfoPopupService } from '../../quick-info-popup.service';
import { Finanzinstitut, finanzinstitutMetadata } from '../model';

@Component({
	selector: 'app-finanzinstitut-list-page',
	templateUrl: './finanzinstitut-list-page.component.html',
	styleUrls: ['./finanzinstitut-list-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanzinstitutListPageComponent {
	public readonly metadata = finanzinstitutMetadata;

	constructor(private quickInfo: QuickInfoPopupService) {}

	openPopup(finanzinstitut: Finanzinstitut): void {
		this.quickInfo.showFinanzinstitut(finanzinstitut);
	}
}
