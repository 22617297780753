import { formatDate } from '@angular/common';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormGroup,
	ValidationErrors,
} from '@angular/forms';
import { format, parseISO } from 'date-fns';
import { firstValueFrom, Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { buffer, debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';
import { isNumberObject } from 'util/types';

export function isValid$(control: AbstractControl): Observable<boolean> {
	return control.statusChanges.pipe(
		startWith('INVALID'),
		map(s => s === 'VALID')
	);
}

export interface BackendQueryResult<T> {
	content?: T;
	isLoading: boolean;
	error?: string;
}

export function queryBackend<TSource, TResult>(
	operation: (src: TSource) => Observable<TResult>
): OperatorFunction<TSource, BackendQueryResult<TResult>> {
	return function (observable: Observable<TSource>): Observable<BackendQueryResult<TResult>> {
		const subject = new ReplaySubject<BackendQueryResult<TResult>>(1);
		subject.next({ isLoading: true });
		observable.pipe(switchMap(operation)).subscribe({
			error: err => {
				console.error(err);
				subject.next({ content: undefined, isLoading: false, error: err.message });
			},
			next: content => {
				subject.next({ content, isLoading: false });
			},
			complete: () => subject.complete(),
		});

		return subject.asObservable();
	};
}

/** Actually round(num, -5) */
export function round2(num: number): number {
	return (Math.round((num * 100) / INTERNAL_NUMBER_FACTOR) * INTERNAL_NUMBER_FACTOR) / 100;
}

/** returns first truthy value */
export function coalesce<T>(...params: (T | null | undefined)[]): T {
	for (const p of params) {
		if (p) return p;
	}

	return params[params.length - 1]!;
}

export function formatDateForSaveModel(d: Date | null | undefined): string | null {
	if (!d) return null;
	return format(d, 'yyyy-MM-dd');
}

export function formatDateForView(d: Date | null | undefined): string {
	if (!d) return '';
	return formatDate(d, 'dd.MM.yyyy', 'de');
}

export function noneToUndefined<T>(v: T | null | undefined): T | undefined {
	if (!v) return undefined;
	return v;
}

export function parseIncomingAsDate(d: Date): Date;
export function parseIncomingAsDate(d: Date | null | undefined): Date | null;
export function parseIncomingAsDate(d: Date | null | undefined): Date | null {
	if (!d) return null;
	const parsed = parseISO(d as unknown as string);
	const isDateInvalid = isNaN(parsed.getTime());
	if (isDateInvalid) {
		console.error(`Bad date`, d);
		return null;
	}
	return parsed;
}

export function allErrors(f: AbstractControl): ValidationErrorGroup | null {
	const childControls = (f as UntypedFormGroup | UntypedFormArray).controls;
	if (!childControls) {
		if (f.errors) {
			return {
				errors: f.errors,
				children: {},
			};
		} else {
			return null;
		}
	}

	const children: { [name: string]: ValidationErrorGroup } = {};
	let anyChildHasErrors = false;
	for (const entry of Object.entries(childControls)) {
		const childErrors = allErrors(entry[1]);
		if (childErrors) {
			anyChildHasErrors = true;
			children[entry[0]] = childErrors;
		}
	}

	if (f.errors || anyChildHasErrors) {
		return {
			errors: f.errors,
			children,
		};
	}

	return null;
}

export interface ValidationErrorGroup {
	errors: ValidationErrors | null;
	children: { [name: string]: ValidationErrorGroup };
}

/** necessary because https://github.com/angular/angular/issues/22556 */
export async function waitUntilDisabled(form: AbstractControl): Promise<void> {
	await firstValueFrom(
		form.statusChanges.pipe(
			startWith(form.status),
			filter(s => s === 'DISABLED')
		)
	);
}

export async function waitUntilEnabled(form: AbstractControl): Promise<void> {
	await firstValueFrom(
		form.statusChanges.pipe(
			startWith(form.status),
			filter(s => s !== 'DISABLED')
		)
	);
}

const EUR_DM_KURS = 1.95583;

/** Rechnet den vorgegeben Wert aus der vorgegebenen Währung in EUR um. Nur DEM und EUR erlaubt, sonst null. */
export function convertCurrency(
	value: number | null | undefined,
	currency: string | null | undefined
): number | null {
	
	if (value === null || value === undefined) {
		return null;
	}
	
	switch (currency) {
		default:
		case 'EUR':
			return Math.round(value);
		case 'DEM':
			return Math.round(value / EUR_DM_KURS);

	}
}

export function checkFilterFails(val: string, filterUpper: string): boolean {
	if (!filterUpper) return false;
	if (!val) return true;
	return !val.toLocaleUpperCase().includes(filterUpper);
}

export function fromEntries<T>(entries: Iterable<readonly [string, T]>): { [k: string]: T } {
	const result: { [k: PropertyKey]: T } = {};
	for (const r of entries) {
		result[r[0]] = r[1];
	}

	return result;
}

export function getArticle(
	gender: 'm' | 'w' | 'n',
	grammarCase: 'nominativ' | 'akkusativ'
): string {
	switch (gender + '-' + grammarCase) {
		case 'm-nominativ':
			return 'der';
		case 'm-akkusativ':
			return 'den';
		case 'w-nominativ':
		case 'w-akkusativ':
			return 'die';
		case 'n-akkusativ':
		case 'n-nominativ':
			return 'das';
		default:
			throw new Error();
	}
}

export function titleCase(t: string): string {
	if (!t) return '';
	return t[0].toLocaleUpperCase() + t.substring(1);
}

export function parseIncomingAsArray<T>(val: any, errorContext: string): T[] | null {
	if (!val) return null;
	if (typeof val !== 'string') return null;
	try {
		const parsedVal = JSON.parse(val);
		if (!Array.isArray(parsedVal)) {
			console.error(
				`JSON parsen von ${errorContext} fehlgeschlagen. Der Wert ist kein Array`,
				JSON.stringify(val)
			);
			return null;
		}

		return parsedVal;
	} catch (err) {
		console.error(
			`JSON parsen von ${errorContext} fehlgeschlagen: ${(err as Error)?.message}`,
			val
		);
		return null;
	}
}

export function extract<TTarget, TSource extends TTarget>(
	source: TSource,
	targetTemplate: TTarget
): TTarget {
	const ret: any = {};
	for (const key in targetTemplate) {
		ret[key] = source[key];
	}
	return ret;
}

type BufferDebounce = <T>(debounce: number) => OperatorFunction<T, T[]>;
export const bufferDebounce: BufferDebounce = debounce => source =>
	new Observable(observer =>
		source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
			next(x) {
				observer.next(x);
			},
			error(err) {
				observer.error(err);
			},
			complete() {
				observer.complete();
			},
		})
	);

type BufferDebounceCount = <T>(
	debounce: number
) => OperatorFunction<T, { item: T; count: number }>;
export const bufferDebounceCount: BufferDebounceCount = debounce => source =>
	new Observable(observer =>
		source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
			next(x) {
				const allSame = x.every(item => x[0] === item);
				if (allSame) {
					observer.next({ item: x[0], count: x.length });
				} else {
					observer.next({ item: x[x.length - 1], count: 1 });
				}
			},
			error(err) {
				observer.error(err);
			},
			complete() {
				observer.complete();
			},
		})
	);

export function statusCheck<D, T>(
	selector: (data: D) => T,
	stati: T[]
): (value: D) => boolean {
	const ret = (value: D) => stati.includes(selector(value));
	return ret;
}

export const INTERNAL_NUMBER_FACTOR = 1000000;

export function toInternalModel(n: number): number;
export function toInternalModel(n: null | undefined): null;
export function toInternalModel(n: number | null): number | null;
export function toInternalModel(n: number | null | undefined): number | null {
	if (typeof n === 'number' && !isNaN(n)) return Math.round(n * INTERNAL_NUMBER_FACTOR);
	return null;
}

export function toExternalModel(n: number): number;
export function toExternalModel(n: null | undefined): null;
export function toExternalModel(n: number | null): number | null;
export function toExternalModel(n: number | null | undefined): number | null {
	if (typeof n === 'number' && !isNaN(n)) return n / INTERNAL_NUMBER_FACTOR;
	return null;
}

/** Runden nach unten, 2 Nachkommastellen */
export function floor2(n: number): number {
	const factor = INTERNAL_NUMBER_FACTOR / 100;
	return factor * Math.floor(n / factor);
}
