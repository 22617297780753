import { MatPaginator } from '@angular/material/paginator';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
	private readonly subscriptions: Subscription[] = [];

	protected registerSubscription(subscription: Subscription) {
		this.subscriptions.push(subscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	protected i18n4Paginator(p: MatPaginator) {
		p._intl.itemsPerPageLabel = 'Zeilen pro Seite';
		p._intl.firstPageLabel = 'Erste Seite';
		p._intl.lastPageLabel = 'Letzte Seite';
		p._intl.nextPageLabel = 'Nächste Seite';
		p._intl.previousPageLabel = 'Vorherige Seite';
	}
}
