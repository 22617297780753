import { ChangeDetectionStrategy, Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription } from 'src/app/model';
import { coalesce } from 'src/app/utils';
import { TypedControl } from '../typed-form';

@Component({
	selector: 'app-date-input',
	templateUrl: './date-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInputComponent extends BaseComponent implements ControlValueAccessor {
	@Input() metadata?: FieldDescription;
	@Input() label = '';
	@Input() placeholder = '';
	@ViewChild('htmlControl') htmlControl?: ElementRef<HTMLInputElement>;

	get resolvedLabel(): string {
		return coalesce(this.label, this.metadata?.label, '');
	}

	get resolvedPlaceholder(): string {
		return coalesce(this.placeholder, this.metadata?.label, '');
	}

	public readonly dateControl = new TypedControl<Date | null>(null, { updateOn: 'blur' });
	public readonly isRequired$ = new BehaviorSubject(false);
	private onChange?: (val: Date | null) => void;
	private onTouchedCallback?: Function;

	constructor(
		@Optional() @Self() public readonly ngControl: NgControl // no idea why @Optional...
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl) throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (!this.ngControl.control) {
				console.error('no control on ngControl! Did you add formControlName or formControl?', this);
				throw new Error('no control on ngControl! Did you add formControlName or formControl?');
			}
			const hostControl = this.ngControl.control;
			this.reevaluateIsRequired();

			const onChange = this.onChange;
			if (!onChange) {
				throw new Error('Missing onChange!');
			}

			const htmlInputElement = this.htmlControl?.nativeElement;
			if (!htmlInputElement) throw new Error('Missing #htmlControl?!');

			this.registerSubscription(
				this.dateControl.value$.subscribe(dateValue => {
					if (dateValue === null && htmlInputElement.value) {
						this.dateControl.setErrors({ badDate: true });
						return;
					}

					this.reevaluateIsRequired();
					onChange(dateValue);
					const errorsFromParentValidators = hostControl.validator
						? hostControl.validator(hostControl)
						: null;
					this.dateControl.setErrors(errorsFromParentValidators);
				})
			);
		});
	}

	/** called when bound FormControl gets input */
	writeValue(val: Date | null | undefined): void {
		this.dateControl.reset(val);
	}

	registerOnChange(fn: (val: Date | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.dateControl.disable();
		} else {
			this.dateControl.enable();
		}
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	clear(): void {
		this.dateControl.reset();
	}

	reevaluateIsRequired(): void {
		const shouldBeRequired = !!this.ngControl.control?.hasValidator(Validators.required);
		if (this.isRequired$.value !== shouldBeRequired) {
			this.isRequired$.next(shouldBeRequired);
		}
	}
}
