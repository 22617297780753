import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { Einreichungsposition, einreichungspositionMetadata } from '../../model';
import {
  KuponnummernItem,
  SchnellerfassungSearchCriteria,
  SchnellerfassungService,
  SearchResultItem,
  SearchResultItemStatus,
} from './schnellerfassung.service';

@Component({
  selector: 'app-einreichung-schnellerfassung',
  templateUrl: './einreichung-schnellerfassung.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungSchnellerfassungComponent extends BaseComponent implements OnInit {
  public readonly positionMetadata = einreichungspositionMetadata;
  public readonly form = new TypedForm<SchnellerfassungSearchCriteria>({
    isinOrWkn: new UntypedFormControl(''),
    kuponnummern: new UntypedFormControl(''),
    kupons: new UntypedFormControl(false),
    mantel: new UntypedFormControl(false),
    anzahl: createFormControl(einreichungspositionMetadata.fields.anzahl, null),
    anteil: createFormControl(einreichungspositionMetadata.fields.anteil, null),
    stueckenummer: createFormControl(einreichungspositionMetadata.fields.stueckenummer, []),
  });

  public readonly cannotAccept$ = new BehaviorSubject<boolean>(true);
  public readonly cannotSearch$ = new BehaviorSubject<boolean>(true);

  public readonly isSearching$ = new BehaviorSubject(false);
  public readonly dataSource = new MatTableDataSource<SearchResultItem>();
  public readonly displayedColumns = [
    'wkzString',
    'gattungsbezeichnung',
    'kuponnummer',
    'zahlbarkeitstag',
    'status',
  ];

  public readonly errorMessage$ = new BehaviorSubject<string>('');

  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  constructor(
    private readonly ref: MatDialogRef<EinreichungSchnellerfassungComponent>,
    private service: SchnellerfassungService,
    public readonly quickInfo: QuickInfoPopupService,
    @Inject(MAT_DIALOG_DATA)
    private readonly sourceData: {
      positionen: Einreichungsposition[];
      schnellerfassungInfo: SchnellerfassungSearchCriteria | null;
      kistamProzentsatz: number;
    }
  ) {
    super();
    const kuponnummernControl = this.form.controls.kuponnummern;
    kuponnummernControl.addValidators(control => {
      try {
        parseKuponnummern(control.value);
        return null;
      } catch (err) {
        return { errorMessage: (err as Error)?.message };
      }
    });

    kuponnummernControl.disable();

    this.registerSubscription(
      this.form.controls.kupons.valueChanges.subscribe(val => {
        if (val) {
          kuponnummernControl.enable();
        } else {
          kuponnummernControl.disable();
        }
      })
    );

    if (sourceData.schnellerfassungInfo) {
      this.form.patchValue(sourceData.schnellerfassungInfo);
    }

    this.form.controls.isinOrWkn.addValidators(control => {
      const value = control.value as string | undefined | null;
      if (!value) return { required: true };
      if (value.length !== 6 && value.length !== 12)
        return { errorMessage: 'Muss entweder 6 (WKN) oder 12 (ISIN) Zeichen haben.' };
      return null;
    });

    this.registerSubscription(
      this.form.valueChanges.subscribe(() => {
        if (!this.form.invalid) {
          this.cannotSearch$.next(false);
        } else {
          this.cannotSearch$.next(true);
        }
      })
    );

    this.registerSubscription(
      this.dataSource.connect().subscribe(() => {
        this.cannotAccept$.next(this.dataSource.data.length === 0);
      })
    );
    
    this.registerSubscription(
      this.form.valueChanges.subscribe(() => {
        this.dataSource.data=[];
      })
    );
  }

  ngOnInit(): void {
    // This is intentional
  }

  accept(): void {
    const schnellerfassungInfo: SchnellerfassungSearchCriteria = this.form.value;
    const neuePositionen: Einreichungsposition[] = this.dataSource.data
      .filter(i => i.status === SearchResultItemStatus.NoPosition)
      .map(p => {
        const pos = p.position!;
        pos.anteil = schnellerfassungInfo.anteil;
        pos.anzahl = schnellerfassungInfo.anzahl;
        // pos.stueckenummer = schnellerfassungInfo.stueckenummer;
        return pos;
      });

    const result: SchnellerfassungResult = {
      neuePositionen,
      schnellerfassungInfo,
    };
    this.ref.close(result);
  }

  tryCancel(): void {
    this.ref.close();
  }

  search(): void {
    this.isSearching$.next(true);
    this.errorMessage$.next('');
    this.dataSource.data = [];
    setTimeout(() => {
      try {
        this.dataSource.data = this.service.search(
          this.form.typedValue,
          parseKuponnummern(this.form.typedValue.kuponnummern),
          this.sourceData.positionen,
          this.sourceData.kistamProzentsatz
        );
      } catch (err: any) {
        this.errorMessage$.next(err?.message ?? 'Fehler aufgetreten');
      }
      this.isSearching$.next(false);
    }, 50);
    this.cannotSearch$.next(true);
  }
}

export interface SchnellerfassungResult {
  schnellerfassungInfo: SchnellerfassungSearchCriteria;
  neuePositionen: Einreichungsposition[];
}

export function parseKuponnummern(data: string | undefined | null): KuponnummernItem[] {
  const result: KuponnummernItem[] = [];
  if (!data) return result;

  const sourceData = data
    .split(/[ ,;]/)
    .map(item => item.trim())
    .filter(item => !!item);

  for (const item of sourceData) {
    if (/^\d+$/.exec(item)) {
      const num = Number(item);
      if (isNaN(num)) throw new Error(`${item} konnte nicht interpretiert werden.`);
      result.push({ type: 'exact', number: num });
      continue;
    }

    let match = /^(\d+)-(\d+)$/.exec(item);
    if (match) {
      const from = Number(match[1]);
      const until = Number(match[2]);
      if (isNaN(from) || isNaN(until) || from > until)
        throw new Error(`${item} konnte nicht interpretiert werden.`);
      result.push({ type: 'range', from, until });
      continue;
    }

    match = /^(\d+)-HEUTE$/i.exec(item);
    if (match) {
      const from = Number(match[1]);
      if (isNaN(from)) throw new Error(`${item} konnte nicht interpretiert werden.`);
      result.push({ type: 'until-today', from });
      continue;
    }

    throw new Error(`${item} konnte nicht interpretiert werden.`);
  }

  return result;
}
