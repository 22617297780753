import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	Optional,
	Self,
	SimpleChanges,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription } from 'src/app/model';
import { TypedControl } from 'src/app/shared/forms/typed-form';
import { coalesce } from 'src/app/utils';
import { GattungService } from '../gattung.service';
import { Gattung } from '../model';
import { GattungChooseDialogComponent } from './gattung-choose-dialog/gattung-choose-dialog.component';

@Component({
	selector: 'app-gattung-choose',
	templateUrl: './gattung-choose.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungChooseComponent
	extends BaseComponent
	implements ControlValueAccessor, AfterViewInit, OnChanges
{
	@Input() metadata?: FieldDescription;
	@Input() label?: string;
	@Input() placeholder?: string;
	@Input() autocompleteEntries = 10;

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;

	public resolvedLabel: string = '';
	public resolvedPlaceholder: string = '';

	public readonly trackBy: TrackByFunction<Gattung> = (index, item) => item.id;
	public readonly textControl = new TypedControl<string>('');
	public readonly isRequired$ = new BehaviorSubject(false);

	private onChange?: (val: string | null) => void;
	private onTouchedCallback?: Function;

	constructor(
		private readonly service: GattungService,
		private readonly matDialog: MatDialog,
		@Optional() @Self() public readonly ngControl: NgControl // no idea why @Optional...
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl) throw new Error('No ngControl!');
		this.ngControl.valueAccessor = this;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.resolvedLabel = coalesce(this.label, this.metadata?.label, 'Gattung');
		this.resolvedPlaceholder = coalesce(
			this.placeholder,
			this.metadata?.label,
			'Wähle Gattung'
		);
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (!this.ngControl.control) {
				throw new Error('no control on ngControl!');
			}
			this.reevaluateIsRequired();

			const parentControl = this.ngControl.control;

			// this is used after internal validation is successful
			const evaluateParentValidators = () => {
				const errorsFromParentValidators = parentControl.validator
					? parentControl.validator(parentControl)
					: null;
				this.textControl.setErrors(errorsFromParentValidators);
			};

			this.registerSubscription(
				this.textControl.value$.pipe(debounceTime(400)).subscribe(textValue => {
					this.reevaluateIsRequired();

					if (!this.onChange) {
						throw new Error('Missing onChange!');
					}

					if (!textValue) {
						this.onChange(null);
						evaluateParentValidators();
						return;
					}

					const institut = this.getGattungFromTextControl();
					if (!institut) {
						// institut not found
						this.textControl.setErrors({ entryInvalid: true });
						this.onChange(null);
						return;
					}

					if (institut && institut.displayName !== textValue) {
						// set display name to formatted value and restart
						this.textControl.setValue(institut.displayName);
						return;
					}

					this.onChange(institut.id);
					evaluateParentValidators();
				})
			);
		});
	}

	/** called when bound FormControl gets input */
	writeValue(val: string | null | undefined): void {
		if (!val) {
			this.textControl.setValue(val);
		} else {
			const institut = this.service.list$.value.find(i => i.id === val);
			this.textControl.setValue(institut?.displayName, { emitEvent: false });
		}
	}

	registerOnChange(fn: (val: string | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.textControl.disable();
		} else {
			this.textControl.enable();
		}
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	public getGattungFromTextControl(): Gattung | undefined {
		const fieldValue = this.textControl.typedValue;
		if (!fieldValue) return undefined;

		const lowercaseValue = fieldValue.toLocaleLowerCase();
		return this.service.list$.value.find(v => v.displayNameLower === lowercaseValue);
	}

	public readonly filteredEntries$ = combineLatest([
		this.service.list$,
		this.textControl.value$,
	]).pipe(
		debounceTime(200),
		map(([list, filterValue]) => {
			if (filterValue) {
				filterValue = filterValue.toLocaleLowerCase();
				list = list.filter(e => e.displayNameLower.includes(filterValue));
			}

			list.sort((a, b) => {
				if (a.zahlbarkeitstag && b.zahlbarkeitstag) {
					return Math.sign(b.zahlbarkeitstag.getTime() - a.zahlbarkeitstag.getTime());
				}
				if (a.zahlbarkeitstag && !b.zahlbarkeitstag) return -1;
				if (!a.zahlbarkeitstag && b.zahlbarkeitstag) return 1;

				return 0;
			});

			return list.slice(0, this.autocompleteEntries);
		})
	);

	openDialog(): void {
		const ref = this.matDialog.open(GattungChooseDialogComponent, {
			height: '80vh',
			width: '80vw',
		});
		ref.afterOpened().subscribe(() => this.autocompleteTrigger!.closePanel());

		ref.afterClosed().subscribe((val: Gattung | undefined) => {
			if (val) {
				this.textControl.setValue(val.displayName);
			}
		});
	}

	clear(): void {
		this.textControl.reset();
	}

	reevaluateIsRequired(): void {
		const shouldBeRequired = !!this.ngControl.control?.hasValidator(Validators.required);
		if (this.isRequired$.value !== shouldBeRequired) {
			this.isRequired$.next(shouldBeRequired);
		}
	}
}
