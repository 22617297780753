import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Einreichung, Einreichungsperson, einreichungspersonMetadata } from '../../model';

@Component({
	selector: 'app-einreichung-view-personen',
	templateUrl: './einreichung-view-personen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungViewPersonenComponent implements OnInit {
	@Input() einreichung?: Einreichung;

	public readonly metadata = einreichungspersonMetadata;
	personen: Einreichungsperson[] = [];

	ngOnInit(): void {
		if (!this.einreichung) return;
		this.personen = [...this.einreichung.personen];
		this.personen.sort(personSorter);	
	}

	depotVorhandenLabel(person: Einreichungsperson) {
		let ret = person.ausweisart === 'S' ? 'Verstorben' : 'Deka-Depot vorhanden';
		return ret;
	}
}

function personSorter(p1: Einreichungsperson, p2: Einreichungsperson): number {
	let comparison = stringComparer(p => p.nachname, p1, p2);
	if (comparison !== 0) return comparison;

	comparison = stringComparer(p => p.vorname, p1, p2);
	if (comparison !== 0) return comparison;

	return 0;
}



function stringComparer(
	getter: (p: Einreichungsperson) => string,
	p1: Einreichungsperson,
	p2: Einreichungsperson
): number {
	const p1value = (getter(p1) || '').toLocaleLowerCase();
	const p2value = (getter(p2) || '').toLocaleLowerCase();
	return p1value.localeCompare(p2value);
}
