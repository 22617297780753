<h1 *ngIf="showHeader">{{ gattung.gattungsbezeichnung }}</h1>

<div class="flex-row">
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.emittentId">
			<button mat-flat-button color="accent" (click)="quickInfo.showFinanzinstitut(gattung.emittentId)">
				{{ gattung.emittentId | finanzinstitutDisplayName$ | async }}
			</button>
		</div>
	</div>
	<div class="data-column2"></div>
	<div class="data-column2"></div>
</div>

<div class="flex-row">
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.isin">{{ gattung.isin }}</div>
		<div app-data-item [field]="metadata.fields.wkn">{{ gattung.wkn }}</div>
		<div app-data-item [field]="metadata.fields.wkz">{{ gattung.wkz ? 'Mantel' : 'Kupon' }}</div>
		<div app-data-item [field]="metadata.fields.kuponnummerFaelligkeit">{{ gattung.kuponnummerFaelligkeit }}</div>
		<div app-data-item [field]="metadata.fields.nominalwaehrung">{{ gattung.nominalwaehrung }}</div>
		<div app-data-item [field]="metadata.fields.zahlbarkeitstag">
			{{ gattung.zahlbarkeitstag | date: 'dd.MM.yyyy' }}
		</div>
		<div app-data-item [field]="metadata.fields.istInkasso">{{ gattung.istInkasso | booleanText }}</div>
		<div app-data-item [field]="metadata.fields.istLiquidiert">
			{{ gattung.istLiquidiert | booleanText }}
		</div>
	</div>

	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.land">{{ gattung.land }}</div>
		<div app-data-item [field]="metadata.fields.bundesland">{{ gattung.bundesland }}</div>
		<div app-data-item [field]="metadata.fields.istThesaurierenderFonds">
			{{ gattung.istThesaurierenderFonds | booleanText }}
		</div>
		<div app-data-item [field]="metadata.fields.investmentsteuerart">{{ gattung.investmentsteuerart }}</div>
		<div app-data-item [field]="metadata.fields.istSteuerfreieGattung">
			{{ gattung.istSteuerfreieGattung | booleanText }}
		</div>
		<div app-data-item [field]="metadata.fields.kestVonEmittentenAbgefuehrt">
			{{ gattung.kestVonEmittentenAbgefuehrt | booleanText }}
		</div>
	</div>

	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.zuVersteuerndeVorabpauschale" label="Beträge Vorabpauschale">
			{{ gattung.zuVersteuerndeVorabpauschale | zuVersteuerndeVorabpauschale: 'betrag' }}
		</div>
		<div app-data-item [field]="metadata.fields.zuVersteuerndeVorabpauschale">
			{{ gattung.zuVersteuerndeVorabpauschale | zuVersteuerndeVorabpauschale }}
		</div>
		<div app-data-item [field]="metadata.fields.ausschuettungsdatum">
			{{ gattung.ausschuettungsdatum | gattungAusschuettungsdatum }}
		</div>
		<div app-data-item [field]="metadata.fields.dotationskonto">{{ gattung.dotationskonto }}</div>
		<div app-data-item [field]="metadata.fields.fusioniertMit">{{ gattung.fusioniertMit }}</div>
		<div app-data-item [field]="metadata.fields.fusioniertAb">{{ gattung.fusioniertAb | date: 'dd.MM.yyyy' }}</div>
		<div app-data-item [field]="metadata.fields.fusionUmrechnungsfaktor">
			{{ gattung.fusionUmrechnungsfaktor | efaNumber: 6 }}
		</div>
		<div app-data-item [field]="metadata.fields.umstellungAusgeschuettetAb">
			{{ gattung.umstellungAusgeschuettetAb | date: 'dd.MM.yyyy' }}
		</div>
	</div>
</div>

<hr />

<div class="flex-row">
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.bruttowert">
			{{ gattung.bruttowert | money: gattung.nominalwaehrung:metadata.fields.bruttowert }}
		</div>
		<div app-data-item [field]="metadata.fields.bruttowertEuro">
			{{ gattung.bruttowertEuro | money: 'EUR':metadata.fields.bruttowertEuro }}
		</div>
		<div app-data-item [field]="metadata.fields.steuerpflichtigerAnteil">
			{{ gattung.steuerpflichtigerAnteil | money: 'EUR':metadata.fields.steuerpflichtigerAnteil }}
		</div>
		<div app-data-item [field]="metadata.fields.zinswert">
			{{ gattung.zinswert | money: gattung.nominalwaehrung:metadata.fields.zinswert }}
		</div>
		<div app-data-item [field]="metadata.fields.dividendenwert">
			{{ gattung.dividendenwert | money: gattung.nominalwaehrung:metadata.fields.dividendenwert }}
		</div>
	</div>

	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.nettoZast">
			{{ gattung.nettoZast | money: gattung.nominalwaehrung:metadata.fields.nettoZast }}
		</div>
		<div app-data-item [field]="metadata.fields.akkumulierteErtraege">
			{{ gattung.akkumulierteErtraege | money: 'EUR':metadata.fields.akkumulierteErtraege }}
		</div>
		<div app-data-item [field]="metadata.fields.zwischengewinn">
			{{ gattung.zwischengewinn | money: 'EUR':metadata.fields.zwischengewinn }}
		</div>

		<div app-data-item [field]="metadata.fields.basisertrag">
			{{ gattung.basisertrag | money: gattung.nominalwaehrung:metadata.fields.basisertrag }}
		</div>
	</div>
</div>

<hr />

<div class="flex-row">
	<div class="data-column2" style="max-width: 50em">
		<div>
			<span>{{ metadata.fields.geaendertVon.label }}:</span>
			<span>{{ gattung.geaendertVon }}</span>
		</div>
		<div>
			<span>{{ metadata.fields.geaendertAm.label }}:</span>
			<span>{{ gattung.geaendertAm | date: 'dd.MM.yyyy' }}</span>
		</div>
	</div>
	<div class="data-column2" style="max-width: 50em">
		<div>
			<span>{{ metadata.fields.freigegebenVon.label }}:</span>
			<span>{{ gattung.freigegebenVon }}</span>
		</div>
		<div>
			<span>{{ metadata.fields.freigegebenAm.label }}:</span>
			<span>{{ gattung.freigegebenAm | date: 'dd.MM.yyyy' }}</span>
		</div>
	</div>
	<div class="data-column2">
		<div></div>
	</div>
	<div class="data-column2">
		<div></div>
	</div>
</div>
