import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Input,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { EinreichungKorrekturPopupComponent } from '../../einreichung-korrektur/einreichung-korrektur-popup/einreichung-korrektur-popup.component';
import { Einreichungsperson, einreichungspersonMetadata } from '../../model';
import { EinreichungEditPersonPopupComponent } from '../einreichung-edit-person-popup/einreichung-edit-person-popup.component';

@Component({
	selector: 'app-einreichung-edit-personen',
	templateUrl: './einreichung-edit-personen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EinreichungEditPersonenComponent),
			multi: true,
		},
	],
})
export class EinreichungEditPersonenComponent
	extends BaseComponent
	implements ControlValueAccessor
{
	@ViewChild(MatSort) sort?: MatSort;
	@Input() korrektur = false;

	public readonly metadata = einreichungspersonMetadata;
	public readonly dataSource = new MatTableDataSource<Einreichungsperson>();
	public readonly displayedColumns: (keyof Einreichungsperson | 'actions')[] = [
		'nachname',
		'istWirtschaftlichBerechtigter',
		'kycPruefungsnummer',
		'embargoPruefungsnummer',
		'actions',
	];

	public readonly trackBy: TrackByFunction<Einreichungsperson> = (index, item) => item.id;
	private onChange: (p: Einreichungsperson[]) => void = () => {};

	constructor(
		private readonly matDialog: MatDialog,
		private readonly simpleDialog: SimpleDialogService,
		private changeDetectorRef: ChangeDetectorRef // necessary to explicitly request table redraw
	) {
		super();
	}

	writeValue(val: Einreichungsperson[]): void {
		this.dataSource.data = val || [];
	}

	registerOnChange(fn: (p: Einreichungsperson[]) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		// intentionally left empty
	}

	ngAfterViewInit(): void {
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
		if (this.korrektur) {
			this.displayedColumns.pop();
		}
	}

	private setData(data: Einreichungsperson[]): void {
		this.dataSource.data = data;
		this.onChange(this.dataSource.data);
		this.changeDetectorRef.detectChanges();
	}

	open(person?: Einreichungsperson): void {
		let popupComponent: any;
		if (this.korrektur) {
			popupComponent = EinreichungKorrekturPopupComponent;
		} else {
			popupComponent = EinreichungEditPersonPopupComponent;
		}
		this.matDialog
			.open(popupComponent, {
				data:
					person ??
					({
						id: 'new-' + Math.random().toString(),
						land: 'DE',
						istEinreicher: true,
						istVerein: false,
						geburtsland: 'DE',
						staatsangehoerigkeit: 'DE',
						ausweisart: 'P',
					} as Einreichungsperson),
				disableClose: true,
				minWidth: '80vw',
			})
			.afterClosed()
			.pipe(take(1)) // https://github.com/angular/components/issues/13585
			.subscribe((newVersion?: Einreichungsperson) => {
				console.log('After closed', newVersion);
				if (!newVersion) return;

				this.setData([
					...this.dataSource.data.filter(p => p.id !== newVersion.id),
					newVersion,
				]);
			});
	}

	remove(personToRemove: Einreichungsperson): void {
		this.simpleDialog
			.jaNein('Wirklich die Person entfernen?', 'Person entfernen?')
			.subscribe(v => {
				if (v === 'Ja') {
					this.setData([...this.dataSource.data.filter(p => p.id !== personToRemove.id)]);
				}
			});
	}
}
