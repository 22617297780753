import { ChangeDetectionStrategy, Component, Input, OnChanges, Optional, Self, SimpleChanges } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldDescription, FixedListFieldDescription } from 'src/app/model';
import { InputBaseComponent } from '../input.base';
import { TypedControl } from '../typed-form';

@Component({
	selector: 'app-fixedlist-input',
	templateUrl: './fixedlist-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedlistInputComponent extends InputBaseComponent<string> implements OnChanges {
	@Input() metadata?: FieldDescription;
	@Input() label = '';
	@Input() placeholder = '';

	public readonly selectControl = new TypedControl<string>('');
	public readonly showClearButton$ = combineLatest([this.selectControl.value$, this.isDisabled$]).pipe(
		map(([value, isDisabled]) => !isDisabled && value)
	);

	public readonly options$ = new BehaviorSubject<{ value: any; label: string }[]>([]);

	constructor(
		@Optional() @Self() ngControl: NgControl // no idea why @Optional...
	) {
		super(ngControl);

		this.registerSubscription(
			this.isDisabled$.subscribe(isDisabled => {
				if (isDisabled) {
					this.selectControl.disable();
				} else {
					this.selectControl.enable();
				}
			})
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);
		const options = (this.metadata as FixedListFieldDescription)?.values;
		if (options) {
			this.options$.next(
				options.map(o => {
					if (typeof o === 'string') return { value: o, label: o };
					return o;
				})
			);
		} else {
			this.options$.next([]);
		}
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (!this.ngControl.control) {
				console.error('no control on ngControl! Did you add formControlName or formControl?', this);
				throw new Error('no control on ngControl! Did you add formControlName or formControl?');
			}
			this.reevaluateValidators();

			const hostControl = this.ngControl.control;

			const onChange = this.onChange;
			if (!onChange) {
				throw new Error('Missing onChange!');
			}

			this.registerSubscription(
				this.selectControl.value$.subscribe(textValue => {
					this.reevaluateValidators();
					onChange(textValue ?? '');
					const errorsFromParentValidators = hostControl.validator
						? hostControl.validator(hostControl)
						: null;
					this.selectControl.setErrors(errorsFromParentValidators);
				})
			);
		});
	}

	/** called when bound FormControl gets input */
	writeValue(val: string | null | undefined): void {
		this.selectControl.reset(val);
	}

	reevaluateValidators(): void {
		const control = this.ngControl.control!; // gesichert in afterViewInit;

		const shouldBeRequired = control.hasValidator(Validators.required);
		if (this.isRequired$.value !== shouldBeRequired) {
			this.isRequired$.next(shouldBeRequired);
		}
	}

	clear(): void {
		this.selectControl.reset();
	}
}
