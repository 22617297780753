import { EntityMetadata } from 'src/app/model';

export interface Buchung {
	buchungssatz: number;
	buchungsnummer: number;
	buchungsinhalt: string;
	kontonummer: string;
	primanotenNummer: string;
	betrag: number;
	buchungsdatum?: Date; // YYYY-MM-DD
	valutatag?: Date; // YYYY-MM-DD
	verarbeitungszeitpunkt?: Date; // timestamp
	status?: string;
	geschaeftsfallId?: string;
	geschaeftsfallNummer?: string;
	geschaeftsfallIsin?: string;
}

export const buchungMetadata: EntityMetadata<Buchung> = {
	apiCollectionName: 'buchung',
	label: 'Buchung',
	labelGender: 'w',
	plural: 'Buchungen',
	fields: {
		betrag: { label: 'Betrag', typ: 'number', isEfaNumber: true },
		buchungsdatum: { label: 'Buchungsdatum', typ: 'backend-datum' },
		buchungsinhalt: { label: 'Buchungsinhalt', typ: 'text', maxLength: 200 },
		buchungsnummer: { label: 'Buchungsnummer', typ: 'number', isEfaNumber: false },
		buchungssatz: { label: 'Buchungssatz', typ: 'number', isEfaNumber: false },
		geschaeftsfallId: { label: 'ID des Geschäftsfall', typ: 'backend-text' },
		geschaeftsfallNummer: { label: 'Nummer des Geschäftsfall', typ: 'backend-text' },
		geschaeftsfallIsin: { label: 'ISIN', typ: 'text', exactLength: 12 },
		kontonummer: { label: 'Kontonummer', typ: 'text', exactLength: 10 },
		primanotenNummer: { label: 'Primanotennummer', typ: 'text', maxLength: 45 },
		status: { label: 'Status', typ: 'backend-text' },
		verarbeitungszeitpunkt: { label: 'Verarbeitungszeitpunkt', typ: 'backend-datum' },
		valutatag: { label: 'Valutatag', typ: 'backend-datum' },
	},
	routing: {},
};
