<h1 mat-dialog-title>Referenzliste-Importe</h1>

<div mat-dialog-content>
	<div *ngIf="errorMessages.length > 0">
		<h2>Importe nicht möglich:</h2>
		<div *ngFor="let errorMessage of errorMessages">{{ errorMessage }}</div>
	</div>

	<div *ngIf="errorMessages.length === 0">
		<table
			mat-table
			[dataSource]="dataSource"
			matSort
			[trackBy]="trackBy"
			matSortActive="kuerzel"
			matSortDirection="asc">
			<ng-container matColumnDef="kuerzel">
				<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
					{{ kuerzelLabel }}
				</th>
				<td mat-cell *matCellDef="let element">{{ element.new.kuerzel }}</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Status</th>
				<td mat-cell *matCellDef="let element" class="text-hide-overflow">
					{{ element.status }}
				</td>
			</ng-container>

			<ng-container matColumnDef="bezeichnung-new">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ bezeichnungLabel }} NEU</th>
				<td
					mat-cell
					*matCellDef="let element"
					class="text-hide-overflow"
					[matTooltip]="element.new.bezeichnung">
					{{ element.new.bezeichnung }}
				</td>
			</ng-container>

			<ng-container matColumnDef="bezeichnung-old">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ bezeichnungLabel }} ALT</th>
				<td
					mat-cell
					*matCellDef="let element"
					class="text-hide-overflow"
					[matTooltip]="element.old?.bezeichnung">
					{{ element.old?.bezeichnung }}
				</td>
			</ng-container>

			<ng-container matColumnDef="extra-new">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ extraLabel }} NEU</th>
				<td mat-cell *matCellDef="let element" class="text-hide-overflow" [matTooltip]="element.new.extra">
					{{ element.new.extra }}
				</td>
			</ng-container>

			<ng-container matColumnDef="extra-old">
				<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ extraLabel }} ALT</th>
				<td mat-cell *matCellDef="let element" class="text-hide-overflow" [matTooltip]="element.old?.extra">
					{{ element.old?.extra }}
				</td>
			</ng-container>

			<ng-container *matHeaderRowDef="displayedColumns">
				<tr mat-header-row></tr>
			</ng-container>

			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<mat-paginator showFirstLastButtons></mat-paginator>
	</div>
</div>

<div mat-dialog-actions style="display: flex; justify-content: space-between">
	<div>
		<button
			mat-raised-button
			color="accent"
			mat-dialog-close
			[disabled]="isImporting$ | async"
			*ngIf="hasImportedSuccessfully$ | async | not">
			<mat-icon>close</mat-icon>
			Abbrechen
		</button>
	</div>
	<div *ngIf="importMessage$ | async as importMessage" style="font-weight: bold">{{ importMessage }}</div>
	<div>
		<button
			mat-raised-button
			color="primary"
			(click)="start()"
			*ngIf="!(isImporting$ | async) && (hasImportedSuccessfully$ | async | not)">
			<mat-icon>play_arrow</mat-icon>
			Starte Importe
		</button>
		<button mat-raised-button color="primary" (click)="stop()" *ngIf="isImporting$ | async">
			<mat-icon>stop</mat-icon>
			Stop
		</button>
		<button
			mat-raised-button
			color="primary"
			(click)="stop()"
			*ngIf="hasImportedSuccessfully$ | async"
			mat-dialog-close>
			<mat-icon>close</mat-icon>
			Schliessen
		</button>
	</div>
</div>
