<p *ngIf="dataFound$ | async | not">Noch keine Statuswechsel vorhanden.</p>

<mat-card>
	<table
		mat-table
		[dataSource]="dataSource"
		style="width: 100%"
		id="status-historie-table"
		matSort
		matSortActive="geaendertAm"
		matSortDirection="desc">
		<ng-container matColumnDef="einreichungsnummer">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Einreichungsnummer</th>
			<td mat-cell *matCellDef="let element">{{ element.einreichungsnummer }}</td>
		</ng-container>
		<ng-container matColumnDef="geschaeftsfallnummer">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Geschäftsfallnummer</th>
			<td mat-cell *matCellDef="let element">{{ element.geschaeftsfallnummer }}</td>
		</ng-container>
		<ng-container matColumnDef="statusVorher">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Status Vorher</th>
			<td mat-cell *matCellDef="let element">{{ element.statusVorher }}</td>
		</ng-container>
		<ng-container matColumnDef="statusNachher">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Status Nachher</th>
			<td mat-cell *matCellDef="let element">{{ element.statusNachher }}</td>
		</ng-container>
		<ng-container matColumnDef="geaendertAm">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Geändert Am</th>
			<td mat-cell *matCellDef="let element">{{ element.geaendertAm | date: 'dd.MM.yyyy HH:mm:ss' }}</td>
		</ng-container>
		<ng-container matColumnDef="geaendertVon">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Geändert Von</th>
			<td mat-cell *matCellDef="let element">{{ element.geaendertVon }}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div style="display: flex; justify-content: flex-end; margin-top: 2em">
		<button mat-raised-button color="accent" (click)="downloadAsExcel(dataSource.data)">
			<mat-icon>file_download</mat-icon>
			Als Excel herunterladen
		</button>
	</div>
</mat-card>
