import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EinreichungArchivComponent } from './bewegungsdaten/archiv/einreichung-archiv/einreichung-archiv.component';
import { BuchungenArchivComponent } from './bewegungsdaten/buchungen/buchungen-archiv/buchungen-archiv.component';
import { EinreichungKorrekturComponent } from './bewegungsdaten/einreichung-korrektur/einreichung-korrektur/einreichung-korrektur.component';
import { einreichungMetadata, geschaeftsfallMetadata } from './bewegungsdaten/model';
import { TagesverarbeitungUebersichtComponent } from './bewegungsdaten/tagesverarbeitung/tagesverarbeitung-uebersicht/tagesverarbeitung-uebersicht.component';
import { UltimoEinreichungenComponent } from './bewegungsdaten/ultimo/ultimo-einreichungen/ultimo-einreichungen.component';
import { UltimoKestComponent } from './bewegungsdaten/ultimo/ultimo-kest/ultimo-kest.component';
import { UltimoKistComponent } from './bewegungsdaten/ultimo/ultimo-kist/ultimo-kist.component';
import { UltimoKontenComponent } from './bewegungsdaten/ultimo/ultimo-konten/ultimo-konten.component';
import { UltimoOverviewComponent } from './bewegungsdaten/ultimo/ultimo-overview/ultimo-overview.component';
import { AssetsComponent } from './general/konfiguration/assets/assets.component';
import { KonfigurationOverviewComponent } from './general/konfiguration/konfiguration-overview/konfiguration-overview.component';
import { KonfigurationComponent } from './general/konfiguration/konfiguration/konfiguration.component';
import { StartPageComponent } from './general/start-page/start-page.component';
import { SystemInformationComponent } from './general/system-information/system-information.component';
import { generateRoutes } from './model';
import { SessionHandlingGuard } from './shared/route-guards/session-handling/session-handling.guard';
import { feiertagskalenderMetadata } from './stammdaten/feiertagskalender/model';
import { finanzinstitutMetadata } from './stammdaten/finanzinstitut/model';
import { GattungNeuAusschuettungenComponent } from './stammdaten/gattung/gattung-neu-ausschuettungen/gattung-neu-ausschuettungen.component';
import { gattungMetadata } from './stammdaten/gattung/model';
import { KuerzelBezeichnungListPageComponent } from './stammdaten/kuerzel-bezeichnung/kuerzel-bezeichnung-list-page/kuerzel-bezeichnung-list-page.component';
import { oppositionMetadata } from './stammdaten/opposition/model';
import { StammdatenUebersichtComponent } from './stammdaten/stammdaten-uebersicht/stammdaten-uebersicht.component';
import { UltimoGeschaeftsfaelleComponent } from './bewegungsdaten/ultimo/ultimo-geschaeftsfaelle/ultimo-geschaeftsfaelle.component';
import { UltimoRisikoklassenbewertungComponent } from './bewegungsdaten/ultimo/ultimo-risikoklassenbewertung/ultimo-risikoklassenbewertung.component';

const routes: Routes = [
	{
		path: '', canActivateChild: [SessionHandlingGuard], children: [
			{ path: '', component: StartPageComponent },
			{ path: 'stammdaten', component: StammdatenUebersichtComponent },
			{ path: 'einreichung/archiv', component: EinreichungArchivComponent },
			{ path: 'einreichung/:id/korrektur', component: EinreichungKorrekturComponent },
			{ path: 'buchungen', component: BuchungenArchivComponent },
			{ path: 'tagesverarbeitung', component: TagesverarbeitungUebersichtComponent },
			{ path: 'ultimo', component: UltimoOverviewComponent },
			{ path: 'ultimo/kest', component: UltimoKestComponent },
			{ path: 'ultimo/kist', component: UltimoKistComponent },
			{ path: 'ultimo/konten', component: UltimoKontenComponent },
			{ path: 'ultimo/einreichungen', component: UltimoEinreichungenComponent },
			{ path: 'ultimo/geschaeftsfaelle', component: UltimoGeschaeftsfaelleComponent },			
			{ path: 'ultimo/risikoklassen', component: UltimoRisikoklassenbewertungComponent },
			...generateRoutes(einreichungMetadata),
			...generateRoutes(geschaeftsfallMetadata),
			...generateRoutes(gattungMetadata),
			...generateRoutes(finanzinstitutMetadata),
			...generateRoutes(oppositionMetadata),
			...generateRoutes(feiertagskalenderMetadata),
			{
				path: 'stammdaten/kuerzelBezeichnung/:referenzlistName',
				component: KuerzelBezeichnungListPageComponent,
			},
			{ path: 'konfiguration', component: KonfigurationOverviewComponent },
			{ path: 'konfiguration/allgemein', component: KonfigurationComponent },
			{ path: 'konfiguration/dateien', component: AssetsComponent },
			{ path: 'sysinfo', component: SystemInformationComponent },
			{ path: 'fehlende-ausschuettungen', component: GattungNeuAusschuettungenComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
