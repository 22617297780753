import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs';
import { ConfigService } from '../config.service';
import { CurrentUserService } from '../current-user.service';

@Component({
	selector: 'app-system-information',
	templateUrl: './system-information.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemInformationComponent {
	constructor(public readonly config: ConfigService, private readonly currentUser: CurrentUserService) {}

	public readonly configInfo = JSON.stringify(this.config.config, null, '\t');
	public readonly currentUserInfo$ = this.currentUser.currentUser$.pipe(map(u => JSON.stringify(u, null, '\t')));
}
