import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenutzerService } from './benutzer.service';

@Pipe({
	name: 'benutzerName$',
})
export class BenutzerNamePipe implements PipeTransform {
	constructor(private service: BenutzerService) {}

	transform(benutzerId: string | null | undefined): Observable<string> {
		return this.service.list$.pipe(map(l => l.find(b => b.id === benutzerId)?.name ?? ''));
	}
}
