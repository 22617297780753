import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addDays } from 'date-fns';
import { debounceTime, map, Observable, shareReplay } from 'rxjs';
import { ConfigService } from 'src/app/general/config.service';
import {
	formatDateForSaveModel,
	parseIncomingAsArray,
	parseIncomingAsDate,
	toExternalModel,
	toInternalModel,
} from 'src/app/utils';
import { StammdatenEntityServiceBase } from '../entity.service.base';
import {
	berechneBerechneteGattungsFelder,
	DotationInfo,
	Gattung,
	GattungAktiveNutzungInfo,
	gattungMetadata,
	WKZ,
	ZuVersteuerndeVorabpauschale,
} from './model';

@Injectable({
	providedIn: 'root',
})
export class GattungService extends StammdatenEntityServiceBase<Gattung> {
	constructor(http: HttpClient, private readonly configService: ConfigService) {
		super(http, gattungMetadata);
	}

	readonly fehlendeNeuausschuettungen$ = this.list$.pipe(
		debounceTime(500),
		map(list => findFehlendeNeuausschuettungen(list)),
		shareReplay(1)
	);

	onLoaded = (g: Gattung) => {
		if (!g) return;

		g.geaendertAm = parseIncomingAsDate(g.geaendertAm);
		g.freigegebenAm = parseIncomingAsDate(g.freigegebenAm);
		g.zahlbarkeitstag = parseIncomingAsDate(g.zahlbarkeitstag);

		g.ausschuettungsdatum =
			parseIncomingAsArray<string>(g.ausschuettungsdatum, 'ausschuettungsdatum') || [];
		g.zuVersteuerndeVorabpauschale = (
			parseIncomingAsArray<ZuVersteuerndeVorabpauschale>(
				g.zuVersteuerndeVorabpauschale,
				'ZuVersteuerndeVorabpauschale'
			) || []
		).map(item => ({
			jahr: item.jahr,
			betragVorabp: toInternalModel(item.betragVorabp) || 0,
			betragStpflAnteil: toInternalModel(item.betragStpflAnteil) || 0,
		}));

		g.fusioniertAb = parseIncomingAsDate(g.fusioniertAb);
		g.umstellungAusgeschuettetAb = parseIncomingAsDate(g.umstellungAusgeschuettetAb);
		g.bruttowert = toInternalModel(g.bruttowert);
		g.steuerpflichtigerAnteil = toInternalModel(g.steuerpflichtigerAnteil);
		g.zinswert = toInternalModel(g.zinswert);
		g.dividendenwert = toInternalModel(g.dividendenwert);
		g.akkumulierteErtraege = toInternalModel(g.akkumulierteErtraege);
		g.zwischengewinn = toInternalModel(g.zwischengewinn);
		g.fusionUmrechnungsfaktor = toInternalModel(g.fusionUmrechnungsfaktor);
		g.stueckelungEinzelwert = toInternalModel(g.stueckelungEinzelwert);

		berechneBerechneteGattungsFelder(g);
	};

	onSaving = (g: Partial<Gattung>) => {
		g.zahlbarkeitstag = formatDateForSaveModel(g.zahlbarkeitstag) as any;
		g.fusioniertAb = formatDateForSaveModel(g.fusioniertAb) as any;

		if (g.ausschuettungsdatum)
			g.ausschuettungsdatum = JSON.stringify(g.ausschuettungsdatum) as any;
		if (g.zuVersteuerndeVorabpauschale) {
			const externalModel = g.zuVersteuerndeVorabpauschale.map(item => ({
				jahr: item.jahr,
				betragVorabp: toExternalModel(item.betragVorabp),
				betragStpflAnteil: toExternalModel(item.betragStpflAnteil),
			}));
			g.zuVersteuerndeVorabpauschale = JSON.stringify(externalModel) as any;
		}

		g.umstellungAusgeschuettetAb = formatDateForSaveModel(
			g.umstellungAusgeschuettetAb
		) as any;
		g.istThesaurierenderFonds = g.istThesaurierenderFonds ?? false;
		g.kestVonEmittentenAbgefuehrt = g.kestVonEmittentenAbgefuehrt ?? false;
		g.istInkasso = g.istInkasso ?? false;
		g.istSteuerfreieGattung = g.istSteuerfreieGattung ?? false;
		g.istLiquidiert = g.istLiquidiert ?? false;
		g.bruttowert = toExternalModel(g.bruttowert ?? 0);
		g.steuerpflichtigerAnteil = toExternalModel(g.steuerpflichtigerAnteil ?? 0);
		g.zinswert = toExternalModel(g.zinswert ?? 0);
		g.dividendenwert = toExternalModel(g.dividendenwert ?? 0);
		g.akkumulierteErtraege = toExternalModel(g.akkumulierteErtraege ?? 0);
		g.zwischengewinn = toExternalModel(g.zwischengewinn ?? 0);
		g.fusionUmrechnungsfaktor = toExternalModel(g.fusionUmrechnungsfaktor ?? 0);
		g.stueckelungEinzelwert = toExternalModel(g.stueckelungEinzelwert ?? 0);
		g.anzahlAnfang = g.anzahlAnfang ?? 0;

		delete g.id;
		delete g.freigegebenAm;
		delete g.freigegebenVon;
		delete g.displayName;
		delete g.displayNameLower;
		delete g.geaendertAm;
		delete g.geaendertVon;
		delete g.status;
		delete g.wertAnfang;
		delete g.bruttowertEuro;
		delete g.basisertrag;
		if (!g.land) g.land = null; // '' -> null
		if (!g.bundesland) g.bundesland = null; // '' -> null
	};

	dotationInfo$(gattungId: string): Observable<DotationInfo | null> {
		return this.http.get<DotationInfo | null>(`/gattung/${gattungId}/dotation`);
	}

	gattungAktiveNutzungInfo$(gattungId: string): Observable<GattungAktiveNutzungInfo | null> {
		return this.http.get<GattungAktiveNutzungInfo | null>(
			`/gattung/${gattungId}/inaktivierungpruefen`
		);
	}

	assembleOpenItemMessage(info: GattungAktiveNutzungInfo): string {
		const numberOfOpenGf = info.geschaeftsfallnummerList
			? info.geschaeftsfallnummerList.split(',').map(x => +x).length
			: 0;
		const numberOfOpenEr = info.einreichungList
			? info.einreichungList.split(',').map(x => +x).length
			: 0;
		const part1 = 'Die Gattung kann nicht geändert werden, weil sie ';
		const part2a =
			numberOfOpenGf === 1
				? `bei dem offener Geschäftsfall ${info.geschaeftsfallnummerList} `
				: '';
		const part2b =
			numberOfOpenGf > 1
				? `bei den offenen Geschäftsfällen ${info.geschaeftsfallnummerList} `
				: '';
		const part3 = info.geschaeftsfallnummerList && info.einreichungList ? 'und ' : '';
		const part4a =
			numberOfOpenEr === 1 ? `bei der offenen Einreichung ${info.einreichungList} ` : '';
		const part4b =
			numberOfOpenEr > 1 ? `bei den offenen Einreichungen ${info.einreichungList} ` : '';
		const part5 = 'verwendet wird.';
		return part1 + part2a + part2b + part3 + part4a + part4b + part5;
	}
}

export interface Neuausschuettung {
	gattung: Gattung;
	tag: Date;
	id: string;
}

export function findFehlendeNeuausschuettungen(gattungen: Gattung[]): Neuausschuettung[] {
	const missing: Neuausschuettung[] = [];
	const gattungMitAusschuettungen = gattungen.filter(
		g => g.wkz === WKZ.Mantel && g.ausschuettungsdatum && g.ausschuettungsdatum.length > 0
	);

	const dateRegex = /^(\d+)\.(\d+)$/;
	const now = new Date();
	const cutoffForInterestingCases = addDays(now, 10); // only consider coupons maturing in max 10 days
	for (const gattungMitAusschuettung of gattungMitAusschuettungen) {
		const existingKuponsForThisIsin = gattungen.filter(
			g =>
				g.wkz === WKZ.Kupon && g.isin === gattungMitAusschuettung.isin && g.zahlbarkeitstag
		);

		const existingZahlbarkeitstage = existingKuponsForThisIsin.map(k =>
			k.zahlbarkeitstag!.getTime()
		);

		for (const dateString of gattungMitAusschuettung.ausschuettungsdatum!) {
			const match = dateRegex.exec(dateString);
			if (!match) continue;

			const month = Number(match[2]);
			const day = Number(match[1]);

			const expectedDate = new Date(now.getFullYear(), month - 1, day);
			if (expectedDate > cutoffForInterestingCases) continue; // ignore

			// an Ausschüttung planned for e.g. 1.10 can happen between 10 days before that date or up to 30 days later
			// so if there is an Ausschüttung in that date range, consider it as equivalent
			const expectedDateRangeBegin = addDays(expectedDate, -10).getTime();
			const expectedDateRangeEnd = addDays(expectedDate, 30).getTime();

			const existingKupon = existingZahlbarkeitstage.find(
				z => z >= expectedDateRangeBegin && z <= expectedDateRangeEnd
			);

			if (!existingKupon) {
				missing.push({
					gattung: gattungMitAusschuettung,
					tag: expectedDate,
					id: gattungMitAusschuettung.id + expectedDate.getTime(),
				});
			}
		}
	}
	return missing;
}
