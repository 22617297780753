import { OppositionService } from './../opposition.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, map, Observable, take } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { NgForm } from '@angular/forms';
import { logger } from 'src/logger';
import { KistamUpdateResult } from 'src/app/bewegungsdaten/einreichung.service';

@Component({
	selector: 'app-opposition-fileimport-modal',
	templateUrl: './opposition-fileimport-modal.component.html',
	styleUrls: ['./opposition-fileimport-modal.component.scss'],

	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OppositionFileimportModalComponent extends BaseComponent implements OnInit {
	readonly parseError$ = new BehaviorSubject<string>('');
	readonly plausiErrors$ = new BehaviorSubject<string[]>([]);
	readonly file$ = new BehaviorSubject<File | null>(null);
	readonly resultMessages$ = new BehaviorSubject<string[]>([]);
	result4CsvImport: Array<{ nr: string; }> = [];
	readonly isFileSelected$ = combineLatest([
		this.file$,
		this.parseError$,
		this.plausiErrors$,
	]).pipe(
		map(([file, parseError, plausiErrors]) => {
			if (!file) return false;
			if (parseError) return false;
			if (plausiErrors.length > 0) return false;
			return true;
		})
	);

	constructor(
		private ref: MatDialogRef<OppositionFileimportModalComponent>,
		public oppositionService: OppositionService
	) {
		super();
	}

	public get text4CancelBtn(): string {
		let ret = 'Abbrechen';
		if (this.oppositionService.isImportDone$.value === true) {
			ret = 'Ok';
		}
		return ret;
	}

	public get showImportBtn(): boolean {
		return this.text4CancelBtn === 'Abbrechen';
	}

	hasErrorImportResults$(): Observable<boolean> {
		const ret = this.oppositionService.result4CsvImport$.pipe(
			map(x => {
				const errors = x.filter(y => y.success === KistamUpdateResult.SUCCESS_saveFailed);
				return errors.length > 0;
			})
		);
		return ret;
	}

	onCancel() {
		this.ref.close();
	}

	onSubmit(form: NgForm) {
		logger.log('Submit file!');
		this.resultMessages$.next(["wird hochgeladen..."]);
		this.oppositionService.importCsv(this.file$.value as File, this);
	}

	ngOnInit() {
		this.registerSubscription(
			this.ref.afterClosed().subscribe(() => {
				logger.log('Opposition Import-Dialog wird geschlossen.');
				this.clearDialogState();
			})
		);
	}

	getImportResultCssClass(success: string = 'success') {
		if (success === 'success') return 'importResultSuccess';
		return 'importResultError';
	}

	onFileSelected(event: any) {
		logger.log('Enter onFileSelected: ', event);

		this.clearDialogState();
		debugger;
		const selectedFile = (event.target as HTMLInputElement).files?.item(0);
		const isCorrectFileSelected = selectedFile?.name.endsWith('csv');

		if (isCorrectFileSelected) {
			this.file$.next(selectedFile as File);
			this.oppositionService
				.parseCsv$(selectedFile as File)
				.pipe(take(1))
				.subscribe(parsedData => {
					logger.log(parsedData, 'parsed Data');
					if (
						!Array.isArray(parsedData) ||
						(parsedData.length &&
							Array.isArray(parsedData[0]) &&
							parsedData[0].length == 1)
					) {
						logger.error(parsedData);
						this.parseError$.next('Datei wurde nicht als CSV erkannt!');
						return;
					}
					this.plausiErrors$.next(
						this.oppositionService.plausibiltyCheckOfCsvData(parsedData)
					);
				});
		} else {
			this.parseError$.next('Keine CSV-Datei ausgewählt!');
		}
	}

	onFilechooserOpened(event: any) {
		this.clearDialogState();
	}

	public clearDialogState(): void {
		this.parseError$.next('');
		this.plausiErrors$.next([]);
		this.oppositionService.result4CsvImport$.next([]);
		this.file$.next(null);
		this.oppositionService.isImportDone$.next(false);
	}
}
