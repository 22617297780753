import { format } from 'date-fns';
import {
	Einreichung,
	Einreichungsposition,
	Einreichungspositionssteuer,
	GeschaeftsfallMitPositionen,
	Kistam_EV,
	Kistam_RK,
	Religionszugehoerigkeit,
	Steuerart,
} from '../bewegungsdaten/model';
import { Finanzinstitut } from '../stammdaten/finanzinstitut/model';
import { Gattung } from '../stammdaten/gattung/model';
import { floor2, INTERNAL_NUMBER_FACTOR, round2 } from '../utils';

// KEST
const KEST_SATZ = 0.25;
const KEST_SATZ_KIST = 0.2445;
const KEST_SATZ_KIST_BYBW = 0.2451;
// SOLI
const SOLI_SATZ = 0.055;
const SOLI_SATZ_75 = 0.075;
// KIST
const KIST_SATZ = 0.09;
const KIST_SATZ_BYBW = 0.08;
const ZAST_SATZ = 0.35;
// QUEST
export const QUEST_ZANT_SATZ = 0.1;
export const QUEST_KEST20_SATZ = 0.2;
export const QUEST_KEST25_SATZ = 0.25;

export function calculateSteuern(
	{ geschaeftsfall, positionen, einreichung }: GeschaeftsfallMitPositionen,
	gattungen: Gattung[],
	finanzinstitute: Finanzinstitut[]
): void {
	positionen.forEach(p => calculatePositionsteuern(p, einreichung, gattungen, finanzinstitute));
	// EFA-99: Steuern auf Zinsgutschriften werden der ersten dem Geschäftsfall zugeordneten Einreichungsposition zugewiesen
	if (!einreichung.einreicher && geschaeftsfall.cdcZinsgutschrift > 0) {
		calculateSteuerAufZinsgutschrift(positionen[0], einreichung, gattungen, geschaeftsfall.cdcZinsgutschrift);
		positionen[0].steuern.forEach(steuer => {
			switch (steuer.steuerart) {
				case Steuerart.Soli:
					steuer.steuerbetrag = floor2(steuer.steuerbetrag);
					break;
				default:
					steuer.steuerbetrag = round2(steuer.steuerbetrag);
					break;
			}
			steuer.steuergrundbetrag = round2(steuer.steuergrundbetrag);
		});
	}
}

/*
EFA-99 Zinserträge aus Mänteln werden separat steuerlich behandelt und resultieren in separaten Berechnungen
(incl. Solidaritätszuschlag und Kirchensteuer)
Steuern auf Zinsgutschriften werden der ersten dem Geschäftsfall zugeordneten Einreichungsposition 	zugewiesen.
Berechnungsbasis:Hierzu wird der auf dem Geschäftsfall im Rahmen der Inkassobehandlung gepflegte Wert in CDC Zinsgutschrift herangezogen
*/
export function calculateSteuerAufZinsgutschrift(
	position: Einreichungsposition,
	einreichung: Einreichung,
	gattungen: Gattung[],
	zinsgutschrift: number
): void {
	const gattung = gattungen.find(g => g.id === position.gattungId);
	if (!gattung) return;
	const zuflussAndKistamEVorRK = einreichung.kistam ? [Kistam_EV, Kistam_RK].includes(einreichung.kistam) : false;
	const steuergrundbetrag = zinsgutschrift;

	const steuersatz = getKestSteuersatz(einreichung, zuflussAndKistamEVorRK);
	const kest = steuergrundbetrag * steuersatz;

	position.steuern.push({
		steuerart: Steuerart.KESt,
		steuergrundbetrag,
		steuersatz,
		steuerbetrag: Math.round(kest),
		steuername: 'KESt auf Zinsgutschrift',
	});

	if (einreichung.kistam && !zuflussAndKistamEVorRK && (einreichung.kistamProzentsatz || 0) > 0) {
		position.steuern.push({
			steuerart: Steuerart.KiSt,
			steuergrundbetrag: Math.round(kest),
			steuersatz: einreichung.kistamProzentsatz ?? 0,
			steuerbetrag: Math.round(kest * (einreichung.kistamProzentsatz ?? 0)),
			steuername: 'KiSt auf KESt Zinsgutschrift',
		});
	}

	position.steuern.push({
		steuerart: Steuerart.Soli,
		steuergrundbetrag: Math.round(kest),
		steuersatz: SOLI_SATZ,
		steuerbetrag: Math.round(kest * SOLI_SATZ),
		steuername: 'Soli auf KESt Zinsgutschrift',
	});
}

export function calculatePositionsteuern(
	position: Einreichungsposition,
	einreichung: Einreichung,
	gattungen: Gattung[],
	finanzinstitute: Finanzinstitut[]
) {
	position.steuern = [];
	const gattung = gattungen.find(g => g.id === position.gattungId);
	if (!gattung) return;

	//kest/kist/soli nur auf private einreichung
	if (!einreichung.einreicher) {
		// dt.Kupons
		steuer98(position, einreichung, gattung, finanzinstitute);
		// liq. Mäntel
		steuer99(position, einreichung, gattung, finanzinstitute);
	}
	// Quest
	steuer101(position, einreichung, gattung, finanzinstitute);

	position.steuern.forEach(steuer => {
		switch (steuer.steuerart) {
			case Steuerart.Soli:
				steuer.steuerbetrag = floor2(steuer.steuerbetrag);
				break;
			default:
				steuer.steuerbetrag = round2(steuer.steuerbetrag);
				break;
		}
		steuer.steuergrundbetrag = round2(steuer.steuergrundbetrag);
	});

	if (!einreichung.einreicher) {
		position.steuern.push(
			...determineVorabpauschaleStatus(
				gattung,
				einreichung.kistamProzentsatz ?? 0,
				(position.anteil * position.anzahl) / INTERNAL_NUMBER_FACTOR,
				einreichung.kistam || ''
			)
		);
	}
}

const currentYear = new Date().getFullYear();

/*
EFA-16 Ermöglicht die Berechnung der Vorabpauschale - Steuern, basierend auf den eingereichten Papieren (nur für Mäntel)
Steuerermittlung analog Kupons, da der Zeitraum nach 2018 steuerlich immer gleich behandelt wird.
*/
export function determineVorabpauschaleStatus(
	gattung?: Gattung,
	kistamProzentsatz?: number,
	positionFaktor?: number,
	kistam?: string
): Einreichungspositionssteuer[] {
	if (!gattung || !gattung.zuVersteuerndeVorabpauschale) return [];
	const zuflussAndKistamEVorRK = kistam ? [Kistam_EV, Kistam_RK].includes(kistam) : false;
	positionFaktor ??= 1;
	kistamProzentsatz ??= 0;
	const vorabpauschaleBasis =
		gattung.zuVersteuerndeVorabpauschale
			.filter(p => p.jahr <= currentYear)
			.reduce((pv, cv) => pv + cv.betragStpflAnteil, 0) * positionFaktor;

	if (vorabpauschaleBasis === 0) return [];

	const result: Einreichungspositionssteuer[] = [];
	const kestSatz = getKestSteuersatz(kistamProzentsatz, zuflussAndKistamEVorRK);
	const kest = round2(kestSatz * vorabpauschaleBasis);

	result.push({
		steuerart: Steuerart.VorabpauschaleKESt,
		steuergrundbetrag: vorabpauschaleBasis,
		steuersatz: kestSatz,
		steuerbetrag: kest,
		steuername: 'Vorabpauschale KESt',
	});

	result.push({
		steuerart: Steuerart.VorabpauschaleSoli,
		steuergrundbetrag: kest,
		steuersatz: SOLI_SATZ,
		steuerbetrag: floor2(kest * SOLI_SATZ),
		steuername: 'Vorabpauschale Soli',
	});

	if (kistamProzentsatz && !zuflussAndKistamEVorRK) {
		result.push({
			steuerart: Steuerart.VorabpauschaleKiSt,
			steuergrundbetrag: kest,
			steuersatz: kistamProzentsatz,
			steuerbetrag: round2(kest * kistamProzentsatz),
			steuername: 'Vorabpauschale KiSt',
		});
	}

	return result;
}

/*
EFA-98: (dt.) kupons
Ermöglicht es, bei Kupons (u.a. deutsche Ertragsscheine) pro Einreichungsposition
die Steuern nach dem Zuflussprinzip (Stand 2022) zu ermitteln.
*/
export function steuer98(
	position: Einreichungsposition,
	einreichung: Einreichung,
	gattung: Gattung,
	finanzinstitute: Finanzinstitut[] //TODO: Kann das weg?
): void {
	if (gattung.wkz) return; //nur Kupons
	if (!gattung.steuerpflichtigerAnteil) return;
	const zuflussAndKistamEVorRK = einreichung.kistam ? [Kistam_EV, Kistam_RK].includes(einreichung.kistam) : false;

	const nominal = Math.floor(
		(position.anteil * position.anzahl * position.faktor) / INTERNAL_NUMBER_FACTOR / INTERNAL_NUMBER_FACTOR
	);
	const steuergrundbetrag = gattung.steuerpflichtigerAnteil * nominal;

	if (steuergrundbetrag <= 0) return;

	const steuersatz = getKestSteuersatz(einreichung, zuflussAndKistamEVorRK);
	const kest = steuergrundbetrag * steuersatz;

	// 1.1 Kest Kupons
	position.steuern.push({
		steuerart: Steuerart.KESt,
		steuergrundbetrag,
		steuersatz,
		steuerbetrag: Math.round(kest),
		steuername: 'KESt auf Kupons',
	});

	// 1.2 Kist Kupons
	// für kistam = Kistam_EV und Kistam_RK kist berechnung nur nach Fälligkeitsprinzip (EFA-2653)
	if (einreichung.kistam && !zuflussAndKistamEVorRK && (einreichung.kistamProzentsatz || 0) > 0) {
		position.steuern.push({
			steuerart: Steuerart.KiSt,
			steuergrundbetrag: Math.round(kest),
			steuersatz: einreichung.kistamProzentsatz ?? 0,
			steuerbetrag: Math.round(kest * (einreichung.kistamProzentsatz ?? 0)),
			steuername: 'KiSt auf KESt Kupons',
		});
	}

	// 1.3 Soli Kupons
	position.steuern.push({
		steuerart: Steuerart.Soli,
		steuergrundbetrag: Math.round(kest),
		steuersatz: SOLI_SATZ,
		steuerbetrag: floor2(kest * SOLI_SATZ),
		steuername: 'Soli auf KESt Kupons',
	});
}

/*
EFA-99 liq. Mäntel
Ermöglicht bei zur Auszahlung zu bringenden Mänteln (liquidiert)
pro Einreichungsposition eine Steuerberechnung nach dem Fälligkeits-Prinzip
*/
export function steuer99(
	position: Einreichungsposition,
	einreichung: Einreichung,
	gattung: Gattung,
	finanzinstitute: Finanzinstitut[]
): void {
	let steuergrundbetragKistSoli: number = 0;
	if (!gattung.wkz) return; //nur Mäntel
	if (!gattung.steuerpflichtigerAnteil) return;
	if (!gattung.istLiquidiert) return;
	if (!gattung.zahlbarkeitstag) return;
	if (format(gattung.zahlbarkeitstag, 'yyyy-MM-dd') < '1993-01-01') return;

	const zuflussAndKistamEVorRK = einreichung.kistam ? [Kistam_EV, Kistam_RK].includes(einreichung.kistam) : false;

	const nominal = Math.floor(
		(position.anteil * position.anzahl * position.faktor) / INTERNAL_NUMBER_FACTOR / INTERNAL_NUMBER_FACTOR
	);
	const steuergrundbetrag = gattung.steuerpflichtigerAnteil * nominal;

	if (steuergrundbetrag <= 0) return;

	const zahlbarkeitstagString = format(gattung.zahlbarkeitstag, 'yyyy-MM-dd');
	const steuersatz = getKestSteuersatz(einreichung);
	const steuerbetrag = steuergrundbetrag * steuersatz;

	// 2.1. Zast / Kest liq.Mäntel
	// 2.1.1: Zast (1993 - 2008)
	if (zahlbarkeitstagString >= '1993-01-01' && zahlbarkeitstagString <= '2008-12-31') {
		const steuersatzZast = ZAST_SATZ;
		const steuerbetragZast = steuergrundbetrag * steuersatzZast;
		steuergrundbetragKistSoli = steuerbetragZast;
		position.steuern.push({
			steuerart: Steuerart.ZASt,
			steuergrundbetrag,
			steuersatz: steuersatzZast,
			steuerbetrag: Math.round(steuerbetragZast),
			steuername: 'ZASt auf liq. Mäntel',
		});
	}

	const hatReligionszugehoerigkeit =
		einreichung.religionszugehoerigkeit === Religionszugehoerigkeit.Evangelisch ||
		einreichung.religionszugehoerigkeit === Religionszugehoerigkeit.Katholisch;

	// 2.1.2 Kest mit spez. Kistam Handling (2009 - 2014)
	if (zahlbarkeitstagString >= '2009-01-01' && zahlbarkeitstagString <= '2014-12-31') {
		if (hatReligionszugehoerigkeit) {
			steuergrundbetragKistSoli = steuerbetrag;
			position.steuern.push({
				steuerart: Steuerart.KESt,
				steuergrundbetrag,
				steuersatz,
				steuerbetrag: Math.round(steuerbetrag),
				steuername: 'KESt auf liq. Mäntel',
			});
		} else {
			//ohne die spez. Kistam-Werte RK/EV wird keine KiSt erhoben und somit KESt 25% abgeführt
			const steuersatzKest = KEST_SATZ;
			const steuerbetragKest = steuergrundbetrag * steuersatzKest;
			steuergrundbetragKistSoli = steuerbetragKest;
			position.steuern.push({
				steuerart: Steuerart.KESt,
				steuergrundbetrag,
				steuersatz: steuersatzKest,
				steuerbetrag: Math.round(steuerbetragKest),
				steuername: 'KESt auf liq. Mäntel',
			});
		}
	}

	// 2.1.3 Standard KESt (ab 2015)
	if (zahlbarkeitstagString >= '2015-01-01') {
		// bei den spez. Kistam-Werte RK/EV wird keine KiSt erhoben und somit KESt 25% abgeführt
		if (zuflussAndKistamEVorRK) {
			const steuersatzKest = KEST_SATZ;
			const steuerbetragKest = steuergrundbetrag * steuersatzKest;
			steuergrundbetragKistSoli = steuerbetragKest;
			position.steuern.push({
				steuerart: Steuerart.KESt,
				steuergrundbetrag,
				steuersatz: steuersatzKest,
				steuerbetrag: Math.round(steuerbetragKest),
				steuername: 'KESt auf liq. Mäntel',
			});
		} else {
			steuergrundbetragKistSoli = steuerbetrag;
			position.steuern.push({
				steuerart: Steuerart.KESt,
				steuergrundbetrag,
				steuersatz,
				steuerbetrag: Math.round(steuerbetrag),
				steuername: 'KESt auf liq. Mäntel',
			});
		}
	}

	// 2.2 Kist
	// 2.2.1 keine Kist (1993 - 2008)
	// 2.2.2 spez. Kistam Handling (2009 - 2014 EFA-1590)
	if (hatReligionszugehoerigkeit && zahlbarkeitstagString >= '2009-01-01' && zahlbarkeitstagString <= '2014-12-31') {
		position.steuern.push({
			steuerart: Steuerart.KiSt,
			steuergrundbetrag: Math.round(steuergrundbetragKistSoli),
			steuersatz: einreichung.kistamProzentsatz ?? 0,
			steuerbetrag: Math.round(steuergrundbetragKistSoli * (einreichung.kistamProzentsatz ?? 0)),
			steuername: 'KiSt auf KESt liq. Mäntel',
		});
	}
	// 2.2.3 Standard Kistam (2015 - )
	if (
		einreichung.kistam &&
		!zuflussAndKistamEVorRK &&
		(einreichung.kistamProzentsatz || 0) > 0 &&
		zahlbarkeitstagString >= '2015-01-01'
	) {
		position.steuern.push({
			steuerart: Steuerart.KiSt,
			steuergrundbetrag: Math.round(steuergrundbetragKistSoli),
			steuersatz: einreichung.kistamProzentsatz ?? 0,
			steuerbetrag: Math.round(steuergrundbetragKistSoli * (einreichung.kistamProzentsatz ?? 0)),
			steuername: 'KiSt auf KESt liq. Mäntel',
		});
	}

	// 2.3 Soli
	// 2.3.1 Soli 7.5% (07/1991 - 06/1992 und 1995 - 1997)
	if (
		(zahlbarkeitstagString >= '1991-07-01' && zahlbarkeitstagString <= '1992-06-30') ||
		(zahlbarkeitstagString >= '1995-01-01' && zahlbarkeitstagString <= '1997-12-31')
	) {
		position.steuern.push({
			steuerart: Steuerart.Soli,
			steuergrundbetrag: Math.round(steuergrundbetragKistSoli),
			steuersatz: SOLI_SATZ_75,
			steuerbetrag: floor2(steuergrundbetragKistSoli * SOLI_SATZ_75),
			steuername: 'Soli auf KESt liq. Mäntel',
		});
	}
	// 2.3.2 Soli 5.5% (1998 - )
	if (zahlbarkeitstagString >= '1998-01-01') {
		position.steuern.push({
			steuerart: Steuerart.Soli,
			steuergrundbetrag: Math.round(steuergrundbetragKistSoli),
			steuersatz: SOLI_SATZ,
			steuerbetrag: Math.round(steuergrundbetragKistSoli * SOLI_SATZ),
			steuername: 'Soli auf KESt liq. Mäntel',
		});
	}
}

/*
	EFA-101
	Ermöglicht es, bereits abgeführte Steuern (für Kupons) anhand der Fälligkeit der jeweiligen Einreichungsposition
	zu erkennen, um eine doppelte Versteuerung zu verhindern oder mögliche Steuerrückzahlungen vorzunehmen.
*/
export function steuer101(
	position: Einreichungsposition,
	einreichung: Einreichung,
	gattung: Gattung,
	finanzinstitute: Finanzinstitut[]
): void {
	if (gattung.wkz) return; //nur Kupons
	if (!gattung.zahlbarkeitstag) return;
	if (!gattung.zinswert && !gattung.dividendenwert) return;

	const zahlbarkeitstagString = format(gattung.zahlbarkeitstag, 'yyyy-MM-dd');

	// 3.1 Quest 10% auf Zinsanteil / kein Soli (01.01.1989 - 30.06.1989)
	if (zahlbarkeitstagString >= '1989-01-01' && zahlbarkeitstagString <= '1989-06-30' && gattung.zinswert) {
		const nominal = Math.floor(
			(position.anteil * position.anzahl * position.faktor) / INTERNAL_NUMBER_FACTOR / INTERNAL_NUMBER_FACTOR
		);
		const zinsanteil = gattung.zinswert * nominal;

		if (zinsanteil <= 0) return;

		const quest = zinsanteil * QUEST_ZANT_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuerKESt,
			steuergrundbetrag: zinsanteil,
			steuersatz: QUEST_ZANT_SATZ,
			steuerbetrag: Math.round(quest),
			steuername: 'Ausgleich QueSt (01.01.1989 - 30.06.1989)',
		});
	}

	// ab 01.04.1999: berechnugsgrundlage quest dividendenanteil
	let dividendenanteil: number | null = null;
	if (zahlbarkeitstagString >= '1999-04-01' && gattung.dividendenwert) {
		const nominal = Math.floor(
			(position.anteil * position.anzahl * position.faktor) / INTERNAL_NUMBER_FACTOR / INTERNAL_NUMBER_FACTOR
		);
		dividendenanteil = gattung.dividendenwert * nominal;

		if (dividendenanteil <= 0) return;
	}

	//	Anpassung vom 15.05.2023: nachträglich 25% (anstatt 20%) für das Jahr 2002
	// 3.2 Quest KapSt. Div 25% (01.04.1999 - 31.12.2001)
	if (zahlbarkeitstagString >= '1999-04-01' && zahlbarkeitstagString <= '2002-12-31' && dividendenanteil) {
		const quest = dividendenanteil * QUEST_KEST25_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuerKESt,
			steuergrundbetrag: dividendenanteil,
			steuersatz: QUEST_KEST25_SATZ,
			steuerbetrag: Math.round(quest),
			steuername: 'Ausgleich QueSt KESt (01.04.1999 - 31.12.2002)',
		});

		const soli = quest * SOLI_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuernSoli,
			steuergrundbetrag: quest,
			steuersatz: SOLI_SATZ,
			steuerbetrag: floor2(soli),
			steuername: 'Ausgleich QueSt Soli (01.04.1999 - 31.12.2002)',
		});
	}

	// 3.3 Quest KapSt. Div 20% (01.01.2002 - 31.12.2008)
	if (zahlbarkeitstagString >= '2003-01-01' && zahlbarkeitstagString <= '2008-12-31' && dividendenanteil) {
		const quest = dividendenanteil * QUEST_KEST20_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuerKESt,
			steuergrundbetrag: dividendenanteil,
			steuersatz: QUEST_KEST20_SATZ,
			steuerbetrag: Math.round(quest),
			steuername: 'Ausgleich QueSt KESt (01.01.2003 - 31.12.2008)',
		});

		const soli = quest * SOLI_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuernSoli,
			steuergrundbetrag: quest,
			steuersatz: SOLI_SATZ,
			steuerbetrag: floor2(soli),
			steuername: 'Ausgleich QueSt Soli (01.01.2003 - 31.12.2008)',
		});
	}

	// 3.4 Quest KapSt.AGS Div 20% (01.01.2009 - 31.12.2011)
	if (zahlbarkeitstagString >= '2009-01-01' && zahlbarkeitstagString <= '2011-12-31' && dividendenanteil) {
		const quest = dividendenanteil * QUEST_KEST25_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuerKESt,
			steuergrundbetrag: dividendenanteil,
			steuersatz: QUEST_KEST25_SATZ,
			steuerbetrag: Math.round(quest),
			steuername: 'Ausgleich QueSt KESt (01.01.2009 - 31.12.2011)',
		});

		const soli = quest * SOLI_SATZ;

		position.steuern.push({
			steuerart: Steuerart.QuellensteuernSoli,
			steuergrundbetrag: quest,
			steuersatz: SOLI_SATZ,
			steuerbetrag: floor2(soli),
			steuername: 'Ausgleich QueSt Soli (01.01.2009 - 31.12.2011)',
		});
	}
}

function getKestSteuersatz(
	einreichungOrProzentsatz: Einreichung | number,
	zuflussAndKistamEVorRK: boolean = false
): number {
	let kistamProzentsatz;
	if (zuflussAndKistamEVorRK) {
		kistamProzentsatz = null;
	} else {
		kistamProzentsatz =
			typeof einreichungOrProzentsatz === 'number'
				? einreichungOrProzentsatz
				: einreichungOrProzentsatz.kistamProzentsatz;
	}

	switch (kistamProzentsatz) {
		case KIST_SATZ_BYBW:
			return KEST_SATZ_KIST_BYBW;
		case KIST_SATZ:
			return KEST_SATZ_KIST;
		default:
			return KEST_SATZ;
	}
}
