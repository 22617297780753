import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TagesverarbeitungService {
	constructor(private readonly http: HttpClient) {}

	public getBySearchCriteria$(
		payload: TagesverarbeitungUebersichtSearchCriteria
	): Observable<TagesverarbeitungUebersichtEintrag[]> {
		return this.http.post<TagesverarbeitungUebersichtEintrag[]>('/geschaeftsfall/tagesverarbeitung', payload);
	}
}

export interface TagesverarbeitungUebersichtSearchCriteria {
	eingangsdatumAb: string | null;
	eingangsdatumBis: string | null;
	verarbeitungszeitpunktAb: string | null;
	verarbeitungszeitpunktBis: string | null;
}

export interface TagesverarbeitungUebersichtEintrag {
	nummer: string;
	wkn: string;
	land: string;
	einreicher: string;
	valutatag: string | null;
	verarbeitungszeitpunkt: string;
	einreichungsdatum: string;
	nominal: number;
	bruttobetrag: number;
	steuern: number;
	kapitalertragssteuer: number;
	zinsabschlagsteuer: number;
	solidaritaetszuschlag: number;
	kirchensteuer: number;
	cdcGebuehren: number;
	dekaGebuehren: number;
	nettobetrag: number;
}
