import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, take, throwError } from 'rxjs';
import { ConfigService } from './config.service';
import { InitService } from './init/init.service';

const noAuth = ['config.json', 'version.txt', 'build-date.txt'];

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	constructor(
		private configService: ConfigService,
		private readonly initService: InitService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const httpEvent$ = next.handle(req);
		const ret$ = httpEvent$.pipe(
			catchError(err => {
				if ([401, 403].includes(err.status)) {
					debugger;
					// auto logout if 401 (login) or 403 (forbidden) response returned from api
					this.initService.logout();
				}

				const error = err.error?.message || err.statusText;
				console.error(err);
				return throwError(() => error);
			}));

		if (noAuth.includes(req.url)) {
			return httpEvent$;
		}
		req = req.clone({ url: this.configService.config.backendUrl + req.url, withCredentials: true });

		return next.handle(req);
	}
}
