import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, pairwise, switchMap, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { DisplayErrorService } from 'src/app/shared/display-error.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { ReportsHasChanged } from 'src/app/shared/route-guards/unsaved-changes-guard';
import { isValid$ } from 'src/app/utils';
import { logger } from 'src/logger';
import { StammdatenStatus } from '../../model';
import { GattungService } from '../gattung.service';
import {
	berechneBerechneteGattungsFelder,
	Gattung,
	gattungMetadata,
	WKZ,
	ZuVersteuerndeVorabpauschale,
} from '../model';
import { FinanzinstitutService } from './../../finanzinstitut/finanzinstitut.service';
import { FinanzinstitutRolle } from '../../finanzinstitut/model';

@Component({
	selector: 'app-gattung-edit-page',
	templateUrl: './gattung-edit-page.component.html',
	styleUrls: ['./gattung-edit-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_RADIO_DEFAULT_OPTIONS,
			useValue: { color: 'primary' },
		},
	],
})
export class GattungEditPageComponent extends BaseComponent implements ReportsHasChanged {
	public readonly metadata = gattungMetadata;
	public readonly finanzinstitutRolle = FinanzinstitutRolle.Emittent;

	public readonly form = new TypedForm<GattungEditFormValues>({
		gattungsbezeichnung: createFormControl(gattungMetadata.fields.gattungsbezeichnung),
		isin: createFormControl(gattungMetadata.fields.isin),
		wkn: createFormControl(gattungMetadata.fields.wkn),
		wkz: createFormControl(gattungMetadata.fields.wkz, false),
		status: createFormControl(gattungMetadata.fields.status),
		kuponnummerFaelligkeit: createFormControl(gattungMetadata.fields.kuponnummerFaelligkeit),
		emittentId: createFormControl(gattungMetadata.fields.emittentId),
		bruttowert: createFormControl(gattungMetadata.fields.bruttowert),
		bruttowertEuro: createFormControl(gattungMetadata.fields.bruttowertEuro),
		zinswert: createFormControl(gattungMetadata.fields.zinswert),
		steuerpflichtigerAnteil: createFormControl(
			gattungMetadata.fields.steuerpflichtigerAnteil
		),
		zahlbarkeitstag: createFormControl(gattungMetadata.fields.zahlbarkeitstag),
		nominalwaehrung: createFormControl(gattungMetadata.fields.nominalwaehrung),
		bundesland: createFormControl(gattungMetadata.fields.bundesland),
		land: createFormControl(gattungMetadata.fields.land),
		nettoKapst: createFormControl(gattungMetadata.fields.nettoKapst),
		dividendenwert: createFormControl(gattungMetadata.fields.dividendenwert),
		nettoZast: createFormControl(gattungMetadata.fields.nettoZast),
		akkumulierteErtraege: createFormControl(gattungMetadata.fields.akkumulierteErtraege),
		zwischengewinn: createFormControl(gattungMetadata.fields.zwischengewinn),
		investmentsteuerart: createFormControl(gattungMetadata.fields.investmentsteuerart),
		zuVersteuerndeVorabpauschale: createFormControl(
			gattungMetadata.fields.zuVersteuerndeVorabpauschale
		),
		basisertrag: createFormControl(gattungMetadata.fields.basisertrag),
		ausschuettungsdatum: createFormControl(gattungMetadata.fields.ausschuettungsdatum),
		dotationskonto: createFormControl(gattungMetadata.fields.dotationskonto),
		fusioniertMit: createFormControl(gattungMetadata.fields.fusioniertMit),
		fusioniertAb: createFormControl(gattungMetadata.fields.fusioniertAb),
		fusionUmrechnungsfaktor: createFormControl(
			gattungMetadata.fields.fusionUmrechnungsfaktor
		),
		umstellungAusgeschuettetAb: createFormControl(
			gattungMetadata.fields.umstellungAusgeschuettetAb
		),
		istThesaurierenderFonds: createFormControl(
			gattungMetadata.fields.istThesaurierenderFonds
		),
		kestVonEmittentenAbgefuehrt: createFormControl(
			gattungMetadata.fields.kestVonEmittentenAbgefuehrt,
			false
		),
		istInkasso: createFormControl(gattungMetadata.fields.istInkasso, false),
		istSteuerfreieGattung: createFormControl(gattungMetadata.fields.istSteuerfreieGattung),
		geaendertVon: createFormControl(gattungMetadata.fields.geaendertVon),
		geaendertAm: createFormControl(gattungMetadata.fields.geaendertAm),
		freigegebenVon: createFormControl(gattungMetadata.fields.freigegebenVon),
		freigegebenAm: createFormControl(gattungMetadata.fields.freigegebenAm),
		istFusioniert: new UntypedFormControl(false),
		hatDotationen: new UntypedFormControl(false),
		stueckelungEinzelwert: createFormControl(gattungMetadata.fields.stueckelungEinzelwert),
		anzahlAnfang: createFormControl(gattungMetadata.fields.anzahlAnfang),
		wertAnfang: createFormControl(gattungMetadata.fields.wertAnfang),
		istLiquidiert: createFormControl(gattungMetadata.fields.istLiquidiert, false),
	});

	public readonly id$ = new BehaviorSubject<string | null>(null);

	public readonly isSaving$ = new BehaviorSubject(false);
	public readonly cannotSave$ = combineLatest([this.isSaving$, isValid$(this.form)]).pipe(
		map(([isSaving, isValid]) => isSaving || !isValid || this.form.pristine)
	);

	public saveButtonTooltip$ = this.form.valueChanges.pipe(
		map(() => {
			if (this.form.status === 'VALID') return 'Speichern';
			return [this.form.errors, ...Object.values(this.form.controls).map(c => c.errors)]
				.filter(e => e)
				.join(', ');
		})
	);

	public readonly openItemsMessage$ = new BehaviorSubject<string | null>(null);
	public readonly duplicateYearVorabpauschaleMessage$ = new BehaviorSubject<string | null>(
		null
	);

	private snapshot?: string;

	public takeSnapshot(): void {
		this.snapshot = JSON.stringify(this.form.getRawValue());
	}

	public hasChanged(): boolean {
		return JSON.stringify(this.form.getRawValue()) !== this.snapshot;
	}

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		public readonly service: GattungService,
		private readonly router: Router,
		private readonly displayError: DisplayErrorService,
		private readonly alert: AlertService,
		private readonly efaKonfigService: KonfigurationService,
		private readonly finanzinstitutService: FinanzinstitutService
	) {
		super();

		this.form.addValidators((form: AbstractControl) => {
			const values = form.value as GattungEditFormValues;

			const duplicateGattung = service.list$.value.find(potentialDuplicate => {
				if (potentialDuplicate.id === this.id$.value) return false; // kann sich selbst nicht duplizieren
				if (
					potentialDuplicate.wkz !== values.wkz ||
					(potentialDuplicate.isin !== values.isin &&
						potentialDuplicate.wkn !== values.wkn)
				)
					return false;
				if (
					!values.wkz && //nur für kupon
					(potentialDuplicate.kuponnummerFaelligkeit || '') !==
					(values.kuponnummerFaelligkeit || '')
				)
					return false;

				return true;
			});

			if (!duplicateGattung) return null;
			return {
				duplicateGattung,
			};
		});

		this.registerSubscription(
			this.activatedRoute.paramMap.subscribe(params => {
				let id = params.get('id');
				if (!id || id === 'new') {
					const dekaFinanzinsitut = finanzinstitutService.list$.value.find(
						f =>
							f.finanzinstitutsnummer ===
							this.efaKonfigService.konfiguration$.value.dekaFinanzinsitutsnummer
					);
					this.form.resetTyped({
						wkz: true,
						ausschuettungsdatum: [],
						zuVersteuerndeVorabpauschale: [],
						land: 'DE',
						emittentId: dekaFinanzinsitut?.id,
					});
					return;
				}

				const zahlbarketstagNeuausschuettungString =
					this.activatedRoute.snapshot?.queryParamMap.get('neuAusschuettung');
				const zahlbarketstagNeuausschuettung =
					zahlbarketstagNeuausschuettungString &&
					new Date(Number(zahlbarketstagNeuausschuettungString));

				const shouldDuplicate =
					this.activatedRoute.snapshot?.queryParamMap.get('duplicate');
				this.id$.next(shouldDuplicate || zahlbarketstagNeuausschuettung ? null : id);

				this.form.disable();
				combineLatest([
					this.service.loadSingle$(id),
					this.service.gattungAktiveNutzungInfo$(id),
				]).subscribe(([f, info]) => {
					if (zahlbarketstagNeuausschuettung) {
						f = { ...f };
						f.zahlbarkeitstag = zahlbarketstagNeuausschuettung;
						f.kuponnummerFaelligkeit = formatDate(
							zahlbarketstagNeuausschuettung,
							'dd.MM.yyy',
							'de'
						);
						f.wkz = WKZ.Kupon;
						f.zwischengewinn = 0;
						f.akkumulierteErtraege = 0;
						f.bruttowert = 0;
						f.bruttowertEuro = 0;
						f.dividendenwert = 0;
						f.zinswert = 0;
						f.ausschuettungsdatum = [];
					}

					this.form.resetTyped({
						...f,
						istFusioniert: !!f.fusioniertMit,
						hatDotationen: !!f.anzahlAnfang && !!f.stueckelungEinzelwert,
					});
					this.form.enable();
					if (
						f.status === StammdatenStatus.Aktiv &&
						!shouldDuplicate &&
						!zahlbarketstagNeuausschuettung &&
						info?.inActiveUse
					) {
						const message = this.service.assembleOpenItemMessage(info);
						this.openItemsMessage$.next(message);
					}

					this.takeSnapshot();
				});
			})
		);

		this.registerSubscription(
			this.form.values$
				.pipe(pairwise())
				.subscribe(([oldValues, newValues]) => this.reevaluateFields(oldValues, newValues))
		);

		this.registerSubscription(
			this.form.controls.zuVersteuerndeVorabpauschale.valueChanges.subscribe(
				(vorabpArray: ZuVersteuerndeVorabpauschale[]) => {
					if (!vorabpArray) return;
					const vorabpYearsArray = vorabpArray.map(x => x.jahr);
					if (vorabpYearsArray.length !== new Set(vorabpYearsArray).size) {
						this.duplicateYearVorabpauschaleMessage$.next(
							'Pro Jahr kann nur eine Vorabpauschale angegeben werden!'
						);
					} else {
						this.duplicateYearVorabpauschaleMessage$.next(null);
					}
				}
			)
		);
	}

	reevaluateFields(oldValues: GattungEditFormValues, newValues: GattungEditFormValues): void {
		if (JSON.stringify(oldValues) === JSON.stringify(newValues)) return;

		const values = this.form.rawTypedValue;
		const { istFusioniert, hatDotationen, ...gattung } = values;

		if (oldValues.istThesaurierenderFonds && !newValues.istThesaurierenderFonds) {
			gattung.steuerpflichtigerAnteil = null;
		}

		berechneBerechneteGattungsFelder(gattung as Gattung);

		this.form.patchValue({ ...gattung }, { emitEvent: false });

		if (newValues.hatDotationen) {
			this.form.controls.stueckelungEinzelwert.addValidators(Validators.required);
			this.form.controls.anzahlAnfang.addValidators(Validators.required);
		} else {
			this.form.controls.stueckelungEinzelwert.removeValidators(Validators.required);
			this.form.controls.stueckelungEinzelwert.updateValueAndValidity();
			this.form.controls.anzahlAnfang.removeValidators(Validators.required);
			this.form.controls.anzahlAnfang.updateValueAndValidity();
		}

		this.form.controls.bruttowertEuro.disable({ emitEvent: false });
		this.form.controls.nettoKapst.disable({ emitEvent: false });
		this.form.controls.nettoZast.disable({ emitEvent: false });
		this.form.controls.basisertrag.disable({ emitEvent: false });
		this.form.controls.wertAnfang.disable({ emitEvent: false });

		if (gattung.istThesaurierenderFonds) {
			this.form.controls.steuerpflichtigerAnteil.disable({ emitEvent: false });
		} else {
			this.form.controls.steuerpflichtigerAnteil.enable({ emitEvent: false });
		}
	}

	save(): void {
		const values = this.form.rawTypedValue;
		this.isSaving$.next(true);
		this.form.disable();
		this.id$
			.pipe(
				take(1),
				switchMap(id => {
					const { istFusioniert, hatDotationen, ...gattung } = values;
					return this.service.save$({
						...gattung,
						id: id ? id : undefined,
						fusioniertAb:
							istFusioniert && values.fusioniertAb ? values.fusioniertAb : null,
						fusioniertMit:
							istFusioniert && values.fusioniertMit ? values.fusioniertMit : null,
						fusionUmrechnungsfaktor:
							istFusioniert && values.fusionUmrechnungsfaktor
								? values.fusionUmrechnungsfaktor
								: null,
						anzahlAnfang:
							hatDotationen && values.anzahlAnfang ? values.anzahlAnfang : null,
						stueckelungEinzelwert:
							hatDotationen && values.stueckelungEinzelwert
								? values.stueckelungEinzelwert
								: null,
					});
				})
			)
			.subscribe({
				error: (err: Error) => {
					this.displayError.showError(`Speichern von Gattung hat fehlgeschlagen`, err);
					logger.error(err);
					this.isSaving$.next(false);
					this.form.enable();
				},
				next: data => {
					this.isSaving$.next(false);
					this.alert.success('Gattung gespeichert');
					this.takeSnapshot();
					this.router.navigate([this.metadata.routing.view!.url(data)]);
				},
			});
	}
}

export interface GattungEditFormValues {
	emittentId: string;
	isin: string;
	wkz: boolean;
	wkn: string;
	gattungsbezeichnung: string;
	status: StammdatenStatus;
	kuponnummerFaelligkeit: string | null;
	bruttowert: number | null;
	bruttowertEuro: number | null;
	steuerpflichtigerAnteil: number | null;
	zinswert: number | null;
	zahlbarkeitstag: Date | undefined;
	nominalwaehrung: string | undefined;
	land: string | undefined;
	bundesland: string | undefined;
	nettoKapst: number | null;
	nettoZast: number | null;
	dividendenwert: number | null;
	akkumulierteErtraege: number | null;
	zwischengewinn: number | null;
	investmentsteuerart: string | null;
	basisertrag: number | null;
	umstellungAusgeschuettetAb: Date | null;
	dotationskonto: string | null;
	fusioniertMit: string | null;
	fusioniertAb: Date | null;
	fusionUmrechnungsfaktor: number | null;
	istThesaurierenderFonds: boolean;
	istInkasso: boolean;
	istSteuerfreieGattung: boolean;
	kestVonEmittentenAbgefuehrt: boolean;
	geaendertVon: string;
	geaendertAm: Date;
	freigegebenVon: string | null;
	freigegebenAm: Date | null;
	istFusioniert: boolean;
	zuVersteuerndeVorabpauschale: ZuVersteuerndeVorabpauschale[];
	ausschuettungsdatum: string[];
	hatDotationen: boolean;
	stueckelungEinzelwert: number | null;
	anzahlAnfang: number | null;
	wertAnfang: number | null;
	istLiquidiert: boolean;
}
