import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Finanzinstitut, finanzinstitutMetadata } from '../model';

@Component({
	selector: 'app-finanzinstitut-details',
	templateUrl: './finanzinstitut-details.component.html',
	styleUrls: ['./finanzinstitut-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanzinstitutDetailsComponent implements OnInit {
	@Input() finanzinstitut: Finanzinstitut;
	@Input() showHeader = true;


	readonly metadata = finanzinstitutMetadata;

	constructor() {
		// this is to ensure that this.finanzinstitut is always defined
		// this avoids additional *ngIf="finanzinstitut" in the template
		this.finanzinstitut = {} as Finanzinstitut;
	}

	ngOnInit(): void {
		if (!this.finanzinstitut) {
			throw new Error('finanzinstitut must be set!');
		}
	}
}
