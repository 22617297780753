import { Pipe, PipeTransform } from '@angular/core';
import { statusCheck } from 'src/app/utils';
import { Einreichungssucheeintrag } from '../archiv/einreichung-suche.service';
import { EditableEinreichungStati, Einreichung } from '../model';

@Pipe({
	name: 'isEditable',
})
export class IsEditablePipe implements PipeTransform {
	transform(value: Einreichungssucheeintrag | Einreichung): boolean {
		const ret = statusCheck(
			(value: Einreichungssucheeintrag | Einreichung) => value.status,
			EditableEinreichungStati
		)(value);
		return ret;
	}
}
