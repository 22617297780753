<div class="flex-column" style="margin: 0 0.5em 0 0.5em">
	<mat-card *ngIf="calculateGeschaeftsfaelleResult.messages.length > 0">
		<mat-card-content>
			<p
				*ngFor="let message of calculateGeschaeftsfaelleResult.messages"
				[class.text-primary]="message.level === 'error'">
				{{ message.text }}
			</p>
		</mat-card-content>
	</mat-card>
	<mat-card
		*ngFor="
			let geschaeftsfallMitPositionen of calculateGeschaeftsfaelleResult.geschaeftsfaelle
		">
		<mat-card-content>
			<app-geschaeftsfall
				[geschaeftsfallMitPositionen]="geschaeftsfallMitPositionen"
				embeddedMode="true"></app-geschaeftsfall>
		</mat-card-content>
	</mat-card>
</div>
