import { EinreichungKorrekturSaveModel, EinreichungspersonSaveModel } from './../../einreichung.service';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, BehaviorSubject, switchMap, take } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { formatDateForSaveModel, isValid$, parseIncomingAsDate } from 'src/app/utils';
import { logger } from 'src/logger';
import { EinreichungKorrekturModel, EinreichungService } from '../../einreichung.service';
import { einreichungMetadata } from '../../model';

@Component({
	selector: 'app-einreichung-korrektur',
	templateUrl: './einreichung-korrektur.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungKorrekturComponent extends BaseComponent {
	public readonly metadata = einreichungMetadata;

	public readonly id$ = new BehaviorSubject<string>('');
	public readonly pageHeaderValues$ = new BehaviorSubject<{ nummer: number }>({} as any);

	public readonly form = new TypedForm<EinreichungKorrekturFormValues>({
		depotInhaber: createFormControl(einreichungMetadata.fields.depotInhaber),
		depotNummer: createFormControl(einreichungMetadata.fields.depotNummer),
		konto: createFormControl(einreichungMetadata.fields.konto),
		bic: createFormControl(einreichungMetadata.fields.bic),
		kontoInhaber: createFormControl(einreichungMetadata.fields.kontoInhaber),
		personen: new UntypedFormControl([]),
		dsgvoAenderungsgrund: createFormControl(einreichungMetadata.fields.dsgvoAenderungsgrund, '', {
			validators: [Validators.required],
		}),
	});

	public readonly cannotSave$ = isValid$(this.form).pipe(map(isValid => !isValid));
	public readonly isSaving$ = new BehaviorSubject(false);

	constructor(
		public readonly service: EinreichungService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly alert: AlertService
	) {
		super();

		this.registerSubscription(
			this.activatedRoute.paramMap.subscribe(params => {
				const id = params.get('id');

				if (id) {
					this.id$.next(id);
				}
			})
		);

		this.id$
			.pipe(
				take(1),
				switchMap(id => this.service.loadSingle$(id))
			)
			.subscribe(e => {
				this.pageHeaderValues$.next({ nummer: e.nummer });
				const formValues: EinreichungKorrekturFormValues = {
					depotNummer: e.depotNummer,
					depotInhaber: e.depotInhaber,
					konto: e.konto,
					bic: e.bic,
					kontoInhaber: e.kontoInhaber,
					personen: e.personen,
					dsgvoAenderungsgrund: e.dsgvoAenderungsgrund,
				};
				this.form.setValueTyped(formValues);
			});
	}

	abbrechen() {
		this.router.navigate([this.metadata.routing.view!.url(this.id$.value)]);
	}

	save() {
		this.isSaving$.next(true);
		const values = this.form.typedValue;
		const saveModel: EinreichungKorrekturSaveModel = {
			...values,
			bic: values.bic ? values.bic : null, //BE gets validation error with empty string
			personen: (values.personen || []).map(
				p =>
					({
						...p,
						geburtsdatum: formatDateForSaveModel(p.geburtsdatum),
					} as EinreichungspersonSaveModel)
			),
		} as EinreichungKorrekturSaveModel;

		this.service.saveKorrektur$(this.id$.value, saveModel).subscribe({
			error: err => {
				this.alert.error('Speichern der Korrektur fehlgeschlagen!', err);
				logger.error(err);
				this.isSaving$.next(false);
				this.form.enable();
			},
			next: () => {
				this.isSaving$.next(false);
				this.alert.success('Korrektur gespeichert');
				this.router.navigate([einreichungMetadata.routing.view!.url(this.id$.value)]);
			},
		});
	}
}

export type EinreichungKorrekturFormValues = Omit<EinreichungKorrekturModel, 'id' | 'nummer'>;
