import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EinreichungStatus } from '../model';

const barItemLabels: { label: string; status: EinreichungStatus[] }[] = [
	{ label: 'Neu/Eingegangen', status: [EinreichungStatus.Eingegangen] },
	{
		label: 'In Bearbeitung',
		status: [EinreichungStatus.InBearbeitung, EinreichungStatus.Rueckfrage, EinreichungStatus.InternInKlaerung],
	},
	{
		label: 'Zur Freigabe',
		status: [
			EinreichungStatus.ZurFreigabe,
			EinreichungStatus.ZurFreigabeKorrigiert,
			EinreichungStatus.ZurFreigabeNurDokumentation,
		],
	},
	{ label: 'Freigegeben', status: [EinreichungStatus.Freigegeben] },
	{ label: 'Teilgebucht', status: [EinreichungStatus.Teilgebucht] },
];

@Component({
	selector: 'app-einreichung-status-bar',
	templateUrl: './einreichung-status-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./einreichung-status-bar.component.scss'],
})
export class EinreichungStatusBarComponent implements OnChanges {
	@Input() status?: EinreichungStatus;

	readonly barItems: { label: string; isActive: boolean }[] = [];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.status.currentValue === changes.status.previousValue) return;

		this.barItems.length = 0;
		if (!this.status) return;

		if (this.status === EinreichungStatus.ZurFreigabeNurDokumentation) {
			this.barItems.push({ label: 'Neuerfassung (nur Dokumentation)', isActive: false });
			this.barItems.push({ label: 'Zur Freigabe (nur Dokumentation)', isActive: true });
			this.barItems.push({ label: 'Abgeschlossen', isActive: false });
			return;
		}

		if (this.status === EinreichungStatus.Abgebrochen) {
			this.barItems.push({ isActive: true, label: 'Abgebrochen' });
		}

		for (const bi of barItemLabels) {
			this.barItems.push({ label: bi.label, isActive: bi.status.includes(this.status) });
		}

		switch (this.status) {
			case EinreichungStatus.Abgebrochen:
				break;
			case EinreichungStatus.Abgeschlossen:
				this.barItems.push({ isActive: true, label: 'Abgeschlossen' });
				break;
			case EinreichungStatus.Storniert:
				this.barItems.push({ isActive: true, label: 'Storniert' });
				break;
			default:
				this.barItems.push({ isActive: false, label: 'Abgeschlossen' });
				break;
		}
	}
}
