<div class="page-header" style="justify-content: unset">
	<button mat-raised-button color="accent" (click)="abbrechen()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>

	<button
		mat-raised-button
		color="primary"
		type="submit"
		[disabled]="cannotSave$ | async"
		(click)="save()">
		<mat-icon>save</mat-icon>
		Zwischenspeichern
	</button>
	<button
		mat-raised-button
		color="primary"
		type="submit"
		[disabled]="cannotSave$ | async"
		(click)="save(true)">
		<mat-icon>save</mat-icon>
		Speichern &amp; Formular schliessen
	</button>
</div>

<div class="page-header" style="position: relative">
	<h1>
		<span *ngIf="!id">Neue Einreichung</span>
		<span *ngIf="id">
			Einreichung {{ (pageHeaderValues$ | async)?.nummer }} ({{
				(pageHeaderValues$ | async)?.status
			}})
		</span>
	</h1>
</div>

<mat-accordion multi [formGroup]="form" style="overflow-y: scroll">
	<mat-expansion-panel [expanded]="true" #headerPanel>
		<mat-expansion-panel-header>
			<mat-panel-title>Eingang</mat-panel-title>
			<mat-panel-description [class.hidden]="headerPanel.expanded">
				<ng-container *ngFor="let item of eingangZusammenfassung$ | async">
					<span>{{ item.label }}:</span>
					<strong>{{ item.value }}</strong>
				</ng-container>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<div class="flex-column" [formGroup]="form">
			<div class="flex-row">
				<app-date-input
					[metadata]="metadata.fields.eingangsdatum"
					formControlName="eingangsdatum"></app-date-input>
				<app-date-input
					[metadata]="metadata.fields.posteingangsdatum"
					formControlName="posteingangsdatum"></app-date-input>
				<app-numeric-input
					[metadata]="metadata.fields.anzahlUmschlaege"
					formControlName="anzahlUmschlaege"></app-numeric-input>
				<app-numeric-input
					[metadata]="metadata.fields.betrag"
					formControlName="betrag"></app-numeric-input>
				<app-text-input
					[metadata]="metadata.fields.waehrungStueck"
					formControlName="waehrungStueck"></app-text-input>
			</div>

			<div class="flex-row">
				<app-text-input
					[metadata]="metadata.fields.absender"
					formControlName="absender"></app-text-input>
				<app-fixedlist-input
					[metadata]="metadata.fields.versandart"
					formControlName="versandart"></app-fixedlist-input>
				<app-text-input
					[metadata]="metadata.fields.sendungsnummer"
					formControlName="sendungsnummer"></app-text-input>
				<app-text-input
					[metadata]="metadata.fields.empfaenger"
					formControlName="empfaenger"></app-text-input>
				<app-text-input
					[metadata]="metadata.fields.externeReferenz"
					formControlName="externeReferenz"></app-text-input>
			</div>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel [expanded]="true" #personenPanel>
		<mat-expansion-panel-header>
			<mat-panel-title>
				Einreichung&nbsp;
				<span *ngIf="form.controls.erbfall.value">
					({{ metadata.fields.erbfall.label }})
				</span>
			</mat-panel-title>
			<mat-panel-description [class.hidden]="personenPanel.expanded">
				{{ einreicherZusammenfassung$ | async }}
			</mat-panel-description>
		</mat-expansion-panel-header>
		<div class="flex-row">
			<div style="flex: 1">
				<mat-slide-toggle formControlName="istEinreichungPrivat" color="primary">
					Ist private Einreichung
				</mat-slide-toggle>
				<!-- <p *ngIf="form.controls.erbfall.value" [matTooltip]="metadata.fields.erbfall.description || ''">
					Einreichung ist {{ metadata.fields.erbfall.label }}
				</p> -->
			</div>

			<ng-container *ngIf="!form.controls.istEinreichungPrivat.value">
				<app-finanzinstitut-choose
					[finanzinstitutRolle]="finanzinstitutRolle"
					formControlName="einreicher"
					style="flex: 6" ></app-finanzinstitut-choose>
			</ng-container>
		</div>

		<ng-container *ngIf="form.controls.istEinreichungPrivat.value">
			<app-einreichung-edit-personen
				formControlName="personen"></app-einreichung-edit-personen>
			<mat-error>{{ form.controls.personen | fieldErrorMessage$ | async }}</mat-error>
		</ng-container>

		<div class="flex-row">
			<app-text-input
				[metadata]="metadata.fields.depotNummer"
				formControlName="depotNummer"
				style="flex: 1"></app-text-input>
			<app-text-input
				style="flex: 2"
				[metadata]="metadata.fields.depotInhaber"
				formControlName="depotInhaber"></app-text-input>
			<app-text-input
				[metadata]="metadata.fields.kassenvereinsnummer"
				formControlName="kassenvereinsnummer"></app-text-input>
		</div>
		<div class="flex-row">
			<app-text-input
				[metadata]="metadata.fields.konto"
				formControlName="konto"></app-text-input>
			<app-text-input
				style="flex: 2"
				[metadata]="metadata.fields.kontoInhaber"
				formControlName="kontoInhaber"></app-text-input>
			<app-text-input
				[metadata]="metadata.fields.bic"
				formControlName="bic"></app-text-input>
		</div>

		<div class="flex-row" style="padding-bottom: 22px">
			<div class="mat-form-field">
				<mat-slide-toggle formControlName="couponShouldPay" color="primary">
					Kupons werden ausbezahlt
				</mat-slide-toggle>
			</div>
		</div>

		<div class="flex-row" *ngIf="form.controls.istEinreichungPrivat.value">
			<app-kuerzel-bezeichnung-choose
				style="flex: 2"
				formControlName="kistam"
				[metadata]="metadata.fields.kistam"></app-kuerzel-bezeichnung-choose>
			<app-readonly-field
				[metadata]="metadata.fields.kistamProzentsatz"
				[value]="form.controls.kistamProzentsatz.value | percent"></app-readonly-field>
			<app-fixedlist-input
				style="flex: 1"
				formControlName="religionszugehoerigkeit"
				[metadata]="metadata.fields.religionszugehoerigkeit"></app-fixedlist-input>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel [expanded]="true" #positionenPanel>
		<mat-expansion-panel-header>
			<mat-panel-title>Positionen</mat-panel-title>
			<mat-panel-description [class.hidden]="positionenPanel.expanded">
				<ng-container *ngFor="let item of positionenZusammenfassung$ | async">
					<span>{{ item.label }}:</span>
					<strong>{{ item.value }}</strong>
				</ng-container>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<div class="flex-column">
			<app-einreichung-dotation-info
				[positionen]="form.controls.positionen.value"></app-einreichung-dotation-info>
			<app-einreichung-edit-page-positionen
				[kistamProzentsatz]="form.controls.kistamProzentsatz.value"
				formControlName="positionen"></app-einreichung-edit-page-positionen>
			<div style="text-align: right">
				<button
					style="width: 15em"
					mat-raised-button
					color="accent"
					(click)="schnellerfassung()"
					[disabled]="form.controls.eingangsdatum.disabled">
					<mat-icon>library_add</mat-icon>
					Schnellerfassung
				</button>
			</div>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel [expanded]="true">
		<mat-expansion-panel-header>
			<mat-panel-title>Oppositionsprüfung</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="flex-row">
			<app-fixedlist-input
				[metadata]="metadata.fields.oppositionstreffer"
				formControlName="oppositionstreffer"></app-fixedlist-input>
		</div>
	</mat-expansion-panel>

	<!-- <mat-expansion-panel [expanded]="false">
		<mat-expansion-panel-header>
			<mat-panel-title>Vorabpauschale</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="flex-row">
			<app-numeric-input
				[metadata]="metadata.fields.vorabpauschaleGesamtsumme"
				formControlName="vorabpauschaleGesamtsumme"></app-numeric-input>
			<app-numeric-input
				[metadata]="metadata.fields.vorabpauschale"
				formControlName="vorabpauschale"></app-numeric-input>
			<div style="margin-left: 10em; margin-top: 2em">
				<mat-slide-toggle
					formControlName="vorabpauschaleEingegangen"
					[matTooltip]="metadata.fields.vorabpauschaleEingegangen.description || ''">
					{{ metadata.fields.vorabpauschaleEingegangen.label }}
				</mat-slide-toggle>
			</div>
		</div>
	</mat-expansion-panel> -->

	<mat-expansion-panel [expanded]="false" #dokumentenLinkPanel>
		<mat-expansion-panel-header>
			<mat-panel-title>Dokumentenlink</mat-panel-title>
			<mat-panel-description *ngIf="!dokumentenLinkPanel.expanded"></mat-panel-description>
		</mat-expansion-panel-header>
		<div class="flex-row">
			<app-text-input
				[metadata]="metadata.fields.dokumentenLink"
				formControlName="dokumentenLink"></app-text-input>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel [expanded]="false" #bemerkungPanel>
		<mat-expansion-panel-header>
			<mat-panel-title>Bemerkung</mat-panel-title>
			<mat-panel-description *ngIf="!bemerkungPanel.expanded"></mat-panel-description>
		</mat-expansion-panel-header>
		<div class="flex-row">
			<app-text-input
				[metadata]="metadata.fields.bemerkung"
				formControlName="bemerkung"></app-text-input>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel [expanded]="false" #aendererFreigeberPanel>
		<mat-expansion-panel-header>
			<mat-panel-title>Letzter Änderer/ Freigeber</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="flex-row">
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.geaendertVon.label }}:</span>
					<span>{{ form.controls.geaendertVon.value }}</span>
				</div>
			</div>
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.freigegebenVon.label }}:</span>
					<span>{{ form.controls.freigegebenVon.value }}</span>
				</div>
			</div>
		</div>
		<div class="flex-row">
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.geaendertAm.label }}:</span>
					<span>{{ form.controls.geaendertAm.value | date : 'dd.MM.yyyy' }}</span>
				</div>
			</div>
			<div class="data-column2" style="max-width: 20em">
				<div>
					<span>{{ metadata.fields.freigegebenAm.label }}:</span>
					<span>{{ form.controls.freigegebenAm.value | date : 'dd.MM.yyyy' }}</span>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
