<mat-form-field style="width: 100%">
	<div matPrefix>
		<app-field-help-icon [helpText]="metadata?.description"></app-field-help-icon>
	</div>
	<mat-label>{{ resolvedLabel }}</mat-label>
	<input
		matInput
		[formControl]="textControl"
		autocomplete="off"
		style="width: 100%"
		(focus)="htmlControl.select()"
		[placeholder]="resolvedPlaceholder"
		#htmlControl
		[maxlength]="maxLength"
		[required]="!!(isRequired$ | async)" />
	<div matSuffix style="margin-left: 0.5em; width: 1em" class="text-input-suffix">
		<mat-icon
			(click)="clear()"
			class="suffix-icon"
			*ngIf="showClearButton$ | async"
			matTooltip="Zurücksetzen">
			close
		</mat-icon>
	</div>
	<mat-error>{{ textControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
