import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/general/base-component';
import { UltimoGeschaeftsfaelleEintrag, UltimoGeschaeftsfaelleSearchCriteria } from '../model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, catchError } from 'rxjs';
import { formatDateForSaveModel } from 'src/app/utils';
import { UltimoService } from '../ultimo.service';
import { AlertService } from 'src/app/shared/alert.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { einreichungspersonMetadata, geschaeftsfallMetadata } from '../../model';
import * as ExcelJS from 'ExcelJS-workaround';

@Component({
	selector: 'app-ultimo-geschaeftsfaelle',
	templateUrl: './ultimo-geschaeftsfaelle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltimoGeschaeftsfaelleComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	@ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

  public readonly isSearching$ = new BehaviorSubject(false);
	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly personMetadata = einreichungspersonMetadata;
	public readonly geschaeftsfallMetadata = geschaeftsfallMetadata;
	
	public readonly dataSource = new MatTableDataSource<UltimoGeschaeftsfaelleEintrag>();
	public readonly displayedColumns = [
		'name',
		'rolle',
		'anschrift',
		'geburtsdatum',
		'staatsangehoerigkeit',
		'einreichungenHoehe',
		'isinWkn',
		'geschaeftsfallNummer',
		'pruefungsergebnis',
		'pruefungsdatum',
		'geschaeftsfallAbschlussdatum',
		'kycPruefnummer',
		'embargoPruefnummer',
	];
	public existsAllSearchCriteria$ = new BehaviorSubject(false);
  readonly searchForm = new TypedForm<FormValues>({
		datumAb: new UntypedFormControl(),
		datumBis: new UntypedFormControl(),
	});

  constructor(
		private readonly service: UltimoService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {
		super();

		this.dataSource.sortingDataAccessor = (element, property) => {
			switch (property) {
				case 'name':
				case 'rolle':
				case 'geburtsdatum':
				case 'staatsangehoerigkeit':
				case 'einreichungenHoehe':
				case 'geschaeftsfallNummer':
				case 'pruefungsergebnis':
				case 'pruefungsdatum':
				case 'geschaeftsfallAbschlussdatum':
       			case 'embargoPruefnummer':
				case 'kycPruefnummer':
					return element[property];
				case 'anschrift':
					return element.postleitzahl + element.stadt + element.adresse;
				case 'isinWkn':
					return element.isin + element.land;
				default:
					return '';
			}
		};

		this.registerSubscription(
			this.searchForm.valueChanges.subscribe(val => {
				if (Object.values(val).filter(s => !!s).length === 2) {
					this.existsAllSearchCriteria$.next(true);
				} else {
					this.existsAllSearchCriteria$.next(false);
					this.dataSource.data = [];
				}
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

  search(): void {
		if (!this.searchForm.typedValue) return;

		this.isSearching$.next(true);

		const payload: UltimoGeschaeftsfaelleSearchCriteria = {
			ab: formatDateForSaveModel(this.searchForm.typedValue.datumAb),
			bis: formatDateForSaveModel(this.searchForm.typedValue.datumBis),
		};

		this.service
			.getUltimoComplianceGeschaeftsfallData$(payload)
			.pipe(
				catchError(err => {
					this.dataSource.data = [];
					this.alert.error('Konnte nicht laden!', err, {
						date: this.searchForm.typedValue,
					});
					this.isSearching$.next(false);
					return EMPTY;
				})
			)
			.subscribe(items => {
				this.dataSource.data = items;
				this.isSearching$.next(false);
			});
	}

	downloadAsCsv() {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(this.geschaeftsfallMetadata.label);
		worksheet.addRow([
			'Name',
			'Rolle',
			'Anschrift',
			'Geburtsdatum',
			'Staatsangehörigkeit',
			'Einreichungen - Höhe',
			'ISIN / WKN (Land)',
			'Geschäftfallnummer',
			'Prüfungsergebnis',
			'Prüfungsdatum',
			'Abschlussdatum',
			'PEP-Nummer',
			'Embargo-Nummer',
		]);
		for (const element of this.dataSource.data) {
			const name = element.name + ', ' + element.vorname;
			const anschrift = element.adresse + ' ' + element.postleitzahl + ' ' + element.stadt;
			const isinWknLand = element.isin + ' / ' + element.wkn + ' (' + element.gattungLand + ')';
			
			worksheet.addRow([
				name,
				element.rolle,
				anschrift,
				element.geburtsdatum,
				element.staatsangehoerigkeit,
				element.einreichungenHoehe,
				isinWknLand,
				element.geschaeftsfallNummer,
				element.pruefungsergebnis,
				element.pruefungsdatum,
				element.geschaeftsfallAbschlussdatum,
				element.kycPruefnummer,
				element.embargoPruefnummer
			]);
		}
		const options = {
			// https://c2fo.io/fast-csv/docs/formatting/options
			formatterOptions: {
				delimiter: ';',
				quoteColumns: true,
				writeBOM: true,
			},
		};

		workbook.csv.writeBuffer(options).then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			const now: string = new Date().toISOString().slice(0, 10);
			this.save.saveAs(blob, 'CsvExportComplianceGeschaeftsfaelle_' + now + '.csv');
		});
	}
}

interface FormValues {
	datumAb: Date;
	datumBis: Date;
}
