import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { KonfigurationService } from '../konfiguration.service';
import { Konfiguration, konfigurationMetadata } from '../model';

@Component({
  selector: 'app-konfiguration',
  templateUrl: './konfiguration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KonfigurationComponent implements OnInit {
  public readonly metadata = konfigurationMetadata;

  public readonly form = new TypedForm<Konfiguration>({
    bankleitzahl: createFormControl(this.metadata.fields.bankleitzahl),
    kontoDotation: createFormControl(this.metadata.fields.kontoDotation),
    kontoCdc: createFormControl(this.metadata.fields.kontoCdc),
    kontoInkasso: createFormControl(this.metadata.fields.kontoInkasso),
    kontoKist: createFormControl(this.metadata.fields.kontoKist),
    kontoKest: createFormControl(this.metadata.fields.kontoKest),
    kontoSoli: createFormControl(this.metadata.fields.kontoSoli),
    kontoVorabpauschale: createFormControl(this.metadata.fields.kontoVorabpauschale),
    kontoAnteilsgeschaeftLeipzig: createFormControl(
      this.metadata.fields.kontoAnteilsgeschaeftLeipzig
    ),
    kontoZahlungsverkehr: createFormControl(this.metadata.fields.kontoZahlungsverkehr),
    kontoProvision: createFormControl(this.metadata.fields.kontoProvision),
    basisablageOrt: createFormControl(this.metadata.fields.basisablageOrt),
    addVerzeichnis: createFormControl(this.metadata.fields.addVerzeichnis),
    sepaVerzeichnis: createFormControl(this.metadata.fields.sepaVerzeichnis),
    dokumentenVerzeichnis: createFormControl(this.metadata.fields.dokumentenVerzeichnis),
    fileNetBaseLink: createFormControl(this.metadata.fields.fileNetBaseLink),
    roboticsVerzeichnis: createFormControl(this.metadata.fields.roboticsVerzeichnis),
    linkZumAnwenderhandbuch: createFormControl(this.metadata.fields.linkZumAnwenderhandbuch),
    dekaOrganisationseinheit: createFormControl(
      this.metadata.fields.dekaOrganisationseinheit
    ),
    dekaFinanzinsitutsnummer: createFormControl(
      this.metadata.fields.dekaFinanzinsitutsnummer
    ),
    dekaTelefonnummer: createFormControl(this.metadata.fields.dekaTelefonnummer),
    dekaFaxnummer: createFormControl(this.metadata.fields.dekaFaxnummer),
    dekaEmail: createFormControl(this.metadata.fields.dekaEmail),
    dekaAnsprechpartner: createFormControl(this.metadata.fields.dekaAnsprechpartner),
    dekaFinanzamtName: createFormControl(this.metadata.fields.dekaFinanzamtName),
    geaendertVon: createFormControl(this.metadata.fields.geaendertVon),
    geaendertAm: createFormControl(this.metadata.fields.geaendertAm),
  });

  public readonly isSaving$ = new BehaviorSubject(false);

  constructor(private service: KonfigurationService, private alert: AlertService) {
    this.form.disable();
  }

  ngOnInit(): void {
    this.form.patchValue(this.service.konfiguration$.value);
  }
}
