import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, catchError, EMPTY } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { formatDateForSaveModel } from 'src/app/utils';
import { geschaeftsfallMetadata } from '../../model';
import {
	TagesverarbeitungService,
	TagesverarbeitungUebersichtEintrag,
} from '../tagesverarbeitung.service';
import * as ExcelJS from 'ExcelJS-workaround';
import { SaveService } from 'src/app/shared/services/save.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
	selector: 'app-tagesverarbeitung-uebersicht',
	templateUrl: './tagesverarbeitung-uebersicht.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagesverarbeitungUebersichtComponent
	extends BaseComponent
	implements AfterViewInit
{
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;

	public readonly isSearching$ = new BehaviorSubject(false);
	public readonly metadata = geschaeftsfallMetadata;

	readonly searchForm = new TypedForm({
		eingangsdatumAb: new UntypedFormControl(),
		eingangsdatumBis: new UntypedFormControl(),
		verarbeitungszeitpunktAb: new UntypedFormControl(),
		verarbeitungszeitpunktBis: new UntypedFormControl(),
	});
	public existsAtLeastOneSearchCriteria$ = new BehaviorSubject(false);

	public readonly dataSource = new MatTableDataSource<TagesverarbeitungUebersichtEintrag>();
	public readonly displayedColumns = [
		'nummer',
		'wkn',
		'land',
		'einreicher',
		'valutatag',
		'verarbeitungszeitpunkt',
		'einreichungsdatum',
		'nominal',
		'bruttobetrag',
		'steuern',
		'kapitalertragssteuer',
		'zinsabschlagsteuer',
		'solidaritaetszuschlag',
		'kirchensteuer',
		'cdcGebuehren',
		'dekaGebuehren',
		'nettobetrag',
	];

	constructor(
		private readonly service: TagesverarbeitungService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {
		super();
		this.registerSubscription(
			this.searchForm.valueChanges.subscribe(val => {
				if (Object.values(val).filter(val => !!val).length > 0) {
					this.existsAtLeastOneSearchCriteria$.next(true);
				} else {
					this.existsAtLeastOneSearchCriteria$.next(false);
				}
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	search(): void {
		if (!this.searchForm.typedValue) return;

		this.isSearching$.next(true);

		const payload = {
			eingangsdatumAb: formatDateForSaveModel(
				this.searchForm.typedValue.eingangsdatumAb as Date
			),
			eingangsdatumBis: formatDateForSaveModel(
				this.searchForm.typedValue.eingangsdatumBis as Date
			),
			verarbeitungszeitpunktAb: formatDateForSaveModel(
				this.searchForm.typedValue.verarbeitungszeitpunktAb as Date
			),
			verarbeitungszeitpunktBis: formatDateForSaveModel(
				this.searchForm.typedValue.verarbeitungszeitpunktBis as Date
			),
		};

		this.service
			.getBySearchCriteria$(payload)
			.pipe(
				catchError(err => {
					this.alert.error('Konnte nicht laden!', err, {
						date: this.searchForm.typedValue,
					});
					this.isSearching$.next(false);
					return EMPTY;
				})
			)
			.subscribe(items => {
				this.dataSource.data = items;
				this.isSearching$.next(false);
			});
	}

	downloadAsExcel(data: TagesverarbeitungUebersichtEintrag[]): void {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Übersicht Tagesverarbeitung');
		worksheet.addRow([
			this.metadata.fields.nummer.label,
			this.metadata.fields.wkn.label,
			'Land',
			'Einreicher',
			'Valutatag',
			'Verarbeitungszeitpunkt',
			'Einreichungsdatum',
			'Nominal',
			this.metadata.fields.bruttobetrag.label,
			this.metadata.fields.steuern.label,
			this.metadata.fields.kapitalertragssteuer.label,
			this.metadata.fields.zinsabschlagsteuer.label,
			this.metadata.fields.solidaritaetszuschlag.label,
			this.metadata.fields.kirchensteuer.label,
			this.metadata.fields.cdcGebuehren.label,
			this.metadata.fields.dekaGebuehren.label,
			this.metadata.fields.nettobetrag.label,
		]);
		for (const element of data) {
			worksheet.addRow([
				element.nummer,
				element.wkn,
				element.land,
				element.einreicher,
				element.valutatag ? new Date(element.valutatag)?.toLocaleDateString('de-DE') : '',
				element.verarbeitungszeitpunkt
					? new Date(element.verarbeitungszeitpunkt)?.toLocaleString('de-DE')
					: '',
				element.einreichungsdatum
					? new Date(element.einreichungsdatum)?.toLocaleDateString('de-DE')
					: '',
				element.nominal,
				element.bruttobetrag.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.steuern.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.kapitalertragssteuer.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.zinsabschlagsteuer.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.solidaritaetszuschlag.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.kirchensteuer.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.cdcGebuehren.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.dekaGebuehren.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.nettobetrag.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
			]);
		}
		//
		workbook.xlsx.writeBuffer().then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			const now: string = new Date().toISOString().slice(0, 10);
			this.save.saveAs(blob, 'tagesverarbeitung_uebersicht_' + now + '.xlsx');
		});
	}
}
