import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { direktBuchungen } from 'src/app/berechnung/buchungen';
import { calculateGeschaeftsfallWerte, SteuerUeberschreibung } from 'src/app/berechnung/geschaeftsfaelle';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { GeschaeftsfallMitPositionen } from '../../model';
import { CalculateResultMitEinreichung } from '../einreichung-view-page.component';

@Component({
	selector: 'app-freigabe-anfordern-steuern-ueberschreibung',
	templateUrl: './freigabe-anfordern-steuern-ueberschreibung.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreigabeAnfordernSteuernUeberschreibungComponent {
	public geschaeftsfaelleZuUeberschreiben: GeschaeftsfallMitPositionen[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: CalculateResultMitEinreichung,
		private readonly gattungenService: GattungService,
		private readonly konfigurationService: KonfigurationService,
		private readonly changeDetectionRef: ChangeDetectorRef
	) {
		this.recalculateGeschaeftsfaelleZuUeberschreiben();
	}

	recalculateGeschaeftsfaelleZuUeberschreiben(): void {
		this.geschaeftsfaelleZuUeberschreiben = this.data.calculateResult.geschaeftsfaelle.filter(
			gf => !gf.geschaeftsfall.istInkasso
		);
	}

	neurechnen(gf: GeschaeftsfallMitPositionen, steuerUeberschreibung: SteuerUeberschreibung): void {
		gf.geschaeftsfall.buchungen = [];
		calculateGeschaeftsfallWerte(gf, steuerUeberschreibung);
		direktBuchungen(gf, this.gattungenService.list$.value, this.konfigurationService.konfiguration$.value);
		this.data.calculateResult.geschaeftsfaelle = this.data.calculateResult.geschaeftsfaelle.map(g =>
			g === gf ? { ...g } : g
		);
		this.recalculateGeschaeftsfaelleZuUeberschreiben();
		this.changeDetectionRef.detectChanges();
	}
}
