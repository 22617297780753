import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SimpleDialogComponent, SimpleDialogContent } from './simple-dialog/simple-dialog.component';
import { SpinnerDialogComponent } from './spinner-dialog/spinner-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class SimpleDialogService {
	constructor(private readonly matDialog: MatDialog) {}

	public open(config: SimpleDialogContent): Observable<string> {
		return this.matDialog.open(SimpleDialogComponent, { data: config }).afterClosed() as Observable<string>;
	}

	public jaNein(message: string, title?: string): Observable<'Ja' | 'Nein'> {
		return this.open({
			message,
			title,
			buttonsLeft: [{ label: 'Nein', color: 'accent', icon: 'close', value: 'Nein' }],
			buttonsRight: [{ label: 'Ja', color: 'primary', icon: 'done', value: 'Ja' }],
		}) as Observable<'Ja' | 'Nein'>;
	}

	public showSpinner(message?: string): () => void {
		const ref = this.matDialog.open(SpinnerDialogComponent, { disableClose: true, data: message });
		return () => ref.close();
	}
}
