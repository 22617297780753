import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { bufferDebounceCount } from 'src/app/utils';
import { FinanzinstitutService } from '../../finanzinstitut/finanzinstitut.service';
import { Finanzinstitut, finanzinstitutMetadata } from '../../finanzinstitut/model';
import { StammdatenStatus } from '../../model';
import { GattungService } from '../gattung.service';
import { Gattung, gattungMetadata } from '../model';

@Component({
	selector: 'app-gattung-list',
	templateUrl: './gattung-list.component.html',
	styleUrls: ['./gattung-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungListComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	@Input() finanzinstitut?: Finanzinstitut;

	@Output() readonly selected$ = new EventEmitter<Gattung>();
	@Output() readonly doubleClick$ = new EventEmitter<Gattung>();

	public readonly stammdatenStatus = StammdatenStatus;
	public readonly metadata = gattungMetadata;
	public readonly finanzinstitutMetadata = finanzinstitutMetadata;
	public readonly dataSource = new MatTableDataSource<GattungListEntry>();
	public readonly displayedColumns = [
		'wkn',
		'isin',
		'wkz',
		'name',
		'kuponnummerFaelligkeit',
		'zahlbarkeitstag',
		'emittent',
		'status',
		'actions',
	];
	public readonly trackBy: TrackByFunction<GattungListEntry> = (index, item) => item.id;
	public readonly filterForm = new TypedForm<{
		wkn: string;
		isin: string;
		wkz: string;
		name: string;
		emittent: string;
		kuponnummer: string;
		zahlbarkeittagAnfang: Date | null;
		zahlbarkeittagEnde: Date | null;
		status: StammdatenStatus;
	}>({
		wkn: new UntypedFormControl(),
		isin: new UntypedFormControl(),
		wkz: new UntypedFormControl(),
		name: new UntypedFormControl(),
		emittent: new UntypedFormControl(),
		kuponnummer: new UntypedFormControl(),
		zahlbarkeittagAnfang: new UntypedFormControl(),
		zahlbarkeittagEnde: new UntypedFormControl(),
		status: new UntypedFormControl(),
	});
	public readonly isinFilterControl = this.filterForm.controls.isin as UntypedFormControl;

	public readonly itemClicked$ = new Subject<GattungListEntry>();

	constructor(
		private readonly service: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService
	) {
		super();

		this.registerSubscription(
			this.itemClicked$.pipe(bufferDebounceCount(400)).subscribe(({ item, count }) => {
				if (count === 1) {
					this.selected$.next(item);
				} else {
					this.doubleClick$.next(item);
				}
			})
		);

		const filterFormValues$ = this.filterForm.values$.pipe(
			debounceTime(200),
			map(v => ({
				...v,
				wkn: v.wkn?.toLocaleUpperCase() ?? '',
				isin: v.isin?.toLocaleUpperCase() ?? '',
				name: v.name?.toLocaleUpperCase() ?? '',
				emittent: v.emittent?.toLocaleUpperCase() ?? '',
				kuponnummer: v.kuponnummer?.toLocaleUpperCase() ?? '',
			}))
		);

		let filterIndex = 0;
		this.registerSubscription(
			filterFormValues$.subscribe(filterValues => {
				this.dataSource.filterPredicate = data => {
					if (filterValues.wkn) {
						if (!data.wknUpperCase.includes(filterValues.wkn)) return false;
					}

					if (filterValues.isin) {
						if (!data.isinUpperCase.includes(filterValues.isin)) return false;
					}

					if (filterValues.wkz) {
						const wkzBezeichnung = data.wkz
							? 'Mantel'.toLocaleUpperCase()
							: 'Kupon'.toLocaleUpperCase();
						if (!wkzBezeichnung.includes(filterValues.wkz.toLocaleUpperCase()))
							return false;
					}

					if (filterValues.name) {
						if (!data.nameUpperCase.includes(filterValues.name)) return false;
					}

					if (filterValues.emittent) {
						if (!data.institutBezeichnungUpperCase.includes(filterValues.emittent))
							return false;
					}

					if (filterValues.kuponnummer) {
						if (!data.kuponnummerUpperCase.includes(filterValues.kuponnummer))
							return false;
					}

					if (filterValues.zahlbarkeittagAnfang) {
						if (!data.zahlbarkeitstag) return false;
						if (data.zahlbarkeitstag < filterValues.zahlbarkeittagAnfang) return false;
					}

					if (filterValues.zahlbarkeittagEnde) {
						if (!data.zahlbarkeitstag) return false;
						if (data.zahlbarkeitstag > filterValues.zahlbarkeittagEnde) return false;
					}

					if (filterValues.status) {
						if (!data.status?.includes(filterValues.status)) return false;
					}

					return true;
				};
				filterIndex++;
				this.dataSource.filter = filterIndex.toFixed();
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}

		if (this.sort) {
			this.dataSource.sort = this.sort;
		}

		setTimeout(() =>
			this.registerSubscription(
				combineLatest([this.service.list$, this.finanzinstitutService.list$]).subscribe(
					([gattungen, finanzinstitute]) => {
						const finanzinstitutId = this.finanzinstitut?.id;
						if (finanzinstitutId) {
							gattungen = gattungen.filter(g => g.emittentId === finanzinstitutId);
						}

						this.dataSource.data = gattungen.map(g => {
							const institut = finanzinstitute.find(i => i.id === g.emittentId);
							return {
								...g,
								institutName: institut?.finanzinstitutsname || '',
								institutBezeichnung: institut?.displayName || '',
								institutBezeichnungUpperCase:
									institut?.displayName?.toLocaleUpperCase() || '',
								wknUpperCase: g.wkn?.toLocaleUpperCase() ?? '',
								isinUpperCase: g.isin?.toLocaleUpperCase() ?? '',
								nameUpperCase: g.gattungsbezeichnung?.toLocaleUpperCase() ?? '',
								isinUndZahlbarkeitstag: isinUndZahlbarkeitstag(g),
								kuponnummerUpperCase:
									g.kuponnummerFaelligkeit?.toLocaleUpperCase() || '',
							};
						});
					}
				)
			)
		);
	}

	open(gattung: Gattung): void {
		this.selected$.emit(gattung);
	}
}

const referenceTime = new Date(2200, 1, 1).getTime();

function isinUndZahlbarkeitstag(g: Gattung): string {
	if (!g.zahlbarkeitstag) return g.isin;

	return (
		g.isin + '-' + (referenceTime - g.zahlbarkeitstag.getTime()).toFixed(0).padStart(20, '0')
	);
}

interface GattungListEntry extends Gattung {
	institutName: string;
	wknUpperCase: string;
	isinUpperCase: string;
	nameUpperCase: string;
	institutBezeichnung: string;
	institutBezeichnungUpperCase: string;
	kuponnummerUpperCase: string;
	/** for sorting, ISIN + Zahlbarkeit absteigend */
	isinUndZahlbarkeitstag: string;
}
