import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { logger } from 'src/logger';

export abstract class DatabaseService<T> {
	public readonly lastLoadedOn$ = new BehaviorSubject<Date | null>(null);
	public readonly list$ = new BehaviorSubject<T[]>([]);
	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly count$ = this.list$.pipe(map(l => l.length));

	constructor(
		protected readonly http: HttpClient,
		protected readonly apiCollectionName: string,
	) { }

	protected onLoaded?: (val: T) => void;
	protected defaultSortCompare?: (t1: T, t2: T) => number;

	public loadAll$(): Observable<void> {
		const subject = new Subject<void>();

		this.isLoading$.next(true);
		this.http.get<T[]>(`/${this.apiCollectionName}`).subscribe({
			next: data => {
				const onLoaded = this.onLoaded;
				if (onLoaded) {
					data.forEach(v => onLoaded(v));
				}

				if (this.defaultSortCompare) {
					data.sort(this.defaultSortCompare);
				}

				this.list$.next(data);
				this.lastLoadedOn$.next(new Date());
				this.isLoading$.next(false);
				subject.next();
				subject.complete();
			},
			error: err => {
				logger.error(err);
				this.isLoading$.next(false);
				subject.error(err);
				if ('status' in err && err.status === 401) {
					console.log(err, 'Error 401 occured at loadAll$');
					document.location.href = '/';
				}
				subject.complete();
			},
		});

		return subject;
	}
}
