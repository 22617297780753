import { Component, ChangeDetectionStrategy, Inject, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { Einreichungsperson, einreichungspersonMetadata } from '../../model';

@Component({
	selector: 'app-einreichung-korrektur-popup',
	templateUrl: './einreichung-korrektur-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungKorrekturPopupComponent extends BaseComponent {
	public readonly metadata = einreichungspersonMetadata;

	readonly form = new TypedForm<EinreichungPersonKorrektur>({
		id: new UntypedFormControl(null),
		anrede: createFormControl(this.metadata.fields.anrede),
		vorname: createFormControl(this.metadata.fields.vorname),
		nachname: createFormControl(this.metadata.fields.nachname),
		adresse: createFormControl(this.metadata.fields.adresse),
		postleitzahl: createFormControl(this.metadata.fields.postleitzahl),
		ort: createFormControl(this.metadata.fields.ort),
		geburtsdatum: createFormControl(this.metadata.fields.geburtsdatum),
		ausweisnummer: createFormControl(this.metadata.fields.ausweisnummer),
		email: createFormControl(this.metadata.fields.email),
	});

	readonly cannotAccept$ = this.form.statusChanges.pipe(
		map(s => s !== 'VALID'),
		startWith(true)
	);

	constructor(
		@Inject(MAT_DIALOG_DATA) public einreichungsperson: Einreichungsperson,
		private readonly ref: MatDialogRef<EinreichungKorrekturPopupComponent>
	) {
		super();

		this.registerSubscription(this.ref.backdropClick().subscribe(() => this.tryCancel()));

		this.form.resetTyped(einreichungsperson);
	}

	@HostListener('window:keyup.enter')
	accept(): void {
		if (this.form.valid) {
			const korrigiertePerson: Einreichungsperson = {
				...this.einreichungsperson,
				anrede: this.form.value.anrede,
				vorname: this.form.value.vorname,
				nachname: this.form.value.nachname,
				email: this.form.value.email,
				postleitzahl: this.form.value.postleitzahl,
				ort: this.form.value.ort,
				adresse: this.form.value.adresse,
				geburtsdatum: this.form.value.geburtsdatum,
				ausweisnummer: this.form.value.ausweisnummer,
			};
			this.ref.close(korrigiertePerson);
		}
	}

	@HostListener('window:keyup.esc')
	tryCancel(): void {
		this.ref.close();
	}
}

export interface EinreichungPersonKorrektur {
	id: string;
	anrede: string;
	vorname: string;
	nachname: string;
	email: string;
	// adresszusatz: string;
	adresse: string; //Strasse
	postleitzahl: string;
	ort: string;
	// geburtsland: string | null;
	geburtsdatum: Date | null;
	ausweisnummer: string;
}
