import { Injectable } from '@angular/core';
import { saveAs as fileServerSaveAs } from 'file-saver';
import { logger } from 'src/logger';

/** Wraps FileSaver so that it can be mocked */
@Injectable({
	providedIn: 'root',
})
export class SaveService {
	public saveAs(blob: Blob, filename: string): void {
		logger.log('Downloading');
		fileServerSaveAs(blob, filename);
	}
}
