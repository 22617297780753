import { formatNumber } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { VorabpauschaleStatus } from '../bewegungsdaten/einreichung.service';
import {
	Einreichung,
	Geschaeftsfall,
	GeschaeftsfallMitPositionen,
	GeschaeftsfallStatus,
	Steuerart,
} from '../bewegungsdaten/model';
import { Konfiguration } from '../general/konfiguration/model';
import { EfaNumberPipe } from '../shared/pipe/efa-number.pipe';
import { Finanzinstitut } from '../stammdaten/finanzinstitut/model';
import { Gattung } from '../stammdaten/gattung/model';
import { round2, toExternalModel } from '../utils';
import { direktBuchungen, kontonummerKuerzen } from './buchungen';
import { plausibilitaet, PlausibilitaetContext } from './plausibilitaet';
import { calculateSteuern } from './steuern';

const geschaeftsfallTemplate: Geschaeftsfall = {
	id: '',
	isin: '',
	wkn: '',
	bruttobetrag: 0,
	nettobetrag: 0,
	cdcGebuehren: 0,
	cdcZinsgutschrift: 0,
	dekaGebuehren: 0,
	kapitalertragssteuer: 0,
	kirchensteuer: 0,
	solidaritaetszuschlag: 0,
	zinsabschlagsteuer: 0,
	kapitalertragssteuerCalculated: 0,
	kirchensteuerCalculated: 0,
	solidaritaetszuschlagCalculated: 0,
	zinsabschlagsteuerCalculated: 0,
	dokumenteVersendet: false,
	einreichungId: '',
	inkassobetrag: 0,
	istInkasso: false,
	istStorno: false,
	nummer: '',
	status: 'Vorschau' as GeschaeftsfallStatus,
	steuern: 0,
	zuVerrechnendeVorabpauschale: 0,
	land: '??',
	vorgangsnummer: '',
	positionen: [],
	buchungen: [],
};

export function calculateGeschaeftsfaelle(
	einreichung: Einreichung,
	gattungen: Gattung[],
	finanzinstitute: Finanzinstitut[],
	konfiguration: Konfiguration
): CalculateGeschaeftsfaelleResult {
	const messages: Message[] = [];
	const geschaeftsfaelleMap: { [key: string]: GeschaeftsfallMitPositionen; } = {};

	const addMessage = (level: 'info' | 'warning' | 'error', text: string) =>
		messages.push({ level, text });

	let lastNummer = 1;
	for (const position of einreichung.positionen.sort((p1, p2) =>
		Math.sign(p1.positionnummer - p2.positionnummer)
	)) {
		if (!position.gattungId) {
			addMessage('error', `Gattung fehlt auf der Position ${position.positionnummer}`);
			continue;
		}

		const gattung = gattungen.find(g => g.id === position.gattungId);
		if (!gattung) {
			addMessage(
				'error',
				`Gattung mit id ${position.gattungId} nicht gefunden für die Position ${position.positionnummer}`
			);
			continue;
		}

		const key = `${gattung.isin}#${gattung.wkz}#${gattung.land}#${gattung.istLiquidiert}`;
		let geschaeftsfallMitPositionen = geschaeftsfaelleMap[key];
		if (!geschaeftsfallMitPositionen) {
			const nummer = `${gattung.wkz ? 'M' : 'K'}-${einreichung.nummer}-${lastNummer}`;
			lastNummer++;

			let status: GeschaeftsfallStatus;
			if (gattung.land === 'LX') {
				status = GeschaeftsfallStatus.VorschauZumInkasso; // Luxemburg
			} else if (gattung.istInkasso) {
				status = GeschaeftsfallStatus.VorschauZumInkasso; // Inkasso-Pflicht
			} else if (!gattung.wkz) {
				status = GeschaeftsfallStatus.VorschauZurBuchung; // Kupons
			} else if (gattung.istLiquidiert) {
				status = GeschaeftsfallStatus.VorschauZurBuchung; // liquidierte Mantel
			} else {
				status = GeschaeftsfallStatus.VorschauZumUebertrag; // sonstige Mantel
			}

			geschaeftsfallMitPositionen = {
				einreichung,
				positionen: [],
				geschaeftsfall: {
					...geschaeftsfallTemplate,
					einreichungId: einreichung.id,
					nummer,
					status,
					land: gattung.land ?? '??',
					istInkasso: gattung.land === 'LX' || gattung.istInkasso,
					isin: gattung.isin,
				},
			};

			geschaeftsfaelleMap[key] = geschaeftsfallMitPositionen;
		}

		geschaeftsfallMitPositionen.positionen.push(position);
	}

	const geschaeftsfaelle = Object.values(geschaeftsfaelleMap);
	geschaeftsfaelle.forEach(gf => calculateSteuern(gf, gattungen, finanzinstitute));
	geschaeftsfaelle.forEach(gf => calculateGeschaeftsfallWerte(gf));
	geschaeftsfaelle.forEach(gf => direktBuchungen(gf, gattungen, konfiguration));

	const vorabpauschaleStatus = determineVorabpauschaleStatus(
		einreichung,
		geschaeftsfaelle,
		konfiguration
	);

	if (
		vorabpauschaleStatus.vorabpauschaleStatus ===
		VorabpauschaleStatus.DurchGeschaeftsfallAbgedeckt
	) {
		// neu berechnung summen nach umhängen der vorabpauschalsteuer von ausloesenden gf auf deckenden gf
		// buchungen sind bereits korrekt
		geschaeftsfaelle.forEach(gf => calculateGeschaeftsfallWerte(gf));
	}

	const context: PlausibilitaetContext = {
		einreichung,
		gattungen,
		addMessage,
		institute: finanzinstitute,
		geschaeftsfaelle,
	};

	plausibilitaet(context);

	return {
		messages,
		geschaeftsfaelle,
		...vorabpauschaleStatus,
	};
}

function determineVorabpauschaleStatus(
	einreichung: Einreichung,
	geschaeftsfaelle: GeschaeftsfallMitPositionen[],
	konfiguration: Konfiguration
): { vorabpauschaleStatus: VorabpauschaleStatus; vorabpauschaleComment: string; } {
	for (const geschaeftsfall of geschaeftsfaelle) {
		geschaeftsfall.geschaeftsfall.buchungen = geschaeftsfall.geschaeftsfall.buchungen.filter(
			b => !b.buchungsinhalt.startsWith('Vorabpauschale')
		);
	}

	const vorabpauschale = einreichung.vorabpauschale;
	if (!vorabpauschale || einreichung.einreicher) {
		let info = '';
		if (einreichung.einreicher) {
			info = ' (Einreicher ist Finanzinstitut)';
		}
		return {
			vorabpauschaleStatus: VorabpauschaleStatus.KeineFaellig,
			vorabpauschaleComment: 'Keine Vorpauschale fällig' + info,
		};
	}

	const vorabpauschaleSummen = einreichung.positionen.reduce(
		(pv, position) => ({
			kest:
				pv.kest +
				(position.steuern.find(s => s.steuerart === Steuerart.VorabpauschaleKESt)
					?.steuerbetrag ?? 0),
			kist:
				pv.kist +
				(position.steuern.find(s => s.steuerart === Steuerart.VorabpauschaleKiSt)
					?.steuerbetrag ?? 0),
			soli:
				pv.soli +
				(position.steuern.find(s => s.steuerart === Steuerart.VorabpauschaleSoli)
					?.steuerbetrag ?? 0),
		}),
		{
			kest: 0,
			kist: 0,
			soli: 0,
		}
	);

	let deckenderGeschaeftsfall = geschaeftsfaelle.find(
		g =>
			g.geschaeftsfall.nummer.startsWith('K') &&
			!g.geschaeftsfall.istInkasso &&
			g.geschaeftsfall.nettobetrag >= vorabpauschale
	);

	const bezugVomKontoVorabpauschale = !deckenderGeschaeftsfall; // kein deckender Geschäftsfall, also Geld vom Standardkonto abziehen

	if (!deckenderGeschaeftsfall) {
		deckenderGeschaeftsfall = geschaeftsfaelle.find(g =>
			g.positionen.some(p => p.vorabpauschale > 0)
		)!;
	}

	let nextBuchungsnummer =
		Math.max(
			0,
			...deckenderGeschaeftsfall.geschaeftsfall.buchungen.map(p => p.buchungsnummer)
		) + 1;
	let vorabpauschaleBuchungssatz: number;

	if (bezugVomKontoVorabpauschale) {
		vorabpauschaleBuchungssatz =
			Math.max(
				0,
				...deckenderGeschaeftsfall.geschaeftsfall.buchungen.map(b => b.buchungssatz)
			) + 1;
		deckenderGeschaeftsfall.geschaeftsfall.buchungen.push({
			betrag: -(
				vorabpauschaleSummen.kest +
				vorabpauschaleSummen.kist +
				vorabpauschaleSummen.soli
			),
			buchungsinhalt: 'Vorabpauschale (Abzug)',
			buchungsnummer: nextBuchungsnummer++,
			buchungssatz: vorabpauschaleBuchungssatz,
			kontonummer: kontonummerKuerzen(konfiguration.kontoVorabpauschale),
			primanotenNummer: '3447',
		});
	} else {
		vorabpauschaleBuchungssatz = 1;
		const auszahlungBuchung = deckenderGeschaeftsfall.geschaeftsfall.buchungen.find(b =>
			b.buchungsinhalt.startsWith('Auszahlung')
		);
		// EFA-3226 Reduce tax costs also for wiederanlageBuchung item
		const wiederanlageBuchung = deckenderGeschaeftsfall.geschaeftsfall.buchungen.find(b =>
			b.buchungsinhalt.startsWith("Wiederanlage")
		);

		var betrag =
			vorabpauschaleSummen.kest + vorabpauschaleSummen.kist + vorabpauschaleSummen.soli;
		if (auszahlungBuchung) auszahlungBuchung!.betrag -= betrag;
		if (wiederanlageBuchung) wiederanlageBuchung!.betrag -= betrag;

		const bemerkung =
			'In den Steuern sind ' +
			formatNumber(toExternalModel(betrag), 'de', '1.2-2') +
			' EUR Vorabpauschalsteuern enthalten.';
		deckenderGeschaeftsfall.geschaeftsfall.bemerkungen = deckenderGeschaeftsfall
			.geschaeftsfall.bemerkungen
			? deckenderGeschaeftsfall.geschaeftsfall.bemerkungen + ' ' + bemerkung
			: bemerkung;

		let ausloesendeGeschaeftsfaelle = geschaeftsfaelle.filter(g =>
			g.geschaeftsfall.nummer.startsWith('M')
		); //mehrere maentel möglich, werden zusammen an einem kupon verrechnet
		const bemerkungAusloesenderGeschaeftsfall = `Die Vorabpauschalsteuern wurde über den Geschäftsfall ${deckenderGeschaeftsfall.geschaeftsfall.nummer} verrechnet.`;
		if (ausloesendeGeschaeftsfaelle.length > 0) {
			for (const agf of ausloesendeGeschaeftsfaelle) {
				agf.geschaeftsfall.bemerkungen = agf.geschaeftsfall.bemerkungen
					? agf.geschaeftsfall.bemerkungen + ' ' + bemerkungAusloesenderGeschaeftsfall
					: bemerkungAusloesenderGeschaeftsfall;
			}
		}

		//Umhängen Vorabp. Steuern an deckenden Geschaeftsfall (EFA-2427)
		for (const agf of ausloesendeGeschaeftsfaelle) {
			for (const pos of agf.positionen) {
				const vorabpSteuern = pos.steuern.filter(s =>
					[
						Steuerart.VorabpauschaleKESt,
						Steuerart.VorabpauschaleKiSt,
						Steuerart.VorabpauschaleSoli,
					].includes(s.steuerart)
				);
				const notVorabpSteuern = pos.steuern.filter(
					s =>
						![
							Steuerart.VorabpauschaleKESt,
							Steuerart.VorabpauschaleKiSt,
							Steuerart.VorabpauschaleSoli,
						].includes(s.steuerart)
				);
				pos.steuern = notVorabpSteuern || [];
				for (const vas of vorabpSteuern) {
					const steuerNameKopie =
						vas.steuername + ' (aus ' + agf.geschaeftsfall.nummer + ')';
					deckenderGeschaeftsfall.positionen[0].steuern.push({
						...vas,
						steuername: steuerNameKopie,
					});
				}
			}
		}
	}

	deckenderGeschaeftsfall.geschaeftsfall.buchungen.push(
		{
			betrag: vorabpauschaleSummen.kest,
			buchungsinhalt: 'Vorabpauschale (KESt)',
			buchungsnummer: nextBuchungsnummer++,
			buchungssatz: vorabpauschaleBuchungssatz,
			kontonummer: kontonummerKuerzen(konfiguration.kontoKest),
			primanotenNummer: '3447',
		},
		{
			betrag: vorabpauschaleSummen.kist,
			buchungsinhalt: 'Vorabpauschale (KiSt)',
			buchungsnummer: nextBuchungsnummer++,
			buchungssatz: vorabpauschaleBuchungssatz,
			kontonummer: kontonummerKuerzen(konfiguration.kontoKist),
			primanotenNummer: '3447',
		},
		{
			betrag: vorabpauschaleSummen.soli,
			buchungsinhalt: 'Vorabpauschale (Soli)',
			buchungsnummer: nextBuchungsnummer++,
			buchungssatz: vorabpauschaleBuchungssatz,
			kontonummer: kontonummerKuerzen(konfiguration.kontoSoli),
			primanotenNummer: '3447',
		}
	);

	if (bezugVomKontoVorabpauschale) {
		return {
			vorabpauschaleStatus: VorabpauschaleStatus.Faelig,
			vorabpauschaleComment:
				'Die Vorabpauschale ist fällig und konnte durch keinen Geschäftsfall gedeckt werden.',
		};
	} else {
		return {
			vorabpauschaleStatus: VorabpauschaleStatus.DurchGeschaeftsfallAbgedeckt,
			vorabpauschaleComment: `Fällig, aber gedeckt durch den Geschäftsfall ${deckenderGeschaeftsfall.geschaeftsfall.nummer}`,
		};
	}
}

export function calculateGeschaeftsfallWerte(
	geschaeftsfallMitPositionen: GeschaeftsfallMitPositionen,
	steuerUeberschreibung?: SteuerUeberschreibung
): void {
	const { geschaeftsfall, positionen, einreichung } = geschaeftsfallMitPositionen;
	const isFinanzinstitutEinreichung = !!einreichung.einreicher;
	// geschaeftsfall.bruttobetrag = round2(sum(positionen, p => p.gesamtwert));
	geschaeftsfall.bruttobetrag = sum(positionen, p => round2(p.gesamtwert)); // EFA-2680 rounding issues
	const steuernSumme = positionen
		.flatMap(p => p.steuern)
		.reduce(
			(agg, steuer) => ({
				soli:
					agg.soli +
					(steuer.steuerart === Steuerart.Soli ||
						steuer.steuerart === Steuerart.VorabpauschaleSoli ||
						(isFinanzinstitutEinreichung &&
							steuer.steuerart === Steuerart.QuellensteuernSoli)
						? steuer.steuerbetrag
						: 0),
				kest:
					agg.kest +
					(steuer.steuerart === Steuerart.KESt ||
						steuer.steuerart === Steuerart.VorabpauschaleKESt ||
						(isFinanzinstitutEinreichung &&
							steuer.steuerart === Steuerart.QuellensteuerKESt)
						? steuer.steuerbetrag
						: 0),
				kist:
					agg.kist +
					(steuer.steuerart === Steuerart.KiSt ||
						steuer.steuerart === Steuerart.VorabpauschaleKiSt
						? steuer.steuerbetrag
						: 0),
				zast: agg.zast + (steuer.steuerart === Steuerart.ZASt ? steuer.steuerbetrag : 0),
			}),
			{ soli: 0, kest: 0, kist: 0, zast: 0 }
		);

	geschaeftsfall.solidaritaetszuschlagCalculated = steuernSumme.soli;
	geschaeftsfall.kapitalertragssteuerCalculated = steuernSumme.kest;
	geschaeftsfall.kirchensteuerCalculated = steuernSumme.kist;
	geschaeftsfall.zinsabschlagsteuerCalculated = steuernSumme.zast;

	if (steuerUeberschreibung) {
		geschaeftsfall.solidaritaetszuschlag = steuerUeberschreibung.solidaritaetszuschlag;
		geschaeftsfall.kapitalertragssteuer = steuerUeberschreibung.kapitalertragssteuer;
		geschaeftsfall.kirchensteuer = steuerUeberschreibung.kirchensteuer;
		geschaeftsfall.zinsabschlagsteuer = steuerUeberschreibung.zinsabschlagsteuer;
	} else {
		geschaeftsfall.solidaritaetszuschlag = geschaeftsfall.solidaritaetszuschlagCalculated;
		geschaeftsfall.kapitalertragssteuer = geschaeftsfall.kapitalertragssteuerCalculated;
		geschaeftsfall.kirchensteuer = geschaeftsfall.kirchensteuerCalculated;
		geschaeftsfall.zinsabschlagsteuer = geschaeftsfall.zinsabschlagsteuerCalculated;
	}

	geschaeftsfall.steuern =
		geschaeftsfall.solidaritaetszuschlag +
		geschaeftsfall.kapitalertragssteuer +
		geschaeftsfall.kirchensteuer +
		geschaeftsfall.zinsabschlagsteuer;

	if (geschaeftsfall.istInkasso) {
		geschaeftsfall.nettobetrag =
			(geschaeftsfall.inkassobetrag ?? 0) -
			geschaeftsfall.steuern -
			geschaeftsfall.dekaGebuehren;
	} else {
		geschaeftsfall.nettobetrag =
			geschaeftsfall.bruttobetrag +
			geschaeftsfall.cdcZinsgutschrift -
			geschaeftsfall.steuern;
	}
}

function sum<T>(list: T[], getter: (item: T) => number): number {
	return list.reduce((agg, item) => agg + getter(item), 0);
}

/** CalculateGeschaeftsfaelleResult wiederaufbauen, nachdem bereits gespeichert  */
export function buildGeschaeftsfaelleMitPositionen(
	geschaeftsfaelle: Geschaeftsfall[] | Error,
	einreichung: Einreichung
): CalculateGeschaeftsfaelleResult {
	if (geschaeftsfaelle instanceof Error || geschaeftsfaelle instanceof HttpErrorResponse) {
		return {
			geschaeftsfaelle: [],
			messages: [{ level: 'error', text: 'Laden von Geschäftsfällen fehlgeschlagen' }],
			vorabpauschaleComment: '',
			vorabpauschaleStatus: VorabpauschaleStatus.Unbekannt,
		} as CalculateGeschaeftsfaelleResult;
	}

	const result: CalculateGeschaeftsfaelleResult = {
		messages: [],
		geschaeftsfaelle: geschaeftsfaelle.map(gf => ({
			einreichung: einreichung,
			geschaeftsfall: gf,
			positionen: einreichung.positionen.filter(p => gf.positionen.includes(p.id)),
		})),
		vorabpauschaleComment: '',
		vorabpauschaleStatus: einreichung.vorabpauschaleStatus,
	};

	const positionenNichtInGeschaeftsfall = einreichung.positionen.filter(
		p => !result.geschaeftsfaelle.some(gf => gf.positionen.includes(p))
	);

	positionenNichtInGeschaeftsfall.forEach(p => {
		result.messages.push({
			level: 'error',
			text: `Position ${p.positionnummer} ist keinem Geschäftsfall zugeordnet!`,
		});
	});

	return result;
}

export interface SteuerUeberschreibung {
	solidaritaetszuschlag: number;
	kapitalertragssteuer: number;
	kirchensteuer: number;
	zinsabschlagsteuer: number;
}

export interface CalculateGeschaeftsfaelleResult {
	geschaeftsfaelle: GeschaeftsfallMitPositionen[];
	messages: Message[];
	vorabpauschaleStatus: VorabpauschaleStatus;
	vorabpauschaleComment: string;
}

export interface Message {
	level: 'info' | 'warning' | 'error';
	text: string;
}
