import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeschaeftsfallMitPositionen } from '../../model';

@Component({
	selector: 'app-geschaeftsfall-buchungen-popup',
	templateUrl: './geschaeftsfall-buchungen-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallBuchungenPopupComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: GeschaeftsfallMitPositionen) {}
}
