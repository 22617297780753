import { InjectionToken } from '@angular/core';
import { FieldDescription } from 'src/app/model';

export interface KuerzelBezeichnungReferenz {
	kuerzel: string;
	bezeichnung: string;
	extra?: string | null;
	status: 'Aktiv' | 'Inaktiv';
	geaendertVon: string;
	geaendertAm: Date;
}

export interface KuerzelBezeichnungUpdateModel {
	kuerzel: string;
	bezeichnung: string;
	extra?: string | number | null;
}

export interface KuerzelBezeichnungReferenzlistMetadata {
	apiCollectionName: string;
	label: string;
	kuerzel: FieldDescription;
	bezeichnung: FieldDescription;
	extra?: FieldDescription;
	/** TRUE = Diese Liste ist fix und darf vom Benutzer nicht bearbeitet werden */
	readonly?: boolean;
}

export type KuerzelBezeichnungReferenzMetadata = { [key: string]: KuerzelBezeichnungReferenzlistMetadata };

export const REFERENZLIST_METADATA = new InjectionToken<KuerzelBezeichnungReferenzMetadata>('REFERENZLIST_METADATA');
