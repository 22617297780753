<div class="mat-elevation-z2">
	<table mat-table [dataSource]="dataSource" class="gattung-table" matSort [trackBy]="trackBy">
		<ng-container matColumnDef="isin">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 15em"
				mat-sort-header="isinUndZahlbarkeitstag">
				<span>{{ metadata.fields.wkn.label }} / {{ metadata.fields.isin.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.gattung.wkn }} / {{ element.gattung.isin }}</td>
		</ng-container>

		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header="gattungsbezeichnung">
				<span>{{ metadata.fields.gattungsbezeichnung.label }}</span>
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.gattungsbezeichnung">
				{{ element.gattung.gattungsbezeichnung }}
			</td>
		</ng-container>

		<ng-container matColumnDef="zahlbarkeitstag">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				<span>{{ metadata.fields.zahlbarkeitstag.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element" class="column-zahlbarkeitstag">
				<span>{{ element.tag | date: 'dd.MM.yyyy' }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="emittent">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header="institutName">
				<span>{{ metadata.fields.emittentId.label }}</span>
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.institutBezeichnung">
				<button
					mat-icon-button
					color="primary"
					[routerLink]="finanzinstitutMetadata.routing.view!.url(element.gattung.emittentId)">
					<mat-icon>open_in_new</mat-icon>
				</button>
				<span>{{ element.institutBezeichnung }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 5em"></th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap">
				<button
					mat-icon-button
					[routerLink]="metadata.routing.view!.url(element.gattung.id)"
					matTooltip="Ansicht">
					<mat-icon>visibility</mat-icon>
				</button>
				<button
					mat-icon-button
					[routerLink]="metadata.routing.edit!.url(element.gattung.id)"
					[queryParams]="{ neuAusschuettung: element.tag.getTime() }"
					matTooltip="Anlegen"
					*requireRole="'standard'">
					<mat-icon>add</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container *matHeaderRowDef="displayedColumns">
			<tr mat-header-row></tr>
			<tr class="mat-header-row" [formGroup]="filterForm" class="table-filter-row">
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input matInput placeholder="Suche..." formControlName="isin" autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input matInput placeholder="Suche..." formControlName="name" autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col"></th>

				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input matInput placeholder="Suche..." formControlName="emittent" autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col" style="max-width: 5em"></th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let element; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="open(element.gattung.id, element.tag.getTime())"></tr>
	</table>

	<mat-paginator showFirstLastButtons></mat-paginator>
</div>
