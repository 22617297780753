import { Einreichung, Geschaeftsfall, GeschaeftsfallMitPositionen } from './../../../../bewegungsdaten/model';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Einreichungsposition, Einreichungspositionssteuer } from 'src/app/bewegungsdaten/model';
import { Gattung } from '../../model';
import { calculateSteuern } from 'src/app/berechnung/steuern';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from '../../gattung.service';
import { toInternalModel } from 'src/app/utils';

@Component({
	selector: 'app-gattung-steuer',
	templateUrl: './gattung-steuer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungSteuerComponent implements OnInit {
	@Input() gattung: Gattung;

	public summaryValues: SummaryValues = { summeSteuern: 0, summeQuest: 0, nettowert: 0 };

	private dummyPosition: Einreichungsposition = {
		gattungId: null,
		anzahl: 1,
		anteil: toInternalModel(1),
		faktor: toInternalModel(1),
		steuern: [] as Einreichungspositionssteuer[],
	} as Einreichungsposition;

	public geschaeftsfallMitPosition: GeschaeftsfallMitPositionen = {
		geschaeftsfall: { cdcZinsgutschrift: 0 } as Geschaeftsfall,
		positionen: [],
		einreichung: { kistamProzentsatz: 0 } as Einreichung,
	};

	constructor(
		private readonly gattungService: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService
	) {
		this.gattung = {} as Gattung;
	}

	ngOnInit() {
		if (!this.gattung) {
			throw new Error('Gattung must be set!');
		}
		this.geschaeftsfallMitPosition.positionen[0] = { ...this.dummyPosition, gattungId: this.gattung.id };
		calculateSteuern(
			this.geschaeftsfallMitPosition,
			this.gattungService.list$.value,
			this.finanzinstitutService.list$.value
		);
		// console.log('Steuern: ', this.geschaeftsfallMitPosition?.positionen[0].steuern);
		this.summaryValues.summeSteuern = Object.values(this.geschaeftsfallMitPosition?.positionen[0].steuern)
			.filter(s => s.steuerart.substring(0, 5) !== 'QueSt')
			.reduce((t, { steuerbetrag }) => t + steuerbetrag, 0);
		this.summaryValues.summeQuest = Object.values(this.geschaeftsfallMitPosition?.positionen[0].steuern)
			.filter(s => s.steuerart.substring(0, 5) === 'QueSt')
			.reduce((t, { steuerbetrag }) => t + steuerbetrag, 0);
		this.summaryValues.nettowert = (this.gattung.bruttowertEuro ?? 0) - (this.summaryValues.summeSteuern ?? 0);
	}
}

export interface SummaryValues {
	summeSteuern: number;
	summeQuest: number;
	nettowert: number;
}
