import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';

export interface ReportsHasChanged {
	hasChanged(): boolean;
}

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<ReportsHasChanged> {
	constructor(public readonly simpleDialog: SimpleDialogService) {}

	canDeactivate(
		component: ReportsHasChanged,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (!component.hasChanged()) return true;

		return this.simpleDialog
			.jaNein(
				'Sie haben ungespeicherte Änderungen. Wollen Sie trotzdem die Seite verlassen?',
				'Änderungen verwerfen?'
			)
			.pipe(map(result => result === 'Ja'));
	}
}
