<div class="flex-column">
    <mat-card class="flex-column-mat-card">
        <form (ngSubmit)="search()" [formGroup]="searchForm">
            <div class="page-header">
                <h1>Compliance Risikoklassenbewertung</h1>
                <button mat-raised-button color="accent" [disabled]="!selectedYear" (click)="downloadAsCsv()">
                    <mat-icon *ngIf="isLoading$ | async | not">file_download</mat-icon>
                    <mat-spinner *ngIf="isLoading$ | async" [diameter]="20"
                        style="display: inline-block; margin-right: 0.5em"></mat-spinner>
                    Als CSV herunterladen
                </button>
            </div>
            <div style="display: flex; gap: 1em; margin-bottom: 1em">
                <label style="margin-top: 2em">Suchkriterium:</label>
                <mat-form-field style="flex-basis: 22em">
                    <input matInput [matDatepicker]="yearPicker" placeholder="Wähle ein Jahr"
                        [value]="displaySelectedYear()">
                    <mat-datepicker #yearPicker startView="multi-year" [startAt]="startYear"
                        (yearSelected)="updateSelectedYear($event)"></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="yearPicker"></mat-datepicker-toggle>
                </mat-form-field>
            </div>
        </form>
    </mat-card>

    <div style="overflow: auto; max-height: 65vh;">
        <table mat-table [dataSource]="dataSource" style="width: 100%" id="ultimo-einreichungen-table" matSort>
            <ng-container matColumnDef="kunde">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" [matTooltip]="'Kunde'" mat-sort-header>
                    Kunde
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.kunde }}
                </td>
            </ng-container>
            <ng-container matColumnDef="anlagedatum">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Anlagedatum
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.anlagedatum | date : 'dd.MM.yyyy' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="gb">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    GB
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.gb }}
                </td>
            </ng-container>
            <ng-container matColumnDef="we">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    WE
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.we }}
                </td>
            </ng-container>
            <ng-container matColumnDef="filiale">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Filiale
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.filiale }}
                </td>
            </ng-container>
            <ng-container matColumnDef="gruppe">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Gruppe
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.gruppe }}
                </td>
            </ng-container>
            <ng-container matColumnDef="staatsangehoerigkeit">
                <th mat-header-cell *matHeaderCellDef scope="col" style="white-space: nowrap; width: 10em"
                    mat-sort-header>
                    Staats-<br>angehörigkeit
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center">
                    {{ element.staatsangehoerigkeit }}
                </td>
            </ng-container>
            <ng-container matColumnDef="adressland">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Adressland
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.adressland }}
                </td>
            </ng-container>
            <ng-container matColumnDef="branche">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Branche
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.branche }}
                </td>
            </ng-container>
            <ng-container matColumnDef="rechtsform">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Rechtsform
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.rechtsform }}
                </td>
            </ng-container>
            <ng-container matColumnDef="pep">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    PEP
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.pep }}
                </td>
            </ng-container>
            <ng-container matColumnDef="kundentyp">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Kundentyp
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.kundentyp }}
                </td>
            </ng-container>
            <ng-container matColumnDef="qualitativeAnalyse">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Qualitative <br>Analyse
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.qualitativeAnalyse }}
                </td>
            </ng-container>
            <ng-container matColumnDef="risikovererbung">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Risiko-<br>vererbung
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.risikovererbung }}
                </td>
            </ng-container>
            <ng-container matColumnDef="konto">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Konto
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.konto }}
                </td>
            </ng-container>
            <ng-container matColumnDef="gb2">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    GB
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.gb2 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="produkt">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Produkt
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.produkt }}
                </td>
            </ng-container>
            <ng-container matColumnDef="konto2">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Konto
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.konto2 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="kunde2">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Kunde
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.kunde2 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="relationstyp">
                <th mat-header-cell *matHeaderCellDef scope="col"
                    style="white-space: nowrap; width: 12em; padding-left: 1em" mat-sort-header>
                    Relationstyp
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden">
                    {{ element.relationstyp }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>

    <mat-paginator showFirstLastButtons></mat-paginator>
</div>