<mat-form-field appearance="fill" style="width: 100%; margin-bottom: 0px;" >
	<div matPrefix>
		<app-field-help-icon *ngIf="metadata" [helpText]="metadata.description"></app-field-help-icon>
	</div>
	<mat-label *ngIf="metadata">{{ metadata.label }}</mat-label>
	<mat-chip-grid #chipGrid>
		<mat-chip-row *ngFor="let value of values$ | async" (removed)="remove(value)" style="max-width: 8em; height: 1.4em; margin-bottom: -4px;" >
			{{ value }}
			<button matChipRemove>
				<mat-icon>cancel</mat-icon>
			</button>
		</mat-chip-row>
		<input
			placeholder="Wert eingeben und mit ENTER bestätigen"
			[formControl]="inputControl"
			[matChipInputFor]="chipGrid"
      		(focus)="onFocus()"
			(blur)="onBlur(); onTouched()"
			(matChipInputTokenEnd)="add($event)" />
		<mat-error>{{ inputControl | fieldErrorMessage$ | async }}</mat-error>
	</mat-chip-grid>
</mat-form-field>
