<mat-form-field style="width: 100%">
	<mat-label>{{ resolvedLabel }}</mat-label>
	<div matPrefix class="input-field-icons">
		<app-field-help-icon [helpText]="metadata?.description"></app-field-help-icon>
	</div>
	<input
		matInput
		[matDatepicker]="datePicker"
		[formControl]="dateControl"
		(focus)="htmlControl.select()"
		[placeholder]="resolvedPlaceholder"
		autocomplete="off"
		#htmlControl
		[required]="!!(isRequired$ | async)" />
	<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
	<mat-datepicker #datePicker></mat-datepicker>
	<mat-error>{{ dateControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
