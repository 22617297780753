import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { logger } from 'src/logger';
import {
	DisplayErrorDialogComponent,
	DisplayErrorDialogPayload,
} from './display-error-dialog/display-error-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class DisplayErrorService {
	constructor(private matDialog: MatDialog) {}

	showError(message: string, err?: Error, context?: any): void {
		if (err) {
			logger.error(err);
		}

		const payload: DisplayErrorDialogPayload = {
			message,
			context,
			error: err,
		};

		this.matDialog.open(DisplayErrorDialogComponent, { data: payload });
	}
}
