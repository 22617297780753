import { KistamUpdateResult } from './../einreichung.service';
import { Einreichung } from './../model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'kistamImportResultText',
})
export class KistamImportResultTextPipe implements PipeTransform {
	transform(value: KistamUpdateResult): string {
		if (value.success == KistamUpdateResult.SUCCESS_success)
			return `Einreichung Nummer ${value.number} erfolgreich importiert `;
		if (value.success == KistamUpdateResult.SUCCESS_saveFailed)
			return `Einreichung Nummer ${value.number} konnte nicht gespeichert werden!`;
		return '';
	}
}
