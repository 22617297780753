import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'app-steuer-ueberschreibung-anzeige',
	templateUrl: './steuer-ueberschreibung-anzeige.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SteuerUeberschreibungAnzeigeComponent {
	@Input() calculatedValue?: number;
	@Input() finalValue?: number;
}
