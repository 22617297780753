<div class="page-header">
	<h1>{{ metadata.plural }}</h1>
	<div>
		<button
			mat-raised-button
			[routerLink]="metadata.routing.edit!.url('new')"
			color="primary"
			*requireRole="'standard'">
			<mat-icon>add</mat-icon>
			Neue Gattung
		</button>
	</div>
</div>

<app-gattung-list (selected$)="openPopup($event)" (doubleClick$)="navigate($event)"></app-gattung-list>
