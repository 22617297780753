<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	Position {{ einreichungsposition.positionnummer }}
</h1>
<form mat-dialog-content style="height: 100%" [formGroup]="form">
	<div class="flex-column">
		<div class="flex-row">
			<app-gattung-choose
				[metadata]="metadata.fields.gattungId"
				formControlName="gattungId"
				#chooseGattung
				style="flex: 3"></app-gattung-choose>
		</div>

		<div class="flex-row"></div>

		<div class="flex-row">
			<app-text-input [metadata]="gattungMetadata.fields.emittentId" formControlName="emittent"></app-text-input>
			<app-readonly-field
				[metadata]="gattungMetadata.fields.zahlbarkeitstag"
				[value]="form.controls.zahlbarkeitstag.value | date: 'dd.MM.yyyy'"></app-readonly-field>
			<app-text-input
				[metadata]="gattungMetadata.fields.kuponnummerFaelligkeit"
				formControlName="kuponnummerFaelligkeit"></app-text-input>
			<app-numeric-input [metadata]="metadata.fields.faktor" formControlName="faktor"></app-numeric-input>
		</div>

		<div class="flex-row">
			<app-numeric-input
				[metadata]="metadata.fields.nominalwert"
				[symbol]="form.controls.nominalwaehrung.value"
				formControlName="nominalwert"></app-numeric-input>

			<app-numeric-input [metadata]="metadata.fields.anteil" formControlName="anteil"></app-numeric-input>

			<app-numeric-input [metadata]="metadata.fields.anzahl" formControlName="anzahl"></app-numeric-input>

			<app-numeric-input [decimals]="0" label="Nominal" formControlName="nominal"></app-numeric-input>

			<app-numeric-input
				[metadata]="metadata.fields.gesamtwert"
				formControlName="gesamtwert"
				symbol="EUR"></app-numeric-input>
		</div>

		<div class="flex-row">
			<app-string-array-input
				[metadata]="metadata.fields.stueckenummer"
				style="flex: 5"
				formControlName="stueckenummer"></app-string-array-input>
			<app-numeric-input
				[metadata]="metadata.fields.vorabpauschale"
				formControlName="vorabpauschale"
				symbol="EUR"></app-numeric-input>
		</div>
		<div>
			<div *ngIf="!stueckenummerIsRequired">
				<p> </p>
			</div>
			<div *ngIf="stueckenummerIsRequired">{{metadata.fields.stueckenummer.label}} ist bei einem Mantel auszufüllen!</div>
		</div>
		<div>
			<span *ngIf="dotation$ | async as dotation; else keineDotation" style="display: inline-flex; gap: 1em">
				<span>Dotation</span>
				<span>Anfangsanzahl: {{ dotation.anzahlAnfang | number: '1.0-0':'de' }}</span>
				<span>Eingelöst: {{ dotation.anzahlEingeloest | number: '1.0-0':'de' }}</span>
				<span>Vorläufig eingelöst: {{ dotation.anzahlVorlaeufigEingeloest | number: '1.0-0':'de' }}</span>
			</span>
		</div>
	</div>
</form>

<ng-template #keineDotation><span>Keine Dotation;</span></ng-template>

<div mat-dialog-actions style="display: flex">
	<button mat-raised-button mat-dialog-close color="accent" (click)="tryCancel()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" (click)="accept()" [disabled]="cannotAccept$ | async">
		<mat-icon>check</mat-icon>
		Akzeptieren
	</button>
</div>
