import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeschaeftsfallBuchungenPopupComponent } from './buchungen/geschaeftsfall-buchungen-popup/geschaeftsfall-buchungen-popup.component';
import { GeschaeftsfallBuchungenComponent } from './buchungen/geschaeftsfall-buchungen/geschaeftsfall-buchungen.component';
import { GeschaeftsfallMitPositionen } from './model';
import { SteuernPopupComponent } from './steuern/steuern-popup/steuern-popup.component';

@Injectable({
	providedIn: 'root',
})
export class BewegungsdatenQuickInfoService {
	constructor(private readonly matDialog: MatDialog) {}

	showTaxes(geschaeftsfallMitPositionen: GeschaeftsfallMitPositionen): void {
		this.matDialog.open(SteuernPopupComponent, {
			data: geschaeftsfallMitPositionen,
			minWidth: '80vw',
		});
	}

	showBuchungen(geschaeftsfallMitPositionen: GeschaeftsfallMitPositionen): void {
		this.matDialog.open(GeschaeftsfallBuchungenPopupComponent, {
			data: geschaeftsfallMitPositionen,
			minWidth: '80vw',
		});
	}
}
