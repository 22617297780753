import { forwardRef } from '@angular/core';
import { EntityMetadata, RequiredLevel } from '../model';
import { UnsavedChangesGuard } from '../shared/route-guards/unsaved-changes-guard';
import { Finanzinstitut } from '../stammdaten/finanzinstitut/model';
import { MAX_SINNVOLLER_BETRAG } from '../stammdaten/gattung/model';
import { Buchung } from './buchungen/model';
import { EinreichungEditPageComponent } from './einreichung-edit-page/einreichung-edit-page.component';
import { EinreichungListPageComponent } from './einreichung-list-page/einreichung-list-page.component';
import { EinreichungViewPageComponent } from './einreichung-view-page/einreichung-view-page.component';
import { VorabpauschaleStatus } from './einreichung.service';
import { GeschaeftsfallListPageComponent } from './geschaeftsfall/geschaeftsfall-list-page/geschaeftsfall-list-page.component';
import { GeschaeftsfallPageComponent } from './geschaeftsfall/geschaeftsfall-page/geschaeftsfall-page.component';
import { getPersonName } from './pipes/person-name.pipe';

export interface Einreichung {
	id: string;
	nummer: number;
	status: EinreichungStatus;
	geaendertVon: string;
	geaendertAm: Date | null;
	freigegebenVon: string;
	freigegebenAm: Date | null;
	dsgvoAenderungsgrund: string;
	vorabpauschaleEingegangen: boolean;
	couponShouldPay: boolean;
	oppositionstreffer: boolean | null;
	oppositionFreigeber: boolean | null;
	oppositionSystem: boolean | null;
	abbruchStornoGrund: string;
	eingangsdatum: Date | null;
	versandart: string;
	posteingangsdatum: Date | null;
	absender: string;
	sendungsnummer: string;
	empfaenger: string;
	betrag: number;
	waehrungStueck: string;
	anzahlUmschlaege: number;
	bemerkung: string;
	erbfall: boolean;
	/** id vom Finanzinsitut; wenn gesetzt Einreicher ist eine Bank */
	einreicher: string | null;
	depotNummer: string;
	depotInhaber: string;
	konto: string;
	kontoInhaber: string;
	dekaDepotVorhanden: boolean;
	vorabpauschale: number; // summe der vorabpauschale steuern
	vorabpauschaleGesamtsumme: number; // summe der vorabpauschale betraege
	kistam: string | null;
	kistamProzentsatz: number | null;
	schnellerfassungInfo: string;
	externeReferenz: string;
	neuerfassungZu: string;
	valutatag: Date | null;
	positionen: Einreichungsposition[];
	personen: Einreichungsperson[];
	kassenvereinsnummer: string | null;
	bic: string;
	dokumentenLink: string;
	vorabpauschaleStatus: VorabpauschaleStatus;
	religionszugehoerigkeit: Religionszugehoerigkeit;
}

export interface Einreichungsposition {
	id: string;
	positionnummer: number;
	gattungId: string | null;
	nominalwert: number;
	nominalwaehrung: string;
	anzahl: number;
	anteil: number;
	faktor: number;
	einzelwert: number;
	gesamtwert: number;
	vorabpauschale: number;
	kuponnummerFaelligkeit: string;
	stueckenummer: string[];
	steuern: Einreichungspositionssteuer[];
}

export interface Einreichungsperson {
	nummer: number;
	id: string;
	istVerein: boolean;
	istEinreicher: boolean;
	istWirtschaftlichBerechtigter: boolean;
	anrede: string;
	vorname: string;
	nachname: string;
	adresszusatz: string;
	adresse: string;
	postleitzahl: string;
	ort: string;
	bundesland: string | null;
	land: string | null;
	geburtsort: string;
	geburtsland: string | null;
	geburtsdatum: Date | null;
	staatsangehoerigkeit: string;
	ausweisart: string | null;
	ausweisnummer: string;
	ausweisAusgestelltAm: Date | null;
	ausweisAusgestelltVon: string;
	ausweisGueltigBis: Date | null;
	email: string;
	dekaDepotVorhanden: boolean;
	kycPruefungsnummer: string;
	kycPositiv: boolean;
	embargoPruefungsnummer:string;
	sapNummer: string;
	dsgvoAenderungsgrund: string;
	kycChecks: KycChecks[];
}

export interface KycChecks{
	id:number;
	pepPruefungsnummer:string;
	embargoPruefungsnummer:string;
	resultat:string;
	datum: Date;
	kommentar:string;
}


export enum Steuerart {
	QuellensteuerKESt = 'QueSt KESt',
	QuellensteuernSoli = 'QueSt Soli',
	KESt = 'KESt',
	ZASt = 'ZASt',
	KiSt = 'KiSt',
	Soli = 'Soli',
	VorabpauschaleKESt = 'Vorabp KESt',
	VorabpauschaleSoli = 'Vorabp Soli',
	VorabpauschaleKiSt = 'Vorabp KiSt',
}

export interface Einreichungspositionssteuer {
	steuerart: Steuerart;
	steuername: string;
	steuergrundbetrag: number;
	steuersatz: number;
	steuerbetrag: number;
}

export enum EinreichungStatus {
	Eingegangen = 'Eingegangen',
	InBearbeitung = 'In Bearbeitung',
	ZurFreigabe = 'Zur Freigabe',
	AutomatisierungFehler = 'Automatisierung Fehler',
	Freigegeben = 'Freigegeben',
	Rueckfrage = 'Rückfrage bei Einreicher',
	InternInKlaerung = 'Intern in Klärung',
	ZurFreigabeKorrigiert = 'Korrigiert - Zur Freigabe',
	ZurFreigabeNurDokumentation = 'Zur Freigabe (Dokumentation)',
	Abgeschlossen = 'Abgeschlossen',
	Abgebrochen = 'Abgebrochen',
	Storniert = 'Storniert',
	Teilgebucht = 'Teilgebucht',
	KistamAbfrage = 'KISTAM - Abfrage',
}

export enum Oppositionspruefung {
	Offen = 'Prüfung nicht durchgeführt',
	KeinTreffer = 'Kein Treffer',
	Treffer = 'Treffer',
}

export const EditableEinreichungStati = [
	EinreichungStatus.Eingegangen,
	EinreichungStatus.InBearbeitung,
	EinreichungStatus.KistamAbfrage,
	EinreichungStatus.ZurFreigabe,
	EinreichungStatus.Rueckfrage,
	EinreichungStatus.InternInKlaerung,
	EinreichungStatus.ZurFreigabeNurDokumentation,
	EinreichungStatus.AutomatisierungFehler,
];

export enum Religionszugehoerigkeit {
	NichtAngegeben = ' ',
	Evangelisch = 'E',
	Katholisch = 'K',
}

export const religionszugehoerigkeitBezeichnung: Record<Religionszugehoerigkeit, string> = {
	' ': 'nicht angegeben',
	E: 'evangelisch',
	K: 'katholisch',
};

export const einreichungMetadata: EntityMetadata<Einreichung> = {
	label: 'Einreichung',
	labelGender: 'w',
	apiCollectionName: 'einreichung',
	plural: 'Einreichungen',
	routing: {
		edit: {
			url: id => `/einreichung/${id}/edit`,
			component: forwardRef(() => EinreichungEditPageComponent),
			canDeactivate: [UnsavedChangesGuard],
		},
		view: {
			url: id => `/einreichung/${id}`,
			component: forwardRef(() => EinreichungViewPageComponent),
		},
		list: { url: '/einreichung', component: forwardRef(() => EinreichungListPageComponent) },
	},
	fields: {
		einreicher: {
			typ: 'reference',
			object: 'finanzinstitut',
			label: 'Einreicher (Institut)',
		},
		nummer: {
			label: 'Nummer',
			typ: 'number',
			description: 'Automatisch vergebene, eindeutige Nummer',
			decimals: 0,
			isEfaNumber: true,
		},
		eingangsdatum: {
			typ: 'datum',
			label: 'Eingangsdatum',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Eingangsdatum bei Deka',
		},
		posteingangsdatum: {
			label: 'Posteingangsdatum',
			typ: 'datum',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Posteingangsdatum bei Deka',
		},
		betrag: {
			label: 'Posteingang Nominal',
			typ: 'number',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Posteingang Nominal',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
		},
		waehrungStueck: {
			typ: 'text',
			label: 'Infos [Währung, Stück, ...]',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Posteingang Währung / Stück',
			maxLength: 50,
			defaultValue: 'Stück',
		},
		anzahlUmschlaege: {
			label: 'Anzahl Umschläge',
			typ: 'number',
			decimals: 0,
			minValue: 1,
			maxValue: 99999,
			isEfaNumber: false,
			defaultValue: 1,
			description: 'Anzahl der Umschläge',
		},
		absender: {
			typ: 'text',
			label: 'Absender',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Posteingang Absender (Privat oder Bank ) (ggf. mit Ortsinformation)',
			maxLength: 200,
		},
		versandart: {
			label: 'Versandart',
			typ: 'fixedlist',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Versandart (Hauspost, DHL, Bote, persönliche Übergabe)',
			values: ['Hauspost', 'DHL', 'Bote', 'persönliche Übergabe'],
		},
		sendungsnummer: {
			label: 'Sendungsnummer',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			description:
				'Posteingang Sendungsnummer (aktuell nur die letzten 4 Ziffern erfasst) ',
			maxLength: 200,
		},
		empfaenger: {
			typ: 'text',
			label: 'Posteingang Kommentar',
			description: 'Kommentare zum Posteingang',
			maxLength: 200,
		},
		konto: { typ: 'iban', label: 'IBAN', description: 'Eindeutige Kontoinformation (IBAN)' },
		kontoInhaber: {
			typ: 'text',
			label: 'Namen aller Kontoinhaber',
			description: 'Namen aller Kontoinhaber',
			maxLength: 200,
		},
		kistam: {
			typ: 'referenzlist',
			referenzlist: 'kistam',
			label: 'KiStam',
			description: 'KiStam - Gilt für den Wirtschaftlich Berechtigten',
		},
		kistamProzentsatz: {
			label: 'KiStam Steuersatz',
			typ: 'number',
			description: 'KiStam Steuersatz - Gilt für den Wirtschaftlich Berechtigten',
			minValue: 0,
			maxValue: 1,
			decimals: 6,
			isEfaNumber: true,
		},
		abbruchStornoGrund: {
			typ: 'text',
			label: 'Grund für Abbruch / Storno',
			description: 'Abbruch / Storno-Grund',
			maxLength: 2000,
		},
		externeReferenz: {
			typ: 'text',
			label: 'Externe Referenz',
			description: 'Referenzinformationen von Fremdbanken (incl. Sendungsnummer)',
			maxLength: 200,
		},
		neuerfassungZu: {
			label: 'Neuerfassung zu',
			typ: 'number',
			isEfaNumber: true,
			description: 'Referenz zus stornierten Einreichung',
		},
		valutatag: { label: 'Valutatag', typ: 'datum', description: 'Valutatag' },
		vorabpauschale: {
			label: 'Summe Steuern auf Vorabpauschale',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Summe Steuern auf Vorabpauschale',
		},
		vorabpauschaleGesamtsumme: {
			label: 'Summe Beträge Vorabpauschale',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Summe Beträge Vorabpauschale',
		},
		erbfall: {
			label: '"Erbengemeinschaft"',
			typ: 'boolean',
			description:
				'Identifiziert Einreichung als "Erbengemeinschaft". Wird gesetzt, wenn mehr als ein wirtschaftlich Berechtigter erfasst wurde.',
		},
		vorabpauschaleEingegangen: { label: 'Vorabpauschale eingegangen', typ: 'boolean' },
		dekaDepotVorhanden: { label: 'DekaDepot Vorhanden', typ: 'boolean' },
		couponShouldPay: { label: 'Kupons werden ausbezahlt', typ: 'boolean' },
		depotNummer: {
			typ: 'text',
			label: 'Depotnummer',
			description: 'Depotnummer',
			maxLength: 50,
		},
		depotInhaber: {
			typ: 'text',
			label: 'Namen aller Depotinhaber',
			description: 'Namen aller Depotinhaber',
			maxLength: 200,
		},
		bemerkung: {
			typ: 'text',
			label: 'Bemerkung',
			description:
				'Allgemeines internes Bemerkungsfeld zur Erfassung der Einreichungs-Besonderheiten.',
			maxLength: 2000,
		},
		positionen: { label: 'Positionen', typ: 'positionen' },
		personen: { label: 'Personen', typ: 'personen' },
		status: { label: 'Status', typ: 'backend-text' },
		dsgvoAenderungsgrund: {
			label: 'DSGVO Änderungsgrund',
			description: 'DSGVO Änderungsgrund',
			typ: 'text',
			maxLength: 2000,
		},
		freigegebenAm: { label: 'Freigegeben am', typ: 'backend-datum' },
		freigegebenVon: { label: 'Freigegeben von', typ: 'backend-text' },
		geaendertAm: { label: 'Geändert am', typ: 'backend-datum' },
		geaendertVon: { label: 'Geändert von', typ: 'backend-text' },
		oppositionstreffer: {
			label: 'Oppositionsprüfung',
			typ: 'fixedlist',
			values: [
				{ value: null, label: Oppositionspruefung.Offen },
				{ value: false, label: Oppositionspruefung.KeinTreffer },
				{ value: true, label: Oppositionspruefung.Treffer },
			],
		},
		oppositionFreigeber: {
			label: 'Oppositionsprüfung (Freigeber)',
			typ: 'fixedlist',
			values: [
				{ value: null, label: Oppositionspruefung.Offen },
				{ value: false, label: Oppositionspruefung.KeinTreffer },
				{ value: true, label: Oppositionspruefung.Treffer },
			],
		},
		oppositionSystem: {
			label: 'Oppositionsprüfung (System)',
			typ: 'fixedlist',
			values: [
				{ value: null, label: Oppositionspruefung.Offen },
				{ value: false, label: Oppositionspruefung.KeinTreffer },
				{ value: true, label: Oppositionspruefung.Treffer },
			],
		},
		schnellerfassungInfo: { label: 'schnellerfassungInfo', typ: 'text', maxLength: 2000 },
		kassenvereinsnummer: {
			label: 'Kassenvereinsnummer Fremddepot',
			typ: 'text',
			description: 'Kassenvereinsnummer Fremddepot',
			maxLength: 10,
		},
		bic: {
			label: 'BIC',
			typ: 'text',
			description: 'BIC von der Bank für SEPA',
			minLength: 8,
			maxLength: 11,
		},
		dokumentenLink: {
			typ: 'text',
			label: 'Dokumentenlink',
			description: 'Link auf Dokumentenmanagementsystem',
			maxLength: 200,
		},
		vorabpauschaleStatus: {
			typ: 'backend-text',
			label: 'Status der Vorabpauschale',
		},
		religionszugehoerigkeit: {
			label: 'Religionszugehörigkeit (2009 - 2014)',
			typ: 'fixedlist',
			values: Object.entries(religionszugehoerigkeitBezeichnung).map(([value, label]) => ({
				value,
				label,
			})),
		},
	},
};

export const einreichungspositionMetadata: EntityMetadata<Einreichungsposition> = {
	label: 'Einreichungsposition',
	labelGender: 'w',
	apiCollectionName: '',
	plural: 'Positionen',
	routing: {},
	fields: {
		anzahl: {
			label: 'Anzahl',
			typ: 'number',
			requiredLevel: RequiredLevel.RequiredToSave,
			minValue: 1,
			maxValue: 1000 * 1000,
			decimals: 0,
			isEfaNumber: false,
		},
		einzelwert: {
			label: 'Einzelwert',
			typ: 'number',
			requiredLevel: RequiredLevel.RequiredToSave,
			minValue: 0,
			isEfaNumber: true,
			maxValue: MAX_SINNVOLLER_BETRAG,
		},
		nominalwert: {
			label: 'Anteilswert',
			typ: 'number',
			decimals: 6,
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			isEfaNumber: true,
		},
		gesamtwert: { label: 'Gesamtwert', typ: 'number', decimals: 6, isEfaNumber: true },
		gattungId: {
			label: 'Gattung',
			typ: 'reference',
			object: 'gattung',
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		positionnummer: { label: 'Nummer', typ: 'number', decimals: 0, isEfaNumber: true },
		stueckenummer: { label: 'Stückenummer', typ: 'stueckenummer' },
		anteil: {
			label: 'Anteil',
			typ: 'number',
			decimals: 0,
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			requiredLevel: RequiredLevel.RequiredToSave,
			isEfaNumber: true,
		},
		faktor: {
			label: 'Faktor',
			typ: 'number',
			decimals: 6,
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			requiredLevel: RequiredLevel.RequiredToSave,
			defaultValue: 1,
			isEfaNumber: true,
		},
		vorabpauschale: {
			label: 'Vorabpauschale',
			typ: 'number',
			decimals: 2,
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			isEfaNumber: true,
		},
		nominalwaehrung: {
			label: 'Nominalwährung',
			typ: 'backend-text',
		},
		steuern: {
			label: 'Steuern',
			typ: 'steuern',
		},
		kuponnummerFaelligkeit: {
			typ: 'text',
			label: 'Kuponnummer/Fälligkeit',
			description: 'Kuponnummer/Fälligkeit',
			maxLength: 20,
		},
	},
};

export const einreichungspersonMetadata: EntityMetadata<Einreichungsperson> = {
	apiCollectionName: '',
	label: 'Person',
	labelGender: 'w',
	plural: 'Personen',
	routing: {},
	fields: {
		adresse: {
			typ: 'text',
			maxLength: 200,
			label: 'Straße und Hausnummer',
			description: 'Straße und Hausnummer',
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		adresszusatz: {
			typ: 'text',
			maxLength: 200,
			label: 'Adresszusatz',
			description: 'Adresszusatz',
		},
		postleitzahl: {
			typ: 'text',
			maxLength: 10,
			description: 'Postleitzahl',
			label: 'Postleitzahl',
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		anrede: {
			typ: 'text',
			maxLength: 50,
			description: 'Anrede, z.B. Herr Doktor',
			label: 'Anrede',
		},
		vorname: {
			typ: 'text',
			maxLength: 200,
			description: 'Vorname',
			label: 'Vorname',
			requiredLevel: RequiredLevel.RequiredToSave,
			isName: true,
		},
		nachname: {
			typ: 'text',
			maxLength: 200,
			description: 'Nachname',
			label: 'Nachname',
			requiredLevel: RequiredLevel.RequiredToSave,
			isName: true,
		},
		ort: {
			typ: 'text',
			maxLength: 200,
			description: 'Ort',
			label: 'Ort',
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		ausweisart: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'referenzlist',
			referenzlist: 'ausweisart',
			label: 'Ausweisart',
		},
		ausweisAusgestelltAm: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'datum',
			label: 'Ausgestellt am',
		},
		ausweisGueltigBis: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'datum',
			label: 'Gültig bis',
		},
		ausweisAusgestelltVon: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'datum',
			label: 'Ausgestellt von',
		},
		ausweisnummer: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'text',
			label: 'Ausweisnummer',
			maxLength: 50,
		},
		bundesland: { typ: 'referenzlist', label: 'Bundesland', referenzlist: 'bundesland' },
		land: {
			typ: 'referenzlist',
			label: 'Land',
			referenzlist: 'land',
			requiredLevel: RequiredLevel.RequiredToSave,
			defaultValue: 'DE',
		},
		geburtsdatum: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'datum',
			label: 'Geburtsdatum',
		},
		geburtsland: { typ: 'referenzlist', label: 'Geburtsland', referenzlist: 'land' },
		geburtsort: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'text',
			label: 'Geburtsort',
			maxLength: 200,
		},
		email: { typ: 'text', label: 'Email', maxLength: 200 },
		dekaDepotVorhanden: { typ: 'boolean', label: 'Ist Deka Depot vorhanden' },
		kycPositiv: { typ: 'boolean', label: 'Kein Handlungsbedarf' },
		kycPruefungsnummer: { typ: 'text', label: 'PEP Prüfungsnummer', maxLength: 100 },
		embargoPruefungsnummer: { typ: 'text', label: 'Embargo Prüfungsnummer', maxLength: 100 },
		dsgvoAenderungsgrund: { typ: 'text', maxLength: 2000, label: 'DSGVO Änderungsgrund' },
		istEinreicher: { typ: 'boolean', label: 'Ist Einreicher', defaultValue: true },
		istVerein: { typ: 'boolean', label: 'Ist Verein', defaultValue: false },
		istWirtschaftlichBerechtigter: {
			typ: 'boolean',
			label: 'Ist wirtschaftlich berechtigt',
		},
		staatsangehoerigkeit: {
			requiredLevel: RequiredLevel.RequiredToSave,
			typ: 'referenzlist',
			label: 'Staatsangehörigkeit',
			referenzlist: 'land',
		},
		sapNummer: { typ: 'text', maxLength: 20, label: 'SAP Nummer' },
		kycChecks:{typ:"kycChecks",label:"KYC-Checks" },
		nummer: {typ:'number',label:"Nummer", isEfaNumber:true }
	},
};

export const einreichungspersonKycChecksMetadata: EntityMetadata<KycChecks> = {
	apiCollectionName: '',
	label: 'KYC-Check',
	labelGender: 'm',
	plural: 'KYC-Checks',
	routing: {},
	fields: {
		embargoPruefungsnummer: {
			typ: 'text',
			maxLength: 200,
			description: 'Embargo Pruefungsnummer',
			label: 'Embargo Pruefungsnummer',
			requiredLevel: RequiredLevel.NotRequired,
		},
		datum: {
			typ: 'datum',
			description: 'Datum	der KYC-Prüfung',
			label: 'Datum',
			requiredLevel: RequiredLevel.NotRequired,
		},
		pepPruefungsnummer: {
			typ: 'text',
			maxLength: 200,
			description: 'PEP Pruefungsnummer',
			label: 'PEP Pruefungsnummer',
			requiredLevel: RequiredLevel.NotRequired,
		},
		resultat: {
			typ: 'fixedlist',
			description: 'Resultat der KYC-Prüfung',
			label: 'Resultat',
			requiredLevel: RequiredLevel.NotRequired,
			values: ['GO', 'INFO', 'HOLD', 'ERROR'],
		},
		kommentar: {
			typ: 'text',
			maxLength: 200,
			description: 'Kommentar',
			label: 'Kommentar',
			requiredLevel: RequiredLevel.NotRequired,
		}
	}}

export function getEinreicherName(e: Einreichung, institute: Finanzinstitut[]): string {
	if (e.einreicher) {
		return institute.find(i => i.id === e.einreicher)?.displayName ?? '';
	} else {
		return e.personen
			.filter(p => p.istEinreicher)
			.map(p => getPersonName(p))
			.join(', ');
	}
}

export interface Geschaeftsfall {
	id: string;
	nummer: string;
	isin: string;
	wkn: string;
	einreichungId: string;
	status: GeschaeftsfallStatus;
	istInkasso: boolean;
	istStorno: boolean;
	vorgangsnummer: string;
	bruttobetrag: number;
	steuern: number;
	nettobetrag: number;
	inkassobetrag: number;
	cdcGebuehren: number;
	cdcZinsgutschrift: number;
	dekaGebuehren: number;
	bemerkungen?: string;
	abbruchStornoGrund?: string;
	dokumenteVersendet: boolean;
	land: string;
	inkassoVersanddatum?: Date | null;
	zuVerrechnendeVorabpauschale: number;
	erstelltAm?: Date | null;
	geaendertAm?: Date | null;
	kapitalertragssteuer: number;
	kapitalertragssteuerCalculated: number;
	zinsabschlagsteuer: number;
	zinsabschlagsteuerCalculated: number;
	solidaritaetszuschlag: number;
	solidaritaetszuschlagCalculated: number;
	kirchensteuer: number;
	kirchensteuerCalculated: number;
	/** UUIDs von Positionen */
	positionen: string[];
	buchungen: Buchung[];
}

export const geschaeftsfallMetadata: EntityMetadata<Geschaeftsfall> = {
	apiCollectionName: 'geschaeftsfall',
	label: 'Geschäftsfall',
	plural: 'Geschäftsfälle',
	labelGender: 'm',
	routing: {
		list: { url: '/geschaeftsfaelle', component: GeschaeftsfallListPageComponent },
		view: { url: id => `/geschaeftsfaelle/${id}`, component: GeschaeftsfallPageComponent },
	},
	fields: {
		nummer: {
			label: 'Geschäftsfallnummer',
			typ: 'text',
			maxLength: 15,
			description:
				'Eindeutige Geschäftsfallnummer im Format "M-" für Mäntel und K-" für Kupons "-[Einreichungsnummer]-[FortlaufendeNummer]"; beim Stornogeschäftsfall zusätzlich noch "-S"',
		},
		einreichungId: { label: 'Einreichung', typ: 'reference', object: 'einreichung' },
		isin: { label: 'ISIN', typ: 'text', exactLength: 12 },
		wkn: { label: 'WKN', typ: 'text', exactLength: 6 },
		status: { label: 'Status', typ: 'backend-text' },
		istInkasso: {
			label: 'Inkasso',
			typ: 'boolean',
			description: 'Kennzeichen ob Geschäftsfall einem Inkassoprozess unterliegt',
		},
		istStorno: { label: 'Storno', typ: 'boolean' },
		vorgangsnummer: {
			label: 'Vorgangsnr.',
			typ: 'text',
			maxLength: 20,
			description: 'Vorgangsnummer von Anteilsgeschäft',
		},
		bruttobetrag: {
			label: 'Bruttobetrag',
			typ: 'number',
			description: 'Errechneter Bruttobetrag aus angehängten Einreichungspositionen',
			isEfaNumber: true,
		},
		steuern: {
			label: 'Steuern',
			typ: 'number',
			description: 'Gesamtsteuerbetrag',
			isEfaNumber: true,
		},
		nettobetrag: { label: 'Nettobetrag', typ: 'number', isEfaNumber: true },
		inkassobetrag: {
			label: 'Inkassobetrag',
			typ: 'number',
			description: 'Eingegangener Betrag',
			isEfaNumber: true,
			minValue: 0,
			decimals: 2,
		},
		cdcGebuehren: {
			label: 'CDC Gebühren',
			typ: 'number',
			description: 'Von CDC einbehaltene Gebühren',
			isEfaNumber: true,
			minValue: 0,
			decimals: 2,
		},
		cdcZinsgutschrift: {
			label: 'Zinsgutschrift CDC / auf deutsche Mänteln',
			typ: 'number',
			isEfaNumber: true,
			minValue: 0,
			decimals: 2,
			description:
				'Von CDC errechnete und ausbezahlte Zinsgutschriften, bzw. Zinsgutschrift auf deutsche Mänteln. Auf den Bruttobetrag aufzuschlagen und im Rahmen der Steuerermittlung zu berücksichtigen.',
		},
		dekaGebuehren: {
			label: 'Deka Gebühren',
			typ: 'number',
			description: 'Errechnete Deka Gebühren',
			isEfaNumber: true,
			minValue: 0,
			decimals: 2,
		},
		kapitalertragssteuer: {
			label: 'Kapitalertragssteuer',
			typ: 'number',
			isEfaNumber: true,
			decimals: 2,
			description: 'Finale Kapitalertragssteuer',
		},
		kapitalertragssteuerCalculated: {
			label: 'Kapitalertragssteuer (Ursprungswert)',
			typ: 'number',
			isEfaNumber: true,
			decimals: 2,
			description: 'Errechnete Kapitalertragssteuer',
		},
		zinsabschlagsteuer: {
			label: 'Zinsabschlagsteuer',
			typ: 'number',
			isEfaNumber: true,
			decimals: 2,
			description: 'Finale Zinsabschlagsteuer',
		},
		zinsabschlagsteuerCalculated: {
			label: 'Zinsabschlagsteuer (Ursprungswert)',
			typ: 'number',
			isEfaNumber: true,
			decimals: 2,
			description: 'Errechnete Zinsabschlagsteuer',
		},
		solidaritaetszuschlag: {
			label: 'Solidaritätszuschlag',
			typ: 'number',
			isEfaNumber: true,
			decimals: 2,
			description: 'Finale Solidaritätszuschlag',
		},
		solidaritaetszuschlagCalculated: {
			label: 'Solidaritätszuschlag (Ursprungswert)',
			typ: 'number',
			isEfaNumber: true,
			decimals: 2,
			description: 'Errechnete Solidaritätszuschlag',
		},
		kirchensteuer: {
			label: 'Kirchensteuer',
			typ: 'number',
			description: 'Finale Kirchensteuer',
			isEfaNumber: true,
			decimals: 2,
		},
		kirchensteuerCalculated: {
			label: 'Kirchensteuer (Ursprungswert)',
			typ: 'number',
			description: 'Errechnete Kirchensteuer',
			isEfaNumber: true,
			decimals: 2,
		},
		bemerkungen: { label: 'Bemerkungen', typ: 'text', maxLength: 2000 },
		abbruchStornoGrund: { label: 'Grund Abbruch/Storno', typ: 'text', maxLength: 2000 },
		dokumenteVersendet: { label: 'Dokumente versendet', typ: 'boolean' },
		land: { label: 'Land', typ: 'referenzlist', referenzlist: 'land' },
		inkassoVersanddatum: { label: 'Inkasso Versanddatum', typ: 'backend-datum' },
		zuVerrechnendeVorabpauschale: {
			label: 'Zu verrechnende Vorabpauschale',
			typ: 'number',
			isEfaNumber: true,
			minValue: 0,
			decimals: 2,
		},
		positionen: { label: 'Positionen', typ: 'positionen' },
		buchungen: { label: 'Buchungen', typ: 'buchungen' },
		erstelltAm: { label: 'Erstelldatum', typ: 'backend-datum' },
		geaendertAm: { label: 'Änderungsdatum', typ: 'backend-datum' },
	},
};

export interface GeschaeftsfallMitPositionen {
	geschaeftsfall: Geschaeftsfall;
	positionen: Einreichungsposition[];
	einreichung: Einreichung;
}

export enum GeschaeftsfallStatus {
	VorschauZumUebertrag = 'Vorschau - Zum Übertrag ins Depot',
	VorschauZurBuchung = 'Vorschau - Zur Buchung',
	VorschauZumInkasso = 'Vorschau - Zum Inkasso',
	Neu = 'Neu',
	ZumUebertrag = 'Zum Übertrag ins Depot',
	ZumInkasso = 'Zum Inkasso',
	InkassoAngefordert = 'Inkasso angefordert',
	// ZurBerechnung = 'Zur Berechnung',
	ZurBuchung = 'Zur Buchung',
	NeuanlageImDepot = 'Neuanlage im Depot',
	ZurAuszahlung = 'Zur Auszahlung',
	ZumDokumentenversand = 'Zum Dokumentenversand',
	Abgeschlossen = 'Abgeschlossen',
	Abgebrochen = 'Abgebrochen',
	StorniertZurBuchung = 'Storniert - Zur Buchung',
	Storniert = 'Storniert',
	VorabpauschaleZurBuchung = 'Zur Buchung - Vorabpauschale',
	BuchungBlockiert = 'Buchung Blockiert',
	ZumUebertragInsDepotBlockiert = 'Zum Übertrag ins Depot - Blockiert',
}

export type GeschaeftsfallListStatusFilter =
	| 'Zum Übertrag ins Depot'
	| 'Zum Inkasso'
	| 'Inkasso angefordert'
	| 'Zum Dokumentenversand'
	| 'Zur Buchung'
	| 'Blockiert';

const filterStatusMapping: Record<GeschaeftsfallListStatusFilter, GeschaeftsfallStatus[]> = {
	'Zum Übertrag ins Depot': [GeschaeftsfallStatus.ZumUebertrag],
	'Zum Inkasso': [GeschaeftsfallStatus.ZumInkasso],
	'Inkasso angefordert': [GeschaeftsfallStatus.InkassoAngefordert],
	'Zum Dokumentenversand': [GeschaeftsfallStatus.ZumDokumentenversand],
	'Zur Buchung': [
		GeschaeftsfallStatus.ZurBuchung,
		GeschaeftsfallStatus.StorniertZurBuchung,
		GeschaeftsfallStatus.VorabpauschaleZurBuchung,
	],
	'Blockiert': [
		GeschaeftsfallStatus.BuchungBlockiert,
		GeschaeftsfallStatus.ZumUebertragInsDepotBlockiert,
	],
};

//This list defines which gf statuses are not permitted for a csv export. 
export const anteilsscheinGeschaeftCsvBlackList: string[] = [GeschaeftsfallStatus.ZumUebertragInsDepotBlockiert]

//This list defines which gf statuses are allowed to show kyc checks at gf. 
export const showKycChecksGfWhiteList: string[] = [
	GeschaeftsfallStatus.InkassoAngefordert,
	GeschaeftsfallStatus.BuchungBlockiert,
	GeschaeftsfallStatus.ZurBuchung,
	GeschaeftsfallStatus.ZumUebertrag,
	GeschaeftsfallStatus.ZumUebertragInsDepotBlockiert,
]


export function doesGFMatchStatusFilter(
	geschaeftsfall: Geschaeftsfall,
	filter: GeschaeftsfallListStatusFilter
): boolean {
	const wantedStatus = filterStatusMapping[filter];
	return wantedStatus && wantedStatus.includes(geschaeftsfall.status);
}

export interface StatusHistorieEintrag {
	einreichungsnummer: string;
	geschaeftsfallnummer: string | null;
	statusVorher: EinreichungStatus | null;
	statusNachher: EinreichungStatus;
	geaendertVon: string;
	geaendertAm: Date;
}

export interface VorabpauschaleSteuersummen {
	kestSumme: number;
	kistSumme: number;
	soliSumme: number;
}

export function calculateVorabpauschaleSteuersummen(
	e: Einreichung | undefined
): VorabpauschaleSteuersummen {
	let vorabpauschaleKest = 0;
	let vorabpauschaleSoli = 0;
	let vorabpauschaleKiSt = 0;

	if (!!e && e.positionen) {
		for (const steuer of e.positionen.flatMap(p => p.steuern)) {
			switch (steuer.steuerart) {
				case Steuerart.VorabpauschaleKESt:
					vorabpauschaleKest += steuer.steuerbetrag;
					break;
				case Steuerart.VorabpauschaleSoli:
					vorabpauschaleSoli += steuer.steuerbetrag;
					break;
				case Steuerart.VorabpauschaleKiSt:
					vorabpauschaleKiSt += steuer.steuerbetrag;
					break;
			}
		}
	}
	return {
		kestSumme: vorabpauschaleKest,
		soliSumme: vorabpauschaleSoli,
		kistSumme: vorabpauschaleKiSt,
	};
}

export const Kistam_RK = '--RK--';
export const Kistam_EV = '--EV--';