import { Component, ChangeDetectionStrategy, Optional, Self } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	UntypedFormControl,
	NgControl,
	ValidationErrors,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { gattungMetadata } from '../../model';

@Component({
	selector: 'app-gattung-edit-ausschuettungsdatum',
	templateUrl: './gattung-edit-ausschuettungsdatum.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungEditAusschuettungsdatumComponent
	extends BaseComponent
	implements ControlValueAccessor
{
	readonly metadata = gattungMetadata.fields.ausschuettungsdatum;
	private onChange?: (list: string[]) => void;
	private onTouchedCallback: any;
	public readonly inputControl = new UntypedFormControl(null, isValidInput);

	public readonly values$ = new BehaviorSubject<string[]>([]);

	constructor(@Optional() @Self() public readonly ngControl: NgControl) {
		super();
		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl)
			throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}

	writeValue(obj?: string[] | null): void {
		this.values$.next(obj ?? []);
	}

	registerOnChange(fn: (list: string[]) => void): void {
		this.onChange = fn;
	}

	add(event: MatChipInputEvent): void {
		if (!event.value) return;
		if (!this.inputControl.valid) return;
		this.inputControl.setValue(null, { emitEvent: false });

		var values = new Set<string>(this.values$.value);
		values.add(event.value);
		this.values$.next([...values]);
		if (this.onChange) this.onChange(this.values$.value);
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	remove(value: string): void {
		this.values$.next(this.values$.value.filter(v => v !== value));
		if (this.onChange) this.onChange(this.values$.value);
	}

	onBlur(): void {
		if (this.inputControl.value) {
			this.inputControl.setErrors({ errorMessage: 'Akzeptiere oder lösche Text' });
		}
	}

	onFocus(): void {
		this.inputControl.updateValueAndValidity();
	}
}

const regex = /^(\d\d?)\.(\d\d?)$/;
const validationError = { errorMessage: 'Ungültige Eingabe: muss TT.MM sein, z.B. 7.12' };

function isValidInput(v: AbstractControl): ValidationErrors | null {
	if (!v.value) return null;
	const match = regex.exec(v.value);
	if (!match) return validationError;

	const potentialDay = Number(match[1]);
	if (potentialDay < 1) return validationError;
	const potentialMonth = Number(match[2]);
	switch (potentialMonth) {
		case 1:
		case 3:
		case 5:
		case 7:
		case 8:
		case 10:
		case 12:
			if (potentialDay > 31) return validationError;
			break;
		case 2:
			if (potentialDay > 28) return validationError;
			break;
		case 4:
		case 6:
		case 9:
		case 11:
			if (potentialDay > 30) return validationError;
			break;
		default:
			return validationError;
	}

	return null;
}
