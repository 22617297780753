import { Component, Input, OnInit } from '@angular/core';
import { QuickInfoPopupService } from '../../quick-info-popup.service';
import { Gattung, gattungMetadata } from '../model';

@Component({
	selector: 'app-gattung-details',
	templateUrl: './gattung-details.component.html',
	styleUrls: ['./gattung-details.component.scss'],
})
export class GattungDetailsComponent implements OnInit {
	@Input() gattung: Gattung;
	@Input() showHeader = true;

	readonly metadata = gattungMetadata;

	constructor(public readonly quickInfo: QuickInfoPopupService) {
		this.gattung = {} as Gattung;
	}

	ngOnInit(): void {
		if (!this.gattung) {
			throw new Error('Gattung must be set!');
		}
	}
}
