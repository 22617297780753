import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, debounceTime, EMPTY, of, switchMap } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { BenutzerRolle } from 'src/app/general/roles';
import { AlertService } from 'src/app/shared/alert.service';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { checkFilterFails } from 'src/app/utils';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from '../kuerzel-bezeichnung-referenzlist.service';
import { KuerzelBezeichnungReferenz, KuerzelBezeichnungReferenzlistMetadata } from '../model';

@Component({
	selector: 'app-kuerzel-bezeichnung-list',
	templateUrl: './kuerzel-bezeichnung-list.component.html',
	styleUrls: ['./kuerzel-bezeichung-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuerzelBezeichnungListComponent
	extends BaseComponent
	implements OnInit, AfterViewInit
{
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	@Output() selected$ = new EventEmitter<KuerzelBezeichnungReferenzEntry>();
	@Input() metadata?: KuerzelBezeichnungReferenzlistMetadata;
	@Input() enableActions = false;

	public readonly displayedColumns = ['actions', 'kuerzel', 'bezeichnung'];
	public readonly dataSource = new MatTableDataSource<KuerzelBezeichnungReferenzEntry>();
	public readonly trackBy: TrackByFunction<KuerzelBezeichnungReferenz> = (index, item) =>
		item.kuerzel;
	public readonly filterForm = new TypedForm<FilterValues>({
		kuerzel: new UntypedFormControl(),
		bezeichnung: new UntypedFormControl(),
		extra: new UntypedFormControl(),
	});

	public service: KuerzelBezeichnungReferenzlistService =
		{} as KuerzelBezeichnungReferenzlistService;
	public canChangeStatus = false;

	constructor(
		private serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private alert: AlertService,
		private readonly simpleDialog: SimpleDialogService,
		private readonly user: CurrentUserService
	) {
		super();
	}

	ngOnInit(): void {
		if (!this.metadata) {
			throw new Error('metadata must be set!');
		}

		this.canChangeStatus =
			this.enableActions &&
			!this.metadata.readonly &&
			// this.metadata.apiCollectionName !== 'basiszinssatz' &&   //EFA-2309
			this.user.hasAnyOfRoles(BenutzerRolle.Erweitert);

		this.displayedColumns.length = 0;
		if (!this.metadata.readonly && this.enableActions)
			this.displayedColumns.push('actions', 'actions2');
		this.displayedColumns.push('kuerzel', 'bezeichnung');
		if (this.metadata.extra) this.displayedColumns.push('extra');
		if (!this.metadata.readonly) this.displayedColumns.push('geaendertVon', 'geaendertAm');
		if (!this.metadata.readonly && this.enableActions) this.displayedColumns.push('delete');

		this.service = this.serviceFactory.getService(this.metadata.apiCollectionName);
	}

	ngAfterViewInit(): void {
		if (!this.sort) throw new Error('Sort missing!');
		if (!this.paginator) throw new Error('Paginator missing!');
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		this.i18n4Paginator(this.paginator);

		setTimeout(() => {
			this.registerSubscription(
				this.service.list$.subscribe(list => {
					this.dataSource.data = (list || []).map(e => ({
						...e,
						bezeichnungUpper: (e.bezeichnung || '').toLocaleUpperCase(),
						kuerzelUpper: (e.kuerzel || '').toLocaleUpperCase(),
						extraUpper: (e.extra || '').toLocaleUpperCase(),
					}));
				})
			);

			this.registerSubscription(
				this.filterForm.values$
					.pipe(debounceTime(200))
					.subscribe(formValues => this.setFilter(formValues))
			);
		});
	}

	setFilter(formValues: FilterValues): void {
		formValues = {
			bezeichnung: (formValues.bezeichnung || '').toLocaleUpperCase(),
			kuerzel: (formValues.kuerzel || '').toLocaleUpperCase(),
			extra: (formValues.extra || '').toLocaleUpperCase(),
		};

		this.dataSource.filterPredicate = (item, filterValuesAny) => {
			const formValues = filterValuesAny as unknown as FilterValues;
			if (checkFilterFails(item.bezeichnungUpper, formValues.bezeichnung)) return false;
			if (checkFilterFails(item.kuerzelUpper, formValues.kuerzel)) return false;
			if (checkFilterFails(item.extraUpper, formValues.extra)) return false;
			return true;
		};

		this.dataSource.filter = formValues as any;
	}

	open(entry: KuerzelBezeichnungReferenzEntry): void {
		this.selected$.next(entry);
	}

	remove(entry: KuerzelBezeichnungReferenzEntry): void {
		this.simpleDialog
			.jaNein(
				`Wollen Sie wirklich den Eintrag ${entry.kuerzel} ${entry.bezeichnung} löschen?`,
				'Bitte bestätigen'
			)
			.pipe(
				switchMap(jaNein => {
					if (jaNein === 'Nein') return EMPTY;
					return this.service.delete$(entry);
				}),
				catchError((err: Error) => {
					this.alert.error('Löschen fehlgeschlagen!', err);
					return EMPTY;
				}),
				switchMap(() => this.service.loadAll$()),
				catchError((err: Error) => {
					this.alert.error('Liste neu laden fehlgeschlagen!', err);
					return EMPTY;
				})
			)
			.subscribe(() => this.alert.success('Gelöscht'));
	}

	toggleStatus(entry: KuerzelBezeichnungReferenzEntry, toggle: MatSlideToggle): void {
		const originalStatusAsBoolean = entry.status === 'Aktiv';
		const neuerStatus = originalStatusAsBoolean ? 'Inaktiv' : 'Aktiv';
		this.service
			.setStatus$(entry, neuerStatus)
			.pipe(
				catchError((err: Error) => {
					this.alert.error('Status setzen fehlgeschlagen!', err);

					// setze den UI-Stand vom Toggle auf den ursprünglichen Stand
					toggle.writeValue(originalStatusAsBoolean);
					return EMPTY;
				}),
				switchMap(() => this.service.loadAll$()),
				catchError((err: Error) => {
					this.alert.error('Liste durchladen fehlgeschlagen!', err);
					return EMPTY;
				})
			)
			.subscribe(() => this.alert.success('Status gesetzt'));
	}
}

interface KuerzelBezeichnungReferenzEntry extends KuerzelBezeichnungReferenz {
	kuerzelUpper: string;
	bezeichnungUpper: string;
	extraUpper: string;
}

interface FilterValues {
	kuerzel: string;
	bezeichnung: string;
	extra: string;
}
