import { Directive, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';

@Directive({
	selector: '[click.stop]',
})
export class StopClickDirective {
	@Output('click.stop') stopPropEvent = new EventEmitter<Event>();
	private unsubscribe?: () => void;

	constructor(private renderer: Renderer2, private element: ElementRef) {}

	ngOnInit() {
		this.unsubscribe = this.renderer.listen(this.element.nativeElement, 'click', event => {
			event.stopPropagation();
			this.stopPropEvent.emit(event);
		});
	}

	ngOnDestroy() {
		if (this.unsubscribe) this.unsubscribe();
	}
}
