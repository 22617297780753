<div
	id="main-container"
	*ngIf="init.isLoggedIn$ | async; else login"
	class="mat-app-background">
	<span id="main-container-logo" routerLink="">
		<img src="assets/logo.jpg" alt="Deka logo" />
		<span>EfA</span>
		<span *ngIf="configService.isDevelopment" class="dev-test-environment-info">
			Dev/Test-Umgebung
		</span>
	</span>

	<mat-toolbar color="primary" id="main-container-toolbar">
		<span style="flex: 1"></span>
		<button mat-button [matMenuTriggerFor]="menu">
			<mat-icon>person_outline</mat-icon>
			<span style="margin-left: 0.5em">
				{{ (currentUser.currentUser$ | async)?.fullname }}
			</span>
		</button>
	</mat-toolbar>

	<div id="main-container-menu">
		<mat-nav-list>
			<a
				class="menu-item"
				mat-list-item
				[routerLink]="menuItem.link"
				routerLinkActive="active-link"
				[routerLinkActiveOptions]="{ exact: false }"
				[class.disabled]="!menuItem.link"
				[queryParams]="menuItem.queryParams"
				*ngFor="let menuItem of filteredMenuItems$ | async"
				[ngClass]="'menuItem-indentation-' + (menuItem.indented ?? 0)"
				[matTooltip]="menuItem.label"
				style="gap: 1em">
				<span class="menu-label">
					{{ menuItem.label }}
				</span>
				<span *ngIf="menuItem.badgeValue" class="menu-label-badge">
					{{ menuItem.badgeValue }}
				</span>
			</a>
		</mat-nav-list>
	</div>

	<main id="main-content" style="padding: 1em">
		<router-outlet></router-outlet>
	</main>
</div>

<ng-template #login>
	<app-init></app-init>
</ng-template>

<mat-menu #menu="matMenu">
	<div style="padding: 0 1em" *ngIf="currentUser.currentUser$ | async as currentUserValue">
		Username: {{ currentUserValue.username }}
		<br />
		Benutzerrollen
		<ul>
			<li *ngFor="let role of currentUserValue.roles">{{ role.toUpperCase() }}</li>
		</ul>
	</div>

	<button mat-menu-item (click)="init.logout()">
		<mat-icon>logout</mat-icon>
		<span>Ausloggen</span>
	</button>
	<hr />
	<button mat-menu-item routerLink="/sysinfo">
		<mat-icon>info</mat-icon>
		<span>System-Informationen</span>
	</button>
	<hr style="display: none" />
	<form
		[formGroup]="themeSelection"
		class="theme-chooser"
		style="padding: 0 1em; display: none">
		<div>Theme</div>
		<mat-button-toggle-group formControlName="theme">
			<mat-button-toggle *ngFor="let theme of themes" [value]="theme">
				{{ theme == 'default' ? 'Standard' : theme }}
			</mat-button-toggle>
		</mat-button-toggle-group>

		<div>
			Dark Mode
			<mat-slide-toggle formControlName="darkMode"></mat-slide-toggle>
		</div>
	</form>
</mat-menu>
