import {
	AbstractControl,
	AbstractControlOptions,
	AsyncValidatorFn,
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export class TypedForm<TValues> extends UntypedFormGroup {
	readonly values$ = this.valueChanges as Observable<TValues>;
	readonly isValid$ = this.statusChanges.pipe(
		startWith(this.status),
		map(status => status === 'VALID')
	);

	get typedValue(): TValues {
		return this.value as TValues;
	}

	get rawTypedValue(): TValues {
		return this.getRawValue() as TValues;
	}

	constructor(controls: Record<keyof TValues, AbstractControl>) {
		super(controls);
	}

	resetTyped(
		val: PartialNulls<TValues>,
		options?: {
			onlySelf?: boolean;
			emitEvent?: boolean;
		}
	): void {
		this.reset(val, options);
	}

	setValueTyped(
		val: TValues,
		options?: {
			onlySelf?: boolean;
			emitEvent?: boolean;
		}
	): void {
		this.setValue(val as any, options);
	}
}

export type PartialNulls<TValues> = Partial<Record<keyof TValues, any>>;

export class TypedControl<TValue> extends UntypedFormControl {
	readonly value$ = this.valueChanges as Observable<TValue>;

	get typedValue(): TValue {
		return this.value as TValue;
	}

	constructor(
		value?: any,
		validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
	) {
		super(value, validatorOrOpts, asyncValidator);
	}
}
