import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { InitComponent } from './init/init/init.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { KonfigurationComponent } from './konfiguration/konfiguration/konfiguration.component';
import { SharedModule } from '../shared/shared.module';
import { SystemInformationComponent } from './system-information/system-information.component';
import { AssetsComponent } from './konfiguration/assets/assets.component';
import { KonfigurationOverviewComponent } from './konfiguration/konfiguration-overview/konfiguration-overview.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { StartPageComponent } from './start-page/start-page.component';
import { StammdatenModule } from '../stammdaten/stammdaten.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [
		AppComponent,
		InitComponent,
		KonfigurationComponent,
		SystemInformationComponent,
		AssetsComponent,
		KonfigurationOverviewComponent,
		StartPageComponent,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		AppRoutingModule,
		HttpClientModule,
		HttpClientXsrfModule.withOptions({ cookieName: 'XSRF-TOKEN' }),
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatInputModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		MatIconModule,
		MatMenuModule,
		MatBadgeModule,
		SharedModule,
		MatSelectModule,
		MatOptionModule,
		StammdatenModule,
		MatTooltipModule,
	],
})
export class GeneralModule {}
