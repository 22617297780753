import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataItemComponent } from './data-item/data-item.component';
import { SimpleDialogComponent } from './dialogs/simple-dialog/simple-dialog.component';
import { StopClickDirective } from './directives/stop-click.directive';
import { DisplayErrorDialogComponent } from './display-error-dialog/display-error-dialog.component';
import { ExcelChooseWorksheetComponent } from './excel/excel-choose-worksheet/excel-choose-worksheet.component';
import { LoadExcelFileComponent } from './excel/load-excel-file/load-excel-file.component';
import { FieldHelpIconComponent } from './field-help-icon/field-help-icon.component';
import { DateInputComponent } from './forms/date-input/date-input.component';
import { FieldErrorMessagePipe } from './forms/field-error-message.pipe';
import { NumericInputComponent } from './forms/numeric-input/numeric-input.component';
import { TextInputComponent } from './forms/text-input/text-input.component';
import { BooleanTextPipe } from './pipe/boolean-text.pipe';
import { MoneyPipe } from './pipe/money.pipe';
import { NotPipe } from './pipe/not.pipe';
import { SpinnerDialogComponent } from './dialogs/spinner-dialog/spinner-dialog.component';
import { StringArrayInputComponent } from './forms/string-array-input/string-array-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { FixedlistInputComponent } from './forms/fixedlist-input/fixedlist-input.component';
import { RequireRoleDirective } from './directives/require-role.directive';
import { ReadonlyFieldComponent } from './forms/readonly-field/readonly-field.component';
import { EfaNumberPipe } from './pipe/efa-number.pipe';
import { DecimalPipe } from '@angular/common';
import { KontonummerPipe } from './pipe/kontonummer.pipe';
import { UnsavedChangesGuard } from './route-guards/unsaved-changes-guard';
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
	declarations: [
		DisplayErrorDialogComponent,
		FieldErrorMessagePipe,
		FieldHelpIconComponent,
		NumericInputComponent,
		TextInputComponent,
		DateInputComponent,
		BooleanTextPipe,
		MoneyPipe,
		DataItemComponent,
		NotPipe,
		StopClickDirective,
		SimpleDialogComponent,
		LoadExcelFileComponent,
		ExcelChooseWorksheetComponent,
		SpinnerDialogComponent,
		StringArrayInputComponent,
		FixedlistInputComponent,
		RequireRoleDirective,
		ReadonlyFieldComponent,
		EfaNumberPipe,
		KontonummerPipe,
		FileUploadComponent,
	],
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		ReactiveFormsModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		MatSelectModule,
		MatOptionModule,
		MatProgressSpinnerModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatButtonModule,
	],
	providers: [DecimalPipe, UnsavedChangesGuard],
	exports: [
		FieldErrorMessagePipe,
		FieldHelpIconComponent,
		NumericInputComponent,
		TextInputComponent,
		DateInputComponent,
		BooleanTextPipe,
		MoneyPipe,
		DataItemComponent,
		NotPipe,
		StopClickDirective,
		LoadExcelFileComponent,
		StringArrayInputComponent,
		FixedlistInputComponent,
		RequireRoleDirective,
		ReadonlyFieldComponent,
		EfaNumberPipe,
		KontonummerPipe,
		FileUploadComponent,
	],
})
export class SharedModule { }
