import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CalculateGeschaeftsfaelleResult } from 'src/app/berechnung/geschaeftsfaelle';
import { VorabpauschaleStatus } from '../../einreichung.service';

@Component({
	selector: 'app-geschaeftsfall-detail-list',
	templateUrl: './geschaeftsfall-detail-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallDetailListComponent {
	@Input() calculateGeschaeftsfaelleResult: CalculateGeschaeftsfaelleResult = {
		geschaeftsfaelle: [],
		messages: [],
		vorabpauschaleComment: '',
		vorabpauschaleStatus: VorabpauschaleStatus.Unbekannt,
	};
}
