import { ChangeDetectionStrategy, Component, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as ExcelJS from 'ExcelJS-workaround';

import { BehaviorSubject, catchError, EMPTY, switchMap } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { InitService } from 'src/app/general/init/init.service';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { AlertService } from 'src/app/shared/alert.service';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { INTERNAL_NUMBER_FACTOR, toExternalModel } from 'src/app/utils';
import { logger } from 'src/logger';
import { GeschaeftsfallService } from '../../geschaeftsfall.service';
import {
	anteilsscheinGeschaeftCsvBlackList,
	Geschaeftsfall,
	geschaeftsfallMetadata,
	GeschaeftsfallMitPositionen,
	GeschaeftsfallStatus,
	getEinreicherName,
} from '../../model';
import { GeschaeftsfallDetailsModalComponent } from '../geschaeftsfall-details-modal/geschaeftsfall-details-modal.component';
import {
	GeschaeftsfaelleListItem,
	GeschaeftsfallListComponent,
} from '../geschaeftsfall-list/geschaeftsfall-list.component';
import { EinreichungService } from '../../einreichung.service';
import { SimpleDialogContent } from 'src/app/shared/dialogs/simple-dialog/simple-dialog.component';

@Component({
	selector: 'app-geschaeftsfall-list-page',
	templateUrl: './geschaeftsfall-list-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallListPageComponent extends BaseComponent implements OnChanges {
	@ViewChild(GeschaeftsfallListComponent) listComponent?: GeschaeftsfallListComponent; // necessary for unit tests

	public readonly metadata = geschaeftsfallMetadata;

	public readonly isRefreshing$ = new BehaviorSubject(false);
	public readonly isProcessing$ = new BehaviorSubject(false);
	public numberOfChosen = 0;
	public readonly geschaeftsfallStatus = GeschaeftsfallStatus;
	public chosenData: GeschaeftsfaelleListItem[] = [];

	constructor(
		private readonly matDialog: MatDialog,
		private readonly geschaeftsfallService: GeschaeftsfallService,
		private readonly alert: AlertService,
		private readonly konfigurationService: KonfigurationService,
		private readonly finanzinstituteService: FinanzinstitutService,
		public readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly gattungService: GattungService,
		private readonly save: SaveService,
		private readonly simpleDialog: SimpleDialogService,
		private readonly initService: InitService,
		private readonly einreichungService: EinreichungService
	) {
		super();
	}

	onItem({ item, count }: { item: GeschaeftsfallMitPositionen; count: number }) {
		if (count > 1) {
			console.error(item);
			this.router.navigate([
				geschaeftsfallMetadata.routing.view!.url(item.geschaeftsfall.id),
			]);
		} else {
			this.matDialog.open(GeschaeftsfallDetailsModalComponent, { data: item });
		}
	}

	ngOnChanges(): void {
		this.initService.lastNavigationDate$.next(new Date());
	}

	refresh(): void {
		if (this.isRefreshing$.value) return;

		this.initService.lastNavigationDate$.next(new Date());

		this.isRefreshing$.next(true);
		this.geschaeftsfallService.loadAll$().subscribe({
			next: () => {
				this.loadEinreichungen();
			},
			error: err => {
				logger.error(err);
				this.alert.error('Aktualisierung fehlgeschlagen!', err);
				this.isRefreshing$.next(false);
			},
		});
	}

	loadEinreichungen(): void {
		this.einreichungService.loadAll$().subscribe({
			next: () => {
				this.alert.success('Fertig!');
				this.isRefreshing$.next(false);
			},
			error: err => {
				logger.error(err);
				this.alert.error('Aktualisierung fehlgeschlagen!', err);
				this.isRefreshing$.next(false);
			},
		});
	}

	downloadAsExcel(data: GeschaeftsfaelleListItem[]): void {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(this.metadata.label);
		worksheet.addRow([
			'Einreichungsnummer',
			this.metadata.fields.nummer.label,
			this.metadata.fields.status.label,
			this.metadata.fields.istInkasso.label,
			this.metadata.fields.vorgangsnummer.label,
			this.metadata.fields.bruttobetrag.label,
			this.metadata.fields.istStorno.label,
			this.metadata.fields.inkassoVersanddatum.label,
			'Einreicher',
			this.metadata.fields.land.label,
			this.metadata.fields.erstelltAm.label,
			this.metadata.fields.geaendertAm.label,
			'Dokumentreferenz',
		]);
		for (const element of data) {
			worksheet.addRow([
				element.einreichung.nummer,
				element.geschaeftsfall.nummer,
				element.geschaeftsfall.status,
				element.geschaeftsfall.istInkasso ? 'Ja' : '--',
				element.geschaeftsfall.vorgangsnummer,
				toExternalModel(element.geschaeftsfall.bruttobetrag)?.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.geschaeftsfall.istStorno ? 'Ja' : '--',
				getEinreicherName(element.einreichung, this.finanzinstituteService.list$.value),
				element.geschaeftsfall.inkassoVersanddatum?.toLocaleDateString('de-DE'),
				element.geschaeftsfall.land,
				element.geschaeftsfall.erstelltAm?.toLocaleDateString('de-DE'),
				element.geschaeftsfall.geaendertAm?.toLocaleDateString('de-DE'),
				`${this.konfigurationService.konfiguration$.value.dokumentenVerzeichnis}/${element.einreichung.nummer}`,
			]);
		}
		workbook.xlsx.writeBuffer().then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			this.save.saveAs(blob, this.metadata.apiCollectionName + '.xlsx');
		});
	}

	csvExportAnteilsgeschaeft(data: GeschaeftsfaelleListItem[]): void {
		this.chosenData = data.filter(c => c.isChosen);
		const chosenGeschaeftsfaelle: Geschaeftsfall[] = this.chosenData.map(
			item => item.geschaeftsfall
		);

		this.geschaeftsfallService.postChosenGeschaeftsfall$(chosenGeschaeftsfaelle).subscribe({
			next: (response: Geschaeftsfall[]) => {
				this.handleSplittedList(response);
			},
			error: err => {
				logger.error(err);
				this.alert.error('CSV-Export fehlgeschlagen!', err);
			},
		});
	}

	handleSplittedList(response: Geschaeftsfall[]): void {
		const negativeGf = response.filter(gf => {
			return anteilsscheinGeschaeftCsvBlackList.includes(gf.status);
		});
		if (negativeGf.length > 0) {
			let messageContent =
			`Folgende Geschäftsfälle konnten leider nicht berücksichtigt werden:\n`;
			messageContent += `<ul>`
			negativeGf.forEach(gf => {
				messageContent += `<li>${gf.nummer}: ${gf.status}</li>`;
			});
			messageContent += `</ul>`
			const title = `${negativeGf.length} von ${response.length} nicht berücksichtigt`;			
			const config: SimpleDialogContent = {
				title: title,
				messageHtml: messageContent,
				buttonsLeft: [],
				buttonsRight: [{ label: 'Ok', color: 'primary' }],
			};
			this.simpleDialog.open(config);
			this.refresh();
		}

		const positiveGf = response.filter(gf => {
			return !anteilsscheinGeschaeftCsvBlackList.includes(gf.status);
		});

		const positiveGfIds: string[] = positiveGf.map((gf: Geschaeftsfall) => gf.id);

		const positiveGfListItems: GeschaeftsfaelleListItem[] = this.chosenData.filter(item => {
			return positiveGfIds.includes(item.geschaeftsfall.id);
		});
		this.createCsvExportAnteilsgeschaeft(positiveGfListItems);
	}

	createCsvExportAnteilsgeschaeft(positiveGf: GeschaeftsfaelleListItem[]) {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(this.metadata.label);
		worksheet.addRow([
			'Effka Ref',
			'Datum',
			'Anteile',
			'WKN',
			'ISIN',
			'Verwendungszweck',
			'Adresse Privatperson',
			'Kontrahent / DBD',
		]);
		for (const element of positiveGf) {
			const firstPosition = (element.einreichung.positionen?.filter(
				p => p.id === element.geschaeftsfall?.positionen[0]
			))[0];
			const gattungFirstPosition = this.gattungService.list$.value.filter(
				g => g.id === firstPosition?.gattungId
			)[0];
			const istEinreichungPrivat = !element.einreichung.einreicher;
			const wirtschaftlichBerechtigterPersonen = element.einreichung.personen.filter(
				p => p.istWirtschaftlichBerechtigter
			);
			let adressePrivatperson = '';
			if (istEinreichungPrivat && wirtschaftlichBerechtigterPersonen.length > 0) {
				if (wirtschaftlichBerechtigterPersonen.length === 1) {
					adressePrivatperson =
						wirtschaftlichBerechtigterPersonen[0].adresse +
						' ' +
						wirtschaftlichBerechtigterPersonen[0].postleitzahl +
						' ' +
						wirtschaftlichBerechtigterPersonen[0].ort;
				} else {
					adressePrivatperson = 'Erbfall';
				}
			}
			const einreichendesFinanzinstitut = this.finanzinstituteService.list$.value.filter(
				f => f.id === element.einreichung.einreicher
			)[0];
			let anteilAnzahl: number | null = null;
			if (element.positionen.length > 0) {
				anteilAnzahl = 0;
				for (let pos = 0; pos < element.positionen.length; pos++) {
					anteilAnzahl +=
						(element.positionen[pos].anteil * element.positionen[pos].anzahl) /
						INTERNAL_NUMBER_FACTOR;
				}
			}
			let kontrahentDbd: string | null;
			if (istEinreichungPrivat) {
				kontrahentDbd = element.einreichung.kassenvereinsnummer ?? '2288';
			} else {
				if (element.einreichung.kassenvereinsnummer !== undefined) {
					kontrahentDbd = element.einreichung.kassenvereinsnummer;
				} else {
					kontrahentDbd = einreichendesFinanzinstitut.finanzinstitutsnummer ?? '';
				}
			}
			worksheet.addRow([
				//Effka Ref:
				element.geschaeftsfall.nummer,
				//Datum:
				element.einreichung.valutatag?.toLocaleDateString('de-DE'),
				//Anteile:
				anteilAnzahl,
				gattungFirstPosition.wkn,
				gattungFirstPosition.isin,
				//Verwendungszweck:
				istEinreichungPrivat
					? `Effekten: z.G.  ${element.einreichung.depotNummer ?? ''} ${
							element.einreichung.depotInhaber ?? ''
					  }`
					: `Effekten: Tausch ${element.einreichung.externeReferenz ?? ''} -VPEME`,
				adressePrivatperson,
				kontrahentDbd,
			]);
		}
		const options = {
			// https://c2fo.io/fast-csv/docs/formatting/options
			formatterOptions: {
				delimiter: ';',
				quoteColumns: true,
				writeBOM: true,
			},
		};

		workbook.csv.writeBuffer(options).then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			const now: string = new Date().toISOString().slice(0, 10);
			this.save.saveAs(blob, 'CsvExportAnteilsgeschaeft_' + now + '.csv');
		});
	}

	onChosenChange($event: number) {
		this.numberOfChosen = $event;
	}

	hasNoLuxSammelabwicklungItems(data: GeschaeftsfaelleListItem[]): boolean {
		return (
			data.filter(
				d =>
					d.geschaeftsfall.status === GeschaeftsfallStatus.ZumInkasso &&
					d.geschaeftsfall.land === 'LX'
			).length === 0
		);
	}

	luxSammelabwicklungStarten(): void {
		if (this.isRefreshing$.value) return;
		this.isRefreshing$.next(true);
		this.geschaeftsfallService
			.luxSammelabwicklung$()
			.pipe(
				switchMap(() => this.geschaeftsfallService.loadAll$()),
				catchError((err: Error) => {
					this.alert.error('Liste neu laden fehlgeschlagen!', err);
					return EMPTY;
				})
			)
			.subscribe({
				next: () => {
					this.alert.success('LUX Sammelabwicklung erfolgreich');
					this.isRefreshing$.next(false);
				},
				error: err => {
					logger.error(err);
					this.alert.error('LUX Sammelabwicklung fehlgeschlagen!', err);
					this.isRefreshing$.next(false);
				},
			});
	}

	sammelverarbeitung(): void {
		this.simpleDialog
			.jaNein(
				'Soll die Sammelverarbeitung gestartet werden?',
				'Sammelverarbeitung bestätigen'
			)
			.pipe(
				switchMap(answer => {
					if (answer !== 'Ja') return EMPTY;
					this.isProcessing$.next(true);
					return this.geschaeftsfallService.sammelverarbeitung$();
				}),
				catchError(err => {
					this.alert.error('Sammelverarbeitung fehlgeschlagen!', err);
					return EMPTY;
				}),
				switchMap(() => this.geschaeftsfallService.loadAll$()),
				catchError(err => {
					this.alert.error('Laden von Geschäftsfällen fehlgeschlagen!', err);
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.isProcessing$.next(false);
				this.alert.success('Sammelverarbeitung erfolgreich!');
			});
	}
}
