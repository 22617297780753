import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { parseIncomingAsDate, formatDateForSaveModel, toInternalModel } from 'src/app/utils';
import { Buchung } from './model';

@Injectable({
	providedIn: 'root',
})
export class BuchungService {
	constructor(private readonly http: HttpClient) {}

	protected onLoaded?: (val: Buchung) => void = buc => {
		buc.buchungsdatum = parseIncomingAsDate(buc.buchungsdatum) ?? undefined;
		buc.valutatag = parseIncomingAsDate(buc.valutatag) ?? undefined;
		buc.verarbeitungszeitpunkt = parseIncomingAsDate(buc.verarbeitungszeitpunkt) ?? undefined;

		buc.betrag = toInternalModel(buc.betrag);
	};

	public getByVerarbeitungszeitpunkt$(date: Date): Observable<Buchung[]> {
		return this.http.get<Buchung[]>(`/buchung/${formatDateForSaveModel(date)}`).pipe(
			tap(data => {
				data.forEach(this.onLoaded!);
			})
		);
	}
}
