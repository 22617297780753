import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { addDays } from 'date-fns';
import { BehaviorSubject, combineLatest, debounceTime, startWith, Subject } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { bufferDebounceCount } from 'src/app/utils';
import { EinreichungService } from '../../einreichung.service';
import { GeschaeftsfallService } from '../../geschaeftsfall.service';
import {
	einreichungMetadata,
	Geschaeftsfall,
	GeschaeftsfallListStatusFilter,
	geschaeftsfallMetadata,
	GeschaeftsfallMitPositionen,
	GeschaeftsfallStatus,
	getEinreicherName,
	doesGFMatchStatusFilter,
} from '../../model';

@Component({
	selector: 'app-geschaeftsfall-list',
	templateUrl: './geschaeftsfall-list.component.html',
	styleUrls: ['geschaeftsfall-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallListComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	@Output() item$ = new EventEmitter<{ item: GeschaeftsfallMitPositionen; count: number }>();
	@Output() numberOfChosen = new EventEmitter<number>();

	@Input() set statusFilter(val: GeschaeftsfallListStatusFilter | null | string | undefined) {
		this.statusFilter$.next((val as GeschaeftsfallListStatusFilter) || null);
	}
	get statusFilter(): GeschaeftsfallListStatusFilter | null {
		return this.statusFilter$.value;
	}

	@Input() set enableSelection(val: boolean) {
		if (val && this.displayedColumns[0] !== 'selection') {
			this.displayedColumns.unshift('selection');
		} else if (!val && this.displayedColumns[0] === 'selection') {
			this.displayedColumns.shift();
		}
	}

	public readonly dataSource = new MatTableDataSource<GeschaeftsfaelleListItem>();
	public readonly trackBy: TrackByFunction<GeschaeftsfaelleListItem> = (index, item) =>
		item.geschaeftsfall.nummer;
	public readonly metadata = geschaeftsfallMetadata;
	public readonly einreichungMetadata = einreichungMetadata;
	public readonly itemClicked$ = new Subject<GeschaeftsfaelleListItem>();
	public readonly geschaeftsfallStatus = Object.values(GeschaeftsfallStatus).filter(
		s => !s.includes('Vorschau')
	);
	public readonly statusFilter$ = new BehaviorSubject<GeschaeftsfallListStatusFilter | null>(
		null
	);

	public readonly filterForm = new TypedForm<FilterForm>({
		einreichungsnummer: new UntypedFormControl(null),
		nummer: new UntypedFormControl(null),
		status: new UntypedFormControl(null),
		vorgangsnummer: new UntypedFormControl(null),
		inkassoVersanddatumAnfang: new UntypedFormControl(null),
		inkassoVersanddatumEnde: new UntypedFormControl(null),
		land: new UntypedFormControl(null),
		erstelltAmAnfang: new UntypedFormControl(null),
		erstelltAmEnde: new UntypedFormControl(null),
		geaendertAmAnfang: new UntypedFormControl(null),
		geaendertAmEnde: new UntypedFormControl(null),
		selectForExport: new UntypedFormControl(null),
	});

	public readonly displayedColumns = [
		'selection',
		'einreichungsnummer',
		'nummer',
		'wkn',
		'status',
		'istInkasso',
		'vorgangsnummer',
		'bruttobetrag',
		'istStorno',
		'einreicherName',
		'inkassoVersanddatum',
		'land',
		'erstelltAm',
		'geaendertAm',
	];

	constructor(
		private readonly geschaeftsfallService: GeschaeftsfallService,
		private readonly einreichungService: EinreichungService,
		private readonly finanzinstitutService: FinanzinstitutService
	) {
		super();

		this.dataSource.sortingDataAccessor = (element, property) => {
			switch (property) {
				case 'einreicherName':
					return element[property];
				case 'einreichungsnummer':
					return element.einreichung.nummer;
				case 'nummer':
					return element.geschaeftsfall.nummer;
				case 'status':
					return element.geschaeftsfall.status;
				case 'istInkasso':
					return element.geschaeftsfall.istInkasso ? 1 : 0;
				case 'vorgangsnummer':
					return element.geschaeftsfall.vorgangsnummer;
				case 'bruttobetrag':
					return element.geschaeftsfall.bruttobetrag;
				case 'istStorno':
					return element.geschaeftsfall.istStorno ? 1 : 0;
				case 'inkassoVersanddatum':
					return element.geschaeftsfall.inkassoVersanddatum
						? element.geschaeftsfall.inkassoVersanddatum?.getTime()
						: 0;
				case 'land':
					return element.geschaeftsfall.land;
				case 'erstelltAm':
					return element.geschaeftsfall.erstelltAm
						? element.geschaeftsfall.erstelltAm.getTime()
						: 0;
				case 'geaendertAm':
					return element.geschaeftsfall.geaendertAm
						? element.geschaeftsfall.geaendertAm?.getTime()
						: 0;
				default:
					return '';
			}
		};

		this.registerSubscription(
			this.itemClicked$
				.pipe(bufferDebounceCount(400))
				.subscribe(val => this.item$.next(val))
		);

		const filterFormValues$ = this.filterForm.values$.pipe(
			debounceTime(200),
			startWith({} as FilterForm)
		);
		let filterIndex = 0;

		this.registerSubscription(
			combineLatest([this.statusFilter$, filterFormValues$]).subscribe(
				([statusFilter, filterValues]) => {
					this.dataSource.filterPredicate = data => {
						if (
							statusFilter &&
							!doesGFMatchStatusFilter(data.geschaeftsfall, statusFilter)
						)
							return false;

						if (filterValues.einreichungsnummer) {
							if (
								!data.einreichung.nummer
									.toString()
									.includes(filterValues.einreichungsnummer)
							)
								return false;
						}

						if (filterValues.nummer) {
							if (!data.geschaeftsfall.nummer.includes(filterValues.nummer))
								return false;
						}

						if (filterValues.status) {
							if (!data.geschaeftsfall.status?.includes(filterValues.status))
								return false;
						}

						if (filterValues.vorgangsnummer) {
							if (!data.geschaeftsfall.vorgangsnummer) return false;
							if (
								!data.geschaeftsfall.vorgangsnummer.includes(
									filterValues.vorgangsnummer
								)
							)
								return false;
						}

						if (filterValues.inkassoVersanddatumAnfang) {
							if (!data.geschaeftsfall.inkassoVersanddatum) return false;
							if (
								data.geschaeftsfall.inkassoVersanddatum <
								filterValues.inkassoVersanddatumAnfang
							)
								return false;
						}

						if (filterValues.inkassoVersanddatumEnde) {
							if (!data.geschaeftsfall.inkassoVersanddatum) return false;
							if (
								data.geschaeftsfall.inkassoVersanddatum >
								filterValues.inkassoVersanddatumEnde
							)
								return false;
						}

						if (filterValues.land) {
							if (!data.geschaeftsfall.land.includes(filterValues.land)) return false;
						}

						if (filterValues.erstelltAmAnfang) {
							if (!data.geschaeftsfall.erstelltAm) return false;
							if (data.geschaeftsfall.erstelltAm < filterValues.erstelltAmAnfang)
								return false;
						}

						if (filterValues.erstelltAmEnde) {
							if (!data.geschaeftsfall.erstelltAm) return false;
							if (data.geschaeftsfall.erstelltAm > filterValues.erstelltAmEnde)
								return false;
						}

						if (filterValues.geaendertAmAnfang) {
							if (!data.geschaeftsfall.geaendertAm) return false;
							if (data.geschaeftsfall.geaendertAm < filterValues.geaendertAmAnfang)
								return false;
						}

						if (filterValues.geaendertAmEnde) {
							if (!data.geschaeftsfall.geaendertAm) return false;
							if (data.geschaeftsfall.geaendertAm > filterValues.geaendertAmEnde)
								return false;
						}

						return true;
					};
					filterIndex++;
					this.dataSource.filter = filterIndex.toFixed();

					// Check entfernen, falls nicht mehr in der Filterung
					setTimeout(() => {
						this.dataSource.data
							.filter(d => d.isChosen)
							.forEach(item => {
								if (!this.dataSource.filteredData.includes(item))
									item.isChosen = false;
							});
					});
				}
			)
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}

		if (this.sort) {
			this.dataSource.sort = this.sort;
		}

		setTimeout(() => {
			this.registerSubscription(
				combineLatest([
					this.einreichungService.list$,
					this.finanzinstitutService.list$,
					this.geschaeftsfallService.list$,
				])
					.pipe(debounceTime(10))
					.subscribe(([einreichungen, institute, geschaeftsfaelle]) => {
						const oldSelection = this.dataSource.data
							.filter(item => item.isChosen)
							.map(item => item.geschaeftsfall.id);

						this.dataSource.data = geschaeftsfaelle
							.map(gf => {
								const einreichung = einreichungen.find(e => e.id === gf.einreichungId);
								if (!einreichung) return null as unknown as GeschaeftsfaelleListItem;
								const einreicherName = getEinreicherName(einreichung, institute);
								return {
									einreicherName,
									einreicherNameUppercase: einreicherName.toLocaleUpperCase(),
									einreichung,
									geschaeftsfall: gf,
									positionen: einreichung.positionen.filter(p =>
										gf.positionen.includes(p.id)
									),
									isChosen: oldSelection.includes(gf.id),
									shouldHighlight: shouldHighlight(gf),
								} as GeschaeftsfaelleListItem;
							})
							.filter(val => !!val);
						this.filterForm.controls.selectForExport.setValue(false);
					})
			);
		});
	}

	toggleAll(): void {
		const allToggledOn = this.dataSource.filteredData.every(i => i.isChosen);
		this.dataSource.filteredData.forEach(i => (i.isChosen = !allToggledOn));
		this.onChangeChosen();
	}

	onChangeChosen(): void {
		const chosen = this.dataSource.filteredData.filter(i => i.isChosen).length;
		this.numberOfChosen.emit(chosen);
	}
}

function shouldHighlight(gf: Geschaeftsfall): boolean {
	if (gf.status === GeschaeftsfallStatus.ZumUebertrag) {
		if (gf.erstelltAm && gf.erstelltAm < addDays(new Date(), -7)) {
			return true;
		}
	}

	return false;
}

type FilterForm = {
	einreichungsnummer: string | null;
	nummer: string | null;
	status: string | null;
	vorgangsnummer: string | null;
	inkassoVersanddatumAnfang: Date | null;
	inkassoVersanddatumEnde: Date | null;
	land: string | null;
	erstelltAmAnfang: Date | null;
	erstelltAmEnde: Date | null;
	geaendertAmAnfang: Date | null;
	geaendertAmEnde: Date | null;
	selectForExport: boolean; //no filter, used for csv export selection
};

export interface GeschaeftsfaelleListItem extends GeschaeftsfallMitPositionen {
	einreicherName: string;
	einreicherNameUppercase: string;
	isChosen: boolean;
	shouldHighlight: boolean;
}
