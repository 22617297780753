import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Einreichungsposition, GeschaeftsfallMitPositionen } from '../../model';

@Component({
	selector: 'app-geschaeftsfall-steuern',
	templateUrl: './geschaeftsfall-steuern.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./geschaeftsfall-steuern.component.scss'],
})
export class GeschaeftsfallSteuernComponent implements OnChanges {
	@Input() geschaeftsfallMitPositionen?: GeschaeftsfallMitPositionen;

	public readonly positionWrappers: PositionWrapper[] = [];

	ngOnChanges(changes: SimpleChanges): void {
		if (this.geschaeftsfallMitPositionen) {
			this.positionWrappers.length = 0;
			this.geschaeftsfallMitPositionen.positionen.forEach(position => this.positionWrappers.push({ position }));
		}
	}
}

export interface PositionWrapper {
	position: Einreichungsposition;
}
