import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FinanzinstitutService } from './finanzinstitut.service';

@Pipe({
	name: 'finanzinstitutDisplayName$',
})
export class FinanzinstitutDisplayNamePipe implements PipeTransform {
	constructor(private service: FinanzinstitutService) {}

	transform(id: string | null | undefined): Observable<string> {
		return this.service.getName$(id);
	}
}
