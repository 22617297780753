import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { einreichungMetadata, Einreichung } from '../model';

@Component({
	selector: 'app-einreichung-details-modal',
	templateUrl: './einreichung-details-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungDetailsModalComponent {
	public readonly metadata = einreichungMetadata;
	constructor(@Inject(MAT_DIALOG_DATA) public readonly einreichung: Einreichung) {}
}
