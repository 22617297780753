<section class="table-container mat-elevation-z2" *ngIf="metadata">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		[trackBy]="trackBy"
		matSortActive="kuerzel"
		matSortDirection="asc">
		<ng-container matColumnDef="kuerzel">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 10em; padding-left: 15px"
				mat-sort-header>
				{{ metadata!.kuerzel.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.kuerzel }}</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th mat-header-cell *matHeaderCellDef scope="col" class="action-column"></th>
			<td mat-cell *matCellDef="let element" class="action-column">
				<button
					mat-icon-button
					color="primary"
					(click.stop)="remove(element)"
					*requireRole="'erweitert'">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions2">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 5em"></th>
			<td mat-cell *matCellDef="let element">
				<mat-icon style="margin-left: 1em; margin-right: 1em" *requireRole="'erweitert'">
					edit
				</mat-icon>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em">Status</th>
			<td mat-cell *matCellDef="let element">
				<mat-slide-toggle
					*ngIf="canChangeStatus"
					(click.stop)="toggleStatus(element, self)"
					#self
					[checked]="element.status === 'Aktiv'">
					{{ element.status }}
				</mat-slide-toggle>
				<span *ngIf="!canChangeStatus">{{ element.status }}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="bezeichnung">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.bezeichnung.label }}
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.bezeichnung">
				{{ element.bezeichnung }}
			</td>
		</ng-container>

		<ng-container matColumnDef="extra">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.extra?.label }}
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.extra">
				<ng-container [ngSwitch]="metadata.extra?.typ">
					<span *ngSwitchCase="'number'">
						{{ element.extra | number : '1.2-2' : 'de' }}
					</span>
					<span *ngSwitchDefault>{{ element.extra }}</span>
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="geaendertVon">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header style="width: 10em">
				Geändert von
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.geaendertVon }}
			</td>
		</ng-container>

		<ng-container matColumnDef="geaendertAm">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header style="width: 10em">
				Geändert am
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.geaendertAm | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>

		<!-- Header Rows -->
		<ng-container *matHeaderRowDef="displayedColumns">
			<tr mat-header-row></tr>
			<tr class="mat-header-row table-filter-row" [formGroup]="filterForm">
				<th class="mat-mdc-header-cell" scope="col" *ngIf="enableActions"></th>
				<th class="mat-mdc-header-cell" scope="col" *ngIf="enableActions"></th>
				<th class="mat-mdc-header-cell" scope="col">
					<mat-form-field class="table-search-header-input" style="padding-left: 8px">
						<input
							matInput
							placeholder="Suche..."
							formControlName="kuerzel"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-mdc-header-cell" scope="col">
					<mat-form-field class="table-search-header-input">
						<input
							matInput
							placeholder="Suche..."
							formControlName="bezeichnung"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-mdc-header-cell" scope="col" *ngIf="metadata.extra">
					<mat-form-field class="table-search-header-input">
						<input
							matInput
							placeholder="Suche..."
							formControlName="extra"
							autocomplete="off" />
					</mat-form-field>
				</th>

				<th class="mat-mdc-header-cell" scope="col" *ngIf="!metadata.readonly"></th>
				<th class="mat-mdc-header-cell" scope="col" *ngIf="!metadata.readonly"></th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="open(row)"></tr>
	</table>

	<mat-paginator showFirstLastButtons></mat-paginator>
</section>
