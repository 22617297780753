<div class="mat-elevation-z2">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		[trackBy]="trackBy"
		matSortActive="finanzinstitutsname"
		matSortDirection="asc">
		<ng-container matColumnDef="finanzinstitutsnummer">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 16em"
				mat-sort-header="nummerMitPadding">
				<span>{{ metadata.fields.finanzinstitutsnummer.label }}</span>
				<app-field-help-icon
					[helpText]="
						metadata.fields.finanzinstitutsnummer.description
					"></app-field-help-icon>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.finanzinstitutsnummer }}</td>
		</ng-container>

		<ng-container matColumnDef="finanzinstitutsname">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				{{ metadata.fields.finanzinstitutsname.label }}
				<app-field-help-icon
					[helpText]="
						metadata.fields.finanzinstitutsname.description
					"></app-field-help-icon>
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="text-hide-overflow"
				[matTooltip]="element.finanzinstitutsname">
				{{ element.finanzinstitutsname }}
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<span>{{ metadata.fields.status.label }}</span>
				<app-field-help-icon
					[helpText]="metadata.fields.status.description"></app-field-help-icon>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.status }}</td>
		</ng-container>

		<ng-container matColumnDef="istEinreicherOderEmittent">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				<span>Einreicher / Emittent</span>
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.istEinreicher ? 'Einreicher' : '--' }} /
				{{ element.istEmittent ? 'Emittent' : '--' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="geaendertVon">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<span>{{ metadata.fields.geaendertVon.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.geaendertVon }}</td>
		</ng-container>

		<ng-container matColumnDef="freigegebenVon">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<span>{{ metadata.fields.freigegebenVon.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">{{ element.freigegebenVon }}</td>
		</ng-container>

		<ng-container matColumnDef="freigegebenAm">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 10em" mat-sort-header>
				<span>{{ metadata.fields.freigegebenAm.label }}</span>
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.freigegebenAm | date : 'dd.MM.yyyy HH:mm:ss' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 5em"></th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap">
				<button
					mat-icon-button
					[routerLink]="metadata.routing.view!.url(element.id)"
					matTooltip="Ansicht">
					<mat-icon>visibility</mat-icon>
				</button>
				<button
					mat-icon-button
					[routerLink]="metadata.routing.edit!.url(element.id)"
					matTooltip="Bearbeiten"
					*requireRole="'standard'">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container *matHeaderRowDef="displayedColumns">
			<tr mat-header-row></tr>
			<tr class="mat-header-row" [formGroup]="filterForm" class="table-filter-row">
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="finanzinstitutsnummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="finanzinstitutsname"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<mat-select
							formControlName="status"
							placeholder="Suche..."
							panelClass="status-filter-panel-250">
							<mat-option [value]="">Kein Statusfilter</mat-option>
							<mat-option
								*ngFor="let status of stammdatenStatus | keyvalue"
								[value]="status.value">
								{{ status.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="istEinreicherOderEmittent"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th scope="col" class="mat-header-cell"></th>
				<th scope="col" class="mat-header-cell"></th>
				<th scope="col" class="mat-header-cell"></th>
				<th style="width: 5em" scope="col" class="mat-header-cell"></th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="open(row)"></tr>
	</table>

	<mat-paginator showFirstLastButtons></mat-paginator>
</div>
