<h1 mat-dialog-title>
	{{ finanzinstitut.finanzinstitutsnummer }} - {{ finanzinstitut.finanzinstitutsname }} ({{
		finanzinstitut.status
	}})
</h1>

<div mat-dialog-content style="height: 100%">
	<app-finanzinstitut-details
		[finanzinstitut]="finanzinstitut"
		[showHeader]="false"></app-finanzinstitut-details>
</div>

<div mat-dialog-actions style="display: flex">
	<span style="flex: 1"></span>
	<button
		mat-raised-button
		color="accent"
		mat-dialog-close
		[routerLink]="metadata.routing.view!.url(finanzinstitut.id)">
		<mat-icon>visibility</mat-icon>
		Ansicht
	</button>
	<button
		mat-raised-button
		color="primary"
		mat-dialog-close
		*requireRole="'standard'"
		[routerLink]="metadata.routing.edit!.url(finanzinstitut.id)">
		<mat-icon>edit</mat-icon>
		Bearbeiten
	</button>
</div>
