import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/alert.service';
import { DisplayErrorService } from 'src/app/shared/display-error.service';
import { isValid$ } from 'src/app/utils';
import { logger } from 'src/logger';
import { BaseComponent } from '../../base-component';
import { ConfigService } from '../../config.service';
import { InitService, InitState } from '../init.service';

@Component({
	selector: 'app-init',
	templateUrl: './init.component.html',
	styleUrls: ['./init.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitComponent extends BaseComponent implements OnInit {
	readonly form = new UntypedFormGroup({
		username: new UntypedFormControl(null, [Validators.required]),
		passwort: new UntypedFormControl(null, [Validators.required]),
	});

	readonly cannotStartLogin$ = combineLatest([this.init.state$, isValid$(this.form)]).pipe(
		map(
			([state, isValid]) =>
				(state !== InitState.RequiresLogin || !isValid) && (state !== InitState.WrongCredentials || !isValid)
		)
	);

	constructor(
		public readonly init: InitService,
		private readonly displayError: DisplayErrorService,
		public readonly configService: ConfigService
	) {
		super();
		this.form.disable({ emitEvent: false });
		this.registerSubscription(
			this.init.state$.subscribe(s => {
				switch (s) {
					case InitState.RequiresLogin:
						this.form.enable({ emitEvent: false });
						break;
					case InitState.WrongCredentials:
						this.form.enable({ emitEvent: false });
						break;
					default:
						this.form.disable({ emitEvent: false });
						break;
				}
			})
		);
	}

	ngOnInit(): void {
		this.init.init();
	}

	login(): void {
		if (!this.form.valid) return;
		const formValue = this.form.value as { username: string; passwort: string; };
		this.init.login$(formValue.username, formValue.passwort).subscribe({
			next: user => logger.log('Logged in as ', user),
			error: err => {
				logger.error('Einloggen fehlgeschlagen', err);
				if (err.status !== HttpStatusCode.Unauthorized) {
					this.displayError.showError('Einloggen fehlgeschlagen', err, {
						username: formValue.username,
						password: '[ENTFERNT]',
					});
				}
			},
		});
	}
}
