import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FieldDescription } from 'src/app/model';

@Component({
	selector: 'app-readonly-field',
	templateUrl: './readonly-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['.readonly-numeric {text-align: right}'],
})
export class ReadonlyFieldComponent implements OnInit {
	@Input() metadata?: FieldDescription;
	@Input() label?: string;
	@Input() value: string | undefined | null;
	@Input() extraInputCssClass?: string;

	public cssClass: string = '';

	public resolvedLabel: string = '';

	ngOnInit(): void {
		this.resolvedLabel = this.label || this.metadata?.label || '';

		switch (this.metadata?.typ) {
			case 'number':
			case 'backend-number':
				this.cssClass = 'readonly-numeric';
				break;
		}

		if (this.extraInputCssClass) {
			this.cssClass += this.extraInputCssClass;
		}
	}
}
