import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Content } from 'pdfmake/interfaces';
import { ConfigService } from 'src/app/general/config.service';
import { PdfService } from '../services/pdf.service';

@Component({
	selector: 'app-display-error-dialog',
	templateUrl: './display-error-dialog.component.html',
})
export class DisplayErrorDialogComponent {
	public readonly content: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) private readonly data: DisplayErrorDialogPayload,
		private readonly configService: ConfigService,
		private readonly pdfService: PdfService
	) {
		this.content = data.message + '\r\n';

		if (!data.error) {
			// nothing
		} else if (data.error instanceof HttpErrorResponse) {
			this.content += formatHttpErrorResponse(data.error);
		} else {
			this.content += ': ' + data.error.message;
		}
	}

	pdf(): void {
		this.pdfService
			.createPdf({
				content: [
					...header(this.configService),
					{ text: 'Problem', style: 'section-title', margin: [0, 20, 0, 0] },
					{ text: this.data.message },
					...contextInfo(this.data.context),
					...errorInfo(this.data.error),
				],
				styles: {
					'document-header': {
						fontSize: 22,
						bold: true,
						lineHeight: 1.5,
						alignment: 'center',
					},
					'error-info': {
						fontSize: 10,
					},
					'section-title': {
						fontSize: 16,
						bold: true,
						lineHeight: 1.5,
						margin: [0, 20, 0, 10],
					},
				},
				defaultStyle: {
					lineHeight: 1.25,
				},
			})
			.open();
	}
}

function header(configService: ConfigService): Content[] {
	const backendVersion = configService.backendVersion$.value;
	return [
		{ text: 'EfA-Fehlerprotokoll', style: 'document-header' },
		`Erstellt am: ${new Date().toISOString()}`,
		`Frontend-Version: ${configService.frontendVersion$.value} gebaut am: ${configService.frontendBuildDate$.value}`,
		`Backend-Version: ${backendVersion.releaseVersion}, Commit ${backendVersion.beGitCommit} gebaut am: ${backendVersion.beBuildDateTime}`,
	];
}

function errorInfo(err?: Error): Content[] {
	if (!err) return [];
	if (err instanceof HttpErrorResponse) {
		return [
			{ text: 'Error', style: 'section-title' },
			{
				stack: [
					formatHttpErrorResponse(err)
						.split('\r\n')
						.map(t => ({
							text: t,
						})),
				],
				style: 'error-info',
			},
		];
	}

	return [{ text: err.message }];
}

function contextInfo(context: any): Content[] {
	if (!context) return [];

	const json = JSON.stringify(context, null, '\t');

	return [{ text: 'Context', style: 'section-title', margin: [0, 20, 0, 0] }, ...json.split('\r\n')];
}

export interface DisplayErrorDialogPayload {
	message: string;
	error?: Error;
	context?: any;
}

function formatHttpErrorResponse(err: HttpErrorResponse): string {
	let content = `${err.status} ${err.statusText} ${err.url}\r\n`;
	switch (typeof err.error) {
		case 'undefined':
			break;
		case 'string':
			try {
				const jsonObject = JSON.parse(err.error);
				if (typeof jsonObject.stackTrace === 'string') {
					jsonObject.stackTrace = jsonObject.stackTrace.split('\\r\\n');
				}
				content += JSON.stringify(jsonObject, null, '\t');
			} catch {
				content += err.error;
			}
			break;
		case 'object':
			try {
				const jsonObject = err.error;
				if (typeof jsonObject.stackTrace === 'string') {
					console.error(jsonObject.stackTrace);
					jsonObject.stackTrace = jsonObject.stackTrace.split(/[\r\n]/).filter((l: string) => l);
				}
				content += JSON.stringify(jsonObject, null, '\t');
			} catch {
				content += 'Nicht JSON-able error?!';
			}
			break;
		default:
			content += `${err.error}`;
			break;
	}

	return content;
}
