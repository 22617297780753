import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, catchError, combineLatest, EMPTY, map } from 'rxjs';
import { direktBuchungen, inkassoBuchungen } from 'src/app/berechnung/buchungen';
import { calculateGeschaeftsfallWerte, SteuerUeberschreibung } from 'src/app/berechnung/geschaeftsfaelle';
import { calculateSteuern } from 'src/app/berechnung/steuern';
import { BaseComponent } from 'src/app/general/base-component';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { round2, toInternalModel } from 'src/app/utils';
import {
	GeschaeftsfallService,
	InkassoAbschlussPayload,
	InkassoDraftPayload,
	InkassoPayload,
} from '../../geschaeftsfall.service';
import { geschaeftsfallMetadata, GeschaeftsfallMitPositionen, Steuerart } from '../../model';

const DEKA_GEBUEHR_SATZ = 0.004;
const DEKA_GEBUEHR_MINWERT = toInternalModel(150);

@Component({
	selector: 'app-inkasso-bearbeitung',
	templateUrl: './inkasso-bearbeitung.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./inkasso-bearbeitung.component.scss'],
})
export class InkassoBearbeitungComponent extends BaseComponent implements OnInit {
	public readonly geschaeftsfallMetadata = geschaeftsfallMetadata;

	@Input() geschaeftsfallMitPositionen: GeschaeftsfallMitPositionen = {} as any;
	@Output() reload$ = new EventEmitter<void>();

	public readonly form = new TypedForm<InkassoBearbeitungValues>({
		cdcGebuehren: createFormControl(geschaeftsfallMetadata.fields.cdcGebuehren),
		cdcZinsgutschrift: createFormControl(geschaeftsfallMetadata.fields.cdcZinsgutschrift),
		inkassobetrag: createFormControl(geschaeftsfallMetadata.fields.inkassobetrag),
		dekaGebuehren: createFormControl(geschaeftsfallMetadata.fields.dekaGebuehren),
	});

	public readonly ausgerechneteDekaGebuehren$ = new BehaviorSubject<number>(0);
	public readonly isDeMantel$ = new BehaviorSubject<boolean>(false);

	public readonly showAusgerechneteDekaGebuehrenWarning$ = combineLatest([
		this.ausgerechneteDekaGebuehren$,
		this.form.controls.dekaGebuehren.valueChanges,
	]).pipe(map(([soll, ist]) => (soll && round2(soll) !== round2(ist) ? round2(soll) : null)));

	public readonly ausstehend$ = this.form.values$.pipe(
		map(values => {
			const gf = this.geschaeftsfallMitPositionen.geschaeftsfall;
			if (!gf) return null;

			return (
				(this.geschaeftsfallMitPositionen.geschaeftsfall.bruttobetrag ?? 0) +
				values.cdcZinsgutschrift -
				values.cdcGebuehren -
				values.inkassobetrag
			);
		})
	);

	public readonly cannotClose$ = this.form.values$.pipe(
		map(values => {
			return this.form.invalid || (this.form.controls.inkassobetrag.enabled && !values.inkassobetrag);
		})
	);

	public readonly closeTooltip$ = this.form.values$.pipe(
		map(values => {
			if (this.form.invalid) return 'Eingabe nicht valide!';
			if (!values.inkassobetrag) return 'Inkassobetrag muss grösser 0 sein';
			return 'Inkasso abschliessen';
		})
	);

	public readonly geschaeftsfallMitPositionen$ = new BehaviorSubject<GeschaeftsfallMitPositionen>(
		{} as GeschaeftsfallMitPositionen
	);

	public readonly steuern$ = this.geschaeftsfallMitPositionen$.pipe(map(gf => gf.geschaeftsfall.steuern));
	public readonly zurAuszahlung$ = this.geschaeftsfallMitPositionen$.pipe(map(gf => gf.geschaeftsfall.nettobetrag));

	public showGebuehren = true;
	public istDeKupon = false;
	public showQuest = false; //Emittentensteuer
	public questSum = 0;

	constructor(
		private readonly geschaeftsfallService: GeschaeftsfallService,
		private readonly alert: AlertService,
		private readonly konfigurationService: KonfigurationService,
		private readonly gattungService: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService,
		private injector: Injector
	) {
		super();

		Object.values(this.form.controls).forEach(c => c.addValidators(Validators.required));
	}

	ngOnInit(): void {
		if (!this.geschaeftsfallMitPositionen.geschaeftsfall) throw new Error('No Geschäftsfall?');
		this.geschaeftsfallMitPositionen$.next(JSON.parse(JSON.stringify(this.geschaeftsfallMitPositionen)));

		const gf = this.geschaeftsfallMitPositionen.geschaeftsfall;

		const istDeMantel = !(gf.land !== 'DE') && gf.nummer.startsWith('M');
		this.istDeKupon = gf.land === 'DE' && gf.nummer.startsWith('K');
		if (this.istDeKupon) {
			const questSteuern = this.geschaeftsfallMitPositionen.positionen.map(p =>
				p.steuern
					.filter(s => [Steuerart.QuellensteuerKESt, Steuerart.QuellensteuernSoli].includes(s.steuerart))
					.map(s => s.steuerbetrag)
			);
			// console.warn('quest: ', questSteuern);
			this.questSum = questSteuern.flat().reduce((a, b) => a + b, 0);
			this.showQuest = this.questSum > 0;
		}

		this.showGebuehren = !istDeMantel;

		if (!istDeMantel) {
			// not necessary any more to be async
			if (this.istDeKupon) {
				this.ausgerechneteDekaGebuehren$.next(0);
			} else {
				//LX
				this.ausgerechneteDekaGebuehren$.next(
					Math.max(
						DEKA_GEBUEHR_SATZ * this.geschaeftsfallMitPositionen.geschaeftsfall.bruttobetrag,
						DEKA_GEBUEHR_MINWERT
					)
				);
			}
			this.isDeMantel$.next(false);
			this.form.controls.inkassobetrag.enable();
		} else {
			// DeMantel
			this.ausgerechneteDekaGebuehren$.next(0);
			this.isDeMantel$.next(true);
			this.form.controls.inkassobetrag.disable();
		}

		let inkassobetragCalculated = 0;
		if (istDeMantel) {
			inkassobetragCalculated =
				(gf.cdcZinsgutschrift ?? 0) + (this.geschaeftsfallMitPositionen.geschaeftsfall.bruttobetrag ?? 0);
		} else {
			inkassobetragCalculated = gf.inkassobetrag;
		}
		this.form.setValueTyped({
			cdcGebuehren: gf.cdcGebuehren,
			cdcZinsgutschrift: gf.cdcZinsgutschrift,
			dekaGebuehren: gf.dekaGebuehren,
			inkassobetrag: inkassobetragCalculated,
		});

		this.registerSubscription(
			this.ausgerechneteDekaGebuehren$.subscribe(v => {
				this.form.controls.dekaGebuehren.setValue(v);
			})
		);

		this.registerSubscription(
			combineLatest([this.form.controls.cdcZinsgutschrift.valueChanges, this.isDeMantel$]).subscribe(
				([zinsgutschrift, isDeMantel]) => {
					if (!isDeMantel) return;
					const inkassobetragMantel =
						(zinsgutschrift ?? 0) + (this.geschaeftsfallMitPositionen.geschaeftsfall.bruttobetrag ?? 0);
					this.form.controls.inkassobetrag.setValue(inkassobetragMantel);
				}
			)
		);

		this.registerSubscription(
			this.form.values$.subscribe(values => {
				const newGfMitPositionen = { ...this.geschaeftsfallMitPositionen$.value };
				newGfMitPositionen.geschaeftsfall = {
					...newGfMitPositionen.geschaeftsfall,
					cdcGebuehren: values.cdcGebuehren,
					cdcZinsgutschrift: values.cdcZinsgutschrift,
					inkassobetrag: values.inkassobetrag,
					dekaGebuehren: values.dekaGebuehren,
					buchungen: [],
				};

				calculateSteuern(
					newGfMitPositionen,
					this.gattungService.list$.value,
					this.finanzinstitutService.list$.value
				);

				calculateGeschaeftsfallWerte(newGfMitPositionen);
				if (gf.istInkasso) {
					inkassoBuchungen(
						newGfMitPositionen,
						this.gattungService.list$.value,
						this.konfigurationService.konfiguration$.value,
						this.injector,
					);
				} else {
					direktBuchungen(
						newGfMitPositionen,
						this.gattungService.list$.value,
						this.konfigurationService.konfiguration$.value
					);
				}
				this.geschaeftsfallMitPositionen$.next(newGfMitPositionen);
			})
		);
	}

	public steuernUeberschreiben(steuerUeberschreibung: SteuerUeberschreibung) {
		const newGfMitPositionen = { ...this.geschaeftsfallMitPositionen$.value };
		newGfMitPositionen.geschaeftsfall = {
			...newGfMitPositionen.geschaeftsfall,
			buchungen: [],
		};

		calculateGeschaeftsfallWerte(newGfMitPositionen, steuerUeberschreibung);
		inkassoBuchungen(
			newGfMitPositionen,
			this.gattungService.list$.value,
			this.konfigurationService.konfiguration$.value,
			this.injector,
		);
		this.geschaeftsfallMitPositionen$.next(newGfMitPositionen);
	}

	private execute(payload: InkassoPayload): void {
		this.form.disable();

		this.geschaeftsfallService
			.inkasso$(this.geschaeftsfallMitPositionen.geschaeftsfall.id, payload)
			.pipe(
				catchError(err => {
					this.alert.error('Inkassobearbeitung fehlgeschlagen!', err, payload);
					this.form.enable();
					if (this.isDeMantel$.value) {
						this.form.controls.inkassobetrag.disable();
					}
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.alert.success('Gespeichert');
				this.form.enable();
				if (this.isDeMantel$.value) {
					this.form.controls.inkassobetrag.disable();
				}
				this.reload$.emit();
			});
	}

	saveDraft(): void {
		let values = this.form.typedValue;
		if (this.isDeMantel$.value) {
			values = { ...this.form.typedValue, inkassobetrag: this.form.rawTypedValue.inkassobetrag };
		}
		const payload: InkassoDraftPayload = {
			inkassoAbschliessen: false,
			...values,
		};

		this.execute(payload);
	}

	public abschliessen(): void {
		let values = this.form.typedValue;
		if (this.isDeMantel$.value) {
			values = { ...this.form.typedValue, inkassobetrag: this.form.rawTypedValue.inkassobetrag };
		}
		const currentGeschaeftsfallMitPositionen = this.geschaeftsfallMitPositionen$.value;
		const geschaeftsfall = currentGeschaeftsfallMitPositionen.geschaeftsfall;
		const payload: InkassoAbschlussPayload = {
			...values,
			inkassoAbschliessen: true,
			bruttobetrag: geschaeftsfall.bruttobetrag,
			nettobetrag: geschaeftsfall.nettobetrag,
			kapitalertragssteuer: geschaeftsfall.kapitalertragssteuer,
			kirchensteuer: geschaeftsfall.kirchensteuer,
			zinsabschlagsteuer: geschaeftsfall.zinsabschlagsteuer,
			solidaritaetszuschlag: geschaeftsfall.solidaritaetszuschlag,
			kapitalertragssteuerCalculated: geschaeftsfall.kapitalertragssteuerCalculated,
			kirchensteuerCalculated: geschaeftsfall.kirchensteuerCalculated,
			zinsabschlagsteuerCalculated: geschaeftsfall.zinsabschlagsteuerCalculated,
			solidaritaetszuschlagCalculated: geschaeftsfall.solidaritaetszuschlagCalculated,
			steuern: geschaeftsfall.steuern,
			positionsteuern: currentGeschaeftsfallMitPositionen.positionen.flatMap(p => [
				...p.steuern.map(st => ({ ...st, einreichungspositionId: p.id })),
			]),
			buchungen: geschaeftsfall.buchungen,
		};

		this.execute(payload);
	}
}

interface InkassoBearbeitungValues {
	inkassobetrag: number;
	cdcGebuehren: number;
	cdcZinsgutschrift: number;
	dekaGebuehren: number;
}
