<section class="table-container mat-elevation-z2" tabindex="0">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		[trackBy]="trackBy"
		style="width: 1000px">
		<ng-container matColumnDef="selection">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 4em"></th>
			<td mat-cell *matCellDef="let element" (click.stop)="({})" style="text-align: center">
				<mat-checkbox
					[(ngModel)]="element.isChosen"
					(change)="onChangeChosen()"></mat-checkbox>
			</td>
		</ng-container>
		<ng-container matColumnDef="einreichungsnummer">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th1" mat-sort-header>
				Einreichungsnr.
			</th>
			<td mat-cell *matCellDef="let element">{{ element.einreichung.nummer }}</td>
		</ng-container>
		<ng-container matColumnDef="nummer">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th1" mat-sort-header>
				Geschäftsfallnr.
			</th>
			<td mat-cell *matCellDef="let element">{{ element.geschaeftsfall.nummer }}</td>
		</ng-container>
		<ng-container matColumnDef="wkn">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 8em; padding-left: 1em"
				mat-sort-header>
				{{ metadata.fields.wkn.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.geschaeftsfall.wkn }}</td>
		</ng-container>
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 15em" mat-sort-header>
				{{ metadata.fields.status.label }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.geschaeftsfall.status }}</td>
		</ng-container>
		<ng-container matColumnDef="istInkasso">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 70px" mat-sort-header>
				{{ metadata.fields.istInkasso.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.geschaeftsfall.istInkasso | booleanText }}
			</td>
		</ng-container>
		<ng-container matColumnDef="vorgangsnummer">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 100px"
				mat-sort-header>
				{{ metadata.fields.vorgangsnummer.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.geschaeftsfall.vorgangsnummer }}
			</td>
		</ng-container>
		<ng-container matColumnDef="bruttobetrag">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 8em" mat-sort-header>
				{{ metadata.fields.bruttobetrag.label }}
			</th>
			<td mat-cell *matCellDef="let element" class="money">
				{{ element.geschaeftsfall.bruttobetrag | efaNumber }} EUR
			</td>
		</ng-container>
		<ng-container matColumnDef="istStorno">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 70px" mat-sort-header>
				{{ metadata.fields.istStorno.label }}
			</th>
			<td mat-cell *matCellDef="let element" style="padding-left: 1em">
				{{ element.geschaeftsfall.istStorno | booleanText }}
			</td>
		</ng-container>
		<ng-container matColumnDef="einreicherName">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 15em"
				mat-sort-header="einreicherName">
				Einreicher
			</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="einreicher-name"
				[matTooltip]="element.einreicherName">
				{{ element.einreicherName }}
			</td>
		</ng-container>
		<ng-container matColumnDef="inkassoVersanddatum">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 220px"
				mat-sort-header>
				{{ metadata.fields.inkassoVersanddatum.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.geschaeftsfall.inkassoVersanddatum | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="land">
			<th mat-header-cell *matHeaderCellDef scope="col" style="width: 70px" mat-sort-header>
				{{ metadata.fields.land.label }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.geschaeftsfall.land }}
			</td>
		</ng-container>
		<ng-container matColumnDef="erstelltAm">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th4" mat-sort-header>
				{{ metadata.fields.erstelltAm.label }}
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.geschaeftsfall.erstelltAm | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="geaendertAm">
			<th mat-header-cell *matHeaderCellDef scope="col" class="th4" mat-sort-header>
				{{ metadata.fields.geaendertAm.label }}
			</th>
			<td mat-cell *matCellDef="let element" class="text-hide-overflow">
				{{ element.geschaeftsfall.geaendertAm | date : 'dd.MM.yyyy' }}
			</td>
		</ng-container>

		<!-- TABLE-FILTER-ROW -->
		<ng-container *matHeaderRowDef="displayedColumns" class="table-filter-row">
			<tr mat-header-row></tr>
			<tr class="mat-header-row table-filter-row" [formGroup]="filterForm">
				<th
					scope="col"
					style="padding-left: 16px"
					*ngIf="displayedColumns[0] === 'selection'">
					<mat-checkbox
						(change)="toggleAll()"
						formControlName="selectForExport"></mat-checkbox>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="einreichungsnummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="nummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col"></th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-select
							formControlName="status"
							placeholder="Suche..."
							panelClass="status-filter-panel-250">
							<mat-option [value]="">Kein Statusfilter</mat-option>
							<mat-option
								*ngFor="let status of geschaeftsfallStatus | keyvalue"
								[value]="status.value">
								{{ status.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col"></th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="Suche..."
							formControlName="vorgangsnummer"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col"></th>
				<th class="mat-header-cell" scope="col"></th>
				<th class="mat-header-cell" scope="col"></th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<!-- <mat-label>Wähle Zeitraum</mat-label> -->
						<mat-date-range-input [rangePicker]="versanddatumPicker">
							<input
								matStartDate
								placeholder="Beginn"
								formControlName="inkassoVersanddatumAnfang" />
							<input
								matEndDate
								placeholder="Ende"
								formControlName="inkassoVersanddatumEnde" />
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="versanddatumPicker">
							<mat-icon matDatepickerToggleIcon>today</mat-icon>
						</mat-datepicker-toggle>
						<mat-date-range-picker #versanddatumPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<input
							matInput
							placeholder="..."
							formControlName="land"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-date-range-input [rangePicker]="erstelltAmPicker">
							<input
								matStartDate
								placeholder="Beginn"
								formControlName="erstelltAmAnfang" />
							<input matEndDate placeholder="Ende" formControlName="erstelltAmEnde" />
						</mat-date-range-input>
						<mat-datepicker-toggle
							matSuffix
							[for]="erstelltAmPicker"
							style="padding-left: 0px">
							<mat-icon matDatepickerToggleIcon>today</mat-icon>
						</mat-datepicker-toggle>
						<mat-date-range-picker #erstelltAmPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field appearance="fill">
						<mat-date-range-input [rangePicker]="geaendertAmPicker">
							<input
								matStartDate
								placeholder="Beginn"
								formControlName="geaendertAmAnfang" />
							<input matEndDate placeholder="Ende" formControlName="geaendertAmEnde" />
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="geaendertAmPicker">
							<mat-icon matDatepickerToggleIcon>today</mat-icon>
						</mat-datepicker-toggle>
						<mat-date-range-picker #geaendertAmPicker></mat-date-range-picker>
					</mat-form-field>
				</th>
			</tr>
		</ng-container>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			[class.highlight]="row.shouldHighlight"
			(click)="itemClicked$.next(row)"></tr>
	</table>
</section>
<mat-paginator showFirstLastButtons></mat-paginator>
