import { debounceTime, map, take } from 'rxjs';
import { OppositionService } from './../opposition.service';
import { oppositionMetadata, Opposition } from './../model';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/general/base-component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FieldDescriptionBase } from 'src/app/model';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash';

@Component({
	selector: 'app-opposition-list-page',
	templateUrl: './opposition-list-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OppositionListPageComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;

	public readonly metadata = oppositionMetadata;
	public readonly dataSource = new MatTableDataSource<Opposition>();
	public readonly filterForm = new FormGroup({});

	constructor(
		public readonly oppositionService: OppositionService,
		private datePipe: DatePipe
	) {
		super();

		for (const field of this.allColumns) {
			this.filterForm.addControl(field, new FormControl(''));
		}

		const filterFormValues$ = this.filterForm.valueChanges.pipe(
			debounceTime(200),
			map(v => ({ ...v }))
		);

		let filterIndex = 0;
		this.registerSubscription(
			filterFormValues$.subscribe(filterValues => {
				this.dataSource.filterPredicate = data => {
					const critIsin = 'isin';
					if (
						critIsin in filterValues &&
						filterValues[critIsin] &&
						!data[critIsin]?.includes(filterValues[critIsin].toString())
					)
						return false;

					const critWkn = 'wkn';
					if (
						critWkn in filterValues &&
						filterValues[critWkn] &&
						!data[critWkn]?.includes(filterValues[critWkn].toString())
					)
						return false;

					return true;
				};
				filterIndex++;
				this.dataSource.filter = filterIndex.toFixed();
			}) //subscribe
		); // registerSubscription

		this.registerSubscription(
			this.oppositionService.isImportDone$.subscribe(isDone => {
				if (isDone) {
					console.log(isDone, '===isDone');
					this.oppositionService.loadAll$();
				}
			})
		);

	}

	public get allColumns(): string[] {
		const ret = Object.keys(this.metadata.fields);
		return ret;
	}

	public getColumnDef(columnKey: string): FieldDescriptionBase {
		const _fields = this.metadata.fields as any;
		if (columnKey in _fields) {
			const ret = _fields[columnKey];
			return ret;
		}
		return {} as FieldDescriptionBase;
	}

	public getValue(el: any, column: string) {
		const coldef: FieldDescriptionBase = this.getColumnDef(column);
		switch (coldef.typ) {
			case 'datum':
				const ret = this.datePipe.transform(el[column], 'dd.MM.yyyy');
				return ret;
			default:
				return el[column];
		}
	}

	ngOnInit(): void {
		this.registerSubscription(
			this.oppositionService.isImportingCsv$.subscribe(isImporting => { })
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}

		if (this.sort) {
			this.dataSource.sort = this.sort;
		}

		setTimeout(() => {
			this.registerSubscription(
				this.oppositionService.list$.subscribe(opp => {
					this.dataSource.data = opp;
					console.log(this.dataSource.data, '===opp');
				})
			);
		});
	}

	onClickCsvImport() {
		this.oppositionService.showCsvImportDialog();
	}
}
