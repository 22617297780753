import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as ExcelJS from 'ExcelJS-workaround';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { AlertService } from 'src/app/shared/alert.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { logger } from 'src/logger';
import { EinreichungListItem } from '../einreichung-list/einreichung-list.component';
import { EinreichungService } from '../einreichung.service';
import {
	Einreichung,
	einreichungMetadata,
	EinreichungStatus,
	getEinreicherName,
} from '../model';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/general/base-component';
import { InitService } from 'src/app/general/init/init.service';

@Component({
	selector: 'app-einreichung-list-page',
	templateUrl: './einreichung-list-page.component.html',
	styleUrls: ['./einreichung-list-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungListPageComponent extends BaseComponent implements OnInit {
	public readonly metadata = einreichungMetadata;
	public readonly einreichungStatus = EinreichungStatus;
	public readonly isRefreshing$ = new BehaviorSubject(false);
	public numberOfChosen = 0;
	public readonly isLoading$ = new BehaviorSubject(false);

	constructor(
		private readonly quickInfo: QuickInfoPopupService,
		private readonly konfigurationService: KonfigurationService,
		public readonly activatedRoute: ActivatedRoute,
		public readonly einreichungen: EinreichungService,
		private readonly alert: AlertService,
		private readonly finanzinstituteService: FinanzinstitutService,
		private readonly router: Router,
		private readonly save: SaveService,
		private readonly matDialog: MatDialog,
		private readonly initService: InitService
	) {
		super();
	}

	ngOnInit(): void {
		this.einreichungen.isImportingKistamCsv$.subscribe(isImporting => { });
	}

	refresh(): void {
		if (this.isRefreshing$.value) return;

		this.initService.lastNavigationDate$.next(new Date());


		this.isRefreshing$.next(true);
		this.einreichungen.loadAll$().subscribe({
			next: () => {
				this.alert.success('Fertig');
				this.isRefreshing$.next(false);
			},
			error: err => {
				logger.error(err);
				this.alert.error('Aktualisierung fehlgeschlagen!', err);
				this.isRefreshing$.next(false);
			},
		});
	}

	openPopup(einreichung: Einreichung): void {
		this.quickInfo.showEinreichung(einreichung);
	}

	openFileKistamFileimportDialog(): void {
		this.einreichungen.showImportKistamCsvDialog();
	}

	open(einreichung: Einreichung): void {
		this.router.navigate([einreichungMetadata.routing.view?.url(einreichung.id)]);
	}

	downloadAsExcel(data: EinreichungListItem[]): void {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(this.metadata.label);
		worksheet.addRow([
			this.metadata.fields.nummer.label,
			'Einreicher',
			this.metadata.fields.eingangsdatum.label,
			this.metadata.fields.freigegebenAm.label,
			this.metadata.fields.absender.label,
			this.metadata.fields.status.label,
			'Dokumentreferenz',
		]);
		for (const einreichung of data) {
			worksheet.addRow([
				einreichung.nummer,
				getEinreicherName(einreichung, this.finanzinstituteService.list$.value),
				einreichung.eingangsdatum?.toLocaleDateString('de-DE'),
				einreichung.freigegebenAm?.toLocaleDateString('de-DE'),
				einreichung.absender,
				einreichung.status,
				`${this.konfigurationService.konfiguration$.value.dokumentenVerzeichnis}/${einreichung.nummer}`,
			]);
		}
		workbook.xlsx.writeBuffer().then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			saveAs(blob, this.metadata.apiCollectionName + '.xlsx');
		});
	}

	onChosenChange($event: number) {
		this.numberOfChosen = $event;
	}

	csvExportKistam(data: EinreichungListItem[]) {
		const selectedItems = data.filter(c => c.isChosen).map(c => c.nummer);
		const payload = { einreichungsnummerList: selectedItems };
		if (
			!!payload &&
			payload.einreichungsnummerList &&
			payload.einreichungsnummerList.length
		) {
			this.isLoading$.next(true);
			this.einreichungen.getKistamDocument$(payload).subscribe({
				next: result => {
					this.save.saveAs(result.data, result.filename);
					this.alert.success('Download erfolgreich');
					this.isLoading$.next(false);
				},
				error: err => {
					this.alert.error('Download fehlgeschlagen', err);
					this.isLoading$.next(false);
				},
			});
		}
	}
}
