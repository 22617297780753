<table class="buchungen-table mat-table cdk-table" style="border-spacing: 1em 0" role="table" *ngIf="geschaeftsfall">
	<thead role="rowgroup">
		<tr class="mat-header-row cdk-header-row">
			<th class="mat-header-cell cdk-header-cell" scope="col"></th>
			<th class="mat-header-cell cdk-header-cell" scope="col">KESt</th>
			<th class="mat-header-cell cdk-header-cell" scope="col">Kirchensteuer</th>
			<th class="mat-header-cell cdk-header-cell" scope="col">Solidaritätszuschlag</th>
			<th class="mat-header-cell cdk-header-cell" scope="col">ZASt</th>
			<th class="mat-header-cell cdk-header-cell" scope="col">Steuern Gesamt</th>
		</tr>
	</thead>
	<tbody role="rowgroup">
		<tr class="mat-row cdk-row">
			<td class="mat-cell cdk-cell">Kalkulierte Steuern</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				{{ geschaeftsfall.kapitalertragssteuerCalculated | efaNumber }} EUR
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				{{ geschaeftsfall.kirchensteuerCalculated | efaNumber }} EUR
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				{{ geschaeftsfall.solidaritaetszuschlagCalculated | efaNumber }} EUR
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				{{ geschaeftsfall.zinsabschlagsteuerCalculated | efaNumber }} EUR
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">{{ originaleSteuern | efaNumber }} EUR</td>
		</tr>
		<tr [formGroup]="form">
			<td class="mat-cell cdk-cell">Steuernüberschreibung</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				<app-numeric-input symbol="EUR" formControlName="kapitalertragssteuer"></app-numeric-input>
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				<app-numeric-input symbol="EUR" formControlName="kirchensteuer"></app-numeric-input>
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				<app-numeric-input symbol="EUR" formControlName="solidaritaetszuschlag"></app-numeric-input>
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">
				<app-numeric-input symbol="EUR" formControlName="zinsabschlagsteuer"></app-numeric-input>
			</td>
			<td class="mat-cell cdk-cell" style="text-align: right">{{ geschaeftsfall.steuern | efaNumber }} EUR</td>
		</tr>
	</tbody>
</table>
