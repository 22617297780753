<div style="display: flex; gap: 3em">
	<mat-card style="flex: 30em 0 0">
		<mat-card-header><mat-card-title>Inkassoeingang erfassen</mat-card-title></mat-card-header>
		<form [formGroup]="form" (ngSubmit)="saveDraft()">
			<div>
				<app-readonly-field
					[metadata]="geschaeftsfallMetadata.fields.bruttobetrag"
					[value]="
						(geschaeftsfallMitPositionen.geschaeftsfall.bruttobetrag | efaNumber) + ' EUR'
					"></app-readonly-field>
			</div>

			<div *ngIf="!istDeKupon">
				<app-numeric-input
					[metadata]="geschaeftsfallMetadata.fields.cdcZinsgutschrift"
					[label]="showGebuehren ? 'CDC Zinsgutschrift' : 'Zinsgutschrift auf liquidierte Mänteln'"
					symbol="EUR"
					formControlName="cdcZinsgutschrift"></app-numeric-input>
			</div>

			<div *ngIf="showGebuehren && !istDeKupon">
				<app-numeric-input
					[metadata]="geschaeftsfallMetadata.fields.cdcGebuehren"
					symbol="EUR"
					formControlName="cdcGebuehren"></app-numeric-input>
			</div>

			<div *ngIf="showQuest">
				<app-readonly-field
					label="Emittentensteuer"
					extraInputCssClass="readonly-numeric"
					style="display: block"
					[value]="(questSum | efaNumber) + ' EUR'"></app-readonly-field>
			</div>

			<div>
				<app-numeric-input
					[label]="showQuest ? 'Inkassobetrag inkl. Emittentensteuer' : 'Inkassobetrag'"
					[metadata]="geschaeftsfallMetadata.fields.inkassobetrag"
					symbol="EUR"
					formControlName="inkassobetrag"></app-numeric-input>
			</div>

			<div style="display: block" *ngIf="showGebuehren">
				<app-readonly-field
					label="Ausstehender Betrag"
					extraInputCssClass="readonly-numeric"
					style="display: block"
					[value]="(ausstehend$ | async | efaNumber) + ' EUR'"></app-readonly-field>
				<p *ngIf="(ausstehend$ | async) !== 0" class="error-text">Ausstehender Betrag ist nicht 0!</p>
			</div>

			<div>
				<app-readonly-field
					[metadata]="geschaeftsfallMetadata.fields.steuern"
					[value]="(steuern$ | async | efaNumber) + ' EUR'"></app-readonly-field>
			</div>

			<div style="display: block" *ngIf="showGebuehren">
				<app-numeric-input
					[metadata]="geschaeftsfallMetadata.fields.dekaGebuehren"
					symbol="EUR"
					style="display: block"
					formControlName="dekaGebuehren"></app-numeric-input>
				<p *ngIf="showAusgerechneteDekaGebuehrenWarning$ | async as value" class="error-text">
					Weicht von Standardgebühren {{ value | efaNumber }} EUR ab!
				</p>
			</div>

			<div style="display: block">
				<app-readonly-field
					label="Zur Auszahlung"
					extraInputCssClass="readonly-numeric"
					[value]="(zurAuszahlung$ | async | efaNumber) + ' EUR'"></app-readonly-field>
				<mat-error *ngIf="((zurAuszahlung$ | async) ?? 0) < 0">Auszuzahlender Betrag kleiner 0!</mat-error>
			</div>

			<div>
				<div style="display: flex; gap: 1em">
					<button type="submit" mat-raised-button color="accent" *requireRole="'standard'">
						<mat-icon>save</mat-icon>
						Speichern
					</button>
					<button
						type="button"
						mat-raised-button
						color="primary"
						*requireRole="'standard'"
						[disabled]="cannotClose$ | async"
						[matTooltip]="(closeTooltip$ | async) ?? ''"
						(click)="abschliessen()">
						<mat-icon>payments</mat-icon>
						Abschliessen
					</button>
				</div>
			</div>
		</form>
	</mat-card>
	<div style="overflow-y: scroll">
		<div
			*ngIf="geschaeftsfallMitPositionen$ | async as gf"
			style="display: flex; flex-direction: column; gap: 2em; margin-top: 1em">
			<mat-card *requireRole="'erweitert'">
				<mat-card-header><mat-card-title>Steuernüberschreibung</mat-card-title></mat-card-header>
				<app-geschaeftsfall-steuern-ueberschreiben
					[geschaeftsfall]="gf.geschaeftsfall"
					(ueberschreiben$)="steuernUeberschreiben($event)"></app-geschaeftsfall-steuern-ueberschreiben>
			</mat-card>
			<mat-card>
				<app-geschaeftsfall [geschaeftsfallMitPositionen]="gf" positionenLocation="below"></app-geschaeftsfall>
			</mat-card>
			<mat-card>
				<mat-card-header><mat-card-title>Buchungen</mat-card-title></mat-card-header>
				<app-geschaeftsfall-buchungen
					[geschaeftsfallMitPositionen]="gf"
					header="hide"></app-geschaeftsfall-buchungen>
			</mat-card>
			<mat-card>
				<mat-card-header><mat-card-title>Positionsteuern</mat-card-title></mat-card-header>
				<app-geschaeftsfall-steuern [geschaeftsfallMitPositionen]="gf"></app-geschaeftsfall-steuern>
			</mat-card>
		</div>
	</div>
</div>
