import { resolveForwardRef, Type } from '@angular/core';
import {
	AbstractControl,
	UntypedFormControl,
	FormControlOptions,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { Route } from '@angular/router';
import { toExternalModel } from './utils';

export enum RequiredLevel {
	NotRequired,
	RequiredToSave,
	RequiredForFreigabe,
}

export interface FieldDescriptionBase {
	label?: string;
	typ: string;
	/** Hilfetext */
	description?: string | undefined;
	requiredLevel?: RequiredLevel;
	placeholder?: string;
	// Styling
	labelStyle?: string;
}

export interface TextFieldDescriptionBase extends FieldDescriptionBase {
	typ: 'text';
	minLength?: number;
	mussNumerischSein?: boolean;
	defaultValue?: string;
	referenzlist?: string;
}

export interface ExactLengthTextFieldDescription extends TextFieldDescriptionBase {
	typ: 'text';
	exactLength: number;
}

export interface TextFieldDescription extends TextFieldDescriptionBase {
	typ: 'text';
	maxLength: number;
	isName?: boolean;
}

export interface BackendFieldDescription extends FieldDescriptionBase {
	typ: 'backend-text' | 'backend-datum' | 'backend-number';
}

export interface IBANFieldDescription extends FieldDescriptionBase {
	typ: 'iban';
}

export interface BooleanFieldDescription extends FieldDescriptionBase {
	typ: 'boolean';
	defaultValue?: boolean;
}

export interface ReferenzlistFieldDescription extends FieldDescriptionBase {
	typ: 'referenzlist';
	referenzlist: string;
	defaultValue?: string;
}

export interface DateFieldDescription extends FieldDescriptionBase {
	typ: 'datum';
}

export interface ReferenceFieldDescription extends FieldDescriptionBase {
	typ: 'reference';
	object: 'gattung' | 'finanzinstitut' | 'einreichung';
}

export interface NumericFieldDescription extends FieldDescriptionBase {
	typ: 'number';
	minValue?: number;
	maxValue?: number;
	/** Anzahl von Nachkommastellen */
	decimals?: number;
	defaultValue?: number;
	showAsPercentage?: boolean;
	isEfaNumber: boolean;
}

export interface FixedListFieldDescription extends FieldDescriptionBase {
	typ: 'fixedlist';
	values:
	| string[]
	| { value: boolean | null; label: string; }[]
	| { value: string | null; label: string; }[];
}

export interface SonderFieldDescription extends FieldDescriptionBase {
	typ:
	| 'ausschuettungsdatum'
	| 'zuVersteuerndeVorabpauschale'
	| 'stueckenummer'
	| 'positionen'
	| 'personen'
	| 'steuern'
	| 'buchungen'
	| 'kycChecks';
}

export type FieldDescription =
	| TextFieldDescription
	| ExactLengthTextFieldDescription
	| ReferenzlistFieldDescription
	| NumericFieldDescription
	| IBANFieldDescription
	| ReferenceFieldDescription
	| DateFieldDescription
	| FixedListFieldDescription
	| BooleanFieldDescription
	| SonderFieldDescription
	| BackendFieldDescription;

export interface EntityMetadata<TEntity> {
	label: string;
	labelGender: 'm' | 'w' | 'n';
	apiCollectionName: string;
	plural: string;
	routing: {
		list?: {
			url: string;
			component: Type<any>;
		};
		edit?: {
			url: (id: string) => string; component: Type<any>;
			canDeactivate?: any[];
		};
		view?: { url: (id: string) => string; component: Type<any>; };
	};
	fields: Omit<Record<keyof TEntity, FieldDescription>, 'id'>;
}

export function createFormControl(
	fieldDescription: FieldDescription,
	initialValue?: any,
	options?: FormControlOptions
): UntypedFormControl {
	const validators: ValidatorFn[] = [];
	if (fieldDescription.requiredLevel === RequiredLevel.RequiredToSave) {
		validators.push(Validators.required);
	}

	switch (fieldDescription.typ) {
		case 'boolean':
			if (
				(initialValue === null || initialValue === undefined) &&
				fieldDescription.defaultValue !== undefined
			) {
				initialValue = fieldDescription.defaultValue;
			}
			break;

		case 'referenzlist':
			if (!initialValue && fieldDescription.defaultValue) {
				initialValue = fieldDescription.defaultValue;
			}
			break;

		case 'text':
			if (fieldDescription.minLength) {
				validators.push(Validators.minLength(fieldDescription.minLength));
			}

			if ((fieldDescription as TextFieldDescription).maxLength) {
				validators.push(
					Validators.maxLength((fieldDescription as TextFieldDescription).maxLength)
				);
			}

			if (fieldDescription.mussNumerischSein) {
				validators.push(mussNumerischSein);
			}

			if ((fieldDescription as ExactLengthTextFieldDescription).exactLength) {
				validators.push(
					exactLength((fieldDescription as ExactLengthTextFieldDescription).exactLength)
				);
			}

			if (!initialValue && fieldDescription.defaultValue)
				initialValue = fieldDescription.defaultValue;

			if ((fieldDescription as TextFieldDescription).isName) {
				validators.push(isName);
			}

			break;

		case 'number':
			if (fieldDescription.minValue) {
				validators.push(Validators.min(fieldDescription.minValue));
			}

			if (fieldDescription.maxValue) {
				if (fieldDescription.isEfaNumber) {
					validators.push(efaMaxNumber(fieldDescription.maxValue));
				} else {
					validators.push(Validators.max(fieldDescription.maxValue));
				}
			}

			if (!initialValue && fieldDescription.defaultValue)
				initialValue = fieldDescription.defaultValue;

			break;

		case 'iban':
			validators.push(Validators.maxLength(32));

			break;
	}

	const finalOptions: FormControlOptions = { ...options };
	if (!finalOptions.validators) {
		finalOptions.validators = validators;
	} else if (typeof finalOptions.validators === 'function') {
		finalOptions.validators = [finalOptions.validators, ...validators];
	} else {
		finalOptions.validators = [...finalOptions.validators, ...validators];
	}

	const control = new UntypedFormControl(initialValue ?? '', finalOptions);

	return control;
}

export function generateRoutes<TEntity>(metadata: EntityMetadata<TEntity>): Route[] {
	const routes: Route[] = [];
	const editRouting = metadata.routing.edit;
	if (editRouting) {
		routes.push({
			path: editRouting.url(':id').substring(1),
			component: resolveForwardRef(editRouting.component),
			canDeactivate: editRouting.canDeactivate,
		});
	}

	const viewRouting = metadata.routing.view;
	if (viewRouting) {
		routes.push({
			path: viewRouting.url(':id').substring(1),
			component: resolveForwardRef(viewRouting.component),
		});
	}

	const listRouting = metadata.routing.list;
	if (listRouting) {
		routes.push({
			path: listRouting.url.substring(1),
			component: resolveForwardRef(listRouting.component),
		});
	}

	return routes;
}

function exactLength(
	exactLength: number
): (control: AbstractControl) => ValidationErrors | null {
	return control => {
		const value = control.value;
		if (!value) return null;
		if (typeof value !== 'string') return null;
		if (value.length === exactLength) return null;
		return { exactLength: exactLength };
	};
}

function efaMaxNumber(
	maxNumber: number
): (control: AbstractControl) => ValidationErrors | null {
	return control => {
		const value = control.value;
		if (typeof value !== 'number') return null;
		if (!value) return null;
		const efaValue = toExternalModel(value);
		if (efaValue > maxNumber) {
			return { max: maxNumber };
		}
		return null;
	};
}

function mussNumerischSein(control: AbstractControl): ValidationErrors | null {
	if (!control.value) return null;

	if (/^[0-9]*$/.test(control.value)) return null;

	return { mussNumerischSein: true };
}

export function evaluateFreigabe<TEntity>(
	obj: TEntity,
	metadata: EntityMetadata<TEntity>
): string[] {
	const result: string[] = [];
	for (const [field, fieldDescription] of Object.entries(metadata.fields) as [
		keyof TEntity,
		FieldDescription
	][]) {
		if (
			fieldDescription.requiredLevel === RequiredLevel.RequiredForFreigabe ||
			fieldDescription.requiredLevel === RequiredLevel.RequiredToSave
		) {
			const fieldValue = obj[field] as any;
			if (fieldValue === '' || fieldValue === undefined || fieldValue === null) {
				result.push(`Feld "${fieldDescription.label}" muss einen Wert haben!`);
			}
		}
	}

	return result;
}

export function isName(control: AbstractControl): ValidationErrors | null {
	if (!control.value) return null;

	if (/^[^&]*$/.test(control.value)) return null;

	return { isName: true };
}
