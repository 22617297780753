<h1 mat-dialog-title>Geschäftsfall abschliessen</h1>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="accept()">
	<app-text-input formControlName="vorgangsnummer" [metadata]="metadata.fields.vorgangsnummer"></app-text-input>
</form>

<div mat-dialog-actions style="display: flex; gap: 1em">
	<button mat-raised-button color="accent" (click)="tryCancel()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" (click)="accept()" [disabled]="cannotAccept$ | async">
		<mat-icon>check</mat-icon>
		Bestätigen
	</button>
</div>
