import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AssetService {
	constructor(private readonly http: HttpClient) {}

	public getAll$(): Observable<AssetMoniker[]> {
		return this.http.get<AssetMoniker[]>('/asset');
	}

	public get$(schluessel: string): Observable<{ data: Blob; filename: string }> {
		return this.http
			.get(`/asset/${schluessel}`, { observe: 'response', responseType: 'blob' })
			.pipe(
				map(response => {
					if (!response.body) throw new Error('No body!');

					let filename = 'file';
					const contentDisposition = response.headers.get('content-disposition');
					if (contentDisposition) {
						const filenameInContentDisposition = contentDisposition
							.split(';')
							.map(item => item.split('=', 2).map(i => i.trim()))
							.filter(itemSplit => itemSplit.length === 2)
							.find(itemSplit => itemSplit[0] === 'filename');

						if (filenameInContentDisposition) {
							filename = filenameInContentDisposition[1];
							console.log('filename', filename);
							if (filename[0] === '"' && filename[filename.length - 1] === '"') {
								filename = filename.substring(1, filename.length - 1);
							}
						}
					}

					console.log(
						response.headers.keys().map(k => ({ k, v: response.headers.getAll(k) }))
					);
					return {
						data: response.body,
						filename,
					};
				})
			);
	}

	upload$({ schluessel, file }: { schluessel: string; file: File }): Observable<void> {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post<void>(`/asset/${schluessel}`, formData);
	}
}

export interface AssetMoniker {
	schluessel: string;
	mimeType: string;
	geaendertAm: string;
	geaendertVon: string;
}
