import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { logger } from 'src/logger';
import { EinreichungDetailsModalComponent } from '../bewegungsdaten/einreichung-details-modal/einreichung-details-modal.component';
import { EinreichungService } from '../bewegungsdaten/einreichung.service';
import { Einreichung } from '../bewegungsdaten/model';
import { FinanzinstitutDetailsModalComponent } from './finanzinstitut/finanzinstitut-details-modal/finanzinstitut-details-modal.component';
import { FinanzinstitutService } from './finanzinstitut/finanzinstitut.service';
import { Finanzinstitut } from './finanzinstitut/model';
import { GattungDetailsModalComponent } from './gattung/gattung-details-modal/gattung-details-modal.component';
import { GattungService } from './gattung/gattung.service';
import { Gattung } from './gattung/model';

@Injectable({
	providedIn: 'root',
})
export class QuickInfoPopupService {
	constructor(
		private readonly matDialog: MatDialog,
		private readonly finanzinstitutService: FinanzinstitutService,
		private readonly gattungService: GattungService,
		private readonly einreichungService: EinreichungService
	) {}

	showFinanzinstitut(finanzinstitutOrId: Finanzinstitut | string | null | undefined) {
		if (!finanzinstitutOrId) return;
		let institut: Finanzinstitut | undefined;

		switch (typeof finanzinstitutOrId) {
			case 'string':
				institut = this.finanzinstitutService.list$.value.find(
					i => i.id === finanzinstitutOrId
				);
				break;
			case 'object':
				institut = finanzinstitutOrId;
				break;
		}

		if (institut) {
			this.matDialog.open(FinanzinstitutDetailsModalComponent, {
				data: institut,
				minWidth: '80vw',
			});
		} else {
			console.error('showFinanzinstitut called with bad value', finanzinstitutOrId);
		}
	}

	showGattung(gattungOrId: Gattung | string | null | undefined) {
		if (!gattungOrId) return;
		if (typeof gattungOrId === 'object') {
			this.matDialog.open(GattungDetailsModalComponent, {
				data: gattungOrId,
				minWidth: '80vw',
			});
		} else {
			const gattung = this.gattungService.list$.value.find(i => i.id === gattungOrId);
			if (gattung) {
				this.matDialog.open(GattungDetailsModalComponent, {
					data: gattung,
					minWidth: '80vw',
				});
			} else {
				logger.warn(`Popup called for Gattung ${gattungOrId} but not found!`);
			}
		}
	}

	showEinreichung(einreichungOrId: Einreichung | string | null | undefined) {
		if (!einreichungOrId) return;
		if (typeof einreichungOrId === 'object') {
			this.matDialog.open(EinreichungDetailsModalComponent, {
				data: einreichungOrId,
				minWidth: '80vw',
			});
		} else {
			const einreichung = this.einreichungService.list$.value.find(
				i => i.id === einreichungOrId
			);
			if (einreichung) {
				this.matDialog.open(EinreichungDetailsModalComponent, {
					data: einreichung,
					minWidth: '80vw',
				});
			} else {
				logger.warn(`Popup called for Einreichung ${einreichungOrId} but not found!`);
			}
		}
	}
}
