import { Platform } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
	NativeDateAdapter,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS } from '@angular/material/progress-bar';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MAT_SORT_DEFAULT_OPTIONS } from '@angular/material/sort';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { format, parse } from 'date-fns';
import { ReplaySubject } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { BewegungsdatenModule } from './bewegungsdaten/bewegungsdaten.module';
import { AppComponent } from './general/app/app.component';
import { AuthHttpInterceptor } from './general/authHttpInterceptor';
import { GeneralModule } from './general/general.module';
import { LogHttpInterceptor } from './general/logHttpInterceptor';
import { RequiredLevel } from './model';
import { SharedModule } from './shared/shared.module';
import { BenutzerService } from './stammdaten/benutzer/benutzer.service';
import { BenutzerServiceMock } from './stammdaten/benutzer/benutzer.service.mock';
import {
	KuerzelBezeichnungReferenzMetadata,
	REFERENZLIST_METADATA,
} from './stammdaten/kuerzel-bezeichnung/model';
import { StammdatenModule } from './stammdaten/stammdaten.module';

const referenzlistenMetadata: KuerzelBezeichnungReferenzMetadata = {
	waehrung: {
		apiCollectionName: 'waehrung',
		label: 'Währungen',
		kuerzel: {
			label: 'Kürzel (ISO-3)',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			exactLength: 3,
		},
		bezeichnung: {
			label: 'Name',
			typ: 'text',
			maxLength: 30,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		readonly: true,
	},
	land: {
		apiCollectionName: 'land',
		label: 'Länder',
		kuerzel: {
			label: 'Kürzel (ISO-2)',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			exactLength: 2,
		},
		bezeichnung: {
			label: 'Name',
			typ: 'text',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		extra: {
			label: 'Bundesbanknummer',
			typ: 'number',
			isEfaNumber: false,
			requiredLevel: RequiredLevel.NotRequired,
		},
	},
	kistam: {
		apiCollectionName: 'kistam',
		label: 'Kirchensteuer-Merkmale',
		kuerzel: {
			label: 'Kürzel (6 Zeichen)',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			exactLength: 6,
		},
		bezeichnung: {
			label: 'Religionszugehörigkeit',
			typ: 'text',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
	},
	bundesland: {
		apiCollectionName: 'bundesland',
		label: 'Bundesländer',
		kuerzel: {
			label: 'Kürzel (2 Zeichen)',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			exactLength: 2,
		},
		bezeichnung: {
			label: 'Name',
			typ: 'text',
			maxLength: 30,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		readonly: true,
	},
	ausweisart: {
		apiCollectionName: 'ausweisart',
		label: 'Ausweisarten',
		kuerzel: {
			label: 'Kürzel (1 Zeichen)',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			exactLength: 1,
		},
		bezeichnung: {
			label: 'Name',
			typ: 'text',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
	},
	postleitzahl: {
		apiCollectionName: 'postleitzahl',
		label: 'Postleitzahlen',
		kuerzel: {
			label: 'Postleitzahl',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			mussNumerischSein: true,
			exactLength: 5,
		},
		bezeichnung: {
			label: 'Ort',
			typ: 'text',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		extra: {
			label: 'Bundesland',
			typ: 'referenzlist',
			requiredLevel: RequiredLevel.RequiredToSave,
			referenzlist: 'bundesland',
		},
	},
	bankleitzahl: {
		apiCollectionName: 'bankleitzahl',
		label: 'Bankleitzahlen',
		kuerzel: {
			label: 'BLZ',
			typ: 'text',
			requiredLevel: RequiredLevel.RequiredToSave,
			mussNumerischSein: true,
			exactLength: 8,
		},
		bezeichnung: {
			label: 'Institutsname',
			typ: 'text',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
		},
		extra: {
			label: 'BIC',
			typ: 'text',
			requiredLevel: RequiredLevel.NotRequired,
			minLength: 6,
			maxLength: 15,
		},
	},
	investmentsteuerart: {
		apiCollectionName: 'investmentsteuerart',
		label: 'Investmentsteuerarten',
		readonly: true,
		kuerzel: {
			typ: 'text',
			exactLength: 200,
			label: 'Kürzel',
		},
		bezeichnung: { label: 'Steuerfrei (% Wert)', typ: 'text', maxLength: 10 },
		extra: { label: 'Steuerpflichtig (% Wert)', typ: 'text', maxLength: 10 },
	},
	// basiszinssatz: {
	// 	apiCollectionName: 'basiszinssatz',
	// 	label: 'Basiszinssatz',
	// 	kuerzel: {
	// 		typ: 'text',
	// 		exactLength: 10,
	// 		label: 'Startdatum (Format YYYY-MM-DD)',
	// 		requiredLevel: RequiredLevel.RequiredToSave,
	// 	},
	// 	bezeichnung: { label: 'Bemerkung', typ: 'text', maxLength: 200 },
	// 	extra: {
	// 		label: 'Zinssatz in % (Format XXX,XX) ',
	// 		typ: 'number',
	// 		isEfaNumber: false,
	// 		minValue: 0,
	// 		maxValue: 100,
	// 		decimals: 2,
	// 	},
	// },
};

export const appDateFormats = {
	parse: {
		dateInput: 'dd.MM.yyyy',
	},
	display: {
		dateInput: 'dd.MM.yyyy',
		monthYearLabel: 'MMM yyyy',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM yyyy',
	},
};

export class AppDateAdapter extends NativeDateAdapter {
	constructor(matDateLocale: string, platform: Platform) {
		super(matDateLocale, platform);
	}

	parse(value: any): Date | null {
		if (typeof value === 'string') {
			return parse(value, appDateFormats.parse.dateInput, new Date());
		} else {
			return null;
		}
	}

	format(date: Date, displayFormat: any): string {
		return format(date, displayFormat);
	}
}

@NgModule({
	declarations: [],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		GeneralModule,
		StammdatenModule,
		BewegungsdatenModule,
		SharedModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: AuthHttpInterceptor,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: LogHttpInterceptor,
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
		{
			provide: DateAdapter,
			useClass: AppDateAdapter,
			deps: [MAT_DATE_LOCALE, Platform],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: appDateFormats,
		},
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
		{ provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		{ provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		{ provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		{ provide: MAT_SORT_DEFAULT_OPTIONS, useValue: { disableClear: true } },
		{
			provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
			useValue: {
				pageSize: 20,
				showFirstLastButtons: false,
				hidePageSize: false,
				pageSizeOptions: [10, 20, 30, 50],
			},
		},
		{ provide: MAT_TABS_CONFIG, useValue: { disablePagination: true } },
		{
			provide: BenutzerService,
			useFactory: () => {
				const benutzerServiceMock = new BenutzerServiceMock();
				benutzerServiceMock.loadAllMock = () => {
					const subject = new ReplaySubject<void>(1);
					subject.next();
					subject.complete();
					return subject;
				};
				return benutzerServiceMock;
			},
		},
		{
			provide: REFERENZLIST_METADATA,
			useValue: referenzlistenMetadata,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
