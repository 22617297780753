import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import {
	BehaviorSubject,
	catchError,
	forkJoin,
	map,
	Observable,
	of,
	switchMap,
	take,
} from 'rxjs';
import { logger } from 'src/logger';
import { AlertService } from '../shared/alert.service';

import { EntityServiceBase } from '../stammdaten/entity.service.base';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from '../stammdaten/kuerzel-bezeichnung/kuerzel-bezeichnung-referenzlist.service';
import {
	formatDateForSaveModel,
	parseIncomingAsArray,
	parseIncomingAsDate,
	round2,
	toExternalModel,
	toInternalModel,
} from '../utils';
import { Buchung } from './buchungen/model';
import { EinreichungFileimportModalComponent } from './einreichung-fileimport-modal/einreichung-fileimport-modal.component';
import {
	Einreichung,
	einreichungMetadata,
	Einreichungsperson,
	Einreichungspositionssteuer,
	EinreichungStatus,
	Religionszugehoerigkeit,
	StatusHistorieEintrag,
	VorabpauschaleSteuersummen,
} from './model';

@Injectable({
	providedIn: 'root',
})
export class EinreichungService extends EntityServiceBase<Einreichung, EinreichungSaveModel> {
	public readonly isImporting2Filenet$ = new BehaviorSubject(false);

	public readonly isImportingKistamCsv$ = new BehaviorSubject(false);
	public readonly result4KistamCsvImport$ = new BehaviorSubject<Array<KistamUpdateResult>>(
		[]
	);
	public readonly isKistamImportDone$ = new BehaviorSubject<boolean>(false);
	public readonly isFileNetImportDone$ = new BehaviorSubject<boolean>(false);
	public readonly fileNetImportResult$ = new BehaviorSubject<string>('');

	public kistamService: KuerzelBezeichnungReferenzlistService;

	constructor(
		http: HttpClient,
		private matDialog: MatDialog,
		private csvParser: NgxCsvParser,
		private readonly alert: AlertService,
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory
	) {
		super(http, einreichungMetadata);

		this.kistamService = serviceFactory.getService('kistam');

		this.onLoaded = e => {
			e.betrag = toInternalModel(e.betrag ?? 0);
			e.vorabpauschale = toInternalModel(e.vorabpauschale ?? 0);
			e.vorabpauschaleGesamtsumme = toInternalModel(e.vorabpauschaleGesamtsumme ?? 0);

			e.eingangsdatum = parseIncomingAsDate(e.eingangsdatum);
			e.geaendertAm = parseIncomingAsDate(e.geaendertAm);
			e.freigegebenAm = parseIncomingAsDate(e.freigegebenAm);
			e.posteingangsdatum = parseIncomingAsDate(e.posteingangsdatum);
			e.valutatag = parseIncomingAsDate(e.valutatag);

			if (!e.positionen) {
				e.positionen = [];
			} else {
				e.positionen.forEach(p => {
					p.anzahl = p.anzahl ?? 1;
					p.einzelwert = toInternalModel(p.einzelwert ?? 0);
					p.gesamtwert = toInternalModel(p.gesamtwert ?? 0);
					p.faktor = toInternalModel(p.faktor ?? 0);
					p.anteil = toInternalModel(p.anteil ?? 1);
					p.nominalwert = toInternalModel(p.nominalwert ?? 0);
					p.vorabpauschale = toInternalModel(p.vorabpauschale ?? 0);
					p.stueckenummer =
						parseIncomingAsArray<string>(
							p.stueckenummer,
							'stueckenummer in der Einreichung ' + e.nummer
						) || [];

					p.steuern ||= [];
					p.steuern.forEach(st => {
						st.steuerbetrag = toInternalModel(st.steuerbetrag);
						st.steuergrundbetrag = toInternalModel(st.steuergrundbetrag);
					});
				});
			}

			if (!e.personen) {
				e.personen = [];
			} else {
				e.personen.forEach(p => {
					p.geburtsdatum = parseIncomingAsDate(p.geburtsdatum);
					p.ausweisAusgestelltAm = parseIncomingAsDate(p.ausweisAusgestelltAm);
					p.ausweisGueltigBis = parseIncomingAsDate(p.ausweisGueltigBis);
				});
			}
		};

		this.onSaving = t => {
			t.betrag = toExternalModel(t.betrag);
			t.vorabpauschale = toExternalModel(t.vorabpauschale);
			t.vorabpauschaleGesamtsumme = toExternalModel(t.vorabpauschaleGesamtsumme);

			t.personen.forEach(p => {
				if (p.id && p.id.startsWith('new')) delete (p as any).id;
			});

			t.positionen.forEach(p => {
				p.einzelwert = toExternalModel(p.einzelwert);
				p.gesamtwert = toExternalModel(p.gesamtwert);
				p.faktor = toExternalModel(p.faktor);
				p.anteil = toExternalModel(p.anteil);
				p.nominalwert = toExternalModel(p.nominalwert);
				p.vorabpauschale = toExternalModel(p.vorabpauschale);
			});
		};
	}

	rueckfrage$({ id, bemerkung }: { bemerkung: string; id: string; }): Observable<void> {
		return this.http.post<void>(`/${this.metadata.apiCollectionName}/${id}/rueckfrage`, {
			id,
			bemerkung,
		});
	}

	abbrechen$({
		id,
		abbruchStornoGrund,
	}: {
		abbruchStornoGrund: string;
		id: string;
	}): Observable<void> {
		return this.http.post<void>(`/${this.metadata.apiCollectionName}/${id}/abbrechen`, {
			id,
			abbruchStornoGrund,
		});
	}

	interneKlaerung$({
		id,
		bemerkung,
		oppositionFreigeber,
	}: {
		bemerkung: string;
		id: string;
		oppositionFreigeber?: boolean;
	}): Observable<void> {
		const payload: any = {
			id,
			bemerkung,
		};

		if (oppositionFreigeber === true || oppositionFreigeber === false) {
			payload.oppositionFreigeber = oppositionFreigeber;
		}

		return this.http.post<void>(
			`/${this.metadata.apiCollectionName}/${id}/interneklaerunganfordern`,
			payload
		);
	}

	/**
	 * Do POST Request
	 * @param id einreichungs id
	 * @param payload data
	*/
	freigabeAnfordern$(id: string, payload: FreigabeAnfordernPayload): Observable<any> {
		this.calcPayloadGeschaeftsfaelle(payload);
		this.calcPayloadSteuern(payload);
		const ret = this.http.post<void>(
			`/${this.metadata.apiCollectionName}/${id}/freigabeAnfordern`,
			payload,
			{
				responseType: 'text' as 'json',
			}
		);
		return ret;
	}

	/**
	 * Transform some given steuern data
	 */
	calcPayloadSteuern(payload: FreigabeAnfordernPayload) {
		payload.steuern.forEach(st => {
			st.steuerbetrag = toExternalModel(st.steuerbetrag);
			st.steuergrundbetrag = toExternalModel(st.steuergrundbetrag);
		});
	}

	/**
	 * Transform some given data.
	 */
	calcPayloadGeschaeftsfaelle(payload: FreigabeAnfordernPayload) {
		payload.geschaeftsfaelle.forEach(gf => {
			gf.bruttobetrag = toExternalModel(gf.bruttobetrag);
			gf.cdcGebuehren = toExternalModel(gf.cdcGebuehren);
			gf.cdcZinsgutschrift = toExternalModel(gf.cdcZinsgutschrift);
			gf.dekaGebuehren = toExternalModel(gf.dekaGebuehren);
			gf.inkassobetrag = toExternalModel(gf.inkassobetrag);
			gf.nettobetrag = toExternalModel(gf.nettobetrag);
			gf.steuern = toExternalModel(gf.steuern);
			gf.kapitalertragssteuer = toExternalModel(gf.kapitalertragssteuer);
			gf.kapitalertragssteuerCalculated = toExternalModel(
				gf.kapitalertragssteuerCalculated
			);
			gf.kirchensteuer = toExternalModel(gf.kirchensteuer);
			gf.kirchensteuerCalculated = toExternalModel(gf.kirchensteuerCalculated);
			gf.solidaritaetszuschlag = toExternalModel(gf.solidaritaetszuschlag);
			gf.solidaritaetszuschlagCalculated = toExternalModel(
				gf.solidaritaetszuschlagCalculated
			);
			gf.zinsabschlagsteuer = toExternalModel(gf.zinsabschlagsteuer);
			gf.zinsabschlagsteuerCalculated = toExternalModel(gf.zinsabschlagsteuerCalculated);
			gf.zuVerrechnendeVorabpauschale = toExternalModel(gf.zuVerrechnendeVorabpauschale);
			gf.buchungen.forEach(b => (b.betrag = toExternalModel(b.betrag)));
		});
	}

	freigeben$(id: string, payload: { oppositionFreigeber: boolean; }): Observable<any> {
		const apiUrl = `/${this.metadata.apiCollectionName}/${id}/freigeben`;
		const ret: Observable<any> = this.http.post<void>(apiUrl, payload, {
			responseType: 'text' as 'json',
		});
		return ret;
	}

	loadStatusHistorie$(id: string): Observable<StatusHistorieEintrag[]> {
		return this.http.get<StatusHistorieEintrag[]>(
			`/${this.metadata.apiCollectionName}/${id}/historie`
		);
	}

	vorabpauschaleEinfordern$(
		id: string,
		payload: VorabpauschaleSteuersummen
	): Observable<{ data: Blob; filename: string; }> {
		return this.http
			.post(`/einreichung/${id}/vorabpauschaleninkasso`, payload, {
				observe: 'response',
				responseType: 'blob',
			})
			.pipe(
				map(response => {
					if (!response.body) throw new Error('No body!');

					let filename = 'file';
					const contentDisposition = response.headers.get('content-disposition');
					if (contentDisposition) {
						const filenameInContentDisposition = contentDisposition
							.split(';')
							.map(item => item.split('=', 2).map(i => i.trim()))
							.filter(itemSplit => itemSplit.length === 2)
							.find(itemSplit => itemSplit[0] === 'filename');

						if (filenameInContentDisposition) {
							filename = filenameInContentDisposition[1];
							console.log('filename', filename);
							if (filename[0] === '"' && filename[filename.length - 1] === '"') {
								filename = filename.substring(1, filename.length - 1);
							}
						}
					}
					return {
						data: response.body,
						filename,
					};
				})
			);
	}

	saveKorrektur$(id: string, payload: EinreichungKorrekturSaveModel): Observable<void> {
		// console.warn('XXX', payload);
		return this.http.put<void>(
			`/${this.metadata.apiCollectionName}/${id}/dsgvoanpassung`,
			payload
		);
	}

	getKistamDocument$(payload: any): Observable<{ data: Blob; filename: string; }> {
		return this.http
			.post(`/einreichung/bzst`, payload, { observe: 'response', responseType: 'blob' })
			.pipe(
				map(response => {
					if (!response.body) throw new Error('No body!');

					let filename = 'file';
					const contentDisposition = response.headers.get('content-disposition');
					if (contentDisposition) {
						const filenameInContentDisposition = contentDisposition
							.split(';')
							.map(item => item.split('=', 2).map(i => i.trim()))
							.filter(itemSplit => itemSplit.length === 2)
							.find(itemSplit => itemSplit[0] === 'filename');

						if (filenameInContentDisposition) {
							filename = filenameInContentDisposition[1];
							console.log('filename', filename);
							if (filename[0] === '"' && filename[filename.length - 1] === '"') {
								filename = filename.substring(1, filename.length - 1);
							}
						}
					}
					return {
						data: response.body,
						filename,
					};
				})
			);
	}

	// #region Kistam Import
	/**
	 * Open modal dialog for kistam csv import
	 */
	showImportKistamCsvDialog(): MatDialogRef<EinreichungFileimportModalComponent> {
		return this.matDialog.open(EinreichungFileimportModalComponent, {
			minWidth: '40vw',
		});
	}

	/**
	 * Import the csv data.
	 * The clientside plausibility check has been done already.
	 */
	importKistamCsv(fileFormControlFile: File): void {
		this.isImportingKistamCsv$.next(true);

		this.parseKistamCsv$(fileFormControlFile)
			.pipe(
				map((arrParsedData: any) => {
					const ret = this.processingAndValidateKistamCsvData(arrParsedData);
					return ret;
				})
			)
			.subscribe({
				next: data => {
					logger.log('Read kistam csv file done.');
					this.isKistamImportDone$.next(true);
					this.updateKistamData(data);
				},
				error: (err: NgxCSVParserError) => {
					logger.error(err.message, 'CSV kistam import error!');
					this.isImportingKistamCsv$.next(false);
				},
				complete: () => {
					this.isImportingKistamCsv$.next(false);
				},
			});
	}

	/**
	 * Call NgxCsvParser with the correct configuration.
	 * @returns the parsed data or the parse error
	 */
	public parseKistamCsv$(fileFormControlFile: File): Observable<any[] | NgxCSVParserError> {
		return this.csvParser.parse(fileFormControlFile, {
			header: false,
			delimiter: ';',
		});
	}

	/**
	 * Plausibility check of the the csv data.
	 * @param arrParsedData Array of parsed csv rows (row == string arrays)
	 * @returns Array with the plausibility error messages.
	 */
	plausibiltyCheckOfKistamCsvData(arrParsedData: any[]): string[] {
		let ret: string[] = [];
		if (arrParsedData.length < 3) {
			ret.push('CSV muss mehr als 2 Zeilen haben!');
			return ret;
		}
		for (let rowIndex = 2; rowIndex < arrParsedData.length; rowIndex++) {
			try {
				const row = arrParsedData[rowIndex] as string[];
				const rowNo = rowIndex + 1;
				const errTextPrefix = `Zeile ${rowNo}:`;
				if (row.length < 23) {
					ret = [];
					ret.push('CSV muss min. 23 Spalten haben! ' + `(Erkannt in Zeile: ${rowNo})`);
					return ret;
				}
				// #region CHECK einreichungsId & Status
				const csvCol3: string = row[2];
				if (csvCol3.trim().length == 0) {
					// Check einreichungId field existance
					ret.push(errTextPrefix + `Spalte 3 darf nicht leer sein!`);
				} else if (isNaN(Number(csvCol3))) {
					// Check einrechungsId is a number
					ret.push(errTextPrefix + `Spalte 3 muss eine Nummer sein!`);
				} else {
					// Check einrechungsId is in cached einreichungen
					const csvEinreichungNumber = Number(csvCol3);
					const einreichung = this.list$.value.find(
						einr => einr.nummer === csvEinreichungNumber
					);
					if (!einreichung) {
						ret.push(
							errTextPrefix +
							`Einreichungsnummer ${csvEinreichungNumber} ist nicht in den Einreichungen vorhanden!`
						);
					} else {
						switch (einreichung.status) {
							case EinreichungStatus.AutomatisierungFehler:
							case EinreichungStatus.Freigegeben:
							case EinreichungStatus.ZurFreigabeKorrigiert:
							case EinreichungStatus.ZurFreigabeNurDokumentation:
							case EinreichungStatus.Abgeschlossen:
							case EinreichungStatus.Abgebrochen:
							case EinreichungStatus.Storniert:
							case EinreichungStatus.Teilgebucht:
								ret.push(
									errTextPrefix +
									`Einreichungsnummer ${csvEinreichungNumber} hat den Status '${einreichung.status}' und darf nicht mehr bearbeitet werden!`
								);
								break;
						}
					}
				}
				// #endregion CHECK einreichungsId & Status

				// #region CHECK kistam entry
				const csvCol23: string = row[22];
				if (csvCol23.trim().length == 0) {
					// pass empty string
					continue;
				}
				if (isNaN(Number(csvCol23))) {
					ret.push(errTextPrefix + `Spalte 23 muss eine KiStam Nummer oder leer sein!`);
				} else {
					const csvKistamNumber = csvCol23.trim().padStart(6, '0');
					const kistam = this.kistamService.list$.value.find(
						_kistam => _kistam.kuerzel === csvKistamNumber
					);
					if (!kistam) {
						ret.push(
							errTextPrefix +
							`Spalte 23 (${csvKistamNumber}) muss eine bekannte KiStam Abkürzung haben!`
						);
					}
				}
				// #endregion CHECK kistam entry
			} catch (err) {
				logger.error(err);
				continue;
			}
		}
		return ret.sort();
	}

	/**
	 * Validate raw csv data, replace some data.
	 *
	 * @param arrParsedData data rows of the csv file
	 * @returns array with only necessary kistam data
	 */
	processingAndValidateKistamCsvData(arrParsedData: any[]): Kistam[] {
		// throw new Error('TestFehler');
		const ret: Kistam[] = arrParsedData
			.slice(2) // ignore first two rows
			.map(x => {
				let kistamFieldValue = x[22];
				kistamFieldValue = kistamFieldValue.trim() ? kistamFieldValue : '------';
				// kistam identifier has to be 6 digets. except '------'
				const kistam_ = kistamFieldValue.includes('------')
					? kistamFieldValue
					: _.padStart(kistamFieldValue, 6, '0');
				const einreichungId = x[2];
				let kistamOfFile = { einreichungId, kistam: kistam_ } as Kistam;
				const einrOfFile = this.list$.value.find(
					l => l.nummer == kistamOfFile.einreichungId
				);
				kistamOfFile = { ...kistamOfFile, uuid: einrOfFile?.id };
				return kistamOfFile;
			});
		return ret;
	}

	/**
	 * Persist kistam data to database
	 * @param {Kistam[]} data
	 */
	updateKistamData(data: Kistam[]) {
		this.result4KistamCsvImport$.next([]);
		const results$ = data.map(kistam => {
			return this.loadSingle$(kistam.uuid!).pipe(
				// Errorhandling for loading
				catchError(err => {
					logger.error(
						err.message,
						'Error while loading kistam with number: ' + kistam.einreichungId
					);
					return of(
						new KistamUpdateResult(
							KistamUpdateResult.SUCCESS_loadFailed,
							kistam.einreichungId,
							err.message
						)
					);
				}),
				switchMap(einr => {
					if (einr instanceof KistamUpdateResult) {
						return of(einr);
					}
					einr.kistam = kistam.kistam;
					if (einr.personen != null && einr.personen.length) {
						const wirtschaftlichBerechtigte: Einreichungsperson[] = einr.personen.filter(
							p => p.istWirtschaftlichBerechtigter
						);
						let kistamProzentsatzValue = this.computeKistamProzentsatzValue(
							einr.kistam,
							wirtschaftlichBerechtigte
						);
						einr.kistamProzentsatz = kistamProzentsatzValue;
					}

					// DTO Mapping
					const saveModel = this.makeSaveModel(einr);

					return this.save$(saveModel).pipe(
						map(
							() =>
								new KistamUpdateResult(KistamUpdateResult.SUCCESS_success, einr.nummer)
						),
						catchError(err =>
							of(
								new KistamUpdateResult(
									KistamUpdateResult.SUCCESS_saveFailed,
									einr.nummer,
									err.message
								)
							)
						)
					);
				})
			);
		});

		forkJoin(results$)
			.pipe(take(1))
			.subscribe(resl => {
				this.result4KistamCsvImport$.next(resl);
			});
	}
	// #endregion Kistam Import

	filenetImport(file: File, uuid_einreichung: string, einreichungNr: number): void {
		const fileExtension = _.last(file.name.split('.'));
		if (!fileExtension) {
			this.alert.error(
				"Ausgewählt Datei hat keine Dateierweiterung (z.B. '.pdf')",
				new Error('missing file extension')
			);
			return;
		}
		const fileNameWithoutExtension = _.trimEnd(file.name, '.' + fileExtension);
		if (!fileNameWithoutExtension.length) {
			this.alert.error(
				'Ausgewählt Datei hat vor der Dateierweiterung keinen weiteren Dateiname',
				new Error('missing file name')
			);
			return;
		}
		const formData = new FormData();
		formData.append('useEinreichung', 'true');
		formData.append('data', file);
		formData.append('name', fileNameWithoutExtension);
		formData.append('type', '.' + fileExtension);
		formData.append('einreichung', einreichungNr.toString());

		this.isImporting2Filenet$.next(true);

		this.http
			.put<void>(`/einreichung/${uuid_einreichung}/upload`, formData)
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.alert.success('Upload erfolgreich.');
				},
				error: err => {
					this.alert.error('Upload nach Filenet fehlgeschlagen!', err);
					this.isImporting2Filenet$.next(false);
					this.fileNetImportResult$.next('Upload nach Filenet fehlgeschlagen!');
				},
				complete: () => {
					this.isImporting2Filenet$.next(false);
					this.isFileNetImportDone$.next(true);
					this.fileNetImportResult$.next('Upload nach Filenet fertig!');
				},
			});
	}

	/**
	 * Compute the percent value for kistam
	 *
	 * @param {*} kistam selected kistam value (5-6 digits)
	 * @param {Einreichungsperson[]} wirtschaftlichBerechtigte at least one person such as defined
	 * @return {*}  {(Number | null)} percent
	 * @memberof EinreichungService
	 */
	computeKistamProzentsatzValue(
		kistam: string,
		wirtschaftlichBerechtigte: Einreichungsperson[]
	): number | null {
		let kistamProzentsatzValue = null;

		if (kistam && kistam !== '------' && wirtschaftlichBerechtigte.length == 1) {
			switch (wirtschaftlichBerechtigte[0].bundesland) {
				case 'BW':
				case 'BY':
					kistamProzentsatzValue = 0.08;
					break;
				default:
					kistamProzentsatzValue = 0.09;
					break;
			}
		}
		return kistamProzentsatzValue;
	}

	/**
	 * Map source values to a DTO
	 *
	 * @param values source values
	 * @returns DTO for rest call
	 */
	makeSaveModel(values: Einreichung): EinreichungSaveModel {
		const ret = {
			id: values.id,
			abbruchStornoGrund: values.abbruchStornoGrund,
			absender: values.absender,
			anzahlUmschlaege: values.anzahlUmschlaege,
			bemerkung: values.bemerkung,
			betrag: values.betrag,
			dekaDepotVorhanden: values.dekaDepotVorhanden,
			couponShouldPay: values.couponShouldPay,
			depotInhaber: values.depotInhaber,
			depotNummer: values.depotNummer,
			eingangsdatum: formatDateForSaveModel(values.eingangsdatum),
			einreicher: values.einreicher,
			empfaenger: values.empfaenger,
			erbfall: values.erbfall,
			externeReferenz: values.externeReferenz,
			kistam: values.kistam,
			kistamProzentsatz: values.kistamProzentsatz,
			konto: values.konto,
			kontoInhaber: values.kontoInhaber,
			neuerfassungZu: values.neuerfassungZu,
			oppositionstreffer: values.oppositionstreffer,
			personen: (values.personen || []).map(p => ({
				...p,
				id: p.id.startsWith('new') ? null : p.id,
				geburtsdatum: formatDateForSaveModel(p.geburtsdatum),
				ausweisAusgestelltAm: formatDateForSaveModel(p.ausweisAusgestelltAm),
				ausweisGueltigBis: formatDateForSaveModel(p.ausweisGueltigBis),
			})),
			positionen: (values.positionen || []).map(p => ({
				...p,
				id: p.id.startsWith('new') ? null : p.id,
				stueckenummer: JSON.stringify(p.stueckenummer),
				gesamtwert: round2(p.gesamtwert), //EFA-2680
			})),
			posteingangsdatum: formatDateForSaveModel(values.posteingangsdatum),
			sendungsnummer: values.sendungsnummer,
			valutatag: formatDateForSaveModel(values.valutatag),
			versandart: values.versandart,
			vorabpauschale: values.vorabpauschale,
			vorabpauschaleGesamtsumme: values.vorabpauschaleGesamtsumme,
			vorabpauschaleEingegangen: values.vorabpauschaleEingegangen,
			waehrungStueck: values.waehrungStueck,
			kassenvereinsnummer: values.kassenvereinsnummer,
			bic: values.bic ? values.bic : null,
			schnellerfassungInfo: values.schnellerfassungInfo,
			dokumentenLink: values.dokumentenLink,
			vorabpauschaleStatus: VorabpauschaleStatus.Unbekannt,
			religionszugehoerigkeit: values.religionszugehoerigkeit,
		};
		return ret;
	}
}

// #region Interfaces & Classes

interface Kistam {
	einreichungId: number | string;
	uuid?: string;
	kistam: string;
}

export class KistamUpdateResult {
	static SUCCESS_success = 'success';
	static SUCCESS_saveFailed = 'save failed';
	static SUCCESS_loadFailed = 'load failed';
	constructor(
		public success: string,
		public number: number | string,
		public errMessage: string = ''
	) { }
}

export interface EinreichungSaveModel {
	id: string | undefined;
	oppositionstreffer: boolean | null;
	abbruchStornoGrund: string;
	eingangsdatum: string | null;
	versandart: string;
	posteingangsdatum: string | null;
	absender: string;
	sendungsnummer: string;
	empfaenger: string;
	betrag: number;
	waehrungStueck: string;
	anzahlUmschlaege: number;
	bemerkung: string;
	einreicher: string | null;
	depotNummer: string;
	depotInhaber: string;
	konto: string;
	kontoInhaber: string;
	dekaDepotVorhanden: boolean;
	couponShouldPay: boolean;
	vorabpauschale: number;
	vorabpauschaleGesamtsumme: number;
	vorabpauschaleEingegangen: boolean;
	vorabpauschaleStatus: VorabpauschaleStatus;
	kistam: string | null;
	kistamProzentsatz: number | null;
	externeReferenz: string;
	neuerfassungZu: string;
	valutatag: string | null;
	erbfall: boolean;
	positionen: EinreichungspositionSaveModel[];
	personen: EinreichungspersonSaveModel[];
	kassenvereinsnummer: string | null;
	bic: string | null;
	schnellerfassungInfo: null | string;
	dokumentenLink: string | null;
	religionszugehoerigkeit: Religionszugehoerigkeit | null;
}

export enum VorabpauschaleStatus {
	Unbekannt = 'U',
	KeineFaellig = 'K',
	DurchGeschaeftsfallAbgedeckt = 'G',
	Faelig = 'F',
	FaelligUndEingegangen = 'E',
}

export interface EinreichungspositionSaveModel {
	id: string | null;
	positionnummer: number;
	gattungId: string | null;
	nominalwert: number;
	anzahl: number;
	anteil: number;
	faktor: number;
	einzelwert: number;
	gesamtwert: number;
	vorabpauschale: number;
	stueckenummer: string;
}

export interface EinreichungspersonSaveModel {
	id: string | null;
	istEinreicher: boolean;
	istVerein: boolean;
	istWirtschaftlichBerechtigter: boolean;
	anrede: string;
	vorname: string;
	nachname: string;
	adresszusatz: string;
	adresse: string;
	postleitzahl: string;
	ort: string;
	bundesland: string | null;
	land: string | null;
	geburtsort: string;
	geburtsland: string | null;
	geburtsdatum: string | null;
	staatsangehoerigkeit: string;
	ausweisart: string | null;
	ausweisnummer: string;
	ausweisAusgestelltAm: string | null;
	ausweisAusgestelltVon: string;
	ausweisGueltigBis: string | null;
	email: string;
	dekaDepotVorhanden: boolean;
	kycPruefungsnummer: string;
	kycPositiv: boolean;
	sapNummer: string;
}

export interface FreigabeAnfordernPayload {
	geschaeftsfaelle: FreigabeAnfordernPayloadGeschaeftsfall[];
	steuern: (Einreichungspositionssteuer & { einreichungspositionId: string; })[];
	nurDokumentation: boolean;
	vorabpauschaleEingegangen: boolean;
	vorabpauschaleStatus: VorabpauschaleStatus;
}

export interface FreigabeAnfordernPayloadGeschaeftsfall {
	nummer: string;
	einreichungId: string;
	isin: string;
	istInkasso: boolean;
	vorgangsnummer: string;
	bruttobetrag: number;
	steuern: number;
	nettobetrag: number;
	cdcGebuehren: number;
	cdcZinsgutschrift: number;
	dekaGebuehren: number;
	inkassobetrag: number;
	kapitalertragssteuer: number;
	kapitalertragssteuerCalculated: number;
	zinsabschlagsteuer: number;
	zinsabschlagsteuerCalculated: number;
	solidaritaetszuschlag: number;
	solidaritaetszuschlagCalculated: number;
	kirchensteuer: number;
	kirchensteuerCalculated: number;
	bemerkungen?: string;
	land: string;
	zuVerrechnendeVorabpauschale: number;
	positionen: string[];
	buchungen: Buchung[];
}

export interface EinreichungKorrekturModel {
	id: string;
	nummer: string;
	depotNummer: string;
	depotInhaber: string;
	konto: string;
	bic: string | null;
	kontoInhaber: string;
	personen: Einreichungsperson[];
	dsgvoAenderungsgrund: string;
}

export interface EinreichungKorrekturSaveModel {
	id: string;
	nummer: string;
	depotNummer: string;
	depotInhaber: string;
	konto: string;
	bic: string | null;
	kontoInhaber: string;
	personen: EinreichungspersonSaveModel[];
	dsgvoAenderungsgrund: string;
}

// #endregion Interfaces & Classes
