import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Opposition, oppositionMetadata } from './model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StammdatenEntityServiceBase } from '../entity.service.base';
import { OppositionFileimportModalComponent } from './opposition-fileimport-modal/opposition-fileimport-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { AlertService } from 'src/app/shared/alert.service';
import { logger } from 'src/logger';

@Injectable({
	providedIn: 'root',
})
export class OppositionService extends StammdatenEntityServiceBase<Opposition> {
	pipe(arg0: any) {
		throw new Error('Method not implemented.');
	}
	public readonly isImportingCsv$ = new BehaviorSubject(false);
	public readonly result4CsvImport$ = new BehaviorSubject<Array<OppositionUpdateResult>>([]);
	public readonly isImportDone$ = new BehaviorSubject<boolean>(false);

	constructor(
		http: HttpClient,
		private matDialog: MatDialog,
		private csvParser: NgxCsvParser,
		private readonly alert: AlertService
	) {
		super(http, oppositionMetadata);
	}

	showCsvImportDialog(): MatDialogRef<OppositionFileimportModalComponent> {
		logger.log('Open opposition import dialog!');
		return this.matDialog.open(OppositionFileimportModalComponent, {
			minWidth: '40vw',
		});
	}

	plausibiltyCheckOfCsvData(arrParsedData: any[]): string[] {
		logger.log(arrParsedData, 'Plausicheck of OppositionCsvData');
		let ret: string[] = [];
		if (arrParsedData.length < 3) {
			ret.push('CSV muss mehr als 2 Zeilen haben!');
			return ret;
		}
		for (let rowIndex = 2; rowIndex < arrParsedData.length; rowIndex++) {
			try {
				const row = arrParsedData[rowIndex] as string[];
				const rowNo = rowIndex + 1;
				const errTextPrefix = `Zeile ${rowNo}:`;
				if (row.length < 20) {
					ret = [];
					ret.push('CSV muss min. 20 Spalten haben! ' + `(Erkannt in Zeile: ${rowNo})`);
					return ret;
				}
				// #region CHECK mandatory fields
				const csvCol1: string = row[0];
				const csvCol2: string = row[1];
				const csvCol6: string = row[5];
				const csvCol7: string = row[6];
				if (csvCol1.trim().length == 0) {
					ret.push(errTextPrefix + `Spalte 1 (ISIN) darf nicht leer sein!`);
				}
				if (csvCol2.trim().length == 0) {
					ret.push(errTextPrefix + `Spalte 2 (WKN) darf nicht leer sein!`);
				}
				if (csvCol6.trim().length == 0) {
					ret.push(errTextPrefix + `Spalte 6 (Aktenzeichen WM) darf nicht leer sein!`);
				}
				if (csvCol7.trim().length == 0) {
					ret.push(errTextPrefix + `Spalte 7 (Oppositionsart) darf nicht leer sein!`);
				}
				// #endregion CHECK einreichungsId & Status
			} catch (err) {
				logger.error(err);
				continue;
			}
		}
		return ret.sort();
	}

	public parseCsv$(fileFormControlFile: File): Observable<any[] | NgxCSVParserError> {
		return this.csvParser.parse(fileFormControlFile, {
			header: false,
			delimiter: ';',
		});
	}

	importCsv(fileFormControlFile: File, dialog: OppositionFileimportModalComponent): void {
		this.isImportingCsv$.next(true);
		const formData = new FormData();
		formData.append('file', fileFormControlFile);

		this.http.put<OppositionUploadResult>(`/opposition/upload`, formData).subscribe({
			next: (result) => {
				logger.log('Read opposition csv file done.');
				this.alert.success('Oppositionsdaten importiert!');
				this.isImportDone$.next(true);

				this.isImportingCsv$.next(false);
				// this.matDialog.closeAll();

				console.log({result});
				const text = [];
				if (result) {
					if (result.fehlerhafteOppositionen && result.fehlerhafteOppositionen.length > 0) {
						text.push(`Fehlerhafte Oppositionen: ${result.fehlerhafteOppositionen.join(', ')}`);
					}
					if (result.aktualisierteOppositionen && result.aktualisierteOppositionen.length > 0) {
						text.push(`Aktualisierte Oppositionen: ${result.aktualisierteOppositionen.join(', ')}`);
					}
					if (result.aktualisierteEinreichungen && result.aktualisierteEinreichungen.length > 0) {
						text.push(`Offene aktualisierte Einreichungen mit Oppositionstreffern: ${result.aktualisierteEinreichungen.join(', ')}`);
					}
					if (result.oppositionstrefferAbgeschlossenerEinreichungen && result.oppositionstrefferAbgeschlossenerEinreichungen.length > 0) {
						text.push(`Abgeschlossene Einreichungen mit Oppositionstreffern: ${result.oppositionstrefferAbgeschlossenerEinreichungen.join(', ')}`);
					}
					if (text.length == 0) {
						text.push('Es wurden keine Oppositionen importiert. (Kein Fehler)');
					}
				} else {
					text.push('Oppositionsimport fehlgeschlagen');
				}
				dialog.resultMessages$.next(text);
			},
			error: err => {
				logger.error(err.message, 'CSV opposition import error!');
				this.alert.error(
					'Fehler beim Importieren der Oppositionsdaten! (Netzwerkfehler)',
					err,
					fileFormControlFile
				);
				this.isImportingCsv$.next(false);
			}
		});
	}
}

export class OppositionUpdateResult {
	static SUCCESS_success = 'success';
	static SUCCESS_saveFailed = 'save failed';
	static SUCCESS_loadFailed = 'load failed';
	constructor(
		public success: string,
		public number: number | string,
		public errMessage: string = ''
	) { }
}

interface OppositionUploadResult {
	fehlerhafteOppositionen: string[];
	aktualisierteOppositionen: string[];
	aktualisierteEinreichungen: number[];
	oppositionstrefferAbgeschlossenerEinreichungen: number[];
}
