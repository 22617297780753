import { forwardRef } from '@angular/core';
import { EntityMetadata } from 'src/app/model';
import { OppositionListPageComponent } from './opposition-list-page/opposition-list-page.component';

export interface Opposition {
	id: string;
	isin: string;
	wkn: string;
	bezeichnung1: string;
	bezeichnung2: string;
	wirksamAm: string;
	aktenzeichenWm: string;
	oppositionsart: string;
	amtsgerichtAm: string;
	aktenzeichenAmtsgericht: string;
	amtsgericht: string;
	postleitzahlOrt: string;
	stueckelungWaehrung: string;
	stueckelung: string;
	buchstStueckelung: string;
	stuecknummerVon: string;
	stuecknummerBis: string;
	allgemeinerText: string;
	neuaufnahme: string;
	loeschung: string;
	aufgebot: string;
}

export const oppositionMetadata: EntityMetadata<Opposition> = {
	label: 'Opposition',
	labelGender: 'n',
	apiCollectionName: 'opposition',
	plural: 'Oppositionen',
	routing: {
		list: {
			url: '/opposition',
			component: forwardRef(() => OppositionListPageComponent), //TODO Liste ersetzen
		},
	},
	fields: {
		isin: {
			typ: 'backend-text',
			label: 'ISIN',
			labelStyle: 'width:150px',
			description: 'Internationale Wertpapierkennnummer',
		},
		wkn: {
			typ: 'backend-text',
			label: 'WKN',

			labelStyle: 'width:100px',
			description: 'Wertpapierkennnummer',
		},
		bezeichnung1: {
			typ: 'backend-text',
			label: 'Bezeichnung 1',
			labelStyle: 'width:180px',
			description: '',
		},
		bezeichnung2: {
			typ: 'backend-text',
			label: 'Bezeichnung 2',
			labelStyle: 'width:180px',
			description: '',
		},
		wirksamAm: {
			typ: 'datum',
			label: 'wirksam am',
			labelStyle: 'width:110px',
			description: '',
		},
		aktenzeichenWm: {
			typ: 'backend-text',
			label: 'Aktenz. <br>WM',
			labelStyle: 'width:120px;word-wrap: break-word;',
			description: 'Aktenzeichen WM',
		},
		oppositionsart: {
			typ: 'backend-text',
			label: 'Oppositions-<br>art',
			labelStyle: 'width:100px',
			description: '',
		},
		amtsgerichtAm: {
			typ: 'datum',
			label: 'Amtsgericht<br> am',
			labelStyle: 'width:150px',
			description: '',
		},
		aktenzeichenAmtsgericht: {
			typ: 'backend-text',
			label: 'Az.<br> Amtsgericht',
			labelStyle: 'width:150px',
			description: 'Aktenzeichen Amtsgericht',
		},
		amtsgericht: {
			typ: 'backend-text',
			label: 'Amtsgericht',
			labelStyle: 'width:180px',
			description: '',
		},
		postleitzahlOrt: {
			typ: 'backend-text',
			label: 'PLZ/Ort',
			labelStyle: 'width:200px',
			description: '',
		},
		stueckelungWaehrung: {
			typ: 'backend-text',
			label: 'Währung<br> Stückelg',
			labelStyle: 'width:100px',
			description: '',
		},
		stueckelung: {
			typ: 'backend-text',
			label: 'Stückelung',
			labelStyle: 'width:80px',
			description: '',
		},
		buchstStueckelung: {
			typ: 'backend-text',
			label: 'Buchst.<br>Stückelung',
			labelStyle: 'width:100px',
			description: '',
		},
		stuecknummerVon: {
			typ: 'backend-text',
			label: 'Stücknummer<br> von',
			labelStyle: 'width:100px',
			description: '',
		},
		stuecknummerBis: {
			typ: 'backend-text',
			label: 'Stücknummer<br> bis',
			labelStyle: 'width:100px',
			description: '',
		},
		allgemeinerText: {
			typ: 'backend-text',
			label: 'Allgem.Text',

			labelStyle: 'width:180px',
			description: '',
		},
		neuaufnahme: {
			typ: 'backend-text',
			label: 'Datum<br> Neuaufnahme',
			labelStyle: 'width:110px',
			description: '',
		},
		loeschung: {
			typ: 'backend-text',
			label: 'Datum<br> Löschung',
			labelStyle: 'width:110px',
			description: 'Löschung durchgeführt am',
		},
		aufgebot: {
			typ: 'backend-text',
			label: 'Datum<br> Angebot',
			labelStyle: 'width:110px',
			description: 'Angebotsdatum',
		},
	},
};
