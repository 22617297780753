import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
	UltimoEinreichungenEintrag,
	UltimoEinreichungenSearchCriteria,
	UltimoGeschaeftsfaelleEintrag,
	UltimoGeschaeftsfaelleSearchCriteria,
	UltimoKontenEintrag,
	UltimoKontenSearchCriteria,
	UltimoRisikoklassenbewertungEintrag,
} from './model';

@Injectable({
	providedIn: 'root',
})
export class UltimoService {
	constructor(private readonly http: HttpClient) { }

	getUltimoData$<T>(type: string, month: string): Observable<T[]> {
		return this.http.get<T[]>(`/ultimo/${type}/${month}/daten`);
	}

	getUltimoDocument$(type: string, month: string): Observable<{ data: Blob; filename: string; }> {
		return this.http.get(`/ultimo/${type}/${month}/dokument`, { observe: 'response', responseType: 'blob' }).pipe(
			map(response => {
				if (!response.body) throw new Error('No body!');

				let filename = 'file';
				const contentDisposition = response.headers.get('content-disposition');
				if (contentDisposition) {
					const filenameInContentDisposition = contentDisposition
						.split(';')
						.map(item => item.split('=', 2).map(i => i.trim()))
						.filter(itemSplit => itemSplit.length === 2)
						.find(itemSplit => itemSplit[0] === 'filename');

					if (filenameInContentDisposition) {
						filename = filenameInContentDisposition[1];
						console.log('filename', filename);
						if (filename[0] === '"' && filename[filename.length - 1] === '"') {
							filename = filename.substring(1, filename.length - 1);
						}
					}
				}
				return {
					data: response.body,
					filename,
				};
			})
		);
	}

	getUltimoKontenData$(payload: UltimoKontenSearchCriteria): Observable<UltimoKontenEintrag[]> {
		return this.http.post<UltimoKontenEintrag[]>('/ultimo/konten/daten', payload);
	}

	getUltimoComplianceData$(payload: UltimoEinreichungenSearchCriteria): Observable<UltimoEinreichungenEintrag[]> {
		return this.http.post<UltimoEinreichungenEintrag[]>('/ultimo/compliance/daten', payload);
	}

	getUltimoComplianceDocument$(
		payload: UltimoEinreichungenSearchCriteria
	): Observable<{ data: Blob; filename: string; }> {
		const ret = this.http
			.post(`/ultimo/compliance/dokument`, payload, { observe: 'response', responseType: 'blob' })
			.pipe(
				map(response => {
					if (!response.body)
						throw new Error('No body!');

					let filename = 'file';
					const contentDisposition = response.headers.get('content-disposition');
					if (contentDisposition) {
						const filenameInContentDisposition = contentDisposition
							.split(';')
							.map(item => item.split('=', 2).map(i => i.trim()))
							.filter(itemSplit => itemSplit.length === 2)
							.find(itemSplit => itemSplit[0] === 'filename');

						if (filenameInContentDisposition) {
							filename = filenameInContentDisposition[1];
							console.log('filename', filename);
							if (filename[0] === '"' && filename[filename.length - 1] === '"') {
								filename = filename.substring(1, filename.length - 1);
							}
						}
					}
					return {
						data: response.body,
						filename,
					};
				})
			);
		return ret;
	}

	getUltimoComplianceGeschaeftsfallData$(payload: UltimoGeschaeftsfaelleSearchCriteria): Observable<UltimoGeschaeftsfaelleEintrag[]> {
		return this.http.post<UltimoGeschaeftsfaelleEintrag[]>('/ultimo/compliance/geschaeftsfaelle/daten', payload);
	}

	getUltimoComplianceRisikoklassenbewertungData$(year: number): Observable<UltimoRisikoklassenbewertungEintrag[]> {
		return this.http.get<UltimoRisikoklassenbewertungEintrag[]>(`/ultimo/compliance/risikoklassenbewertung/${year}/daten`);
	}

	dateToYYYYMM(date: Date) {
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return '' + y + '-' + (m <= 9 ? '0' + m : m);
	}
}
