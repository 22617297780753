<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<div mat-dialog-content *ngIf="data.message">
	{{ data.message }}
</div>
<div mat-dialog-content *ngIf="data.messageHtml" [innerHtml]="data.messageHtml"></div>
<div mat-dialog-actions style="display: flex; justify-content: space-between; gap: 2em">
	<div>
		<button
			mat-raised-button
			*ngFor="let button of data.buttonsLeft"
			[color]="button.color"
			[mat-dialog-close]="button.value ?? button.label">
			<mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
			{{ button.label }}
		</button>
	</div>
	<div>
		<button
			mat-raised-button
			*ngFor="let button of data.buttonsRight"
			[color]="button.color"
			[mat-dialog-close]="button.value ?? button.label">
			<mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
			{{ button.label }}
		</button>
	</div>
</div>
