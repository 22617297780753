import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as ExcelJS from 'ExcelJS-workaround';
import { saveAs } from 'file-saver';
import { BehaviorSubject, EMPTY, map, switchMap } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { ExcelFileLoadService } from 'src/app/shared/excel/excel-file-load.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { logger } from 'src/logger';
import {
	KuerzelBezeichnungImportComponent,
	KuerzelBezeichnungImportConfig,
} from '../kuerzel-bezeichnung-import/kuerzel-bezeichnung-import.component';
import { KuerzelBezeichnungPopupComponent } from '../kuerzel-bezeichnung-popup/kuerzel-bezeichnung-popup.component';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from '../kuerzel-bezeichnung-referenzlist.service';
import {
	KuerzelBezeichnungReferenz,
	KuerzelBezeichnungReferenzlistMetadata,
	KuerzelBezeichnungReferenzMetadata,
	REFERENZLIST_METADATA,
} from '../model';

@Component({
	selector: 'app-kuerzel-bezeichnung-list-page',
	templateUrl: './kuerzel-bezeichnung-list-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuerzelBezeichnungListPageComponent extends BaseComponent {
	readonly metadata$ = new BehaviorSubject<KuerzelBezeichnungReferenzlistMetadata | undefined>(undefined);
	readonly title$ = this.metadata$.pipe(map(m => m?.label || ''));
	private service?: KuerzelBezeichnungReferenzlistService;

	constructor(
		@Inject(REFERENZLIST_METADATA) referenzlistMetadata: KuerzelBezeichnungReferenzMetadata,
		route: ActivatedRoute,
		private readonly matDialog: MatDialog,
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private readonly alert: AlertService,
		private readonly excelFileLoadService: ExcelFileLoadService,
		private readonly save: SaveService
	) {
		super();

		this.registerSubscription(
			route.paramMap.subscribe(params => {
				const referenzlistName = params.get('referenzlistName');
				if (!referenzlistName) throw new Error('Missing referenzlistName!');

				const metadata = referenzlistMetadata[referenzlistName];
				if (!metadata) throw new Error('Unknown list ' + referenzlistName);

				this.service = serviceFactory.getService(referenzlistName);
				this.metadata$.next(metadata);
			})
		);
	}

	openPopup(item?: KuerzelBezeichnungReferenz): void {
		const metadata = this.metadata$.value;
		if (metadata && !metadata.readonly) {
			this.matDialog.open(KuerzelBezeichnungPopupComponent, { data: { metadata, item } });
		}
	}

	reload(): void {
		const metadata = this.metadata$.value;
		if (!metadata || !this.service) return;

		this.service.loadAll$().subscribe({
			next: () => this.alert.success(`${metadata.label} erfolgreich durchgeladen`),
			error: err => this.alert.error('Durchladen fehlgeschlagen', err),
		});
	}

	loadFromExcel(): void {
		this.excelFileLoadService
			.uploadExcelAndSelectWorksheet$()
			.pipe(
				switchMap(worksheet => {
					if (!worksheet) return EMPTY;
					const config: KuerzelBezeichnungImportConfig = {
						metadata: this.metadata$.value!,
						service: this.service!,
						worksheet,
					};
					return this.matDialog.open(KuerzelBezeichnungImportComponent, { data: config }).afterClosed();
				}),
				switchMap(() => this.service!.loadAll$())
			)
			.subscribe({
				next: () => this.alert.success('Liste neu geladen'),
				error: err => logger.error(err),
			});
	}

	downloadAsExcel(): void {
		const metadata = this.metadata$.value;
		if (!metadata || !this.service) return;

		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(metadata.label);
		if (metadata.extra) {
			worksheet.addRow([
				metadata.kuerzel.label ?? 'Kürzel',
				metadata.bezeichnung.label ?? 'Bezeichnung',
				metadata.extra.label ?? 'Extra',
				'Geändert am',
				'Geändert von',
			]);
			for (const item of this.service.list$.value) {
				worksheet.addRow([
					item.kuerzel,
					item.bezeichnung,
					item.extra,
					item.geaendertAm?.toISOString(),
					item.geaendertVon,
				]);
			}
		} else {
			worksheet.addRow([
				metadata.kuerzel.label ?? 'Kürzel',
				metadata.bezeichnung.label ?? 'Bezeichnung',
				'Geändert am',
				'Geändert von',
			]);
			for (const item of this.service.list$.value) {
				worksheet.addRow([item.kuerzel, item.bezeichnung, item.geaendertAm?.toISOString(), item.geaendertVon]);
			}
		}

		workbook.xlsx.writeBuffer().then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			this.save.saveAs(blob, metadata.apiCollectionName + '.xlsx');
		});
	}
}
