import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'app-field-help-icon',
	templateUrl: './field-help-icon.component.html',
	styleUrls: ['./field-help-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldHelpIconComponent {
	@Input() helpText?: string;
}
