<h1 mat-dialog-title>Buchungen für Geschäftsfall {{ data.geschaeftsfall.nummer }}</h1>

<div mat-dialog-content style="height: 100%">
	<app-geschaeftsfall-buchungen
		[geschaeftsfallMitPositionen]="data"
		style="height: 100%"></app-geschaeftsfall-buchungen>
</div>

<div mat-dialog-actions style="display: flex">
	<span style="flex: 1"></span>
	<button mat-raised-button color="accent" mat-dialog-close>
		<mat-icon>close</mat-icon>
		Schliessen
	</button>
</div>
