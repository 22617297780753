import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { map, shareReplay, startWith, tap } from 'rxjs';
import { AlertService } from 'src/app/shared/alert.service';
import { VorabpauschaleStatus } from '../../einreichung.service';
import { CalculateResultMitEinreichung } from '../einreichung-view-page.component';
import { FreigabeAnfordernSteuernUeberschreibungComponent } from '../freigabe-anfordern-steuern-ueberschreibung/freigabe-anfordern-steuern-ueberschreibung.component';

@Component({
	selector: 'app-einreichung-freigabe-anfordern-popup',
	templateUrl: './einreichung-freigabe-anfordern-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungFreigabeAnfordernPopupComponent implements OnInit {
	public result: EinreichungFreigabeAnfordernResult;
	public readonly istNeuabrechnung: boolean;

	public readonly nurDokumentationControl = new UntypedFormControl(false);
	public readonly vorabpauschaleEingegangenControl = new UntypedFormControl(false);

	public readonly canAnfordern$ = this.vorabpauschaleEingegangenControl.valueChanges.pipe(
		startWith(this.vorabpauschaleEingegangenControl.value !== false),
		map(v => v !== false)
	);

	constructor(
		@Inject(MAT_DIALOG_DATA) data: CalculateResultMitEinreichung,
		private readonly changeDetection: ChangeDetectorRef,
		private readonly matDialog: MatDialog,
		private readonly alert: AlertService
	) {
		this.result = {
			...data,
			nurDokumentation: false,
			vorabpauschaleEingegangen: false,
			vorabpauschaleStatus: data.calculateResult.vorabpauschaleStatus,
		};
		this.istNeuabrechnung = !!data.einreichung.neuerfassungZu;
	}

	ngOnInit(): void {
		switch (this.result.calculateResult.vorabpauschaleStatus) {
			case VorabpauschaleStatus.KeineFaellig:
			case VorabpauschaleStatus.Unbekannt:
			case VorabpauschaleStatus.DurchGeschaeftsfallAbgedeckt:
				setTimeout(() => {
					this.vorabpauschaleEingegangenControl.disable();
				}, 0);

				this.vorabpauschaleEingegangenControl.setValue(true, { emitEvent: true });
				break;
			case VorabpauschaleStatus.FaelligUndEingegangen:
			case VorabpauschaleStatus.Faelig:
				this.result.calculateResult.vorabpauschaleStatus = VorabpauschaleStatus.Faelig;
				break;
		}
	}

	setNurDokumentation({ checked }: MatSlideToggleChange): void {
		this.result.nurDokumentation = checked;
	}

	steuernUeberschreiben(): void {
		this.matDialog
			.open(FreigabeAnfordernSteuernUeberschreibungComponent, {
				data: JSON.parse(JSON.stringify(this.result)),
			})
			.afterClosed()
			.subscribe(data => {
				if (data) {
					this.result = { ...data, nurDokumentation: this.result.nurDokumentation };
					this.changeDetection.detectChanges();
					this.alert.success('Steuernüberschreibung bestätigt');
				}
			});
	}
}

export interface EinreichungFreigabeAnfordernResult extends CalculateResultMitEinreichung {
	nurDokumentation: boolean;
	vorabpauschaleStatus: VorabpauschaleStatus;
	vorabpauschaleEingegangen: boolean;
}
