import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-spinner-dialog',
	templateUrl: './spinner-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public readonly message?: string) {}
}
