import { AlertService } from './../../alert.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as ExcelJS from 'ExcelJS-workaround';
import { BehaviorSubject, catchError, EMPTY, from, switchMap } from 'rxjs';
import { logger } from 'src/logger';

@Component({
	selector: 'app-load-excel-file',
	templateUrl: './load-excel-file.component.html',
	styleUrls: ['./load-excel-file.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadExcelFileComponent {
	public readonly isLoading$ = new BehaviorSubject(false);
	protected file: File | undefined | null;

	constructor(
		private readonly ref: MatDialogRef<LoadExcelFileComponent>,
		private alert: AlertService
	) {}

	onFileSelected(event: Event) {
		logger.log(event, 'SelectedFile');
		this.file = (event.target as HTMLInputElement).files?.item(0);
	}

	load(): void {
		if (!this.file) return;

		this.isLoading$.next(true);
		this.ref.disableClose = true;
		from(this.file.arrayBuffer())
			.pipe(
				switchMap(arrayBuffer => {
					const workbook = new ExcelJS.Workbook();
					return workbook.xlsx.load(arrayBuffer);
				}),
				catchError((err: Error) => {
					this.alert.error('Laden fehlgeschlagen', err);
					this.isLoading$.next(false);
					this.ref.disableClose = false;
					return EMPTY;
				})
			)
			.subscribe(excel => this.ref.close(excel));
	}
}
