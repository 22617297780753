<h1 mat-dialog-title>Fehler ist aufgetreten</h1>
<div mat-dialog-content style="height: 100%; white-space: pre">
	{{ content }}
</div>
<div mat-dialog-actions style="display: flex; gap: 1em">
	<button style="flex: auto 0 0" mat-raised-button color="accent" (click)="pdf()">
		<mat-icon>picture_as_pdf</mat-icon>
		Fehlerprotokoll als PDF herunterladen
	</button>
	<button mat-raised-button mat-dialog-close color="primary">
		<mat-icon>close</mat-icon>
		Schließen
	</button>
</div>
