import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, of, shareReplay, startWith } from 'rxjs';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { Einreichung, einreichungMetadata, EinreichungStatus } from '../../model';
import * as _ from 'lodash';

@Component({
	selector: 'app-einreichung-freigeben-popup',
	templateUrl: './einreichung-freigeben-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungFreigebenPopupComponent {
	public readonly metadata = einreichungMetadata;

	public readonly result$: Observable<EinreichungFreigebenResult>;
	public readonly form = new TypedForm<{
		oppositionFreigeber: boolean | null;
		bemerkung: string;
	}>({
		oppositionFreigeber: createFormControl(einreichungMetadata.fields.oppositionFreigeber),
		bemerkung: createFormControl(einreichungMetadata.fields.bemerkung),
	});
	public readonly disableInterneKlaerung$: Observable<boolean>;
	public readonly disableFreigabe$: Observable<boolean>;
	public korrektur: boolean = false;
	public showOppositionControls: boolean = true;

	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: EinreichungFreigebenPopupData) {
		this.form.controls.bemerkung.addValidators(Validators.required);
		this.form.setValueTyped({
			oppositionFreigeber: data.einreichung.oppositionFreigeber,
			bemerkung: data.einreichung.bemerkung,
		});
		this.korrektur = data.einreichung.status === EinreichungStatus.ZurFreigabeKorrigiert;

		const oppPruefungErfasser_nichtDurchgeführt = data.einreichung.oppositionstreffer === null;
		const oppPruefungErfasser_treffer = _.isBoolean(data.einreichung.oppositionstreffer) && data.einreichung.oppositionstreffer;
		const oppPruefungSystem_nichtDurchgeführt = !_.isBoolean(data.einreichung.oppositionSystem);
		const oppPruefungSystem_treffer = _.isBoolean(data.einreichung.oppositionSystem) && data.einreichung.oppositionSystem;

		this.showOppositionControls = !this.korrektur &&
			!oppPruefungErfasser_treffer &&
			oppPruefungSystem_nichtDurchgeführt;

		if (this.showOppositionControls) {
			this.result$ = this.form.values$.pipe(
				map(values => ({
					id: data.einreichung.id,
					...values,
				})),
				shareReplay(1)
			);
		} else if (this.korrektur) {
			this.result$ = of({
				id: data.einreichung.id,
				oppositionFreigeber: data.einreichung.oppositionFreigeber,
				bemerkung: data.einreichung.bemerkung,
			});
		} else {
			this.result$ = of({
				id: data.einreichung.id,
				oppositionFreigeber: false,
				bemerkung: "Kein Opposition Freigeber nötig",
			});
		}

		this.disableInterneKlaerung$ = this.result$.pipe(
			map(res => !res.bemerkung || res.oppositionFreigeber !== true),
			startWith(true)
		);

		this.disableFreigabe$ = this.result$.pipe(
			map(res => {
				console.log(res, '===res');
				if (this.showOppositionControls) {
					return res.oppositionFreigeber !== false;
				} else if (this.korrektur) {
					return false;
				} else {
					if (!oppPruefungErfasser_treffer && !oppPruefungSystem_treffer) {
						return false;
					}
					if (!oppPruefungErfasser_treffer && oppPruefungSystem_nichtDurchgeführt) {
						return false;
					}
					if (oppPruefungErfasser_nichtDurchgeführt && !oppPruefungSystem_treffer) {
						return false;
					}
					return true;
				}
			}),
			startWith(true)
		);
	}
}

export interface EinreichungFreigebenPopupData {
	einreichung: Einreichung;
}

export interface EinreichungFreigebenResult {
	id: string;
	oppositionFreigeber: boolean | null;
	bemerkung: string;
}
