import { EntityMetadata } from 'src/app/model';

export const konfigurationMetadata: EntityMetadata<Konfiguration> = {
	label: 'Konfiguration',
	labelGender: 'w',
	apiCollectionName: 'konfiguration',
	plural: 'Konfiguration',
	routing: {},
	fields: {
		bankleitzahl: {
			label: 'Bankleitzahl',
			typ: 'text',
			mussNumerischSein: true,
			exactLength: 8,
			description: 'Die Bankleitzahl der Deka-Bank. Wird für alle Konten verwendet',
		},
		kontoDotation: { label: 'Konto Dotation', typ: 'iban' },
		kontoCdc: { label: 'Konto CDC', typ: 'iban' },
		kontoInkasso: { label: 'Konto Inkasso', typ: 'iban' },
		kontoKist: { label: 'Konto KiSt', typ: 'iban' },
		kontoKest: { label: 'Konto KESt', typ: 'iban' },
		kontoSoli: { label: 'Konto Soli', typ: 'iban' },
		kontoVorabpauschale: { label: 'Konto Vorabpauschale', typ: 'iban' },
		kontoAnteilsgeschaeftLeipzig: {
			label: 'Konto Anteilsgeschäft Leipzig',
			typ: 'iban',
		},
		kontoZahlungsverkehr: { label: 'Konto Zahlungsverkehr', typ: 'iban' },
		kontoProvision: { label: 'Konto Provision', typ: 'iban' },
		basisablageOrt: { label: 'Basisablage-Ort', typ: 'text', maxLength: 2000 },
		addVerzeichnis: { label: 'ADD-Verzeichnis', typ: 'text', maxLength: 2000 },
		sepaVerzeichnis: { label: 'SEPA-Verzeichnis', typ: 'text', maxLength: 2000 },
		dokumentenVerzeichnis: { label: 'Dokumenten-Verzeichnis', typ: 'text', maxLength: 2000 },
		fileNetBaseLink: { label: 'Filenet Basisverzeichnis', typ: 'text', maxLength: 2000 },
		roboticsVerzeichnis: { label: 'Robotics-Verzeichnis', typ: 'text', maxLength: 2000 },
		linkZumAnwenderhandbuch: {
			label: 'Link zum Anwenderhandbuch',
			typ: 'text',
			maxLength: 2000,
		},
		dekaOrganisationseinheit: {
			label: 'Organisationseinheit',
			typ: 'text',
			maxLength: 2000,
		},
		dekaFinanzinsitutsnummer: { label: 'Finanzinsitutsnummer', typ: 'text', maxLength: 10 },
		dekaTelefonnummer: { label: 'Telefonnummer', typ: 'text', maxLength: 30 },
		dekaFaxnummer: { label: 'Faxnummer', typ: 'text', maxLength: 30 },
		dekaEmail: { label: 'Email', typ: 'text', maxLength: 200 },
		dekaAnsprechpartner: { label: 'Ansprechpartner', typ: 'text', maxLength: 200 },
		dekaFinanzamtName: { label: 'Finanzamtsname', typ: 'text', maxLength: 200 },
		geaendertVon: { label: 'Geändert von', typ: 'backend-text' },
		geaendertAm: { label: 'Geändert am', typ: 'backend-datum' },
	},
};

export interface Konfiguration {
	bankleitzahl: string;
	kontoDotation: string;
	kontoCdc: string;
	kontoInkasso: string;
	kontoKist: string;
	kontoKest: string;
	kontoSoli: string;
	kontoVorabpauschale: string;
	kontoAnteilsgeschaeftLeipzig: string;
	kontoZahlungsverkehr: string;
	kontoProvision: string;
	basisablageOrt: string;
	addVerzeichnis: string;
	sepaVerzeichnis: string;
	dokumentenVerzeichnis: string;
	fileNetBaseLink: string;
	roboticsVerzeichnis: string;
	linkZumAnwenderhandbuch: string;
	dekaOrganisationseinheit: string;
	dekaFinanzinsitutsnummer: string;
	dekaTelefonnummer: string;
	dekaFaxnummer: string;
	dekaEmail: string;
	dekaAnsprechpartner: string;
	dekaFinanzamtName: string;
	geaendertVon: string;
	geaendertAm: string;
}
