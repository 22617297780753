import { OppositionService } from './../opposition/opposition.service';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/shared/alert.service';
import { BenutzerService } from '../benutzer/benutzer.service';
import { FeiertagskalenderService } from '../feiertagskalender/feiertagskalender.service';
import { FinanzinstitutService } from '../finanzinstitut/finanzinstitut.service';
import { GattungService } from '../gattung/gattung.service';
import { KuerzelBezeichnungReferenzlistServiceFactory } from '../kuerzel-bezeichnung/kuerzel-bezeichnung-referenzlist.service';
import {
	KuerzelBezeichnungReferenzlistMetadata,
	KuerzelBezeichnungReferenzMetadata,
	REFERENZLIST_METADATA,
} from '../kuerzel-bezeichnung/model';
import { logger } from 'src/logger';

@Component({
	selector: 'app-stammdaten-uebersicht',
	templateUrl: './stammdaten-uebersicht.component.html',
})
export class StammdatenUebersichtComponent {
	public readonly dataSource = new MatTableDataSource<StammdatenUebersichtItem>();
	public readonly displayedColumns = [
		'label',
		'isLoading',
		'count',
		'lastLoadedOn',
		'actions',
	];

	constructor(
		private readonly router: Router,
		gattungen: GattungService,
		benutzer: BenutzerService,
		finanzinstitute: FinanzinstitutService,
		oppositionService: OppositionService,
		feiertagskalender: FeiertagskalenderService,
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private readonly alert: AlertService,
		@Inject(REFERENZLIST_METADATA)
		referenzlistenMetadata: KuerzelBezeichnungReferenzMetadata,
		private changeDetection: ChangeDetectorRef
	) {
		this.dataSource.data = [
			{ service: gattungen, metadata: gattungen.metadata },
			{ service: benutzer, metadata: benutzer.metadata },
			{ service: finanzinstitute, metadata: finanzinstitute.metadata },
			{ service: oppositionService, metadata: oppositionService.metadata },
			{ service: feiertagskalender, metadata: feiertagskalender.metadata },
			...Object.entries(referenzlistenMetadata).map(([key, metadata]) =>
				this.createStammdatenUebersichtItemForReferenzlist(key, metadata, serviceFactory)
			),
		].sort((a, b) => a.metadata.plural.localeCompare(b.metadata.plural));
	}

	navigate(row?: any): void {
		if (row) {
			let link = row.metadata.routing.list?.url;
			if (!link) {
				logger.error('"metadata.routing.list?.url" is not defined for stammdaten: ', row);
			}
			this.router.navigate([link]);
		}
	}

	reload(item: StammdatenUebersichtItem, button: MatButton): void {
		button.disabled = true;
		this.changeDetection.detectChanges();
		item.service.loadAll$().subscribe({
			next: () => {
				this.alert.success(`${item.metadata.plural} erfolgreich neu geladen`);
				button.disabled = false;
				this.changeDetection.detectChanges();
			},
			error: err => {
				this.alert.error('Neu laden fehlgeschlagen', err);
				button.disabled = false;
				this.changeDetection.detectChanges();
			},
		});
	}

	createStammdatenUebersichtItemForReferenzlist(
		key: string,
		metadata: KuerzelBezeichnungReferenzlistMetadata,
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory
	): StammdatenUebersichtItem {
		return {
			service: serviceFactory.getService(key),
			metadata: {
				plural: metadata.label,
				routing: { list: { url: '/stammdaten/kuerzelBezeichnung/' + key } },
			},
		};
	}
}

export interface StammdatenUebersichtItem {
	metadata: { plural: string; routing: { list?: { url: string; }; }; };
	service: {
		isLoading$: Observable<boolean>;
		count$: Observable<number>;
		lastLoadedOn$: Observable<Date | null>;
		loadAll$(): Observable<void>;
	};
}
