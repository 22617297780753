import { Pipe, PipeTransform } from '@angular/core';
import { Einreichungsperson } from '../model';

@Pipe({
	name: 'personRole',
})
export class PersonRolePipe implements PipeTransform {
	transform(einreichungsperson: Einreichungsperson): string {
		return [
			einreichungsperson.istEinreicher ? 'Einreicher' : '',
			einreichungsperson.istWirtschaftlichBerechtigter ? 'Wirtschaftlich Berechtigter' : '',
		]
			.filter(part => part)
			.join(', ');
	}
}
