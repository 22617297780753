<div class="flex-column">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		[trackBy]="trackBy"
		matSortActive="nachname"
		matSortDirection="asc">
		<ng-container matColumnDef="nachname">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header="nachname">Name</th>
			<td mat-cell *matCellDef="let element">
				{{ element | personName }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="istWirtschaftlichBerechtigter">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Rolle</th>
			<td mat-cell *matCellDef="let element">
				{{ element | personRole }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="kycPruefungsnummer">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				PEP Prüfungsnummer
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.kycPruefungsnummer }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="embargoPruefungsnummer">
			<th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
				Embargo Prüfungsnummer
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.embargoPruefungsnummer }}
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th
				mat-header-cell
				*matHeaderCellDef
				scope="col"
				style="width: 8em"
				class="action-column"></th>
			<td mat-cell *matCellDef="let element" style="" class="action-column">
				<button mat-icon-button><mat-icon>edit</mat-icon></button>
				<button mat-icon-button color="primary" (click.stop)="remove(element)">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="mat-row-clickable"
			(click)="open(row)"></tr>

		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>
	<div class="flex-row" style="margin-bottom: 0.5em">
		<span style="flex: 1"></span>
		<button
			*ngIf="!korrektur"
			mat-raised-button
			color="primary"
			(click)="open()"
			style="flex: 15em 0 0">
			<mat-icon>add</mat-icon>
			Neue Person
		</button>
	</div>
</div>
