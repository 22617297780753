import { DateAdapter } from '@angular/material/core';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/general/base-component';
import { UltimoRisikoklassenbewertungEintrag } from '../model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, catchError } from 'rxjs';
import { UltimoService } from '../ultimo.service';
import { AlertService } from 'src/app/shared/alert.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { einreichungspersonMetadata, geschaeftsfallMetadata } from '../../model';
import * as ExcelJS from 'ExcelJS-workaround';

@Component({
	selector: 'app-ultimo-risikoklassenbewertung',
	templateUrl: './ultimo-risikoklassenbewertung.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltimoRisikoklassenbewertungComponent
	extends BaseComponent
	implements AfterViewInit
{
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	@ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

	startYear = new Date();
	selectedYear: number | null = null;

	public readonly isSearching$ = new BehaviorSubject(false);
	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly personMetadata = einreichungspersonMetadata;
	public readonly geschaeftsfallMetadata = geschaeftsfallMetadata;
	public readonly dataSource = new MatTableDataSource<UltimoRisikoklassenbewertungEintrag>();
	public readonly displayedColumns = [
		'kunde',
		'anlagedatum',
		'gb',
		'we',
		'filiale',
		'gruppe',
		'staatsangehoerigkeit',
		'adressland',
		'branche',
		'rechtsform',
		'pep',
		'kundentyp',
		'qualitativeAnalyse',
		'risikovererbung',
		'konto',
		'gb2',
		'produkt',
		'konto2',
		'kunde2',
		'relationstyp',
	];
	readonly searchForm = new TypedForm<FormValues>({
		year: new UntypedFormControl(),
	});

	constructor(
		private dateAdapter: DateAdapter<Date>,
		private service: UltimoService,
		private alert: AlertService,
		private save: SaveService
	) {
		super();

		this.dateAdapter.setLocale('de-DE');

		this.dataSource.sortingDataAccessor = (element, property) => {
			switch (property) {
				case 'kunde':
				case 'anlagedatum':
				case 'gb':
				case 'we':
				case 'filiale':
				case 'gruppe':
				case 'staatsangehoerigkeit':
				case 'adressland':
				case 'branche':
				case 'rechtsform':
				case 'pep':
				case 'kundentyp':
				case 'qualitativeAnalyse':
				case 'risikovererbung':
				case 'konto':
				case 'gb2':
				case 'produkt':
				case 'konto2':
				case 'kunde2':
				case 'relationstyp':
					return element[property];
				default:
					return '';
			}
		};
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	downloadAsCsv() {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Risikoklassenbewertung');
		worksheet.addRow([
			'Smaragd CRS: C',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'Smaragd CRS: A',
			'',
			'',
			'Smaragd CRS: R',
			'',
			'',
		]);
		worksheet.addRow([
			'Kunde',
			'Anlagedatum',
			'GB',
			'WE',
			'Filiale',
			'Gruppe',
			'Staatsangehoerigkeit',
			'Adressland',
			'Branche',
			'Rechtsform',
			'PEP',
			'Kundentyp',
			'Qualitative Analyse',
			'Risikovererbung',
			'Konto',
			'GB',
			'Produkt',
			'Konto',
			'Kunde',
			'Relationstyp',
		]);
		for (const element of this.dataSource.data) {
			worksheet.addRow([
				element.kunde,
				element.anlagedatum,
				element.gb,
				element.we,
				element.filiale,
				element.gruppe,
				element.staatsangehoerigkeit,
				element.adressland,
				element.branche,
				element.rechtsform,
				element.pep,
				element.kundentyp,
				element.qualitativeAnalyse,
				element.risikovererbung,
				element.konto,
				element.gb2,
				element.produkt,
				element.konto2,
				element.kunde2,
				element.relationstyp,
			]);
		}
		const options = {
			// https://c2fo.io/fast-csv/docs/formatting/options
			formatterOptions: {
				delimiter: ';',
				quoteColumns: true,
				writeBOM: true,
			},
		};

		workbook.csv.writeBuffer(options).then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			const now: string = new Date().toISOString().slice(0, 10);
			this.save.saveAs(blob, 'CsvExportComplianceRisikoklassenbewertung_' + now + '.csv');
		});
	}

	updateSelectedYear(event: any) {
		this.selectedYear = event.getFullYear();
		this.search();
		const yearPicker: any = document.querySelector('mat-datepicker-content');
		if (yearPicker) {
			yearPicker.style.display = 'none';
		}
	}

	displaySelectedYear(): Date | null {
		if (this.selectedYear !== null) {
			const lastDayOfYear = new Date(this.selectedYear, 11, 31);
			return lastDayOfYear;
		}
		return null;
	}

	search() {
		if (this.selectedYear) {
			this.service
				.getUltimoComplianceRisikoklassenbewertungData$(this.selectedYear)
				.pipe(
					catchError(err => {
						this.dataSource.data = [];
						this.alert.error('Konnte nicht laden!', err, {
							date: this.searchForm.typedValue,
						});
						this.isSearching$.next(false);
						return EMPTY;
					})
				)
				.subscribe(items => {
					this.dataSource.data = items;
					this.isSearching$.next(false);
				});
		}
	}
}
interface FormValues {
	year: Date;
}
