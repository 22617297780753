import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import {
	AbstractControl,
	UntypedFormControl,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { KuerzelBezeichnungReferenz } from 'src/app/stammdaten/kuerzel-bezeichnung/model';
import { EinreichungService } from '../../einreichung.service';
import { Einreichungsperson, einreichungspersonMetadata } from '../../model';

@Component({
	selector: 'app-einreichung-edit-person-popup',
	templateUrl: './einreichung-edit-person-popup.component.html',
	styleUrls: ['./einreichung-edit-person-popup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungEditPersonPopupComponent
	extends BaseComponent {
	public readonly metadata = einreichungspersonMetadata;

	readonly form = new TypedForm<EinreichungspersonForm>({
		id: new UntypedFormControl(null),
		nummer: new UntypedFormControl(null),
		istVerein: createFormControl(this.metadata.fields.istVerein),
		istEinreicher: createFormControl(this.metadata.fields.istEinreicher),
		istWirtschaftlichBerechtigter: createFormControl(
			this.metadata.fields.istWirtschaftlichBerechtigter
		),
		anrede: createFormControl(this.metadata.fields.anrede),
		vorname: createFormControl(this.metadata.fields.vorname),
		nachname: createFormControl(this.metadata.fields.nachname),
		adresszusatz: createFormControl(this.metadata.fields.adresszusatz),
		adresse: createFormControl(this.metadata.fields.adresse),
		postleitzahl: createFormControl(this.metadata.fields.postleitzahl),
		ort: createFormControl(this.metadata.fields.ort),
		bundesland: createFormControl(this.metadata.fields.bundesland),
		land: createFormControl(this.metadata.fields.land),
		geburtsort: createFormControl(this.metadata.fields.geburtsort),
		geburtsland: createFormControl(this.metadata.fields.geburtsland),
		geburtsdatum: createFormControl(this.metadata.fields.geburtsdatum),
		staatsangehoerigkeit: createFormControl(this.metadata.fields.staatsangehoerigkeit),
		ausweisart: createFormControl(this.metadata.fields.ausweisart),
		ausweisnummer: createFormControl(this.metadata.fields.ausweisnummer),
		ausweisAusgestelltAm: createFormControl(this.metadata.fields.ausweisAusgestelltAm),
		ausweisAusgestelltVon: createFormControl(this.metadata.fields.ausweisAusgestelltVon),
		ausweisGueltigBis: createFormControl(this.metadata.fields.ausweisGueltigBis),
		email: createFormControl(this.metadata.fields.email),
		dekaDepotVorhanden: createFormControl(this.metadata.fields.dekaDepotVorhanden),
		kycPruefungsnummer: createFormControl(this.metadata.fields.kycPruefungsnummer),
		embargoPruefungsnummer: createFormControl(this.metadata.fields.embargoPruefungsnummer),
		kycPositiv: createFormControl(this.metadata.fields.kycPositiv),
		sapNummer: createFormControl(this.metadata.fields.sapNummer),
		dsgvoAenderungsgrund: createFormControl(this.metadata.fields.dsgvoAenderungsgrund),
		kycChecks: new UntypedFormControl(null),
	});

	readonly cannotAccept$ = this.form.statusChanges.pipe(
		map(s => s !== 'VALID'),
		startWith(true)
	);
	
	constructor(
		@Inject(MAT_DIALOG_DATA) public einreichungsperson: Einreichungsperson,
		private readonly ref: MatDialogRef<EinreichungEditPersonPopupComponent>,
		public readonly einreichungService: EinreichungService,
		public readonly simpleDialogService: SimpleDialogService,
	) {
		super();

		this.registerSubscription(this.ref.backdropClick().subscribe(() => this.tryCancel()));

		this.form.addValidators(personRoleValidator);

		// #region SUBSCRIPTION
		this.registerSubscription(
			this.form.controls.istVerein.valueChanges.subscribe(isClub => {
				const fieldNames4RequiredToggeling: string[] = [
					'geburtsdatum',
					'geburtsort',
					'staatsangehoerigkeit',
					'ausweisart',
					'ausweisnummer',
					'ausweisAusgestelltAm',
					'ausweisAusgestelltVon',
					'ausweisGueltigBis',
					'vorname',
				];
				if (isClub) {
					// ist Verein
					for (const fieldname of fieldNames4RequiredToggeling) {
						this.form.controls[fieldname].removeValidators(Validators.required);
						this.form.controls[fieldname].reset(this.form.controls[fieldname].value);
						this.form.controls[fieldname].updateValueAndValidity();
					}
				} else {
					for (const fieldname of fieldNames4RequiredToggeling) {
						this.form.controls[fieldname].addValidators(Validators.required);
						this.form.controls[fieldname].reset(this.form.controls[fieldname].value);
						this.form.controls[fieldname].updateValueAndValidity();
					}
				}
			})
		);

		this.registerSubscription(
			this.form.values$.subscribe(v => {
				const bundeslandControl = this.form.controls.bundesland;
				if (v.land === 'DE') {
					if (bundeslandControl.disabled) {
						bundeslandControl.addValidators(Validators.required);
						bundeslandControl.enable({ emitEvent: false });
					}
				} else {
					if (bundeslandControl.value) {
						bundeslandControl.setValue(null);
						return;
					}

					if (bundeslandControl.enabled) {
						bundeslandControl.removeValidators(Validators.required);
						bundeslandControl.disable({ emitEvent: false });
					}
				}
			})
		);

		// #endregion SUBSCRIPTION

		this.form.resetTyped(einreichungsperson);
	}
	@HostListener('window:keyup.enter')
	accept(): void {
		if (this.form.valid) {
			let person = this.form.value as Einreichungsperson;
			this.ref.close(person);
		}
	}

	tryCancel(): void {
		this.ref.close();
	}

	depotVorhandenLabel() {
		let ret = 'Deka-Depot vorhanden';
		if (this.form.controls['ausweisart'].value === 'S') {
			ret = 'Verstorben';
		}
		return ret;
	}

	onUserEnteredPostleitzahl(ref: KuerzelBezeichnungReferenz): void {
		if (!ref) return;

		const currentValues = this.form.typedValue;
		if (currentValues.land === 'DE' && ref.bezeichnung && ref.extra) {
			this.form.patchValue({ ort: ref.bezeichnung, bundesland: ref.extra });
		}
	}
}

function personRoleValidator(a: AbstractControl): ValidationErrors | null {
	const val = a.value as Einreichungsperson;
	if (!val.istEinreicher && !val.istWirtschaftlichBerechtigter) {
		return { personRoleError: true };
	} else return null;
}

type EinreichungspersonForm = Einreichungsperson;
