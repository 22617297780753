import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CurrentUserService } from 'src/app/general/current-user.service';

@Directive({
	selector: '[requireRole]',
})
export class RequireRoleDirective {
	private isShowing = false;

	@Input() set requireRole(val: string | string[]) {
		const shouldShow = this.currentUser.hasAnyOfRoles(val);

		if (shouldShow === this.isShowing) return;

		if (shouldShow) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.isShowing = true;
		} else {
			this.viewContainer.clear();
			this.isShowing = false;
		}
	}

	constructor(
		private currentUser: CurrentUserService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}
}
