import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, TrackByFunction } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
	BehaviorSubject,
	catchError,
	debounceTime,
	distinctUntilChanged,
	EMPTY,
	filter,
	Observable,
	switchMap,
	tap,
} from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { SaveService } from 'src/app/shared/services/save.service';
import { UltimoKistEintrag, ultimoMetadata } from '../model';
import { UltimoService } from '../ultimo.service';

export const MY_FORMATS = {
	parse: {
		dateInput: 'MM/YYYY',
	},
	display: {
		dateInput: 'MM/yyyy',
		monthYearLabel: 'MMM yyyy',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM yyyy',
	},
};

@Component({
	selector: 'app-ultimo-kist',
	templateUrl: './ultimo-kist.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class UltimoKistComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatSort) sort?: MatSort;
	@ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

	public readonly isSearching$ = new BehaviorSubject(false);
	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly metadata = ultimoMetadata;

	public readonly dataSource = new MatTableDataSource<UltimoKistEintrag>();
	public readonly displayedColumns = ['kistam', 'text', 'zeileKapst', 'summe'];
	public readonly trackBy: TrackByFunction<UltimoKistEintrag> = (index, item) => item.kistam;

	public sumOfSums: number = 0;
	public dateNow: Date = new Date();
	readonly filterForm: UntypedFormGroup = new UntypedFormGroup({
		datum: new UntypedFormControl(''),
	});
	readonly formValues$ = this.filterForm.valueChanges as Observable<FormValues>;

	constructor(
		private readonly service: UltimoService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {
		super();
		this.registerSubscription(
			this.formValues$
				.pipe(
					filter(fv => !!fv && fv.datum && !isNaN(fv.datum.getTime())),
					debounceTime(400),
					distinctUntilChanged(),
					tap(() => {
						this.isSearching$.next(true);
						this.dataSource.data = [];
					}),
					switchMap(requestData => {
						// console.warn('adatum: ', dateToYYYYMM(requestData.datum));
						return this.service.getUltimoData$<UltimoKistEintrag>(
							'kist',
							this.service.dateToYYYYMM(requestData.datum)
						);
					}),
					catchError(err => {
						this.alert.error('Konnte nicht laden!', err);
						this.isSearching$.next(false);
						return EMPTY;
					})
				)
				.subscribe(items => {
					this.dataSource.data = items;
					this.sumOfSums = items.map(s => s.summe).reduce((acc, value) => acc + value, 0);
					this.isSearching$.next(false);
				})
		);
	}

	ngOnInit(): void {
		this.filterForm.patchValue({ datum: this.dateNow });
	}

	ngAfterViewInit(): void {
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	openDatePicker(): void {
		this.picker?.open();
	}

	closeDatePicker(selectedDate: Date): void {
		// get selectedDate and close datepicker, thus prevent user to select day
		this.filterForm.patchValue({ datum: selectedDate });
		this.picker?.close();
	}

	downloadAsExcel(): void {
		const selectedMonth: Date = this.filterForm.get('datum')?.value;
		if (selectedMonth && !isNaN(selectedMonth.getTime())) {
			this.isLoading$.next(true);
			this.service.getUltimoDocument$('kist', this.service.dateToYYYYMM(selectedMonth)).subscribe({
				next: result => {
					this.save.saveAs(result.data, result.filename);
					this.alert.success('Download erfolgreich');
					this.isLoading$.next(false);
				},
				error: err => {
					this.alert.error('Download fehlgeschlagen', err);
					this.isLoading$.next(false);
				},
			});
		}
	}
}

interface FormValues {
	datum: Date;
}
