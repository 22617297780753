import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit {
	// Properties
	@Input() clearSelectionOnOpenFiledialog: boolean = false;
	@Input() accept = ''; // File extensions are allowed (mime-types commasepareted)
	@Input() placeholder = 'CSV Datei auswählen..';
	@Output() fileSelected = new EventEmitter<Event>();
	@Output() fileChooserOpened = new EventEmitter<Event>();

	// Attributes
	kistamCsvFile = new UntypedFormControl('');
	file: File | undefined | null;

	constructor() { }

	ngOnInit() { }

	onFileSelected(event: Event) {
		const files = (event.target as HTMLInputElement).files;
		if (files && files.length) {
			this.file = files?.item(0);
		} else return;

		this.fileSelected.emit(event);
	}

	onClickFileinput(event: Event) {
		this.fileChooserOpened.emit(event);

		if (this.clearSelectionOnOpenFiledialog) {
			this.kistamCsvFile.setValue('');
		}
	}
}
