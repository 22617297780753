import { EntityMetadata, RequiredLevel } from 'src/app/model';

export interface Benutzer {
	id: string;
	name: string;
}

export const benutzerMetadata: EntityMetadata<Benutzer> = {
	label: 'Benutzer',
	labelGender: 'm',
	apiCollectionName: 'benutzer',
	plural: 'Benutzer',
	fields: {
		name: { label: 'Name', maxLength: 20, requiredLevel: RequiredLevel.RequiredToSave, typ: 'text' },
	},
	routing: {},
};
