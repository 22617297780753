import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-einreichung-fileimport-csv-preview',
  templateUrl: './einreichung-fileimport-csv-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EinreichungFileimportCsvPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
