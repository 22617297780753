import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'gattungAusschuettungsdatum',
})
export class GattungAusschuettungsdatumPipe implements PipeTransform {
	transform(ausschuettungsdatum: string[] | null): string {
		if (!ausschuettungsdatum || ausschuettungsdatum.length === 0) return '--';
		return ausschuettungsdatum.join(', ');
	}
}
