<mat-form-field style="width: 100%">
	<mat-label>{{ label }}</mat-label>
	<input
		#textInputField
		autocomplete="off"
		type="text"
		[placeholder]="placeholder"
		matInput
		[formControl]="textControl"
		[matAutocomplete]="auto"
		(blur)="onTouched()"
		style="width: 100%"
		[required]="!!(isRequired$ | async)" />
	<span matSuffix class="text-input-suffix">
		<mat-icon (click)="openDialog()" class="suffix-icon">search</mat-icon>
		<mat-icon (click)="clear()" class="suffix-icon">close</mat-icon>
	</span>

	<mat-autocomplete #auto="matAutocomplete">
		<mat-option *ngFor="let entry of filteredEntries$ | async" [value]="entry.displayName">
			{{ entry.displayName }}
		</mat-option>
	</mat-autocomplete>
	<mat-error>{{ textControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
