import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Finanzinstitut, FinanzinstitutRolle } from '../../model';

@Component({
	selector: 'app-finanzinstitut-choose-dialog',
	templateUrl: './finanzinstitut-choose-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanzinstitutChooseDialogComponent {
	finanzinstitutRolle?: FinanzinstitutRolle;
	constructor(
		@Inject(MatDialogRef)
		private readonly ref: MatDialogRef<FinanzinstitutChooseDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public readonly role: FinanzinstitutRolle
	) {
		if (role) {
			this.finanzinstitutRolle = role;
		} else {
			this.finanzinstitutRolle = FinanzinstitutRolle.EmittentUndEinreicher;
		}
	}

	choose(institut: Finanzinstitut): void {
		this.ref.close(institut);
	}
}
