import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { geschaeftsfallMetadata } from 'src/app/bewegungsdaten/model';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';

@Component({
	selector: 'app-geschaeftsfall-abschliessen-popup',
	templateUrl: './geschaeftsfall-abschliessen-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallAbschliessenPopupComponent {
	public readonly metadata = geschaeftsfallMetadata;
	public readonly form = new TypedForm<{ vorgangsnummer: string }>({
		vorgangsnummer: createFormControl(geschaeftsfallMetadata.fields.vorgangsnummer),
	});
	readonly cannotAccept$ = this.form.statusChanges.pipe(
		map(s => s !== 'VALID'),
		startWith(true)
	);
	constructor(private ref: MatDialogRef<GeschaeftsfallAbschliessenPopupComponent>) {
		this.ref.backdropClick().subscribe(b => this.tryCancel());
		this.form.controls.vorgangsnummer.addValidators([Validators.required, Validators.maxLength(20)]);
	}

	tryCancel(): void {
		this.ref.close();
	}
	accept(): void {
		this.ref.close({ dialogResult: 'Ja', ...this.form.typedValue });
	}
}
