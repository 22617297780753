import { Injectable } from '@angular/core';
import { EntityServiceBaseMock } from '../entity.service.base.mock';
import { Benutzer, benutzerMetadata } from './model';

@Injectable({
	providedIn: 'root',
})
export class BenutzerServiceMock extends EntityServiceBaseMock<Benutzer> {
	constructor() {
		super(benutzerMetadata);
	}
}
