import { logger } from 'src/logger';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { encode } from 'punycode';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { EinreichungService } from '../einreichung.service';
import {
	calculateVorabpauschaleSteuersummen,
	Einreichung,
	einreichungMetadata,
	VorabpauschaleSteuersummen,
} from '../model';

@Component({
	selector: 'app-einreichung-details',
	templateUrl: './einreichung-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungDetailsComponent implements OnInit {
	@Input() einreichung: Einreichung;
	@Input() showHeader = true;

	readonly metadata = einreichungMetadata;
	// dokumentreferenz: string = '';

	public vorabpauschaleKest = 0;
	public vorabpauschaleKiSt = 0;
	public vorabpauschaleSoli = 0;

	public vorabpauschaleSteuersummen: VorabpauschaleSteuersummen;

	public readonly neuerfassungZu$ = new BehaviorSubject<{ id: string; nummer: number; } | null>(null);

	constructor(
		public readonly quickInfo: QuickInfoPopupService,
		private readonly konfigurationService: KonfigurationService,
		private readonly service: EinreichungService
	) {
		this.einreichung = {} as Einreichung;
		this.vorabpauschaleSteuersummen = {} as VorabpauschaleSteuersummen;
	}

	get fileNetBaseLink(): string | null {
		let fileNetBaseLink: string = this.konfigurationService.konfiguration$.value.fileNetBaseLink;
		let searchCriteriaString = `"property":"REFERENZ_NUMMER","value":"${this.einreichung.nummer}"`;
		searchCriteriaString = encodeURI(searchCriteriaString);
		let ret: string | null = fileNetBaseLink + `&searchCriteria=[{${searchCriteriaString}}]&autoRunSearch=true`;
		return ret;
	}

	ngOnInit(): void {
		if (!this.einreichung) {
			throw new Error('Einreichung must be set!');
		}

		if (!this.einreichung.neuerfassungZu) {
			this.neuerfassungZu$.next({ id: '', nummer: 0 });
		} else {
			this.service
				.loadSingle$(this.einreichung.neuerfassungZu)
				.pipe(
					map(e => {
						return { id: e.id, nummer: e.nummer };
					}),
					catchError(err => {
						console.error(err);
						return of({ id: '', nummer: 0 });
					})
				)
				.subscribe(val => this.neuerfassungZu$.next(val));
		}

		this.vorabpauschaleSteuersummen = calculateVorabpauschaleSteuersummen(this.einreichung);
		this.vorabpauschaleKest = this.vorabpauschaleSteuersummen.kestSumme;
		this.vorabpauschaleSoli = this.vorabpauschaleSteuersummen.soliSumme;
		this.vorabpauschaleKiSt = this.vorabpauschaleSteuersummen.kistSumme;

		// this.dokumentreferenz = `${this.konfigurationService.konfiguration$.value.basisablageOrt}/${this.einreichung.nummer}`;
	}
}
