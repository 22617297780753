import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as ExcelJS from 'ExcelJS-workaround';

@Component({
	selector: 'app-excel-choose-worksheet',
	templateUrl: './excel-choose-worksheet.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcelChooseWorksheetComponent {
	public readonly selectSheetControl = new UntypedFormControl(null, [Validators.required]);

	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ExcelChooseWorksheet) {}
}

export interface ExcelChooseWorksheet {
	workbook: ExcelJS.Workbook;
}
