<div class="page-header">
	<h1>{{ metadata.plural }}</h1>
	<div>
		<button
			mat-raised-button
			color="accent"
			(click)="refresh()"
			[disabled]="isRefreshing$ | async">
			<mat-icon *ngIf="isRefreshing$ | async | not">refresh</mat-icon>
			<mat-spinner
				*ngIf="isRefreshing$ | async"
				[diameter]="15"
				style="display: inline-block; margin-right: 0.5em"></mat-spinner>
			Aktualisieren
		</button>
		<button
			mat-raised-button
			color="accent"
			(click)="downloadAsExcel(list.dataSource.filteredData)">
			<mat-icon>file_download</mat-icon>
			Als Excel herunterladen
		</button>
		<ng-container *requireRole="['standard']">
			<button
				mat-raised-button
				color="accent"
				[disabled]="numberOfChosen === 0"
				(click)="csvExportKistam(list.dataSource.filteredData)"
				*ngIf="
					(activatedRoute.queryParamMap | async)?.get('status') ===
						einreichungStatus.InBearbeitung ||
					(activatedRoute.queryParamMap | async)?.get('status') ===
						einreichungStatus.Eingegangen
				">
				<mat-icon *ngIf="isLoading$ | async | not">file_download</mat-icon>
				<mat-spinner
					*ngIf="isLoading$ | async"
					[diameter]="20"
					style="display: inline-block; margin-right: 0.5em"></mat-spinner>
				CSV Export Kistam
			</button>
		</ng-container>
		<ng-container *requireRole="['standard']">
			<button
				mat-raised-button
				color="accent"
				(click)="openFileKistamFileimportDialog()"
				[disabled]="einreichungen.isImportingKistamCsv$ | async"
				*ngIf="
					(activatedRoute.queryParamMap | async)?.get('status') ===
						einreichungStatus.InBearbeitung ||
					(activatedRoute.queryParamMap | async)?.get('status') ===
						einreichungStatus.Eingegangen
				">
				<mat-icon *ngIf="einreichungen.isImportingKistamCsv$ | async | not">
					file_upload
				</mat-icon>
				<mat-spinner
					*ngIf="einreichungen.isImportingKistamCsv$ | async"
					[diameter]="20"
					style="display: inline-block; margin-right: 0.5em"></mat-spinner>
				CSV Import Kistam
			</button>
		</ng-container>
		<button
			mat-raised-button
			color="primary"
			[routerLink]="metadata.routing.edit!.url('new')"
			*requireRole="'standard'">
			<mat-icon>add</mat-icon>
			Neue Einreichung
		</button>
	</div>
</div>

<div class="flex-column">
	<div class="mat-elevation-z2">
		<app-einreichung-list
			#list
			(selected$)="openPopup($event)"
			(doubleClick$)="open($event)"
			[statusFilter]="(activatedRoute.queryParamMap | async)?.get('status')"
			(numberOfChosen)="onChosenChange($event)"
			[enableSelection]="
				(activatedRoute.queryParamMap | async)?.get('status') ===
					einreichungStatus.InBearbeitung ||
				(activatedRoute.queryParamMap | async)?.get('status') ===
					einreichungStatus.Eingegangen
			"></app-einreichung-list>
	</div>
</div>
