<div class="page-header">
	<h1>{{ metadata.plural }}</h1>
	<ng-container *requireRole="['standard']">
		<button mat-raised-button color="accent" (click)="onClickCsvImport()">
			<mat-icon>file_upload</mat-icon>
			<mat-spinner
				*ngIf="oppositionService.isImportingCsv$ | async"
				[diameter]="20"
				style="display: inline-block; margin-right: 0.5em"></mat-spinner>
			CSV Import Opposition
		</button>
	</ng-container>
</div>

<section class="table-container mat-elevation-z2" style="min-height: 90%">
	<table
		mat-table
		matSort
		matSortActive="bezeichnung1"
		matSortDirection="asc"
		[dataSource]="dataSource"
		style="width: 3000px">
		<ng-container [matColumnDef]="column" *ngFor="let column of allColumns">
			<div *ngIf="getColumnDef(column); let c">
				<th
					[id]="column"
					mat-header-cell
					mat-sort-header
					*matHeaderCellDef
					[style]="c.labelStyle"
					[matTooltip]="c.description!">
					<div [innerHTML]="c.label"></div>
				</th>
				<td mat-cell *matCellDef="let element">{{ getValue(element, column) }}</td>
			</div>
		</ng-container>

		<ng-container *matHeaderRowDef="allColumns">
			<tr mat-header-row></tr>
			<tr class="mat-header-row" [formGroup]="filterForm" class="table-filter-row">
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="isin"
							autocomplete="off" />
					</mat-form-field>
				</th>
				<th class="mat-header-cell" scope="col">
					<mat-form-field>
						<input
							matInput
							placeholder="Suche..."
							formControlName="wkn"
							autocomplete="off" />
					</mat-form-field>
				</th>
			</tr>
		</ng-container>

		<tr mat-row *matRowDef="let row; columns: allColumns"></tr>
	</table>
</section>
<mat-paginator showFirstLastButtons></mat-paginator>
