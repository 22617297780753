import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { determineVorabpauschaleStatus } from 'src/app/berechnung/steuern';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import {
	KuerzelBezeichnungReferenzlistService,
	KuerzelBezeichnungReferenzlistServiceFactory,
} from 'src/app/stammdaten/kuerzel-bezeichnung/kuerzel-bezeichnung-referenzlist.service';
import {
	extract,
	formatDateForSaveModel,
	INTERNAL_NUMBER_FACTOR,
	isValid$,
	round2,
} from 'src/app/utils';
import { logger } from 'src/logger';
import {
	EinreichungSaveModel,
	EinreichungService,
	VorabpauschaleStatus,
} from '../einreichung.service';
import {
	Einreichung,
	einreichungMetadata,
	Einreichungsposition,
	GeschaeftsfallStatus,
	Kistam_EV,
	Kistam_RK,
	Religionszugehoerigkeit,
} from '../model';
import { GeschaeftsfallService } from '../geschaeftsfall.service';
import { getPersonName } from '../pipes/person-name.pipe';
import { Einreichungsperson, EinreichungStatus } from './../model';
import {
	EinreichungSchnellerfassungComponent,
	SchnellerfassungResult,
} from './einreichung-schnellerfassung/einreichung-schnellerfassung.component';
import { SchnellerfassungSearchCriteria } from './einreichung-schnellerfassung/schnellerfassung.service';
import { ReportsHasChanged } from 'src/app/shared/route-guards/unsaved-changes-guard';
import { FinanzinstitutRolle } from 'src/app/stammdaten/finanzinstitut/model';

const defaultFormValues: EinreichungEditFormValues = {
	betrag: 0,
	eingangsdatum: null,
	einreicher: null,
	absender: '',
	anzahlUmschlaege: 0,
	bemerkung: '',
	dekaDepotVorhanden: false,
	couponShouldPay: false,
	depotInhaber: '',
	depotNummer: '',
	empfaenger: '',
	erbfall: false,
	externeReferenz: '',
	kistam: '',
	kistamProzentsatz: 0,
	konto: '',
	kontoInhaber: '',
	neuerfassungZu: '',
	posteingangsdatum: null,
	schnellerfassungInfo: '',
	sendungsnummer: '',
	valutatag: null,
	versandart: '',
	vorabpauschale: 0,
	vorabpauschaleGesamtsumme: 0,
	waehrungStueck: 'Stück',
	abbruchStornoGrund: '',
	vorabpauschaleEingegangen: false,
	istEinreichungPrivat: false,
	oppositionstreffer: null,
	oppositionFreigeber: null,
	oppositionSystem: null,
	geaendertVon: '',
	geaendertAm: null,
	freigegebenVon: '',
	freigegebenAm: null,
	personen: [],
	positionen: [],
	kassenvereinsnummer: '',
	bic: '',
	dokumentenLink: '',
	religionszugehoerigkeit: ' ' as Religionszugehoerigkeit,
};

@Component({
	selector: 'app-einreichung-edit-page',
	templateUrl: './einreichung-edit-page.component.html',
	styleUrls: ['./einreichung-edit-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungEditPageComponent extends BaseComponent implements ReportsHasChanged {
	// #region Attributes / Properties
	public readonly metadata = einreichungMetadata;
	public refService: KuerzelBezeichnungReferenzlistService;
	private bankRefService: KuerzelBezeichnungReferenzlistService;

	public readonly finanzinstitutRolle = FinanzinstitutRolle.Einreicher;

	public readonly form = new TypedForm<EinreichungEditFormValues>({
		einreicher: createFormControl(einreichungMetadata.fields.einreicher),
		eingangsdatum: createFormControl(einreichungMetadata.fields.eingangsdatum),
		betrag: createFormControl(einreichungMetadata.fields.betrag),
		absender: createFormControl(einreichungMetadata.fields.absender),
		anzahlUmschlaege: createFormControl(einreichungMetadata.fields.anzahlUmschlaege),
		bemerkung: createFormControl(einreichungMetadata.fields.bemerkung),
		dekaDepotVorhanden: createFormControl(einreichungMetadata.fields.dekaDepotVorhanden),
		couponShouldPay: createFormControl(einreichungMetadata.fields.couponShouldPay, false),
		depotInhaber: createFormControl(einreichungMetadata.fields.depotInhaber),
		depotNummer: createFormControl(einreichungMetadata.fields.depotNummer),
		empfaenger: createFormControl(einreichungMetadata.fields.empfaenger),
		erbfall: createFormControl(einreichungMetadata.fields.erbfall),
		externeReferenz: createFormControl(einreichungMetadata.fields.externeReferenz),
		kistam: createFormControl(einreichungMetadata.fields.kistam),
		kistamProzentsatz: createFormControl(einreichungMetadata.fields.kistamProzentsatz),
		konto: createFormControl(einreichungMetadata.fields.konto),
		kontoInhaber: createFormControl(einreichungMetadata.fields.kontoInhaber),
		neuerfassungZu: createFormControl(einreichungMetadata.fields.neuerfassungZu),
		posteingangsdatum: createFormControl(einreichungMetadata.fields.posteingangsdatum),
		schnellerfassungInfo: createFormControl(einreichungMetadata.fields.schnellerfassungInfo),
		sendungsnummer: createFormControl(einreichungMetadata.fields.sendungsnummer),
		valutatag: createFormControl(einreichungMetadata.fields.valutatag),
		versandart: createFormControl(einreichungMetadata.fields.versandart),
		vorabpauschale: createFormControl(einreichungMetadata.fields.vorabpauschale),
		vorabpauschaleGesamtsumme: createFormControl(
			einreichungMetadata.fields.vorabpauschaleGesamtsumme
		),
		waehrungStueck: createFormControl(einreichungMetadata.fields.waehrungStueck),
		abbruchStornoGrund: createFormControl(einreichungMetadata.fields.abbruchStornoGrund),
		vorabpauschaleEingegangen: createFormControl(
			einreichungMetadata.fields.vorabpauschaleEingegangen
		),
		oppositionstreffer: createFormControl(einreichungMetadata.fields.oppositionstreffer),
		oppositionFreigeber: createFormControl(einreichungMetadata.fields.oppositionFreigeber),
		oppositionSystem: createFormControl(einreichungMetadata.fields.oppositionSystem),
		istEinreichungPrivat: new UntypedFormControl(false),
		geaendertVon: createFormControl(einreichungMetadata.fields.geaendertVon),
		geaendertAm: createFormControl(einreichungMetadata.fields.geaendertAm),
		freigegebenVon: createFormControl(einreichungMetadata.fields.freigegebenVon),
		freigegebenAm: createFormControl(einreichungMetadata.fields.freigegebenAm),
		personen: new UntypedFormControl([]),
		positionen: new UntypedFormControl([]),
		kassenvereinsnummer: createFormControl(einreichungMetadata.fields.kassenvereinsnummer),
		bic: createFormControl(einreichungMetadata.fields.bic),
		dokumentenLink: createFormControl(einreichungMetadata.fields.dokumentenLink),
		religionszugehoerigkeit: createFormControl(
			einreichungMetadata.fields.religionszugehoerigkeit
		),
	});

	public readonly pageHeaderValues$ = new BehaviorSubject<{
		nummer: number;
		status: EinreichungStatus;
	}>({} as any);
	public readonly cannotSave$ = isValid$(this.form).pipe(map(isValid => !isValid));
	public readonly isSaving$ = new BehaviorSubject(false);
	public readonly positionenZusammenfassung$: Observable<{ label: string; value: string; }[]> =
		this.form.controls.positionen.valueChanges.pipe(
			map((positionen: Einreichungsposition[]) => {
				if (!positionen) positionen = [];
				const values = positionen.reduce(
					(agg, p) => ({ gesamtwert: agg.gesamtwert + p.gesamtwert }),
					{
						gesamtwert: 0,
					}
				);
				
				//Wert zur Anzeige bei geschlossenen Paneel um Faktor 1000000 zu hoch
				values.gesamtwert=(values.gesamtwert/INTERNAL_NUMBER_FACTOR)

				return [
					{ label: 'Positionen', value: positionen.length.toLocaleString('de') },
					{
						label: 'Gesamtbetrag',
						value:
							values.gesamtwert.toLocaleString('de', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) + ' EUR',
					},
				];
			})
		);

	public readonly einreicherZusammenfassung$: Observable<string> = this.form.values$.pipe(
		map(v => {
			if (v.istEinreichungPrivat && v.personen) {
				const personenText =
					v.personen.length === 1 ? '1 Person' : `${v.personen.length} Personen`;
				const wirtschaftlichBerechtigtAnzahl = v.personen.filter(
					p => p.istWirtschaftlichBerechtigter
				).length;
				const wirtschaftlichBerechtigtText = `${wirtschaftlichBerechtigtAnzahl} wirtschaftlich berechtigt`;
				return `Private Einreichung, ${personenText}, ${wirtschaftlichBerechtigtText}`;
			} else if (v.einreicher) {
				return 'Finanzinstitut: ' + this.finanzinstitutService.getName(v.einreicher);
			} else {
				return '';
			}
		})
	);

	public readonly eingangZusammenfassung$: Observable<{ label: string; value: string; }[]> =
		this.form.values$.pipe(
			map(form => {
				const values: { label: string; value: string; }[] = [];
				if (form.eingangsdatum) {
					values.push({
						label: 'Eingegangen',
						value: form.eingangsdatum.toLocaleDateString(),
					});
				}

				return values;
			})
		);

	public id: string | null = null;
	private snapshot?: string;

	public takeSnapshot(): void {
		this.snapshot = JSON.stringify(this.form.getRawValue());
	}

	public hasChanged(): boolean {
		return JSON.stringify(this.form.getRawValue()) !== this.snapshot;
	}
	// #endregion Attributes / Properties

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		public readonly service: EinreichungService,
		private readonly geschaeftsfallService: GeschaeftsfallService,
		private readonly router: Router,
		private readonly alert: AlertService,
		private readonly finanzinstitutService: FinanzinstitutService,
		gattungService: GattungService,
		serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private readonly matDialog: MatDialog
	) {
		super();

		this.refService = serviceFactory.getService('kistam');
		this.bankRefService = serviceFactory.getService('bankleitzahl');
		this.form.controls.personen.addValidators(max1Einreicher);
		this.form.controls.neuerfassungZu.disable();

		// #region SUBSCRIBE valueChanges handling

		this.registerSubscription(
			this.form.controls.konto.valueChanges.subscribe(kontoNr => {
				if (!kontoNr) {
					this.form.controls.couponShouldPay.setValue(false);
					this.form.controls.couponShouldPay.disable();
				} else {
					this.form.controls.couponShouldPay.enable();
				}
			})
		);

		this.registerSubscription(
			this.form.controls.istEinreichungPrivat.valueChanges.subscribe(
				istEinreichungPrivat => {
					if (istEinreichungPrivat) {
						this.form.controls.einreicher.setValue(null);
						this.form.controls.einreicher.disable();
					} else {
						this.form.controls.einreicher.enable();
					}
				}
			)
		);

		this.registerSubscription(
			combineLatest([
				this.form.controls.kistam.valueChanges,
				this.form.controls.personen.valueChanges as Observable<Einreichungsperson[]>,
			]).subscribe(([kistam, personen]) => {
				if (!personen) return; // not initialized;

				const wirtschaftlichBerechtigte: Einreichungsperson[] = personen.filter(
					p => p.istWirtschaftlichBerechtigter
				);

				kistam = this.setKistamProzentsatz(kistam, wirtschaftlichBerechtigte);

				switch (kistam) {
					case null:
					case '------':
						this.form.controls.religionszugehoerigkeit.setValue(
							Religionszugehoerigkeit.NichtAngegeben,
							{
								emitEvent: false,
							}
						);
						this.form.controls.religionszugehoerigkeit.disable({ emitEvent: false });
						break;
					case Kistam_EV:
						this.form.controls.religionszugehoerigkeit.setValue(
							Religionszugehoerigkeit.Evangelisch,
							{
								emitEvent: false,
							}
						);
						this.form.controls.religionszugehoerigkeit.disable({ emitEvent: false });
						break;
					case Kistam_RK:
						this.form.controls.religionszugehoerigkeit.setValue(
							Religionszugehoerigkeit.Katholisch,
							{
								emitEvent: false,
							}
						);
						this.form.controls.religionszugehoerigkeit.disable({ emitEvent: false });
						break;
					default:
						this.form.controls.religionszugehoerigkeit.enable({ emitEvent: false });
						break;
				}
			})
		);

		this.registerSubscription(
			this.activatedRoute.paramMap.subscribe(params => {
				const id = params.get('id');
				const neuabrechnung =
					this.activatedRoute.snapshot.queryParamMap.get('neuabrechnung');
				const shouldClone =
					!!neuabrechnung || !!this.activatedRoute.snapshot.queryParamMap.get('clone');
				const shouldSeparateVorabpauschale =
					!!this.activatedRoute.snapshot.queryParamMap.get('separationVorabp');

				if (!id || id === 'new') {
					this.load(null);
				} else {
					this.load(id, shouldClone, shouldSeparateVorabpauschale, neuabrechnung);
				}
			})
		);

		this.registerSubscription(
			this.form.controls.personen.valueChanges.subscribe(
				(personen: Einreichungsperson[]) => {
					const istErbfall =
						personen && personen.filter(p => p.istWirtschaftlichBerechtigter).length > 1;
					if (this.form.controls.erbfall.value !== istErbfall) {
						this.form.controls.erbfall.setValue(istErbfall, {
							emitEvent: false,
							onlySelf: true,
						});
					}

					const wirtschaftlichBerechtigte = personen.filter(
						p => p.istWirtschaftlichBerechtigter
					);
					if (wirtschaftlichBerechtigte.length > 0) {
						const wirtschaftlichBerechtigteText = wirtschaftlichBerechtigte
							.map(p => getPersonName(p, { ohneAnrede: true }))
							.join(', ');
						if (!this.form.controls.kontoInhaber.value) {
							this.form.controls.kontoInhaber.setValue(wirtschaftlichBerechtigteText);
						}
					}

					const wirtschaftlichBerechtigteUndDepotinhaber =
						wirtschaftlichBerechtigte.filter(p => p.dekaDepotVorhanden);
					if (wirtschaftlichBerechtigteUndDepotinhaber.length > 0) {
						const wirtschaftlichBerechtigteUndDepotinhaberText =
							wirtschaftlichBerechtigteUndDepotinhaber
								.map(p => getPersonName(p, { ohneAnrede: true }))
								.join(', ');
						if (!this.form.controls.depotInhaber.value) {
							this.form.controls.depotInhaber.setValue(
								wirtschaftlichBerechtigteUndDepotinhaberText
							);
						}
					}
				}
			)
		);

		this.registerSubscription(
			this.form.controls.bic.valueChanges.subscribe((bic: string) => {
				if (!bic) return;
				const bicUppercase = bic.toLocaleUpperCase();
				if (bic !== bicUppercase) {
					this.form.controls.bic.setValue(bicUppercase);
				}
			})
		);

		this.registerSubscription(
			this.form.controls.konto.valueChanges.subscribe((iban: string) => {
				if (!this.form.controls.konto.pristine) {
					//change to uppercase
					if (iban) {
						const ibanUppercase = iban.toLocaleUpperCase();
						if (iban !== ibanUppercase) {
							this.form.controls.konto.setValue(ibanUppercase);
						}
					}
					//determine bic
					let result = null;
					if (iban && iban.length === 22 && iban.startsWith('DE')) {
						//only german iban
						const blz = iban.slice(4, 12);
						const bankinfo = this.bankRefService.list$.value.find(
							b => b.kuerzel === blz
						);
						if (bankinfo) {
							result = bankinfo.extra;
						}
					}
					this.form.controls.bic.setValue(result);
				}
			})
		);

		this.registerSubscription(
			this.form.controls.positionen.valueChanges.subscribe(
				(positionen: Einreichungsposition[]) => {
					const expectedVorabpauschale = !positionen
						? 0
						: positionen.reduce((pv, cv) => pv + cv.vorabpauschale, 0);

					if (this.form.controls.vorabpauschale.value !== expectedVorabpauschale) {
						this.form.controls.vorabpauschale.setValue(expectedVorabpauschale);
					}
					let expectedVorabpauschaleGesamtsumme = 0;
					positionen
						.filter(p => (p.vorabpauschale ?? 0) > 0)
						.forEach(p => {
							const positionFaktor =
								((p.anzahl ?? 1) * (p.anteil ?? INTERNAL_NUMBER_FACTOR)) /
								INTERNAL_NUMBER_FACTOR;
							const positionsVorabpauschaleInfo =
								gattungService.list$.value.find(g => g.id === p.gattungId)
									?.zuVersteuerndeVorabpauschale || [];
							expectedVorabpauschaleGesamtsumme =
								expectedVorabpauschaleGesamtsumme +
								positionsVorabpauschaleInfo
									.filter(vp => vp.jahr <= new Date().getFullYear())
									.reduce((pv, cv) => pv + cv.betragVorabp, 0) *
								positionFaktor;
						});
					if (
						this.form.controls.vorabpauschaleGesamtsumme.value !==
						expectedVorabpauschaleGesamtsumme
					) {
						this.form.controls.vorabpauschaleGesamtsumme.setValue(
							expectedVorabpauschaleGesamtsumme
						);
					}
				}
			)
		);

		this.registerSubscription(
			combineLatest([
				this.form.controls.kistamProzentsatz.valueChanges,
				this.form.controls.positionen.valueChanges,
			]).subscribe(([kistamProzentsatz, positionen]: [number, Einreichungsposition[]]) => {
				let vorabpauschaleGesamt = 0;
				for (const position of positionen) {
					const vorabpauschaleItems = determineVorabpauschaleStatus(
						gattungService.list$.value.find(g => g.id === position.gattungId),
						kistamProzentsatz,
						((position.anzahl ?? 1) * (position.anteil ?? INTERNAL_NUMBER_FACTOR)) /
						INTERNAL_NUMBER_FACTOR
					);
					position.vorabpauschale = vorabpauschaleItems.reduce(
						(pv, cv) => pv + cv.steuerbetrag,
						0
					);
					vorabpauschaleGesamt += position.vorabpauschale;
				}

				if (vorabpauschaleGesamt !== this.form.controls.vorabpauschale.value) {
					this.form.controls.vorabpauschale.setValue(vorabpauschaleGesamt);
				}
			})
		);
		// #endregion  SUBSCRIBE valueChanges handling
	}

	private setKistamProzentsatz(
		kistam: any,
		wirtschaftlichBerechtigte: Einreichungsperson[]
	): String | null {
		if (kistam && (kistam as string).includes('------')) {
			kistam = null;
		}

		let kistamProzentsatzValue = this.service.computeKistamProzentsatzValue(
			kistam,
			wirtschaftlichBerechtigte
		);
		this.form.controls.kistamProzentsatz.setValue(kistamProzentsatzValue);

		return kistam;
	}

	load(
		id: string | null,
		shouldClone = false,
		shouldSeparateVorabpauschale = false,
		geschaeftsfallId: 'alle' | string | null = null
	): void {
		this.form.disable();
		this.id = id;
		if (!id) {
			this.form.resetTyped({
				eingangsdatum: new Date(),
				posteingangsdatum: new Date(),
				anzahlUmschlaege: 1,
				istEinreichungPrivat: true,
				waehrungStueck: 'Stück',
			});
			this.form.enable();
			this.disableReadonlyFields(this.form.controls);
			return;
		}

		const geschaeftsfaelle$ =
			geschaeftsfallId === 'alle'
				? this.geschaeftsfallService.getByEinreichung$(id)
				: geschaeftsfallId
					? this.geschaeftsfallService.getSingle$(geschaeftsfallId).pipe(map(g => [g]))
					: of(null);

		forkJoin([this.service.loadSingle$(id), geschaeftsfaelle$])
			.pipe(debounceTime(10))
			.subscribe(([f, geschaeftsfaelleZurNeuabrechnung]) => {
				if (geschaeftsfaelleZurNeuabrechnung) {
					const positionenIdsToInclude = geschaeftsfaelleZurNeuabrechnung
						.filter(
							g =>
								g.status === GeschaeftsfallStatus.Storniert ||
								g.status === GeschaeftsfallStatus.StorniertZurBuchung
						)
						.flatMap(g => g.positionen);

					f.positionen = (f.positionen || []).filter(p =>
						positionenIdsToInclude.includes(p.id)
					);
				}

				if (shouldSeparateVorabpauschale) {
					f.positionen = (f.positionen || []).filter(p => p.vorabpauschale > 0);
				}

				this.form.setValueTyped(
					extract(
						{
							...f,
							// istEinreichungPrivat: f.personen?.length > 0 ?? false,
							istEinreichungPrivat: f.einreicher ? false : true,
							neuerfassungZu: geschaeftsfallId ? id : f.neuerfassungZu,
							couponShouldPay: f.couponShouldPay ? true : false,
						},
						defaultFormValues
					)
				);

				if (shouldClone || shouldSeparateVorabpauschale) {
					this.id = null;
					this.form.patchValue({
						eingangsdatum: new Date(),
						posteingangsdatum: new Date(),
						oppositionstreffer: null,
						oppositionFreigeber: null,
						oppositionSystem: null,
						abbruchStornoGrund: null,
						dsgvoAenderungsgrund: null,
						vorabpauschaleEingegangen: false,
					});
				}

				this.pageHeaderValues$.next({ nummer: f.nummer, status: f.status });

				this.form.enable();
				this.form.controls.posteingangsdatum.disable();
				this.disableReadonlyFields(this.form.controls);

				this.takeSnapshot();
			});
	}

	disableReadonlyFields(controls: { [key: string]: AbstractControl; }) {
		controls.erbfall.disable();
		controls.vorabpauschale.disable();
		controls.vorabpauschaleGesamtsumme.disable();
		controls.neuerfassungZu.disable();
		controls.valutatag.disable();
		controls.kistamProzentsatz.disable();
	}

	abbrechen() {
		if (this.id) {
			this.router.navigate([this.metadata.routing.view!.url(this.id)]);
		} else {
			this.router.navigate([this.metadata.routing.list!.url]);
		}
	}

	onChange(e: Event) {
		alert('changed');
	}

	save(shouldClose = false) {
		this.isSaving$.next(true);
		const values = this.form.rawTypedValue;
		const shouldClone = this.activatedRoute.snapshot.queryParamMap.get('clone')
		

		if (values.personen && shouldClone) {
			values.personen.forEach(person => {
				if (person.id && !person.id.startsWith("new")) {
					person.id = 'new-' + Math.random().toString();
				}
			});
		}

		if (values.positionen && shouldClone) {
			values.positionen.forEach(position => {
				if (position.id && !position.id.startsWith("new")) {
					position.id = 'new-1';
				}
			});
		}
		
		const saveModel = makeSaveModel(this.id, values);

		this.service.save$(saveModel).subscribe({
			error: err => {
				this.alert.error('Speichern fehlgeschlagen!', err);
				logger.error(err);
				this.isSaving$.next(false);
				this.form.enable();
			},
			next: data => {
				this.isSaving$.next(false);
				this.alert.success('Einreichung gespeichert');
				this.takeSnapshot();
				if (shouldClose) {
					this.router.navigate([einreichungMetadata.routing.view!.url(data)]);
				} else if (this.id) {
					this.load(this.id);
				} else {
					this.router.navigate([einreichungMetadata.routing.edit!.url(data)]);
				}
			},
		});
	}

	schnellerfassung(): void {
		let schnellerfassungInfo: SchnellerfassungSearchCriteria | null = null;
		try {
			console.log();
			if (this.form.controls.schnellerfassungInfo.value) {
				schnellerfassungInfo = JSON.parse(this.form.controls.schnellerfassungInfo.value);
			}
		} catch { }

		this.matDialog
			.open(EinreichungSchnellerfassungComponent, {
				minWidth: '80vw',
				data: {
					positionen: this.form.controls.positionen.value,
					schnellerfassungInfo,
					kistamProzentsatz: this.form.controls.kistamProzentsatz,
				},
			})
			.afterClosed()
			.subscribe((val: SchnellerfassungResult) => {
				if (val) {
					logger.log("Add new position to einreichung from schnellerfassung: ", val.schnellerfassungInfo);
					const jsonSchnellerfInfo = JSON.stringify(val.schnellerfassungInfo);
					this.form.controls.schnellerfassungInfo.setValue(jsonSchnellerfInfo);

					const oldPositionen = this.form.controls.positionen.value || [];
					const newPositionen = [...oldPositionen, ...val.neuePositionen];
					this.form.controls.positionen.setValue(newPositionen);

				}
			});
	}
}

export type EinreichungEditFormValues = Omit<
	Einreichung,
	'id' | 'dsgvoAenderungsgrund' | 'nummer' | 'status' | 'vorabpauschaleStatus'
> & {
	istEinreichungPrivat: boolean;
};

function makeSaveModel(
	id: string | null,
	values: EinreichungEditFormValues
): EinreichungSaveModel {
	return {
		id: id ? id : undefined,
		abbruchStornoGrund: values.abbruchStornoGrund,
		absender: values.absender,
		anzahlUmschlaege: values.anzahlUmschlaege,
		bemerkung: values.bemerkung,
		betrag: values.betrag,
		dekaDepotVorhanden: values.dekaDepotVorhanden,
		couponShouldPay: values.couponShouldPay,
		depotInhaber: values.depotInhaber,
		depotNummer: values.depotNummer,
		eingangsdatum: formatDateForSaveModel(values.eingangsdatum),
		einreicher: values.einreicher,
		empfaenger: values.empfaenger,
		erbfall: values.erbfall,
		externeReferenz: values.externeReferenz,
		kistam: values.kistam,
		kistamProzentsatz: values.kistamProzentsatz,
		konto: values.konto,
		kontoInhaber: values.kontoInhaber,
		neuerfassungZu: values.neuerfassungZu,
		oppositionstreffer: values.oppositionstreffer,
		personen: (values.personen || []).map(p => ({
			...p,
			nummer:null,
			id: p.id.startsWith('new') ? null : p.id,
			geburtsdatum: formatDateForSaveModel(p.geburtsdatum),
			ausweisAusgestelltAm: formatDateForSaveModel(p.ausweisAusgestelltAm),
			ausweisGueltigBis: formatDateForSaveModel(p.ausweisGueltigBis),
		})),
		positionen: (values.positionen || []).map(p => ({
			...p,
			id: p.id.startsWith('new') ? null : p.id,
			stueckenummer: JSON.stringify(p.stueckenummer),
			gesamtwert: round2(p.gesamtwert), //EFA-2680
		})),
		posteingangsdatum: formatDateForSaveModel(values.posteingangsdatum),
		sendungsnummer: values.sendungsnummer,
		valutatag: formatDateForSaveModel(values.valutatag),
		versandart: values.versandart,
		vorabpauschale: values.vorabpauschale,
		vorabpauschaleGesamtsumme: values.vorabpauschaleGesamtsumme,
		vorabpauschaleEingegangen: values.vorabpauschaleEingegangen,
		waehrungStueck: values.waehrungStueck,
		kassenvereinsnummer: values.kassenvereinsnummer,
		bic: values.bic ? values.bic : null, //BE gets validation error with empty string
		schnellerfassungInfo: values.schnellerfassungInfo,
		dokumentenLink: values.dokumentenLink,
		vorabpauschaleStatus: VorabpauschaleStatus.Unbekannt,
		religionszugehoerigkeit: values.religionszugehoerigkeit,
	};
}

function max1Einreicher(a: AbstractControl): ValidationErrors | null {
	const val = a.value as Einreichungsperson[];
	if (!val) return null;

	const einreicher = val.filter(p => p.istEinreicher);
	if (einreicher.length > 1) {
		return { errorMessage: 'Nur eine Person darf als Einreicher gekennzeichnet werden' };
	}

	return null;
}
