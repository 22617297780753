import { BaseComponent } from 'src/app/general/base-component';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Inject,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as ExcelJS from 'ExcelJS-workaround';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { logger } from 'src/logger';
import { KuerzelBezeichnungReferenzlistService } from '../kuerzel-bezeichnung-referenzlist.service';
import {
	KuerzelBezeichnungReferenz,
	KuerzelBezeichnungReferenzlistMetadata,
	KuerzelBezeichnungUpdateModel,
} from '../model';

@Component({
	selector: 'app-kuerzel-bezeichnung-import',
	templateUrl: './kuerzel-bezeichnung-import.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuerzelBezeichnungImportComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;

	public readonly errorMessages: string[] = [];
	public readonly dataSource = new MatTableDataSource<Comparison>();
	public readonly hasExtra: boolean;
	public readonly kuerzelLabel: string;
	public readonly bezeichnungLabel: string;
	public readonly extraLabel?: string;
	public readonly trackBy: TrackByFunction<Comparison> = (index, item) => item.new.kuerzel;
	public readonly displayedColumns = ['kuerzel', 'status'];
	private comparisonResult: Comparison[] = [];

	public readonly isImporting$ = new BehaviorSubject(false);
	public readonly hasImportedSuccessfully$ = new BehaviorSubject(false);
	public readonly importMessage$ = new BehaviorSubject('');
	private shouldAbort = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private readonly config: KuerzelBezeichnungImportConfig
	) {
		super();
		this.kuerzelLabel = config.metadata.kuerzel.label ?? 'Kürzel';
		this.bezeichnungLabel = config.metadata.bezeichnung.label ?? 'Bezeichnung';
		this.extraLabel = config.metadata.extra?.label;
		this.hasExtra = !!config.metadata.extra?.label;
		if (this.hasExtra) {
			this.displayedColumns = [
				'kuerzel',
				'status',
				'bezeichnung-old',
				'bezeichnung-new',
				'extra-old',
				'extra-new',
			];
		} else {
			this.displayedColumns = ['kuerzel', 'status', 'bezeichnung-old', 'bezeichnung-new'];
		}
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}
		if (this.sort) this.dataSource.sort = this.sort;
		setTimeout(() => this.calculate());
	}

	calculate(): void {
		const originalReferenzlist = this.config.service.list$.value;

		let kuerzelColumn = -1;
		let bezeichnungColumn = -1;
		let extraColumn = -1;

		const kuerzelLabelLowercase = this.kuerzelLabel.toLocaleLowerCase();
		const bezeichnungLabelLowercase = this.bezeichnungLabel.toLocaleLowerCase();
		const extraLabelLowercase = this.extraLabel?.toLocaleLowerCase();
		this.config.worksheet.getRow(1).eachCell((cell, colNumber) => {
			if (typeof cell.value === 'string') {
				const lowercased = cell.value.toLocaleLowerCase();
				if (lowercased === kuerzelLabelLowercase) {
					kuerzelColumn = colNumber;
				} else if (lowercased === bezeichnungLabelLowercase) {
					bezeichnungColumn = colNumber;
				} else if (this.hasExtra && lowercased === extraLabelLowercase) {
					extraColumn = colNumber;
				}
			}
		});

		if (kuerzelColumn < 0)
			this.errorMessages.push(`Erwartete Spalte ${this.kuerzelLabel} nicht gefunden!`);
		if (bezeichnungColumn < 0)
			this.errorMessages.push(`Erwartete Spalte ${this.bezeichnungLabel} nicht gefunden!`);
		if (this.hasExtra && extraColumn < 0)
			this.errorMessages.push(`Erwartete Spalte ${this.extraLabel} nicht gefunden!`);
		if (this.errorMessages.length > 0) return;

		const comparisonResult: Comparison[] = [];
		this.config.worksheet.eachRow((row, index) => {
			if (index === 1) return;

			const item: KuerzelBezeichnungUpdateModel = {
				kuerzel: (
					row.getCell(kuerzelColumn)?.text?.toLocaleString() ?? ''
				).toLocaleUpperCase(),
				bezeichnung: row.getCell(bezeichnungColumn)?.value?.toLocaleString() ?? '',
				extra: this.hasExtra
					? row.getCell(extraColumn)?.value?.toLocaleString() ?? ''
					: undefined,
			};

			if (item.kuerzel) {
				const oldItem = originalReferenzlist.find(i => i.kuerzel === item.kuerzel);
				const comparisonResultItem: Comparison = {
					new: item,
					old: oldItem,
					status: 'Unverändert',
				};
				if (this.hasExtra) {
					if (oldItem && !oldItem.extra) oldItem.extra = '';
					if (!item.extra) item.extra = '';
				}
				comparisonResult.push(comparisonResultItem);

				if (!oldItem) {
					comparisonResultItem.status = 'Neu';
				} else if (oldItem.bezeichnung !== item.bezeichnung) {
					comparisonResultItem.status = 'Geändert';
				} else if (this.hasExtra && oldItem.extra !== item.extra) {
					comparisonResultItem.status = 'Geändert';
				} else if (oldItem.status === 'Inaktiv') {
					comparisonResultItem.status = 'Reaktivieren';
				}
			}
		});

		this.comparisonResult = comparisonResult;
		this.dataSource.data = this.comparisonResult;
	}

	async start(): Promise<void> {
		if (this.comparisonResult.length === 0) return;
		this.shouldAbort = false;
		this.isImporting$.next(true);

		const anzahlZuImportieren = this.comparisonResult.filter(
			c => c.status !== 'Unverändert'
		).length;
		let importeIndex = 0;

		for (const comparison of this.comparisonResult) {
			console.warn(comparison);
			if (this.shouldAbort) {
				this.isImporting$.next(false);
				this.importMessage$.next(`Importe abgebrochen!`);
			}

			importeIndex++;
			this.importMessage$.next(`Importiere ${importeIndex} von ${anzahlZuImportieren}...`);

			try {
				switch (comparison.status) {
					case 'Neu':
					case 'Geändert':
						await lastValueFrom(this.config.service.save$(comparison.new));
						break;
					case 'Reaktivieren':
						await lastValueFrom(this.config.service.setStatus$(comparison.new, 'Aktiv'));
						break;
				}
			} catch (err) {
				logger.error(err);
				this.importMessage$.next(`Importe von ${this.comparisonResult} fehlgeschlagen!`);
				this.isImporting$.next(false);
				return;
			}

			this.importMessage$.next(`Importe abgeschlossen!`);
			this.isImporting$.next(false);
			this.hasImportedSuccessfully$.next(true);
		}
	}

	stop(): void {
		this.shouldAbort = true;
	}
}

export interface KuerzelBezeichnungImportConfig {
	service: KuerzelBezeichnungReferenzlistService;
	metadata: KuerzelBezeichnungReferenzlistMetadata;
	worksheet: ExcelJS.Worksheet;
}

export interface Comparison {
	status: 'Neu' | 'Geändert' | 'Unverändert' | 'Reaktivieren';
	old?: KuerzelBezeichnungReferenz;
	new: KuerzelBezeichnungUpdateModel;
}
