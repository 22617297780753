import { Component, ChangeDetectionStrategy, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { queryBackend } from 'src/app/utils';
import { EinreichungService } from '../einreichung.service';
import { Einreichung, StatusHistorieEintrag } from '../model';
import * as ExcelJS from 'ExcelJS-workaround';
import { SaveService } from 'src/app/shared/services/save.service';

@Component({
	selector: 'app-einreichung-status-historie',
	templateUrl: './einreichung-status-historie.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungStatusHistorieComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatSort) sort?: MatSort;

	@Input() set einreichung(einreichung: Einreichung) {
		if (einreichung) {
			this.einreichungId$.next(einreichung.id);
		}
	}

	private readonly einreichungId$ = new Subject<string>();

	public readonly isLoading$ = new BehaviorSubject(false);
	public readonly dataFound$ = new BehaviorSubject(false);

	public readonly dataSource = new MatTableDataSource<StatusHistorieEintrag>();
	public readonly displayedColumns = [
		'einreichungsnummer',
		'geschaeftsfallnummer',
		'statusVorher',
		'statusNachher',
		'geaendertAm',
		'geaendertVon',
	];

	constructor(
		public readonly service: EinreichungService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {
		super();

		this.registerSubscription(
			this.einreichungId$
				.pipe(
					debounceTime(200),
					switchMap(id => this.service.loadStatusHistorie$(id))
				)
				.subscribe({
					next: data => {
						this.dataSource.data = data;
						this.isLoading$.next(false);
						this.dataFound$.next(this.dataSource.data.length > 0);
					},
					error: err => {
						this.dataSource.data = [];
						this.alert.error('Laden Historie fehlgeschlagen!', err);
						this.isLoading$.next(false);
					},
				})
		);
	}

	ngAfterViewInit(): void {
		// console.warn({ sort: this.sort });
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	downloadAsExcel(data: StatusHistorieEintrag[]): void {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Übersicht Tagesverarbeitung');
		worksheet.addRow([
			'Einreichungsnummer',
			'Geschäftsfallnummer',
			'Status Vorher',
			'Status Nachher',
			'Geändert Am',
			'Geändert Von',
		]);
		for (const element of data) {
			worksheet.addRow([
				element.einreichungsnummer,
				element.geschaeftsfallnummer,
				element.statusVorher,
				element.statusNachher,
				element.geaendertVon,
				new Date(element.geaendertAm).toLocaleString('de-DE'),
			]);
		}
		//
		const einreichungsnummer = data.length > 0 ? data[0].einreichungsnummer : '';
		workbook.xlsx.writeBuffer().then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			const now: string = new Date().toISOString().slice(0, 10);
			this.save.saveAs(blob, 'einreichung_' + einreichungsnummer + '_historie_' + now + '.xlsx');
		});
	}
}
