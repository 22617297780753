import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Gattung, gattungMetadata } from '../model';

@Component({
	selector: 'app-gattung-details-modal',
	templateUrl: './gattung-details-modal.component.html',
	styleUrls: ['./gattung-details-modal.component.scss'],
})
export class GattungDetailsModalComponent {
	public readonly metadata = gattungMetadata;
	constructor(@Inject(MAT_DIALOG_DATA) public readonly gattung: Gattung) {}
}
