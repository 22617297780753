<h1 mat-dialog-title>Korrektur Personendaten</h1>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="accept()">
	<mat-accordion multi>
		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Person</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<app-text-input [metadata]="metadata.fields.anrede" formControlName="anrede"></app-text-input>
					<app-text-input [metadata]="metadata.fields.vorname" formControlName="vorname"></app-text-input>
					<app-text-input [metadata]="metadata.fields.nachname" formControlName="nachname"></app-text-input>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Adresse</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<app-postleitzahl-input
						formControlName="postleitzahl"
						[metadata]="metadata.fields.postleitzahl"></app-postleitzahl-input>

					<app-text-input formControlName="ort" [metadata]="metadata.fields.ort"></app-text-input>

					<app-text-input formControlName="adresse" [metadata]="metadata.fields.adresse"></app-text-input>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Amtliche Identifizierung</mat-expansion-panel-header>
			<div class="flex-column">
				<div class="flex-row">
					<app-date-input
						[metadata]="metadata.fields.geburtsdatum"
						formControlName="geburtsdatum"></app-date-input>

					<app-text-input
						formControlName="ausweisnummer"
						[metadata]="metadata.fields.ausweisnummer"></app-text-input>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</form>

<div mat-dialog-actions style="display: flex">
	<button mat-raised-button color="accent" (click)="tryCancel()">
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" (click)="accept()" [disabled]="cannotAccept$ | async">
		<mat-icon>check</mat-icon>
		Akzeptieren
	</button>
</div>
