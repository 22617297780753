<mat-card>
	<mat-card-content class="einreichung-status-bar">
		<ng-container *ngFor="let item of barItems">
			<div
				*ngIf="
					item.label !== 'Neu/Eingegangen' &&
					item.label !== 'Neuerfassung (nur Dokumentation)'
				">
				<hr />
			</div>
			<div [class.is-active]="item.isActive">{{ item.label }}</div>
		</ng-container>
	</mat-card-content>
</mat-card>
