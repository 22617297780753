import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	Output,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { StammdatenStatus } from '../../model';
import { FinanzinstitutService } from '../finanzinstitut.service';
import { Finanzinstitut, FinanzinstitutRolle, finanzinstitutMetadata } from '../model';

@Component({
	selector: 'app-finanzinstitut-list',
	templateUrl: './finanzinstitut-list.component.html',
})
export class FinanzinstitutListComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;
	
	//Choose for Filtering displayed values 
	@Input() finanzinstitutRolle?:FinanzinstitutRolle;

	@Output() selected$ = new EventEmitter<Finanzinstitut>();

	public readonly stammdatenStatus = StammdatenStatus;
	public readonly metadata = finanzinstitutMetadata;
	public readonly dataSource = new MatTableDataSource<FinanzinstitutListEntry>();
	public readonly displayedColumns = [
		'finanzinstitutsnummer',
		'finanzinstitutsname',
		'status',
		'istEinreicherOderEmittent',
		'geaendertVon',
		'freigegebenVon',
		'freigegebenAm',
		'actions',
	];
	public readonly trackBy: TrackByFunction<FinanzinstitutListEntry> = (index, item) =>
		item.id;
	public readonly filterForm = new FormGroup({
		finanzinstitutsnummer: new FormControl(''),
		finanzinstitutsname: new FormControl(''),
		status: new FormControl(''),
		istEinreicherOderEmittent: new FormControl(''),
	});

	constructor(public readonly service: FinanzinstitutService) {
		super();

		// #region Filter logic
		const filterFormValues$ = this.filterForm.valueChanges.pipe(
			debounceTime(200),
			map(v => ({
				...v,
				finanzinstitutsname: v.finanzinstitutsname?.toLocaleUpperCase() ?? '',
			}))
		);

		let filterIndex = 0;
		this.registerSubscription(
			filterFormValues$.subscribe(filterValues => {
				this.dataSource.filterPredicate = data => {
					if (filterValues.finanzinstitutsnummer) {
						if (
							!data.finanzinstitutsnummer?.includes(filterValues.finanzinstitutsnummer)
						)
							return false;
					}
					if (filterValues.finanzinstitutsname) {
						if (!data.nameUpperCase?.includes(filterValues.finanzinstitutsname))
							return false;
					}
					if (filterValues.status) {
						if (!data.status?.includes(filterValues.status)) return false;
					}
					if (filterValues.istEinreicherOderEmittent) {
						if (
							filterValues.istEinreicherOderEmittent
								.toLocaleUpperCase()
								.includes('EI') &&
							!data.istEinreicher
						)
							return false;
						if (
							filterValues.istEinreicherOderEmittent
								.toLocaleUpperCase()
								.includes('EM') &&
							!data.istEmittent
						)
							return false;
					}

					return true;
				};
				filterIndex++;
				this.dataSource.filter = filterIndex.toFixed();
			})
		);
		// #endregion  Filter logic
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}

		if (this.sort) {
			this.dataSource.sort = this.sort;
		}

		setTimeout(() =>
			this.registerSubscription(
				this.service.list$.subscribe(institute => {
					this.dataSource.data = institute.map(g => ({
						...g,
						nameUpperCase: g.finanzinstitutsname?.toLocaleUpperCase() ?? '',
						nummerMitPadding: (g.finanzinstitutsnummer || '').padStart(12, '0'),
					}));

					//filter for role of Finanzinstitut ("emittent", "einreicher" or both them)
					switch (this.finanzinstitutRolle) {
						case FinanzinstitutRolle.Einreicher:
							this.dataSource.data = this.dataSource.data.filter(e => e.istEinreicher);
							break;
						case FinanzinstitutRolle.Emittent:
							this.dataSource.data = this.dataSource.data.filter(e => e.istEmittent);
							break;
						default:
							//don´t filter if both einreicher and emittent
							break;
					}
				})
			)
		);
	}

	open(finanzinstitut: FinanzinstitutListEntry): void {
		this.selected$.emit(finanzinstitut);
	}
}

interface FinanzinstitutListEntry extends Finanzinstitut {
	nameUpperCase: string;
	nummerMitPadding: string;
}
