import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Finanzinstitut, finanzinstitutMetadata } from '../model';

@Component({
	selector: 'app-finanzinstitut-details-modal',
	templateUrl: './finanzinstitut-details-modal.component.html',
})
export class FinanzinstitutDetailsModalComponent {
	public readonly metadata = finanzinstitutMetadata;
	constructor(@Inject(MAT_DIALOG_DATA) public readonly finanzinstitut: Finanzinstitut) {}
}
