import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit, TrackByFunction } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, map, take } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { formatDateForSaveModel, parseIncomingAsDate } from 'src/app/utils';
import { einreichungMetadata, EinreichungStatus } from '../../model';
import {
	Einreichungssucheeintrag,
	Einreichungssuchekriterien,
	EinreichungSucheService,
} from '../einreichung-suche.service';
import * as ExcelJS from 'ExcelJS-workaround';
import saveAs from 'file-saver';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { EinreichungService } from '../../einreichung.service';
import { KonfigurationService } from 'src/app/general/konfiguration/konfiguration.service';

const PAGE_SIZE = 20;
interface FilterForm {
	nummer: number;
	einreicher: string;
	einreicherart: string | null;
	eingangsdatumAb: Date | null;
	eingangsdatumBis: Date | null;
	freigegebenDatumAb: Date | null;
	freigegebenDatumBis: Date | null;
	absender: string;
	status: string;
}

@Component({
	selector: 'app-einreichung-archiv',
	templateUrl: './einreichung-archiv.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungArchivComponent extends BaseComponent implements OnInit, AfterViewInit {
	@ViewChild(MatSort) sort?: MatSort;

	public readonly isSearching$ = new BehaviorSubject(false);
	public readonly metadata = einreichungMetadata;

	public readonly dataSource = new MatTableDataSource<Einreichungssucheeintrag>();
	public readonly trackBy: TrackByFunction<Einreichungssucheeintrag> = (index, item) => item.id;
	public readonly currentPage$ = new BehaviorSubject(1);
	private readonly cache: { [page: string]: Einreichungssucheeintrag[] } = {};
	public readonly hasFurtherEntries$ = new BehaviorSubject(false);
	public readonly displayedColumns = [
		'nummer',
		'einreicher',
		'einreicherart',
		'eingangsdatum',
		'freigabedatum',
		'absender',
		'status',
		'actions',
	];
	public readonly einreichungStatus = EinreichungStatus;
	public readonly filterForm = new TypedForm<FilterForm>({
		nummer: new UntypedFormControl(),
		einreicher: new UntypedFormControl(),
		einreicherart: new UntypedFormControl(),
		eingangsdatumAb: new UntypedFormControl(),
		eingangsdatumBis: new UntypedFormControl(),
		freigegebenDatumAb: new UntypedFormControl(),
		freigegebenDatumBis: new UntypedFormControl(),
		absender: new UntypedFormControl(),
		status: new UntypedFormControl(),
	});

	public readonly canGoNextPage$ = this.currentPage$.pipe(
		map(currentPage => {
			if (this.cache[currentPage + 1]) return true;
			return this.hasFurtherEntries$.value;
		})
	);

	constructor(
		private readonly einreichungsService: EinreichungService,
		private readonly service: EinreichungSucheService,
		private readonly alert: AlertService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly quickInfo: QuickInfoPopupService,
		private readonly konfigurationService: KonfigurationService
	) {
		super();
	}

	ngOnInit(): void {
		var nummer = this.activatedRoute.snapshot.queryParamMap.get('nummer');
		if (nummer) {
			this.filterForm.patchValue({ nummer });
			this.search();
		}
	}

	public lastCriteria: Einreichungssuchekriterien = { pageSize: PAGE_SIZE, page: 1 };

	ngAfterViewInit(): void {
		if (this.sort) {
			this.registerSubscription(this.sort.sortChange.subscribe(() => this.search(false)));
		}
	}

	search(nextPage = false): void {
		let criteria: Einreichungssuchekriterien;
		if (!nextPage) {
			Object.keys(this.cache).forEach(k => delete this.cache[k]);
			const values = this.filterForm.typedValue;
			criteria = {
				page: 1,
				pageSize: PAGE_SIZE,
				absender: values.absender,
				eingangsdatumBis: formatDateForSaveModel(values.eingangsdatumBis),
				eingangsdatumAb: formatDateForSaveModel(values.eingangsdatumAb),
				freigegebendatumAb: formatDateForSaveModel(values.freigegebenDatumAb),
				freigegebendatumBis: formatDateForSaveModel(values.freigegebenDatumBis),
				einreicher: values.einreicher,
				nummer: values.nummer ? +values.nummer : null,
				status: values.status,
				// wirtschaftlichBerechtigter: null,
				einreicherart: (values.einreicherart as any) ? (values.einreicherart as any) : null,
				orderBy: (this.sort?.active as any) ? (this.sort?.active as any) : null,
				orderDirection: (this.sort?.direction as any) ? (this.sort?.direction as any) : null,
			};
		} else {
			criteria = { ...this.lastCriteria, page: this.currentPage$.value + 1 };
		}

		this.isSearching$.next(true);
		this.service.search$(criteria).subscribe({
			next: data => {
				this.lastCriteria = criteria;
				if (data && data.length > 0) {
					this.cache[criteria.page.toFixed()] = data;
					this.dataSource.data = data;
					this.hasFurtherEntries$.next(data.length === PAGE_SIZE);
					this.currentPage$.next(criteria.page);
					this.lastCriteria = criteria;
				} else {
					this.dataSource.data = [];
					this.hasFurtherEntries$.next(false);
					this.currentPage$.next(this.currentPage$.value);
				}

				this.isSearching$.next(false);
			},
			error: err => {
				this.alert.error('Fehlgeschlagen!', err);
				this.isSearching$.next(false);
			},
		});
	}

	open(e: Einreichungssucheeintrag): void {
		console.log(e);
		this.einreichungsService
			.loadSingle$(e.id)
			.pipe(take(1))
			.subscribe(er => this.quickInfo.showEinreichung(er));
	}

	prevPage(): void {
		if (this.isSearching$.value) return;
		if (this.currentPage$.value <= 1) return;

		const newPage = this.currentPage$.value - 1;

		this.dataSource.data = this.cache[newPage.toFixed()];
		this.currentPage$.next(newPage);
	}

	nextPage(): void {
		if (this.isSearching$.value) return;
		const newPage = this.currentPage$.value + 1;
		const pageFromCache = this.cache[newPage.toFixed()];
		if (pageFromCache) {
			this.dataSource.data = this.cache[newPage.toFixed()];
			this.currentPage$.next(newPage);
		} else {
			this.search(true);
		}
	}

	downloadAllAsExcel(): void {
		this.isSearching$.next(true);
		this.service.searchAll$(this.lastCriteria).subscribe({
			next: data => {
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.metadata.label);
				worksheet.addRow([
					this.metadata.fields.nummer.label,
					'Einreicher',
					'Einreicherart',
					this.metadata.fields.eingangsdatum.label,
					this.metadata.fields.freigegebenAm.label,
					this.metadata.fields.absender.label,
					this.metadata.fields.status.label,
					'Dokumentreferenz',
				]);
				for (const eintrag of data) {
					worksheet.addRow([
						eintrag.nummer,
						eintrag.einreicher,
						eintrag.einreicherart?.replace('ae', 'ä'),
						parseIncomingAsDate(eintrag.eingangsdatum)?.toLocaleDateString('de-DE'),
						parseIncomingAsDate(eintrag.freigabedatum)?.toLocaleDateString('de-DE'),
						eintrag.absender,
						eintrag.status,
						`${this.konfigurationService.konfiguration$.value.dokumentenVerzeichnis}/${eintrag.nummer}`,
					]);
				}
				workbook.xlsx.writeBuffer().then(arrayBuffer => {
					const blob = new Blob([arrayBuffer]);
					saveAs(blob, this.metadata.apiCollectionName + 'All' + '.xlsx');
				});
				this.isSearching$.next(false);
			},
			error: err => {
				this.alert.error('Download Fehlgeschlagen!', err);
				this.isSearching$.next(false);
			},
		});
	}
}
