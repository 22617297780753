import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	TrackByFunction,
	ChangeDetectorRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { switchMap, EMPTY, catchError, BehaviorSubject, combineLatest } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { BenutzerRolle } from 'src/app/general/roles';
import { AlertService } from 'src/app/shared/alert.service';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { FeiertagskalenderPopupComponent } from '../feiertagskalender-popup/feiertagskalender-popup.component';
import { FeiertagskalenderService } from '../feiertagskalender.service';
import { Feiertag, feiertagskalenderMetadata } from '../model';

@Component({
	selector: 'app-feiertagskalender-list',
	templateUrl: './feiertagskalender-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeiertagskalenderListComponent extends BaseComponent implements OnInit {
	public readonly metadata = feiertagskalenderMetadata;
	public readonly dataSource = new MatTableDataSource<Feiertag>();
	public readonly displayedColumns = [
		'name',
		'erstesAuftreten',
		'istWiederkehrend',
		'actions',
	];
	public readonly trackBy: TrackByFunction<Feiertag> = (index, item) => item.id;
	readonly currentYear$ = new BehaviorSubject<number>(new Date().getFullYear());

	constructor(
		public readonly service: FeiertagskalenderService,
		private readonly matDialog: MatDialog,
		private readonly alert: AlertService,
		private readonly simpleDialog: SimpleDialogService,
		private readonly userService: CurrentUserService,
		public changeDetector: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit() {
		this.registerSubscription(
			combineLatest([this.service.list$, this.currentYear$]).subscribe(([list, year]) => {
				this.dataSource.data = (list || [])
					.filter(
						f =>
							(!f.istWiederkehrend && f.erstesAuftreten.getFullYear() === year) ||
							(f.istWiederkehrend && f.erstesAuftreten.getFullYear() <= year)
					)
					.sort((f1, f2) => f1.erstesAuftreten.valueOf() - f2.erstesAuftreten.valueOf());
				this.changeDetector.detectChanges(); //to do
			})
		);
	}

	openPopup(item?: Feiertag): void {
		if (this.metadata && this.userService.hasAnyOfRoles(BenutzerRolle.Erweitert)) {
			this.matDialog.open(FeiertagskalenderPopupComponent, {
				data: { metadata: this.metadata, item },
			});
		}
	}

	reload(): void {
		if (!this.metadata || !this.service) return;
		this.service.loadAll$().subscribe({
			next: () => this.alert.success(`${this.metadata.label} erfolgreich durchgeladen`),
			error: err => this.alert.error('Durchladen fehlgeschlagen', err),
		});
	}

	remove(entry: Feiertag): void {
		this.simpleDialog
			.jaNein(`Wollen Sie wirklich den Eintrag ${entry.name} löschen?`, 'Bitte bestätigen')
			.pipe(
				switchMap(jaNein => {
					if (jaNein === 'Nein') return EMPTY;
					return this.service.delete$(entry.id);
				}),
				catchError((err: Error) => {
					this.alert.error('Löschen fehlgeschlagen!', err);
					return EMPTY;
				}),
				switchMap(() => this.service.loadAll$()),
				catchError((err: Error) => {
					this.alert.error('Liste neu laden fehlgeschlagen!', err);
					return EMPTY;
				})
			)
			.subscribe(() => this.alert.success('Gelöscht'));
	}

	goPreviousYear(): void {
		this.currentYear$.next(this.currentYear$.value - 1);
	}

	goNextYear(): void {
		this.currentYear$.next(this.currentYear$.value + 1);
	}
}
