import { forwardRef } from '@angular/core';
import { EntityMetadata, RequiredLevel } from 'src/app/model';
import { UnsavedChangesGuard } from 'src/app/shared/route-guards/unsaved-changes-guard';
import { convertCurrency } from 'src/app/utils';
import { StammdatenStatus } from '../model';
import { GattungEditPageComponent } from './gattung-edit-page/gattung-edit-page.component';
import { GattungListPageComponent } from './gattung-list-page/gattung-list-page.component';
import { GattungViewPageComponent } from './gattung-view-page/gattung-view-page.component';

export const WKZ = {
	Mantel: true,
	Kupon: false,
};

export interface ZuVersteuerndeVorabpauschale {
	jahr: number;
	betragVorabp: number;
	betragStpflAnteil: number;
}

export interface Gattung {
	id: string;
	emittentId: string | null;
	status: StammdatenStatus;
	/** false: Kupon; true: Mantel */
	wkz: boolean;
	wkn: string;
	isin: string;
	kuponnummerFaelligkeit: string | null;
	gattungsbezeichnung: string;
	zahlbarkeitstag: Date | null; //erst bei freigabe pflicht
	land: string | null;
	bundesland: string | null;
	nominalwaehrung: string | null;
	// nominalwert: number | null;
	bruttowert: number | null;
	zinswert: number | null;
	steuerpflichtigerAnteil: number | null;
	dividendenwert: number | null;
	istThesaurierenderFonds: boolean;
	akkumulierteErtraege: number | null;
	zwischengewinn: number | null;
	kestVonEmittentenAbgefuehrt: boolean;
	istInkasso: boolean; // Inkassopflicht
	investmentsteuerart: string | null;
	istSteuerfreieGattung: boolean;
	dotationskonto: string | null;
	geaendertVon: string;
	geaendertAm: Date;
	freigegebenVon: string | null;
	freigegebenAm: Date | null;
	fusioniertMit: string | null;
	fusioniertAb: Date | null;
	fusionUmrechnungsfaktor: number | null;
	umstellungAusgeschuettetAb: Date | null;
	stueckelungEinzelwert: number | null;
	anzahlAnfang: number | null;
	istLiquidiert: boolean;

	// berechnete Werte
	bruttowertEuro: number | null;
	nettoKapst: number | null;
	nettoZast: number | null;
	basisertrag: number | null;
	ausschuettungsdatum: string[] | null;
	zuVersteuerndeVorabpauschale: ZuVersteuerndeVorabpauschale[];
	displayName: string; // kommt nicht vom Backend
	displayNameLower: string; // kommt nicht vom Backend
	wertAnfang: number | null;
}

export function berechneBerechneteGattungsFelder(g: Gattung): void {
	const differingWkn = g.isin && g.wkn && !g.isin.includes(g.wkn) ? ` [WKN ${g.wkn}]` : '';

	if (g.wkz === WKZ.Kupon && g.kuponnummerFaelligkeit) {
		g.displayName = `${g.isin}${differingWkn} [Kupon ${g.kuponnummerFaelligkeit}] ${g.gattungsbezeichnung}`;
	} else {
		g.displayName = `${g.isin}${differingWkn} ${g.gattungsbezeichnung}`;
	}

	g.displayNameLower = g.displayName.toLocaleLowerCase();

	g.bruttowertEuro = convertCurrency(g.bruttowert, g.nominalwaehrung);
	if (g.istThesaurierenderFonds) {
		const ertragPlusGewinn = (g.akkumulierteErtraege ?? 0) + (g.zwischengewinn ?? 0);
		g.steuerpflichtigerAnteil = Math.max(ertragPlusGewinn, (g.bruttowertEuro ?? 0) * 0.3);
	} else {
		g.akkumulierteErtraege = null;
		g.zwischengewinn = null;
	}
	g.wertAnfang = (g.stueckelungEinzelwert ?? 0) * (g.anzahlAnfang ?? 0);
}

export const MAX_SINNVOLLER_BETRAG = 100 * 1000 * 1000;

export const gattungMetadata: EntityMetadata<Gattung> = {
	label: 'Gattung',
	labelGender: 'w',
	apiCollectionName: 'gattung',
	plural: 'Gattungen',
	routing: {
		list: { url: '/gattungen', component: forwardRef(() => GattungListPageComponent) },
		edit: {
			url: id => `/gattungen/${id}/edit`,
			component: forwardRef(() => GattungEditPageComponent),
			canDeactivate: [UnsavedChangesGuard],
		},
		view: { url: id => `/gattungen/${id}`, component: forwardRef(() => GattungViewPageComponent) },
	},
	fields: {
		emittentId: {
			typ: 'reference',
			object: 'finanzinstitut',
			label: 'Emittent',
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Emittent (Finanzinstitut) ',
		},
		isin: {
			typ: 'text',
			label: 'ISIN',
			exactLength: 12,
			requiredLevel: RequiredLevel.RequiredToSave,
			description: '12-stelliger Code für International Securities Identification Number (ISIN) ',
		},
		wkn: {
			typ: 'text',
			label: 'WKN',
			exactLength: 6,
			requiredLevel: RequiredLevel.RequiredToSave,
			description: '6-stelliger Code für Wertpapierkennummer (WKN)',
		},
		gattungsbezeichnung: {
			typ: 'text',
			label: 'Name',
			maxLength: 200,
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Name der Gattung',
		},
		kuponnummerFaelligkeit: {
			typ: 'text',
			label: 'Kuponnummer / Fälligkeit',
			description: 'Fortlaufende Kuponnummern pro Wertpapier / Fälligkeit für Sonderausschüttungen',
			maxLength: 20,
		},
		nominalwaehrung: {
			label: 'Währungsschlüssel Nominal',
			typ: 'referenzlist',
			requiredLevel: RequiredLevel.RequiredForFreigabe,
			description: 'Währung, in der die Gattung ausgegeben wurde',
			referenzlist: 'waehrung',
		},
		bruttowert: {
			label: 'Bruttowert',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			description: 'Ausschüttungswert',
			requiredLevel: RequiredLevel.RequiredForFreigabe,
			isEfaNumber: true,
		},
		bruttowertEuro: {
			label: 'Bruttowert EURO',
			typ: 'number',
			description: 'Bruttowert in EURO',
			decimals: 6,
			isEfaNumber: true,
		},
		ausschuettungsdatum: {
			label: 'Ausschüttungsdatum',
			typ: 'ausschuettungsdatum',
			description: 'Information, wann Ausschüttung der Gattung im Jahr anliegt',
		},
		bundesland: { label: 'Bundesland (Emittent)', referenzlist: 'bundesland', typ: 'referenzlist' },
		land: {
			label: 'Land',
			referenzlist: 'land',
			requiredLevel: RequiredLevel.RequiredForFreigabe,
			typ: 'referenzlist',
		},
		zahlbarkeitstag: {
			label: 'Zahlbarkeitstag',
			typ: 'datum',
			description: 'Tag, an welchem ausgezahlt wird',
			requiredLevel: RequiredLevel.RequiredForFreigabe,
		},
		dividendenwert: {
			label: 'Dividendenwert ',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Alter Dividendenwert für die Berechnung von Emittentensteuern',
		},
		zinswert: {
			label: 'Zinswert',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Alter Zinswert für die Berechnung von Emittentensteuern',
		},
		steuerpflichtigerAnteil: {
			label: 'Steuerpflichtiger Anteil',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Steuerpflichtiger Anteil',
		},
		dotationskonto: {
			label: 'Dotationskonto',
			description: 'Dotationskonto (Sonderfall) IBAN ',
			typ: 'iban',
		},
		istThesaurierenderFonds: { label: 'Ist thesauriererender Fonds', typ: 'boolean' },
		freigegebenAm: { label: 'Freigegeben am', typ: 'backend-datum' },
		freigegebenVon: { label: 'Freigegeben von', typ: 'backend-text' },
		geaendertAm: { label: 'Geändert am', typ: 'backend-datum' },
		geaendertVon: { label: 'Geändert von', typ: 'backend-text' },
		fusionUmrechnungsfaktor: {
			label: 'Fusion-Umrechnungsfaktor',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Bei der Berechnung der Anteile zu berücksichtigen (Anteile werden abgerundet)',
		},
		fusioniertMit: {
			label: 'Fusioniert mit (WKN)',
			exactLength: 6,
			description: 'WKN der Fusionierten Gattung',
			typ: 'text',
		},
		fusioniertAb: {
			label: 'Fusioniert ab',
			typ: 'datum',
			description: 'Datumsangabe ab wann eine Fusion berücksichtigt werden muss',
		},
		umstellungAusgeschuettetAb: {
			label: 'Umstellung auf ausgeschüttet ab',
			typ: 'number',
			isEfaNumber: true,
			description: 'Information für Bearbeiter, dass ggf. nicht eingereichte Kupons abgerechnet werden',
		},
		istInkasso: {
			label: 'Inkassopflicht',
			typ: 'boolean',
			description: 'Diese Gattung soll normalerweise zum Inkasso',
		},
		investmentsteuerart: {
			label: 'Investmentsteuerart',
			typ: 'referenzlist',
			description: 'Auswahl Investmentsteuerart - Nur eine Information, wird nicht zur Berechnung verwendet',
			referenzlist: 'investmentsteuerart',
		},
		akkumulierteErtraege: {
			label: 'Akkumulierte Erträge',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: '[Thes. Fonds] Aufgerechnete Erträge (in Originalwährung)',
		},
		zwischengewinn: {
			label: 'Zwischengewinn',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: '[Thes. Fonds] Zwischengewinn (in Originalwährung)',
		},
		kestVonEmittentenAbgefuehrt: {
			label: 'KESt. vom Emittenten abgeführt',
			typ: 'boolean',
			description:
				'KESt. wird im Rahmen der Steuerberechnung / Abrechnung als noch abzuführen bzw. als bereits abgeführt aufgelistet',
		},
		status: { label: 'Status', typ: 'backend-text' },
		istSteuerfreieGattung: { label: 'Steuerfreie Gattung', typ: 'boolean' },
		wkz: {
			label: 'WKZ',
			typ: 'text',
			exactLength: 6,
			requiredLevel: RequiredLevel.RequiredToSave,
			description: 'Wertpapierkennzeichen WKZ ',
		},
		nettoKapst: {
			label: 'Netto abzgl. KAPSt',
			typ: 'number',
			isEfaNumber: true,
			description: 'Ausschüttungswert - KAPSt (in Originalwährung)',
		},
		nettoZast: {
			label: 'Netto abzgl. ZASt',
			typ: 'number',
			isEfaNumber: true,
			description: 'Ausschüttungswert - ZASt (in Originalwährung)',
		},
		zuVersteuerndeVorabpauschale: {
			label: 'Zu versteuernde Vorabpauschale',
			typ: 'zuVersteuerndeVorabpauschale',
			description: 'TODO',
		},
		basisertrag: {
			label: 'Basisertrag',
			typ: 'number',
			isEfaNumber: true,
			description:
				'Basisertrag = 70% x (1% pro Jahr seit 01.01.2018) x Rücknamepreis der Fondsanteile zum Vorjahr) ',
		},
		displayName: { label: 'Gattung', typ: 'backend-text' },
		displayNameLower: { label: '', typ: 'backend-text' },
		stueckelungEinzelwert: {
			label: 'Stückelung / Einzelwert ',
			typ: 'number',
			minValue: 0,
			maxValue: MAX_SINNVOLLER_BETRAG,
			decimals: 6,
			isEfaNumber: true,
			description: 'Einzelwert der Gattung ',
		},
		anzahlAnfang: {
			label: 'Anzahl Anfang',
			typ: 'number',
			minValue: 0,
			decimals: 0,
			isEfaNumber: false,
			description: 'Anfangsanzahl der Dotationen',
		},
		wertAnfang: {
			label: '(Netto) Wert Anfang',
			typ: 'number',
			description: 'Errechnet aus Stückelung * Anzahl Anfang',
			decimals: 6,
			isEfaNumber: true,
		},

		istLiquidiert: { label: 'Ist liquidiert', typ: 'boolean' },
	},
};

export const zuVersteuerndeVorabpauschaleMetadata: EntityMetadata<ZuVersteuerndeVorabpauschale> = {
	apiCollectionName: '',
	fields: {
		jahr: {
			label: 'Jahr',
			typ: 'number',
			decimals: 0,
			minValue: 1900,
			maxValue: 2300,
			requiredLevel: RequiredLevel.RequiredToSave,
			isEfaNumber: false,
		},
		betragVorabp: {
			label: 'Betrag Vorabpauschale',
			typ: 'number',
			decimals: 2,
			requiredLevel: RequiredLevel.RequiredToSave,
			isEfaNumber: true,
		},
		betragStpflAnteil: {
			label: 'Betrag steuerpflichtiger Anteil Vorabpauschale',
			typ: 'number',
			decimals: 2,
			requiredLevel: RequiredLevel.RequiredToSave,
			isEfaNumber: true,
		},
	},
	label: '',
	labelGender: 'm',
	plural: '',
	routing: {},
};

export interface DotationInfo {
	anzahlAnfang: number;
	anzahlVorlaeufigEingeloest: number;
	anzahlEingeloest: number;
}

export const dotationInfoMetadata: EntityMetadata<DotationInfo> = {
	apiCollectionName: '',
	fields: {
		anzahlEingeloest: {
			label: 'Anzahl Eingelöst',
			typ: 'number',
			decimals: 0,
			isEfaNumber: false,
			description: 'Errechnet anhand der im Rahmen der Einreichungsbearbeitung erfassten Werte',
		},
		anzahlVorlaeufigEingeloest: {
			label: 'Anzahl Eingelöst (vorläufig)',
			typ: 'number',
			decimals: 0,
			isEfaNumber: false,
			description: 'Errechnet anhand der im Rahmen der Einreichungsbearbeitung erfassten Werte',
		},
		anzahlAnfang: {
			label: 'Anzahl Anfang',
			typ: 'number',
			decimals: 0,
			isEfaNumber: false,
			description: 'Aus den Gattungsstammdaten',
		},
		// wertEingeloest: {
		// 	label: '(Netto) Wert Eingelöst ',
		// 	typ: 'number',
		// 	description: 'Errechnet aus Stückelung * Anzahl Eingelöst',
		// 	isEfaNumber: true,
		// 	decimals: 6,
		// },
		// anzahlRest: {
		// 	label: 'Anzahl Rest',
		// 	typ: 'number',
		// 	isEfaNumber: false,
		// 	description: 'Errechnet aus Anzahl Anfang - Anzahl Eingelöst',
		// 	decimals: 6,
		// },
		// wertRest: {
		// 	label: '(Netto) Wert Rest',
		// 	typ: 'number',
		// 	isEfaNumber: true,
		// 	description: 'Errechnet aus Stückelung * Anzahl Anfang - Stückelung * Anzahl Eingelöst',
		// 	decimals: 6,
		// },
	},
	label: '',
	labelGender: 'w',
	plural: '',
	routing: {},
};

export interface GattungAktiveNutzungInfo {
	inActiveUse: boolean;
	geschaeftsfallnummerList: string | null;
	einreichungList: string | null;
}
