import { Component, ChangeDetectionStrategy, Input, Attribute, OnInit } from '@angular/core';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { BewegungsdatenQuickInfoService } from '../../bewegungsdaten-quick-info.service';
import { Einreichungsposition, geschaeftsfallMetadata, GeschaeftsfallMitPositionen } from '../../model';

@Component({
	selector: 'app-geschaeftsfall',
	templateUrl: './geschaeftsfall.component.html',
	styleUrls: ['./geschaeftsfall.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallComponent implements OnInit {
	@Input() geschaeftsfallMitPositionen: GeschaeftsfallMitPositionen = {} as any;
	@Input() userHasRoleAnteilsscheingeschaeft: boolean = false;
	@Input('positionenLocation') public positionenLocation: string = 'side-by-side';
	public showAnsichtButtonResolved: boolean = true;

	public showNettoBetrag: boolean = false;

	public readonly metadata = geschaeftsfallMetadata;

	constructor(
		public readonly quickInfo: QuickInfoPopupService,
		public readonly bewegungsdatenQuickInfoService: BewegungsdatenQuickInfoService,
		@Attribute('embeddedMode') public readonly embeddedMode: string = 'true',
		@Attribute('showButtons') public readonly showButtons: string = 'false'
	) {
		if (!embeddedMode) embeddedMode = 'true';
	}

	ngOnInit(): void {
		this.showAnsichtButtonResolved =
			this.embeddedMode === 'true' &&
			!this.geschaeftsfallMitPositionen.geschaeftsfall.status.includes('Vorschau');

		const geschaeftsfall = this.geschaeftsfallMitPositionen.geschaeftsfall;
		if (geschaeftsfall) {
			this.showNettoBetrag = !geschaeftsfall.istInkasso || !!geschaeftsfall.inkassobetrag;
		}
		(this.geschaeftsfallMitPositionen.positionen as any).sort(positionSorter);
	}
}

export function positionSorter(
	p1: Einreichungsposition & { type: string; },
	p2: Einreichungsposition & { type: string; }
): number {
	if (p1.kuponnummerFaelligkeit.split('.').length === 3) {
		p1.type = 'date';
	} else {
		p1.type = 'noDate';
	}
	if (p2.kuponnummerFaelligkeit.split('.').length === 3) {
		p2.type = 'date';
	} else {
		p2.type = 'noDate';
	}

	if (p1.type < p2.type) {
		return 1;
	}
	if (p1.type > p2.type) {
		return -1;
	}
	if (p1.type === 'date' && p2.type === 'date') {
		const kf1 = p1.kuponnummerFaelligkeit.split('.').map(k => Number(k));
		const kf2 = p2.kuponnummerFaelligkeit.split('.').map(k => Number(k));
		return new Date(kf1[2], kf1[1] - 1, kf1[0]).getTime() - new Date(kf2[2], kf2[1] - 1, kf2[0]).getTime();
	}
	if (p1.type === 'noDate' && p2.type === 'noDate') {
		if (p1.kuponnummerFaelligkeit < p2.kuponnummerFaelligkeit) {
			return -1;
		}
		if (p1.kuponnummerFaelligkeit > p2.kuponnummerFaelligkeit) {
			return 1;
		}
	}
	return 0;
}
