import { Component, OnInit, ChangeDetectionStrategy, Optional, Self, Input, SimpleChanges } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { FieldDescription } from 'src/app/model';
import { coalesce } from 'src/app/utils';
import { TypedControl } from '../typed-form';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent extends BaseComponent {
	@Input() metadata?: FieldDescription;
	@Input() label = '';
	@Input() placeholder = '';
	@Input() maxLength: number | null = null;

	get resolvedLabel(): string {
		return coalesce(this.label, this.metadata?.label, '');
	}

	get resolvedPlaceholder(): string {
		return coalesce(this.placeholder, this.metadata?.label, '');
	}

	public readonly isDisabled$ = new BehaviorSubject(false);
	public readonly isRequired$ = new BehaviorSubject(false);

	public readonly textControl = new TypedControl<string>('');
	public readonly showClearButton$ = combineLatest([this.textControl.value$, this.isDisabled$]).pipe(
		map(([value, isDisabled]) => !isDisabled && value)
	);

	private onChange?: (val: string | null) => void;
	private onTouchedCallback?: Function;

	constructor(
		@Optional() @Self() public readonly ngControl: NgControl // no idea why @Optional...
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl) throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (!this.ngControl.control) {
				console.error('no control on ngControl! Did you add formControlName or formControl?', this);
				throw new Error('no control on ngControl! Did you add formControlName or formControl?');
			}
			this.reevaluateValidators();

			const hostControl = this.ngControl.control;

			const onChange = this.onChange;
			if (!onChange) {
				throw new Error('Missing onChange!');
			}

			this.registerSubscription(
				this.textControl.value$.subscribe(textValue => {
					this.reevaluateValidators();
					onChange(textValue ?? '');
					const errorsFromParentValidators = hostControl.validator
						? hostControl.validator(hostControl)
						: null;

					this.textControl.setErrors(errorsFromParentValidators);
				})
			);
		});
	}

	/** called when bound FormControl gets input */
	writeValue(val: string | null | undefined): void {
		this.textControl.reset(val);
	}

	registerOnChange(fn: (val: string | null) => void): void {
		this.onChange = fn;
	}

	reevaluateValidators(): void {
		const control = this.ngControl.control!; // gesichert in afterViewInit;

		const shouldBeRequired = control.hasValidator(Validators.required);
		if (this.isRequired$.value !== shouldBeRequired) {
			this.isRequired$.next(shouldBeRequired);
		}
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.textControl.disable();
		} else {
			this.textControl.enable();
		}
		this.isDisabled$.next(isDisabled);
	}

	onTouched(): void {
		if (this.onTouchedCallback) this.onTouchedCallback();
	}

	clear(): void {
		this.textControl.reset();
	}
}
