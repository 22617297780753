import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { einreichungMetadata } from '../../model';

@Component({
	selector: 'app-einreichung-rueckfrage-popup',
	templateUrl: './einreichung-rueckfrage-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungRueckfragePopupComponent {
	public readonly metadata = einreichungMetadata;
	public readonly form = new TypedForm<{ bemerkung: string }>({
		bemerkung: createFormControl(einreichungMetadata.fields.bemerkung),
	});

	readonly cannotAccept$ = this.form.statusChanges.pipe(
		map(s => s !== 'VALID'),
		startWith(true)
	);

	public readonly title: string;

	constructor(
		private ref: MatDialogRef<EinreichungRueckfragePopupComponent>,
		@Inject(MAT_DIALOG_DATA) data: { bemerkung: string | undefined | null; mode: 'Interne Klärung' | 'Rückfrage' }
	) {
		this.ref.backdropClick().subscribe(b => this.tryCancel());
		this.form.setValue({ bemerkung: data.bemerkung || '' });
		this.form.controls.bemerkung.addValidators(Validators.required);

		switch (data.mode) {
			case 'Interne Klärung':
				this.title = 'Interne Klärung anfordern';
				break;
			default:
				this.title = 'Rückfrage anfordern';
				break;
		}
	}

	tryCancel(): void {
		this.ref.close();
	}

	accept(): void {
		this.ref.close({ dialogResult: 'Ja', ...this.form.typedValue });
	}
}
