<form mat-dialog-content class="flex-column" [formGroup]="form" (ngSubmit)="save()">
	<app-text-input style="flex: 1" formControlName="name" [metadata]="data.metadata.fields.name"></app-text-input>

	<app-date-input
		[metadata]="data.metadata.fields.erstesAuftreten"
		formControlName="erstesAuftreten"></app-date-input>

	<mat-checkbox formControlName="istWiederkehrend">{{ data.metadata.fields.istWiederkehrend.label }}</mat-checkbox>

	<div style="display: flex; justify-content: space-between; gap: 1em; margin-top: 1em">
		<div>
			<button mat-raised-button mat-dialog-close color="accent" [disabled]="isSaving$ | async">Abbrechen</button>
		</div>

		<div>
			<button
				mat-raised-button
				color="primary"
				type="submit"
				[disabled]="cannotSave$ | async"
				*requireRole="'erweitert'">
				<mat-icon *ngIf="isSaving$ | async | not">save</mat-icon>
				<mat-spinner
					*ngIf="isSaving$ | async"
					[diameter]="20"
					style="display: inline-block; margin-right: 0.5em"></mat-spinner>
				Speichern
			</button>
		</div>
	</div>
</form>
