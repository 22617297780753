import {
	Component,
	ChangeDetectionStrategy,
	TrackByFunction,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, catchError, debounceTime, EMPTY, startWith, Subject } from 'rxjs';
import { createFormControl } from 'src/app/model';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { toExternalModel } from 'src/app/utils';
import { BuchungService } from '../buchung.service';
import { Buchung, buchungMetadata } from '../model';
import * as ExcelJS from 'ExcelJS-workaround';
import { SaveService } from 'src/app/shared/services/save.service';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/general/base-component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
	selector: 'app-buchungen-archiv',
	templateUrl: './buchungen-archiv.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuchungenArchivComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;

	readonly metadata = buchungMetadata;
	readonly searchForm = new TypedForm<{ date: Date }>({
		date: createFormControl(buchungMetadata.fields.verarbeitungszeitpunkt),
	});
	readonly filterForm = new TypedForm<FilterForm>({
		buchungsdatumAnfang: new UntypedFormControl(null),
		buchungsdatumEnde: new UntypedFormControl(null),
		valutatagAnfang: new UntypedFormControl(null),
		valutatagEnde: new UntypedFormControl(null),
		geschaeftsfallNummer: new UntypedFormControl(null),
		kontonummer: new UntypedFormControl(null),
	});

	readonly isLoading$ = new BehaviorSubject(false);
	readonly searchDate$ = new Subject<Date>();
	public readonly dataSource = new MatTableDataSource<Buchung>();
	public readonly displayedColumns = [
		'kontonummer',
		'buchungsdatum',
		'valutatag',
		'betrag',
		'isin',
		'geschaeftsfallNummer',
		'verarbeitungszeitpunkt',
	];
	public readonly trackBy: TrackByFunction<Buchung> = (index, item) =>
		`${item.geschaeftsfallId}${item.buchungsnummer}`;

	constructor(
		private readonly service: BuchungService,
		private readonly alert: AlertService,
		private readonly save: SaveService
	) {
		super();

		const filterFormValues$ = this.filterForm.values$.pipe(
			debounceTime(200),
			startWith({} as FilterForm)
		);
		let filterIndex = 0;

		this.registerSubscription(
			filterFormValues$.subscribe(filterValues => {
				this.dataSource.filterPredicate = data => {
					if (filterValues.kontonummer) {
						if (!data.kontonummer?.includes(filterValues.kontonummer)) return false;
					}
					if (filterValues.buchungsdatumAnfang) {
						if (!data.buchungsdatum) return false;
						if (data.buchungsdatum < filterValues.buchungsdatumAnfang) return false;
					}
					if (filterValues.buchungsdatumEnde) {
						if (!data.buchungsdatum) return false;
						if (data.buchungsdatum > filterValues.buchungsdatumEnde) return false;
					}
					if (filterValues.valutatagAnfang) {
						if (!data.valutatag) return false;
						if (data.valutatag < filterValues.valutatagAnfang) return false;
					}
					if (filterValues.valutatagEnde) {
						if (!data.valutatag) return false;
						if (data.valutatag > filterValues.valutatagEnde) return false;
					}
					if (filterValues.geschaeftsfallNummer) {
						if (!data.geschaeftsfallNummer?.includes(filterValues.geschaeftsfallNummer))
							return false;
					}
					return true;
				};
				filterIndex++;
				this.dataSource.filter = filterIndex.toFixed();
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	search(): void {
		if (!this.searchForm.typedValue.date) return;

		this.isLoading$.next(true);

		this.service
			.getByVerarbeitungszeitpunkt$(this.searchForm.typedValue.date)
			.pipe(
				catchError(err => {
					this.alert.error('Konnte nicht laden!', err, {
						date: this.searchForm.typedValue.date,
					});
					this.isLoading$.next(false);
					return EMPTY;
				})
			)
			.subscribe(buchungen => {
				this.dataSource.data = buchungen;
				this.isLoading$.next(false);
				this.searchDate$.next(this.searchForm.typedValue.date);
			});
	}

	downloadAsExcel(data: Buchung[]): void {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(this.metadata.label);
		worksheet.addRow([
			this.metadata.fields.kontonummer.label,
			this.metadata.fields.buchungsdatum.label,
			this.metadata.fields.valutatag.label,
			this.metadata.fields.betrag.label,
			this.metadata.fields.geschaeftsfallIsin.label,
			this.metadata.fields.geschaeftsfallNummer.label,
			this.metadata.fields.verarbeitungszeitpunkt.label,
		]);
		for (const element of data) {
			worksheet.addRow([
				element.kontonummer,
				element.buchungsdatum?.toLocaleDateString('de-DE'),
				element.valutatag?.toLocaleDateString('de-DE'),
				toExternalModel(element.betrag)?.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				}),
				element.geschaeftsfallIsin,
				element.geschaeftsfallNummer,
				element.verarbeitungszeitpunkt?.toLocaleString('de-DE'),
			]);
		}
		workbook.xlsx.writeBuffer().then(arrayBuffer => {
			const blob = new Blob([arrayBuffer]);
			const now: string = new Date().toISOString().slice(0, 10);
			this.save.saveAs(blob, this.metadata.apiCollectionName + '_' + now + '.xlsx');
		});
	}
}

type FilterForm = {
	buchungsdatumAnfang: Date | null;
	buchungsdatumEnde: Date | null;
	valutatagAnfang: Date | null;
	valutatagEnde: Date | null;
	geschaeftsfallNummer: string | null;
	kontonummer: string | null;
};
