import { HttpClient } from '@angular/common/http';
import { interval, timer } from 'rxjs';

class GlobalLogger {
	private readonly queue: LogMessage[] = [];

	enableHttp(http: HttpClient) {
		interval(2000).subscribe(() => {
			const queuedData = [...this.queue];
			this.queue.length = 0;
			if (queuedData.length > 0) {
				http.post('/logging', queuedData).subscribe({
					error: err => console.error('Failed to send logs', err, queuedData),
					complete: () => {
						console.debug('logs sent');
					},
				});
			}
		});
	}

	/**
	 * For logging object data use
	 * log(object, message)
	 * @param parts parameter for consol.log
	 */
	log(...parts: any[]): void {
		console.log(...parts);
		this.logHttpMessage('info', ...parts);
	}

	error(...parts: any[]): void {
		console.error(...parts);
		this.logHttpMessage('error', parts);
	}

	warn(...parts: any[]): void {
		console.warn(...parts);
		this.logHttpMessage('warn', parts);
	}

	private logHttpMessage(level: string, ...parts: any[]): void {
		if (this.queue.length < 2000) {
			this.queue.push({
				level,
				message: parts
					.map(formatMessagePart)
					.filter(m => m)
					.join('\n'),
			});
		}
	}
}

export const logger = new GlobalLogger();

interface LogMessage {
	level: string;
	message: string;
}

function formatMessagePart(part: string | Error | any): string {
	if (typeof part === 'string') {
		return part;
	} else if (part instanceof Error) {
		return `${part.name} ${part.message}\n${part.stack}`;
	} else {
		try {
			return JSON.stringify(part);
		} catch {
			return '';
		}
	}
}
