<h1 *ngIf="showHeader">{{ finanzinstitut.finanzinstitutsnummer }} - {{ finanzinstitut.finanzinstitutsname }}</h1>


<div class="flex-row">
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.finanzinstitutsnummer">{{ finanzinstitut.finanzinstitutsnummer }}</div>
		<div app-data-item [field]="metadata.fields.finanzinstitutsname">{{ finanzinstitut.finanzinstitutsname }}</div>
		<div app-data-item [field]="metadata.fields.finanzinstitutskonto">{{ finanzinstitut.finanzinstitutskonto }}</div>
		<div app-data-item [field]="metadata.fields.istEinreicher">{{ finanzinstitut.istEinreicher | booleanText }}</div>
		<div app-data-item [field]="metadata.fields.istEmittent">{{ finanzinstitut.istEmittent | booleanText }}</div>
	</div>
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.adresszusatz">{{ finanzinstitut.adresszusatz }}</div>
		<div app-data-item [field]="metadata.fields.adresse">{{ finanzinstitut.adresse }}</div>
		<div app-data-item [field]="metadata.fields.postleitzahl">{{ finanzinstitut.postleitzahl }}</div>
		<div app-data-item [field]="metadata.fields.ort">{{ finanzinstitut.ort }}</div>
		<div app-data-item [field]="metadata.fields.land">{{ finanzinstitut.land }}</div>
		<div app-data-item [field]="metadata.fields.bundesland">{{ finanzinstitut.bundesland }}</div>
	</div>
</div>

<hr />

<div class="flex-row">
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.geaendertVon">{{ finanzinstitut.geaendertVon }}</div>
		<div app-data-item [field]="metadata.fields.geaendertAm">{{ finanzinstitut.geaendertAm | date: 'dd.MM.yyyy' }}</div>
	</div>
	<div class="data-column2">
		<div app-data-item [field]="metadata.fields.freigegebenVon">{{ finanzinstitut.freigegebenVon }}</div>
		<div app-data-item [field]="metadata.fields.freigegebenAm">{{ finanzinstitut.freigegebenAm | date: 'dd.MM.yyyy' }}</div>
	</div>
</div>
