import { ChangeDetectionStrategy, Component, forwardRef, Input, TrackByFunction, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, of } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { SimpleDialogService } from 'src/app/shared/dialogs/simple-dialog.service';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { gattungMetadata } from 'src/app/stammdaten/gattung/model';
import { QuickInfoPopupService } from 'src/app/stammdaten/quick-info-popup.service';
import { toInternalModel } from 'src/app/utils';
import { Einreichungsposition, einreichungspositionMetadata } from '../../model';
import { EinreichungEditPositionPopupComponent } from '../einreichung-edit-position-popup/einreichung-edit-position-popup.component';

@Component({
	selector: 'app-einreichung-edit-page-positionen',
	templateUrl: './einreichung-edit-page-positionen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EinreichungEditPagePositionenComponent),
			multi: true,
		},
	],
})
export class EinreichungEditPagePositionenComponent extends BaseComponent implements ControlValueAccessor {
	@ViewChild(MatSort) sort?: MatSort;
	@Input() kistamProzentsatz?: number;

	public readonly metadata = einreichungspositionMetadata;
	public readonly gattungMetadata = gattungMetadata;
	public readonly dataSource = new MatTableDataSource<EinreichungspositionEntry>();
	public readonly displayedColumns: (keyof EinreichungspositionEntry)[] = [
		'positionnummer',
		'isin',
		'gattungId',
		'kuponnummerFaelligkeit',
		'emittent',
		'anteil',
		'anzahl',
		'gesamtwert',
		'actions',
	];

	public readonly trackBy: TrackByFunction<EinreichungspositionEntry> = (index, item) => item.id;
	public readonly positionen$ = new BehaviorSubject<Einreichungsposition[]>([]);
	public readonly gesamtwertEUR$ = this.positionen$.pipe(
		map(positionen => positionen.reduce((agg, cv) => agg + cv.gesamtwert, 0))
	);

	constructor(
		private readonly gattungService: GattungService,
		private readonly finanzinstitutService: FinanzinstitutService,
		private readonly matDialog: MatDialog,
		public readonly quickInfo: QuickInfoPopupService,
		private readonly simpleDialog: SimpleDialogService
	) {
		super();
	}

	writeValue(obj: Einreichungsposition[]): void {
		this.setPositionen(obj || []);
	}

	setPositionen(positionen: Einreichungsposition[]): void {
		positionen = [...positionen].sort((a, b) => Math.sign(a.positionnummer - b.positionnummer));
		let expectedPositionnummer = 1;
		for (const p of positionen) {
			p.positionnummer = expectedPositionnummer;
			expectedPositionnummer++;
		}
		this.positionen$.next(positionen);
	}

	registerOnChange(fn: (positionen: Einreichungsposition[]) => void): void {
		this.registerSubscription(this.positionen$.pipe(distinctUntilChanged()).subscribe(fn));
	}

	registerOnTouched(fn: any): void {
		// no-op
	}

	setDisabledState(shouldBeDisabled: boolean): void {
		// no-op
	}

	ngAfterViewInit(): void {
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
		const positionen$ = this.positionen$;
		if (!positionen$) throw new Error('Positionen$ not set!');

		setTimeout(() =>
			this.registerSubscription(
				combineLatest([this.gattungService.list$, this.finanzinstitutService.list$, positionen$]).subscribe(
					([gattungen, institute, positionen]) => {
						this.dataSource.data = positionen.map(p => {
							const gattung = p.gattungId ? gattungen.find(g => g.id === p.gattungId) : undefined;
							const institut = gattung?.emittentId
								? institute.find(i => i.id === gattung.emittentId)
								: undefined;
							return {
								...p,
								isin: gattung?.isin ?? '',
								gattungsbezeichnung: gattung?.gattungsbezeichnung ?? '',
								kuponnummerFaelligkeit: gattung?.kuponnummerFaelligkeit ?? '',
								emittent: institut?.displayName ?? '',
								emittentId: institut?.id,
							};
						});
					}
				)
			)
		);
	}

	open(position?: Einreichungsposition): void {
		const nextPositionNummer = 1 + Math.max(0, ...this.positionen$.value.map(p => p.positionnummer));
		if (!position) {
			position = {
				anzahl: 1,
				einzelwert: 0,
				gattungId: null,
				gesamtwert: 0,
				nominalwert: 0,
				positionnummer: nextPositionNummer,
				id: 'new-' + nextPositionNummer,
				anteil: toInternalModel(1),
				faktor: toInternalModel(1),
				nominalwaehrung: 'EUR',
				stueckenummer: [],
				vorabpauschale: 0,
				steuern: [],
				kuponnummerFaelligkeit: '',
			};
		}

		this.matDialog
			.open(EinreichungEditPositionPopupComponent, {
				data: {
					einreichungsposition: position,
					kistamProzentsatz: this.kistamProzentsatz,
				},
				disableClose: true,
				minWidth: '80vw',
			})
			.afterClosed()
			.subscribe((p: Einreichungsposition) => {
				if (p) {
					this.setPositionen([...this.positionen$.value.filter(old => old.id !== p.id), p]);
				}
			});
	}

	remove(positionToRemove: Einreichungsposition): void {
		this.simpleDialog.jaNein('Wirklich die Position entfernen?', 'Position entfernen?').subscribe(v => {
			console.log(this.positionen$.value, v, positionToRemove);
			if (v === 'Ja') this.setPositionen(this.positionen$.value.filter(p => p.id !== positionToRemove.id));
		});
	}

	removeAll(): void {
		this.simpleDialog.jaNein('Wirklich alle Position entfernen?', 'Alle Position entfernen?').subscribe(v => {
			if (v === 'Ja') this.setPositionen([]);
		});
	}
}

interface EinreichungspositionEntry extends Einreichungsposition {
	isin: string;
	gattungsbezeichnung: string;
	kuponnummerFaelligkeit: string;
	emittent: string;
	emittentId?: string;
	actions?: {};
}
