import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { BenutzerRolle } from './roles';

/** Holds the instance of the current user. Separated from InitService to make testing easier. */
@Injectable({
	providedIn: 'root',
})
export class CurrentUserService {
	private readonly _currentUser$ = new BehaviorSubject<CurrentUser>({
		fullname: 'NICHT_EINGELOGGT',
		username: '',
		roles: [],
	} as CurrentUser);

	public readonly currentUser$ = this._currentUser$.asObservable();

	public get fullname() {
		return this._currentUser$.value.fullname;
	}

	public get username() {
		return this._currentUser$.value.username;
	}

	public get roles() {
		return this._currentUser$.value.roles;
	}

	public readonly isLeser = this.roles && this.roles.includes(BenutzerRolle.Leser);

	setCurrentUser(currentUser: CurrentUser): void {
		const val = {
			fullname: currentUser.fullname || '',
			roles: currentUser.roles || [],
			username: currentUser.username || '',
		} as CurrentUser;
		this._currentUser$.next(val);
	}

	logout(): void {
		this._currentUser$.next({ fullname: 'NICHT_EINGELOGGT', username: '', roles: [] } as CurrentUser);
	}

	hasAnyOfRoles(requiredRoles: string | string[] | BenutzerRolle | BenutzerRolle[]): boolean {
		const actualRoles = this.roles;
		switch (typeof requiredRoles) {
			case 'string':
				return actualRoles.includes(requiredRoles as BenutzerRolle);
			default:
				return actualRoles.some(r => requiredRoles.includes(r));
		}
	}

	hasAnyOfRoles$(requiredRoles: string | string[] | BenutzerRolle | BenutzerRolle[]): Observable<boolean> {
		return this._currentUser$.pipe(
			map(cu => {
				const actualRoles = cu.roles;
				switch (typeof requiredRoles) {
					case 'string':
						return actualRoles.includes(requiredRoles as BenutzerRolle);
					default:
						return actualRoles.some(r => requiredRoles.includes(r));
				}
			}),
			startWith(false)
		);
	}
}

export interface CurrentUser {
	username: string;
	fullname: string;
	roles: BenutzerRolle[];
}
