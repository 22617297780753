import { Pipe, PipeTransform } from '@angular/core';
import { Einreichungsperson } from '../model';

@Pipe({
	name: 'personName',
})
export class PersonNamePipe implements PipeTransform {
	transform(einreichungsperson: Einreichungsperson): string {
		return getPersonName(einreichungsperson);
	}
}

export function getPersonName(
	einreichungsperson: Einreichungsperson,
	{ ohneAnrede }: { ohneAnrede: boolean } = { ohneAnrede: false }
) {
	return [ohneAnrede ? '' : einreichungsperson.anrede, einreichungsperson.vorname, einreichungsperson.nachname]
		.filter(part => part)
		.map(part => part.trim())
		.join(' ');
}
