<h1 mat-dialog-title>Wähle eine Excel Datei</h1>
<div mat-dialog-content>
	<app-file-upload
		(fileSelected)="onFileSelected($event)"
		placeholder="Excel-Datei auswählen..."
		accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></app-file-upload>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-between; gap: 2em">
	<div>
		<button
			mat-raised-button
			mat-dialog-close
			color="accent"
			*ngIf="isLoading$ | async | not">
			<mat-icon>close</mat-icon>
			Abbrechen
		</button>
	</div>
	<div>
		<button
			mat-raised-button
			color="primary"
			[disabled]="!file"
			(click)="load()"
			*ngIf="isLoading$ | async | not; else spinner">
			<mat-icon>file_upload</mat-icon>
			Hochladen
		</button>
	</div>
</div>

<ng-template #spinner>
	<mat-spinner diameter="20"></mat-spinner>
</ng-template>
