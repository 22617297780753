<h1 mat-dialog-title>Filenet Datei Upload</h1>
<form (ngSubmit)="onSubmit(f)" #f="ngForm">
	<div matDialogContent>
		<app-file-upload
			(fileSelected)="onFileSelected($event)"
			placeholder="Datei auswählen..."></app-file-upload>
		<mat-error *ngIf="parseError$ | async">{{ parseError$.value }}</mat-error>
		<div *ngIf="importResult$ | async" style="color: green">{{ importResult$.value }}</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="accent" mat-dialog-close (click)="onCancel(); (false)">
			{{ text4CancelBtn }}
		</button>
		<button
			[disabled]="file$ | async | not"
			*ngIf="showImportBtn"
			mat-raised-button
			color="primary"
			type="submit">
			Importieren
		</button>
	</div>
</form>
