import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { einreichungMetadata } from '../../model';

@Component({
	selector: 'app-einreichung-abbrechen-popup',
	templateUrl: './einreichung-abbrechen-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungAbbrechenPopupComponent {
	public readonly metadata = einreichungMetadata;
	public readonly form = new TypedForm<{ abbruchStornoGrund: string }>({
		abbruchStornoGrund: createFormControl(einreichungMetadata.fields.abbruchStornoGrund),
	});

	readonly cannotAccept$ = this.form.statusChanges.pipe(
		map(s => s !== 'VALID'),
		startWith(true)
	);

	constructor(private ref: MatDialogRef<EinreichungAbbrechenPopupComponent>) {
		this.ref.backdropClick().subscribe(b => this.tryCancel());
		this.form.controls.abbruchStornoGrund.addValidators(Validators.required);
	}

	tryCancel(): void {
		this.ref.close();
	}

	accept(): void {
		this.ref.close({ dialogResult: 'Ja', ...this.form.typedValue });
	}
}
