<mat-form-field style="width: 100%">
	<div matPrefix>
		<app-field-help-icon [helpText]="metadata?.description"></app-field-help-icon>
	</div>
	<mat-label>{{ resolvedLabel }}</mat-label>
	<input
		matInput
		[formControl]="textControl"
		autocomplete="off"
		style="width: 100%"
		(focus)="htmlControl.select()"
		[placeholder]="resolvedPlaceholder"
		#htmlControl
		[required]="!!(isRequired$ | async)"
		[matAutocomplete]="auto"
		(blur)="maybeEmit()"
		(keydown.enter)="maybeEmit()"
    [required]="!!(isRequired$ | async)"
    />
	<div matSuffix style="margin-left: 0.5em; width: 1em">
		<mat-icon
			(click)="clear()"
			style="cursor: pointer; display: inline-block"
			*ngIf="showClearButton$ | async"
			matTooltip="Zurücksetzen">
			close
		</mat-icon>
	</div>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="maybeEmit()">
		<mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.kuerzel">
			<div style="display: flex; gap: 0.5em">
				<span style="flex: 1">{{ option.kuerzel }}</span>
				<span style="flex: auto 0 0; text-overflow: ellipsis; overflow-x: hidden">
					{{ option.bezeichnung }}, {{ option.extra }}
				</span>
			</div>
		</mat-option>
	</mat-autocomplete>
	<mat-error>{{ textControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
