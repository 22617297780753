import { OppositionService } from './../../stammdaten/opposition/opposition.service';
import { FeiertagskalenderService } from './../../stammdaten/feiertagskalender/feiertagskalender.service';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	EMPTY,
	map,
	Observable,
	of,
	shareReplay,
	Subscription,
	throwError,
	timer,
} from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { AlertService } from 'src/app/shared/alert.service';
import { BenutzerService } from 'src/app/stammdaten/benutzer/benutzer.service';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { KuerzelBezeichnungReferenzlistServiceFactory } from 'src/app/stammdaten/kuerzel-bezeichnung/kuerzel-bezeichnung-referenzlist.service';
import {
	KuerzelBezeichnungReferenzMetadata,
	REFERENZLIST_METADATA,
} from 'src/app/stammdaten/kuerzel-bezeichnung/model';
import { logger } from 'src/logger';
import { AppConfig, ConfigService } from '../config.service';
import { CurrentUser, CurrentUserService } from '../current-user.service';
import { KonfigurationService } from '../konfiguration/konfiguration.service';
import { BenutzerRolle } from '../roles';
import { EinreichungService } from 'src/app/bewegungsdaten/einreichung.service';
import { GeschaeftsfallService } from 'src/app/bewegungsdaten/geschaeftsfall.service';
import * as _ from 'lodash';
import * as df from 'date-fns';

@Injectable({
	providedIn: 'root',
})
export class InitService {
	readonly stateAndMessage$ = new BehaviorSubject<{ state: InitState; message: string; }>({
		state: InitState.LoadingConfiguration,
		message: 'Lade Konfiguration...',
	});
	readonly lastNavigationDate$ = new BehaviorSubject<Date>(new Date()); // init = NOW
	readonly state$ = this.stateAndMessage$.pipe(map(sm => sm.state));
	readonly isLoggedIn$ = this.state$.pipe(map(v => v === InitState.LoggedIn));
	private timerSubscription: Subscription = new Subscription();

	constructor(
		private http: HttpClient,
		private gattungen: GattungService,
		private finanzinsitute: FinanzinstitutService,
		private oppositionService: OppositionService,
		private benutzer: BenutzerService,
		private feiertage: FeiertagskalenderService,
		private alert: AlertService,
		private serviceFactory: KuerzelBezeichnungReferenzlistServiceFactory,
		private currentUser: CurrentUserService,
		private configService: ConfigService,
		private konfiguration: KonfigurationService,
		private einreichungen: EinreichungService,
		private geschaeftsfaelle: GeschaeftsfallService,
		@Inject(REFERENZLIST_METADATA)
		private referenzlistMetadata: KuerzelBezeichnungReferenzMetadata
	) { }

	/** Initialisiere die App:
	 * 1. lade config.json und setze this.config
	 * 2. starte verifyCacheLogin
	 */
	init(): void {
		this.http
			.get<string>('version.txt', { responseType: 'text' as 'json', observe: 'body' })
			.pipe(
				catchError(err => {
					logger.error(err);
					return of('Konnte nicht ermittelt werden');
				})
			)
			.subscribe(version => this.configService.frontendVersion$.next(version));

		this.http
			.get<string>('build-date.txt', { responseType: 'text' as 'json', observe: 'body' })
			.pipe(
				catchError(err => {
					logger.error(err);
					return of('Konnte nicht ermittelt werden');
				})
			)
			.subscribe(version => this.configService.frontendBuildDate$.next(version));

		this.http.get<AppConfig>('config.json').subscribe({
			error: err => {
				this.stateAndMessage$.next({
					state: InitState.FailedToLoadConfiguration,
					message: err.message,
				});
				logger.error(err);
			},
			next: config => {
				this.configService.config = config;
				if (!config.backendUrl) {
					return this.stateAndMessage$.next({
						state: InitState.FailedToLoadConfiguration,
						message:
							'Die Konfiguration enthält kein backendUrl: ' + JSON.stringify(config),
					});
				}

				this.http
					.get<BackendVersion>('/version')
					.pipe(
						catchError(err => {
							logger.error(err);
							return EMPTY;
						})
					)
					.subscribe(version => this.configService.backendVersion$.next(version));

				if (config.sendLogsToServer) {
					logger.enableHttp(this.http);
				}

				this.verifyCachedLogin();
			},
		});
	}

	verifyCachedLogin(): void {
		this.stateAndMessage$.next({ state: InitState.VerifyingCachedLogin, message: '' });
		if (this.configService.config.fakeLogin) {
			const efaFakeLogin = localStorage.getItem('efa-fake-login');
			if (efaFakeLogin) {
				const currentUser = JSON.parse(efaFakeLogin) as CurrentUser;
				return this.onSuccessfulLogin(currentUser);
			}

			return this.stateAndMessage$.next({ state: InitState.RequiresLogin, message: '' });
		}

		this.http.get<CurrentUser>('/userprofile').subscribe({
			next: currentUser => this.onSuccessfulLogin(currentUser),
			error: err => {
				// console.warn(err);
				return this.stateAndMessage$.next({ state: InitState.RequiresLogin, message: '' });
			},
		});
	}

	login$(username: string, password: string): Observable<CurrentUser> {
		if (!this.configService) throw new Error('No config, call configure first!');

		this.stateAndMessage$.next({ state: InitState.LoggingIn, message: '' });

		const loginCallResult = this.configService.config.fakeLogin
			? this.fakeLogin$(username, password)
			: this.http.post<CurrentUser>('/login', { username, password });

		const loginCallResultShared = loginCallResult.pipe(shareReplay(1));

		loginCallResultShared.subscribe({
			error: err => {
				logger.error(err);
				this.stateAndMessage$.next({
					state: InitState.WrongCredentials,
					message: 'Login fehlgeschlagen!',
				});
			},
			next: currentUser => this.onSuccessfulLogin(currentUser),
		});

		return loginCallResultShared;
	}

	onSuccessfulLogin(currentUser: CurrentUser): void {
		this.currentUser.setCurrentUser(currentUser);
		this.alert.success('Eingeloggt als ' + currentUser.fullname);

		if (this.currentUser.roles.length === 0) {
			this.stateAndMessage$.next({
				state: InitState.NoRoles,
				message: 'User hat keine Berechtigungen für diese Anwendung!',
			});
		} else {
			// starte einen asynchronen Load von Daten
			this.loadInitialData();
			this.setupRefresh();
		}
	}

	setupRefresh(): void {
		const rollenMitRefresh = [
			BenutzerRolle.Anteilsscheingeschäft,
			BenutzerRolle.Leser,
			BenutzerRolle.Standard,
		];
		if (!this.currentUser.hasAnyOfRoles(rollenMitRefresh)) {
			return logger.warn(
				'Kein Refresh von Bewegungsdaten! Nur für eine der folgenden Rollen erlaubt:',
				rollenMitRefresh
			);
		}

		if (
			!('enableAutoDataRefresh' in this.configService.config) ||
			('enableAutoDataRefresh' in this.configService.config &&
				this.configService.config.enableAutoDataRefresh)
		) {
			let refreshIntervalInSeconds: number = 30;
			if ('dataRefreshIntervalInSeconds' in this.configService.config) {
				refreshIntervalInSeconds = _.toNumber(this.configService.config.dataRefreshIntervalInSeconds);
			}
			this.timerSubscription = timer(500, refreshIntervalInSeconds * 1000).subscribe(() => {
				console.log('Timer fired for fetching EINREICHUNG and GESCHAEFTSFALL data.');

				if (this.isLastNavigationOlderThenRefreshIntervall(refreshIntervalInSeconds)) {

					logger.log('HTTP-Request einreichungen AND geschaeftsfelle');

					if (!this.einreichungen.isLoading$.value) {
						this.einreichungen.loadAll$();
					}

					if (!this.geschaeftsfaelle.isLoading$.value) {
						this.geschaeftsfaelle.loadAll$();
					}
				}
			});
		}
	}

	isLastNavigationOlderThenRefreshIntervall(refreshIntervalInSeconds: number): boolean {
		let lastNaviDate: Date = this.lastNavigationDate$.value;
		let now = new Date();

		let nowMinusIntervall = df.subSeconds(now, refreshIntervalInSeconds);
		let ret: boolean = nowMinusIntervall < lastNaviDate;
		return ret;
	}

	fakeLogin$(username: string, passwort: string): Observable<CurrentUser> {
		if (!username || username !== passwort) {
			return throwError(() => new Error('Failed to log in'));
		}

		const fakeBenutzer: CurrentUser = {
			username: username,
			fullname: 'Herr ' + username[0].toLocaleUpperCase() + username.substring(1),
			roles: [],
		};

		switch (username) {
			case BenutzerRolle.Leser:
				fakeBenutzer.roles.push(BenutzerRolle.Leser);
				break;
			case BenutzerRolle.DSGVO:
				fakeBenutzer.roles.push(BenutzerRolle.DSGVO);
				break;
			case BenutzerRolle.Benutzerprotokoll:
				fakeBenutzer.roles.push(BenutzerRolle.Benutzerprotokoll);
				break;
			case BenutzerRolle.Anteilsscheingeschäft:
				fakeBenutzer.roles.push(BenutzerRolle.Anteilsscheingeschäft);
				break;
			default:
				fakeBenutzer.roles.push(BenutzerRolle.Standard);
				break;
		}

		localStorage.setItem('efa-fake-login', JSON.stringify(fakeBenutzer));

		return of(fakeBenutzer);
	}

	loadInitialData(): void {
		this.stateAndMessage$.next({ state: InitState.LoadInitialData, message: '' });
		const referenzlistServices = Object.keys(this.referenzlistMetadata).map(referenzlist =>
			this.serviceFactory.getService(referenzlist)
		);

		combineLatest([
			this.gattungen.loadAll$(),
			this.finanzinsitute.loadAll$(),
			this.oppositionService.loadAll$(),
			this.benutzer.loadAll$(),
			this.feiertage.loadAll$(),
			this.konfiguration.load$(),
			...referenzlistServices.map(service => service.loadAll$()),
		]).subscribe({
			next: () => {
				this.alert.success('System bereit');
				this.stateAndMessage$.next({ state: InitState.LoggedIn, message: '' });
			},
			error: (err: Error) => {
				logger.error(err);
				this.stateAndMessage$.next({
					state: InitState.InitialDataLoadFailed,
					message: `Fehlgeschlagen: ${err.message}`,
				});
			},
		});
	}

	logout(): void {
		this.http.post<void>('/logout', {}).subscribe({ error: err => logger.error(err) });
		this.currentUser.logout();
		this.timerSubscription.unsubscribe();
		localStorage.removeItem('efa-fake-login');
		this.stateAndMessage$.next({
			state: InitState.RequiresLogin,
			message: 'Ausgeloggt. Bitte erneut anmelden',
		});
	}
}

export enum InitState {
	LoadingConfiguration = 'LoadingConfiguration',
	FailedToLoadConfiguration = 'FailedToLoadConfiguration',
	VerifyingCachedLogin = 'VerifyingCachedToken',
	RequiresLogin = 'RequiresLogin',
	LoggingIn = 'LoggingIn',
	LoadInitialData = 'LoadInitialData',
	InitialDataLoadFailed = 'InitialDataLoadFailed',
	LoggedIn = 'LoggedIn',
	NoRoles = 'NoRoles',
	WrongCredentials = 'WrongCredentials',
}

export interface BackendVersion {
	releaseVersion: string;
	beGitCommit: string;
	beBuildDateTime: string;
}
