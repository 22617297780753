import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { INTERNAL_NUMBER_FACTOR } from 'src/app/utils';

@Pipe({
	name: 'efaNumber',
})
export class EfaNumberPipe implements PipeTransform {
	constructor(private standardNumberPipe: DecimalPipe) {}

	transform(value: number | null | undefined, decimals: number = 2): string {
		if (value === null || value === undefined) return '';

		const pattern = decimals === 2 ? '1.2-2' : decimals === 6 ? '1.6-6' : `1.${decimals}-${decimals}`;

		value = value / INTERNAL_NUMBER_FACTOR;

		return this.standardNumberPipe.transform(value, pattern, 'de-DE') ?? '';
	}
}
