<h1 mat-dialog-title>Kistam Bz.ST Import</h1>

<div mat-dialog-content>tabelle</div>

<div mat-dialog-actions class="dialogActions">
	<div>
		<button mat-raised-button color="accent" mat-dialog-close>
			<mat-icon class="mat-18">close</mat-icon>
			Abbrechen
		</button>
	</div>
	<div>
		<button mat-raised-button color="primary" mat-dialog-close>
			<mat-icon class="mat-18">close</mat-icon>
			Schließen
		</button>
	</div>
</div>
