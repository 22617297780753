import { InitService } from 'src/app/general/init/init.service';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { BaseComponent } from 'src/app/general/base-component';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FinanzinstitutService } from 'src/app/stammdaten/finanzinstitut/finanzinstitut.service';
import { Finanzinstitut } from 'src/app/stammdaten/finanzinstitut/model';
import { bufferDebounceCount } from 'src/app/utils';
import { EinreichungService } from '../einreichung.service';
import {
	Einreichung,
	einreichungMetadata,
	EinreichungStatus,
	getEinreicherName,
} from '../model';
import { getPersonName } from '../pipes/person-name.pipe';

@Component({
	selector: 'app-einreichung-list',
	templateUrl: './einreichung-list.component.html',
	styleUrls: ['./einreichung-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungListComponent extends BaseComponent implements OnChanges {
	// #region Properties / Attributes
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;

	@Output() selected$ = new EventEmitter<Einreichung>();
	@Output() doubleClick$ = new EventEmitter<Einreichung>();
	@Input() set statusFilter(val: EinreichungListStatusFilter | null | string | undefined) {
		this.statusFilter$.next((val as EinreichungListStatusFilter) || null);
	}

	get statusFilter(): EinreichungListStatusFilter | null {
		return this.statusFilter$.value;
	}

	@Output() numberOfChosen = new EventEmitter<number>();
	@Input() set enableSelection(val: boolean) {
		if (val && this.displayedColumns[0] !== 'selection') {
			this.displayedColumns.unshift('selection');
		} else if (!val && this.displayedColumns[0] === 'selection') {
			this.displayedColumns.shift();
		}
	}

	public readonly einreichungStatus = EinreichungStatus;
	public readonly dataSource = new MatTableDataSource<EinreichungListItem>();
	public readonly displayedColumns = [
		'selection',
		'nummer',
		'einreicherName',
		'eingangsdatum',
		'freigegebenAm',
		'absender',
		'status',
		'kistam',
		'actions',
	];
	public readonly trackBy: TrackByFunction<Einreichung> = (index, item) => item.id;
	public readonly filterForm = new TypedForm<FilterForm>({
		kistam: new UntypedFormControl(),
		nummer: new UntypedFormControl(),
		name: new UntypedFormControl(),
		eingangsdatumAnfang: new UntypedFormControl(),
		eingangsdatumEnde: new UntypedFormControl(),
		absender: new UntypedFormControl(),
		status: new UntypedFormControl(),
		selectForExport: new UntypedFormControl(null),
	});
	public readonly metadata = einreichungMetadata;
	public readonly statusFilter$ = new BehaviorSubject<EinreichungListStatusFilter | null>(
		null
	);

	public readonly itemClicked$ = new Subject<EinreichungListItem>();
	// #endregion Properties / Attributes

	constructor(
		private readonly service: EinreichungService,
		private readonly institutService: FinanzinstitutService,
		private readonly initService: InitService
	) {
		super();

		const filterFormValues$ = this.filterForm.values$.pipe(
			debounceTime(200),
			map(v => ({
				...v,
				name: v.name?.toLocaleUpperCase() ?? '',
				absender: v.absender?.toLocaleUpperCase() ?? '',
			})),
			startWith({} as FilterForm)
		);

		let filterIndex = 0;
		this.registerSubscription(
			combineLatest([this.statusFilter$, filterFormValues$]).subscribe(
				([statusFilter, filterValues]) => {
					this.dataSource.filterPredicate = einreichung => {
						if (statusFilter && !isMatch(einreichung, statusFilter)) return false;

						if (filterValues.nummer) {
							if (!einreichung.nummer.toString().includes(filterValues.nummer))
								return false;
						}

						if (filterValues.kistam) {
							if (!einreichung.kistam?.toString().includes(filterValues.kistam))
								return false;
						}

						if (filterValues.name) {
							const isMatch =
								einreichung.einreicherNameUppercase?.includes(filterValues.name) ||
								einreichung.wirtschaftlichBerechtigtUppercase?.includes(
									filterValues.name
								);
							if (!isMatch) return false;
						}

						if (filterValues.eingangsdatumAnfang) {
							if (!einreichung.eingangsdatum) return false;
							if (einreichung.eingangsdatum < filterValues.eingangsdatumAnfang)
								return false;
						}

						if (filterValues.eingangsdatumEnde) {
							if (!einreichung.eingangsdatum) return false;
							if (einreichung.eingangsdatum > filterValues.eingangsdatumEnde)
								return false;
						}

						if (filterValues.absender) {
							if (!einreichung.absenderUpperCase?.includes(filterValues.absender))
								return false;
						}

						if (filterValues.status) {
							if (!einreichung.status?.includes(filterValues.status)) return false;
						}

						return true;
					};
					filterIndex++;
					this.dataSource.filter = filterIndex.toFixed();
				}
			)
		);

		this.registerSubscription(
			this.itemClicked$.pipe(bufferDebounceCount(400)).subscribe(({ item, count }) => {
				if (count === 1) {
					this.selected$.next(item);
				} else {
					this.doubleClick$.next(item);
				}
			})
		);
	}

	ngOnChanges(): void {
		this.initService.lastNavigationDate$.next(new Date());

		// remove selection
		this.dataSource.data.map(x => x.isChosen = false);
	}

	ngAfterViewInit(): void {

		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.i18n4Paginator(this.paginator);
		}

		if (this.sort) {
			this.dataSource.sort = this.sort;
		}

		setTimeout(() => {
			this.registerSubscription(
				combineLatest([this.service.list$, this.institutService.list$])
					.pipe(debounceTime(10))
					.subscribe(([einreichungen, institute]) => {
						const oldSelection = this.dataSource.data
							.filter(item => item.isChosen)
							.map(item => item.id);
						this.dataSource.data = einreichungen.map(e =>
							mapListItem(e, institute, oldSelection)
						);
					})
			);

			this.service.loadAll$();
		});
	}

	toggleAll(): void {
		const allToggledOn = this.dataSource.filteredData.every(i => i.isChosen);
		this.dataSource.filteredData.forEach(i => (i.isChosen = !allToggledOn));
		this.onChangeChosen();
	}

	onChangeChosen(): void {
		const chosen = this.dataSource.filteredData.filter(i => i.isChosen).length;
		this.numberOfChosen.emit(chosen);
	}
}

function mapListItem(
	e: Einreichung,
	institute: Finanzinstitut[],
	s: string[]
): EinreichungListItem {
	const einreicherName = getEinreicherName(e, institute);

	const wirtschaftlichBerechtigtUppercase = (e.personen || [])
		.filter(p => p.istWirtschaftlichBerechtigter)
		.map(p => getPersonName(p).toLocaleUpperCase())
		.join(', ');

	return {
		...e,
		einreicherName,
		einreicherNameUppercase: einreicherName.toLocaleUpperCase(),
		wirtschaftlichBerechtigtUppercase,
		absenderUpperCase: e.absender?.toLocaleUpperCase(),
		isChosen: s.includes(e.id),
	};
}

export interface EinreichungListItem extends Einreichung {
	einreicherName: string;
	einreicherNameUppercase: string;
	wirtschaftlichBerechtigtUppercase: string;
	absenderUpperCase: string | null;
	isChosen: boolean;
}

export type EinreichungListStatusFilter =
	| 'Blockiert'
	| 'Eingegangen'
	| 'In Bearbeitung'
	| 'Zur Freigabe'
	| 'Automatisierung Fehler'
	| 'Freigegeben'
	| 'Teilgebucht';

const filterStatusMapping: Record<EinreichungListStatusFilter, EinreichungStatus[]> = {
	Blockiert: [EinreichungStatus.InternInKlaerung, EinreichungStatus.Rueckfrage],
	'In Bearbeitung': [EinreichungStatus.InBearbeitung, EinreichungStatus.KistamAbfrage],
	'Zur Freigabe': [
		EinreichungStatus.ZurFreigabe,
		EinreichungStatus.ZurFreigabeKorrigiert,
		EinreichungStatus.ZurFreigabeNurDokumentation,
	],
	'Automatisierung Fehler': [EinreichungStatus.AutomatisierungFehler],
	Eingegangen: [EinreichungStatus.Eingegangen],
	Freigegeben: [EinreichungStatus.Freigegeben],
	Teilgebucht: [EinreichungStatus.Teilgebucht],
};

export function isMatch(
	einreichung: Einreichung,
	filter: EinreichungListStatusFilter
): boolean {
	const wantedStatus = filterStatusMapping[filter];
	return wantedStatus && wantedStatus.includes(einreichung.status);
}

type FilterForm = {
	nummer: string;
	name: string;
	kistam: string;
	eingangsdatumAnfang: Date | null;
	eingangsdatumEnde: Date | null;
	absender: string;
	status: string;
	selectForExport: boolean; //no filter, used for csv export selection
};
