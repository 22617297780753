import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GattungService } from '../gattung.service';

@Pipe({
	name: 'gattungBezeichnung$',
})
export class GattungBezeichnungPipe implements PipeTransform {
	constructor(private readonly service: GattungService) {}

	transform(gattungId: string | null | undefined): Observable<string> {
		return this.service.list$.pipe(
			map(list => {
				const gattung = list.find(l => l.id === gattungId);
				if (!gattung) return '';

				if (gattung.kuponnummerFaelligkeit) {
					return `${gattung.displayName} [${gattung.kuponnummerFaelligkeit}]`;
				} else {
					return gattung.displayName;
				}
			})
		);
	}
}
