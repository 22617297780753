<h1 mat-dialog-title>Steuern überschreiben</h1>

<div mat-dialog-content class="flex-column" style="gap: 1em">
	<p *ngIf="geschaeftsfaelleZuUeberschreiben.length === 0">Keine Geschäftsfälle vorhanden, wo man Steuern überschreiben kann.</p>
	<mat-card *ngFor="let gf of geschaeftsfaelleZuUeberschreiben">
		<app-geschaeftsfall [geschaeftsfallMitPositionen]="gf"></app-geschaeftsfall>
		<app-geschaeftsfall-steuern-ueberschreiben
			[geschaeftsfall]="gf.geschaeftsfall" (ueberschreiben$)="neurechnen(gf, $event)"></app-geschaeftsfall-steuern-ueberschreiben>
	</mat-card>
</div>

<div mat-dialog-actions style="display: flex; gap: 1em">
	<button mat-raised-button color="accent" mat-dialog-close>
		<mat-icon>close</mat-icon>
		Abbrechen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" [mat-dialog-close]="data">
		<mat-icon>money_off</mat-icon>
		Steuern überschreiben
	</button>
</div>
