<ng-container *ngIf="pageContent$ | async as pageContent">
	<div *ngIf="pageContent.content as finanzinstitut; else noContent">
		<div class="page-header">
			<div>
				<a mat-raised-button color="accent" [routerLink]="metadata.routing!.list!.url">
					<mat-icon>list</mat-icon>
					Zurück zur Liste
				</a>
			</div>
			<div>
				<ng-container *requireRole="'standard'">
					<ng-container *ngIf="freigabeInfo$ | async as freigabeInfo">
						<button
							mat-raised-button
							color="primary"
							*ngIf="freigabeInfo.showFreigabeAnfordern"
							[disabled]="freigabeInfo.disableFreigabeAnfordern"
							(click)="freigabeAnfordern()">
							<mat-icon>flaky</mat-icon>
							Freigabe anfordern
						</button>
						<button
							mat-raised-button
							color="primary"
							*ngIf="freigabeInfo.showFreigeben"
							(click)="freigeben()"
							[disabled]="freigabeInfo.disableFreigeben">
							<mat-icon>approval</mat-icon>
							Freigeben
						</button>
						<button
							mat-raised-button
							color="primary"
							*ngIf="freigabeInfo.showInaktivieren"
							(click)="inaktivieren()">
							<mat-icon><span class="material-icons">visibility_off</span></mat-icon>
							Inaktivieren
						</button>
					</ng-container>

					<a mat-raised-button color="primary" [routerLink]="metadata.routing!.edit!.url(finanzinstitut.id)">
						<mat-icon>edit</mat-icon>
						<span *ngIf="finanzinstitut.status === stammdatenStatus.Inaktiv; else finanzinstitutAendern">
							Aktivieren
						</span>
						<ng-template #finanzinstitutAendern>Ändern</ng-template>
					</a>
				</ng-container>
			</div>
		</div>

		<div class="page-header">
			<h1>
				{{ finanzinstitut.finanzinstitutsnummer }} -- {{ finanzinstitut.finanzinstitutsname }} ({{
					finanzinstitut.status
				}})
			</h1>
		</div>

		<mat-tab-group>
			<mat-tab title="Details" label="Details">
				<app-finanzinstitut-details [finanzinstitut]="finanzinstitut"></app-finanzinstitut-details>
			</mat-tab>
			<mat-tab title="Gattungen" label="Gattungen">
				<app-gattung-list
					[finanzinstitut]="finanzinstitut"
					(selected$)="quickInfo.showGattung($event)"></app-gattung-list>
			</mat-tab>
			<mat-tab
				*ngIf="(freigabeInfo$ | async)?.showFreigabeAnfordern || (freigabeInfo$ | async)?.showFreigeben"
				label="Freigabeinfo">
				<ng-template mat-tab-label>
					<div
						*ngIf="(freigabeInfo$ | async)?.freigabeFehler?.length; else freigabeInfoPlain"
						class="error-text">
						<mat-icon class="example-tab-icon">warning</mat-icon>
						Freigabeinfo
					</div>
				</ng-template>
				<mat-list role="list" *ngIf="freigabeInfo$ | async as freigabeInfo">
					<div *ngIf="freigabeInfo.freigabeFehler.length === 0">
						<div *ngIf="finanzinstitut.status === stammdatenStatus.InArbeit">
							<span style="margin-right: 1em">Freigabe anfordern möglich</span>
							<button
								mat-raised-button
								color="primary"
								(click)="freigabeAnfordern()"
								*requireRole="'standard'">
								<mat-icon>flaky</mat-icon>
								Freigabe anfordern
							</button>
						</div>
						<div *ngIf="finanzinstitut.status === stammdatenStatus.ZurFreigabe" style="margin-right: 1em">
							<span style="margin-right: 1em">Freigabe möglich</span>
							<button mat-raised-button color="primary" (click)="freigeben()" *requireRole="'standard'">
								<mat-icon>approval</mat-icon>
								Freigeben
							</button>
						</div>
					</div>
					<mat-list-item role="listitem" *ngFor="let freigabeFehler of freigabeInfo.freigabeFehler">
						{{ freigabeFehler }}
					</mat-list-item>
				</mat-list>
			</mat-tab>
		</mat-tab-group>
	</div>

	<ng-template #noContent>
		<mat-spinner *ngIf="pageContent.isLoading; else error"></mat-spinner>
	</ng-template>

	<ng-template #error>
		<div>Das Finanzinstitut konnte nicht geladen werden!</div>
		<div *ngIf="pageContent.error">{{ pageContent.error }}</div>
	</ng-template>
</ng-container>

<ng-template #freigabeInfoPlain>Freigabeinfo</ng-template>
