import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatabaseService } from 'src/app/shared/database.service';
import { parseIncomingAsDate } from 'src/app/utils';
import { KuerzelBezeichnungReferenz, KuerzelBezeichnungUpdateModel } from './model';

export class KuerzelBezeichnungReferenzlistService extends DatabaseService<KuerzelBezeichnungReferenz> {
	constructor(http: HttpClient, apiCollectionName: string) {
		super(http, apiCollectionName);
	}

	onLoaded = (val: KuerzelBezeichnungReferenz) => {
		if (val.kuerzel) val.kuerzel = val.kuerzel.trim();
		if (val.bezeichnung) val.bezeichnung = val.bezeichnung.trim();
		if (val.extra) val.extra = `${val.extra}`.trim();
		if (val.geaendertAm) val.geaendertAm = parseIncomingAsDate(val.geaendertAm);
		if (!val.status) val.status = 'Aktiv';
	};

	save$(referenz: KuerzelBezeichnungUpdateModel): Observable<string> {
		return this.http.put(`/${this.apiCollectionName}/${referenz.kuerzel}`, referenz, { responseType: 'text' });
	}

	setStatus$(referenz: KuerzelBezeichnungUpdateModel, neuerStatus: 'Aktiv' | 'Inaktiv'): Observable<string> {
		switch (neuerStatus) {
			case 'Aktiv':
				return this.http.post(`/${this.apiCollectionName}/${referenz.kuerzel}/aktivieren`, null, {
					responseType: 'text',
				});
			case 'Inaktiv':
				return this.http.post(`/${this.apiCollectionName}/${referenz.kuerzel}/deaktivieren`, null, {
					responseType: 'text',
				});
			default:
				throw new Error('Neuer Status ungültig: ' + neuerStatus);
		}
	}

	delete$(referenz: KuerzelBezeichnungUpdateModel): Observable<string> {
		return this.http.delete(`/${this.apiCollectionName}/${referenz.kuerzel}`, { responseType: 'text' });
	}

	getKuerzelBezeichnungMap(): KuerzelBezeichnungMap {
		return new KuerzelBezeichnungMap(this.list$.value);
	}
}

export class KuerzelBezeichnungMap {
	public readonly data: { [key: string]: string } = {};
	constructor(items: KuerzelBezeichnungReferenz[]) {
		items.forEach(val => (this.data[val.kuerzel] = val.bezeichnung));
	}

	public map(val: string | null | undefined): string | null {
		if (!val) return null;
		return this.data[val] ?? null;
	}

	public mapOrDefault(val: string | null | undefined, defaultValue: string): string {
		if (!val) return defaultValue;
		return this.data[val] ?? defaultValue;
	}
}

@Injectable({ providedIn: 'root' })
export class KuerzelBezeichnungReferenzlistServiceFactory {
	private readonly services: { [apiCollectionName: string]: KuerzelBezeichnungReferenzlistService } = {};

	constructor(private http: HttpClient) {}

	public getService(referenzlist: string): KuerzelBezeichnungReferenzlistService {
		let service = this.services[referenzlist];
		if (!service) {
			service = new KuerzelBezeichnungReferenzlistService(this.http, 'referenzen/' + referenzlist);
			this.services[referenzlist] = service;
		}

		return service;
	}
}
