<ng-container *ngIf="pageContent$ | async as pageContent">
	<div *ngIf="pageContent.content as einreichung; else noContent">
		<div class="page-header">
			<div>
				<div>
					<a mat-raised-button color="accent" [routerLink]="metadata.routing!.list!.url">
						<mat-icon>list</mat-icon>
						Zurück zur Liste
					</a>
				</div>
				<div>
					<button
						mat-raised-button
						[matMenuTriggerFor]="menu"
						color="accent"
						aria-label="weitere Aktionen"
						title="weitere Aktionen">
						<mat-icon style="margin: 0px">more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<div style="padding-right: 10px; padding-left: 10px">
							<div style="margin-bottom: 5px">
								<a
									mat-raised-button
									color="accent"
									[routerLink]="metadata.routing!.edit!.url(einreichung.id)"
									[queryParams]="{ clone: true }"
									style="width: 100%"
									*requireRole="'standard'">
									<mat-icon>content_copy</mat-icon>
									Einreichung duplizieren
								</a>
							</div>
							<div style="margin-bottom: 5px">
								<a
									mat-raised-button
									color="accent"
									*ngIf="canDoVorabpauschaleSeparieren$ | async"
									[routerLink]="metadata.routing!.edit!.url(einreichung.id)"
									[queryParams]="{ separationVorabp: true }"
									style="padding-right: 24px">
									<mat-icon>swap_horiz</mat-icon>
									Vorabpauschale separieren
								</a>
							</div>
							<div style="margin-bottom: 5px">
								<a
									mat-raised-button
									color="accent"
									*ngIf="canDoInterneKlaerung$ | async"
									(click)="interneKlaerung()"
									style="width: 100%">
									<mat-icon>quiz</mat-icon>
									Zur internen Klärung...
								</a>
							</div>
							<div style="margin-bottom: 5px">
								<a
									mat-raised-button
									color="accent"
									*ngIf="einreichung.personen.length > 0"
									(click)="generatePDF()"
									style="width: 100%">
									<mat-icon>picture_as_pdf</mat-icon>
									Personenstammblatt...
								</a>
							</div>
							<div style="margin-bottom: 5px">
								<a
									mat-raised-button
									color="accent"
									*ngIf="canDoRueckfrage$ | async"
									(click)="rueckfrage()"
									style="width: 100%">
									<mat-icon>question_answer</mat-icon>
									Rückfrage beim Einreicher...
								</a>
							</div>
						</div>
					</mat-menu>
				</div>
			</div>
			<div>
				<ng-container *requireRole="['standard']">
					<a mat-raised-button color="accent" (click)="fileNetImport()">
						<mat-icon>file_upload</mat-icon>
						Filenet Import...
					</a>
				</ng-container>

				<a
					mat-raised-button
					color="primary"
					*ngIf="canDoVorabpauschleEinfordern$ | async"
					(click)="vorabpauschaleEinfordern()"
					style="padding-right: 24px; margin-left: 3em">
					<mat-icon *ngIf="isLoading$ | async | not">picture_as_pdf</mat-icon>
					<mat-spinner
						*ngIf="isLoading$ | async"
						[diameter]="20"
						[color]="'accent'"
						style="display: inline-block; margin-right: 0.5em"></mat-spinner>
					Vorabpauschale
				</a>

				<a
					mat-raised-button
					color="primary"
					*ngIf="canDoAbbrechen$ | async"
					(click)="abbrechen()"
					style="padding-right: 24px">
					<mat-icon>delete_forever</mat-icon>
					Abbrechen
				</a>

				<a
					mat-raised-button
					color="primary"
					*ngIf="canDoKorrektur$ | async"
					[routerLink]="['/einreichung', einreichung.id, 'korrektur']"
					style="padding-right: 24px">
					<mat-icon>published_with_changes</mat-icon>
					Korrektur (DSGVO)
				</a>

				<ng-container *ngIf="aendernButtonState$ | async as stateAndTooltip">
					<a
						mat-raised-button
						color="primary"
						[routerLink]="metadata.routing!.edit!.url(einreichung.id)"
						*ngIf="stateAndTooltip.state !== 'Hidden'"
						[disabled]="stateAndTooltip.state === 'Disabled'"
						[matTooltip]="stateAndTooltip.tooltip"
						style="padding-right: 24px">
						<mat-icon>edit</mat-icon>
						Ändern
					</a>
				</ng-container>

				<a
					*ngIf="canDoNeuabrechnung$ | async"
					mat-raised-button
					color="primary"
					[routerLink]="metadata.routing.edit!.url(einreichung.id)"
					[queryParams]="{ neuabrechnung: 'alle' }">
					<mat-icon>functions</mat-icon>
					Neu abrechnen
				</a>

				<ng-container *ngIf="freigabeAnfordernButtonState$ | async as stateAndTooltip">
					<a
						mat-raised-button
						color="primary"
						*ngIf="stateAndTooltip.state !== 'Hidden'"
						[disabled]="stateAndTooltip.state === 'Disabled'"
						[matTooltip]="stateAndTooltip.tooltip"
						(click)="freigabeAnfordern$.next()"
						style="padding-right: 32px">
						<mat-icon>flaky</mat-icon>
						Freigabe anfordern
					</a>
				</ng-container>

				<ng-container *ngIf="freigebenButtonState$ | async as stateAndTooltip">
					<a
						mat-raised-button
						color="primary"
						*ngIf="stateAndTooltip.state !== 'Hidden'"
						[disabled]="stateAndTooltip.state === 'Disabled'"
						[matTooltip]="stateAndTooltip.tooltip"
						(click)="stateAndTooltip.state !== 'Disabled' && freigeben$.next()"
						style="padding-right: 24px; margin-left: 3em">
						<mat-icon>approval</mat-icon>
						Freigeben
					</a>
				</ng-container>
			</div>
		</div>

		<div class="page-header">
			<div>
				<h1>Einreichung {{ einreichung.nummer }}</h1>
				<div class="text-primary">{{ einreichung.status }}</div>
			</div>
		</div>

		<app-einreichung-status-bar [status]="einreichung.status"></app-einreichung-status-bar>

		<mat-tab-group>
			<mat-tab title="Details" label="Details">
				<app-einreichung-details
					[einreichung]="einreichung"
					[showHeader]="false"></app-einreichung-details>
				<hr />
				<div *ngIf="calculateGeschaeftsfaelle$ | async as vorabpauschale">
					<p>Vorabpauschale: {{ vorabpauschale.vorabpauschaleComment }}</p>
					<!-- <p>
						{{ vorabpauschale.vorabpauschaleStatus | vorabpauschaleStatus }}:
						{{ vorabpauschale.vorabpauschaleComment }}
					</p> -->
				</div>
			</mat-tab>
			<mat-tab *ngIf="!einreichung.einreicher" title="Personen" label="Personen">
				<app-einreichung-view-personen
					[einreichung]="einreichung"></app-einreichung-view-personen>
			</mat-tab>
			<mat-tab title="Positionen" label="Positionen">
				<app-einreichung-view-positionen
					[einreichung]="einreichung"></app-einreichung-view-positionen>
			</mat-tab>
			<mat-tab title="Notizen" label="Notizen">
				<app-einreichung-notizen
					[einreichungId]="einreichung?.id"></app-einreichung-notizen>
			</mat-tab>
			<mat-tab title="Geschäftsfälle" label="Geschäftsfälle">
				<ng-template
					mat-tab-label
					*ngIf="(freigabeAnfordernButtonState$ | async)?.state === 'Disabled'">
					<mat-icon class="error-text">warning</mat-icon>
					<span class="error-text">Geschäftsfälle</span>
				</ng-template>
				<app-geschaeftsfall-detail-list
					style="margin: 0.5em"
					*ngIf="calculateGeschaeftsfaelle$ | async as calculateGeschaeftsfaelleResult"
					[calculateGeschaeftsfaelleResult]="
						calculateGeschaeftsfaelleResult
					"></app-geschaeftsfall-detail-list>
			</mat-tab>
			<mat-tab title="Historie" label="Historie">
				<app-einreichung-status-historie
					style="margin: 0.5em"
					[einreichung]="einreichung"></app-einreichung-status-historie>
			</mat-tab>
		</mat-tab-group>
	</div>

	<ng-template #noContent>
		<mat-spinner *ngIf="pageContent.isLoading; else error"></mat-spinner>
	</ng-template>

	<ng-template #error>
		<div>Einreichung konnte nicht geladen werden!</div>
		<div *ngIf="pageContent.error">{{ pageContent.error }}</div>
	</ng-template>
</ng-container>
