import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content } from 'pdfmake/interfaces';
import { Konfiguration } from 'src/app/general/konfiguration/model';
import { KuerzelBezeichnungMap } from 'src/app/stammdaten/kuerzel-bezeichnung/kuerzel-bezeichnung-referenzlist.service';
import { formatDateForView } from 'src/app/utils';
import { Einreichung, Einreichungsperson } from '../model';
import { getPersonName } from '../pipes/person-name.pipe';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface PDFContext {
	laender: KuerzelBezeichnungMap;
	bundeslaender: KuerzelBezeichnungMap;
	ausweisarten: KuerzelBezeichnungMap;
	konfiguration: Konfiguration;
}

export function generatePersonenPDF(einreichung: Einreichung, context: PDFContext): void {
	pdfMake.createPdf(createContent(einreichung, context) as any).open();
}

export function createContent(einreichung: Einreichung, context: PDFContext) {
	return {
		content: [createHeader(einreichung, context), ...createPersonenContent(einreichung.personen, context)],
		styles: {
			'document-header': {
				fontSize: 22,
				bold: true,
				lineHeight: 1.5,
				alignment: 'center',
			},
			'person-header': {
				fontSize: 16,
				bold: true,
				lineHeight: 1.5,
				margin: [0, 20, 0, 10],
			},
		},
		defaultStyle: {
			lineHeight: 1.25,
		},
	};
}

function createHeader(einreichung: Einreichung, context: PDFContext): Content {
	// const link = `${context.konfiguration.basisablageOrt}/${einreichung.nummer}`;
	const link = einreichung.dokumentenLink;
	return [
		{
			text: `Personen in der EfA-Einreichung ${einreichung.nummer}`,
			style: 'document-header',
		},
		{
			text: [`Einreichungsdokumente abgelegt auf: `, { text: link, link, decoration: 'underline' }],
		},
	];
}

function createPersonenContent(personen: Einreichungsperson[], context: PDFContext): Content[] {
	return personen.map(p => createPersonContent(p, context));
}

function createPersonContent(person: Einreichungsperson, context: PDFContext): Content[] {
	const land = context.laender.mapOrDefault(person.land, '<FEHLT>');
	const bundesland =
		person.land === 'DE' ? '/ ' + context.bundeslaender.mapOrDefault(person.bundesland, '<FEHLT>') : '';
	return [
		{
			text: getPersonName(person),
			style: 'person-header',
		},
		{
			layout: 'lightHorizontalLines',
			table: {
				widths: ['auto', '*'],
				body: [
					[person.sapNummer ? 'SAP Nummer' : {text:'', colSpan:1},person.sapNummer ? person.sapNummer : {text:'', colSpan:1},  ],
					['Anrede', person.anrede],
					['Vorname', person.vorname],
					['Nachname', person.nachname],
					['Adresszusatz', person.adresszusatz],
					['Adresse / Straße', person.adresse],
					['Postleitzahl / Ort', `${person.postleitzahl} ${person.ort}`],
					['Land / Bundesland', `${land} ${bundesland}`],
					['PEP-Prüfungsnummer', `${person.kycPruefungsnummer ? person.kycPruefungsnummer : '<FEHLT>'}`],
					['Embargo-Prüfungsnummer', `${person.embargoPruefungsnummer ? person.embargoPruefungsnummer : '<FEHLT>'}`],
					['KYC-Prüfung positiv', `${person.kycPositiv ? 'JA' : 'NEIN'}`],
					['Geburtsdatum', formatDateForView(person.geburtsdatum)],
					['Geburtsort', person.geburtsort ?? ''],
					['Geburtsland', context.laender.map(person.geburtsland) ?? ''],
					['Staatsangehörigket', context.laender.map(person.staatsangehoerigkeit) ?? ''],
					['Ausweisart', context.ausweisarten.mapOrDefault(person.ausweisart, '')], // TODO translate
					['Ausweisnummer', person.ausweisnummer ?? ''],
					['Ausgestellt am', formatDateForView(person.ausweisAusgestelltAm)],
					['Ausgestellt von', person.ausweisAusgestelltVon ?? ''],
					['Gültig bis', formatDateForView(person.ausweisGueltigBis)],
				],
			},
		},
	];
}
