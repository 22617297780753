import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { SteuerUeberschreibung } from 'src/app/berechnung/geschaeftsfaelle';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { toExternalModel, toInternalModel } from 'src/app/utils';
import { Geschaeftsfall, geschaeftsfallMetadata } from '../../model';

@Component({
	selector: 'app-geschaeftsfall-steuern-ueberschreiben',
	templateUrl: './geschaeftsfall-steuern-ueberschreiben.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallSteuernUeberschreibenComponent extends BaseComponent implements OnChanges {
	public readonly metadata = geschaeftsfallMetadata;

	@Input() geschaeftsfall?: Geschaeftsfall;
	@Output() ueberschreiben$ = new EventEmitter<SteuerUeberschreibung>();

	public readonly form = new TypedForm<SteuerUeberschreibung>({
		solidaritaetszuschlag: createFormControl(this.metadata.fields.solidaritaetszuschlag, 0, {
			validators: [Validators.required],
			updateOn: 'blur',
		}),
		kapitalertragssteuer: createFormControl(this.metadata.fields.kapitalertragssteuer, 0, {
			validators: [Validators.required],
			updateOn: 'blur',
		}),
		kirchensteuer: createFormControl(this.metadata.fields.kirchensteuer, 0, {
			validators: [Validators.required],
			updateOn: 'blur',
		}),
		zinsabschlagsteuer: createFormControl(this.metadata.fields.zinsabschlagsteuer, 0, {
			validators: [Validators.required],
			updateOn: 'blur',
		}),
	});

	public originaleSteuern?: number;

	constructor() {
		super();

		this.registerSubscription(
			this.form.values$.subscribe(v => {
				if (this.form.valid) {
					this.ueberschreiben$.emit({
						kapitalertragssteuer: toInternalModel(v.kapitalertragssteuer),
						zinsabschlagsteuer: toInternalModel(v.zinsabschlagsteuer),
						solidaritaetszuschlag: toInternalModel(v.solidaritaetszuschlag),
						kirchensteuer: toInternalModel(v.kirchensteuer),
					});
				}
			})
		);
	}

	ngOnChanges(): void {
		if (!this.geschaeftsfall) return;

		this.originaleSteuern =
			this.geschaeftsfall.kapitalertragssteuerCalculated +
			this.geschaeftsfall.solidaritaetszuschlagCalculated +
			this.geschaeftsfall.kirchensteuerCalculated +
			this.geschaeftsfall.zinsabschlagsteuerCalculated;

		this.form.setValueTyped(
			{
				solidaritaetszuschlag: toExternalModel(this.geschaeftsfall.solidaritaetszuschlag ?? 0),
				kapitalertragssteuer: toExternalModel(this.geschaeftsfall.kapitalertragssteuer ?? 0),
				kirchensteuer: toExternalModel(this.geschaeftsfall.kirchensteuer ?? 0),
				zinsabschlagsteuer: toExternalModel(this.geschaeftsfall.zinsabschlagsteuer ?? 0),
			},
			{ emitEvent: false }
		);
	}
}
