import { Injectable } from '@angular/core';
import { EMPTY, Observable, switchMap } from 'rxjs';
import * as ExcelJS from 'ExcelJS-workaround';
import { MatDialog } from '@angular/material/dialog';
import { LoadExcelFileComponent } from './load-excel-file/load-excel-file.component';
import { ExcelChooseWorksheetComponent } from './excel-choose-worksheet/excel-choose-worksheet.component';

@Injectable({
	providedIn: 'root',
})
export class ExcelFileLoadService {
	constructor(private readonly matDialog: MatDialog) {}

	public uploadExcel$(): Observable<ExcelJS.Workbook | undefined> {
		return this.matDialog.open(LoadExcelFileComponent).afterClosed() as Observable<ExcelJS.Workbook | undefined>;
	}

	public uploadExcelAndSelectWorksheet$(): Observable<ExcelJS.Worksheet | undefined> {
		return this.uploadExcel$().pipe(
			switchMap((workbook: ExcelJS.Workbook | undefined) => {
				if (!workbook) return EMPTY;
				return this.matDialog
					.open(ExcelChooseWorksheetComponent, { data: { workbook } })
					.afterClosed() as Observable<ExcelJS.Worksheet | undefined>;
			})
		);
	}
}
