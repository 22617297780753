import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-simple-dialog',
	templateUrl: './simple-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: SimpleDialogContent) {}
}

export interface SimpleDialogContent {
	title?: string;
	message?: string;
	messageHtml?: string;
	buttonsLeft: ButtonDefinition[];
	buttonsRight: ButtonDefinition[];
}

export interface ButtonDefinition {
	label: string;
	value?: string;
	color?: string;
	icon?: string;
}
