<mat-form-field style="width: 100%">
	<mat-label>{{ resolvedLabel }}</mat-label>
	<app-field-help-icon
		matPrefix
		class="input-field-icons"
		[helpText]="metadata?.description"></app-field-help-icon>
	<input
		autocomplete="off"
		type="text"
		[placeholder]="resolvedPlaceholder"
		matInput
		[formControl]="textControl"
		(focus)="htmlControl.select()"
		(blur)="reformatValue(); onTouched()"
		style="width: 100%; text-align: right"
		[required]="!!(isRequired$ | async)"
		#htmlControl />
	<div matSuffix style="margin-left: 0.5em" class="numeric-input-suffix">
		<span matPrefix style="margin-right: 0.5em">{{ symbol }}</span>
		<mat-icon
			(click)="clear()"
			class="input-field-icons suffix-icon"
			*ngIf="showClearButton$ | async"
			matTooltip="Zurücksetzen">
			close
		</mat-icon>
	</div>
	<mat-error>{{ textControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
