import { KistamUpdateResult } from './../einreichung.service';
import { logger } from 'src/logger';
import { BehaviorSubject, Subscription, take, combineLatest, map, Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { EinreichungService } from 'src/app/bewegungsdaten/einreichung.service';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from 'src/app/general/base-component';

@Component({
	selector: 'app-einreichung-fileimport-modal',
	templateUrl: './einreichung-fileimport-modal.component.html',
	styleUrls: ['./einreichung-fileimport-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungFileimportModalComponent
	extends BaseComponent
	implements OnInit, OnDestroy
{
	readonly parseError$ = new BehaviorSubject<string>('');
	readonly plausiErrors$ = new BehaviorSubject<string[]>([]);
	readonly file$ = new BehaviorSubject<File | null>(null);

	readonly isFileSelected$ = combineLatest([
		this.file$,
		this.parseError$,
		this.plausiErrors$,
	]).pipe(
		map(([file, parseError, plausiErrors]) => {
			if (!file) return false;
			if (parseError) return false;
			if (plausiErrors.length > 0) return false;
			return true;
		})
	);

	private result4KistamCsvImport$Subscr!: Subscription;
	result4KistamCsvImport: Array<{ nr: string }> = [];

	constructor(
		private ref: MatDialogRef<EinreichungFileimportModalComponent>,
		public einreichungService: EinreichungService
	) {
		super();
	}

	ngOnDestroy(): void {
		if (this.result4KistamCsvImport$Subscr) {
			this.result4KistamCsvImport$Subscr.unsubscribe();
		}
	}

	public get result4KistamCsvImport$(): any {
		return this.einreichungService.result4KistamCsvImport$;
	}

	protected get text4CancelBtn(): string {
		let ret = 'Abbrechen';
		if (this.einreichungService.isKistamImportDone$.value === true) {
			ret = 'Ok';
		}
		return ret;
	}

	protected get showImportBtn(): boolean {
		return this.text4CancelBtn === 'Abbrechen';
	}

	ngOnInit() {
		this.result4KistamCsvImport$Subscr =
			this.einreichungService.result4KistamCsvImport$.subscribe(result => {
				this.result4KistamCsvImport = result.map(x => {
					return { nr: x.number.toString() };
				});
			});
		this.registerSubscription(
			this.ref.afterClosed().subscribe(() => {
				console.log('Kistam Import-Dialog wird geschlossen.');
				this.clearDialogState();
			})
		);
	}

	onCancel() {
		this.ref.close();
	}

	onSubmit(form: NgForm) {
		logger.log('Submit file!');
		this.einreichungService.importKistamCsv(this.file$.value as File);
	}

	/**
	 * Parse selected file and make some plausi checks
	 * @param event source element
	 */
	onFileSelected(event: any) {
		logger.log('Enter onFileSelected: ', event);

		this.clearDialogState();
		this.einreichungService.result4KistamCsvImport$.next([]);
		const selectedFile = (event.target as HTMLInputElement).files?.item(0);
		const isCorrectFileSelected = selectedFile?.name.endsWith('csv');

		if (isCorrectFileSelected) {
			this.file$.next(selectedFile as File);
			this.einreichungService
				.parseKistamCsv$(selectedFile as File)
				.pipe(take(1))
				.subscribe(parsedData => {
					if (
						!Array.isArray(parsedData) ||
						(parsedData.length &&
							Array.isArray(parsedData[0]) &&
							parsedData[0].length == 1)
					) {
						logger.error(parsedData);
						this.parseError$.next('Datei wurde nicht als CSV erkannt!');
						return;
					}
					this.plausiErrors$.next(
						this.einreichungService.plausibiltyCheckOfKistamCsvData(parsedData)
					);
				});
		} else {
			this.parseError$.next('Keine CSV-Datei ausgewählt!');
		}
	}

	private clearDialogState(): void {
		this.parseError$.next('');
		this.plausiErrors$.next([]);
		this.einreichungService.result4KistamCsvImport$.next([]);
		this.file$.next(null);
		this.einreichungService.isKistamImportDone$.next(false);
	}

	/**
	 * returns the class name depending on the success state
	 * @returns the name of the css class
	 */
	getImportResultCssClass(success: string = 'success') {
		if (success === 'success') return 'importResultSuccess';
		return 'importResultError';
	}

	/**
	 * check whether the import result has also a error
	 */
	hasErrorImportResults$(): Observable<boolean> {
		const ret = this.einreichungService.result4KistamCsvImport$.pipe(
			map(x => {
				const errors = x.filter(y => y.success === KistamUpdateResult.SUCCESS_saveFailed);
				return errors.length > 0;
			})
		);
		return ret;
	}
}
