import { DataSource } from '@angular/cdk/collections';
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Self,
	Optional,
	ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, FormArray, NgControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/general/base-component';
import { createFormControl } from 'src/app/model';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import {
	ZuVersteuerndeVorabpauschale,
	zuVersteuerndeVorabpauschaleMetadata,
} from '../../model';

@Component({
	selector: 'app-gattung-edit-vorabpauschale',
	templateUrl: './gattung-edit-vorabpauschale.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungEditVorabpauschaleComponent
	extends BaseComponent
	implements ControlValueAccessor
{
	private onChange: (v: ZuVersteuerndeVorabpauschale[]) => void = () => {
		// This is intentional
	};

	public readonly metadata = zuVersteuerndeVorabpauschaleMetadata;
	public readonly dataSource = new MatTableDataSource<
		TypedForm<ZuVersteuerndeVorabpauschale>
	>([
		new TypedForm<ZuVersteuerndeVorabpauschale>({
			jahr: createFormControl(this.metadata.fields.jahr),
			betragVorabp: createFormControl(this.metadata.fields.betragVorabp),
			betragStpflAnteil: createFormControl(this.metadata.fields.betragStpflAnteil),
		}),
	]);

	public readonly displayedColumns = ['jahr', 'betragVorabp', 'betragStpflAnteil', 'action'];

	constructor(
		@Optional() @Self() public readonly ngControl: NgControl,
		private readonly changeDetector: ChangeDetectorRef
	) {
		super();

		// this makes NG_VALUE_ACCESSOR unnecessary
		if (!this.ngControl)
			throw new Error('No ngControl! Did you add formControlName or formControl?');
		this.ngControl.valueAccessor = this;
	}

	private alreadyWritten = false;

	writeValue(data?: ZuVersteuerndeVorabpauschale[]): void {
		if (this.alreadyWritten) return;

		if (!data) return;

		this.alreadyWritten = true;
		if (!data) data = [];
		this.dataSource.data = [];
		data.forEach(item => this.addYear(item));
	}

	registerOnChange(fn: (v: ZuVersteuerndeVorabpauschale[]) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		// noop
	}

	ngOnInit(): void {
		// This is intentional
	}

	addYear(data?: ZuVersteuerndeVorabpauschale): void {
		var newTypedForm = new TypedForm<ZuVersteuerndeVorabpauschale>({
			jahr: createFormControl(this.metadata.fields.jahr),
			betragVorabp: createFormControl(this.metadata.fields.betragVorabp),
			betragStpflAnteil: createFormControl(this.metadata.fields.betragStpflAnteil),
		});

		if (data) {
			newTypedForm.setValueTyped(data);
		}

		this.registerSubscription(
			newTypedForm.valueChanges.subscribe(() => this.reevaluateValues())
		);

		this.dataSource.data = [...this.dataSource.data, newTypedForm];
	}

	reevaluateValues(): void {
		if (!this.dataSource.data.every(i => i.valid)) {
			this.ngControl.control?.setErrors({ invalid: true });
		} else {
			this.onChange(this.dataSource.data.map(t => t.typedValue));
		}
	}

	deleteYear(toRemove: any): void {
		this.dataSource.data = this.dataSource.data.filter(a => a !== toRemove);
		this.onChange(this.dataSource.data.map(t => t.typedValue));
	}
}
