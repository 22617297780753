import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	concat,
	debounceTime,
	EMPTY,
	forkJoin,
	map,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { GattungService } from 'src/app/stammdaten/gattung/gattung.service';
import { DotationInfo, Gattung } from 'src/app/stammdaten/gattung/model';
import { Einreichungsposition } from '../model';

@Component({
	selector: 'app-einreichung-dotation-info',
	templateUrl: './einreichung-dotation-info.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungDotationInfoComponent {
	@Input() set positionen(value: Einreichungsposition[]) {
		this.positionen$.next(value ? value : []);
	}

	private readonly positionen$ = new BehaviorSubject<Einreichungsposition[]>([]);

	public readonly dotationsueberzuginfo$: Observable<string> = this.positionen$.pipe(
		debounceTime(100),
		switchMap((positionen: Einreichungsposition[]) => {
			if (!positionen || positionen.length === 0) return '';
			const gattungIds = new Set<string>(positionen.map(p => p.gattungId!).filter(id => id));
			// für jede Gattung hole die DotationInfo ab
			return concat(
				of('Ermittle Dotationsinformation...'),
				forkJoin(
					[...gattungIds].map(id => {
						const gattung = this.gattungService.list$.value.find(g => g.id === id);
						if (!gattung) return EMPTY;

						return this.gattungService.dotationInfo$(id).pipe(
							map(dotationInfo => {
								if (!dotationInfo) return null;
								return { dotationInfo, gattung };
							}),
							catchError(err =>
								of({
									dotationInfo: {
										anzahlAnfang: -1,
										anzahlEingeloest: 0,
										anzahlVorlaeufigEingeloest: 0,
									},
									gattung,
								})
							)
						);
					})
				).pipe(
					map((dotationInfos: ({ dotationInfo: DotationInfo; gattung: Gattung } | null)[]) => {
						return dotationInfos
							.filter(
								di =>
									di &&
									(di.dotationInfo.anzahlAnfang === -1 ||
										di.dotationInfo.anzahlAnfang < di.dotationInfo.anzahlEingeloest ||
										di.dotationInfo.anzahlAnfang < di.dotationInfo.anzahlVorlaeufigEingeloest)
							)
							.map(di =>
								di?.dotationInfo.anzahlAnfang === -1
									? `Dotation für die Gattung ${di.gattung.displayName} konnte nicht ermittelt werden!`
									: `Dotation überzogen bei der Gattung ${di!.gattung.displayName}`
							)
							.join(' ');
					})
				)
			);
		})
	);

	constructor(private readonly gattungService: GattungService) {}
}
