<mat-form-field style="width: 100%">
	<mat-label *ngIf="kistamCsvFile.value; else newFile">
		{{ kistamCsvFile.value.substring(kistamCsvFile.value.lastIndexOf('\\') + 1) }}
	</mat-label>
	<ng-template #newFile>
		<mat-label>{{ placeholder }}</mat-label>
	</ng-template>
	<input matInput disabled />
	<button mat-icon-button matSuffix (click)="fileInput.click(); (false)">
		<mat-icon>attach_file</mat-icon>
	</button>
	<input
		hidden
		[formControl]="kistamCsvFile"
		(input)="onFileSelected($event)"
		(click.stop)="onClickFileinput($event)"
		#fileInput
		[accept]="accept"
		type="file"
		id="file" />
</mat-form-field>
