<h1 mat-dialog-title>Geschäftsfall stornieren</h1>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="accept()">
	<div>
		<mat-slide-toggle formControlName="buchungenStornieren">Buchungen stornieren</mat-slide-toggle>
	</div>
	<div>
		<app-text-input
			formControlName="abbruchStornoGrund"
			[metadata]="metadata.fields.abbruchStornoGrund"></app-text-input>
	</div>
</form>

<div mat-dialog-actions style="display: flex; gap: 1em">
	<button mat-raised-button color="accent" (click)="tryCancel()">
		<mat-icon>close</mat-icon>
		Fenster schliessen
	</button>
	<span style="flex: 1"></span>
	<button mat-raised-button color="primary" (click)="accept()" [disabled]="cannotAccept$ | async">
		<mat-icon>check</mat-icon>
		Storno bestätigen
	</button>
</div>
