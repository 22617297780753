import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'booleanText',
})
export class BooleanTextPipe implements PipeTransform {
	transform(value: boolean | undefined | null, showNein: boolean = false): string {
		if (value === true) return 'Ja';
		if (value === false && showNein === true) return 'Nein';
		return '--';
	}
}
