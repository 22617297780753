<h1 mat-dialog-title>
	{{ gattung.isin }} -- {{ gattung.gattungsbezeichnung }} ({{ gattung.status }})
</h1>

<div mat-dialog-content style="height: 100%">
	<app-gattung-details [gattung]="gattung" [showHeader]="false"></app-gattung-details>
</div>

<div mat-dialog-actions style="display: flex">
	<span style="flex: 1"></span>
	<button
		mat-raised-button
		color="accent"
		mat-dialog-close
		[routerLink]="metadata.routing.view!.url(gattung.id)">
		<mat-icon>visibility</mat-icon>
		Ansicht
	</button>
	<button
		mat-raised-button
		color="primary"
		mat-dialog-close
		[routerLink]="metadata.routing.edit!.url(gattung.id)"
		*requireRole="'standard'">
		<mat-icon>edit</mat-icon>
		Bearbeiten
	</button>
</div>
