<h1 mat-dialog-title>CSV Import Kistam</h1>
<form (ngSubmit)="onSubmit(f)" #f="ngForm">
	<div mat-dialog-content>
		<app-file-upload
			(fileSelected)="onFileSelected($event)"
			[clearSelectionOnOpenFiledialog]="true"
			accept=".csv, text/csv"></app-file-upload>
		<mat-error *ngIf="parseError$ | async">{{ parseError$.value }}</mat-error>
		<ul>
			<li *ngFor="let plausiError of plausiErrors$ | async" class="plausiErrors">
				{{ plausiError }}
			</li>
		</ul>
		<ul>
			<li
				*ngFor="let result of einreichungService.result4KistamCsvImport$ | async"
				[ngClass]="getImportResultCssClass(result.success)">
				{{ result | kistamImportResultText }}
			</li>
		</ul>
		<div *ngIf="hasErrorImportResults$() | async" class="importResultErrorReasons">
			<strong>Unbekannter Importfehler</strong>
			Evtl. fehlt die Angabe einer wirtschaftlich berechtigten Person an der Einreichung.
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="accent" (click)="onCancel(); (false)">
			{{ text4CancelBtn }}
		</button>
		<button
			[disabled]="isFileSelected$ | async | not"
			*ngIf="showImportBtn"
			mat-raised-button
			color="primary"
			type="submit">
			Importieren
		</button>
	</div>
</form>
