import {
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	AfterViewInit,
	TrackByFunction,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, map, withLatestFrom } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { CurrentUserService } from 'src/app/general/current-user.service';
import { BenutzerRolle } from 'src/app/general/roles';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { FinanzinstitutService } from '../../finanzinstitut/finanzinstitut.service';
import { finanzinstitutMetadata } from '../../finanzinstitut/model';
import { GattungService, Neuausschuettung } from '../gattung.service';
import { gattungMetadata } from '../model';

@Component({
	selector: 'app-gattung-neu-ausschuettungen',
	templateUrl: './gattung-neu-ausschuettungen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GattungNeuAusschuettungenComponent
	extends BaseComponent
	implements AfterViewInit
{
	@ViewChild(MatPaginator) paginator?: MatPaginator;
	@ViewChild(MatSort) sort?: MatSort;

	public readonly dataSource = new MatTableDataSource<NeuausschuettungListItem>();
	public readonly metadata = gattungMetadata;
	public readonly finanzinstitutMetadata = finanzinstitutMetadata;
	public readonly trackBy: TrackByFunction<Neuausschuettung> = (index, item) => item.id;
	public readonly displayedColumns = [
		'isin',
		'name',
		'zahlbarkeitstag',
		'emittent',
		'actions',
	];
	public readonly filterForm = new TypedForm<{
		isin: string;
		wkz: string;
		name: string;
		emittent: string;
	}>({
		isin: new UntypedFormControl(),
		wkz: new UntypedFormControl(),
		name: new UntypedFormControl(),
		emittent: new UntypedFormControl(),
	});

	constructor(
		private readonly gattungService: GattungService,
		private readonly institutService: FinanzinstitutService,
		private readonly currentUserService: CurrentUserService,
		private readonly router: Router
	) {
		super();

		const filterFormValues$ = this.filterForm.values$.pipe(
			debounceTime(200),
			map(v => ({
				...v,
				isin: v.isin?.toLocaleUpperCase() ?? '',
				name: v.name?.toLocaleUpperCase() ?? '',
				emittent: v.emittent?.toLocaleUpperCase() ?? '',
			}))
		);

		let filterIndex = 0;
		this.registerSubscription(
			filterFormValues$.subscribe(filterValues => {
				this.dataSource.filterPredicate = element => {
					if (filterValues.isin) {
						if (!element.isinUpperCase.includes(filterValues.isin)) return false;
					}

					if (filterValues.wkz) {
						const wkzBezeichnung = element.gattung.wkz
							? 'Mantel'.toLocaleUpperCase()
							: 'Kupon'.toLocaleUpperCase();
						if (!wkzBezeichnung.includes(filterValues.wkz.toLocaleUpperCase()))
							return false;
					}

					if (filterValues.name) {
						if (!element.nameUpperCase.includes(filterValues.name)) return false;
					}

					if (filterValues.emittent) {
						if (!element.institutBezeichnungUpperCase.includes(filterValues.emittent))
							return false;
					}

					return true;
				};
				filterIndex++;
				this.dataSource.filter = filterIndex.toFixed();
			})
		);
	}

	ngAfterViewInit(): void {
		this.dataSource.paginator = this.paginator!;
		this.dataSource.sort = this.sort!;
		setTimeout(() => {
			this.registerSubscription(
				this.gattungService.fehlendeNeuausschuettungen$
					.pipe(withLatestFrom(this.institutService.list$))
					.subscribe(([list, institute]) => {
						this.dataSource.data = list.map(v => {
							const institut = institute.find(i => i.id === v.gattung.emittentId);
							const institutBezeichnung = institut?.displayName || '';
							return {
								...v,
								isinUpperCase: v.gattung.isin.toLocaleUpperCase(),
								institutBezeichnungUpperCase: institutBezeichnung.toLocaleUpperCase(),
								institutBezeichnung,
								nameUpperCase: v.gattung.gattungsbezeichnung.toLocaleUpperCase(),
							} as NeuausschuettungListItem;
						});
					})
			);
		});
	}

	open(id: string, tagNumber: number) {
		if (this.currentUserService.hasAnyOfRoles([BenutzerRolle.Standard])) {
			this.router.navigate([this.metadata.routing.edit!.url(id)], {
				queryParams: { neuAusschuettung: tagNumber },
			});
		}
	}
}

interface NeuausschuettungListItem extends Neuausschuettung {
	isinUpperCase: string;
	institutBezeichnungUpperCase: string;
	institutBezeichnung: string;
	nameUpperCase: string;
}
