import { MatMenuModule } from '@angular/material/menu';
import { EinreichungFileimportModalComponent } from './einreichung-fileimport-modal/einreichung-fileimport-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StammdatenModule } from '../stammdaten/stammdaten.module';
import { EinreichungArchivComponent } from './archiv/einreichung-archiv/einreichung-archiv.component';
import { BuchungenArchivComponent } from './buchungen/buchungen-archiv/buchungen-archiv.component';
import { GeschaeftsfallBuchungenPopupComponent } from './buchungen/geschaeftsfall-buchungen-popup/geschaeftsfall-buchungen-popup.component';
import { GeschaeftsfallBuchungenComponent } from './buchungen/geschaeftsfall-buchungen/geschaeftsfall-buchungen.component';
import { EinreichungDetailsModalComponent } from './einreichung-details-modal/einreichung-details-modal.component';
import { EinreichungDetailsComponent } from './einreichung-details/einreichung-details.component';
import { EinreichungDotationInfoComponent } from './einreichung-dotation-info/einreichung-dotation-info.component';
import { EinreichungEditPagePositionenComponent } from './einreichung-edit-page/einreichung-edit-page-positionen/einreichung-edit-page-positionen.component';
import { EinreichungEditPageComponent } from './einreichung-edit-page/einreichung-edit-page.component';
import { EinreichungEditPersonPopupComponent } from './einreichung-edit-page/einreichung-edit-person-popup/einreichung-edit-person-popup.component';
import { EinreichungEditPersonenComponent } from './einreichung-edit-page/einreichung-edit-personen/einreichung-edit-personen.component';
import { EinreichungEditPositionPopupComponent } from './einreichung-edit-page/einreichung-edit-position-popup/einreichung-edit-position-popup.component';
import { EinreichungSchnellerfassungComponent } from './einreichung-edit-page/einreichung-schnellerfassung/einreichung-schnellerfassung.component';
import { EinreichungKorrekturPopupComponent } from './einreichung-korrektur/einreichung-korrektur-popup/einreichung-korrektur-popup.component';
import { EinreichungKorrekturComponent } from './einreichung-korrektur/einreichung-korrektur/einreichung-korrektur.component';
import { EinreichungListPageComponent } from './einreichung-list-page/einreichung-list-page.component';
import { EinreichungListComponent } from './einreichung-list/einreichung-list.component';
import { EinreichungStatusBarComponent } from './einreichung-status-bar/einreichung-status-bar.component';
import { EinreichungStatusHistorieComponent } from './einreichung-status-historie/einreichung-status-historie.component';
import { EinreichungAbbrechenPopupComponent } from './einreichung-view-page/einreichung-abbrechen-popup/einreichung-abbrechen-popup.component';
import { EinreichungFreigabeAnfordernPopupComponent } from './einreichung-view-page/einreichung-freigabe-anfordern-popup/einreichung-freigabe-anfordern-popup.component';
import { EinreichungFreigebenPopupComponent } from './einreichung-view-page/einreichung-freigeben-popup/einreichung-freigeben-popup.component';
import { EinreichungRueckfragePopupComponent } from './einreichung-view-page/einreichung-rueckfrage-popup/einreichung-rueckfrage-popup.component';
import { EinreichungViewPageComponent } from './einreichung-view-page/einreichung-view-page.component';
import { EinreichungViewPersonenComponent } from './einreichung-view-page/einreichung-view-personen/einreichung-view-personen.component';
import { EinreichungViewPositionenComponent } from './einreichung-view-page/einreichung-view-positionen/einreichung-view-positionen.component';
import { FreigabeAnfordernSteuernUeberschreibungComponent } from './einreichung-view-page/freigabe-anfordern-steuern-ueberschreibung/freigabe-anfordern-steuern-ueberschreibung.component';
import { GeschaeftsfallDetailListComponent } from './geschaeftsfall/geschaeftsfall-detail-list/geschaeftsfall-detail-list.component';
import { GeschaeftsfallDetailsModalComponent } from './geschaeftsfall/geschaeftsfall-details-modal/geschaeftsfall-details-modal.component';
import { GeschaeftsfallListPageComponent } from './geschaeftsfall/geschaeftsfall-list-page/geschaeftsfall-list-page.component';
import { GeschaeftsfallListComponent } from './geschaeftsfall/geschaeftsfall-list/geschaeftsfall-list.component';
import { GeschaeftsfallAbbrechenPopupComponent } from './geschaeftsfall/geschaeftsfall-page/geschaeftsfall-abbrechen-popup/geschaeftsfall-abbrechen-popup.component';
import { GeschaeftsfallAbschliessenPopupComponent } from './geschaeftsfall/geschaeftsfall-page/geschaeftsfall-abschliessen-popup/geschaeftsfall-abschliessen-popup.component';
import { GeschaeftsfallPageComponent } from './geschaeftsfall/geschaeftsfall-page/geschaeftsfall-page.component';
import { GeschaeftsfallStornoPopupComponent } from './geschaeftsfall/geschaeftsfall-page/geschaeftsfall-storno-popup/geschaeftsfall-storno-popup.component';
import { GeschaeftsfallSteuernUeberschreibenComponent } from './geschaeftsfall/geschaeftsfall-steuern-ueberschreiben/geschaeftsfall-steuern-ueberschreiben.component';
import { GeschaeftsfallComponent } from './geschaeftsfall/geschaeftsfall/geschaeftsfall.component';
import { SteuerUeberschreibungAnzeigeComponent } from './geschaeftsfall/geschaeftsfall/steuer-ueberschreibung-anzeige/steuer-ueberschreibung-anzeige.component';
import { InkassoBearbeitungComponent } from './geschaeftsfall/inkasso-bearbeitung/inkasso-bearbeitung.component';
import { EinreichungNotizenComponent } from './notizen/einreichung-notizen/einreichung-notizen.component';
import { EinreicherartPipe } from './pipes/einreicherart.pipe';
import { IsEditablePipe } from './pipes/is-editable.pipe';
import { OppositionspruefungTextPipe } from './pipes/oppositionspruefung-text.pipe';
import { PersonNamePipe } from './pipes/person-name.pipe';
import { PersonRolePipe } from './pipes/person-role.pipe';
import { ReligionszugehoerigkeitPipe } from './pipes/religionszugehoerigkeit.pipe';
import { VorabpauschaleStatusPipe } from './pipes/vorabpauschale-status.pipe';
import { GeschaeftsfallSteuernComponent } from './steuern/geschaeftsfall-steuern/geschaeftsfall-steuern.component';
import { SteuernPopupComponent } from './steuern/steuern-popup/steuern-popup.component';
import { TagesverarbeitungUebersichtComponent } from './tagesverarbeitung/tagesverarbeitung-uebersicht/tagesverarbeitung-uebersicht.component';
import { UltimoEinreichungenComponent } from './ultimo/ultimo-einreichungen/ultimo-einreichungen.component';
import { UltimoKestComponent } from './ultimo/ultimo-kest/ultimo-kest.component';
import { UltimoKistComponent } from './ultimo/ultimo-kist/ultimo-kist.component';
import { UltimoKontenComponent } from './ultimo/ultimo-konten/ultimo-konten.component';
import { UltimoOverviewComponent } from './ultimo/ultimo-overview/ultimo-overview.component';
import { EinreichungFileimportCsvPreviewComponent } from './einreichung-fileimport-csv-preview/einreichung-fileimport-csv-preview.component';
import { KistamImportResultTextPipe } from './pipes/kistamImportResultText.pipe';
import { EinreichungFilenetImportComponent } from './einreichung-filenet-import/einreichung-filenet-import.component';
import { EinreichungViewPersonenKycchecksComponent } from './einreichung-view-page/einreichung-view-personen-kycchecks/einreichung-view-personen-kycchecks.component';
import { UltimoGeschaeftsfaelleComponent } from './ultimo/ultimo-geschaeftsfaelle/ultimo-geschaeftsfaelle.component';
import { UltimoRisikoklassenbewertungComponent } from './ultimo/ultimo-risikoklassenbewertung/ultimo-risikoklassenbewertung.component';

@NgModule({
	declarations: [
		EinreichungListPageComponent,
		EinreichungEditPageComponent,
		EinreichungListComponent,
		EinreichungEditPagePositionenComponent,
		EinreichungEditPositionPopupComponent,
		EinreichungViewPageComponent,
		EinreichungDetailsComponent,
		EinreichungDetailsModalComponent,
		EinreichungEditPersonenComponent,
		EinreichungEditPersonPopupComponent,
		PersonNamePipe,
		PersonRolePipe,
		EinreichungStatusBarComponent,
		EinreichungViewPersonenComponent,
		EinreichungViewPositionenComponent,
		EinreichungRueckfragePopupComponent,
		EinreichungArchivComponent,
		EinreichungNotizenComponent,
		GeschaeftsfallListComponent,
		GeschaeftsfallComponent,
		EinreichungFreigabeAnfordernPopupComponent,
		EinreicherartPipe,
		IsEditablePipe,
		OppositionspruefungTextPipe,
		GeschaeftsfallDetailListComponent,
		GeschaeftsfallListPageComponent,
		GeschaeftsfallDetailsModalComponent,
		EinreichungFreigebenPopupComponent,
		GeschaeftsfallPageComponent,
		InkassoBearbeitungComponent,
		GeschaeftsfallAbschliessenPopupComponent,
		EinreichungAbbrechenPopupComponent,
		GeschaeftsfallAbbrechenPopupComponent,
		SteuernPopupComponent,
		GeschaeftsfallSteuernComponent,
		GeschaeftsfallBuchungenComponent,
		GeschaeftsfallBuchungenPopupComponent,
		BuchungenArchivComponent,
		GeschaeftsfallSteuernUeberschreibenComponent,
		FreigabeAnfordernSteuernUeberschreibungComponent,
		SteuerUeberschreibungAnzeigeComponent,
		TagesverarbeitungUebersichtComponent,
		EinreichungStatusHistorieComponent,
		EinreichungDotationInfoComponent,
		UltimoOverviewComponent,
		UltimoKestComponent,
		UltimoKistComponent,
		UltimoKontenComponent,
		UltimoEinreichungenComponent,
		EinreichungSchnellerfassungComponent,
		GeschaeftsfallStornoPopupComponent,
		EinreichungKorrekturComponent,
		EinreichungKorrekturPopupComponent,
		VorabpauschaleStatusPipe,
		ReligionszugehoerigkeitPipe,
		EinreichungFileimportModalComponent,
		EinreichungFileimportCsvPreviewComponent,
		KistamImportResultTextPipe,
		EinreichungFilenetImportComponent,
  		EinreichungViewPersonenKycchecksComponent,
    	UltimoGeschaeftsfaelleComponent,
     UltimoRisikoklassenbewertungComponent,
	],
	providers: [],
	imports: [
		CommonModule,
		AppRoutingModule,
		HttpClientModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMenuModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatExpansionModule,
		MatChipsModule,
		MatTooltipModule,
		MatTabsModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatCardModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		StammdatenModule,
		SharedModule,
		DragDropModule,
	],
})
export class BewegungsdatenModule { }
