import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnChanges,
	Attribute,
} from '@angular/core';
import { GeschaeftsfallMitPositionen } from '../../model';
import { Buchung } from '../model';

@Component({
	selector: 'app-geschaeftsfall-buchungen',
	templateUrl: './geschaeftsfall-buchungen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeschaeftsfallBuchungenComponent implements OnChanges {
	@Input() geschaeftsfallMitPositionen?: GeschaeftsfallMitPositionen;

	constructor(@Attribute('header') public readonly header: string) {}

	public buchungen: BuchungExtended[] = [];

	ngOnChanges(): void {
		if (this.geschaeftsfallMitPositionen) {
			this.buchungen = this.geschaeftsfallMitPositionen.geschaeftsfall.buchungen
				.map(this._createBuchungExtended)
				.sort((b1, b2) => Math.sign(b1.buchungsnummer - b2.buchungsnummer));
		} else {
			this.buchungen = [];
		}
	}
	private _createBuchungExtended(b: Buchung): BuchungExtended {
		return {
			...b,
			betrag: Math.abs(b.betrag),
			sollHaben: b.betrag < 0 ? 'S' : 'H',
		};
	}
}

interface BuchungExtended extends Buchung {
	sollHaben: 'H' | 'S';
}
