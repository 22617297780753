<mat-form-field style="width: 100%">
	<div matPrefix>
		<app-field-help-icon [helpText]="metadata?.description"></app-field-help-icon>
	</div>
	<mat-label>{{ resolvedLabel }}</mat-label>
	<mat-select
		[formControl]="selectControl"
		[ngStyle]="{ width: (selectControlNotEmpty$ | async) ? '95%' : '100%' }"
		[placeholder]="resolvedPlaceholder"
		[panelClass]="panelClass"
		#matSelectElement
		[required]="!!(isRequired$ | async)">
		<mat-option *ngFor="let item of options$ | async" [value]="item.kuerzel">
			{{ item.kuerzel }} - {{ item.bezeichnung }}
		</mat-option>
	</mat-select>
	<div
		matSuffix
		style="margin-left: 0.5em; width: 1em"
		class="kuerzel-bezeichnung-choose-suffix">
		<mat-icon
			(click.stop)="clear()"
			class="suffix-icon"
			*ngIf="selectControlNotEmpty$ | async"
			matTooltip="Zurücksetzen">
			close
		</mat-icon>
	</div>
	<mat-error>{{ selectControl | fieldErrorMessage$ | async }}</mat-error>
</mat-form-field>
