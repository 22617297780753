import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
	Einreichungsperson,
	KycChecks,
	einreichungspersonKycChecksMetadata,
	einreichungspersonMetadata,
} from '../../model';

@Component({
	selector: 'app-einreichung-view-personen-kycchecks',
	templateUrl: './einreichung-view-personen-kycchecks.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinreichungViewPersonenKycchecksComponent implements OnInit {
	@Input() person?: Einreichungsperson;

	public readonly metadata = einreichungspersonMetadata;
	public readonly kycMetadata = einreichungspersonKycChecksMetadata;
	kycChecks: KycChecks[] = [];

	ngOnInit(): void {
		if (!this.person) {
			console.log('No person provided. Exiting.');
			return;
		}
		this.kycChecks = [...this.person.kycChecks];		
		this.kycCheckSorter(this.person);
	}
	
	kycCheckSorter(person: Einreichungsperson): void {
		person.kycChecks.sort((a, b) => {
			//Sort by descending order of kyc check date
			return new Date(a.datum).getTime() - new Date(b.datum).getTime();
		});
	}
}
