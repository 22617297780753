import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, TrackByFunction } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, EMPTY, filter } from 'rxjs';
import { BaseComponent } from 'src/app/general/base-component';
import { AlertService } from 'src/app/shared/alert.service';
import { TypedForm } from 'src/app/shared/forms/typed-form';
import { formatDateForSaveModel } from 'src/app/utils';
import { UltimoKontenEintrag, UltimoKontenSearchCriteria, ultimoMetadata } from '../model';
import { UltimoService } from '../ultimo.service';

@Component({
	selector: 'app-ultimo-konten',
	templateUrl: './ultimo-konten.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltimoKontenComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatSort) sort?: MatSort;
	@ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

	public readonly isSearching$ = new BehaviorSubject(false);
	public readonly metadata = ultimoMetadata;

	public readonly dataSource = new MatTableDataSource<UltimoKontenEintrag>();
	public readonly displayedColumns = ['kontonummer', 'kontoart', 'summe'];

	public sumOfSums: number = 0;
	readonly searchForm = new TypedForm<FormValues>({
		datumAb: new UntypedFormControl(),
		datumBis: new UntypedFormControl(),
	});
	public existsAllSearchCriteria$ = new BehaviorSubject(false);

	constructor(private readonly service: UltimoService, private readonly alert: AlertService) {
		super();
		this.registerSubscription(
			this.searchForm.valueChanges.subscribe(val => {
				if (Object.values(val).filter(s => !!s).length === 2) {
					this.existsAllSearchCriteria$.next(true);
				} else {
					this.existsAllSearchCriteria$.next(false);
					this.dataSource.data = [];
				}
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.sort) {
			this.dataSource.sort = this.sort;
		}
	}

	search(): void {
		if (!this.searchForm.typedValue) return;

		this.isSearching$.next(true);

		const payload: UltimoKontenSearchCriteria = {
			kontoZerlegungAb: formatDateForSaveModel(this.searchForm.typedValue.datumAb),
			kontoZerlegungBis: formatDateForSaveModel(this.searchForm.typedValue.datumBis),
		};

		this.service
			.getUltimoKontenData$(payload)
			.pipe(
				catchError(err => {
					this.dataSource.data = [];
					this.alert.error('Konnte nicht laden!', err, { date: this.searchForm.typedValue });
					this.isSearching$.next(false);
					return EMPTY;
				})
			)
			.subscribe(items => {
				this.dataSource.data = items;
				this.sumOfSums = items.map(s => s.summe).reduce((acc, value) => acc + value, 0);
				this.isSearching$.next(false);
			});
	}
}

interface FormValues {
	datumAb: Date;
	datumBis: Date;
}
